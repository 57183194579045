interface CoinsIconProps {
  color?: string
  className?: string
  size?: string
}

export const CoinsIcon = ({
  color = 'white',
  className = '',
  size = '14',
}: CoinsIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M12 3.3833C12 5.17797 9.31333 6.6333 6 6.6333C2.68667 6.6333 0 5.17797 0 3.3833C0 1.58863 2.68667 0.133301 6 0.133301C9.31333 0.133301 12 1.58863 12 3.3833Z'
      fill={color}
    />
    <path
      d='M6 7.63319C7.79 7.63319 9.46 7.24252 10.7187 6.56052C11.1819 6.31459 11.6077 6.00391 11.9833 5.63786C11.994 5.71852 12 5.80052 12 5.88319C12 7.67786 9.31333 9.13319 6 9.13319C2.68667 9.13319 0 7.67786 0 5.88319C0 5.80052 0.00600006 5.71852 0.0166667 5.63786C0.392278 6.0039 0.81811 6.31457 1.28133 6.56052C2.53933 7.24252 4.21 7.63319 6 7.63319Z'
      fill={color}
    />
    <path
      d='M10.7187 9.06052C9.46 9.74253 7.79 10.1332 6 10.1332C4.21 10.1332 2.53933 9.74253 1.28133 9.06052C0.818105 8.81458 0.392272 8.5039 0.0166667 8.13786C0.00600006 8.21852 0 8.30052 0 8.38319C0 10.1779 2.68667 11.6332 6 11.6332C9.31333 11.6332 12 10.1779 12 8.38319C12 8.30052 11.994 8.21852 11.9833 8.13786C11.6077 8.50392 11.1819 8.8146 10.7187 9.06052Z'
      fill={color}
    />
  </svg>
)
