import { HomeIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import Lottie from 'lottie-react'
import { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { useMixpanel } from 'react-mixpanel-browser'
import ReactPolling from 'react-polling'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import AskUseTheseSettingsModal from '../components/AskUseTheseSettingsModal'
import { CanvasFailed } from '../components/CanvasCell/CanvasFailed'
import { ConfigsSummary } from '../components/ConfigsSummary'
import DownloadModal from '../components/DownloadModal'
import FeedbackUserModal from '../components/FeedbackUserModal'
import Footer from '../components/Footer'
import { Modal } from '../components/Modal'
import Navbar from '../components/Navbar'
import { RegenerateVideoButton } from '../components/RegenerateVideoButton'
import { SceneDropdown } from '../components/SceneDropdown'
import ShareOptions from '../components/ShareOptions'
import { UpscaleModal } from '../components/UpscaleModal'
import VideoDeletionModal from '../components/VideoDeletionModal'
import VideoFeedbackModal from '../components/VideoFeedbackModal'
import VideoInfoModal from '../components/VideoInfoModal'
import VideoUserFeedback from '../components/VideoUserFeedback'
import { useUserAccountContext } from '../context/userContext'
import { useVideoEventsContext } from '../context/videoEventsContext'
import DownloadIcon from '../images/Download2.svg'
import DownloadIconTray from '../images/DownloadIconTray.svg'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import ShareIcon from '../images/Share.svg'
import UpscaleIcon from '../images/Upscale2.svg'
import http from '../services/HttpService'
import { cn, getAspectRatioFromWidthAndHeight } from '../utils'
import {
  ANIMATED_IMAGE_PROMPT_COPY,
  VIDEO_TYPES_AND_VERSIONS,
} from '../utils/constants'
import { getProxiedR2FileUrl } from '../utils/fileUtils'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'
import { getFormattedTypeAndVersion } from '../utils/video_util'

function MemoryDetailView() {
  const params = useParams()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()
  const [memory, setMemory] = useState(null)
  const [runSettings, setRunSettings] = useState(null)
  const [isNotFound, setIsNotFound] = useState(false)
  const [failed, setFailed] = useState(false)
  const [showVideoDeletionModal, setShowVideoDeletionModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showReferralModal, setShowReferralModal] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [showAskUseTheseSettingsModal, setShowAskUseTheseSettingsModal] =
    useState(false)
  const [videoFeedbackStatus] = useState(false)
  const [showVideoInfoModal, setShowVideoInfoModal] = useState(false)
  const [, setIsVideoFeedbackDone] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadPercent, setDownloadPercent] = useState(0)
  const [, setEstimatedWaitTime] = useState(0)
  const [isUpscaling, setIsUpscaling] = useState(false)
  const [upscaleOpen, setUpscalingOpen] = useState(false)
  const [downloadIsOpen, setDownloadIsOpen] = useState(false)
  const [isRequeueing, setIsRequeueing] = useState(false)
  const [numberOfScenes, setNumberOfScenes] = useState(null)
  const [sceneIndex, setSceneIndex] = useState(0)
  const [sceneData, setSceneData] = useState({})
  const [sceneTimestamp, setSceneTimestamp] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showFeedback, setShowFeedback] = useState(true)
  const [hideFeedback, setHideFeedback] = useState(false)
  const [timeToShowFeedback, setTimeToShowFeedback] = useState(false)
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [showRegenerateButton, setShowRegenerateButton] = useState(true)
  const videoRef = useRef()
  const timerRef = useRef(null)
  const [audioUrl, setAudioUrl] = useState(null)
  const abortController = useRef(new AbortController())

  const {
    currentUser,
    isAuthenticated,
    setMixpanelMemoryIds,
    featureToggles,
    refreshCredits,
  } = useUserAccountContext()

  const {
    setEventStartTime,
    setEventEndTime,
    setEventMethod,
    setEventMemoryId,
    setIsPlaying,
    onUnloadEvent,
  } = useVideoEventsContext()

  const { TRANSFORM_V2 } = VIDEO_TYPES_AND_VERSIONS

  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  const toggleVideoDeletionModal = (isOpen) => {
    setShowVideoDeletionModal(isOpen)
  }

  const navigateToDashboard = () => {
    navigate('/dashboard')
  }

  const canUpscale = useMemo(() => {
    if (
      currentUser?.subscriptionTier &&
      currentUser.subscriptionTier !== 'explorer_tier_monthly'
    ) {
      return true
    }
    return false
  }, [currentUser])

  useEffect(() => {
    videoRef.current?.load()
  }, [memory])

  useEffect(() => {
    if (
      !runSettings?.rating &&
      videoRef?.current?.currentTime &&
      !timeToShowFeedback
    ) {
      if (
        videoRef?.current?.currentTime >= videoRef?.current?.duration - 0.5 ||
        videoRef?.current?.currentTime >= videoRef?.current?.duration * 0.99
      ) {
        setTimeToShowFeedback(true)
      }
    }
  }, [timeToShowFeedback, runSettings, videoRef?.current?.currentTime])

  useEffect(() => {
    const scenes = runSettings?.scenes
    const type = runSettings?.type
    const version = runSettings?.version

    if (scenes) {
      setSceneData(scenes[sceneIndex])
      setNumberOfScenes(Object.keys(scenes).length)
      setShowRegenerateButton(
        getFormattedTypeAndVersion(type, version) === TRANSFORM_V2 ||
          !!scenes[sceneIndex]?.selectedFrame,
      )
    } else if (runSettings) {
      // needed to preserve backwards compatibility
      runSettings['length'] = memory?.settingsUsed.duration
      runSettings['camera_movement_names'] = [
        memory?.settingsUsed.cameraMovementName,
      ]
      runSettings['prompt'] = memory?.settingsUsed.prompt
      setSceneData(runSettings)
      setNumberOfScenes(1)
      setShowRegenerateButton(!!runSettings['init_image_s3_key'])
    }
  }, [runSettings])

  const getTimestamp = useCallback(() => {
    let timestamp = 0
    const scenes = runSettings?.scenes

    if (!scenes) return

    for (let i = 0; i < Object.keys(scenes).length; i++) {
      if (scenes[i] === scenes[sceneIndex]) break
      timestamp += scenes[i].length
    }

    return timestamp
  }, [runSettings, sceneIndex])

  useEffect(() => {
    const timestamp = getTimestamp()
    setSceneTimestamp(timestamp)

    if (videoRef.current) {
      videoRef.current.currentTime = timestamp
      videoRef.current.pause()
    }
  }, [sceneIndex])

  const onLoaded = () => {
    setIsLoaded(true)
  }

  const handleSceneChange = (sceneNumber) => {
    const index = sceneNumber - 1
    setSceneIndex(index)
    setSceneData(runSettings.scenes[index])
  }

  const fetchMemory = useCallback(async () => {
    if (!isNotFound) {
      try {
        const res = await http.get('/api/get_memory/' + params.memory_id)
        return res
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsNotFound(true)
        }
      }
    }
  }, [isNotFound, params.memory_id])

  const handleOpenDownload = () => {
    setDownloadIsOpen(!downloadIsOpen)
  }

  const handleOpenUpscaleModal = (memoryId = false) => {
    if (canUpscale) {
      if (memoryId) setIsUpscaling(true)
      setUpscalingOpen(!upscaleOpen)
    } else {
      navigate('/pricing')
    }
  }

  const handleUpscaleSuccess = async (memoryId) => {
    const response = await http.get('/api/get_memory/' + memoryId)
    if (response) {
      setMemory(response.data.memory)
      handleIsUpscaling()
      refreshCredits()
    }
    setIsUpscaling(false)
  }

  const handleUpscaleError = async (memoryId) => {
    const response = await http.get('/api/get_memory/' + memoryId)
    if (response) {
      setMemory(response.data.memory)
      handleIsUpscaling()
    }
    setIsUpscaling(false)
  }

  const handleShare = () => {
    setShowShareModal(true)
  }

  const handleShowFeedbackChange = (newState) => {
    if (showFeedback && !newState) {
      timerRef.current = setTimeout(() => {
        setHideFeedback(true)
      }, 800)
    }
    setShowFeedback(newState)
  }

  const handleTryAgain = async () => {
    if (isRequeueing) {
      return
    }

    setIsRequeueing(true)

    // Send request to rerun video generation
    await http.post('/api/requeue_video', {
      memory_id: params.memory_id,
    })

    window.location.reload()
  }

  const toggleAskUseTheseSettingsModal = (isOpen) => {
    setShowAskUseTheseSettingsModal(isOpen)
  }

  const toggleVideoInfoModal = (isOpen) => {
    setShowVideoInfoModal(isOpen)
  }

  const onDownloadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    setDownloadPercent(percentCompleted)
  }

  const stopDownload = useCallback(() => {
    if (abortController?.current) {
      abortController.current.abort()
    }
  }, [abortController])

  useEffect(() => {
    return () => stopDownload()
  }, [stopDownload])

  const downloadVideo = async (videoUrl, videoTitle) => {
    try {
      // Start downloading
      setIsDownloading(true)

      // use proxy server to get around CORS errors with R2
      videoUrl = getProxiedR2FileUrl(videoUrl, true)

      // Fetch the video as a Blob using axios
      const response = await http({
        url: videoUrl,
        method: 'GET',
        responseType: 'blob', // Tell axios to return the response as a Blob
        signal: abortController.current.signal,
        onDownloadProgress,
        transformRequest: (data, headers) => {
          delete headers.Authorization
          return data
        },
      })

      const blob = response.data
      let videoSize = (blob.size / (1024 * 1024)).toFixed(2)

      // Create an object URL that points to the Blob
      const objectUrl = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')
      link.href = objectUrl

      // Set the download attribute and the file name
      link.download = videoTitle

      // Add the link to the document
      document.body.appendChild(link)

      // Click the link to start the download
      link.click()

      // Remove the link from the document
      document.body.removeChild(link)

      // Revoke the object URL
      URL.revokeObjectURL(objectUrl)

      mixpanel.track('video_downloaded', {
        file_size: videoSize,
        memoryId: memory.memory_id,
      })

      trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.VIDEO_DOWNLOAD, {
        memory_id: memory.memory_id,
        video_type: memory.settingsUsed.videoType,
        video_file_size: videoSize,
        video_quality: 1, // to implement in the future for other qualities https://height.app/M9jzUFTFNu/T-6200
        video_version: memory.settingsUsed?.version,
        video_is_watermarked: memory.isWatermarked,
        video_download_url: videoUrl,
        page_url: window.location.origin,
      })

      // Enable download button
      setIsDownloading(false)
      setDownloadPercent(0)
      toast.success('Video downloaded.')
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      } else {
        console.log(error)
        window.open(videoUrl, '_blank')
      }
    }
  }

  const handleButtonDisable = () => {
    return (
      isUpscaling ||
      !memory ||
      (memory &&
        memory.upscale_status &&
        memory.upscale_status === 'pending') ||
      (memory.versions &&
        memory.versions.some((e) => e.scale_multiplier === 2) &&
        memory.versions.some((e) => e.scale_multiplier === 4))
    )
  }

  const handleIsUpscaling = (_memory = memory) => {
    setIsUpscaling(
      !!(
        _memory &&
        _memory.upscale_status &&
        _memory.upscale_status === 'pending'
      ),
    )
  }

  const handleOpenInPromptEditor = () => {
    trackEvent(
      mixpanel,
      currentUser,
      MIXPANEL_EVENTS.CLICK_OPEN_IN_PROMPT_EDITOR,
      {
        memory_id: memory.memory_id,
        video_type: memory.settingsUsed.videoType,
        video_scenes_count: Object.keys(memory.settingsUsed.scenes).length,
        page_url: window.location.origin,
      },
    )

    const path = `/create?memory_id=${memory.memory_id}`
    navigate(path)
  }

  const updateMemory = async () => {
    if (!isNotFound) {
      const response = await http.get('/api/get_memory/' + params.memory_id)

      if (response.data && response.data.memory) {
        if (
          response.data.memory.upscale_status &&
          response.data.memory.upscale_status === 'pending'
        ) {
          await new Promise((resolve) => setTimeout(resolve, 3000))
          updateMemory()
        } else if (
          response.data.memory.upscale_status &&
          response.data.memory.upscale_status === 'failed'
        ) {
          handleUpscaleError(params.memory_id)
          toast.error('Upscaling failed')
        } else {
          setMemory(response.data.memory)
          handleIsUpscaling(response.data.memory)
          refreshCredits()
        }
      }
    }
  }

  const onPlay = () => {
    setEventStartTime(videoRef.current.currentTime)
    setEventMemoryId(params.memory_id)
    setIsPlaying(true)
  }

  const onPause = () => {
    setEventMemoryId(params.memory_id)
    setEventEndTime(videoRef.current.currentTime)
    setEventMethod('player')
  }

  const onTimeUpdate = () => {
    setEventEndTime(videoRef.current.currentTime)
  }

  const handleResize = useCallback(() => {
    setIsMobile(checkMobile())
  }, [])

  const checkMobile = () => {
    const userAgent = window.navigator.userAgent
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent,
      )
    ) {
      return true
    } else {
      //this is for iPad Prod
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      )
    }
    /*return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )*/
  }

  useEffect(() => {
    const onUnload = () => {
      onUnloadEvent(videoRef, params.memory_id)
    }

    setIsMobile(checkMobile())

    window.addEventListener('beforeunload', onUnload)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, onUnloadEvent, params.memory_id])

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchMemory()

      if (response) {
        if (response.data && response.data.memory) {
          const isOwner =
            currentUser?.distinctId === response.data?.memory.auth0_user_id ||
            currentUser?.role === 'admin'
          if (!isOwner) {
            // navigate(`/share/${params.memory_id}`)
          }

          setMemory(response.data.memory)
          setRunSettings(response.data.run_settings)

          if (!audioUrl) {
            // There's no need to update the audio url with every get_memory since it's always the same link but signed differently,
            // and updating it causes shutter during audio play
            setAudioUrl(
              response.data.memory?.settingsUsed?.userProvidedAudioFileUrl,
            )
          }

          onLoaded()

          if (isOwner) {
            if (
              response.data.memory.upscale_status &&
              response.data.memory.upscale_status === 'pending'
            ) {
              handleIsUpscaling(response.data.memory)
              await new Promise((resolve) => setTimeout(resolve, 3000))
              updateMemory()
            } else {
              setMemory(response.data.memory)
              handleIsUpscaling(response.data.memory)
            }

            if (!memory) {
              setMemory(response.data.memory)
              handleIsUpscaling(response.data.memory)
            }

            if (
              response.data.memory.upscale_status &&
              response.data.memory.upscale_status === 'pending'
            ) {
              await new Promise((resolve) => setTimeout(resolve, 3000))
              updateMemory()
            } else {
              setMemory(response.data.memory)
              handleIsUpscaling(response.data.memory)
            }
          }

          setMixpanelMemoryIds([response.data.memory.memory_id])

          mixpanel.track('video_presented', {
            number_of_videos: 1,
            memoryIds: [response.data.memory.memory_id],
          })
        }

        if (response.data.run_settings) {
          setEstimatedWaitTime(
            (15 * response.data.run_settings.max_frames) / 12,
          )
        }
      }
    }

    fetchData()
  }, [])

  let preview_frame = null

  if (runSettings) {
    if (
      runSettings.s3_images &&
      Object.keys(runSettings.s3_images).length > 0
    ) {
      preview_frame =
        runSettings.s3_images[runSettings.init_image_s3_key] ||
        Object.entries(runSettings.s3_images)[0][1]
    }

    if (runSettings.init_image_url) {
      preview_frame = runSettings.init_image_url
    }

    if (memory.initImageUrl) {
      preview_frame = memory.initImageUrl
    }
  }

  useEffect(() => {
    if (memory) {
      const query = new URLSearchParams(window.location.search)
      const postToTiktok = query.get('post_tiktok')
      // eslint-disable-next-line eqeqeq
      if (postToTiktok == true || postToTiktok === 'true') {
        handleShare()
      }

      const showSurvey = window.sessionStorage.getItem('SHOW_SURVEY')
      if (showSurvey === 'true') {
        const surveyEvent = new Event('toggleSurvey')
        window.dispatchEvent(surveyEvent)
        window.sessionStorage.removeItem('SHOW_SURVEY')
      }
    }
  }, [memory])

  return (
    <div className='min-h-full'>
      <Navbar />
      <div
        className={cn('flex bg-dark justify-center pt-[74px]', {
          'pt-[124px]': isFeedbackBannerShown,
        })}
      >
        <ReactPolling
          url={'url to poll'}
          interval={2000} // in milliseconds(ms)
          retryCount={2} // number of retries at interval provided if polling call fails
          onSuccess={(response) => {
            if (response.data.memory) {
              setMemory(response.data.memory)
              // There's no need to update the audio url with every get_memory since it's always the same link but signed differently,
              // and updating it causes shutter during audio play
              if (audioUrl) {
                setAudioUrl(
                  response.data.memory?.settingsUsed?.userProvidedAudioFileUrl,
                )
              }
            }

            if (
              response.data.memory &&
              (typeof response.data.memory.status === 'undefined' ||
                response.data.memory.status === 'done')
            ) {
              if (isAuthenticated) {
                refreshCredits()
              }
              setFailed(false)
              setMemory(response.data.memory)
              handleIsUpscaling(response.data.memory)
              return false
            } else if (response.data?.deleted === true) {
              setIsLoaded(true)
              setIsNotFound(true)
              return false
            } else if (
              (currentUser?.distinctId ===
                response.data?.memory.auth0_user_id ||
                currentUser?.role === 'admin') &&
              response.data.memory?.status === 'failed'
            ) {
              setFailed(true)
              return false
            } else {
              setFailed(false)
              return true
            }
          }}
          onFailure={(error) => {
            if (error?.response?.status === 404) {
              setIsNotFound(true)
            }
            return false
          }}
          promise={fetchMemory} // custom api calling function that should return a promise
          render={({ isPolling }) => {
            // SHOW LOADING SCREEN WHILE POLLING
            if (isPolling && !isLoaded && !failed) {
              return (
                <div className='flex flex-col items-center pt-56 pb-48 space-y-4'>
                  <div className='p-6 bg-darkGray rounded-3xl'>
                    <Lottie
                      animationData={LoadingWhite}
                      loop={true}
                      className='w-16 h-16'
                    />
                  </div>
                </div>
              )

              // SHOW MEMORY DETAILS ONCE LOADED
            } else if (memory && runSettings && !failed) {
              return (
                <div className='max-w-full m-auto mt-2 space-y-8 mb-[83px] lg:mb-40 sm:max-w-7xl lg:mt-[95px]'>
                  <div className='flex flex-col-reverse grid-cols-4 px-5 lg:gap-12 lg:grid'>
                    {/* LEFT COLUMN */}
                    <div className='relative col-span-2 lg:w-[480px] '>
                      <div className='bg-[#212127] rounded-lg py-[37px] px-[32px]'>
                        <div className='flex flex-wrap items-center'>
                          <h2 className='text-[#8E978E] font-public-sans text-sm font-normal mr-3'>
                            SUMMARY
                          </h2>
                          {numberOfScenes > 1 && (
                            <div className='lg:ml-8'>
                              <SceneDropdown
                                numberOfScenes={numberOfScenes ?? 1}
                                activeScene={sceneIndex + 1}
                                setActiveScene={handleSceneChange}
                                lastCompletedScene={numberOfScenes ?? 1}
                              />
                            </div>
                          )}
                        </div>

                        {/* PROMPT */}

                        {sceneData.subject === ',' &&
                        sceneData.style === ',' ? (
                          <p className='inline text-3xl text-[#ECFBEC] font-public-sans font-medium leading-normal mt-3'>
                            {ANIMATED_IMAGE_PROMPT_COPY}
                            <span className='inline-block has-tooltip ml-3'>
                              <div className='text-lg px-2 py-1 -mt-8 text-gray-100 bg-gray-800 rounded shadow-lg tooltip'>
                                No prompt was entered when creating this video.
                              </div>
                              <AiOutlineQuestionCircle className='size-7 leading-3 text-[#9B92D0]' />
                            </span>
                          </p>
                        ) : (
                          <>
                            <div className='text-3xl text-[#ECFBEC] font-public-sans font-medium leading-normal mt-6'>
                              {sceneData.subject}
                            </div>
                            <p className='text-3xl text-[#ECFBEC] font-public-sans font-medium leading-normal'>
                              in the style of {sceneData.style}
                            </p>
                          </>
                        )}

                        {/* CONFIGS SUMMARY */}
                        <ConfigsSummary
                          activeScene={sceneIndex + 1}
                          timestamp={sceneTimestamp}
                          aspectRatio={getAspectRatioFromWidthAndHeight(
                            runSettings.W || runSettings.width,
                            runSettings.H || runSettings.height,
                          )}
                          length={sceneData.length}
                          cameraMovementNames={sceneData.camera_movement_names}
                          audioUrl={audioUrl}
                          showAspectRatio={
                            !runSettings.user_provided_starting_frame_s3_key &&
                            !memory.settingsUsed?.initVideoS3Key
                          }
                          className='mt-6'
                        />

                        <div className='mt-4 border-b border-solid border-[#515151]'></div>
                        {/* BUTTON GROUP */}
                        {!isPolling && (
                          <div className='flex items-start mt-5 lg:mt-6 gap-3 sm:gap-7'>
                            {/* DOWNLOAD BUTTON */}
                            <button
                              onClick={() =>
                                !memory.versions ||
                                currentUser?.distinctId !== memory.auth0_user_id
                                  ? downloadVideo(
                                      memory.mediaUrl,
                                      `${sceneData.subject
                                        .substr(0, 150)
                                        .replace(
                                          /\r\n/g,
                                          ' ',
                                        )} (${Date.now()}).mp4`,
                                    )
                                  : handleOpenDownload()
                              }
                              className={cn(
                                'group flex p-2 items-center gap-2 sm:flex-1 sm:w-[105px] md:w-auto border rounded border-solid border-kaiberGreen py-2 px-3 text-kaiberGreen',
                                !isDownloading &&
                                  'hover:bg-primary hover:text-black',
                              )}
                              disabled={isDownloading}
                            >
                              {isDownloading ? (
                                <>
                                  <img
                                    className='w-3 h-auto sm:w-5'
                                    src={DownloadIconTray}
                                    alt='download icon'
                                  />
                                  <span className='lg:inline text-[#8DDE9C] text-[12px] sm:text-[14px]'>
                                    {downloadPercent}%
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img
                                    className='w-3 h-auto sm:w-4 group-hover:brightness-0'
                                    src={DownloadIcon}
                                    alt='download icon'
                                  />
                                  <span className='lg:inline text-[12px] sm:text-[14px]'>
                                    Download
                                  </span>
                                </>
                              )}
                            </button>

                            {/* UPSCALE BUTTON */}
                            {currentUser?.distinctId === memory.auth0_user_id &&
                              memory && (
                                <button
                                  onClick={() => handleOpenUpscaleModal()}
                                  disabled={handleButtonDisable()}
                                  className={cn(
                                    'group flex p-2 items-center gap-2 sm:flex-1 sm:w-[105px] md:w-auto text-kaiberGreen border rounded border-solid border-kaiberGreen py-2 px-3',
                                    {
                                      'hover:no-underline text-primary/25':
                                        handleButtonDisable(),
                                    },
                                    !isUpscaling &&
                                      'hover:bg-primary hover:text-black',
                                  )}
                                >
                                  <img
                                    className={cn(
                                      'w-3 h-auto sm:w-4',
                                      !isUpscaling &&
                                        'group-hover:brightness-0',
                                    )}
                                    src={UpscaleIcon}
                                    alt='upscale icon'
                                  />
                                  <span className='lg:inline text-[12px] sm:text-[14px]'>
                                    {!canUpscale && (
                                      <span className='font-bold'>
                                        Upgrade to upscale
                                      </span>
                                    )}
                                    {canUpscale &&
                                      (isUpscaling
                                        ? `Upscaling...`
                                        : `Upscale`)}
                                  </span>
                                </button>
                              )}

                            {/* SHARE BUTTON */}
                            <button
                              className={cn(
                                'group flex p-2 items-center gap-2 sm:flex-1 sm:w-[105px] md:w-auto text-kaiberGreen border rounded border-solid border-kaiberGreen py-2 px-3 hover:bg-primary hover:text-black',
                              )}
                              onClick={() => {
                                handleShare()
                                mixpanel.track('Open Share Modal')
                              }}
                            >
                              <img
                                className='w-3 h-auto sm:w-4 group-hover:brightness-0'
                                src={ShareIcon}
                                alt='share icon'
                              />
                              <span className='lg:inline text-[12px] sm:text-[14px]'>
                                Share
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className='flex flex-col gap-4 mt-10 text-lg text-kaiberGreen'>
                        <button
                          className='inline-flex px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-primary hover:bg-primary hover:text-black w-fit'
                          onClick={handleOpenInPromptEditor}
                        >
                          Open In Prompt Editor
                        </button>
                        <Link
                          to='/dashboard'
                          className='inline-flex px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-primary hover:bg-primary hover:text-black w-fit'
                        >
                          View In My Videos
                        </Link>
                      </div>
                    </div>

                    {/* RIGHT COLUMN */}
                    <div className='flex col-span-2 mt-10 lg:mt-0 flex-col'>
                      {isPolling ? (
                        <div className='relative' style={{ minHeight: 150 }}>
                          {preview_frame ? (
                            <img
                              src={preview_frame}
                              onLoad={onLoaded}
                              className='object-cover w-full rounded-xl max-h-[680px]'
                              style={{ display: isLoaded ? 'block' : 'none' }}
                              alt={sceneData?.subject}
                            />
                          ) : (
                            <div
                              className='bg-lightGray bg-opacity-75 object-cover w-full rounded-xl max-h-[680px]'
                              style={{
                                display: 'block',
                                height: runSettings.H
                                  ? `${runSettings.H}px`
                                  : '100%',
                              }}
                              alt={sceneData?.subject}
                            />
                          )}

                          <div className='absolute inset-0 bg-black bg-opacity-75'>
                            <div className='flex flex-col items-center justify-center w-full h-full backdrop-blur-sm'>
                              <div className='flex flex-col items-center space-y-4'>
                                <div
                                  className={`bg-darkGray ${
                                    runSettings.H > runSettings.W
                                      ? 'p-6'
                                      : 'p-4'
                                  } rounded-3xl`}
                                >
                                  <Lottie
                                    animationData={LoadingWhite}
                                    loop={true}
                                    className={` ${
                                      runSettings.H > runSettings.W
                                        ? 'w-16 h-16'
                                        : 'w-10 h-10'
                                    }`}
                                  />
                                </div>
                              </div>
                              <p
                                className={`text-white ${
                                  runSettings.H > runSettings.W
                                    ? 'mt-8'
                                    : 'mt-4'
                                }`}
                              >
                                {memory.status === 'pending' &&
                                  'Crafting your masterpiece...'}
                                {memory.status === 'done' && 'Loading...'}
                              </p>
                              {memory.progress && (
                                <p
                                  className={`text-white ${
                                    runSettings.H > runSettings.W
                                      ? 'mt-8'
                                      : 'mt-4'
                                  }`}
                                >
                                  {`${(memory.progress * 100).toFixed(
                                    0,
                                  )}% completed`}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='flex lg:items-center flex-col'>
                          <div className='flex justify-center relative lg:block mb-4 overflow-hidden'>
                            <video
                              className='rounded-xl max-h-[680px]'
                              type='video/mp4'
                              autoPlay
                              loop
                              playsInline
                              controls
                              ref={videoRef}
                              onLoadedData={onLoaded}
                              onPlay={onPlay}
                              onPause={onPause}
                              onEnded={onPause}
                              onTimeUpdate={onTimeUpdate}
                            >
                              <source src={memory.mediaUrl} type='video/mp4' />
                            </video>

                            {!runSettings.rating &&
                              timeToShowFeedback &&
                              !hideFeedback && (
                                <div
                                  className={`absolute w-full ${
                                    isMobile
                                      ? 'top-full animate-slide-up -translate-y-full'
                                      : '-translate-y-full max-[1023px]:top-full max-[1023px]:animate-slide-up'
                                  } ${
                                    showFeedback
                                      ? 'animate-slide-up'
                                      : 'animate-slide-down'
                                  }`}
                                >
                                  <VideoUserFeedback
                                    setShowModal={setShowModal}
                                    memoryId={params.memory_id}
                                    setShowFeedback={handleShowFeedbackChange}
                                    feedbackSent={feedbackSent}
                                    setFeedbackSent={setFeedbackSent}
                                  />
                                </div>
                              )}
                          </div>

                          {featureToggles['enable-regenerate'] &&
                            showRegenerateButton && (
                              <RegenerateVideoButton
                                sceneData={sceneData}
                                runSettings={runSettings}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )

              // SHOW 404 PAGE IF MEMORY NOT FOUND
            } else if (isNotFound && !isPolling) {
              return (
                <div className='px-5 py-12 text-center lg:py-24'>
                  <div className='space-y-4'>
                    <h1 className='text-tertiary text-8xl'>
                      <span className='font-bold'>404</span>
                    </h1>
                    <p className='text-tertiary/60'>
                      This video could not be found.
                    </p>
                    <Link to='/'>
                      <button className='inline-flex items-center justify-between flex-grow gap-2 px-6 py-4 mt-6 text-base font-bold text-white uppercase border border-transparent rounded-full shadow-sm bg-secondary hover:bg-green-700 focus:outline-none focus:ring-0 w-60'>
                        Go back home
                        <HomeIcon className='w-6 h-6' />
                      </button>
                    </Link>
                  </div>
                </div>
              )

              // SHOW ERROR PAGE IF VIDEO GENERATION FAILED
            } else if (failed) {
              return (
                <div className='px-5 py-28 text-center'>
                  <CanvasFailed
                    canvas={memory}
                    handleTryAgain={handleTryAgain}
                    isRequeueing={isRequeueing}
                    onDelete={toggleVideoDeletionModal}
                    showFailedIcon={true}
                  />
                </div>
              )
            } else {
              return null
            }
          }}
        />
      </div>
      <FeedbackUserModal
        isOpen={showModal}
        setShowModal={setShowModal}
        memoryId={params.memory_id}
        setFeedbackSent={setFeedbackSent}
      />
      <Modal open={showShareModal} setOpen={setShowShareModal}>
        <ShareOptions
          isMobile={isMobile}
          shareUrl={`${window.location.origin}/share/${params.memory_id}`}
          title='Share'
          memory={memory}
          handleModalClose={setShowShareModal}
        />
      </Modal>
      <Modal open={showReferralModal} setOpen={setShowReferralModal}>
        <ShareOptions
          isMobile={isMobile}
          shareUrl={`${process.env.REACT_APP_SITE_URL}?referrer_id=${currentUser?.uuid}`}
          // shareUrl={`${process.env.REACT_APP_SITE_URL}/?via=${currentUser?.rewardfulLink}`}
          title='Refer a friend'
          bodyText='Refer a friend and get 300 extra credits when they sign up for a Pro plan.'
          requireAuth
          // requireRewardful
        />
      </Modal>
      <VideoDeletionModal
        isOpen={showVideoDeletionModal}
        videoId={memory?._id}
        onClose={toggleVideoDeletionModal}
        onDelete={navigateToDashboard}
      />
      <VideoFeedbackModal
        open={showFeedbackModal}
        setOpen={setShowFeedbackModal}
        page='Memory Details'
        status={videoFeedbackStatus}
        setDone={setIsVideoFeedbackDone}
      />
      <VideoInfoModal
        settingsUsed={memory?.settingsUsed}
        isOpen={showVideoInfoModal}
        onClose={toggleVideoInfoModal}
      />
      <AskUseTheseSettingsModal
        settingsUsed={memory?.settingsUsed}
        isOpen={showAskUseTheseSettingsModal}
        onClose={toggleAskUseTheseSettingsModal}
      />
      <UpscaleModal
        isOpen={upscaleOpen}
        onClose={handleOpenUpscaleModal}
        memory={memory}
        onSuccess={handleUpscaleSuccess}
        onError={handleUpscaleError}
      />
      <DownloadModal
        isOpen={downloadIsOpen}
        onClose={() => setDownloadIsOpen(false)}
        memory={memory}
      />
      {isLoaded && <Footer />}
    </div>
  )
}

export default MemoryDetailView
