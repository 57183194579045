import { BaseComponentProps } from '../../types'

export const CircleInfoIcon = ({ className = '' }: BaseComponentProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    stroke='currentColor'
    className={className}
  >
    <path
      d='M10.75 11.8983H12V17.1483M21.25 12.8983C21.25 18.0069 17.1086 22.1483 12 22.1483C6.89137 22.1483 2.75 18.0069 2.75 12.8983C2.75 7.78969 6.89137 3.64832 12 3.64832C17.1086 3.64832 21.25 7.78969 21.25 12.8983Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 8.27332C11.6548 8.27332 11.375 8.55314 11.375 8.89832C11.375 9.2435 11.6548 9.52332 12 9.52332C12.3452 9.52332 12.625 9.2435 12.625 8.89832C12.625 8.55314 12.3452 8.27332 12 8.27332Z'
      strokeWidth='0.25'
    />
  </svg>
)
