import SectionTitle from './SectionTitle'
import Button from './Button'
import { Link } from 'react-router-dom'
import TestimonyComponent from './TestimonyComponent'

const section3Img1 = '/website/welcome/manu-kaiber.jpg'
const section3Img2 = '/website/welcome/august-kamp-still.png'
const avatar1 = '/website/welcome/avatar-manu.jpeg'
const avatar2 = '/website/welcome/avatar-august-kamp.jpeg'

function ArtistTestimony() {
  return (
    <div className='flex w-full justify-center overflow-hidden'>
      <div className='flex flex-col flex-1 max-w-[1440px] w-full items-center md:px-[3.5%] px-4'>
        <SectionTitle
          title='Join the creative revolution'
          description='Experience limitless creativity, connect with like-minded artists'
        />
        <TestimonyComponent
          image={section3Img1}
          text='“As I’m always traveling, Kaiber has been an amazing addition to my workflow, enabling me to quickly generate creative ideas in a mobile-friendly way. Their user experience is also very accessible for beginners, while leaving enough control even for experts.”'
          name='Manuel “Manu.Vision” Sainsily'
          job='Caribbeanofuturist'
          igLink='https://www.instagram.com/manu.vision/'
          avatar={avatar1}
          justify='justify-end'
        />

        <TestimonyComponent
          image={section3Img2}
          text='“In a world where every single AI system is stumbling over itself trying to replace your whole toolbox, Kaiber is taking a different approach. It feels more like a tool that was designed to make you pick up your other tools again.”'
          name='August Kamp'
          job='Musician'
          igLink='https://www.instagram.com/august.kamp/'
          avatar={avatar2}
          orderImg='sm:order-last'
          orderText='sm:order-first'
          justify='justify-start'
        />
        <Link to='/create'>
          <Button btnText='Try now' />
        </Link>
      </div>
    </div>
  )
}

export default ArtistTestimony
