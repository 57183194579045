import { Link as LinkScroll } from 'react-scroll'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Accordion from '../components/Accordion'
import { CommunityForm } from '../components/communityForm'
import { cn } from '../utils'
import { useUserAccountContext } from '../context/userContext'

const communityHero =
  'https://website-public-assets.kybercorp.org/web/pricing/community-hero.png'
const fallingForward = '/website/community/falling-forward.png'
const discussions = '/website/community/discussions.png'
const helpCenter = '/website/community/help-center.png'
const blog = '/website/community/blog.png'
const arrow = '/website/community/arrow.svg'

export const Community = () => {
  const { featureToggles } = useUserAccountContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  const accordionItems = [
    {
      title: 'Get Paid',
      content: `Refer Kaiber to your friends and followers, and earn 10% of all referred payments. There's no limit to how much you can earn.`,
    },
    {
      title: 'Unlimited Access',
      content:
        'Receive unlimited Kaiber credits and exclusive access to Beta tests.',
    },
    {
      title: 'Partnerships',
      content:
        'Unlock opportunities for long-term collaborations, plus marketing support to take your creative projects to the next level.',
    },
  ]

  return (
    <div className='bg-black'>
      <Navbar />

      <div
        className={cn('pt-[100px] md:pt-[175px]', {
          'pt-[150px] md:pt-[225px]': isFeedbackBannerShown,
        })}
      >
        <div className='mb-[120px] md:mb-[140px] flex justify-center mx-[20px] md:mx-0'>
          <div className='flex max-w-[1890px] w-full items-center md:items-start relative md:h-[865px] flex-col-reverse md:justify-center'>
            <div className='md:ml-[5%] xl:ml-[120px] relative z-[1] max-w-[789px] w-full md:w-[70%]'>
              <h2 className='text-tertiary text-2xl xl:text-[69px] font-normal leading-tight mt-[30px] max-w-[600px]'>
                Welcome to the Kaiber Community
              </h2>
              <p className='text-tertiary text-base xl:text-[31px] font-light my-6 md:mb-[52px] xl:leading-[40px] max-w-[640px] tracking-[0.5px]'>
                A space for passionate creators who want to push the boundaries
                of generative art. Connect, collaborate, gain exclusive access
                to new features, and be rewarded for your creativity.
              </p>
              <a
                className='text-black bg-kaiberGreen rounded-full py-3 px-10 text-base xl:text-2xl font-semibold'
                target='_blank'
                href='https://discord.com/invite/kaiber'
                rel='noopener noreferrer nofollow'
              >
                Join our Discord
              </a>

              <LinkScroll
                to='content'
                className='cursor-pointer text-kaiberGreen text-[28px] xl:text-[37px] font-light mt-[50px] flex items-center ml-[10px]'
                spy={true}
                smooth={true}
                offset={-112}
              >
                Explore more opportunities{' '}
                <img
                  className='rotate-[270deg] max-w-[18px] md:max-w-[30px]'
                  src={arrow}
                  alt='arrow'
                />
              </LinkScroll>
            </div>

            <div className='md:absolute right-0 self-end'>
              <div className='relative pt-0 w-[295px] h-[458px] md:w-[810px] md:h-[990px] overflow-hidden rounded-[33px] md:rounded-[60px]'>
                <img
                  className='h-full absolute top-0 left-[-1px] md:left-0'
                  src={communityHero}
                  alt='unveil'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-center mt-[73px]'>
          <div className='flex justify-between max-w-[1200px] w-[95%] flex-col min-[900px]:flex-row'>
            <div className='w-full min-[900px]:w-[48%]'>
              <img
                className='w-full'
                src={fallingForward}
                alt='falling forward'
              />
            </div>

            <div className='w-full min-[900px]:w-[48%] text-white pt-[20px]'>
              <h3
                className='text-white md:text-[42px] text-[30px] m-0'
                id='content'
              >
                Become a Kaiber Affiliate
              </h3>
              <p className='text-[#CBCBCB] text-[20px] md:text-[24px] mt-[20px] md:mt-0 mb-[60px] font-light leading-tight max-w-[500px]'>
                Spread the word about Kaiber and earn financial rewards and
                special perks:
              </p>

              <Accordion items={accordionItems} />

              <a
                className='inline-flex items-center justify-center px-6 py-4 text-xl font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40 mt-10'
                target='_blank'
                rel='noreferrer'
                href='https://znavcmypr3t.typeform.com/to/ImpnNpqQ?typeform-source=height.app'
              >
                Apply now
              </a>
            </div>
          </div>
        </div>

        <div className='flex justify-center mt-[160px] mb-0'>
          <div className='flex justify-between max-w-[1200px] w-[95%] flex-col md:flex-row'>
            <div className='w-full mb-10 md:mb-0 md:w-[29%]'>
              <img
                className='w-full mb-[25px]'
                src={discussions}
                alt='discussions'
              />

              <h4 className='text-white text-[24px] m-0 font-bold mb-[10px]'>
                Discussion
              </h4>
              <p className='text-white text-base m-0 font-normal mb-[10px]'>
                Ask questions, join conversations, get solutions to common
                issues, and find your people.
              </p>
              <a
                className='text-kaiberGreen border-b-[1px] pb-[3px] border-kaiberGreen'
                target='_blank'
                rel='noreferrer'
                href='https://discord.com/invite/kaiber'
              >
                Join Discord
              </a>
            </div>

            <div className='w-full mb-10 md:mb-0 md:w-[29%]'>
              <img
                className='w-full mb-[25px]'
                src={helpCenter}
                alt='help center'
              />

              <h4 className='text-white text-[24px] m-0 font-bold mb-[10px]'>
                Help Center
              </h4>
              <p className='text-white text-base m-0 font-normal mb-[10px]'>
                Check out the go-to resource for Kaiber tips and tricks, how to
                troubleshoot any issues, and explore all our features.
              </p>
              <a
                className='text-kaiberGreen border-b-[1px] border-kaiberGreen pb-[3px]'
                target='_blank'
                rel='noreferrer'
                href='https://helpcenter.kaiber.ai/'
              >
                Visit the Help Center
              </a>
            </div>

            <div className='w-full mb-10 md:mb-0 md:w-[29%]'>
              <img className='w-full mb-[25px]' src={blog} alt='blog' />

              <h4 className='text-white text-[24px] m-0 font-bold mb-[10px]'>
                Blog
              </h4>
              <p className='text-white text-base m-0 font-normal mb-[10px]'>
                Stay in the loop on the latest features, trends, and people
                shaping the future of generative art.
              </p>
              <a
                className='text-kaiberGreen border-b-[1px] border-kaiberGreen pb-[3px]'
                target='_blank'
                rel='noreferrer'
                href='https://blog.kaiber.ai/'
              >
                Browse the Blog
              </a>
            </div>
          </div>
        </div>

        <div className='flex justify-center mt-[100px] md:mt-[160px] mb-[200px]'>
          <div className='max-w-[1000px] w-[95%] text-center'>
            <h2 className='text-white text-[30px] md:text-[64px] mt-0 font-normal'>
              Kaiber Insider Community
            </h2>
            <p className='text-[#8C8C8C] text-[21px] md:text-[40px] font-light'>
              Get exclusive offers and updates.
            </p>

            <CommunityForm />

            <p className='text-[#8C8C8C] text-base md:text-[20px] font-light'>
              By signing up, you agree to with Kaiber’s Privacy Policy and Terms
              of Use.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
