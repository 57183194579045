import { IoMdArrowRoundBack } from 'react-icons/io'
import classNames from 'classnames'

export const PromptDisplay = ({
  subject,
  artStyle,
  onBackClick,
  disabled = false,
}) => {
  return (
    <div className='flex justify-between'>
      <div className='flex flex-col'>
        {subject === ',' && artStyle[0] === ',' ? (
          <span className='text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
            This video will be generated using attributes from the uploaded
            image
          </span>
        ) : (
          <>
            <span className='text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
              {subject}
            </span>
            <span className='text-xl whitespace-pre-line lg:text-2xl text-primary xl:text-3xl'>
              in the style of {artStyle.join(', ')}
            </span>
          </>
        )}
      </div>
      <div className='hidden md:block'>
        <button
          onClick={onBackClick}
          disabled={disabled}
          className={classNames(
            'ml-2 transition-all duration-200 ease-in-out rounded-full text-kaiberPurple',
            {
              'hover:bg-kaiberPurple hover:text-dark': !disabled,
              'opacity-60': disabled,
            },
          )}
        >
          <IoMdArrowRoundBack className='inline w-10 h-10 cursor-pointer' />
        </button>
      </div>
    </div>
  )
}
