import { useCallback, useEffect, useRef, useState } from 'react'
import { HiPause, HiPlay } from 'react-icons/hi2'

import { SliderMusicPlayer } from './SliderMusicPlayer'

export const AudioPlayback = ({
  audioUrl,
  color = 'kaiberGreen',
  className = '',
}) => {
  const audioRef = useRef(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [localSliderValue, setLocalSliderValue] = useState(0)

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime)
      setLocalSliderValue(
        (audioRef.current.currentTime / audioRef.current.duration) * 100,
      )
    }
  }, [])

  const handleAudioEnd = useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false)
      setCurrentTime(0)
      audioRef.current.currentTime = 0
    }
  }, [isPlaying])

  useEffect(() => {
    const ref = audioRef.current
    if (ref) {
      ref.addEventListener('timeupdate', handleTimeUpdate)
      ref.addEventListener('ended', handleAudioEnd)
    }

    return () => {
      if (ref) {
        ref.removeEventListener('timeupdate', handleTimeUpdate)
        ref.removeEventListener('ended', handleAudioEnd)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentTime])

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  const handleProgressChange = (value) => {
    const newTime = (value / 100) * audioRef.current.duration
    audioRef.current.currentTime = newTime
    setCurrentTime(newTime)
  }

  // const handleVolumeChange = (e) => {
  //   const newVolume = e.target.value / 100
  //   audioRef.current.volume = newVolume
  //   setVolume(newVolume)
  // }

  return (
    <div className={`flex md:gap-5 gap-2 w-full text-${color} ${className}`}>
      <audio ref={audioRef} src={audioUrl} onEnded={handleAudioEnd}></audio>

      {/* PLAY/PAUSE BUTTON */}
      <div
        onClick={togglePlay}
        className={`bg-transparent border-none text-kaiberGreen cursor-pointer text-xl px-0 pl-2 focus:outline-none`}
      >
        {isPlaying ? (
          <HiPause className={`text-kaiberGreen w-8 h-8`} />
        ) : (
          <HiPlay className={`text-kaiberGreen w-8 h-8`} />
        )}
      </div>

      <div className='flex flex-col w-full'>
        <SliderMusicPlayer
          sliderValue={localSliderValue}
          onProgressChange={handleProgressChange}
          currentTime={currentTime}
          audioUrl={audioUrl}
        />
      </div>

      {/* VOL. CONTROL */}
      {/* <div className='flex items-center w-fit'>
      <VolumeControl color={color} volume={volume} onVolumeChange={handleVolumeChange} />
    </div> */}
    </div>
  )
}
