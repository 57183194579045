import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function InviteSection({ registerLink }) {
  const features = []

  return (
    <div className='overflow-hidden bg-dark py-16 lg:py-24'>
      <div className='relative mx-auto max-w-xl lg:max-w-7xl'>
        <div className='relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8'>
          <div className='relative'>
            <h3 className='text-2xl font-bold tracking-tight text-tertiary sm:text-6xl'>
              Interested in making your own?
            </h3>
            <p className='mt-3 text-lg text-tertiary/80'>
              Create an account to try the fastest way to make video art for
              FREE.
            </p>

            <dl className='mt-10 space-y-10'>
              {features.map((item) => (
                <div key={item.id} className='relative'>
                  <dt>
                    <div className='absolute flex h-8 w-8 items-center justify-center rounded-full bg-secondary/10 text-white'>
                      <item.icon
                        className='h-4 w-4 text-secondary'
                        aria-hidden='true'
                      />
                    </div>
                    <p className='ml-16 text-lg font-medium leading-6 text-gray-200'>
                      {item.name}
                    </p>
                  </dt>
                </div>
              ))}
            </dl>
          </div>

          <div className='relative -mx-4 mt-10 lg:mt-0' aria-hidden='true'>
            <div className='p-12 bg-darkGray rounded-3xl space-y-4'>
              <Link
                to={registerLink}
                className='mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-primary hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
              >
                {/* Create Account */}
                Sign up for free{' '}
                <ArrowRightIcon
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    marginLeft: 10,
                  }}
                  width={15}
                  height={15}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
