import SectionTitle from '../welcome/SectionTitle'
import Button from '../welcome/Button'

function WorkWithUs() {
  return (
    <div className='flex flex-col flex-1 w-full items-center space-y-20'>
      <SectionTitle
        title='Want to work with us?'
        description={`We'd love to hear from you! Get in touch and share why you’re eager to make a creative impact with us.`}
      />
      <a href='mailto:hello@kaiber.ai'>
        <Button btnText='Contact us' />
      </a>
    </div>
  )
}

export default WorkWithUs
