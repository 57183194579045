import { Helmet } from 'react-helmet'

function RewardfullHead() {
  return (
    <Helmet>
      <script>{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful')`}</script>
      <script
        async
        src='https://r.wdfl.co/rw.js'
        data-rewardful='c3c082'
      ></script>
    </Helmet>
  )
}

export default RewardfullHead
