import { useEffect, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'

export const Waveform = ({ audioUrl }) => {
  const containerRef = useRef(null)
  const waveformRef = useRef(null)

  useEffect(() => {
    if (audioUrl && !waveformRef?.current) {
      waveformRef.current = WaveSurfer.create({
        container: containerRef.current,
        responsive: true,
        cursorWidth: 0,
        barWidth: 2,
        barHeight: 1,
        barGap: 6,
        height: 38,
        waveColor: '#415A4B',
        progressColor: '#415A4B',
      })
      waveformRef.current.load(audioUrl)
    } else if (!audioUrl) {
      waveformRef.current?.destroy()
      waveformRef.current = null
    }
  }, [audioUrl])

  return (
    <div className='grid items-center text-white z-[1]'>
      <div ref={containerRef} />
    </div>
  )
}
