import deepEqual from 'deep-equal'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import Lottie from 'lottie-react'

import AskEditSettingsModal from '../components/AskEditSettingsModal'
import AskForCreditsModal from '../components/AskForCreditsModal'
import FramesPreview from '../components/FramesPreview'
import InitialFilesUpload from '../components/InitialFilesUpload'
import Navbar from '../components/Navbar'
import { SceneDropdown } from '../components/SceneDropdown'
import VideoSettings from '../components/VideoSettings'
import { PromptInput } from '../components/PromptInput'
import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import {
  formatDuration,
  scrollToTop,
  isNonSubscribedUserWithCredits,
} from '../utils'
import {
  CURATED_CATEGORY_OPTIONS,
  IN_THE_STYLE_OF,
  MAX_NUMBER_OF_SCENES,
  STEPS,
  NONE,
  MIN_SCENE_LENGTH,
  MIN_LENGTH_FOR_ADDING_SCENE,
  DEFAULTS,
  MODEL_VERSION_MAX,
  MOTION,
  FLIPBOOK,
  TRANSFORM,
  VIDEO_TYPES_AND_VERSIONS,
  BETA_MOTION_V3,
  IMAGE_MIMETYPES,
  STABLE_DIFFUSION_CHECKPOINTS,
} from '../utils/constants'
import CtaToast from '../components/CtaToast'
import {
  getKeyframesFromAudioBuffer,
  getAudioBufferFromFile,
} from '../utils/audio_util.js'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import {
  getFirstFrame,
  convertDataURLToFile,
  generateVideoRequest,
  getFormattedTypeAndVersion,
} from '../utils/video_util'
import VideoTypeSelect from '../components/VideoTypeSelect'
import { getCurrentUser } from '../services/AuthService'
import { getCreditsCost } from '../utils/credits_util.js'
import { Logtail } from '@logtail/browser'
import { Base64 } from 'js-base64'

const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN)

const { v4: uuidv4 } = require('uuid')

const {
  STEP_1_UPLOAD_FILES,
  STEP_2_PROMPT_INPUT,
  STEP_3_VIDEO_SETTINGS,
  STEP_4_PREVIEW_FRAMES,
  STEP_5_SUMMARY_PAGE,
} = STEPS

const SHOW_BANNER = false

export const Creator = () => {
  const navigate = useNavigate()
  const isMounted = useRef(true)
  const [subject, setSubject] = useState(null)
  const [artStyle, setArtStyle] = useState([])
  const [artColor, setArtColor] = useState(null)
  const [artEmotion, setArtEmotion] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [curatedStyle, setCuratedStyle] = useState(null)
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [previewFrames, setPreviewFrames] = useState({})
  const [isMobile, setIsMobile] = useState(false)
  const [isLoadingMemoryId, setIsLoadingMemoryId] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [userProvidedStartingFrame, setUserProvidedStartingFrame] =
    useState(null)
  const [userProvidedStartingFrameS3Key, setUserProvidedStartingFrameS3Key] =
    useState(null)
  const [userProvidedAudioFile, setUserProvidedAudioFile] = useState(null)
  const [userProvidedAudioAudioUrl, setUserProvidedAudioAudioUrl] =
    useState(null)
  const [userProvidedAudioS3Url, setUserProvidedAudioS3Url] = useState(null)
  const [showAskEditSettingsModal, setShowAskEditSettingsModal] =
    useState(false)
  const [showAskForCreditsModal, setShowAskForCreditsModal] = useState(false)

  const [aspectRatio, setAspectRatio] = useState('9:16')
  const [creditsCost, setCreditsCost] = useState(8)
  const [totalCreditsCost, setTotalCreditsCost] = useState(0)

  const [promptSelectedValue, setPromptSelectedValue] = useState(null)
  const [memoryId, setMemoryId] = useState(null)
  const {
    currentUser,
    setCurrentUser,
    refreshCredits,
    featureToggles,
    refreshVideoCount,
  } = useUserAccountContext()
  const enableSaveDraft = featureToggles['enable-create-video-save-draft']
  const enableDefaultZeroEvolveForMotion3 =
    featureToggles['enable-motion-v3-default-evolve-set-to-zero']
  const enableDefaultStableDiffusionCheckpointPhotorealisticForMotion3 =
    featureToggles[
      'enable-motion-v3-set-default-stable-diffusion-checkpoint-to-photorealistic'
    ]

  const [step, setStep] = useState(1)

  const [scene] = useState('SCN 1')
  const [numberOfScenes, setNumberOfScenes] = useState(1)
  const [activeScene, setActiveScene] = useState(1)
  const [scenesData, setScenesData] = useState({})
  const [lastCompletedScene, setLastCompletedScene] = useState(1)
  const [audioBuffer, setAudioBuffer] = useState(null)
  const [userProvidedMediaLength, setUserProvidedMediaLength] = useState(null)
  const [userProvidedVideoFPS, setUserProvidedVideoFPS] = useState(null)
  const [previewFramesSettings, setPreviewFramesSettings] = useState({})
  const [didReuseSettings, setDidReuseSettings] = useState(false)
  const [reuseHasAudio, setReuseHasAudio] = useState(false)
  const [videoLength, setVideoLength] = useState(() => {
    if (
      !currentUser?.subscriptionTier &&
      currentUser?.credits &&
      currentUser?.credits > 0
    ) {
      return DEFAULTS.NONSUBSCRIBER_VIDEO_LENGTH
    }
    return DEFAULTS.VIDEO_LENGTH
  })
  const [videoStrength, setVideoStrength] = useState(DEFAULTS.VIDEO_STRENGTH)
  const [motionMagnitude, setMotionMagnitude] = useState(
    DEFAULTS.MOTION_MAGNITUDE,
  )
  const [animationKeyframes, setAnimationKeyframes] = useState(
    DEFAULTS.ANIMATION_KEYFRAME[NONE],
  )
  const [audioReactivity, setAudioReactivity] = useState(
    DEFAULTS.AUDIO_REACTIVITY,
  )

  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [makeBoomerangVideo, setMakeBoomerangVideo] = useState(false)
  const [
    showUserProvidedStartingFrameInFirstFrameOfVideo,
    setShowUserProvidedStartingFrameInFirstFrameOfVideo,
  ] = useState(true)
  const [cameraMovementNames, setCameraMovementNames] = useState([NONE])

  const [selectedFrame, setSelectedFrame] = useState(null)
  const [longVideoMaintenanceMode, setLongVideoMaintenanceMode] =
    useState(false)
  const [userProvidedVideoFile, setUserProvidedVideoFile] = useState(null)
  // Note, we only use this s3 key if the user is visiting the page by reusing settings
  // We opt to store the S3 key here because we don't MagicWandButtont the user to redownload the video every time they navigate to this page.
  const [userProvidedVideoFileS3Key, setUserProvidedVideoFileS3Key] =
    useState(null)
  const [audioFileName, setAudioFileName] = useState(null)
  const [isAudioProcessing, setIsAudioProcessing] = useState(false)
  const [abortController, setAbortController] = useState(false)
  const [disabledBack, setDisabledBack] = useState(false)

  const [cancelPreviewFrames, setCancelPreviewFrames] = useState(false) // allow user to cancel preview frames load by going to previous step
  // Create a mutable ref to store the cancelPreviewFrames value.
  // This is needed since we're trying to access the state from setTimeout()
  const cancelPreviewFramesRef = useRef(cancelPreviewFrames)

  const [sceneStartTimestamp, setSceneStartTimestamp] = useState(0)
  const [reusedSettingsMemoryId, setReusedSettingsMemoryId] = useState(null)
  const [isInterrogating, setIsInterrogating] = useState(false)

  const [minSceneLength, setMinSceneLength] = useState(null)
  const [showOnlyTimeLine, setShowOnlyTimeLine] = useState(false)
  const [createVideoSessionId, setCreateVideoSessionId] = useState(null)

  const [isImage, setIsImage] = useState(false)
  const [isVideo, setIsVideo] = useState(false)
  const [originalSubject, setOriginalSubject] = useState(null)
  const [selectedVideoType, setSelectedVideoType] = useState('')
  const [stepOneSection, setStepOneSection] = useState('videoType')
  const [inversionPrompt, setInversionPrompt] = useState(null)
  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)
  const [version, setVersion] = useState(1)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadingMedia, setIsUploadingMedia] = useState(false)

  const [autoSaveTrigger, setAutoSaveTrigger] = useState(0) // state to initiate save workflow
  const [isDraftExists, setIsDraftExists] = useState(false)
  const [sceneSwitchGeneratePreviews, setSceneSwitchGeneratePreviews] =
    useState(false)

  const [negativePrompt, setNegativePrompt] = useState(null)
  const [retainInitialImage, setRetainInitialImage] = useState(true)

  const [skippedPrompt, setSkippedPrompt] = useState(false)
  const [stableDiffusionCheckpoint, setStableDiffusionCheckpoint] = useState(
    Object.values(STABLE_DIFFUSION_CHECKPOINTS)[0],
  )
  const [mask, setMask] = useState(null)
  const [isLoadingPromptEditor, setIsLoadingPromptEditor] = useState(false)
  const [promptEditorErrorMessage, setPromptEditorErrorMessage] = useState(null)

  const canvas = useRef(null)

  const handleFinalMask = (mask) => {
    setMask(mask)
  }

  const maskUrl = useMemo(() => {
    if (mask) {
      return URL.createObjectURL(mask)
    }

    return null
  }, [mask])

  useEffect(() => {
    if (mask) {
      setRetainInitialImage(true)
    }
  }, [mask])

  const handleVersionChange = (value, resetStoryboard = null) => {
    // Reset storyboard if user change motion V2 to V3
    if (resetStoryboard) {
      deleteScenes()
    }

    if (selectedVideoType === MOTION && version === 2 && value === 3) {
      // Set video length to default for motion V3 when switching from V2
      handleLengthSlider(DEFAULTS.VIDEO_LENGTH)
    }

    setVersion(value)
    // Clear out preview frames as we might need a different number of frames for different versions
    setPreviewFrames({})
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }
  const isMotionV3Enabled = featureToggles[BETA_MOTION_V3.FLAG]
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  const typeAndVersion = getFormattedTypeAndVersion(selectedVideoType, version)

  const enabledBackTimeout = useRef(null)

  const canGenerateVideo = useMemo(() => {
    return (
      currentUser.subscriptionType === 'Standard' ||
      (currentUser?.credits && currentUser?.credits > 0)
    )
  }, [currentUser])

  const maxVideoUploadLength = useMemo(() => {
    if (
      currentUser?.subscriptionTier &&
      currentUser.subscriptionTier !== 'explorer_tier_monthly'
    ) {
      return 60 * 4
    }
    return 60
  }, [currentUser])

  const handleStableDiffusionCheckpointChange = (value) => {
    setStableDiffusionCheckpoint(value)
  }

  const maxLength = useMemo(() => {
    if (!currentUser?.subscriptionTier) {
      return 6
    }

    if (
      currentUser?.subscriptionTier &&
      currentUser.subscriptionTier !== 'explorer_tier_monthly'
    ) {
      if (selectedVideoType !== 'motion') {
        return 60 * 8
      } else if (typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V2) {
        return 160
      } else if (typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V3) {
        return 16
      }
    } else {
      if (selectedVideoType !== 'motion') {
        return 60
      } else {
        return 16
      }
    }
  }, [currentUser, selectedVideoType, typeAndVersion])

  const maxAudioLength = useMemo(() => {
    if (typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V3) {
      return 60 * 4
    } else {
      return maxLength
    }
  }, [maxLength, typeAndVersion])

  const selectFrame = (frameId) => {
    let newScenesData = { ...scenesData }
    newScenesData[activeScene - 1].selectedFrame = frameId
    setScenesData(newScenesData)
    setSelectedFrame(frameId)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const toggleAskEditSettingsModal = (isOpen) => {
    setShowAskEditSettingsModal(isOpen)
  }

  const handleAudioReactivityChange = (value) => {
    setAudioReactivity(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  // Function to check if user is on a mobile device
  const checkMobile = () => {
    const userAgent = window.navigator.userAgent
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    )
  }

  useEffect(() => {
    // show saved video prompt if it exists
    const settingsInBase64 = window.localStorage.getItem('SAVED_DRAFT')
    if (settingsInBase64) {
      const usingSettings = JSON.parse(Base64.decode(settingsInBase64))
      if (usingSettings) {
        setIsDraftExists(true)
      }
    }

    window.addEventListener('paddleCheckoutCompleted', async (data) => {
      try {
        const checkoutData = data.detail

        logtail.info(
          `${currentUser?.distinctId} felog Creator.js addEventListener('paddleCheckoutCompleted') Paddle iframe checkoutData`,
          { paddle: checkoutData },
        )
        logtail.flush()

        // store data into tempUser collection, route is /api/auth/paddle/checkout-completed
        http.post('/api/auth/paddle/checkout-completed', checkoutData)

        // confirm paddle transaction status
        if (checkoutData?.data?.transaction_id) {
          await http.post('/api/auth/paddle/confirm-transaction', {
            transactionId: checkoutData?.data?.transaction_id,
          })

          const response = await getCurrentUser()

          setCurrentUser((currentUser) => ({
            ...currentUser,
            ...response.data.user,
          }))
        } else {
          throw new Error('There was an error processing your request.')
        }
      } catch (err) {
        logtail.info(
          `${currentUser?.distinctId} felog Creator.js addEventListener('paddleCheckoutCompleted') error`,
          { user: currentUser, error: err },
        )
        logtail.flush()

        if (err.response) {
          toast.error(
            err.response.data?.message ||
              err.response.data?.error ||
              err.response.data,
          )
        } else {
          toast.error(err.message)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentUser])

  useEffect(() => {
    // Create a mutable ref to store the cancelPreviewFrames value for use in setTimeout()
    cancelPreviewFramesRef.current = cancelPreviewFrames
  }, [cancelPreviewFrames])

  const handleResize = useCallback(() => {
    // Check if user is on mobile on resize
    setIsMobile(checkMobile())
  }, [])

  // Use effect to add event listener for resize
  useEffect(() => {
    // Check if user is on mobile on first load
    const mobile = checkMobile()
    setIsMobile(mobile)
    // if (!mobile) {
    setSelectedCategory('subject')
    //}

    const checkMaintenanceMode = async () => {
      const maintenanceMode = await http.get('/api/long_video_maintenance_mode')
      setLongVideoMaintenanceMode(maintenanceMode.data)
    }

    checkMaintenanceMode()

    // remove anything that comes after the hashtag in the URL,
    // since we add steps in the URL like #prompt, if a user copy+pastes the URL we should remove it so they don't get stuck on a step
    window.history.replaceState(
      null,
      '',
      window.location.pathname + window.location.search,
    )
    // set back button history state for first step of Create Video workflow
    let historyState = { step: STEP_1_UPLOAD_FILES, scene: activeScene }
    window.history.pushState(historyState, STEP_1_UPLOAD_FILES, '')

    // Set temp Create video session ID (UUID)
    setCreateVideoSessionId(uuidv4())

    // Add event listener
    window.addEventListener('resize', handleResize)

    const handleHistoryChange = (event) => {
      // event.state.step contains the step the user just navigated to

      const mobile = checkMobile()

      if (cancelPreviewFrames) {
        setCancelPreviewFrames(false)
      }

      if (event.state?.step === STEP_2_PROMPT_INPUT) {
        if (mobile) {
          setSelectedCategory('subject')
        }
      }

      if (event.state?.step === STEP_3_VIDEO_SETTINGS) {
        // cancel step 4 preview frames polling (if running)
        setIsLoadingPreview(false)
        setDisabledBack(false)
        setCancelPreviewFrames(true)
      }

      // use the previous history stage to set the step and scene
      let backScene = 1
      if (event.state?.scene) {
        backScene = event.state?.scene
        setActiveScene(backScene)
      }
      setActiveScene(backScene)

      let backStep = 1
      if (event.state?.step) {
        backStep = event.state?.step
      }
      setStep(backStep)
      scrollToTop()
      setShowAlert(false)
      setShowSuccess(false)
    }
    window.addEventListener('popstate', handleHistoryChange)

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('popstate', handleHistoryChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResize])

  // Check when to update credit cost
  useEffect(() => {
    let updatedCreditsCost = 0
    if (userProvidedVideoFile) {
      updatedCreditsCost = videoLength * 5
    } else {
      updatedCreditsCost = videoLength
    }

    setCreditsCost(Math.max(updatedCreditsCost, 1))
  }, [videoLength, userProvidedVideoFile])

  const calculateMinSceneLength = useCallback(() => {
    let min = MIN_LENGTH_FOR_ADDING_SCENE
    // eslint-disable-next-line eqeqeq
    if (userProvidedMediaLength != null && userProvidedMediaLength != 0) {
      min = Math.ceil(userProvidedMediaLength * 0.1)
    }

    if (min < MIN_LENGTH_FOR_ADDING_SCENE) {
      min = MIN_LENGTH_FOR_ADDING_SCENE
    }

    setMinSceneLength(min)
  }, [userProvidedMediaLength])

  const handleCameraMovementSelect = useCallback(
    (names) => {
      setCameraMovementNames(names)

      let updatedAnimationKeyframes = { ...DEFAULTS.ANIMATION_KEYFRAME[NONE] }

      for (let name of names) {
        if (name === NONE) {
          break
        } else {
          const defaultKeyframe = DEFAULTS.ANIMATION_KEYFRAME[name]
          const keyframeName = Object.keys(defaultKeyframe)[0]

          updatedAnimationKeyframes[keyframeName] =
            defaultKeyframe[keyframeName]
        }
      }

      setAnimationKeyframes(updatedAnimationKeyframes)

      setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
    },
    [autoSaveTrigger],
  )

  useEffect(() => {
    return () => {
      //this stop the calls to pollForPreviewFrames if the user leaves the creator page
      isMounted.current = false
    }
  }, [])

  const pollForPreviewFrames = useCallback(
    async (memory_id) => {
      try {
        if (abortController?.token && isMounted.current) {
          const response = await http.get(`/api/poll_for_preview_frames`, {
            cancelToken: abortController.token,
            params: {
              memory_id,
              prompt: `${subject}, in the style of ${artStyle.join(', ')}`,
              subject: subject,
              originalSubject: originalSubject,
              style: artStyle.join(', '),
            },
          })

          if (response.data) {
            setIsLoadingPreview(false)
            setPreviewFrames(response.data.s3_images)
            setDisabledBack(false)
            setUserProvidedVideoFPS(response.data.fps)
            setPreviewFramesSettings({
              subject,
              artStyle,
              videoStrength,
              aspectRatio,
              curatedStyle,
              userProvidedStartingFrame,
              userProvidedVideoFile,
              version,
              negativePrompt,
            })
            if (enabledBackTimeout?.current)
              clearTimeout(enabledBackTimeout.current)
          } else {
            setTimeout(() => {
              pollForPreviewFrames(memory_id)
            }, 2000)
          }
        }
      } catch (err) {
        console.log(err)
        if (!http.isCancel(err)) {
          setShowAlert(true)
          setAlertMessage(err)
        }
      }
    },
    [
      abortController.token,
      artStyle,
      aspectRatio,
      curatedStyle,
      originalSubject,
      subject,
      userProvidedStartingFrame,
      userProvidedVideoFile,
      version,
      videoStrength,
      negativePrompt,
    ],
  )

  const getPreviewFrames = useCallback(async () => {
    try {
      setPreviewFrames({})
      setStep(STEP_4_PREVIEW_FRAMES)
      setIsLoadingPreview(true)
      setDisabledBack(true)
      setIsUploading(true)
      enabledBackTimeout.current = setTimeout(() => {
        setDisabledBack(false)
      }, 1000 * 60) // 60 sec

      const data = new FormData()

      if (userProvidedVideoFile) {
        data.append('userProvidedVideoFile', userProvidedVideoFile)
        data.append('processed_frame_timestamp', sceneStartTimestamp)
        data.append('height', videoHeight)
        data.append('width', videoWidth)
      }

      if (userProvidedVideoFileS3Key) {
        data.append('userProvidedVideoFileS3Key', userProvidedVideoFileS3Key)
      }

      if (userProvidedStartingFrame) {
        data.append('userProvidedStartingFrame', userProvidedStartingFrame)
      }

      data.append(
        'prompt',
        `${subject}, in the style of ${artStyle.join(', ')}`,
      )
      data.append('aspectRatio', aspectRatio)
      data.append('curatedStyle', curatedStyle)
      data.append('evolve', videoStrength)
      data.append('length', videoLength)
      data.append('email', currentUser.email)
      data.append('version', version)
      data.append('videoType', selectedVideoType)
      data.append('typeAndVersion', typeAndVersion) // eventually used by Sieve's monitoring system to track usage
      data.append('stableDiffusionCheckpoint', stableDiffusionCheckpoint)
      if (negativePrompt) {
        data.append('negativePrompt', negativePrompt)
      }

      let endpoint = '/api/get_preview_frames'

      if (typeAndVersion === VIDEO_TYPES_AND_VERSIONS.TRANSFORM_V2) {
        endpoint = '/api/transform/v2/get_preview_frames'
      }

      let dataJsonPayload = {}
      data.forEach((value, key) => {
        dataJsonPayload[key] = value
      })
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js getPreviewFrames() request ${endpoint}`,
        { user: currentUser, payload: dataJsonPayload },
      )
      logtail.flush()

      const response = await http.post(endpoint, data, {
        cancelToken: abortController.token,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.data?.images_from_fast_preview_server) {
        setIsLoadingPreview(false)
        setPreviewFrames(response.data.images_from_fast_preview_server)
        setDisabledBack(false)
        setUserProvidedVideoFPS(response.data.fps)
        setPreviewFramesSettings({
          subject,
          artStyle,
          videoStrength,
          aspectRatio,
          curatedStyle,
          userProvidedStartingFrame,
          userProvidedVideoFile,
          version,
        })
        if (enabledBackTimeout?.current)
          clearTimeout(enabledBackTimeout.current)
      } else {
        pollForPreviewFrames(response.data.identifier)
      }

      setMemoryId(response.data.identifier)
    } catch (err) {
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js getPreviewFrames() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()

      if (!http.isCancel(err)) {
        console.log(err)
        setIsLoadingPreview(false)
        setStep(STEP_2_PROMPT_INPUT)
        setDisabledBack(false)

        const errMessage =
          err.response?.data?.message ||
          err.response?.data?.error ||
          err.response?.data ||
          err.message ||
          'Something went wrong.'

        if (errMessage.includes('credits')) {
          setShowAskForCreditsModal(true)
        }

        toast.error(errMessage)
      }
    } finally {
      setIsUploading(false)
    }
    // selectedVideoType is currently left out of the dependency array - ticket to revisit https://height.app/M9jzUFTFNu/T-5386
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    abortController.token,
    artStyle,
    aspectRatio,
    curatedStyle,
    currentUser.email,
    pollForPreviewFrames,
    sceneStartTimestamp,
    subject,
    typeAndVersion,
    userProvidedStartingFrame,
    userProvidedVideoFile,
    userProvidedVideoFileS3Key,
    version,
    videoHeight,
    videoLength,
    videoStrength,
    videoWidth,
    negativePrompt,
  ])

  const updateCurrentSettingsToSceneSettings = useCallback(
    (sceneIndex, overrideSceneData = null) => {
      let currentSceneData = scenesData[sceneIndex]

      if (overrideSceneData) {
        currentSceneData = overrideSceneData
      }

      // We had a brief period where we were not storing subject/style in sceneData - let's parse it out of the prompt
      if (!currentSceneData.subject || !currentSceneData.style) {
        const promptParts = currentSceneData.prompt.split(', in the style of ')
        currentSceneData.subject = promptParts[0]
        currentSceneData.style = promptParts[1]
      }

      if (!currentSceneData.cameraMovementNames) {
        currentSceneData.cameraMovementNames = [NONE]
      }

      if (!currentSceneData.previewFrames) {
        currentSceneData.previewFrames = {}
      }

      setSubject(currentSceneData.subject)
      setArtStyle([currentSceneData.style])
      setVideoLength(currentSceneData.length)
      setVideoStrength(currentSceneData.evolve)
      setCuratedStyle(currentSceneData.curatedStyle)
      setCameraMovementNames(currentSceneData.cameraMovementNames)
      setAnimationKeyframes(currentSceneData.animationKeyframes)
      setSceneStartTimestamp(currentSceneData.timestamp)

      setPreviewFrames(currentSceneData.previewFrames)
      setPreviewFramesSettings({
        ...currentSceneData.previewFramesSettings,
        negativePrompt,
      })

      if (currentSceneData.selectedFrame) {
        setSelectedFrame(currentSceneData.selectedFrame)
      }

      if (currentSceneData.motionMagnitude) {
        setMotionMagnitude(currentSceneData.motionMagnitude)
      }

      if (currentSceneData.audioReactivity) {
        setAudioReactivity(currentSceneData.audioReactivity)
      }

      if (currentSceneData.negativePrompt) {
        setNegativePrompt(currentSceneData.negativePrompt)
      }

      // Atualize o custo de créditos para a cena atual
      let updatedCreditsCost = 0
      if (userProvidedVideoFile) {
        updatedCreditsCost = currentSceneData.length * 5
      } else {
        updatedCreditsCost = currentSceneData.length
      }
      currentSceneData.creditsCost = Math.max(updatedCreditsCost, 1)
      setAudioReactivity(currentSceneData.audioReactivity)

      if (
        step === STEP_4_PREVIEW_FRAMES &&
        !Object.keys(currentSceneData.previewFrames || {}).length
      ) {
        setSceneSwitchGeneratePreviews(true) // call useEffect() to generate preview frames
      }
    },
    [scenesData, step, userProvidedVideoFile, negativePrompt],
  )

  useEffect(() => {
    if (sceneSwitchGeneratePreviews) {
      // This is triggered when a user gets to Step 4 Preview Frames
      // without clicking on GENERATE PREVIEW FRAMES on Step 3.
      // AND if there was no preview frame saved for the current step.
      // This will force the preview frames to be generated.
      // This useEffect() triggered from updateCurrentSettingsToSceneSettings()
      getPreviewFrames()
      setSceneSwitchGeneratePreviews(false)
    }
  }, [getPreviewFrames, sceneSwitchGeneratePreviews])

  const saveActiveSceneData = useCallback(() => {
    const sceneData = {
      prompt: `${subject}, in the style of ${artStyle.join(', ')}`,
      subject,
      style: artStyle.join(', '),
      length: videoLength,
      evolve: videoStrength,
      aspectRatio,
      curatedStyle,
      cameraMovementNames: cameraMovementNames,
      animationKeyframes,
      audioReactivity,
      timestamp: sceneStartTimestamp,
      motionMagnitude,
      negativePrompt,
      previewFrames,
      selectedFrame,
      previewFramesSettings: {
        subject,
        artStyle,
        videoStrength,
        aspectRatio,
        curatedStyle,
        userProvidedStartingFrame,
        userProvidedVideoFile,
        version,
      },
    }
    const updatedScenesData = { ...scenesData }
    if (!updatedScenesData[activeScene - 1]) {
      updatedScenesData[activeScene - 1] = {}
    }
    Object.assign(updatedScenesData[activeScene - 1], sceneData)
    setScenesData(updatedScenesData)

    return updatedScenesData
  }, [
    activeScene,
    animationKeyframes,
    artStyle,
    aspectRatio,
    audioReactivity,
    cameraMovementNames,
    curatedStyle,
    motionMagnitude,
    negativePrompt,
    previewFrames,
    sceneStartTimestamp,
    scenesData,
    selectedFrame,
    subject,
    userProvidedStartingFrame,
    userProvidedVideoFile,
    version,
    videoLength,
    videoStrength,
  ])

  const handleSaveWorkflow = useCallback(async () => {
    if (enableSaveDraft) {
      const updatedScenesData = saveActiveSceneData()

      const workflowData = {
        prompt: `${subject}, in the style of ${artStyle.join(', ')}`,
        promptSubject: subject,
        promptArtStyle: artStyle,
        duration: 3,
        aspectRatio: aspectRatio,
        audioReactivity: null,
        cameraMovementName: cameraMovementNames,
        evolve: videoStrength,
        motionSlider: motionMagnitude,
        showFirstFrame: showUserProvidedStartingFrameInFirstFrameOfVideo,
        boomerang: makeBoomerangVideo,
        userProvidedStartingFrameUrl: userProvidedStartingFrameS3Key,
        userProvidedAudioFileUrl: userProvidedAudioS3Url,
        initVideoS3Key: userProvidedVideoFileS3Key,
        fps: userProvidedVideoFPS,
        vidWidth: videoWidth,
        vidHeight: videoHeight,
        scenes: updatedScenesData,
        videoType: selectedVideoType,
        version: version,
        memory_id: memoryId,
        initImage: selectedFrame,
      }
      console.log('Save Draft------')
      console.log(workflowData)
      const settingsInBase64 = Base64.encode(JSON.stringify(workflowData), true)
      window.localStorage.setItem('SAVED_DRAFT', settingsInBase64)
      setIsDraftExists(true)
    }
  }, [
    artStyle,
    aspectRatio,
    cameraMovementNames,
    makeBoomerangVideo,
    saveActiveSceneData,
    selectedVideoType,
    showUserProvidedStartingFrameInFirstFrameOfVideo,
    subject,
    userProvidedAudioS3Url,
    userProvidedStartingFrameS3Key,
    userProvidedVideoFileS3Key,
    userProvidedVideoFPS,
    videoWidth,
    videoHeight,
    version,
    videoStrength,
    motionMagnitude,
    memoryId,
    selectedFrame,
    enableSaveDraft,
  ])

  const handleLoadWorkflow = useCallback(async () => {
    if (enableSaveDraft) {
      const settingsInBase64 = window.localStorage.getItem('SAVED_DRAFT')

      if (settingsInBase64) {
        const usingSettings = JSON.parse(Base64.decode(settingsInBase64))
        console.log('Load Draft------')
        console.log(usingSettings)

        setDidReuseSettings(true)

        const {
          aspectRatio,
          audioReactivity,
          boomerang,
          cameraMovementName,
          duration,
          evolve,
          motionSlider,
          promptArtStyle,
          promptSubject,
          showFirstFrame,
          userProvidedStartingFrameUrl,
          userProvidedAudioFileUrl,
          initVideoS3Key,
          fps,
          vidWidth,
          vidHeight,
          scenes,
          videoType,
          version,
          memory_id,
          initImage,
          retainInitialImage,
          mask,
        } = usingSettings

        if (userProvidedStartingFrameUrl) {
          setUserProvidedStartingFrameS3Key(userProvidedStartingFrameUrl)
          const getSignedUrl = await http.get(`/api/get_signed_url`, {
            params: {
              asset: userProvidedStartingFrameUrl,
            },
          })
          const signedUrl = getSignedUrl.data.signedUrl
          createFileObjectFromUrl(signedUrl, IMAGE_MIMETYPES.PNG).then(
            (file) => {
              setUserProvidedStartingFrame(file)
              setIsImage(true)
            },
          )
        }

        if (userProvidedAudioFileUrl) {
          setUserProvidedAudioS3Url(userProvidedAudioFileUrl)
          setUserProvidedAudioAudioUrl(userProvidedAudioFileUrl)
          createFileObjectFromUrl(userProvidedAudioFileUrl).then((file) => {
            setUserProvidedAudioFile(file)
            setReuseHasAudio(true)
          })
          setUserProvidedMediaLength(duration)
        }

        if (initVideoS3Key) {
          setUserProvidedVideoFileS3Key(initVideoS3Key)

          http
            .get(`/api/get_signed_url`, {
              params: {
                asset: initVideoS3Key,
              },
            })
            .then((response) => {
              const signedUrl = response.data.signedUrl
              createFileObjectFromUrl(signedUrl, IMAGE_MIMETYPES.PNG).then(
                (file) => {
                  setUserProvidedVideoFile(file)
                  setPreviewFramesSettings({
                    ...scenes[0].previewFramesSettings,
                    userProvidedVideoFile: file,
                  })
                },
              )
            })

          setUserProvidedVideoFPS(fps)
          setUserProvidedMediaLength(duration)
          setIsVideo(true)
          setVideoHeight(vidHeight)
          setVideoWidth(vidWidth)
        }

        if (reusedSettingsMemoryId) {
          setReusedSettingsMemoryId(reusedSettingsMemoryId)
        }

        if (videoType) {
          setSelectedVideoType(videoType)
        }

        if (version) {
          setVersion(version)
        }
        setMakeBoomerangVideo(boomerang)

        if (initImage) {
          setSelectedFrame(initImage)
        }

        if (memory_id) {
          // previous draft generated preview frames which creates a memory_id in the DB
          setMemoryId(memory_id)
        }

        if (initImage) {
          setSelectedFrame(initImage)
        }

        if (mask) {
          try {
            createFileObjectFromUrl(mask)
              .then((file) => {
                setMask(file)
              })
              .catch((error) => {
                console.error(
                  'Error creating mask file object from URL:',
                  error,
                )
              })
          } catch (error) {
            console.error('Error processing mask:', error)
          }
        }

        if (scenes) {
          setNumberOfScenes(Object.keys(scenes).length)
          setScenesData(scenes)
          updateCurrentSettingsToSceneSettings(0, scenes[0])
          setOriginalSubject(scenes[0].subject)
          setAspectRatio(aspectRatio)
        } else {
          handleCameraMovementSelect([cameraMovementName])
          setVideoLength(duration)
          setVideoStrength(evolve)
          setSubject(promptSubject)
          setOriginalSubject(promptSubject)
          setPromptSelectedValue(promptSubject)
          setArtStyle([promptArtStyle])
          setShowUserProvidedStartingFrameInFirstFrameOfVideo(showFirstFrame)
          if (aspectRatio) {
            setAspectRatio(aspectRatio)
          }

          if (audioReactivity) {
            setAudioReactivity(audioReactivity)
          }

          if (CURATED_CATEGORY_OPTIONS.artStyle.includes(promptArtStyle)) {
            setCuratedStyle(promptArtStyle)
          }
        }

        if (motionSlider) {
          setMotionMagnitude(motionSlider)
        }

        if (retainInitialImage) {
          setRetainInitialImage(retainInitialImage)
        }

        setStep(STEP_2_PROMPT_INPUT)
        scrollToTop()
      }
    }
  }, [
    enableSaveDraft,
    handleCameraMovementSelect,
    reusedSettingsMemoryId,
    updateCurrentSettingsToSceneSettings,
  ])

  const handleClearWorkflow = () => {
    setIsDraftExists(false)
    window.localStorage.removeItem('SAVED_DRAFT')
  }

  useEffect(() => {
    calculateMinSceneLength()
  }, [calculateMinSceneLength])

  const handleSelectMakeBoomerangVideo = (checked) => {
    setMakeBoomerangVideo(checked)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleSelectShowUserProvidedStartingFrameInFirstFrameOfVideo = (
    checked,
  ) => {
    setShowUserProvidedStartingFrameInFirstFrameOfVideo(checked)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleRetainInitialImage = (checked) => {
    setRetainInitialImage(checked)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  useEffect(() => {
    let defaultModelVersion = MODEL_VERSION_MAX[selectedVideoType]
    if (selectedVideoType === MOTION && !isMotionV3Enabled) {
      defaultModelVersion = 2
    }

    if (!didReuseSettings) {
      setVersion(defaultModelVersion)
    }
  }, [selectedVideoType, isMotionV3Enabled, didReuseSettings])

  // Motion's 'CREATE VIDEO' TRIGGERS THIS
  const generateMotionVideo = async () => {
    try {
      setIsLoadingMemoryId(true)

      // If the user clicked "CREATE VIDEO" a non-last scene, we navigate them to the
      // next scene instead of allowing them to generate the video
      if (activeScene < numberOfScenes) {
        setSelectedCategory('subject')
        handleSetActiveScene(activeScene + 1)
        setIsLoadingMemoryId(false)
        setLastCompletedScene(Math.max(activeScene + 1, lastCompletedScene))
        setStep(STEP_2_PROMPT_INPUT)
        scrollToTop()
        return
      }

      // delete saved workflow
      handleClearWorkflow()

      const motionVideoData = {
        s3Images: JSON.stringify(previewFrames), // user_image
        userProvidedAudioFile, // user_audio
        audioReactivity: userProvidedAudioFile ? audioReactivity : null, // video_audio_reactivity
        length: videoLength, // video_duration
        aspectRatio, // video_aspect_ratio
        evolve: videoStrength, // video_evolve
        motionMagnitude, // video_motion
        reusedSettingsMemoryId, // video_settings_copied_from
        version, // video_version
        userSkipPrompt: skippedPrompt, // user_skip_prompt
        memoryId,
        videoType: selectedVideoType, //
        scenes: JSON.stringify(saveActiveSceneData()),
        userProvidedStartingFrame,
        originalPrompt: subject + IN_THE_STYLE_OF + artStyle.join(', '),
        subject,
        style: artStyle.join(', '),
        curatedStyle,
        negativePrompt,
        retainInitialImage,
        inversionPrompt,
        mask,
        stableDiffusionCheckpoint,
      }
      const response = await generateVideoRequest(motionVideoData)

      refreshCredits()

      window.sessionStorage.setItem('SHOW_SURVEY', 'true')
      navigate('/memory/' + response.data.memory_id)
    } catch (err) {
      console.error(err)
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js generateMotionVideo() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()

      const errMessage =
        err.response?.data?.error ||
        err.response?.data ||
        err.message ||
        'Something went wrong.'
      if (errMessage.includes('credits')) {
        setShowAskForCreditsModal(true)
      }

      toast.error(errMessage)
    } finally {
      setIsLoadingMemoryId(false)
    }
  }

  useEffect(() => {
    if (scenesData[activeScene - 1]) {
      // if scene already exists, set state to the existing scene
      updateCurrentSettingsToSceneSettings(activeScene - 1)
    } else if (activeScene > 1) {
      // Define variables to default value
      // We don't want to do this for scene 1 - the default values are already set and it will override our reused settings
      setVideoLength(DEFAULTS.VIDEO_LENGTH)
      setPreviewFrames({})
      setPreviewFramesSettings({})
      setAnimationKeyframes(DEFAULTS.ANIMATION_KEYFRAME[NONE])
      // if no video or audio, timestamp should be equal to the length of all previous scenes
      let previousSceneTimestamp = 0
      let previousSceneLength = 0
      if (activeScene > 1) {
        previousSceneTimestamp = scenesData[activeScene - 2].timestamp
        previousSceneLength = scenesData[activeScene - 2].length
      }
      let predictedSceneTimestamp = previousSceneTimestamp + previousSceneLength
      if (userProvidedMediaLength) {
        predictedSceneTimestamp = previousSceneTimestamp + 2
      }
      handleAudioTimestampChange(predictedSceneTimestamp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScene])

  const calculateTotalLength = useCallback(() => {
    let totalLength = 0
    for (let i = 0; i < numberOfScenes; i++) {
      const sceneData = scenesData[i]
      if (sceneData?.length) {
        totalLength += sceneData.length
      }
    }

    return totalLength
  }, [numberOfScenes, scenesData])

  const calculateMaxLength = () => {
    //calculate max length only for LengthSlider component
    let totalLength = 0
    if (numberOfScenes > 1) {
      for (let i = 0; i < numberOfScenes - 1; i++) {
        const sceneData = scenesData[i]
        if (sceneData?.length) {
          totalLength += sceneData.length
        }
      }
    }

    return totalLength
  }

  // Updates the credits cost and total credits cost when
  // any of the dependencies (videoLength, creditsCost, scenesData, activeScene) change.
  useEffect(() => {
    // Initialize a variable to hold the updated credits cost.
    let updatedCreditsCost = getCreditsCost(
      selectedVideoType,
      version,
      videoLength,
    )
    const totalLength = calculateTotalLength()
    let totalCreditsCost = getCreditsCost(
      selectedVideoType,
      version,
      totalLength,
    )

    // Update the credits cost state, ensuring it is at least 1.
    setCreditsCost(Math.max(updatedCreditsCost, 1))
    setTotalCreditsCost(totalCreditsCost)
  }, [
    videoLength,
    creditsCost,
    scenesData,
    activeScene,
    selectedVideoType,
    userProvidedVideoFile,
    calculateTotalLength,
    version,
  ])

  const handleSceneDropdownClick = () => {
    if (activeScene === 1 && needsToGeneratePreviewFrames()) {
      toast(
        <CtaToast
          description='Generate Preview Frames before switching storyboards.'
          cta='Click here to do so'
          onClick={() => {
            setStep(4)
            toast.dismiss()
            saveActiveSceneData()
            userProvidedStartingFrame
              ? confirmPreviewFrames(null)
              : getPreviewFrames()
          }}
        />,
        {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        },
      )
      // to prevent sceneDropdown open
      return false
    }
    // open sceneDropdown
    return true
  }

  const handleSetActiveScene = (scene) => {
    let hashtag = ''
    if (step === STEP_1_UPLOAD_FILES) {
      hashtag = ''
    } else if (step === STEP_2_PROMPT_INPUT) {
      hashtag = '#prompt'
    } else if (step === STEP_3_VIDEO_SETTINGS) {
      hashtag = '#settings'
    } else if (step === STEP_4_PREVIEW_FRAMES) {
      hashtag = '#preview'
    }
    let historyState = { step: step, scene: scene }
    window.history.pushState(historyState, step, hashtag)

    saveActiveSceneData()
    setActiveScene(scene)
  }

  const handleAddScene = () => {
    const sceneNumber = numberOfScenes + 1
    setSelectedCategory('subject')

    if (sceneNumber > MAX_NUMBER_OF_SCENES) return

    setNumberOfScenes(sceneNumber)
    handleSetActiveScene(sceneNumber)

    setLastCompletedScene(Math.max(sceneNumber, lastCompletedScene))

    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save

    setStep(STEP_2_PROMPT_INPUT)
    scrollToTop()
  }

  const handleDeleteCurrentScene = () => {
    if (activeScene === 1) return

    const sceneNumber = numberOfScenes - 1

    const currentSceneIndex = activeScene - 1

    const updatedScenesData = { ...scenesData }
    const currentScene = { ...updatedScenesData[currentSceneIndex] }
    delete updatedScenesData[currentSceneIndex]

    // we then need to shift all indices down by 1
    for (let i = currentSceneIndex + 1; i < numberOfScenes; i++) {
      //update scenes timestamps on scene delete
      let scene = updatedScenesData[i]
      let previousScene = null

      if (i === activeScene) {
        previousScene = updatedScenesData[currentSceneIndex - 1]
      } else {
        previousScene = updatedScenesData[i - 1]
      }
      scene.timestamp = previousScene.timestamp + previousScene.length
      updatedScenesData[i - 1] = scene
    }

    // if there is only one scene and user provided audio file, update the length of the scene to the length of the audio file
    if (
      sceneNumber === 1 &&
      (userProvidedAudioFile || userProvidedAudioS3Url)
    ) {
      updatedScenesData['0'].length =
        updatedScenesData['0'].length + currentScene.length
    }

    // delete the last scene as it will be duplicate
    delete updatedScenesData[numberOfScenes - 1]

    setScenesData(updatedScenesData)
    setNumberOfScenes(sceneNumber)
    setActiveScene(currentSceneIndex)
    setStep(STEP_2_PROMPT_INPUT)
  }

  const deleteScenes = () => {
    //delete all scenes except the first one
    const scene1 = { ...scenesData[0] }
    const updatedScenesData = { '0': scene1 }
    setScenesData(updatedScenesData)
    setNumberOfScenes(1)
    setActiveScene(1)
  }

  // TRANSFORM/FLIPBOOK'S 'CREATE VIDEO' TRIGGERS THIS
  const confirmPreviewFrames = async (preview_frame_id) => {
    try {
      setIsLoadingMemoryId(true)

      // If the user clicked "CREATE VIDEO" a non-last scene, we navigate them to the
      // next scene instead of allowing them to generate the video
      if (activeScene < numberOfScenes) {
        setSelectedCategory('subject')
        handleSetActiveScene(activeScene + 1)
        setIsLoadingMemoryId(false)
        setLastCompletedScene(Math.max(activeScene + 1, lastCompletedScene))
        setStep(STEP_2_PROMPT_INPUT)
        scrollToTop()
        return
      }

      const updatedScenesData = saveActiveSceneData() // force save the scenes again

      // Add audio animation keyframes for each scene
      if (userProvidedAudioFile) {
        for (let [scene_index, scene_data] of Object.entries(
          updatedScenesData,
        )) {
          const audioAnimationKeyframes = {}
          for (let camera_movement_key of scene_data.cameraMovementNames) {
            // get the sole key of expression_data
            const expression_data =
              DEFAULTS.ANIMATION_KEYFRAME_EXPRESSION[camera_movement_key]
            const movement_key = Object.keys(expression_data)[0]
            const expression = expression_data[movement_key]
            const keyframes = getKeyframesFromAudioBuffer(
              expression,
              scene_data.audioReactivity / DEFAULTS.AUDIO_REACTIVITY,
              audioBuffer,
              (error) => {
                toast.error(error)
                handleAudioFileChange(null)
              },
            )
            audioAnimationKeyframes[movement_key] = keyframes

            if (!keyframes) {
              return
            }
          }

          updatedScenesData[scene_index]['audioAnimationKeyframes'] =
            audioAnimationKeyframes
        }
      }

      // delete saved workflow
      handleClearWorkflow()

      const response = await generateVideoRequest({
        videoType: selectedVideoType,
        version,
        scenes: JSON.stringify(updatedScenesData),
        aspectRatio,
        memoryId,
        audioReactivity,
        initImage: preview_frame_id,
        showUserProvidedStartingFrameInFirstFrameOfVideo,
        userProvidedAudioFile,
        userProvidedStartingFrame,
        reusedSettingsMemoryId,
        inversionPrompt,
        createBoomerangVideo: makeBoomerangVideo,
        fps: userProvidedVideoFPS,
        userProvidedVideoFileS3Key,
        height: videoHeight,
        width: videoWidth,
        s3Images: JSON.stringify(previewFrames),
        evolve: videoStrength,
        negativePrompt,
        retainInitialImage,
        stableDiffusionCheckpoint,
      })

      refreshCredits()
      refreshVideoCount()

      window.sessionStorage.setItem('SHOW_SURVEY', 'true')
      navigate('/memory/' + response.data.memory_id)
    } catch (err) {
      console.error(err)
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js confirmPreviewFrames() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()

      const errMessage =
        err.response?.data?.error ||
        err.response?.data ||
        err.message ||
        'Something went wrong.'

      if (errMessage.includes('credits')) {
        setShowAskForCreditsModal(true)
      }

      toast.error(errMessage)
    } finally {
      setIsLoadingMemoryId(false)
    }
  }

  const interrogate = async (overrideSubjectField = true) => {
    try {
      setIsInterrogating(true)
      const data = new FormData()

      let file = null
      let interrogate_url = '/api/interrogate'

      if (typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V3) {
        interrogate_url = '/api/interrogate_gpt_vision_for_motion_v3'
      }

      if (isVideo) {
        let { thumbnailDataUrl } = await getFirstFrame(userProvidedVideoFile)
        file = await convertDataURLToFile(thumbnailDataUrl)
      }

      if (userProvidedStartingFrame || file) {
        const fileContent = new Blob(
          [userProvidedStartingFrame ? userProvidedStartingFrame : file],
          {
            type: userProvidedStartingFrame
              ? userProvidedStartingFrame.type
              : file.type,
          },
        )
        data.append('createVideoSessionId', createVideoSessionId)
        data.append('userProvidedStartingFrame', fileContent)
      }

      data.append('subject', subject)

      const interrogate_response = await http.post(interrogate_url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      const suggestedSubject = interrogate_response.data?.subject

      if (overrideSubjectField) {
        setSubject(suggestedSubject)
        setOriginalSubject(suggestedSubject)
      }

      setInversionPrompt(suggestedSubject)
    } catch (err) {
      console.error(err)
    } finally {
      setIsInterrogating(false)
    }
  }

  const handleCategorySelection = (category) => {
    setSelectedCategory(category)
    if (category === 'subject') {
      setPromptSelectedValue(subject)
    } else if (category === 'artStyle') {
      setPromptSelectedValue(artStyle.join(', '))
    }
  }

  const handleNextCategory = () => {
    switch (selectedCategory) {
      case 'subject':
        handleCategorySelection('artStyle')
        break
      case 'artStyle':
        handleNextStep()
        break
      default:
        break
    }
  }

  const handleGoBackToPrompt = () => {
    setStep(2)
    scrollToTop()
    setSelectedCategory('subject')
  }

  const handleGoToTimeline = () => {
    setStep(3)
    setShowOnlyTimeLine(true)
    scrollToTop()
    //setSelectedCategory('subject')
  }

  const uploadVideoFile = async (file) => {
    try {
      setIsUploadingMedia(true)
      const data = new FormData()
      data.append('userProvidedVideoFile', file)
      const endpoint = '/api/upload_asset'

      logtail.info(
        `${currentUser?.distinctId} felog Creator.js uploadVideoFile() request ${endpoint}`,
        { user: currentUser, payload: data },
      )
      logtail.flush()

      const response = await http.post(endpoint, data, {
        cancelToken: abortController.token,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setUserProvidedVideoFileS3Key(response.data.init_video_s3_key)
      setIsUploadingMedia(false)
    } catch (err) {
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js uploadVideoFile() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()
    }
  }

  const handleVideoFileChange = async (file) => {
    setUserProvidedVideoFileS3Key(null)
    setUserProvidedVideoFile(file)

    // Get the duration of the video
    if (file) {
      // upload video to R2
      uploadVideoFile(file)

      const video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src)
        const userProvidedVideoLength = Math.min(
          Math.max(Math.ceil(video.duration), 1),
          240,
        )
        setUserProvidedMediaLength(userProvidedVideoLength)
        setVideoLength(userProvidedVideoLength)
        setVideoHeight(video.videoHeight)
        setVideoWidth(video.videoWidth)
      }

      video.src = URL.createObjectURL(file)

      setUserProvidedVideoFile(file)
      handleVideoTypeChange(TRANSFORM)
      handleFileInputChange(null)
      handleAudioFileChange(null)
      setIsVideo(true)
      setIsImage(false)
    } else {
      setVideoLength(DEFAULTS.VIDEO_LENGTH)
      setUserProvidedMediaLength(null)
    }
  }

  const handleVideoFileChangeAndSave = async (file) => {
    handleVideoFileChange(file)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleVideoFileChangeAndClear = async (file) => {
    handleVideoFileChange(file)
    setIsDraftExists(false)
    window.localStorage.removeItem('SAVED_DRAFT')
  }

  const createFileObjectFromUrl = async (url) => {
    const response = await fetch(url)
    const blob = await response.blob()
    const filename = url.split('/').pop()
    return new File([blob], filename, {
      type: response.headers.get('content-type'),
    })
  }

  const handleFileInputChange = async (file) => {
    try {
      if (file) {
        setUserProvidedStartingFrame(file)
        if (
          selectedVideoType !== FLIPBOOK &&
          selectedVideoType !== MOTION &&
          selectedVideoType != null
        ) {
          handleAudioFileChange(null)
        }
        setIsImage(true)
        setIsVideo(false)

        // upload file to R2
        const data = new FormData()
        data.append('userProvidedStartingFrame', file)
        const endpoint = '/api/upload_asset'

        logtail.info(
          `${currentUser?.distinctId} felog Creator.js handleFileInputChange() request ${endpoint}`,
          { user: currentUser, payload: data },
        )
        logtail.flush()

        const response = await http.post(endpoint, data, {
          cancelToken: abortController.token,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response.data.user_provided_starting_frame_s3_key) {
          // save S3 URL
          setUserProvidedStartingFrameS3Key(
            response.data.user_provided_starting_frame_s3_key,
          )
          // convert to form file input object
          createFileObjectFromUrl(
            response.data.user_provided_starting_frame_s3_signed_url,
            IMAGE_MIMETYPES.PNG,
          ).then((file) => {
            setUserProvidedStartingFrame(file)
            setIsImage(true)
          })
        }
      } else {
        setMask(null)
        setUserProvidedStartingFrame(null)
      }
    } catch (err) {
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js handleFileInputChange() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()
    }
  }

  const handleFileInputChangeAndSave = async (file) => {
    await handleFileInputChange(file)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleFileInputChangeAndClear = async (file) => {
    handleFileInputChange(file)
    setIsDraftExists(false)
    window.localStorage.removeItem('SAVED_DRAFT')
  }

  const handleAudioFileChange = async (file) => {
    try {
      if (file) {
        setIsUploadingMedia(true)
        // upload audio to R2
        const data = new FormData()
        data.append('userProvidedAudioFile', file)
        const endpoint = '/api/upload_asset'

        logtail.info(
          `${currentUser?.distinctId} felog Creator.js handleAudioFileChange() request ${endpoint}`,
          { user: currentUser, payload: data },
        )
        logtail.flush()

        const response = await http.post(endpoint, data, {
          cancelToken: abortController.token,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response.data.user_provided_audio_url) {
          // set S3 URL for audio file
          setUserProvidedAudioS3Url(response.data.user_provided_audio_url)
          setIsUploadingMedia(false)
        }

        setUserProvidedAudioFile(file)
        setUserProvidedAudioAudioUrl(URL.createObjectURL(file)) // local URL for audio file asset
        if (
          selectedVideoType !== FLIPBOOK &&
          selectedVideoType !== MOTION &&
          selectedVideoType != null
        ) {
          handleFileInputChange(null)
        }
        handleVideoFileChange(null)
        setIsVideo(false)
      } else {
        setUserProvidedAudioFile(null)
        setUserProvidedMediaLength(null)
        setUserProvidedAudioAudioUrl(null)
        setVideoLength(DEFAULTS.VIDEO_LENGTH)
        setAudioBuffer(null)
      }
    } catch (err) {
      logtail.info(
        `${currentUser?.distinctId} felog Creator.js handleAudioFileChange() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()
    }
  }

  const handleAudioFileChangeAndSave = async (file) => {
    await handleAudioFileChange(file)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleAudioFileChangeAndClear = (file) => {
    handleAudioFileChange(file)
    setIsDraftExists(false)
    window.localStorage.removeItem('SAVED_DRAFT')
  }

  const handleLengthSlider = (value) => {
    setVideoLength(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleStrengthSlider = (value) => {
    setVideoStrength(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleMotionMagnitudeSlider = (value) => {
    setMotionMagnitude(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const handleAudioTimestampChange = (timestamp) => {
    // when a timestamp is adjusted for scene i
    // we need to adjust the timestamp and length of scene i-1 and the timestamp and length of scene i+1

    const updatedScenesData = saveActiveSceneData()
    const currentSceneIndex = activeScene - 1

    let sceneLength = DEFAULTS.VIDEO_LENGTH
    if (userProvidedMediaLength) {
      sceneLength = updatedScenesData[currentSceneIndex + 1]
        ? updatedScenesData[currentSceneIndex + 1].timestamp - timestamp
        : userProvidedMediaLength - timestamp
    }

    setSceneStartTimestamp(timestamp)
    setVideoLength(sceneLength)

    if (updatedScenesData[currentSceneIndex - 1]) {
      const previousScene = updatedScenesData[currentSceneIndex - 1]
      previousScene.length = timestamp - previousScene.timestamp

      updatedScenesData[currentSceneIndex - 1] = previousScene
    }

    setScenesData(updatedScenesData)
  }

  const handleTimestampChanges = (data) => {
    let newData = [...data]
    newData.unshift(0)
    let updatedScenesData = saveActiveSceneData()

    for (let i = 1; i < newData.length; i++) {
      const currentSceneIndex = i
      let timestamp = newData[i]
      let sceneLength = DEFAULTS.VIDEO_LENGTH
      if (userProvidedMediaLength) {
        sceneLength = updatedScenesData[currentSceneIndex + 1]
          ? updatedScenesData[currentSceneIndex + 1].timestamp - timestamp
          : userProvidedMediaLength - timestamp
      }

      updatedScenesData[i].timestamp = timestamp
      updatedScenesData[i].length = sceneLength

      if (activeScene - 1 === currentSceneIndex) {
        setSceneStartTimestamp(timestamp)
        setVideoLength(sceneLength)
      }

      if (updatedScenesData[currentSceneIndex - 1]) {
        const previousScene = updatedScenesData[currentSceneIndex - 1]
        previousScene.length = timestamp - previousScene.timestamp

        updatedScenesData[currentSceneIndex - 1] = previousScene
      }
    }

    setScenesData(updatedScenesData)
  }

  const handleAspectRatioSelect = (aspectRatio, _) => {
    setAspectRatio(aspectRatio)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  useEffect(() => {
    if (userProvidedAudioFile) {
      getAudioBufferFromFile(userProvidedAudioFile, (audioBuffer) => {
        setAudioBuffer(audioBuffer)
        const audioLength = Math.ceil(audioBuffer.duration)
        setUserProvidedMediaLength(Math.min(audioLength, maxAudioLength))

        // We only want to do this at the start of a create flow - otherwise this will mess up reuse settings
        if (numberOfScenes === 1) {
          setVideoLength(audioLength)
        }

        setIsAudioProcessing(false)
      })
    } else {
      setAnimationKeyframes(DEFAULTS.ANIMATION_KEYFRAME[NONE])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProvidedAudioFile])

  useEffect(() => {
    saveActiveSceneData()
    // TODO: Figure out why motion needs this to load scenesData
    // fails at preview selection step for motion otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneStartTimestamp, videoLength])

  useEffect(
    function initPromptEditor() {
      const loadFromSettings = async (memorySettings) => {
        setDidReuseSettings(true)

        if (!memorySettings) {
          return
        }

        const {
          aspectRatio,
          audioReactivity,
          boomerang,
          cameraMovementName,
          duration,
          evolve,
          motionMagnitude,
          promptArtStyle,
          promptSubject,
          showFirstFrame,
          userProvidedStartingFrameS3Key,
          userProvidedAudioFileUrl,
          initVideoS3Key,
          scenes,
          reusedSettingsMemoryId,
          videoType,
          version,
          fps,
          width,
          height,
          stableDiffusionCheckpoint,
          mask,
        } = memorySettings

        if (userProvidedStartingFrameS3Key) {
          setUserProvidedStartingFrameS3Key(userProvidedStartingFrameS3Key)
          http
            .get(`/api/get_signed_url`, {
              params: {
                asset: userProvidedStartingFrameS3Key,
              },
            })
            .then((response) => {
              const signedUrl = response.data.signedUrl
              createFileObjectFromUrl(signedUrl, IMAGE_MIMETYPES.PNG).then(
                (file) => {
                  setUserProvidedStartingFrame(file)
                  setIsImage(true)
                },
              )
            })
        }

        if (userProvidedAudioFileUrl) {
          setUserProvidedAudioS3Url(userProvidedAudioFileUrl)
          setUserProvidedAudioAudioUrl(userProvidedAudioFileUrl)
          createFileObjectFromUrl(userProvidedAudioFileUrl).then((file) => {
            setUserProvidedAudioFile(file)
            setReuseHasAudio(true)
          })
          setUserProvidedMediaLength(duration)
        }

        if (initVideoS3Key) {
          setUserProvidedVideoFileS3Key(initVideoS3Key)

          http
            .get(`/api/get_signed_url`, {
              params: {
                asset: initVideoS3Key,
              },
            })
            .then((response) => {
              const signedUrl = response.data.signedUrl
              createFileObjectFromUrl(signedUrl, IMAGE_MIMETYPES.PNG).then(
                (file) => {
                  setUserProvidedVideoFile(file)
                  setPreviewFramesSettings({
                    ...scenes[0].previewFramesSettings,
                    userProvidedVideoFile: file,
                  })
                },
              )
            })

          setUserProvidedVideoFPS(fps)
          setUserProvidedMediaLength(duration)
          setIsVideo(true)
          setVideoHeight(height)
          setVideoWidth(width)
        }

        if (reusedSettingsMemoryId) {
          setReusedSettingsMemoryId(reusedSettingsMemoryId)
        }

        if (videoType) {
          // TODO: We need to retroactively fix all these in the DB so that they match our proper naming conventions
          // Deprecate restylization type

          let updatedVideoType = videoType
          if (videoType === 'restylization') {
            updatedVideoType = TRANSFORM
          }
          setSelectedVideoType(updatedVideoType)
        }

        if (version) {
          setVersion(version)
        }

        if (scenes) {
          setNumberOfScenes(Object.keys(scenes).length)
          setScenesData(scenes)
          updateCurrentSettingsToSceneSettings(0, scenes[0])
          setMakeBoomerangVideo(boomerang)
          setOriginalSubject(scenes[0].subject)
        } else {
          setMakeBoomerangVideo(boomerang)
          handleCameraMovementSelect([cameraMovementName])
          setVideoLength(duration)
          setVideoStrength(evolve)
          setSubject(promptSubject)
          setOriginalSubject(promptSubject)
          setPromptSelectedValue(promptSubject)
          setArtStyle([promptArtStyle])
          setShowUserProvidedStartingFrameInFirstFrameOfVideo(showFirstFrame)

          if (audioReactivity) {
            setAudioReactivity(audioReactivity)
          }

          if (CURATED_CATEGORY_OPTIONS.artStyle.includes(promptArtStyle)) {
            setCuratedStyle(promptArtStyle)
          }
        }

        if (motionMagnitude) {
          setMotionMagnitude(motionMagnitude)
        }

        if (aspectRatio) {
          setAspectRatio(aspectRatio)
        }

        if (stableDiffusionCheckpoint) {
          setStableDiffusionCheckpoint(stableDiffusionCheckpoint)
        }

        if (mask) {
          try {
            createFileObjectFromUrl(mask)
              .then((file) => {
                setMask(file)
              })
              .catch((error) => {
                console.error(
                  'Error creating mask file object from URL:',
                  error,
                )
              })
          } catch (error) {
            console.error('Error processing mask:', error)
          }
        }

        setStep(STEP_2_PROMPT_INPUT)
        scrollToTop()
      }

      const promptEditorFlow = async (memory_id) => {
        try {
          setIsLoadingPromptEditor(true)
          const res = await http.get('/api/get_memory/' + memory_id)
          const memorySettings = res.data.memory.settingsUsed
          await loadFromSettings(memorySettings)
        } catch (error) {
          console.error('Failed to load prompt editor: ', error)
          // Error handling
          if (error.code === 'ERR_BAD_REQUEST') {
            setPromptEditorErrorMessage(
              'This memory is does not exist or is not available for editing.',
            )
            return
          }
          setPromptEditorErrorMessage(
            'Something wrong happened while opening the prompt editor. Please try again later.',
          )
        } finally {
          setIsLoadingPromptEditor(false)
        }
      }

      // Short circuit if the settings have been reused already
      if (didReuseSettings) {
        return
      }
      const query = new URLSearchParams(window.location.search)
      const memoryId = query.get('memory_id')
      // Stop prompt editor if there is no memory_id
      if (memoryId) {
        promptEditorFlow(memoryId)
      }

      const settingsQuery = query.get('settings')

      if (settingsQuery) {
        const usingSettings = JSON.parse(Base64.decode(settingsQuery))
        loadFromSettings(usingSettings)
      }

      if (memoryId) {
        promptEditorFlow(memoryId)
      }
    },
    [
      didReuseSettings,
      updateCurrentSettingsToSceneSettings,
      handleCameraMovementSelect,
    ],
  )

  useEffect(
    function promptEditorErrorMessageHandler() {
      if (promptEditorErrorMessage !== null) {
        toast.error(promptEditorErrorMessage, {
          toastId: 'prompt-editor-error-message',
        })
        setPromptEditorErrorMessage(null)
      }
    },
    [promptEditorErrorMessage],
  )

  const needsToGeneratePreviewFrames = () => {
    if (previewFrames && Object.keys(previewFrames).length === 0) {
      return true
    }

    if (
      deepEqual(
        previewFramesSettings,
        {
          subject,
          artStyle,
          videoStrength,
          aspectRatio,
          curatedStyle,
          userProvidedStartingFrame,
          userProvidedVideoFile,
          version,
          //negativePrompt, // will need to re-add
        },
        { strict: true },
      )
    ) {
      return false
    }

    return true
  }

  const handleNextStep = () => {
    if (showOnlyTimeLine) {
      setShowOnlyTimeLine(false)
    }
    if (step === STEP_1_UPLOAD_FILES) {
      let historyState = { step: STEP_2_PROMPT_INPUT, scene: activeScene }
      window.history.pushState(historyState, STEP_2_PROMPT_INPUT, '#prompt')
      handleClearWorkflow()
    }

    if (step === STEP_2_PROMPT_INPUT) {
      let historyState = { step: STEP_3_VIDEO_SETTINGS, scene: activeScene }
      window.history.pushState(historyState, STEP_3_VIDEO_SETTINGS, '#settings')

      if (!subject && !artStyle.length) {
        if (selectedVideoType === MOTION && userProvidedStartingFrame) {
          skipPrompt()
          return
        }
        // || !artStyle.length
        if (!subject) handleCategorySelection('subject')
        else handleCategorySelection('artStyle')
        setAlertMessage('You must select a subject and art style')
        setShowAlert(true)
        return
      }
    }

    if (step === STEP_3_VIDEO_SETTINGS) {
      let historyState = { step: STEP_4_PREVIEW_FRAMES, scene: activeScene }
      window.history.pushState(historyState, STEP_4_PREVIEW_FRAMES, '#preview')

      if (selectedVideoType !== MOTION) {
        if (selectedVideoType === TRANSFORM) {
          if (!userProvidedVideoFile && !userProvidedVideoFileS3Key) {
            toast.error('You must upload a video for Transform.')
            return
          }

          if (
            typeAndVersion === VIDEO_TYPES_AND_VERSIONS.TRANSFORM_V2 &&
            !userProvidedVideoFPS
          ) {
            setUserProvidedVideoFPS(DEFAULTS.FPS)
          }
        }

        if (needsToGeneratePreviewFrames()) {
          // save scene data first then proceed to STEP_4_PREVIEW_FRAMES
          setCancelPreviewFrames(false)
          toast.dismiss()
          saveActiveSceneData()
          getPreviewFrames()

          scrollToTop()
          setShowAlert(false)
          setShowSuccess(false)
          setStep(STEP_4_PREVIEW_FRAMES)
          return
        }

        scrollToTop()
        setStep(STEP_4_PREVIEW_FRAMES)
        return
      } else {
        if (videoStrength === 0 && userProvidedStartingFrame) {
          generateMotionVideo()
        } else {
          scrollToTop()
          getPreviewFrames()
        }
        return
      }
    }

    if (step === STEP_4_PREVIEW_FRAMES) {
      let historyState = { step: STEP_5_SUMMARY_PAGE, scene: activeScene }
      window.history.pushState(historyState, STEP_5_SUMMARY_PAGE, '#summary')
    }

    let nextStep = step + 1

    setStep(nextStep)
    scrollToTop()
    setShowAlert(false)
    setShowSuccess(false)
  }

  const handlePrevStep = () => {
    if (disabledBack) return
    let prevStep = step - 1
    if (isLoadingPreview) abortController.cancel('aborted by user')
    setIsLoadingPreview(false)
    setStep(prevStep)
    scrollToTop()
  }

  const handleVideoTypeChange = (value) => {
    if (value != null) {
      setSelectedVideoType(value)
      setVersion(MODEL_VERSION_MAX[value])
      setStepOneSection('uploadMedia')

      if (value === MOTION) {
        if (userProvidedVideoFile) {
          handleVideoFileChange(null)
          handleAudioFileChange(null)
        }

        if (enableDefaultZeroEvolveForMotion3) {
          setVideoStrength(0)
        } else {
          setVideoStrength(4)
        }

        if (enableDefaultStableDiffusionCheckpointPhotorealisticForMotion3) {
          setStableDiffusionCheckpoint(
            STABLE_DIFFUSION_CHECKPOINTS.Photorealistic,
          )
        } else {
          setStableDiffusionCheckpoint(STABLE_DIFFUSION_CHECKPOINTS.Animated)
        }
      }

      if (value === TRANSFORM) {
        if (userProvidedAudioFile || userProvidedStartingFrame) {
          handleFileInputChange(null)
          handleAudioFileChange(null)
        }
      }
    }
  }

  const backToVideoTypeSection = () => {
    setStepOneSection('videoType')
  }

  useEffect(() => {
    if (!isLoadingPreview) {
      const source = http.CancelToken.source()
      setAbortController(source)
    }
    //setStep(4)
  }, [isLoadingPreview])

  function handleConfirmPreviewFrames() {
    if (selectedVideoType !== MOTION) {
      confirmPreviewFrames(selectedFrame)
    } else if (selectedVideoType === MOTION) {
      generateMotionVideo(selectedFrame)
    }
  }

  const setAndSaveSubject = (value) => {
    setSubject(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const setAndSaveArtStyle = (value) => {
    setArtStyle(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  const setAndSaveNegativePrompt = (value) => {
    setNegativePrompt(value)
    setAutoSaveTrigger(autoSaveTrigger + 1) // initiate auto-save
  }

  // autosave
  useEffect(() => {
    if (autoSaveTrigger > 0) {
      handleSaveWorkflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSaveTrigger])

  useEffect(() => {
    if (skippedPrompt) {
      handleNextStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skippedPrompt])

  const canAddScene = () => {
    if (typeAndVersion === VIDEO_TYPES_AND_VERSIONS.TRANSFORM_V2) {
      return false
    } else if (userProvidedMediaLength) {
      return (
        scenesData[Object.keys(scenesData).length - 1]?.timestamp +
          minSceneLength <
        userProvidedMediaLength
      )
    } else {
      return calculateTotalLength() + 3 < maxLength
    }
  }

  const skipPrompt = () => {
    setSubject(',')
    setArtStyle([','])
    setPromptSelectedValue(',')
    setVideoStrength(0)
    setSkippedPrompt(true)
  }

  return (
    <div className='min-h-full sm:pb-24'>
      <Navbar showBanner={SHOW_BANNER} />
      <div className='justify-center h-full px-5 py-10 bg-dark '>
        <div
          className={classNames(
            'm-auto sm:space-y-8 mx-auto max-w-7xl mt-20 lg:mt-28 relative',
            {
              'opacity-50 pointer-events-none select-none': !canGenerateVideo,
              'mt-32 lg:mt-36': isFeedbackBannerShown,
            },
          )}
        >
          {/* SCENE DROPDOWN */}
          {step !== STEP_1_UPLOAD_FILES && !isLoadingMemoryId && (
            <div
              className={`${
                !isMobile && 'absolute'
              } top-[40px] sm:top-[36px] right-[24px] md:right-0 lg:right-[24px]`}
            >
              <SceneDropdown
                numberOfScenes={numberOfScenes}
                activeScene={activeScene}
                setActiveScene={handleSetActiveScene}
                lastCompletedScene={lastCompletedScene}
                onTopLevelClick={handleSceneDropdownClick}
                onDelete={handleDeleteCurrentScene}
                isLoadingPreview={isLoadingPreview}
              />
            </div>
          )}

          {/* STEP 1 */}
          {!isLoadingPreview && (
            <div className='flex grid flex-col items-center justify-center grid-cols-1'>
              {step === STEP_1_UPLOAD_FILES && (
                <div className='flex flex-col items-center justify-center'>
                  {!isLoadingPromptEditor && (
                    <>
                      <VideoTypeSelect
                        stepOneSection={stepOneSection}
                        selectedVideoType={selectedVideoType}
                        handleVideoTypeChange={handleVideoTypeChange}
                        isMobile={isMobile}
                        image={userProvidedStartingFrame}
                        video={userProvidedVideoFile}
                        audio={userProvidedAudioFile}
                        showTemplates={isMotionV3Enabled}
                      />
                      <InitialFilesUpload
                        handleAudioFileChange={handleAudioFileChangeAndClear}
                        handleFileInputChange={handleFileInputChangeAndClear}
                        handleVideoFileChange={handleVideoFileChangeAndClear}
                        handleNextStep={handleNextStep}
                        setAlertMessage={setSuccessMessage}
                        setShowAlert={setShowSuccess}
                        audio={userProvidedAudioFile}
                        audioUrl={userProvidedAudioAudioUrl}
                        image={userProvidedStartingFrame}
                        video={userProvidedVideoFile}
                        step={step}
                        videoS3Key={userProvidedVideoFileS3Key}
                        isAudioProcessing={isAudioProcessing}
                        setIsAudioProcessing={setIsAudioProcessing}
                        audioFileName={audioFileName}
                        setAudioFileName={setAudioFileName}
                        longVideoMaintenanceMode={longVideoMaintenanceMode}
                        disabled={!canGenerateVideo}
                        maxAudioUploadLength={maxAudioLength}
                        showMaxAudioUploadLength={
                          typeAndVersion !== VIDEO_TYPES_AND_VERSIONS.MOTION_V3
                        }
                        maxVideoUploadLength={maxVideoUploadLength}
                        didReuseSettings={didReuseSettings}
                        allowAudioUpload={
                          selectedVideoType === FLIPBOOK ||
                          typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V3
                        }
                        allowVideoUpload={selectedVideoType === TRANSFORM}
                        allowImageUpload={selectedVideoType !== TRANSFORM}
                        stepOneSection={stepOneSection}
                        selectedVideoType={selectedVideoType}
                        handleVideoTypeChange={handleVideoTypeChange}
                        backToVideoTypeSection={backToVideoTypeSection}
                      />

                      {stepOneSection === 'videoType' &&
                        !selectedVideoType &&
                        isDraftExists === true &&
                        enableSaveDraft === true && (
                          <button
                            className='text-[#939393] text-[18px] mb-[80px] border-b-[1px] border-[#939393]'
                            onClick={handleLoadWorkflow}
                          >
                            or continue from previous draft
                          </button>
                        )}
                    </>
                  )}

                  {isLoadingPromptEditor && (
                    <div className='flex justify-center py-40'>
                      <div className='flex flex-col items-center space-y-4'>
                        <div className='p-6 bg-darkGray rounded-3xl'>
                          <Lottie
                            animationData={LoadingWhite}
                            loop={true}
                            className='w-16 h-16'
                          />
                        </div>
                        <h2 className='pt-8 text-tertiary'>
                          Preparing your prompt editor...
                        </h2>
                        <p className='mt-4 text-tertiary/60'>
                          This could take a few seconds.{'\n'}
                          Please wait...
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* STEP 2 */}
              {step === STEP_2_PROMPT_INPUT && (
                <PromptInput
                  subject={subject}
                  artStyle={artStyle}
                  artColor={artColor}
                  negativePrompt={negativePrompt}
                  artEmotion={artEmotion}
                  activeScene={activeScene}
                  setSubject={setAndSaveSubject}
                  setOriginalSubject={setOriginalSubject}
                  setNegativePrompt={setAndSaveNegativePrompt}
                  setArtStyle={setAndSaveArtStyle}
                  setArtColor={setArtColor}
                  setArtEmotion={setArtEmotion}
                  setCuratedStyle={setCuratedStyle}
                  setPromptSelectedValue={setPromptSelectedValue}
                  handleAudioFileChange={handleAudioFileChangeAndSave}
                  handleFileInputChange={handleFileInputChangeAndSave}
                  handleVideoFileChange={handleVideoFileChangeAndSave}
                  audioFileName={audioFileName}
                  setAudioFileName={setAudioFileName}
                  userProvidedAudioFile={userProvidedAudioFile}
                  userProvidedAudioAudioUrl={userProvidedAudioAudioUrl}
                  reuseHasAudio={reuseHasAudio}
                  userProvidedVideoFile={userProvidedVideoFile}
                  isAudioProcessing={isAudioProcessing}
                  setIsAudioProcessing={setIsAudioProcessing}
                  userProvidedStartingFrame={userProvidedStartingFrame}
                  longVideoMaintenanceMode={longVideoMaintenanceMode}
                  maxLength={maxLength}
                  maxAudioUploadLength={maxAudioLength}
                  didReuseSettings={didReuseSettings}
                  selectedCategory={selectedCategory}
                  handleNextStep={handleNextStep}
                  handleNextCategory={handleNextCategory}
                  getPreviewFrames={getPreviewFrames}
                  isLoadingMemoryId={isLoadingMemoryId}
                  totalCreditsCost={totalCreditsCost}
                  promptSelectedValue={promptSelectedValue}
                  showAlert={showAlert}
                  alertMessage={alertMessage}
                  showSucces={showSuccess}
                  successMessage={successMessage}
                  setShowAlert={setShowAlert}
                  setAlertMessage={setAlertMessage}
                  setShowSuccess={setShowSuccess}
                  interrogate={interrogate}
                  isInterrogating={isInterrogating}
                  maxVideoUploadLength={maxVideoUploadLength}
                  isImage={isImage}
                  isVideo={isVideo}
                  videoType={selectedVideoType}
                  typeAndVersion={typeAndVersion}
                  skipPrompt={skipPrompt}
                  imageMaskOverlay={maskUrl}
                />
              )}

              {/* STEP 3 */}
              {step === STEP_3_VIDEO_SETTINGS && !isLoadingMemoryId && (
                <VideoSettings
                  motionMagnitude={motionMagnitude}
                  handleMotionMagnitudeSlider={handleMotionMagnitudeSlider}
                  handleVersionChange={handleVersionChange}
                  version={version}
                  handleAudioFileChange={handleAudioFileChange}
                  handleFileInputChange={handleFileInputChange}
                  handleNextCategory={handleNextCategory}
                  handleNextStep={handleNextStep}
                  handlePrevStep={handlePrevStep}
                  handleGoBackToPrompt={handleGoBackToPrompt}
                  setAlertMessage={setSuccessMessage}
                  setShowAlert={setShowSuccess}
                  audio={userProvidedAudioFile}
                  audioUrl={userProvidedAudioAudioUrl}
                  audioFileName={audioFileName}
                  isAudioProcessing={isAudioProcessing}
                  setIsAudioProcessing={setIsAudioProcessing}
                  setAudioFileName={setAudioFileName}
                  image={userProvidedStartingFrame}
                  handleAspectRatioSelect={handleAspectRatioSelect}
                  userProvidedStartingFrame={userProvidedStartingFrame}
                  handleLengthSlider={handleLengthSlider}
                  handleStrengthSlider={handleStrengthSlider}
                  hasAudio={!!userProvidedAudioFile}
                  hasVideo={
                    userProvidedVideoFile != null ||
                    userProvidedVideoFileS3Key != null
                  }
                  handleCameraMovementSelect={handleCameraMovementSelect}
                  handleSelectMakeBoomerangVideo={
                    handleSelectMakeBoomerangVideo
                  }
                  length={videoLength}
                  formatDuration={formatDuration}
                  strength={videoStrength}
                  aspectRatio={aspectRatio}
                  scene={scene}
                  isMobile={isMobile}
                  cameraMovementNames={cameraMovementNames}
                  makeBoomerangVideo={makeBoomerangVideo}
                  creditsCost={totalCreditsCost}
                  longVideoMaintenanceMode={longVideoMaintenanceMode}
                  showUserProvidedStartingFrameInFirstFrameOfVideo={
                    showUserProvidedStartingFrameInFirstFrameOfVideo
                  }
                  handleSelectShowUserProvidedStartingFrameInFirstFrameOfVideo={
                    handleSelectShowUserProvidedStartingFrameInFirstFrameOfVideo
                  }
                  retainInitialImage={retainInitialImage}
                  handleRetainInitialImage={handleRetainInitialImage}
                  handleAudioReactivityChange={handleAudioReactivityChange}
                  artStyle={artStyle}
                  subject={subject}
                  hasNextStep={true}
                  activeScene={activeScene}
                  numberOfScenes={numberOfScenes}
                  scenes={scenesData}
                  selectedFrame={scenesData[activeScene - 1]?.selectedFrame}
                  userProvidedMediaLength={userProvidedMediaLength}
                  sceneStartTimestamp={sceneStartTimestamp}
                  handleAudioTimestampChange={handleAudioTimestampChange}
                  handleTimestampChanges={handleTimestampChanges}
                  minSceneStartTimestamp={
                    scenesData[activeScene - 2]?.timestamp + MIN_SCENE_LENGTH
                  }
                  maxSceneStartTimestamp={
                    scenesData[activeScene]
                      ? scenesData[activeScene].timestamp - MIN_SCENE_LENGTH
                      : userProvidedMediaLength - MIN_SCENE_LENGTH
                  }
                  lengthMaxDuration={Math.max(
                    0,
                    maxLength - calculateMaxLength(),
                  )}
                  lengthMinDuration={3}
                  lengthStep={1}
                  audioReactivity={audioReactivity}
                  showOnlyTimeLine={showOnlyTimeLine}
                  videoType={selectedVideoType}
                  maxLength={maxLength}
                  maxAudioUploadLength={maxAudioLength}
                  typeAndVersion={typeAndVersion}
                  isImage={isImage}
                  didReuseSettings={didReuseSettings}
                  reuseHasAudio={reuseHasAudio}
                  canvasRef={canvas}
                  handleStableDiffusionCheckpointChange={
                    handleStableDiffusionCheckpointChange
                  }
                  stableDiffusionCheckpoint={stableDiffusionCheckpoint}
                  onFinalMask={handleFinalMask}
                  mask={mask}
                  imageMaskOverlay={maskUrl}
                />
              )}
            </div>
          )}

          {/* STEP 4 - after previews generated */}
          {step === STEP_4_PREVIEW_FRAMES && !isLoadingMemoryId && (
            <FramesPreview
              memoryId={memoryId}
              typeAndVersion={typeAndVersion}
              handleDeleteCurrentScene={handleDeleteCurrentScene}
              handleGoBackToPrompt={handleGoBackToPrompt}
              previewFrames={previewFrames}
              isLoadingMemoryId={isLoadingMemoryId}
              prompt={subject + IN_THE_STYLE_OF + artStyle.join(', ')}
              selectedFrame={scenesData[activeScene - 1]?.selectedFrame}
              selectFrame={selectFrame}
              handleConfirmPreviewFrames={handleConfirmPreviewFrames}
              artStyle={artStyle}
              subject={subject}
              activeScene={activeScene}
              isMobile={isMobile}
              creditsCost={totalCreditsCost}
              setActiveScene={setActiveScene}
              numberOfScenes={numberOfScenes}
              lastCompletedScene={lastCompletedScene}
              length={videoLength}
              aspectRatio={aspectRatio}
              cameraMovementNames={cameraMovementNames}
              onAddStoryboard={handleAddScene}
              timestamp={sceneStartTimestamp}
              canAddScene={canAddScene()}
              audioUrl={userProvidedAudioAudioUrl}
              showAspectRatio={
                !userProvidedVideoFile && !userProvidedStartingFrame
              }
              isLoadingPreview={isLoadingPreview}
              handleNextCategory={handleGoToTimeline}
              handleNextCategoryMobile={handleGoToTimeline}
              hasAudio={!!userProvidedAudioFile}
              hasVideo={!!userProvidedVideoFile}
              hasImage={!!userProvidedStartingFrame}
              isNonSubscribedUserWithCredits={isNonSubscribedUserWithCredits(
                currentUser,
              )}
              selectedVideoType={selectedVideoType}
              allowSkipPreviewFrames={false}
              isUploading={isUploading}
              videoFPS={userProvidedVideoFPS}
              isUploadingMedia={isUploadingMedia}
            />
          )}

          {isLoadingMemoryId && (
            <div className='flex justify-center py-40'>
              <div className='flex flex-col items-center space-y-4'>
                <div className='p-6 bg-darkGray rounded-3xl'>
                  <Lottie
                    animationData={LoadingWhite}
                    loop={true}
                    className='w-16 h-16'
                  />
                </div>
                <h2 className='pt-8 text-tertiary'>
                  Preparing your generation...
                </h2>
                <p className='mt-4 text-tertiary/60'>
                  This could take a few moments.{'\n'}
                  Please wait...
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <AskEditSettingsModal
        handleEditSettings={() => {
          setStep(3)
          toast.dismiss()
          toggleAskEditSettingsModal(false)
        }}
        handleGenerateVideo={() => {
          setStep(4)
          toast.dismiss()
          saveActiveSceneData()

          if (needsToGeneratePreviewFrames()) {
            getPreviewFrames()
          }
          toggleAskEditSettingsModal(false)
        }}
        isOpen={showAskEditSettingsModal}
        onClose={toggleAskEditSettingsModal}
      />

      <AskForCreditsModal
        isOpen={showAskForCreditsModal}
        onClose={setShowAskForCreditsModal}
      />
    </div>
  )
}
