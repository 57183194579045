import { SVGProps } from '@/types'

export const ChevronDownIcon = ({
  color = 'white',
  size = 20,
  ...props
}: SVGProps) => (
  <svg
    stroke='currentColor'
    fill='none'
    height={size}
    width={size}
    viewBox='0 0 8 9'
    {...props}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.3335 3.16663L3.52876 5.61549C3.7891 5.90589 4.21123 5.90589 4.47156 5.61549L6.66683 3.16663'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
