import { evaluate } from 'mathjs'

export const getAudioBufferFromFile = (file, onSuccess) => {
  if (file) {
    const reader = new FileReader()
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    reader.readAsArrayBuffer(file)

    reader.onload = function () {
      const arrayBuffer = reader.result
      audioContext.decodeAudioData(arrayBuffer, onSuccess)
    }
  }
}

export const getKeyframesFromAudioBuffer = (
  expression,
  audioReactivityMultiplier,
  audioBuffer,
  onError,
) => {
  function getString(arr) {
    let string = ''
    for (let ind of Object.keys(arr)) {
      let sample = arr[ind]
      string = string.concat(`${ind}: (${parseFloat(sample).toFixed(3)})`)
      if (parseInt(ind) < parseInt(arr.length - 1)) {
        string = string.concat(', ')
      }
    }
    return string
  }

  // Average between channels. Take abs so we don't have phase issues (and we eventually want absolute value anyway, for volume).
  function addAbsArrayElements(a, b) {
    return a.map((e, i) => Math.abs(e) + Math.abs(b[i]))
  }

  let channels = []
  for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    channels.push(audioBuffer.getChannelData(i))
  }

  const rawData = channels
    .reduce(addAbsArrayElements)
    .map((x) => x / audioBuffer.numberOfChannels)

  const framerate = 12
  const samples = audioBuffer.duration * framerate //rawData.length // Number of samples we want to have in our final data set
  const blockSize = Math.floor(rawData.length / samples) // Number of samples in each subdivision

  let filteredData = []
  for (let i = 0; i < samples; i++) {
    let chunk = rawData.slice(i * blockSize, (i + 1) * blockSize - 1)
    let sum = chunk.reduce((a, b) => a + b, 0)
    filteredData.push(sum / chunk.length)
  }

  const oldRangeMin = Math.min(...filteredData)
  const oldRangeMax = Math.max(...filteredData)

  const newRangeMin = 0
  const newRangeMax = 1

  if (oldRangeMax - oldRangeMin + newRangeMin === 0) {
    onError('Audio file is completely silent or corrupt.')
    return
  }

  filteredData = filteredData.map((x) => {
    return (
      ((x - oldRangeMin) * (newRangeMax - newRangeMin)) /
        (oldRangeMax - oldRangeMin) +
      newRangeMin
    )
  })

  filteredData = filteredData.map((x, ind) => {
    return (
      evaluate(expression.replace('x', x).replace('y', ind)) *
      audioReactivityMultiplier
    )
  })

  let string = getString(filteredData)

  return string
}
