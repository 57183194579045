import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { LABELS } from '../../../../constants'
import { useThemeContext } from '../../../../context/themeContext'
import { FieldType } from '../../../../types'
import { cn } from '../../../../utils'
import { useInputHandlers } from '../../../../utils/inputUtils'
import { CanvasBaseElement } from '../CanvasBaseElement'

interface PromptV2Props {
  label?: string
  children?: React.ReactNode
}

// Base PromptV2 component
export const PromptV2: React.FC<PromptV2Props> = ({
  label = LABELS.SUBJECT_PROMPT,
  children,
}) => {
  const { register, setValue } = useFormContext()
  const { handleFocusCapture, handleBlurCapture } = useInputHandlers()
  const [errorMessage, setErrorMessage] = useState('')
  const { colors } = useThemeContext()

  const handleWordLimit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0)
    const currentWordCount = words.length

    if (currentWordCount <= 150) {
      setErrorMessage('')
      setValue(FieldType.Subject, text)
    } else {
      setErrorMessage('150 word limit reached.')
    }

    adjustTextareaHeight(event.target)
  }

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'

    const newHeight = Math.min(textarea.scrollHeight, 240)
    textarea.style.height = `${newHeight}px`
  }

  return (
    <CanvasBaseElement label={label} errorMessage={errorMessage}>
      <div>
        <textarea
          onFocusCapture={handleFocusCapture}
          onBlurCapture={handleBlurCapture}
          className={cn(
            'w-full bg-transparent outline-none resize-none text-base min-h-[100px] max-h-[360px] overflow-y-auto',
            colors.text.default,
          )}
          {...register(FieldType.Subject)}
          onBlur={handleWordLimit}
        />
        {children}
      </div>
    </CanvasBaseElement>
  )
}
