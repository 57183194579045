import InfoIcon from '../../images/Info.svg'

export const InfoButton = ({ onInfo, canvas }) => (
  <button
    onClick={(e) => {
      if (onInfo) onInfo(true, canvas.settingsUsed)
    }}
    className='p-0 transition duration-200 ease-in-out rounded-full shadow-md hover:bg-purple-500 hover:bg-opacity-70 focus:outline-none h-7 w-7'
  >
    <img src={InfoIcon} alt='Info Icon' />
  </button>
)
