import { toast } from 'react-toastify'

import Copy from '../../images/Copy.svg'
import { useUserAccountContext } from '../../context/userContext'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../../utils/mixpanel'

const DELAY = 1500 // add synthetic delay to improve UX otherwise too quick

export const CopyPromptButton = ({ settingsUsed }) => {
  const { currentUser } = useUserAccountContext()
  const mixpanel = useMixpanel()

  const handleClick = () => {
    try {
      navigator.clipboard.writeText(settingsUsed.prompt)

      trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.CLICK_COPY_PROMPT, {
        memory_id: settingsUsed.reusedSettingsMemoryId,
        video_type: settingsUsed.videoType,
        // video_is_related: settingsUsed, // should be added in the future, shows if one of the suggested related videos is copied, https://height.app/M9jzUFTFNu/T-6200
        video_scenes_count: Object.keys(settingsUsed.scenes).length,
        page_url: window.location.origin,
        user_subject: settingsUsed.promptSubject,
        user_style: settingsUsed.promptArtStyle,
        // curated_subject: settingsUsed,  to implement in the future https://height.app/M9jzUFTFNu/T-6200
        // curated_style: settingsUsed,  to implement in the future https://height.app/M9jzUFTFNu/T-6200
        // exclusive_style: settingsUsed  to implement in the future https://height.app/M9jzUFTFNu/T-6200
      })

      toast('✨ Prompt copied to clipboard', { autoClose: DELAY })
    } catch {
      // Can happen if the prompt has invalid characters (for example, some users put Chinese characters).
      // btoa() won't work properly in that scenario.
      console.error(
        'Copy prompt error -- probably due to invalid or non-English characters',
      )
      toast.error(
        `Sorry, we're unable to copy the prompt for this video due to the use of special characters.`,
      )
    }
  }

  return (
    <button
      onClick={handleClick}
      className='p-0 transition duration-200 ease-in-out rounded-full shadow-md over:bg-opacity-70 focus:outline-none h-7 w-7 hover:bg-opacity-70 hover:bg-gray-600'
    >
      <img src={Copy} alt='Copy Icon' />
    </button>
  )
}
