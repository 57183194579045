import { SVGProps } from '@/types'

export const XMarkIcon2 = ({ size = 24, ...props }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M4.75 4.75L19.25 19.25M19.25 4.75L4.75 19.25'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
)
