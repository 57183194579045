import '@xyflow/react/dist/style.css'

import { Collection } from '@kaiber/shared-types'
import {
  addEdge,
  Background,
  ColorMode,
  Connection,
  Edge,
  getNodesBounds,
  getViewportForBounds,
  isEdge,
  isNode,
  MiniMap,
  Node,
  OnNodesChange,
  ReactFlow,
  ReactFlowJsonObject,
  SelectionMode,
  useEdgesState,
  useOnSelectionChange,
  useReactFlow,
  useViewport,
  type Viewport,
} from '@xyflow/react'
import _ from 'lodash'
import React, { KeyboardEvent as ReactKeyboardEvent } from 'react'
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { fetchAnnouncement } from '../api'
import { AnnouncementsModal } from '../components/AnnouncementsModal'
import { AutoSavingAlert } from '../components/k2/AutoSavingAlert'
import { CanvasNavbar } from '../components/k2/Canvas/CanvasNavbar'
import { CustomConnectionLine } from '../components/k2/Canvas/CustomConnectionLine'
import { CustomEdge } from '../components/k2/Canvas/CustomEdge'
import { HelperLines } from '../components/k2/Canvas/HelperLines'
import { VersionDialog } from '../components/k2/Canvas/VersionDialog'
import { ContextMenu, ContextMenuProps, Sidebar } from '../components/k2/Menus'
import { MyLibrary } from '../components/k2/MyLibrary'
import { SelectionToolToolbar } from '../components/k2/SelectionToolToolbar'
import { Loading } from '../components/loading'
import {
  ANNOUNCEMENTS_LAST_VIEWED_K2,
  CANVAS_SAVE_DEBOUNCE_TIME_MS,
  DEFAULT_STROKE_COLOR,
  STROKE_COLORS,
  ZOOM_DEBOUNCE_TIME_MS,
  DEFAULT_THEME,
} from '../constants'
import {
  CanvasProvider,
  PresetProvider,
  useCanvasContext,
  usePresetContext,
  useThemeContext,
} from '../context'
import {
  useAnalytics,
  useContextMenu,
  useHotkeys,
  useInitialLoad,
  useNodeIntersection,
  useNodeUtility,
  useFileUpload,
  useCreateCollection,
} from '../hooks'
import { load, save } from '../services/CanvasService'
import { canvasStore } from '../stores/canvasStore'
import { mediaStore } from '../stores/mediaStore'
import '../styles/k2.css'
import { getCanvasChanges } from '../utils/canvasUtils'
import { useDndHandlers } from '../utils/dndUtils'
import { applyHelperLinesToNodeChanges } from '../utils/helperLinesUtils'
import {
  AllNodeTypes,
  AnalyticsEvent,
  Announcement,
  CanvasLoadedAction,
  FileWithSource,
  Media,
  MediaUploadSource,
  ModelType,
  NodeOrigin,
  NodeType,
  PresetFlow,
  useNodeTypes,
} from '@/types'
const NODE_DRAG_OPACITY = 0.4
const NODE_DRAG_THRESHOLD = 10
const NODE_CLICK_DISTANCE = 10

const DEFAULT_MIN_ZOOM = 0.5
const DEFAULT_MAX_ZOOM = 2
const MULTIPLE_FILE_UPLOAD_AUTO_GRID_GAP = 50

/**
 * Create a wrapper component that wraps FlowCanvas with CanvasProvider and PresetProvider
 * We need this because FlowCanvas uses the useCanvasContext and usePresetContext hooks
 * We will need to lift the Provider up to the App level if we use PresetProvider elsewhere in the future
 */
export const FlowCanvas = () => {
  return (
    <CanvasProvider>
      <PresetProvider>
        <FlowCanvasContent />
      </PresetProvider>
    </CanvasProvider>
  )
}

const FlowCanvasContent = () => {
  const navigate = useNavigate()
  const { canvasId } = useParams()
  useHotkeys()
  const {
    getIntersectingNodes,
    toObject,
    setViewport,
    screenToFlowPosition,
    updateNode,
  } = useReactFlow()
  const { preventScrolling, setSelectedNodeIds } = useCanvasContext()
  const { isSingleNodeSelected, isNodeInSelection, getMaxNodeZIndex } =
    useNodeUtility()
  const { addPresetToCanvas } = usePresetContext()
  const { trackNodeEvent, trackEvent } = useAnalytics()
  const { addCollectionToCanvas } = useCreateCollection()
  const { colors, activeTheme } = useThemeContext()

  const previousFlowObjRef = useRef<ReactFlowJsonObject | null>(null)
  const viewport = useViewport()
  const [nodes, setNodes] = useState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isMove, setIsMove] = useState(false)
  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState(false)
  const [isAnnouncementsModal, setAnnouncementsModal] = useState(false)
  const [selectedNode, setSelectedNode] = useState<Node>(null)
  const [minZoom, setMinZoom] = useState<number>(DEFAULT_MIN_ZOOM)
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined)
  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined)
  const [selectionBounds, setSelectionBounds] = useState<{
    x: number
    y: number
    width: number
    height: number
  } | null>(null)

  const reactFlowWrapper = useRef(null)

  const { trackPage } = useAnalytics()

  const {
    checkIntersections,
    handleIntersection,
    handleInvokeNodeIntersection,
  } = useNodeIntersection(setNodes)

  const renderCount = useRef(0)
  const mousePosition = useRef({ x: 0, y: 0 }) // useRef here as we never need to re-render this component if the mouse position updates
  const lastRenderTime = useRef(Date.now())

  // Setting up a node's context menu
  const [menu, setMenu] = useState<ContextMenuProps>(null)
  const { onPaneClick, onNodeContextMenu, onSelectionContextMenu } =
    useContextMenu(setMenu)

  useEffect(() => {
    trackPage('k2-canvas')
  }, [])

  // Infinite re-renders are unintentional, silent, cause bugs, affect performance and are difficult to notice, so let's set up a warning.
  useEffect(() => {
    const currentTime = Date.now()
    const timeDiff = currentTime - lastRenderTime.current

    if (timeDiff < 2000) {
      renderCount.current += 1
    } else {
      renderCount.current = 1
      lastRenderTime.current = currentTime
    }

    if (renderCount.current > 200) {
      console.warn(
        'FlowCanvas re-rendered more than 200 times within 2 seconds. Please ensure that FlowCanvas is not re-rendering in an infinite loop.',
      )
    }
  })

  const updateMinZoom = useCallback(
    (width: number, height: number) => {
      if (nodes.length === 0) return

      const bounds = getNodesBounds(nodes)
      const viewport = getViewportForBounds(
        bounds,
        width,
        height,
        0,
        DEFAULT_MAX_ZOOM,
        0.2,
      )

      const updatedMinZoom = Math.min(DEFAULT_MIN_ZOOM, viewport.zoom)

      setMinZoom(updatedMinZoom)
    },
    [nodes],
  )

  useEffect(() => {
    if (reactFlowWrapper.current) {
      const { width, height } = reactFlowWrapper.current.getBoundingClientRect()
      updateMinZoom(width, height)
    }
  }, [nodes, updateMinZoom])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateMinZoom(entry.contentRect.width, entry.contentRect.height)
      }
    })

    const currentWrapper = reactFlowWrapper.current
    if (currentWrapper) {
      resizeObserver.observe(currentWrapper)
    }

    return () => {
      if (currentWrapper) {
        resizeObserver.unobserve(currentWrapper)
      }
      resizeObserver.disconnect()
    }
  }, [updateMinZoom])

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) =>
        applyHelperLinesToNodeChanges(
          changes,
          nodes,
          setHelperLineHorizontal,
          setHelperLineVertical,
        ),
      )

      // Clear helper lines after applying changes
      requestAnimationFrame(() => {
        setHelperLineHorizontal(undefined)
        setHelperLineVertical(undefined)
      })

      // Update selection bounds
      const selectedNodes = nodes.filter((node) => node.selected)
      const bounds =
        selectedNodes.length > 0 ? getNodesBounds(selectedNodes) : null
      setSelectionBounds(bounds)
    },
    [setHelperLineHorizontal, setHelperLineVertical, nodes],
  )

  const onSelectionChange = useCallback(
    ({ nodes: selectedNodes }: { nodes: Node[]; edges: Edge[] }) => {
      const selectedNodeIds = new Set(selectedNodes.map((node) => node.id))
      setSelectedNodeIds(selectedNodeIds)

      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          const isSelected = selectedNodeIds.has(node.id)

          return {
            ...node,
            zIndex: isSelected ? getMaxNodeZIndex() : node.zIndex,
            style: {
              ...node.style,
              boxShadow: isSelected // use boxShadow to avoid changing dimensions of the node
                ? `0 0 0 2px ${colors.hex.brand}`
                : 'none',
              borderRadius: '16px',
            },
          }
        }),
      )

      const bounds =
        selectedNodes.length > 0 ? getNodesBounds(selectedNodes) : null
      setSelectionBounds(bounds)
    },
    [getMaxNodeZIndex, setNodes, setSelectedNodeIds, colors.hex.brand],
  )

  useOnSelectionChange({ onChange: onSelectionChange })

  const handleMouseMove = useCallback((event: React.MouseEvent) => {
    const { left, top } = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - left
    const y = event.clientY - top
    mousePosition.current = { x, y }
  }, [])

  const handleDebouncedZoomChange = useDebouncedCallback((zoom: number) => {
    canvasStore.setDebouncedZoomAndScale(zoom)
  }, ZOOM_DEBOUNCE_TIME_MS)

  const handleViewportChange = useCallback(
    ({ zoom }: Viewport) => {
      handleDebouncedZoomChange(zoom)
    },
    [handleDebouncedZoomChange],
  )

  const resetCanvas = useCallback(async () => {
    try {
      previousFlowObjRef.current = {
        nodes: [],
        edges: [],
        viewport: { x: 0, y: 0, zoom: 1 },
      }
      setIsSaving(true)
      await save(canvasId, [], true)
      setIsSaving(false)
    } catch (error) {
      console.log(error)
      setIsSaving(false)
    }
  }, [canvasId])

  const saveVersion = useCallback(async () => {
    if (!previousFlowObjRef.current) return
    try {
      const currentObj = toObject()

      const { diffs, currentFlowObj, hasChanges } = getCanvasChanges(
        previousFlowObjRef.current,
        currentObj,
      )

      if (!hasChanges) return

      previousFlowObjRef.current = _.cloneDeep(currentFlowObj)

      setIsSaving(true)
      await save(canvasId, diffs, false)
      setIsSaving(false)
    } catch (error) {
      console.error(error)
      setIsSaving(false)
    }
  }, [canvasId, toObject])

  const debouncedSaveVersion = useDebouncedCallback(
    saveVersion,
    CANVAS_SAVE_DEBOUNCE_TIME_MS,
  )

  const onSaveBeforeCanvasSwitch = useCallback(async () => {
    debouncedSaveVersion.cancel()
    await saveVersion()
  }, [saveVersion, debouncedSaveVersion])

  // trigger canvas save when the user exits or switches browser tabs
  useEffect(() => {
    const handleBeforeUnload = async (): Promise<void> => {
      try {
        await onSaveBeforeCanvasSwitch()
      } catch (error) {
        console.error('Error saving before unload:', error)
      }
    }

    const handleVisibilityChange = async (): Promise<void> => {
      if (document.visibilityState === 'hidden') {
        try {
          await onSaveBeforeCanvasSwitch()
        } catch (error) {
          console.error('Error saving on visibility change:', error)
        }
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [onSaveBeforeCanvasSwitch])

  const loadVersion = useCallback(
    async (timestamp?: number) => {
      try {
        setIsLoading(true)
        const flowObj = await load(canvasId, timestamp)

        previousFlowObjRef.current = flowObj
        if (flowObj) {
          const { nodes = [], edges = [] } = flowObj
          const { x = 0, y = 0, zoom = 1 } = flowObj.viewport

          const validNodes = nodes.filter((node) => {
            if (
              (Object.values(NodeType) as string[]).includes(node.type) &&
              isNode(node)
            )
              return true
            console.error('invalid node:', node)
            return false
          })

          const validEdges = edges.filter((edge) => {
            if (isEdge(edge)) return true

            console.error('invalid node:', edge)
            return false
          })

          // Add the medias loaded from the canvas to mediaStore
          validNodes.forEach((node) => {
            const media = node.data?.media as Media
            if (node.type === NodeType.MediaNode && media?.mediaId) {
              mediaStore.setMedia(media)
            }
          })

          setNodes(validNodes)
          setEdges(validEdges)
          setViewport({ x, y, zoom })
        }
      } catch (error: any) {
        previousFlowObjRef.current = toObject()

        if (error.response) {
          console.log(error.response.data.code)
          if (error.response.data.code === 'CANVAS_NOT_FOUND') {
            navigate('/flow-canvas')
          }
          console.error(error.response.data.message)
        } else {
          console.error('Cannot load version', error)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [canvasId, setNodes, setEdges, setViewport, toObject, navigate],
  )

  const handleRestoreVersion = useCallback(
    async (timestamp: number) => {
      await loadVersion(timestamp)
      trackEvent(AnalyticsEvent.CanvasLoaded, {
        action: CanvasLoadedAction.RestoredVersion,
      })
    },
    [loadVersion, trackEvent],
  )

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((edges) => addEdge(connection, edges))
    },
    [setEdges],
  )

  // feature flagged: is-canvas-reset-enabled
  const onResetCanvas = () => {
    setNodes([])
    setEdges([])
    setViewport({ x: 0, y: 0, zoom: 1 })
    resetCanvas()
  }

  useEffect(() => {
    debouncedSaveVersion()
  }, [nodes, edges, viewport, debouncedSaveVersion])

  useEffect(() => {
    if (canvasId) {
      loadVersion()
    }
  }, [canvasId, loadVersion])

  // temp fix to hide intercom on flow-canvas
  useEffect(() => {
    const hideIntercom = () => {
      const intercomLauncher: HTMLElement = document.querySelector(
        '.intercom-lightweight-app-launcher',
      )
      if (intercomLauncher) {
        intercomLauncher.style.display = 'none'
      }
    }

    hideIntercom()
  }, [])

  const nodeTypes = useNodeTypes()

  const onNodeClick = useCallback((_: MouseEvent, node: Node) => {
    setMenu(null)

    setSelectedNode(node)
  }, [])

  const onNodeDragStart = useCallback(
    (_: MouseEvent, node: Node) => {
      if (!isSingleNodeSelected(node.id)) return

      updateNode(node.id, (n) => ({
        ...n,
        style: { ...n.style, opacity: NODE_DRAG_OPACITY },
        data: { ...n.data, dragStartPosition: n.position },
      }))
      setMenu(null)
    },
    [updateNode, isSingleNodeSelected],
  )

  const onNodeDragStop = useCallback(
    (_: MouseEvent, node: Node) => {
      if (!isSingleNodeSelected(node.id)) return

      handleIntersection.cancel()
      handleInvokeNodeIntersection(_, node)
    },
    [isSingleNodeSelected, handleIntersection, handleInvokeNodeIntersection],
  )

  const onNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      if (!isSingleNodeSelected(node.id)) return

      if (node.type === NodeType.MediaNode) {
        checkIntersections(node)
      }

      if (node.type !== NodeType.FlowNode && !node.parentId) {
        return
      }

      setSelectedNode(node)

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === NodeType.AssembleSlotNode,
      )

      const slotClassName =
        intersections.length && node.parentId !== intersections[0]?.parentId
          ? 'active'
          : ''

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.type === NodeType.AssembleSlotNode) {
            return {
              ...n,
              className: slotClassName,
            }
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            }
          }
          return {
            ...n,
          }
        })
      })

      // Clean up by canceling the debounce when dragging ends
      return () => {
        handleIntersection.cancel()
      }
    },
    [
      getIntersectingNodes,
      setNodes,
      isSingleNodeSelected,
      handleIntersection,
      checkIntersections,
    ],
  )

  const onSelectionDragStop = useCallback(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (isNodeInSelection(node.id)) {
          return {
            ...node,
            dragging: false,
          }
        }
        return { ...node }
      })
    })
  }, [setNodes, isNodeInSelection])

  const { uploadAndProcessFiles } = useFileUpload()

  const addFilesToCanvas = useCallback(
    async (
      files: FileWithSource[],
      position: { x: number; y: number },
      isExternalFile: boolean,
      nodeOrigin: NodeOrigin,
    ) => {
      try {
        const processedFiles = await uploadAndProcessFiles(
          files,
          MediaUploadSource.CanvasUpload,
        )
        const gridSize = Math.ceil(Math.sqrt(processedFiles.length))

        let maxRowHeight = 0

        for (let index = 0; index < processedFiles.length; index++) {
          const { displayDimensions, ...media } = processedFiles[index]

          const row = Math.floor(index / gridSize)
          const column = index % gridSize

          if (column === 0 && row !== 0) {
            position.y += maxRowHeight + MULTIPLE_FILE_UPLOAD_AUTO_GRID_GAP
            maxRowHeight = 0
          }

          const newNode: AllNodeTypes = {
            id: `${media.mediaId}-${Date.now()}`,
            position: { x: position.x, y: position.y },
            data: {
              media,
              isProcessing: false,
            },
            type: NodeType.MediaNode,
          }

          setNodes((nds) => nds.concat(newNode))
          trackNodeEvent(newNode, AnalyticsEvent.NodeAdded, {
            nodeOrigin: nodeOrigin,
          })

          maxRowHeight = Math.max(maxRowHeight, displayDimensions.height)
          position.x +=
            displayDimensions.width + MULTIPLE_FILE_UPLOAD_AUTO_GRID_GAP
        }
      } catch (error) {
        console.error('Error adding files to canvas:', error)
      }
    },
    [setNodes, trackNodeEvent, uploadAndProcessFiles],
  )

  const handlePaste = useCallback(
    (ev: ClipboardEvent) => {
      const items = ev.clipboardData?.items

      if (!items) return

      const filesWithSource = [...items].reduce<FileWithSource[]>(
        (acc, item) => {
          if (item.type.startsWith('image/')) {
            const file = item.getAsFile()
            if (file) {
              acc.push({ file, source: URL.createObjectURL(file) })
            }
          }
          return acc
        },
        [],
      )

      addFilesToCanvas(
        filesWithSource,
        mousePosition.current,
        true,
        NodeOrigin.PastedMedia,
      )
    },
    [addFilesToCanvas, mousePosition],
  )

  // We use this instead of the onPaste event because it requires focus of the element, which isn't guaranteed
  useEffect(() => {
    window.addEventListener('paste', handlePaste)

    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])

  /**
   * Handles the event of files being dropped onto a canvas.
   * It calculates the position where the files are dropped and then adds the files to the canvas at the calculated position.
   */
  const handleFileDrop = useCallback(
    async (files: FileWithSource[], event: React.DragEvent<HTMLDivElement>) => {
      const { pageX, pageY } = event
      const { x, y } = screenToFlowPosition({ x: pageX, y: pageY })
      const isExternalFile = event.dataTransfer.types.includes('Files')

      addFilesToCanvas(
        files,
        { x, y },
        isExternalFile,
        NodeOrigin.DragAndDropMedia,
      )
    },
    [screenToFlowPosition, addFilesToCanvas],
  )

  /**
   * Manages the event of dropping a preset flow onto a canvas.
   */
  const handlePresetDrop = useCallback(
    (
      preset: PresetFlow,
      name: string,
      event: React.DragEvent<HTMLDivElement>,
    ) => {
      const { pageX, pageY } = event
      const position = screenToFlowPosition({ x: pageX, y: pageY })
      addPresetToCanvas(preset, position)
    },
    [addPresetToCanvas, screenToFlowPosition],
  )

  /**
   * Manages the event of dropping a collection onto a canvas.
   */
  const handleCollectionDrop = useCallback(
    (collection: Collection, event: React.DragEvent<HTMLDivElement>) => {
      const { pageX, pageY } = event
      const position = screenToFlowPosition({ x: pageX, y: pageY })
      addCollectionToCanvas(collection, position)
    },
    [addCollectionToCanvas, screenToFlowPosition],
  )

  const { handleDrop, handleDragOver } = useDndHandlers({
    handleFileDrop,
    handlePresetDrop,
    handleCollectionDrop,
  })

  const [announcement, setAnnouncement] = useState<Announcement>({})

  useInitialLoad(async () => {
    const response = await fetchAnnouncement(
      window.localStorage.getItem(ANNOUNCEMENTS_LAST_VIEWED_K2),
      'K2',
    )

    if (
      response.data.announcement &&
      response.data.announcement.type === 'K2'
    ) {
      setAnnouncement(response.data.announcement)
      setAnnouncementsModal(true)
      window.localStorage.setItem(
        ANNOUNCEMENTS_LAST_VIEWED_K2,
        response.data.announcement.publishAt,
      )
    }
  })

  const onKeyDown = useCallback(
    (e: ReactKeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && selectedNode?.type === NodeType.FlowNode) {
        e.preventDefault()
        const formId = selectedNode.id
        const form = document.getElementById(formId) as HTMLFormElement
        if (form) {
          form.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
          )
        } else {
          console.error(`Form with id ${formId} not found`)
        }
      }
    },
    [selectedNode],
  )

  const edgeTypes = useMemo(() => ({ default: CustomEdge }), [])

  useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        const isSelected = node.selected
        return {
          ...node,
          style: {
            ...node.style,
            boxShadow: isSelected
              ? `0 0 0 2px ${activeTheme === DEFAULT_THEME.Light ? 'black' : 'white'}`
              : 'none',
            borderRadius: '16px',
          },
        }
      }),
    )
  }, [activeTheme, setNodes])

  return (
    <div
      className='w-screen h-screen overflow-auto bg-k2-neutral-50 flex items-center justify-center'
      ref={reactFlowWrapper}
    >
      <div className='w-full h-full min-w-[1024px] min-h-[576px]'>
        <CanvasNavbar
          isMove={isMove}
          isAutoSaving={isSaving}
          setIsMove={setIsMove}
          setDialogOpen={setIsVersionDialogOpen}
        />
        <ReactFlow
          nodes={nodes}
          edges={edges}
          minZoom={minZoom}
          maxZoom={DEFAULT_MAX_ZOOM}
          nodeDragThreshold={NODE_DRAG_THRESHOLD}
          nodeClickDistance={NODE_CLICK_DISTANCE}
          nodesFocusable={false}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          onSelectionDragStop={onSelectionDragStop}
          onNodeDrag={onNodeDrag}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeContextMenu={onNodeContextMenu}
          onSelectionContextMenu={onSelectionContextMenu}
          onKeyDown={onKeyDown}
          onNodeClick={onNodeClick}
          onPaneClick={onPaneClick}
          onMouseMove={handleMouseMove}
          onViewportChange={handleViewportChange}
          proOptions={{ hideAttribution: true }}
          preventScrolling={preventScrolling}
          panOnScroll={!isMove}
          panOnDrag={isMove ? [0, 1] : [1]} // 0: left mouse, 1: middle mouse
          selectionOnDrag={!isMove}
          zoomOnScroll={isMove}
          selectionMode={SelectionMode.Partial}
          selectionKeyCode={['Meta', 'Shift']}
          multiSelectionKeyCode={['Meta', 'Control']}
          zoomActivationKeyCode={['Meta', 'Control']}
          colorMode={activeTheme as ColorMode}
          style={{ userSelect: 'none' }}
          deleteKeyCode={['Backspace', 'Delete']}
          connectionLineComponent={CustomConnectionLine}
        >
          <Sidebar onSaveBeforeCanvasSwitch={onSaveBeforeCanvasSwitch} />
          <VersionDialog
            isOpen={isVersionDialogOpen}
            setIsOpen={setIsVersionDialogOpen}
            loadVersion={handleRestoreVersion}
            resetCanvas={onResetCanvas}
          />
          <AnnouncementsModal
            isOpen={isAnnouncementsModal}
            setIsOpen={setAnnouncementsModal}
            announcement={announcement}
          />
          <MyLibrary />
          <MiniMap
            bgColor={colors.background.neutral}
            pannable
            zoomable
            nodeStrokeColor={(node) =>
              STROKE_COLORS[node.data?.activeThemelType as ModelType] ??
              DEFAULT_STROKE_COLOR
            }
            nodeStrokeWidth={10}
            nodeBorderRadius={20}
          />
          <Background variant={null} />
          <HelperLines
            horizontal={helperLineHorizontal}
            vertical={helperLineVertical}
            opacity={0.5}
            dashPattern={[5, 5]}
          />
          {menu && <ContextMenu {...menu} />}
          <SelectionToolToolbar selectionBounds={selectionBounds} />
        </ReactFlow>
        {isLoading && <Loading text='Loading Canvas...' />}
        <AutoSavingAlert isSaving={isSaving && !isLoading} />
      </div>
    </div>
  )
}
