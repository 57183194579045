import { ReactNode } from 'react'

interface AnnotationProps {
  text: string
  children: ReactNode
  hideAnnotation?: boolean
}

const Annotation: React.FC<AnnotationProps> = ({
  text,
  children,
  hideAnnotation,
}) => {
  return (
    <div className='relative inline-block'>
      {children}
      {!hideAnnotation && (
        <div className='absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-kaiberGreen text-black text-xs py-1 px-2 rounded whitespace-nowrap'>
          <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -mt-1 w-0 h-0 border-l-4 border-r-4 border-t-0 border-b-4 border-solid border-transparent border-b-kaiberGreen'></div>
          {text}
        </div>
      )}
    </div>
  )
}

export default Annotation
