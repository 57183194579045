import { Handle, Position, HandleType } from '@xyflow/react'

import { useThemeContext } from '../../../context/themeContext'
import { cn } from '../../../utils'

export const CustomHandle = ({
  type,
  position,
  isConnectable,
}: {
  type: HandleType
  position: Position
  isConnectable: boolean
}) => {
  const { colors } = useThemeContext()

  return (
    <Handle
      type={type}
      position={position}
      isConnectable={isConnectable}
      className={cn(
        `w-3 h-3 rounded-full border outline outline-1 outline-offset-4 border-r ${colors.border.brand} ${colors.outline.brand} ${colors.shadow.default} cursor-pointer`,
        colors.background.brand,
        'hover:border-0 hover:w-5 hover:h-5 hover:bg-neutral-transparent-700 hover:outline-offset-1 hover:shadow-none',
      )}
    />
  )
}
