import React from 'react'

interface HandIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const HandIcon = React.forwardRef<SVGSVGElement, HandIconProps>(
  ({ size = '20', ...props }, ref) => (
    <>
      <svg
        ref={ref}
        stroke='currentColor'
        fill='none'
        height={size}
        width={size}
        viewBox='0 0 32 32'
        {...props}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20.6364 11.4545V10.3636C20.6364 9.15865 21.6132 8.18182 22.8182 8.18182C24.0232 8.18182 25 9.15865 25 10.3636V14.7273M25 15.2727V18.3591C25 22.6795 21.4976 26.1818 17.1773 26.1818C14.2729 26.1818 11.6076 24.5727 10.2549 22.0025L7 15.8182L7.81885 14.7946C8.5716 13.8537 9.94459 13.7011 10.8855 14.4539L11.9091 15.2727V14.1818M11.9091 13.9091V9.27273C11.9091 8.06774 12.8859 7.09091 14.0909 7.09091C15.2959 7.09091 16.2727 8.06774 16.2727 9.27273V14.1818M16.2727 11.4545V8.18182C16.2727 6.97683 17.2495 6 18.4545 6C19.6596 6 20.6364 6.97683 20.6364 8.18182V15'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  ),
)
