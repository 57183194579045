import { useState, useEffect } from 'react'
import cookies from 'browser-cookies'
import Lottie from 'lottie-react'
import { Link } from 'react-router-dom'

import { signInAndUpWithGoogle } from '../../services/AuthService'
import { Alert } from '../../components/Alert'
import LoadingWhite from '../../images/lottie/loaderWhite.json'

export default function GoogleCallback() {
  const [isLoading, setIsLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)

  const handleGoogleCallback = async () => {
    try {
      let newsletter

      switch (window.sessionStorage.getItem('NEWSLETTER')) {
        case 'true':
          newsletter = true
          break
        case 'false':
          newsletter = false
          break
        default:
          newsletter = false
      }

      // Legacy code for rewardful
      const referrerId = window.sessionStorage.getItem('REFERRER_ID') // [TO BE DEPRECATED]
      const referrerIdFromCookies = cookies.get('referrer_id') // [TO BE DEPRECATED]
      const rewardfulReferral = JSON.parse(cookies.get('rewardful.referral')) // [TO BE DEPRECATED]
      const rewardfulReferrerId = rewardfulReferral?.affiliate?.id // ID of the affiliate // [TO BE DEPRECATED]
      const rewardfulObjectId = rewardfulReferral?.id // ID of the referral // [TO BE DEPRECATED]

      // New Affiliate tracking code
      const affiliateLinkToken = cookies.get('affiliate') // [TO BE DEPRECATED]

      const impactPartnerId = cookies.get('impact_partner_id')
      const impactPartnerToken = cookies.get('impact_partner_token')

      const response = await signInAndUpWithGoogle(
        window.location.search,
        newsletter,
        referrerId || referrerIdFromCookies, // [TO BE DEPRECATED]
        rewardfulReferrerId, // [TO BE DEPRECATED]
        rewardfulObjectId, // [TO BE DEPRECATED]
        affiliateLinkToken, // [TO BE DEPRECATED]
        impactPartnerId,
        impactPartnerToken,
      )

      window.sessionStorage.removeItem('NEWSLETTER')

      if (referrerId) {
        window.sessionStorage.removeItem('REFERRER_ID')
      }

      let redirect = window.sessionStorage.getItem('LOGIN_REDIRECT_TO')

      const user = response?.data?.user

      if (!user?.onboardingCompletedAt) {
        if (user?.skippedOnboarding === false) {
          redirect = '/onboarding'
        }
      }

      // FOR MOBILE APP AFFILIATE TRACKING -- Nov 1, 2023 -- leaving out of initial Paddle launch
      // if (isAndroid || isIOS) {
      //   if (redirect) {
      //     window.location.replace('/get-mobile?redirect=' + redirect)
      //   }
      //   else {
      //     window.location.replace('/get-mobile')
      //   }
      // }
      // else if (redirect) {
      //   window.sessionStorage.removeItem('LOGIN_REDIRECT_TO')
      //   window.location.replace(window.location.origin + redirect)
      // } else {
      //   window.location.replace('/dashboard')
      // }

      if (redirect) {
        window.sessionStorage.removeItem('LOGIN_REDIRECT_TO')
        window.location.replace(window.location.origin + redirect)
      } else {
        window.location.replace('/dashboard')
      }
    } catch (err) {
      setIsLoading(false)
      setShowAlert(true)

      if (err.response) {
        setAlertMessage(
          err.response.data.message || 'Oops! Something went wrong.',
        )
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    }
  }

  useEffect(() => {
    handleGoogleCallback()
  }, [])

  return (
    <div
      className={`sm:p-10 flex h-full ${
        isLoading ? 'bg-transparent' : 'bg-darkGray'
      } sm:bg-transparent overflow-y-auto`}
    >
      {isLoading ? (
        <div className='flex flex-col items-center space-y-4 m-auto'>
          <div className='bg-darkGray p-6 rounded-3xl'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        </div>
      ) : (
        <div className='w-full sm:max-w-sm bg-darkGray sm:rounded-3xl m-auto p-10'>
          {showAlert && (
            <>
              <div className='mb-4'>
                <Alert open={showAlert} message={alertMessage} type='alert' />
              </div>

              <div className='text-center'>
                <Link
                  to='/login'
                  className='text-tertiary/80 hover:text-primary'
                >
                  Back to login page
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
