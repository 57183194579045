import React, { useRef, RefObject } from 'react'

import { canvasStore } from '../../stores/canvasStore'
import type { BaseWrapperProps } from '../../types'

interface IScaleWrapperProps extends BaseWrapperProps {
  disableScaling?: boolean
  className?: string
  maxWidth?: number
  maxHeight?: number
}

export const ScaleWrapper: React.FC<IScaleWrapperProps> = ({
  disableScaling = false,
  className = '',
  children,
  maxWidth = 0,
  maxHeight = 0,
}) => {
  const scale = canvasStore.useScale()
  const childRef: RefObject<HTMLDivElement> = useRef(null)

  /**
   * Calculates and sets the adjusted scale based on the maximum allowed dimensions.
   * This ensures that the content doesn't exceed the specified maxWidth or maxHeight,
   * while maintaining the aspect ratio. See Slider in CollectionDisplay.tsx for an example.
   */

  const calculateAdjustedScale = () => {
    if ((maxWidth || maxHeight) && childRef.current) {
      const contentWidth = childRef.current.offsetWidth
      const contentHeight = childRef.current.offsetHeight
      const maxAllowedWidthScale = maxWidth ? maxWidth / contentWidth : Infinity
      const maxAllowedHeightScale = maxHeight
        ? maxHeight / contentHeight
        : Infinity
      const maxAllowedScale = Math.min(
        maxAllowedWidthScale,
        maxAllowedHeightScale,
      )
      return Math.min(scale, maxAllowedScale)
    }
    return scale
  }

  const adjustedScale = calculateAdjustedScale()

  return (
    <div
      className={className}
      style={disableScaling ? {} : { transform: `scale(${adjustedScale})` }}
    >
      <div ref={childRef}>{children}</div>
    </div>
  )
}
