import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

import { cn } from '../utils'
import { updateBio } from '../services/UserProfileService'
import { useUserAccountContext } from '../context/userContext'
import { Modal } from './Modal'

const MAX_BIO_LENGTH = 150

export const UpdateBioModal = ({ isOpen, setOpen, onUpdate }) => {
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const [bio, setBio] = useState(currentUser.bio || '')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const textareaRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (textareaRef?.current) {
          const length = textareaRef.current.value.length
          textareaRef.current.focus()
          textareaRef.current.setSelectionRange(length, length)
        }
      }, 0)

      return () => clearTimeout(timer)
    }
  }, [isOpen])

  const handleBioChange = (e) => {
    setBio(e.target.value)
    if (e.target.value.length > MAX_BIO_LENGTH) {
      setError(`Bio must be under ${MAX_BIO_LENGTH} characters`)
    } else {
      setError('')
    }
  }

  const handleSave = async () => {
    if (bio.length <= MAX_BIO_LENGTH) {
      setIsLoading(true)

      try {
        await updateBio(bio)
        setCurrentUser({
          ...currentUser,
          bio,
        })
        toast.success('Bio updated successfully!')
        setOpen(false)
        onUpdate({ bio })
      } catch (error) {
        if (error.response.data.errors?.bio) {
          setError(error.response.data.errors.bio)
        } else {
          setError(`Error updating bio: ${error.message}`)
        }
      }

      setIsLoading(false)
    }
  }

  return (
    <Modal open={isOpen} setOpen={setOpen}>
      <div className='flex flex-col items-center space-y-2 w-full'>
        <h2 className='m-2 text-3xl text-tertiary'>Edit Bio</h2>
        <textarea
          value={bio}
          onChange={handleBioChange}
          ref={textareaRef}
          className='w-full px-2 py-4 h-32 border-none outline-none rounded-xl bg-neutral-600'
          placeholder='Talk about yourself...'
        />
        <div className='flex justify-between w-full pb-2'>
          <p className='text-sm text-red-500'>{error}</p>
          <span
            className={`text-sm text-gray-400 ${
              bio.length > MAX_BIO_LENGTH ? 'text-red-500' : ''
            }`}
          >
            {bio.length} / {MAX_BIO_LENGTH} characters
          </span>
        </div>
        <button
          onClick={handleSave}
          disabled={bio.length > MAX_BIO_LENGTH || isLoading}
          className={cn(
            'w-full px-4 py-2 border-solid border-2 border-primary rounded-full',
            bio.length > MAX_BIO_LENGTH || isLoading
              ? 'bg-gray-300 border-none'
              : 'bg-darkStone text-primary',
          )}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
