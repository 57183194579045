import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Modal } from './Modal'
import { useUserAccountContext } from '../context/userContext'
import { updateCurrentUser } from '../services/AuthService'

const UPDATE_INFORMATION_ERROR_MESSAGES = {
  INVALID_NAME_CHARS:
    'First and last name can only contain letters and hyphens',
  NAME_LENGTH: 'Name must be less than 32 characters.',
  INVALID_USERNAME_LENGTH: 'Username must be between 4 and 26 characters.',
  INVALID_USERNAME_CHARS:
    'Username can only contain letters, numbers, underscores, and periods.',
  USERNAME_TAKEN: 'This username is already taken.',
}

const nameRegex = /^[a-zA-Z-]+$/
const usernameRegex = /^[a-z0-9_.]+$/

const UPDATE_INFORMATION_LAST_DISMISSED = 'UPDATE_INFORMATION_LAST_DISMISSED'

export const UpdateNameModal = ({ onUpdate }) => {
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState(currentUser.firstName || '')
  const [lastName, setLastName] = useState(currentUser.lastName || '')
  const [username, setUsername] = useState(currentUser.username || '')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const isReminderDue = // 3 day reminder timer stored in local storage
      !window.localStorage.getItem(UPDATE_INFORMATION_LAST_DISMISSED) ||
      window.localStorage.getItem(UPDATE_INFORMATION_LAST_DISMISSED) <
        Date.now() - 1000 * 60 * 60 * 24 * 3

    if (currentUser && !currentUser.firstName && isReminderDue) {
      setOpen(true)
    }
  }, [currentUser])

  const dismissForLater = () => {
    setOpen(false)
    window.localStorage.setItem(UPDATE_INFORMATION_LAST_DISMISSED, Date.now())
  }

  const handleUsernameChange = (e) => {
    // P2: Add debounced availability check

    setError(null)
    setUsername(e.target.value.toLowerCase())
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!firstName.match(nameRegex)) {
      setError(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_NAME_CHARS)
      return
    }

    if (lastName && !lastName.match(nameRegex)) {
      setError(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_NAME_CHARS)
      return
    }

    if (username && !username.match(usernameRegex)) {
      setError(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_USERNAME_CHARS)
      return
    }

    if (username.length < 3 || username.length > 26) {
      setError(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_USERNAME_LENGTH)
      return
    }

    if (firstName.length > 32 || lastName.length > 32) {
      setError(UPDATE_INFORMATION_ERROR_MESSAGES.NAME_LENGTH)
      return
    }

    setIsLoading(true)
    try {
      await updateCurrentUser({ firstName, lastName, username })

      setCurrentUser({
        ...currentUser,
        firstName,
        lastName,
        username,
      })

      if (onUpdate) {
        onUpdate({ username })
      }

      toast.success('Information updated successfully!')
      setOpen(false)
    } catch (error) {
      if (error.response.data.errors?.username) {
        setError(UPDATE_INFORMATION_ERROR_MESSAGES.USERNAME_TAKEN)
      } else {
        setError(`Error updating information: ${error.message}`)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={open} setOpen={dismissForLater}>
      <div className='flex flex-col items-center justify-center space-y-2'>
        <h2 className='m-2 mt-12 text-3xl text-tertiary'>
          Update your information
        </h2>
        <p className='text-gray-500'>
          Only your username will be public on your profile
        </p>
        <form
          onSubmit={handleSubmit}
          className='flex flex-col justify-center space-y-2 w-full'
        >
          <div className='flex flex-row items-center justify-center space-x-4'>
            <div className='flex flex-col justify-center space-y-2'>
              <label
                htmlFor='firstName'
                className='block text-sm text-tertiary pt-2 px-4 font-light'
              >
                <strong>First Name</strong>
                <span className='text-red-500'>*</span>
              </label>
              <input
                type='text'
                id='firstName'
                placeholder='Leonardo'
                className={`w-full p-3 px-8 border-transparent rounded-full bg-[#303030] text-white`}
                value={firstName}
                onChange={(e) => {
                  const trimmedName = e.target.value.trim()

                  setError('')
                  setFirstName(trimmedName)
                }}
              />
            </div>
            <div className='flex flex-col justify-center space-y-2'>
              <label
                htmlFor='lastName'
                className='block text-sm text-tertiary pt-2 px-4 font-light'
              >
                <strong>Last Name</strong>
              </label>
              <input
                type='text'
                id='lastName'
                placeholder='Davinci'
                className={`w-full p-3 px-8 border-transparent rounded-full bg-[#303030] text-white`}
                value={lastName}
                onChange={(e) => {
                  const trimmedName = e.target.value.trim()

                  setError('')
                  setLastName(trimmedName)
                }}
              />
            </div>
          </div>
          <label
            htmlFor='username'
            className='block text-sm text-tertiary pt-2 px-4 font-light'
          >
            <strong>Username</strong>
          </label>
          <div className='relative'>
            <input
              type='text'
              id='username'
              placeholder='user123'
              className={`w-full p-3 px-8 border-transparent border rounded-full bg-[#303030] text-white`}
              value={username}
              onChange={handleUsernameChange}
            />
            <span className='absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400'>
              @
            </span>
          </div>
          <p className='text-red-400 text-xs px-5 min-h-[16px]'>{error}</p>
          <button
            type='submit'
            className='w-full border-transparent p-3 rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary bg-primary disabled:bg-disabled'
            disabled={isLoading || error || !firstName}
          >
            {isLoading ? 'Updating...' : 'Update'}
          </button>
          <button
            onClick={dismissForLater}
            className='w-full text-primary border-transparent p-3 rounded-full border-gray-300 shadow-sm hover:border-primary focus:border-primary focus:ring-primary bg-transparent disabled:bg-disabled'
            disabled={isLoading}
          >
            Remind me later
          </button>
        </form>
      </div>
    </Modal>
  )
}
