import { useCallback, useRef } from 'react'

const MENU_CLOSE_DELAY = 500

/**
 * Custom hook to handle mouse enter and leave events for menu toggle
 */
export const useMenuToggle = (
  openFn: () => void,
  closeFn: () => void,
  closeDelay: number = MENU_CLOSE_DELAY,
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  /**
   * Handles menu open event
   */
  const handleMenuOpen = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    openFn()
  }, [openFn])

  /**
   * Closes menu after delay for improved UX otherwise menu closes too quickly
   */
  const handleMenuClose = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      closeFn()
    }, closeDelay)
  }, [closeFn, closeDelay])

  /**
   * Closes menu immediately which can be helpful for close buttons
   */
  const handleMenuCloseImmediately = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    closeFn()
  }, [closeFn])

  return { handleMenuOpen, handleMenuClose, handleMenuCloseImmediately }
}
