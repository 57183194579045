import { useState, useEffect } from 'react'

import { MEDIA_CANVAS_DEFAULT_SIZE } from '../../constants'
import { MediaType } from '@/types'

export interface AutoscaleMediaProps {
  source: string
  mediaType: MediaType
  maxWidth?: number
  overrideWidth?: number
  overrideHeight?: number
}

export const useAutoscaleMedia = ({
  source,
  mediaType,
  maxWidth = MEDIA_CANVAS_DEFAULT_SIZE,
  overrideWidth = 0,
  overrideHeight = 0,
}: AutoscaleMediaProps) => {
  const [originalDimensions, setOriginalDimensions] = useState({
    width: overrideWidth,
    height: overrideHeight,
  })
  const [scaledDimensions, setScaledDimensions] = useState({
    width: 0,
    height: 0,
  })
  const [scaledImage, setScaledImage] = useState<HTMLImageElement | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let element: HTMLImageElement | HTMLVideoElement

    const calculateDimensions = (width: number, height: number) => {
      setOriginalDimensions({ width, height })

      let scaledWidth = width
      let scaledHeight = height

      const aspectRatio = width / height
      if (scaledWidth > maxWidth) {
        scaledWidth = maxWidth
        scaledHeight = maxWidth / aspectRatio
      }

      setScaledDimensions({
        width: scaledWidth,
        height: scaledHeight,
      })
    }

    const onLoadHandler = () => {
      if (overrideWidth && overrideHeight) {
        calculateDimensions(overrideWidth, overrideHeight)
      } else {
        const width =
          (element as HTMLImageElement).naturalWidth ||
          (element as HTMLVideoElement).videoWidth
        const height =
          (element as HTMLImageElement).naturalHeight ||
          (element as HTMLVideoElement).videoHeight

        calculateDimensions(width, height)
      }

      if (mediaType === MediaType.Image) {
        setScaledImage(element as HTMLImageElement)
      }

      setIsLoading(false)
    }

    const createMediaElement = () => {
      if (overrideWidth && overrideHeight) {
        calculateDimensions(overrideWidth, overrideHeight)
        setIsLoading(false)
      } else {
        switch (mediaType) {
          case MediaType.Image:
            element = new Image()
            element.onload = onLoadHandler
            break
          case MediaType.Video:
            element = document.createElement('video')
            element.onloadedmetadata = onLoadHandler
            break
          default:
            element = new Image()
            element.onload = onLoadHandler
            break
        }
        element.src = source
      }
    }

    createMediaElement()
  }, [source, mediaType, maxWidth, overrideWidth, overrideHeight])

  return { originalDimensions, scaledDimensions, isLoading, scaledImage }
}
