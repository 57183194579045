import { Fragment, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { useMixpanel } from 'react-mixpanel-browser'
import {
  Bars3Icon,
  XMarkIcon,
  UserIcon,
  PlusIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import { TbCoins } from 'react-icons/tb'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import cx from 'classnames'
import { usePostHog } from 'posthog-js/react'

import logo from '../images/welcome/logo.png'
import { useUserAccountContext } from '../context/userContext'
import { logout } from '../services/AuthService'
import http from '../services/HttpService'
import Dropdown from './Dropdown'
import { SearchBar } from './SearchBar'
import { isMobile } from '../utils'
import { Banner } from './Banner'
import { AnnouncementsModal } from './AnnouncementsModal'
import { BETA_MOTION_V3 } from '../utils/constants'
import { ANNOUNCEMENTS_LAST_VIEWED_K1 } from '../constants'
import { fetchAnnouncement } from '../api'
import { useInitialLoad } from '../hooks'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const MOTION_V3_TYPEFORM_URL = 'https://form.typeform.com/to/pw7ioKeh'

export default function Navbar({
  faded = false,
  searchRef = null,
  getVideos = () => undefined,
  isLoading = false,
  setSearchText = () => undefined,
  searchText = '',
  showBanner = false,
}) {
  const { currentUser, isAuthenticated, isImpersonating, featureToggles } =
    useUserAccountContext()
  const [scheduledMaintenanceDate, setScheduledMaintenanceDate] = useState(null)
  const [showSearch, setShowSearch] = useState(false)

  const isSubscribed = currentUser?.subscriptionType === 'Standard'
  const [, setIsMobileUser] = useState(false)

  const mixpanel = useMixpanel()
  const navigate = useNavigate()

  const [announcementsModal, setAnnouncementsModal] = useState(false)
  const [announcement, setAnnouncement] = useState(false)

  const posthog = usePostHog()
  const showFeedbackBanner = featureToggles['enable-feedback-banner']

  useInitialLoad(async () => {
    if (isAuthenticated) {
      const response = await fetchAnnouncement(
        window.localStorage.getItem(ANNOUNCEMENTS_LAST_VIEWED_K1),
        'K1',
      )

      if (
        response.data.announcement &&
        response.data.announcement.type === 'K1'
      ) {
        setAnnouncement(response.data.announcement)
        setAnnouncementsModal(true)
        window.localStorage.setItem(
          ANNOUNCEMENTS_LAST_VIEWED_K1,
          response.data.announcement.publishAt,
        )
      }
    }
  })

  useEffect(() => {
    if (isAuthenticated) {
      mixpanel.identify(currentUser.distinctId)
      mixpanel.people.set({
        $email: currentUser.email,
        $name: currentUser.name,
        $created: currentUser.createdAt,
        $last_login: new Date(),
        $auth0_id: currentUser.distinctId,
        $is_subscribed: currentUser?.subscriptionType === 'Standard',
        $locationData: currentUser?.locationData,
      })

      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'vcf1kn2l',
      })

      window.Intercom('update', {
        email: currentUser.email,
        name: currentUser.name,
        created_at: currentUser.createdAt,
        user_id: currentUser.distinctId,
        is_subscribed: currentUser?.subscriptionType === 'Standard',
        $locationData: currentUser?.locationData,
      })
    }

    http.get('/api/check_scheduled_maintenance').then((response) => {
      const { scheduledMaintenanceDate } = response.data

      if (scheduledMaintenanceDate) {
        setScheduledMaintenanceDate(scheduledMaintenanceDate)
      }
    })

    if (
      currentUser?.paymentGateway === 'apple' ||
      currentUser?.paymentGateway === 'google'
    ) {
      setIsMobileUser(true)
    }
    // This useEffect() should only be called once -- some dependencies vars can change
    // and would trigger useEffect() again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // intersection observer for showing search in sticky navbar
  useEffect(() => {
    let currentSearchRef = searchRef?.current

    if (!currentSearchRef) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowSearch(!entry.isIntersecting && !isMobile())
      },
      {
        threshold: [0.5],
        rootMargin: `-${currentSearchRef.clientHeight}px 0px 0px 0px`,
      },
    )
    observer.observe(currentSearchRef)

    return () => {
      observer.unobserve(currentSearchRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let navigation = []
  let userNavigation = []
  const myPlanButtonText = isSubscribed ? 'My Plan' : 'Upgrade Now'
  if (isAuthenticated) {
    navigation = [
      {
        name: 'Product',
        href: '/product',
        current: false,
      },
      { name: 'Gallery', href: '/gallery', current: false },
      { name: 'Kaiber Studio', href: '/studio', current: true },
      { name: 'My Videos', href: '/dashboard', current: true },
      {
        name: 'About',
        href: '/about',
        current: false,
        submenu: [
          {
            name: 'Company',
            href: '/about',
            current: false,
          },
          {
            name: 'Blog',
            href: 'https://blog.kaiber.ai',
            current: false,
          },
          {
            name: 'Community',
            href: '/community',
            current: false,
          },
          {
            name: 'Media Kit',
            href: 'https://storage.googleapis.com/kaiber_files/01.Media_Kit_Kaiber_2023.zip',
            current: false,
          },
          {
            name: 'Help Center',
            href: 'https://helpcenter.kaiber.ai',
            current: false,
          },
        ],
      },
    ]

    if (isMobile()) {
      navigation.push({
        name: 'Blog',
        href: 'https://blog.kaiber.ai',
        current: true,
      })
    }

    const myPlanRedirect = isSubscribed ? '/account?tab=my-plan' : '/pricing'
    userNavigation = [
      { name: 'Account', href: '/account' },
      {
        name: myPlanButtonText,
        href: myPlanRedirect,
      },
      {
        name: 'Sign Out',
        onClick: async () => {
          await logout()
          await posthog.reset()
          window.location.href = '/login'
        },
      },
    ]
  } else {
    navigation = [
      {
        name: 'Product',
        href: '/product',
        current: false,
      },
      { name: 'Pricing', href: '/pricing', current: false },
      {
        name: 'Gallery',
        href: '/gallery',
        current: false,
      },
      { name: 'Kaiber Studio', href: '/studio', current: false },
      {
        name: 'About',
        href: '/about',
        current: false,
        submenu: [
          {
            name: 'Company',
            href: '/about',
            current: false,
          },
          {
            name: 'Blog',
            href: 'https://blog.kaiber.ai',
            current: false,
          },
          {
            name: 'Community',
            href: '/community',
            current: false,
          },
          {
            name: 'Media kit',
            href: 'https://drive.google.com/uc?export=download&id=1tp5JXRyu3Q7gHXjetRfwi1GxP6Bs0ZEq',
            current: false,
          },
          {
            name: 'Help Center',
            href: 'https://helpcenter.kaiber.ai',
            current: false,
          },
        ],
      },
    ]

    if (isMobile()) {
      navigation.push({
        name: 'Blog',
        href: 'https://blog.kaiber.ai',
        current: true,
      })
    }

    userNavigation = []
  }

  const FeedbackBanner = ({ featureName }) => (
    <div className='text-white text-2xl bg-[#181A18] border-b border-[#354230] flex justify-center'>
      <a
        href={MOTION_V3_TYPEFORM_URL}
        target='_blank'
        rel='noopener noreferrer'
        title={`Submit Feedback for ${featureName}`}
        aria-label={`Submit Feedback for ${featureName}`}
      >
        <button className='px-8 py-1 m-3 mb-2 text-sm font-normal text-kaiberPurple rounded-full shadow-xl bg-transparent hover:bg-kaiberPurple/90 hover:text-dark active:bg-primary/40 border-kaiberPurple border duration-300 ease-in-out'>
          Submit Feedback for {featureName}
        </button>
      </a>
    </div>
  )

  return (
    <div className='fixed left-0 right-0 z-30 shadow bg-black/90 backdrop-blur-md top-0'>
      {scheduledMaintenanceDate && (
        <div className='px-5 pt-5 bg-dark'>
          <div className='flex justify-center mx-auto max-w-7xl'>
            <div className='flex items-center gap-2 rounded-full bg-yellow-300 text-gray-800 px-4 py-2.5'>
              <ExclamationTriangleIcon
                className='w-6 h-6 lg:w-5 lg:h-5 min-w-fit'
                aria-hidden='true'
              />
              <p>
                Scheduled maintenance: Kaiber will be temporarily unavailable on{' '}
                <strong>{scheduledMaintenanceDate}</strong> for major upgrades.
                Thanks for your understanding!
              </p>
            </div>
          </div>
        </div>
      )}

      <Disclosure as='header'>
        {({ open }) => (
          <>
            {isAuthenticated && showFeedbackBanner && (
              <FeedbackBanner featureName={BETA_MOTION_V3.NAME} />
            )}

            <div
              className={cx(
                'px-5 mx-auto max-w-7xl lg:divide-y lg:divide-gray-200',
                {
                  'py-4 lg:py-5': !showSearch,
                  'py-2': showSearch,
                },
              )}
            >
              <div className='relative flex'>
                {/* LOGO */}
                <div
                  className={cx('relative z-10 flex px-2 lg:px-0', {
                    'scale-[.65] absolute -left-6': showSearch,
                  })}
                >
                  <div className='flex items-center max-w-[180px] mr-8'>
                    <Link to='/' className='relative'>
                      <img src={logo} alt='Kaiber Logo' />
                    </Link>
                  </div>
                </div>

                {/* SEARCH */}
                {showSearch && (
                  <div className='flex flex-1 items-center scale-[.85] ml-[-5.5rem]'>
                    <SearchBar
                      getVideos={getVideos}
                      isLoading={isLoading}
                      setSearchText={setSearchText}
                      searchText={searchText}
                      className='w-[37vw] sm:w-[50vw] lg:w-[21vw] xl:w-[29.5rem]'
                    />
                  </div>
                )}

                {/* PAGE LINKS */}

                <div
                  className={cx('relative flex items-center z-0 px-2', {
                    'justify-center lg:inset-0 flex-1 md:items-end lg:items-center':
                      !showSearch,
                    'scale-[.75] absolute right-0 w-[453px]':
                      !isAuthenticated && showSearch,
                    'scale-[.75] absolute right-52':
                      isAuthenticated && showSearch,
                  })}
                >
                  <div className='hidden w-full text-center lg:block'>
                    <div
                      className={cx(
                        'flex flex-wrap items-center list-none justify-center',
                        {
                          'gap-1 text-xs lg:gap-1 lg:text-xs xl:gap-3 xl:text-lg':
                            !showSearch,
                          'gap-2': showSearch,
                        },
                      )}
                    >
                      {navigation.map((item) => {
                        const isActive = window.location.pathname === item.href

                        if (item.href.startsWith('https://')) {
                          return (
                            <a
                              key={item.name}
                              href={item.href}
                              className={cx(
                                'rounded-2xl py-2 px-2 inline-flex items-center font-medium uppercase',
                                isActive
                                  ? 'text-white'
                                  : 'text-tertiary/60 hover:text-gray-50',
                                {
                                  'lg:pb-0 lg:mt-3 xl:pb-2 xl:mt-0':
                                    !showSearch,
                                },
                              )}
                              aria-current={isActive ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          )
                        }

                        // TODO: Refactor these into new components
                        //DropwDown Menu
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        const [dropdown, setDropdown] = useState(false)
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        let ref = useRef()

                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                          const handler = (event) => {
                            if (
                              dropdown &&
                              ref.current &&
                              !ref.current.contains(event.target)
                            ) {
                              setDropdown(false)
                            }
                          }
                          document.addEventListener('mousedown', handler)
                          document.addEventListener('touchstart', handler)
                          return () => {
                            // Cleanup the event listener
                            document.removeEventListener('mousedown', handler)
                            document.removeEventListener('touchstart', handler)
                          }
                        }, [dropdown])

                        return (
                          <li
                            className='relative'
                            key={item.name}
                            ref={ref}
                            onMouseEnter={() => setDropdown(true)}
                            onMouseLeave={() => setDropdown(false)}
                          >
                            {item.submenu ? (
                              <>
                                <button
                                  key={item.name}
                                  aria-haspopup='menu'
                                  aria-expanded={dropdown ? 'true' : 'false'}
                                  // onClick={() => setDropdown((prev) => !prev)}
                                  className={cx(
                                    'rounded-2xl py-2 px-2 inline-flex items-center capitalize gap-2',
                                    dropdown || isActive
                                      ? 'text-primary'
                                      : 'text-white hover:text-primary',
                                    { 'pb-2 ': !showSearch },
                                  )}
                                  aria-current={isActive ? 'page' : undefined}
                                >
                                  <span>{item.name} </span>
                                  <div className='text-primary'>
                                    {dropdown ? (
                                      <IoIosArrowUp />
                                    ) : (
                                      <IoIosArrowDown />
                                    )}
                                  </div>
                                </button>
                                <Dropdown
                                  submenus={item.submenu}
                                  isActive={isActive}
                                  dropdown={dropdown}
                                />
                              </>
                            ) : (
                              <Link
                                key={item.name}
                                to={item.href}
                                className={cx(
                                  'rounded-2xl py-2 px-2 inline-flex items-center capitalize',
                                  isActive
                                    ? ' text-primary'
                                    : 'text-white hover:text-primary',
                                  { 'pb-2': !showSearch },
                                )}
                                aria-current={isActive ? 'page' : undefined}
                              >
                                {item.name}
                              </Link>
                            )}
                          </li>
                        )
                      })}
                    </div>
                  </div>
                </div>

                {/* Mobile menu button */}
                <div className='relative z-10 flex items-center mr-2 lg:hidden'>
                  {isAuthenticated ? (
                    <Link to='/create'>
                      <button className='inline-flex items-center gap-2 px-3 py-2 text-sm font-medium bg-transparent border rounded-full shadow-sm border-primary text-primary hover:border-primary focus:outline-none focus:ring-0 button-shadow'>
                        <PlusIcon className='w-5 h-5' aria-hidden='true' />
                        CREATE
                      </button>
                    </Link>
                  ) : (
                    <a href='/create'>
                      <button className='inline-flex items-center gap-2 px-3 py-2 text-sm font-medium bg-transparent border rounded-full shadow-sm border-primary text-primary hover:border-primary focus:outline-none focus:ring-0 button-shadow'>
                        <PlusIcon className='w-5 h-5' aria-hidden='true' />
                        CREATE
                      </button>
                    </a>
                  )}
                </div>
                {!isAuthenticated && (
                  <div className='hidden gap-2 lg:relative lg:z-10 lg:flex lg:items-center'>
                    <a href='/register'>
                      <button
                        className={cx(
                          'flex items-center justify-center font-medium leading-5 text-black rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40',
                          {
                            'text-xl duration-0 px-4 py-4': !showSearch,
                            'text-[15px] duration-0 px-2 py-2': showSearch,
                          },
                        )}
                      >
                        Start free trial
                      </button>
                    </a>
                    <a href='/login'>
                      <button
                        className={cx(
                          'inline-flex items-center bg-transparent border border-transparent rounded-full shadow-sm text-primary active:text-white hover:border-primary focus:outline-none focus:ring-0',
                          {
                            'text-xl px-4 py-4': !showSearch,
                            'text-[15px] px-2 py-2': showSearch,
                          },
                        )}
                      >
                        Login
                      </button>
                    </a>
                  </div>
                )}

                {/* MENU BUTTONS */}
                {isAuthenticated && (
                  <div
                    className={cx('hidden gap-2 lg:z-10 lg:items-center', {
                      'xl:ml-4 xl:relative lg:flex': !showSearch,
                      'scale-[.75] absolute -right-[41px] lg:flex': showSearch,
                    })}
                  >
                    <Menu as='div' className='relative ml-3'>
                      <div>
                        <Menu.Button className='flex items-center gap-2 px-3 py-2 text-sm bg-transparent border rounded-full border-primary/25 focus:outline-none focus:ring-0'>
                          <span className='sr-only'>Open user menu</span>
                          <UserIcon
                            className='w-6 h-6 text-tertiary/80'
                            aria-hidden='true'
                          />
                          {currentUser?.credits != null && (
                            <span className='px-3 py-2 rounded-full bg-secondary/10'>
                              <p className='flex gap-2 text-tertiary/80'>
                                <TbCoins className='w-4 h-4 text-[#CAD7CA] place-self-center' />
                                {`${currentUser?.credits}`}
                              </p>
                            </span>
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-[#181A18] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <p
                            href='#'
                            className={classNames(
                              'block px-4 py-2 text-sm text-tertiary/80',
                            )}
                          >
                            <span className='break-all'>
                              {currentUser?.email}
                            </span>
                          </p>

                          <div className='h-0.5 bg-tertiary/25 my-2' />
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to='/account'
                                className={classNames(
                                  'block px-4 py-2 text-sm text-tertiary/80 font-bold cursor-pointer hover:text-primary',
                                )}
                              >
                                Account
                              </Link>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              //MARIA
                              <button
                                className={classNames(
                                  'block px-4 py-2 text-sm text-tertiary/80 font-bold cursor-pointer hover:text-primary',
                                )}
                                onClick={() => {
                                  if (isSubscribed) {
                                    navigate('/account?tab=my-plan') // Navigate to the account page with the section id
                                  } else {
                                    navigate('/pricing')
                                  }
                                }}
                              >
                                {myPlanButtonText}
                              </button>
                            )}
                          </Menu.Item>
                          {isSubscribed && (
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    'block px-4 py-2 text-sm text-tertiary/80 font-bold cursor-pointer hover:text-primary',
                                  )}
                                  onClick={() => {
                                    navigate('/pricing?tab=credit-packs')
                                  }}
                                >
                                  Buy Credits
                                </button>
                              )}
                            </Menu.Item>
                          )}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  'block px-4 py-2 text-sm text-tertiary/80 font-bold cursor-pointer hover:text-primary',
                                )}
                                onClick={async () => {
                                  await logout()
                                  await posthog.reset()
                                  window.location.href =
                                    '/login?redirect=' +
                                    window.location.pathname
                                }}
                              >
                                Sign Out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <Link to='/create'>
                      <button className='inline-flex items-center justify-center px-4 py-4 text-xl font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40'>
                        <PlusIcon className='w-5 h-5' aria-hidden='true' />
                        Create Video
                      </button>
                    </Link>
                  </div>
                )}

                {/* Mobile hamburger menu button */}
                <div className='relative z-10 flex items-center lg:hidden'>
                  <Disclosure.Button className='inline-flex items-center justify-center p-2 border rounded-full text-primary border-primary/25 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <span className='sr-only'>Open menu</span>
                    {open ? (
                      <XMarkIcon className='block w-6 h-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block w-6 h-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel
              as='nav'
              className={cx('lg:hidden', { 'bg-dark': faded })}
              aria-label='Global'
            >
              <div className='px-5'>
                {navigation.map((item) => {
                  const isActive = window.location.pathname === item.href
                  return (
                    <Disclosure.Button
                      key={item.name}
                      as={
                        item.href && !item.href.startsWith('https://')
                          ? Link
                          : 'a'
                      }
                      to={item.href || '#'}
                      href={item.href || '#'}
                      className={classNames(
                        isActive ? ' text-primary' : 'text-tertiary',
                        'block rounded-md py-2 px-3 text-base font-medium uppercase',
                      )}
                      aria-current={isActive ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
                })}
              </div>
              <div className='pt-4 pb-3'>
                {isAuthenticated && (
                  <div className='flex flex-row flex-wrap justify-between gap-2 px-5 sm:flex-col'>
                    <div className='flex gap-2 px-3 py-2 text-white'>
                      <UserIcon className='w-4 h-4 place-self-center' />
                      <span>{currentUser?.email}</span>
                    </div>
                    <div className='self-start inline-block px-3 py-2 rounded-full bg-secondary/10'>
                      <p className='flex gap-2 text-tertiary/80'>
                        <TbCoins className='w-4 h-4 text-[#CAD7CA] place-self-center' />
                        {`${currentUser?.credits}`}
                      </p>
                    </div>
                  </div>
                )}
                <div className='px-5 mt-3 space-y-1'>
                  {userNavigation.map(
                    (item) =>
                      item && (
                        <Disclosure.Button
                          key={item.name}
                          as={
                            item.href && !item.href.startsWith('https://')
                              ? Link
                              : 'a'
                          }
                          to={item.href || '#'}
                          href={item.href || '#'}
                          onClick={item.onClick}
                          className='block px-3 py-2 text-base font-medium text-gray-500 rounded-md'
                        >
                          {item.name}
                        </Disclosure.Button>
                      ),
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {showBanner && <Banner />}
      {announcementsModal && announcement && (
        <AnnouncementsModal
          isOpen={announcementsModal}
          setIsOpen={setAnnouncementsModal}
          announcement={announcement}
        />
      )}
      {isImpersonating && (
        <div className='absolute left-0 top-0 py-0.5 px-0.5 bg-red-600 text-white text-xs'>
          ADMIN LOGGED IN AS USER
        </div>
      )}
    </div>
  )
}
