import { forwardRef } from 'react'

import { useThemeContext } from '../../../context'
import { toTitleCase } from '../../../utils/formUtils'

interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  min: number | string
  max: number | string
  step?: number
  label?: string
}

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  ({ min, max, step = 1, label = '', value, onChange, ...rest }, ref) => {
    const { colors } = useThemeContext()
    const currentWidthPercent =
      ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100

    return (
      <div className='flex flex-col gap-1 w-full nodrag'>
        {label && (
          <label
            htmlFor={rest.id}
            className={`my-2 text-sm  text-left ${colors.text.subtle}`}
          >
            {toTitleCase(label)}
          </label>
        )}
        <div
          className={`flex items-center relative w-full h-6 rounded-xl py-1 px-1 overflow-hidden border ${colors.background.inactive}`}
        >
          <div className='absolute inset-0 flex items-center justify-center z-10'>
            <span className={`text-sm ${colors.text.default}`}>{value}</span>
          </div>
          <div
            className={`absolute h-full left-0 top-0 pointer-events-none ${colors.background.gradient}`}
            style={{
              width: `calc(${currentWidthPercent}% - ${(10 * currentWidthPercent) / 100 - 4}px)`,
            }}
          ></div>
          <input
            type='range'
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            ref={ref}
            className='appearance-none w-full bg-transparent slider-thumb h-[12px] z-20'
            {...rest}
          />
        </div>
      </div>
    )
  },
)

Slider.displayName = 'Slider'
