import { useNavigate } from 'react-router-dom'
import { Loading } from '../components/loading'
import { useInitialLoad } from '../hooks'
import { getCanvases } from '../services/CanvasService'

export const FlowCanvasSelector = () => {
  const navigate = useNavigate()

  useInitialLoad(async () => {
    try {
      const canvases = await getCanvases()
      navigate(`/flow-canvas/${canvases[0].id}`)
    } catch (error: any) {
      if (error.response) {
        console.error(error.response.data.message)
      } else {
        console.error('Can not load the canvas slots', error)
      }
    }
  })

  return <Loading text='Loading Canvas...' />
}
