import { AiOutlineQuestionCircle } from 'react-icons/ai'
import classNames from 'classnames'

const DropdownMenu = ({
  title,
  description,
  handleChange,
  options = [],
  selectedOption,
}) => {
  const handleDropdownChange = (event) => {
    handleChange(event.target.value)
  }

  return (
    <div className='mb-12'>
      <div className='flex mb-5 gap-2 items-center'>
        <h1 className='text-xl lg:text-2xl xl:text-3xl text-primary'>
          {title}
        </h1>
        {/* Show information tooltip */}
        <div className='flex has-tooltip'>
          <span className='tooltip rounded shadow-lg px-2 py-1 bg-gray-800 text-gray-100 -mt-8 text-sm'>
            {description}
          </span>
          <AiOutlineQuestionCircle className='w-6 h-6 self-center select-none text-primary' />
        </div>
      </div>
      {/* Dropdown */}
      <div className='relative rounded-lg w-64 border border-gray-500'>
        <select
          value={selectedOption}
          onChange={handleDropdownChange}
          className={classNames(
            'block appearance-none w-full bg-transparent text-primary py-2 px-3 pr-6 rounded-lg leading-tight focus:outline-none focus:border-primary text-base',
            'focus:outline-none focus:border focus:border-primary',
          )}
        >
          {options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              className='bg-dark bg-opacity-100 text-base text-lg'
            >
              {option.name}
            </option>
          ))}
        </select>
        <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-primary'>
          <svg
            className='fill-current h-3 w-3'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
          >
            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default DropdownMenu
