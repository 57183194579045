import { useState } from 'react'
import { FiEdit } from 'react-icons/fi'

import { UpdateBioModal } from '../components/UpdateBioModal'
import { cn } from '../utils'

export const Bio = ({ bio, canEdit, onUpdate }) => {
  const [showEditIcon, setShowEditIcon] = useState(false)
  const [updatingBio, setUpdatingBio] = useState(false)

  const toggleEditIcon = () => setShowEditIcon(!showEditIcon)
  const openBioModal = () => setUpdatingBio(true)

  if (!bio && !canEdit) return null

  return (
    <button
      className={cn(
        'p-4 rounded-xl bg-darkGray flex text-left',
        !canEdit && 'cursor-default',
      )}
      onMouseEnter={canEdit ? toggleEditIcon : null}
      onMouseLeave={canEdit ? toggleEditIcon : null}
      onClick={canEdit ? openBioModal : null}
    >
      <span className='relative inline-block w-6 h-6 mr-1'>
        <FiEdit
          className={cn(
            'absolute inset-0 w-6 h-5 text-primary ease-in-out transition-opacity duration-500',
            showEditIcon ? 'opacity-100' : 'opacity-0',
          )}
        />
        <img
          src='/bio_spark.svg'
          className={cn(
            'absolute inset-0 w-6 h-6 ease-in-out transition-opacity duration-500',
            showEditIcon ? 'opacity-0' : 'opacity-100',
          )}
          alt='bio spark'
        />
      </span>
      <div className='flex-1'>
        <p className='text-gray-400'>{bio || (canEdit && 'Add a bio')}</p>
      </div>

      {canEdit && (
        <UpdateBioModal
          isOpen={updatingBio}
          setOpen={setUpdatingBio}
          onUpdate={onUpdate}
        />
      )}
    </button>
  )
}
