import { toast } from 'react-toastify'

import http from '../services/HttpService'
import { Modal } from './Modal'

/**
 * @typedef {Object} Subscriptions
 * @property {Array} stripeSubscriptions - Stripe subscriptions
 * @property {Array} paddleSubscriptions - Paddle subscriptions
 * @property {Array} googleTransaction - Google transactions
 * @property {Array} appleTransaction - Apple transactions
 */

/**
 *
 * @param {Object} props
 * @param {boolean} props.isOpen If the modal is open
 * @param {string} props.distinctId The user's distinct ID
 * @param {boolean} props.missingPaddleSubscription If the user is missing a Paddle subscription
 * @param {boolean} props.paddleSubscriptionMismatch If the user's Paddle subscription is different than the subscription in Kaiber
 * @param {boolean} props.missingStripeSubscription If the user is missing a Stripe subscription
 * @param {boolean} props.hasGoogleSubscription If the user has a Google subscription
 * @param {boolean} props.hasAppleSubscription If the user has an Apple subscription
 * @param {Subscriptions} props.subscriptions The user's subscriptions
 */
export const RestoreSubscriptionModal = ({
  isOpen,
  distinctId,
  missingPaddleSubscription,
  paddleSubscriptionMismatch,
  missingStripeSubscription,
  hasGoogleSubscription,
  hasAppleSubscription,
  subscriptions,
  onClose,
  refreshUser,
}) => {
  const handleRestoreStripeSubscription = async () => {
    try {
      onClose(false)
      await http.patch(
        `/api/admin/users/${distinctId}/restore-stripe-subscription`,
      )
      toast.success('The subscription has been restored.')
      refreshUser()
    } catch (error) {
      console.log(error)
    }
  }

  const handleRestorePaddleSubscription = async () => {
    try {
      onClose(false)
      await http.patch(
        `/api/admin/users/${distinctId}/restore-paddle-subscription`,
      )

      toast.success('The subscription has been restored.')
      refreshUser()
    } catch (error) {
      console.log(error)
    }
  }

  const handleRestoreGoogleSubscription = async () => {
    try {
      onClose(false)
      await http.patch(
        `/api/admin/users/${distinctId}/restore-google-subscription`,
        {
          googleTransaction: subscriptions.googleTransaction,
        },
      )
      toast.success('The subscription has been restored.')
      refreshUser()
    } catch (error) {
      console.error(error)
    }
  }

  async function handleRestoreAppleSubscription() {
    try {
      onClose(false)
      await http.patch(
        `/api/admin/users/${distinctId}/restore-apple-subscription`,
        {
          appleTransaction: subscriptions.appleTransaction,
        },
      )
      toast.success('The subscription has been restored.')
      refreshUser()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal open={isOpen} setOpen={onClose}>
      {missingStripeSubscription && (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <p className='text-2xl sm:text-5xl text-tertiary'>
            Restore Stripe subscription?
          </p>
          <p className='text-tertiary/80'>
            This user has an active Stripe subscription, but no subscription on
            Kaiber. Clicking 'Yes, restore subscription' will restore their
            subscription status.
            <br />
            {subscriptions.stripeSubscriptions.length > 1 && (
              <span className='text-orange-400'>
                <br />
                This user has multiple active subsriptions in Stripe. Please
                check the user's Stripe account to ensure only one subscription
                is active. Clicking 'Yes, restore subscription' will restore the
                first subscription.
              </span>
            )}
          </p>
          <div className='mt-4 flex items-center justify-end space-x-2'>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
              onClick={() => onClose(false)}
            >
              No, cancel
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestoreStripeSubscription}
            >
              Yes, restore subscription
            </button>
          </div>
        </div>
      )}

      {(missingPaddleSubscription || paddleSubscriptionMismatch) && (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <p className='text-2xl sm:text-5xl text-tertiary'>
            Restore Paddle subscription?
          </p>
          <p className='text-tertiary/80'>
            {missingPaddleSubscription === true && (
              <span>
                This user has an active Paddle subscription, but no subscription
                on Kaiber. Clicking 'Yes, restore subscription' will restore
                their subscription status.
                <br />
              </span>
            )}
            {paddleSubscriptionMismatch === true && (
              <span>
                This user's Kaiber subscription is different than the
                subscription in Paddle. Clicking 'Yes, restore subscription'
                will restore their Kaiber subscription status.
                <br />
              </span>
            )}
            {subscriptions.paddleSubscriptions.length > 1 && (
              <span className='text-orange-400'>
                <br />
                This user has multiple active subsriptions in Paddle. Please
                check the user's Paddle account to ensure only one subscription
                is active. If there is more than one subscription, then clicking
                'Yes, restore subscription' will restore the first subscription.
              </span>
            )}
          </p>
          <div className='mt-4 flex items-center justify-end space-x-2'>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
              onClick={() => onClose(false)}
            >
              No, cancel
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestorePaddleSubscription}
            >
              Yes, restore subscription
            </button>
          </div>
        </div>
      )}

      {hasGoogleSubscription && hasAppleSubscription ? (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <p className='text-2xl sm:text-5xl text-tertiary'>
            Restore Google or Apple subscription?
          </p>
          <p className='text-tertiary/80'>
            This user has an active Google and Apple subscription, but no
            subscription on Kaiber. Clicking 'Restore subscription' will restore
            their respective subscription status.
          </p>
          <div className='mt-4 flex items-center justify-center space-x-2'>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-transparent px-2 py-2 text-xs font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
              onClick={() => onClose(false)}
            >
              No, cancel
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-1 py-2 text-xs font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestoreAppleSubscription}
            >
              Restore Apple subscription
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-1 py-2 text-xs font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestoreGoogleSubscription}
            >
              Restore Google subscription
            </button>
          </div>
        </div>
      ) : hasGoogleSubscription ? (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <p className='text-2xl sm:text-5xl text-tertiary'>
            Restore Google subscription?
          </p>
          <p className='text-tertiary/80'>
            This user has an active Google subscription, but no subscription on
            Kaiber. Clicking 'Yes, restore subscription' will restore their
            subscription status.
          </p>
          <div className='mt-4 flex items-center justify-end space-x-2'>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
              onClick={() => onClose(false)}
            >
              No, cancel
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestoreGoogleSubscription}
            >
              Yes, restore subscription
            </button>
          </div>
        </div>
      ) : hasAppleSubscription ? (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <p className='text-2xl sm:text-5xl text-tertiary'>
            Restore Apple subscription?
          </p>
          <p className='text-tertiary/80'>
            This user has an active Apple subscription, but no subscription on
            Kaiber. Clicking 'Yes, restore subscription' will restore their
            subscription status.
          </p>
          <div className='mt-4 flex items-center justify-end space-x-2'>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
              onClick={() => onClose(false)}
            >
              No, cancel
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestoreAppleSubscription}
            >
              Yes, restore subscription
            </button>
          </div>
        </div>
      ) : null}
    </Modal>
  )
}
