import { useEffect, useState } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'

import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import { Modal } from './Modal'

export default function FreeTrialUsageAlertModalComponent({ children }) {
  const [open, setOpen] = useState(false)
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const navigate = useNavigate()

  const markAsRead = async () => {
    try {
      await http.patch('/api/auth/myaccount/free-trial-usage-alert')
    } catch (err) {}
  }

  const handleModalClose = () => {
    setOpen(false)

    markAsRead()

    setTimeout(() => {
      setCurrentUser((currentUser) => ({
        ...currentUser,
        showFreeTrialUsageAlert: false,
      }))

      if (window.location.pathname !== '/pricing') {
        navigate('/pricing')
      }
    }, 100)
  }

  useEffect(() => {
    if (currentUser?.showFreeTrialUsageAlert) {
      setOpen(true)
    }
  }, [currentUser])

  if (!currentUser?.showFreeTrialUsageAlert) {
    return children
  }

  return (
    <>
      {children}

      <Modal open={open} setOpen={handleModalClose}>
        <div className='text-center px-6 py-6 sm:px-12 sm:py-10 w-full'>
          <ExclamationTriangleIcon className='w-10 h-10 inline text-yellow-300' />
          <p className='mt-4 text-4xl sm:text-5xl text-tertiary'>
            Free Trial Expired
          </p>
          <p className='mt-4 text-tertiary/80'>
            Your free trial has already been used on another account. Upgrade to
            a paid subscription to access Kaiber’s full range of creative tools.
          </p>
          <div className='mt-8'>
            <button
              onClick={handleModalClose}
              className='text-2xl text-primary underline'
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
