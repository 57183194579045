import { useFormContext } from 'react-hook-form'

import { Slider } from '../../../../../components/k2/Fields/Slider'
import { DEFAULT_FORM_VALUES, LABELS } from '../../../../../constants'
import { FieldType } from '../../../../../types'
import { CanvasBaseElement } from '../../CanvasBaseElement'

export const UpscaleV1Settings = () => {
  const { register, setValue, watch } = useFormContext()
  const denoiseValue = watch(FieldType.Denoise)
  const creativityScaleValue = watch(FieldType.CreativityScale)

  return (
    <CanvasBaseElement label={LABELS.UPSCALE_V1_SETTINGS}>
      <div className='flex flex-col space-y-4'>
        <div className='flex flex-col space-y-2'>
          <label htmlFor='denoise' className='text-sm font-medium'>
            Denoise Strength
          </label>
          <Slider
            id='denoise'
            min={0}
            max={1}
            step={0.01}
            value={[denoiseValue]}
            defaultValue={DEFAULT_FORM_VALUES.denoise}
            onChange={(e) => setValue(FieldType.Denoise, e.target.value)}
            {...register(FieldType.Denoise)}
          />
        </div>

        <div className='flex flex-col space-y-2'>
          <label htmlFor='creativityScale' className='text-sm font-medium'>
            Creativity Scale
          </label>
          <Slider
            id='creativityScale'
            min={0}
            max={3}
            step={0.1}
            defaultValue={DEFAULT_FORM_VALUES.creativityScale}
            value={[creativityScaleValue]}
            onChange={(e) =>
              setValue(FieldType.CreativityScale, e.target.value)
            }
            {...register(FieldType.CreativityScale)}
          />
        </div>

        <input
          {...register(FieldType.Version)}
          defaultValue={DEFAULT_FORM_VALUES.version}
          hidden
        />
      </div>
    </CanvasBaseElement>
  )
}
