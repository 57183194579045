import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import cookies from 'browser-cookies'
import Lottie from 'lottie-react'

import logo from '../../images/welcome/logo.png'
import google from '../../images/google.svg'
import appleLogo from '../../images/apple-white.svg'
import LoadingWhite from '../../images/lottie/loaderWhite.json'
import { register, getGoogleAuthorisationURL } from '../../services/AuthService'
import { Alert } from '../../components/Alert'
import { PasswordInput } from '../../components/PasswordInput'
import { Modal } from '../../components/Modal'
import AppleLogin from 'react-apple-login'

export default function Register() {
  const { referrerId } = useParams() // Rewardful user ID from URL (eg. ?via=kevinpixelbot) ... doesn't seem to work ... ?
  const [errors, setErrors] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false)

  const navigate = useNavigate()

  const clearError = (field) => {
    setErrors((errors) => ({
      ...errors,
      [field]: null,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    const email = e.target[0].value
    //const password = e.target[1].value
    const passwordConfirmation = e.target[2].value
    const newsletter = e.target[3].checked

    // Legacy code for rewardful
    const referrerIdFromCookies = cookies.get('referrer_id') // [TO BE DEPRECATED] - Rewardful user ID from URL (eg. ?via=kevinpixelbot) ... doesn't seem to work ... ?
    const rewardfulReferral = JSON.parse(cookies.get('rewardful.referral')) // [TO BE DEPRECATED]
    const rewardfulReferrerId = rewardfulReferral?.affiliate?.id // [TO BE DEPRECATED] - ID of the affiliate
    const rewardfulObjectId = rewardfulReferral?.id // [TO BE DEPRECATED] - ID of the referral
    const impactPartnerId = cookies.get('impact_partner_id')
    const impactPartnerToken = cookies.get('impact_partner_token')

    // New Affiliate tracking code
    const affiliateLinkToken = cookies.get('affiliate') // [TO BE DEPRECATED]

    try {
      await register(
        email,
        password,
        passwordConfirmation,
        newsletter,
        referrerId || referrerIdFromCookies, // [TO BE DEPRECATED]
        rewardfulReferrerId, // [TO BE DEPRECATED]
        rewardfulObjectId, // [TO BE DEPRECATED]
        affiliateLinkToken, // [TO BE DEPRECATED]
        impactPartnerId, // For users using new impact partner links
        impactPartnerToken, // For users using old legacy partner links
      )

      setShowVerifyEmailModal(true)
    } catch (err) {
      setIsSubmitting(false)
      console.log(err)
      if (err.response) {
        switch (err.response.status) {
          case 401:
            setErrors({
              email: err.response.data.message,
            })
            break
          case 400:
            setErrors(err.response.data.errors)
            break
          default:
            setAlertMessage('Oops! Something went wrong.')
            setShowAlert(true)
        }
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
        setShowAlert(true)
      }
    }
  }

  const handleSignUpWithGoogle = async () => {
    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    try {
      const response = await getGoogleAuthorisationURL()
      const newsletter =
        document.querySelector(`input[type='checkbox']#newsletter`)?.checked ||
        false

      window.sessionStorage.setItem('NEWSLETTER', newsletter)

      if (referrerId) {
        window.sessionStorage.setItem('REFERRER_ID', referrerId)
      }

      window.addEventListener('pagehide', handlePageHide)

      window.location.href = response.data.authUrl
    } catch (err) {
      setIsSubmitting(false)
      setShowAlert(true)

      if (err.response) {
        setAlertMessage(err.response.data.message)
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    }
  }

  const handlePageHide = (e) => {
    setIsSubmitting(false)
    window.removeEventListener('pagehide', handlePageHide)
  }

  const getDefaultEmail = () => {
    const query = new URLSearchParams(window.location.search)

    return query.get('email')
  }

  // const initRewardful = () => {
  //   const query = new URLSearchParams(window.location.search)
  //   const rewardfulLink = query.get('via')

  //   if (rewardfulLink) {
  //     window.rewardful('source', rewardfulLink)
  //   }
  // }

  useEffect(() => {
    setDefaultEmail(getDefaultEmail())
    // initRewardful()
  }, [])

  return (
    <>
      <div
        className='flex h-full overflow-y-auto sm:p-10 bg-darkGray sm:bg-transparent bg-cover bg-center'
        style={{
          backgroundImage: 'url(../website/welcome/bg_quote_new.png)',
        }}
      >
        <div className='w-full p-10 m-auto sm:max-w-sm bg-black sm:rounded-3xl sm:relative'>
          <div className='mb-8 px-6 text-center'>
            <a
              href={process.env.REACT_APP_SITE_URL}
              className='relative inline-block'
            >
              <span className='border border-primary/25 rounded-full py-0.5 px-1.5 text-primary/75 text-xs absolute -right-8 -top-4'>
                BETA
              </span>
              <img src={logo} alt='Logo' />
            </a>
          </div>

          {showAlert && (
            <div className='mb-4'>
              <Alert
                open={showAlert}
                setOpen={setShowAlert}
                message={alertMessage}
                type='alert'
              />
            </div>
          )}
          <Modal
            children={
              <div className='flex flex-col items-center justify-center py-3 relative'>
                <div className='flex flex-col items-center justify-center'>
                  <h3 className='mb-2 text-2xl font-bold text-center text-white'>
                    Verify your email address
                  </h3>
                  <p className='mb-4 text-center text-gray-400'>
                    We've sent you an email with a link to verify your email
                    address. Check your spam folder if you don't receive it
                    within a few minutes.
                  </p>
                </div>
              </div>
            }
            open={showVerifyEmailModal}
            setOpen={() => {
              setShowVerifyEmailModal(false)
              navigate('/login')
            }}
            persistent={true}
          />

          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Email</strong>
              </label>
              <input
                type='email'
                id='email'
                onInput={() => clearError('email')}
                required
                defaultValue={defaultEmail}
                placeholder='Enter email address'
                className={`w-full mt-2 block ${
                  errors.email ? 'border-red-700' : 'border-transparent'
                } border rounded-full shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3 px-5 bg-[#303030] text-white`}
                disabled={isSubmitting}
              />
              {errors.email && (
                <span className='text-red-500'>{errors.email}</span>
              )}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='password'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Password</strong>
              </label>
              <PasswordInput
                id='password'
                onInput={(e) => {
                  clearError('password')
                  setPassword(e.target.value)
                }}
                required
                placeholder='Enter password'
                className={`w-full mt-2 block ${
                  errors.password ? 'border-red-700' : 'border-transparent'
                } border rounded-full shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3 px-5 bg-[#303030] text-white`}
                disabled={isSubmitting}
              />
              {errors.password && (
                <span className='text-red-500'>{errors.password}</span>
              )}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='password-confirmation'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Confirm Password</strong>
              </label>
              <input
                type='password'
                id='password-confirmation'
                onInput={() => clearError('passwordConfirmation')}
                required
                placeholder='Enter password'
                className={`w-full mt-2 block ${
                  errors.passwordConfirmation
                    ? 'border-red-700'
                    : 'border-transparent'
                } border rounded-full shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3 px-5 bg-[#303030] text-white`}
                disabled={isSubmitting}
              />
              {errors.passwordConfirmation && (
                <span className='text-red-500'>
                  {errors.passwordConfirmation}
                </span>
              )}
            </div>
            <div className='mb-4 px-5'>
              <label>
                <input
                  type='checkbox'
                  id='newsletter'
                  className='float-left mt-[6px]'
                  disabled={isSubmitting}
                />
                <span className='block ml-5 text-xs text-[#545854] font-medium'>
                  I would like to subscribe to emails from Kaiber
                </span>
              </label>
            </div>
            <div className='my-4 text-left px-5 leading-3'>
              <span className='text-xs text-gray-400'>
                By signing up, I agree to the{' '}
                <a
                  href='/terms'
                  className='hover:text-tertiary/80 text-[#97e8a6]'
                >
                  terms of service
                </a>{' '}
                and{' '}
                <a
                  href='/privacy'
                  className='hover:text-tertiary/80 text-[#97e8a6]'
                >
                  privacy policy
                </a>
                .
              </span>
            </div>
            <button
              type='submit'
              className='w-full p-4 py-3 text-base font-medium text-gray-900 border-2 rounded-full shadow-sm bg-primary border-primary'
              disabled={isSubmitting}
            >
              Register
            </button>
          </form>

          <a
            href='/login'
            className='block w-full px-4 py-3 mt-4 text-base font-medium text-center text-primary border-2 rounded-full shadow-sm border-primary'
          >
            Sign In
          </a>

          <p className='my-5 text-center text-gray-400'>or</p>

          <button
            type='button'
            className='flex items-center justify-center w-full py-1 p-2 border rounded-full shadow-sm border-primary'
            onClick={handleSignUpWithGoogle}
            disabled={isSubmitting}
          >
            <img src={google} alt='Google Icon' />
            <span className='text-primary'>Sign up with Google</span>
          </button>

          <div className='mt-4'>
            <AppleLogin
              clientId={process.env.REACT_APP_APPLE_WEB_CLIENT_ID}
              redirectURI={process.env.REACT_APP_APPLE_WEB_REDIRECT_URI}
              usePopup={false}
              scope='email name'
              responseMode='form_post'
              responseType='code'
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  type='button'
                  className='flex items-center justify-center w-full py-1 p-2 border rounded-full shadow-sm border-primary'
                >
                  <img
                    className='m-2 mr-3'
                    width={20}
                    height={20}
                    src={appleLogo}
                    alt='Apple Icon'
                  />
                  <span className='text-primary'>Sign in with Apple</span>
                </button>
              )}
            />
          </div>

          {isSubmitting && (
            <div className='absolute inset-0 flex w-full h-full bg-gray-500 bg-opacity-75 sm:rounded-3xl'>
              <div className='flex flex-col items-center m-auto space-y-4'>
                <div className='p-6 bg-darkGray rounded-3xl'>
                  <Lottie
                    animationData={LoadingWhite}
                    loop={true}
                    className='w-16 h-16'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
