import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from '@xyflow/react'

import { theme } from '../../../../tailwind.config.js'
import { useThemeContext } from '../../../context'

export const CustomEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  selected, // Add this prop
}: EdgeProps) => {
  const { colors } = useThemeContext()
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  // Determine the stroke color based on the selected state
  const strokeColor = selected
    ? theme.extend.colors.k2.green['750']
    : colors.hex.brand

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: 'none' }}
      />
      <svg
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          pointerEvents: 'none',
        }}
      >
        <path
          d={edgePath}
          fill='none'
          strokeWidth={3}
          stroke={strokeColor}
          strokeDasharray='5,5'
          className='animate-march'
        />
      </svg>
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
          className='nodrag nopan'
        ></div>
      </EdgeLabelRenderer>
    </>
  )
}
