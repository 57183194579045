import { useFormContext } from 'react-hook-form'

import { ImageDimensionsInput } from './ImageDimensionsInput'
import { DEFAULT_FORM_VALUES, LABELS } from '../../../../../constants'
import { CanvasBaseElement } from '../../CanvasBaseElement'
import { FieldType } from '@/types'

export const ImageSettingsV2 = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const imageUpload = watch(FieldType.ImageUpload)
  const controlNetUpload = watch(FieldType.ControlnetReferenceUpload)

  const isImageUploaded =
    (!!imageUpload && imageUpload.length > 0) ||
    (!!controlNetUpload && controlNetUpload.length > 0)

  const errorMessage =
    (errors[FieldType.Width]?.message as string) ||
    (errors[FieldType.Height]?.message as string)

  return (
    <CanvasBaseElement label={LABELS.IMAGE_DETAILS} errorMessage={errorMessage}>
      <div className='flex gap-3 flex-col min-w-[200px]'>
        <input
          {...register(FieldType.Version)}
          defaultValue={DEFAULT_FORM_VALUES.version}
          hidden
        />

        <ImageDimensionsInput isImageUploaded={isImageUploaded} />
      </div>
    </CanvasBaseElement>
  )
}
