import { useCallback } from 'react'
import { useReactFlow, useStoreApi } from '@xyflow/react'

export const useDetachNodes = () => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const detachNodes = useCallback(
    (ids: string[], removeParentId?: string) => {
      const { nodeLookup } = store.getState()
      const nextNodes = Array.from(nodeLookup.values()).map((n) => {
        if (ids.includes(n.id) && n.parentId) {
          const parentNode = nodeLookup.get(n.parentId)
          return {
            ...n,
            position: {
              x: n.position.x + (parentNode?.position?.x ?? 0),
              y: n.position.y + (parentNode?.position?.y ?? 0),
            },
            expandParent: undefined,
            parentNode: undefined,
            parentId: undefined, // Remove the parentId
          }
        }
        return n
      })

      setNodes(
        nextNodes.filter((n) => !removeParentId || n.id !== removeParentId),
      )

      return nextNodes.filter((n) => ids.includes(n.id)) // Return the detached nodes
    },
    [setNodes, store],
  )

  return detachNodes
}
