import { forwardRef } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { scrollToTop } from '../utils'

export const SearchBar = forwardRef(
  (
    { getVideos, isLoading, setSearchText, searchText, className = '' },
    ref,
  ) => {
    const handleSearch = async (e) => {
      if (e.key === 'Enter' && !isLoading) {
        scrollToTop()
        getVideos()
      }
    }

    const handleClearSearch = () => {
      if (ref) ref.current.value = ''
      setSearchText('')
      scrollToTop()
      getVideos(1, '')
    }

    return (
      <div className='flex justify-center'>
        <div className='relative flex items-center'>
          <MagnifyingGlassIcon
            className='absolute w-5 h-5 pointer-events-none left-3 text-tertiary/80'
            aria-hidden='true'
          />
          <input
            type='text'
            name='search'
            id='search'
            placeholder='Search for videos'
            className={`w-full sm:mt-0 block rounded-full border-gray-300 shadow-md outline-none py-3 pl-11 pr-10 bg-[#545854]/25 text-white ${className}`}
            onKeyUp={handleSearch}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            ref={ref}
          />
          {!!searchText && (
            <XCircleIcon
              className='absolute w-6 h-6 text-gray-400 transition duration-200 ease-in-out right-3 opacity-80 hover:cursor-pointer hover:opacity-100'
              onClick={handleClearSearch}
              aria-label='Clear search query'
            />
          )}
        </div>
      </div>
    )
  },
)
