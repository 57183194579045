import { SVGProps } from '@/types'
import { forwardRef } from 'react'

export const AddToFlowIcon = ({ size = 28, className }: SVGProps) => (
  <svg
    stroke='currentColor'
    fill='none'
    height={size}
    width={size}
    viewBox='0 0 32 32'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <Paths />
  </svg>
)

interface AddToFlowIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const AddToFlowIconWithRef = forwardRef<
  SVGSVGElement,
  AddToFlowIconProps
>(({ size = '20', ...props }, ref) => (
  <svg
    ref={ref}
    stroke='currentColor'
    fill='none'
    height={size}
    width={size}
    viewBox='0 0 32 32'
    {...props}
    xmlns='http://www.w3.org/2000/svg'
  >
    <Paths />
  </svg>
))

const Paths = () => (
  <>
    <path
      d='M25.667 6.33337H6.33366C4.8609 6.33337 3.66699 7.52728 3.66699 9.00004V23C3.66699 24.4728 4.8609 25.6667 6.33366 25.6667H25.667C27.1398 25.6667 28.3337 24.4728 28.3337 23V9.00004C28.3337 7.52728 27.1398 6.33337 25.667 6.33337Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.0003 11.6666V16M16.0003 16V20.3333M16.0003 16H11.667M16.0003 16H20.3337'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>
)
