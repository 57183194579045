import { useEffect, useState } from 'react'
import Lottie from 'lottie-react'

import LoadingWhite from '../images/lottie/loaderWhite.json'
import { deleteMyAccount } from '../services/AuthService'
import { Alert } from '../components/Alert'
import { Modal } from '../components/Modal'

export function DeleteAccountForm() {
  const [errors, setErrors] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const clearError = (field) => {
    setErrors((errors) => ({
      ...errors,
      [field]: null,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    const currentPassword = e.target[0].value

    try {
      await deleteMyAccount(currentPassword)
      window.location.href = '/login'
    } catch (err) {
      setIsSubmitting(false)

      if (err.response) {
        switch (err.response.status) {
          case 400:
            setErrors(err.response.data.errors)
            break
          default:
            setAlertMessage('Oops! Something went wrong.')
            setShowAlert(true)
        }
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
        setShowAlert(true)
      }
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      setShowAlert(false)
      setErrors({})
    }
  }, [isModalOpen])

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6 pb-10 mb-1 pt-10'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl font-medium leading-6 text-kaiberGreen'>
              Delete Account
            </h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='rounded-2xl px-4 py-5 sm:p-6 relative sm:pt-0'>
            <button
              className='w-full rounded-full border bg-black border-kaiberRed p-4 text-base font-normal text-kaiberRed shadow-sm'
              onClick={() => setIsModalOpen(true)}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        setOpen={isSubmitting ? () => {} : setIsModalOpen}
        persistent={isSubmitting}
      >
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          {isSubmitting ? (
            <div className='flex justify-center'>
              <Lottie
                animationData={LoadingWhite}
                loop={true}
                className='w-16 h-16'
              />
            </div>
          ) : (
            <>
              <p className='text-2xl sm:text-4xl text-tertiary'>
                Delete Account?
              </p>
              <p className='text-tertiary/80'>
                WARNING: Deleting your account will cancel your subscription
                without refund and permanently remove all your data and
                creations from Kaiber. This action is irreversible.
              </p>
              <div className='mt-4 flex flex-col items-center justify-end gap-2'>
                {showAlert && (
                  <div className='mb-4 w-full'>
                    <Alert
                      open={showAlert}
                      setOpen={setShowAlert}
                      message={alertMessage}
                      type='alert'
                    />
                  </div>
                )}

                <form onSubmit={handleSubmit} className='w-full'>
                  <div className='mb-8'>
                    <label
                      htmlFor='delete-account-current-password'
                      className='block text-tertiary'
                    >
                      <strong>Current Password</strong>
                    </label>
                    <input
                      type='password'
                      id='delete-account-current-password'
                      onInput={() => clearError('currentPassword')}
                      required
                      className={`w-full mt-3 block ${
                        errors.currentPassword
                          ? 'border-red-700'
                          : 'border-transparent'
                      } border rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3 bg-[#545854]/25 text-white`}
                      disabled={isSubmitting}
                    />
                    {errors.currentPassword && (
                      <span className='text-red-500'>
                        {errors.currentPassword}
                      </span>
                    )}
                  </div>
                  <div className='flex flex-col sm:flex-row gap-4'>
                    <button
                      type='button'
                      className='rounded-full text-center border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25 w-full'
                      onClick={() => setIsModalOpen(false)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='w-full sm:w-[500px] rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
                      disabled={isSubmitting}
                    >
                      Yes, delete account
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}
