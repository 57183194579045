import { useCallback, useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'

import http from '../../services/HttpService'
import AdminNavbar from '../../components/AdminNavbar'
import Footer from '../../components/Footer'
import { VideoGallery } from '../../components/VideoGallery'
import { SearchBar } from '../../components/SearchBar'
import { useVideoEventsContext } from '../../context/videoEventsContext'
import VideoDeletionModal from '../../components/VideoDeletionModal'

export default function AdminGallery() {
  const [videos, setVideos] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [offsetDescription, setOffsetDescription] = useState(null)
  const [totalItems, setTotalItems] = useState(null)
  const [hasPagination, setHasPagination] = useState(false)
  const [isVideoDeletionModalActive, setIsVideoDeletionModalActive] =
    useState(false)
  const [videoDeletionId, setVideoDeletionId] = useState(null)
  const [isAdminDeleted, setIsAdminDeleted] = useState(null)
  const [userSelectedData, setUserSelectedData] = useState(null)

  const searchInputRef = useRef(null)

  const { trackVideos } = useVideoEventsContext()

  const getVideos = useCallback(
    async (page = 1) => {
      setIsLoading(true)

      if (page === 1 && videos.length > 0) {
        setVideos([])
        setCurrentPage(1)
      }

      try {
        const { data } = await http.get(
          `/api/admin/get-gallery-videos?page=${page}`,
          {
            params: {
              q: searchInputRef?.current?.value ?? '',
              isAdmin: true,
            },
          },
        )
        setVideos(data.items)
        setTotalPages(data.totalPages)

        // for pagination
        setTotalItems(data.totalItems)
        setOffsetDescription(data.offsetDescription)
        setHasPagination(data.hasPagination)

        trackVideos(data.items)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    },
    [videos.length, trackVideos],
  )

  const handlePageClick = (event) => {
    const page = event.selected + 1
    setCurrentPage(page)
    getVideos(page, searchText)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const toggleVideoDeletionModal = async (
    isOpen,
    videoId,
    isAdminDeleted,
    userData,
  ) => {
    setUserSelectedData(userData || null)
    setIsVideoDeletionModalActive(isOpen)
    setVideoDeletionId(videoId || null)
    setIsAdminDeleted(isAdminDeleted || null)
  }

  const toggleVideoAdminDeleted = (videoId) => {
    setVideos((videos) =>
      videos.map((v) =>
        v._id === videoId
          ? { ...v, adminDeleted: !v.adminDeleted || false }
          : v,
      ),
    )
  }

  useEffect(() => {
    getVideos(currentPage)
    // note: do not include getVideos in dependency array otherwise infinite calls
    // TODO: test if this is still the case after adjusting getVideos dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  // focus on search on intial load
  useEffect(() => {
    searchInputRef.current.focus()
  }, [])

  return (
    <div className='bg-dark'>
      <AdminNavbar />
      {/* <div className='section py-10'>
        <div className='py-16 mx-auto space-y-8 max-w-7xl sm:py-24'>
          <div className='space-y-6 text-center'>
            <h1 className='text-tertiary/80 flex gap-2 text-5xl font-bold'>
              Gallery
            </h1>
          </div> */}
      <div className='section py-10'>
        <div className='container mx-auto max-w-7xl space-y-8 px-5'>
          <div className='flex justify-between'>
            <h1 className='text-tertiary/80 flex gap-2 text-5xl font-bold'>
              Gallery
            </h1>
          </div>
          {(videos.length !== 0 || searchText != null) && (
            <SearchBar
              ref={searchInputRef}
              getVideos={getVideos}
              isLoading={isLoading}
              setSearchText={setSearchText}
              searchText={searchText}
              className='w-[89vw] sm:w-[30rem]'
            />
          )}
          <VideoGallery
            videos={videos}
            getVideos={getVideos}
            setSearchText={setSearchText}
            searchInputRef={searchInputRef}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onDelete={toggleVideoDeletionModal}
            totalPages={totalPages}
            isLoading={isLoading}
            isAdmin={true}
          />

          {/* PAGINATION */}
          <div>
            {offsetDescription && hasPagination && (
              <div className='mb-5 text-center'>
                <span className='text-gray-500'>{offsetDescription}</span>
              </div>
            )}

            {!(videos.length === 0 && searchText === null) &&
              hasPagination &&
              totalItems > 0 && (
                <ReactPaginate
                  forcePage={currentPage - 1}
                  breakLabel='...'
                  previousLabel={<ChevronDoubleLeftIcon className='w-6 h-6' />}
                  nextLabel={<ChevronDoubleRightIcon className='w-6 h-6' />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalPages}
                  renderOnZeroPageCount={null}
                  marginPagesDisplayed={2}
                  containerClassName='flex flex-wrap justify-center gap-2'
                  breakClassName='page-item'
                  pageClassName='page-item'
                  previousClassName='page-item'
                  nextClassName='page-item'
                  activeClassName='active'
                  breakLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                  pageLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                  previousLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                  nextLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                  activeLinkClassName='radial-gradient shadow-sm text-gray-900 font-bold'
                  disabledLinkClassName='border-gray-900 text-gray-700'
                />
              )}
          </div>
        </div>
      </div>
      <Footer />
      {/* MODALS */}
      <VideoDeletionModal
        isOpen={isVideoDeletionModalActive}
        videoId={videoDeletionId}
        userSelectedData={userSelectedData}
        onClose={toggleVideoDeletionModal}
        onDelete={toggleVideoAdminDeleted}
        isAdmin={true}
        isAdminDeleted={isAdminDeleted}
      />
    </div>
  )
}
