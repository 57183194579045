import CurrencyFormat from 'react-currency-format'
import Lottie from 'lottie-react'

import LoadingWhite from '../images/lottie/loaderWhite.json'
import { Modal } from './Modal'

export default function AskForUpgradeOrDowngradeModal({
  isOpen,
  onClose,
  isSubmitting,
  onUpgradeOrDowngrade,
  tierDescription,
  isUpgrade,
  subscriptionCurrencyValue,
  subscriptionUpgradeChargeValue,
  subscriptionUpgradeRefundValue,
}) {
  return (
    <Modal open={isOpen} setOpen={onClose} persistent={isSubmitting}>
      {isSubmitting ? (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <div className='mt-4 flex flex-col items-center justify-end gap-2'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        </div>
      ) : (
        <div className='px-6 py-6 sm:px-12 sm:py-10 w-full space-y-8'>
          <p className='text-2xl sm:text-5xl text-tertiary'>
            {isUpgrade ? 'Upgrade' : 'Downgrade'} your plan?
          </p>
          <p className='text-tertiary/80'>
            Would you like to subscribe to the {tierDescription} paid plan?{' '}
            {subscriptionUpgradeChargeValue > 0 ? (
              <>
                You will be charged{' '}
                <CurrencyFormat
                  value={subscriptionUpgradeChargeValue / 100}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={subscriptionCurrencyValue}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
                {subscriptionUpgradeRefundValue > 0 && (
                  <>
                    {' '}
                    and a refund of{' '}
                    <CurrencyFormat
                      value={subscriptionUpgradeRefundValue / 100}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={subscriptionCurrencyValue}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />{' '}
                    will be made
                  </>
                )}
                .
              </>
            ) : (
              <>You will not be charged for this upgrade.</>
            )}
          </p>
          <div className='text-center'>
            <button
              id={isUpgrade ? 'pricing_upgrade' : 'pricing_downgrade'}
              onClick={onUpgradeOrDowngrade}
              className='w-full rounded-full border radial-gradient px-4 py-4 text-sm font-bold text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase gap-2 border-primary'
            >
              {isUpgrade ? 'UPGRADE' : 'DOWNGRADE'} NOW
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}
