import { Node, useReactFlow } from '@xyflow/react'
import { ReactNode, memo, useEffect, useRef, useState } from 'react'
import { useErrorBoundary, withErrorBoundary } from 'react-error-boundary'
import { FormProvider, useForm } from 'react-hook-form'
import ReactCrop, { PixelCrop, ReactCropProps } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { v4 as uuid } from 'uuid'

import { ErrorNode } from './ErrorNode'
import { MediaNodeData } from './MediaNode'
import { saveMedia } from '../../../api'
import { MEDIA_CANVAS_MIN_SIZE } from '../../../constants'
import { useAutoscaleMedia, useAnalytics } from '../../../hooks'
import { ArrowRotateRightLeftIcon } from '../../../images/icons/ArrowRotateRightLeftIcon'
import { CropIcon } from '../../../images/icons/CropIcon'
import { SettingsSliderIcon } from '../../../images/icons/SettingsSliderIcon'
import { mediaStore, myLibraryStore } from '../../../stores'
import { cn } from '../../../utils'
import { isCanvasExportable } from '../../../utils/canvasUtils'
import { getProxiedR2FileUrl } from '../../../utils/fileUtils'
import { Skeleton } from '../../loading'
import { ColorSettings } from '../Cropper/ColorSettings'
import { RatioSettings } from '../Cropper/RatioSettings'
import { RotateSettings } from '../Cropper/RotateSettings'
import { MediaDisplay } from '../Media'
import {
  Media,
  NodeType,
  Image,
  TagNamespace,
  AnalyticsEvent,
  MediaUploadSource,
} from '@/types'

export interface CropperNodeData extends Record<string, unknown> {
  media: Media
}

export interface CropperNodeProps {
  id: string
  data: CropperNodeData
}

interface ScaleCompatibleReactCropProps {
  scale?: number
}

/** The default ReactCrop component does not properly account for the scaling of the viewport
 * when zooming in or out of the ReactFlow canvas. This is a temporary fix until this issue is closed:
 * https://github.com/sekoyo/react-image-crop/issues/591
 */
class ScaleCompatibleReactCrop extends ReactCrop {
  props: ReactCropProps & ScaleCompatibleReactCropProps

  getBox() {
    const box = super.getBox()
    const { scale } = this.props

    if (scale !== undefined) {
      box.width /= scale
      box.height /= scale
    }

    return box
  }
}

enum ImageSettingsTab {
  RATIO,
  COLOR,
  ROTATION,
}

interface SettingsTabConfig {
  id: ImageSettingsTab
  icon: ReactNode
}

const SETTINGS_TAB_CONFIG: SettingsTabConfig[] = [
  { id: ImageSettingsTab.RATIO, icon: <CropIcon /> },
  { id: ImageSettingsTab.COLOR, icon: <SettingsSliderIcon /> },
  { id: ImageSettingsTab.ROTATION, icon: <ArrowRotateRightLeftIcon /> },
]
export const CROP_TITLE_HEIGHT = 32

export const BaseCropperNode = memo((props: CropperNodeProps) => {
  const { addNodes, setNodes, updateNodeData, getNode, getZoom } =
    useReactFlow()
  const { id, data } = props
  const [crop, setCrop] = useState<PixelCrop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [outputNode, setOutputNode] = useState<Node>()
  const [activeTabId, setActiveTabId] = useState<ImageSettingsTab>(
    ImageSettingsTab.RATIO,
  )
  const methods = useForm()
  const [selectedRatioId, setSelectedRatioId] = useState(-1)
  const [aspectRatio, setAspectRatio] = useState<number | undefined>(undefined)
  const rotateAngle = methods.watch('Angle')

  const titleRef = useRef<HTMLDivElement>(null)
  const scale = getZoom()
  /**
   * localSourceUrl is used to create a local URL for the image source
   * even when you set this as a CORS-friendly URL, ReactCrop will get tainted, which is a CORS error on canvas
   * which breaks handleCompletedCrop as it cannot export the cropped image
   */
  const [localSourceUrl, setLocalSourceUrl] = useState<string | null>(null)

  const { showBoundary } = useErrorBoundary()
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    const fetchLocalSource = async () => {
      try {
        const response = await fetch(getProxiedR2FileUrl(data.media.source))
        const blob = await response.blob()
        const file = new File([blob], 'image', { type: blob.type })
        setLocalSourceUrl(URL.createObjectURL(file))
      } catch (error) {
        showBoundary(error)
      }
    }

    fetchLocalSource()
  }, [data.media.source, showBoundary])

  const { originalDimensions, scaledDimensions, scaledImage, isLoading } =
    useAutoscaleMedia({
      source: localSourceUrl,
      mediaType: data.media.type,
    })

  useEffect(() => {
    if (!isLoading) {
      setCrop({
        unit: 'px',
        x: 0,
        y: 0,
        width: scaledDimensions.width,
        height: scaledDimensions.height,
      })
    }
  }, [isLoading, scaledDimensions])

  const handleCropChange = (crop: PixelCrop) => {
    setCrop(crop)
  }

  const [outputBlob, setOutputBlob] = useState<Blob | null>(null)

  const handleCompletedCrop = (croppedAreaPixels: PixelCrop) => {
    // handles edge case bug where user clicks or double clicks without dragging in crop area
    if (croppedAreaPixels.width === 0 || croppedAreaPixels.height === 0) {
      return
    }

    if (scaledImage) {
      const scaleX = originalDimensions.width / scaledDimensions.width
      const scaleY = originalDimensions.height / scaledDimensions.height

      const adjustedCroppedAreaPixels = {
        x: croppedAreaPixels.x * scaleX,
        y: croppedAreaPixels.y * scaleY,
        width: croppedAreaPixels.width * scaleX,
        height: croppedAreaPixels.height * scaleY,
      }

      const canvas = document.createElement('canvas')
      canvas.width = adjustedCroppedAreaPixels.width
      canvas.height = adjustedCroppedAreaPixels.height

      const centerX = scaledImage.naturalWidth / 2
      const centerY = scaledImage.naturalHeight / 2

      const ctx = canvas.getContext('2d')
      if (ctx) {
        const { brightness, contrast, grayscale, saturation, angle } =
          methods.getValues()
        const rotateRads = ((angle ?? 0) * Math.PI) / 180

        ctx.translate(
          -adjustedCroppedAreaPixels.x,
          -adjustedCroppedAreaPixels.y,
        )
        ctx.translate(centerX, centerY)
        ctx.rotate(rotateRads)
        ctx.translate(-centerX, -centerY)

        ctx.filter = `brightness(${brightness}%) contrast(${contrast}%) grayscale(${grayscale}%) saturate(${saturation}%)`

        ctx.drawImage(
          scaledImage,
          0,
          0,
          scaledImage.naturalWidth,
          scaledImage.naturalHeight,
          0,
          0,
          scaledImage.naturalWidth,
          scaledImage.naturalHeight,
        )

        if (!isCanvasExportable(canvas)) {
          console.error('Canvas is tainted, cannot export to image')
          return
        }

        canvas.toBlob(async (blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob)
            setOutputBlob(blob)

            const cropperNode = getNode(id)
            const titleWidth = titleRef.current?.offsetWidth ?? 0
            const titleHeight = titleRef.current?.offsetHeight ?? 0
            const outputNodeX =
              (cropperNode?.position?.x ?? 0) + titleWidth + 20
            const outputNodeY = (cropperNode?.position?.y ?? 0) + titleHeight

            if (!completedCrop || !outputNode) {
              const newNode: Node = {
                id: `${id}-cropped-${uuid()}`,
                position: { x: outputNodeX, y: outputNodeY },
                data: {
                  media: {
                    source: blobUrl,
                    type: data.media.type,
                  },
                  noSubscribe: true,
                },
                type: NodeType.MediaNode,
              }
              addNodes(newNode)
              setOutputNode(newNode)
            } else {
              updateNodeData(outputNode.id, {
                media: {
                  source: blobUrl,
                  type: data.media.type,
                },
              })
            }
          }
        }, 'image/png')
      }
    }
    setCompletedCrop(croppedAreaPixels)
  }

  const onImageEditChange = () => {
    handleCropChange(crop)
    handleCompletedCrop(completedCrop)
  }

  if (isLoading) {
    return (
      <Skeleton className='flex justify-center items-center w-full h-full' />
    )
  }

  const handleCancel = () => {
    const node = getNode(id)
    if (node) {
      const mediaNode: Node<MediaNodeData> = {
        ...node,
        data,
        position: {
          x: node.position.x,
          y: node.position.y + CROP_TITLE_HEIGHT,
        },
        type: NodeType.MediaNode,
      }
      setNodes((nodes) =>
        nodes
          .filter((n) => (outputNode ? n.id !== outputNode.id : true))
          .map((n) => (n.id === id ? mediaNode : n)),
      )
    }
  }

  const handleSave = async () => {
    setNodes((nodes) => nodes.filter((n) => n.id !== id))

    try {
      if (!outputBlob) {
        throw new Error('No output blob available')
      }

      const file = new File([outputBlob], 'image', { type: outputBlob.type })
      const media = (await saveMedia(file, [
        { ns: TagNamespace.MediaType, name: 'Image' },
      ])) as Image
      mediaStore.setMedia(media)
      myLibraryStore.prependMediaId(media.mediaId)
      trackEvent(AnalyticsEvent.MediaUploaded, {
        mediaId: media.mediaId,
        mediaType: media.type,
        fileSize: file.size,
        fileType: file.type,
        uploadSource: MediaUploadSource.ImageUpload,
      })

      updateNodeData(outputNode.id, {
        media,
      })
    } catch (error) {
      console.error('Error uploading cropped media:', error)
      return
    }
  }

  return (
    <div className='relative rounded-2xl bg-[#474747] overflow-hidden'>
      <div
        ref={titleRef}
        className={`px-3 py-1.5 flex justify-between h-[${CROP_TITLE_HEIGHT}px]`}
      >
        <button
          className='px-2 py-1 rounded text-xs leading-none font-semibold bg-k2-toolbar-secondary'
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className='px-2 py-1 rounded text-xs leading-none font-semibold bg-k2-toolbar-primary'
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      <ScaleCompatibleReactCrop
        crop={crop}
        onChange={handleCropChange}
        onComplete={handleCompletedCrop}
        scale={scale}
        aspect={aspectRatio}
        minHeight={MEDIA_CANVAS_MIN_SIZE}
        minWidth={MEDIA_CANVAS_MIN_SIZE}
      >
        <div
          style={{
            width: `${scaledDimensions.width}px`,
            height: `${scaledDimensions.height}px`,
          }}
        >
          <MediaDisplay
            rotate={rotateAngle}
            className='nodrag'
            media={data.media}
            width={scaledDimensions.width}
            height={scaledDimensions.height}
          />
        </div>
      </ScaleCompatibleReactCrop>
      <div className='flex flex-row bg-[#585858] text-white'>
        {SETTINGS_TAB_CONFIG.map((config) => (
          <span
            key={config.id}
            className={cn(
              'flex-1 flex justify-center items-center px-4 py-2 cursor-pointer hover:bg-[#787878] transition-colors',
              { 'bg-[#858585]': activeTabId === config.id },
            )}
            onClick={() => setActiveTabId(config.id)}
          >
            {config.icon}
          </span>
        ))}
      </div>

      <FormProvider {...methods}>
        <div className='p-4'>
          {activeTabId === ImageSettingsTab.RATIO && (
            <RatioSettings
              selectedRatioId={selectedRatioId}
              onRatioIdChange={setSelectedRatioId}
              onRatioChange={setAspectRatio}
              dimensions={scaledDimensions}
              onCropChange={handleCropChange}
              handleCompletedCrop={handleCompletedCrop}
            />
          )}
          {activeTabId === ImageSettingsTab.COLOR && (
            <ColorSettings onImageEditChange={onImageEditChange} />
          )}
          {activeTabId === ImageSettingsTab.ROTATION && (
            <RotateSettings onImageEditChange={onImageEditChange} />
          )}
        </div>
      </FormProvider>
    </div>
  )
})

BaseCropperNode.displayName = 'BaseCropperNode'

export const CropperNode = withErrorBoundary(BaseCropperNode, {
  FallbackComponent: ErrorNode,
  onError(error: any, info: any) {
    console.error('Error caught by Error Boundary:', error, info)
  },
})
