import { useFormContext } from 'react-hook-form'

import {
  DEFAULT_FORM_VALUES,
  STABLE_DIFFUSION_CHECKPOINTS,
} from '../../../constants'
import { FieldType } from '../../../types'
import { CanvasBaseElement } from './CanvasBaseElement'

export const CheckpointSelector = () => {
  const { register } = useFormContext()

  return (
    <CanvasBaseElement label='Checkpoint'>
      <div>
        <div className='flex flex-col'>
          <div>
            <label>
              <span className=''>checkpoint name - </span>
            </label>
            <select
              className='bg-transparent outline-none'
              {...register(FieldType.CheckpointSelector)}
              defaultValue={DEFAULT_FORM_VALUES.stableDiffusionCheckpoint}
            >
              {Object.keys(STABLE_DIFFUSION_CHECKPOINTS).map((key) => (
                <option key={key} value={STABLE_DIFFUSION_CHECKPOINTS[key]}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </CanvasBaseElement>
  )
}
