import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const ModalVideo = ({
  children,
  open,
  setOpen,
  handleClose,
  persistent = false,
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as='div'
          className='flex w-fit relative z-50'
          onClick={(e) => e.stopPropagation()}
          onClose={() => {
            setOpen(false)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-white bg-opacity-30' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-5xl transform overflow-hidden rounded-2xl bg-dark p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='absolute top-0 right-0 pt-4 pr-4'>
                    <button
                      type='button'
                      className='text-gray-400 rounded-md hover:text-gray-500'
                      onClick={() => {
                        setOpen(false)
                        if (handleClose) {
                          handleClose()
                        }
                      }}
                    >
                      {!persistent && (
                        <>
                          <span className='sr-only'>Close</span>
                          <XMarkIcon className='w-6 h-6' aria-hidden='true' />
                        </>
                      )}
                    </button>
                  </div>
                  <div className='flex w-full h-fit'>{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
