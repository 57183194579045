import { Link } from 'react-router-dom'

const Dropdown = ({ submenus, dropdown }) => {
  return (
    <ul
      className={`absolute left-auto z-50 min-w-[160px] rounded-b-md overflow-hidden ${
        dropdown ? 'block' : 'hidden'
      }`}
    >
      {submenus.map((submenu, index) => {
        const isActive = window.location.pathname === submenu.href

        return (
          <li key={index} className='relative text-left'>
            {submenu.href.startsWith('https://') ? (
              <a
                target='_blank'
                rel='noreferrer'
                key={submenu.name}
                href={submenu.href}
                className={`${
                  isActive
                    ? ' text-primary bg-[#33333a]'
                    : 'text-white hover:text-primary bg-darkGray hover:bg-[#33333a] duration-300'
                }
                        flex w-full py-2 px-3`}
                aria-current={isActive ? 'page' : undefined}
              >
                {submenu.name}
              </a>
            ) : (
              <Link
                key={submenu.name}
                to={submenu.href}
                aria-haspopup='menu'
                className={`${
                  isActive
                    ? ' text-primary bg-[#33333a]'
                    : 'text-white hover:text-primary bg-darkGray hover:bg-[#33333a] duration-300'
                }
                            flex w-full py-2 px-3`}
                aria-current={isActive ? 'page' : undefined}
              >
                {submenu.name}
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default Dropdown
