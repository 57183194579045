import { memo } from 'react'

import { cn } from '../../../../utils'
import { NodeToolbar, ToolbarButton } from '../../NodeToolbar'

interface IFlowNodeToolbarProps {
  isEditing: boolean
  isHovering: boolean
  toolbarButtons: ToolbarButton[]
  borderColor: string
}

export const FlowNodeToolbar: React.FC<IFlowNodeToolbarProps> = memo(
  ({ isEditing, isHovering, toolbarButtons, borderColor }) => {
    return (
      <div
        className={cn(
          'absolute left-1/2 -translate-x-1/2 -top-12 transition-opacity duration-300',
          isHovering ? 'opacity-100' : 'opacity-0',
          isEditing ? 'z-0' : 'z-50',
        )}
      >
        <NodeToolbar
          buttons={toolbarButtons}
          className={`border border-solid ${borderColor} rounded-lg h-9`}
        />
      </div>
    )
  },
)

FlowNodeToolbar.displayName = 'FlowNodeToolbar'
