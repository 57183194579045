import { ChangeEvent, useMemo, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import {
  AUDIO_UPLOAD,
  FIELD_LABELS,
  LABELS,
  LENGTH_CONFIG,
} from '../../../../../constants'
import { FieldType } from '../../../../../types'
import { InputField, Slider } from '../../../Fields'
import { CanvasBaseElement } from '../../CanvasBaseElement'

export const MotionV3Settings = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const audioWatch = watch(AUDIO_UPLOAD)

  const isAudioAttached = useMemo(
    () => audioWatch instanceof File,
    [audioWatch],
  )

  useEffect(() => {
    if (audioWatch instanceof File) {
      const audioElement = document.createElement('audio')
      const objectUrl = URL.createObjectURL(audioWatch)

      audioElement.src = objectUrl

      audioElement.addEventListener('loadedmetadata', () => {
        const duration = Math.ceil(audioElement.duration)
        const length = Math.max(
          LENGTH_CONFIG.MOTION_V3_WITH_AUDIO.min,
          Math.min(duration, LENGTH_CONFIG.MOTION_V3_WITH_AUDIO.max),
        )

        setValue(FieldType.Length, length)
        // Release object URL to avoid memory leaks
        URL.revokeObjectURL(objectUrl)
        audioElement.remove()
      })
    } else {
      const length = LENGTH_CONFIG.MOTION_V3.min
      setValue(FieldType.Length, length)
    }
  }, [audioWatch, setValue])

  const config = useMemo(() => {
    return isAudioAttached
      ? LENGTH_CONFIG.MOTION_V3_WITH_AUDIO
      : LENGTH_CONFIG.MOTION_V3
  }, [isAudioAttached])

  const validateLength = useCallback(
    (value: number) => {
      return (
        (value >= config.min && value <= config.max) ||
        `Length must be between ${config.min} and ${config.max}`
      )
    },
    [config.min, config.max],
  )

  const updateLengthWithinRange = useDebouncedCallback(
    useCallback(
      (length: number) => {
        if (length > config.max) setValue(FieldType.Length, config.max)
        else if (length < config.min) setValue(FieldType.Length, config.min)
        else setValue(FieldType.Length, length)
      },
      [config, setValue],
    ),
    700,
  )

  const onLengthChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const length = e.target.value === '' ? null : parseInt(e.target.value)
      setValue(FieldType.Length, length)
      updateLengthWithinRange(length)
    },
    [setValue, updateLengthWithinRange],
  )

  const onMotionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(FieldType.Motion, Number(e.target.value))
    },
    [setValue],
  )

  // Toast error messages has to rely on specific field, wont work with just errors in dep array
  useEffect(() => {
    if (errors[FieldType.Length]) {
      toast.error(errors[FieldType.Length]?.message as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors[FieldType.Length]])

  return (
    <CanvasBaseElement label={LABELS.VIDEO_DETAILS}>
      <div className='flex gap-3 flex-col min-w-[200px]'>
        <InputField
          label={FIELD_LABELS[FieldType.Length]}
          type='number'
          {...register(FieldType.Length, {
            required: 'Length is required',
            min: {
              value: config.min,
              message: `Minimum length is ${config.min}`,
            },
            max: {
              value: config.max,
              message: `Maximum length is ${config.max}`,
            },
            validate: validateLength,
            onChange: onLengthChange,
            valueAsNumber: true,
          })}
          value={watch(FieldType.Length) ?? ''}
          className={errors[FieldType.Length] ? 'border-red-500' : ''}
          disabled={isAudioAttached}
        />
        <Slider
          label={FIELD_LABELS[FieldType.Motion]}
          min={0}
          max={10}
          step={1}
          {...register(FieldType.Motion, {
            required: 'Motion is required',
            valueAsNumber: true,
            onChange: onMotionChange,
          })}
          value={watch(FieldType.Motion) ?? 0}
        />
      </div>
    </CanvasBaseElement>
  )
}
