//** React Query key for a collectionId */
const keyOf = (collectionId: string) => ['collection', collectionId]
//** React Query key for all collections IDs */
export const ALL_COLLECTION_IDS_QUERY_KEY = ['collection_ids']

// Use this export if the function name (e.g. collectionUtils.keyOf) is too general in your code's context
export const collectionUtils = {
  keyOf,
  ALL_COLLECTION_IDS_QUERY_KEY,
}
