import { StarIcon } from '@heroicons/react/24/outline'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import classNames from 'classnames'

import { AUDIO_REACTIVE_OPTIONS } from '../utils/constants'

export const SelectAudioReactivity = ({
  handleSelect,
  subscriptionTextCTA = null,
  showSubscriptionTextCTA = false,
  className = '',
  audioReactivity = 0,
}) => {
  const onSelect = (value) => {
    handleSelect(value)
  }

  return (
    <div className={`mb-5 text-base ${className}`}>
      <div className='flex gap-3 mb-5'>
        <h2 className='text-[#9B92D0] text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
          Audio-reactivity
        </h2>

        {/* TOOLTIP */}
        <div className='flex has-tooltip'>
          <span className='px-2 py-1 -mt-8 text-gray-100 bg-gray-800 rounded shadow-lg tooltip'>
            Lower = Camera moves less with your audio, Higher = Camera moves
            more
          </span>
          <AiOutlineQuestionCircle className='self-center w-6 h-6 select-none text-[#9B92D0]' />
        </div>
      </div>

      <div className='flex flex-wrap max-w-lg gap-3 mb-5'>
        {AUDIO_REACTIVE_OPTIONS.map((option) => (
          <button
            key={option.name}
            data-label={option.name}
            className={classNames(
              'border relative buttonPrompt px-4 py-2 rounded-full border-[#9B92D0] hover:bg-[#9B92D0] hover:text-black transition-all capitalize',
              {
                'bg-[#9B92D0] text-black font-bold':
                  audioReactivity === option.value,
                'text-[#9B92D0]': audioReactivity !== option.value,
              },
            )}
            onClick={() => onSelect(option.value)}
          >
            <span className='absolute top-2 bottom-2 left-4 right-4'>
              {option.name}
            </span>
          </button>
        ))}
      </div>

      {showSubscriptionTextCTA && (
        <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
          <StarIcon className='inline w-6 h-6 mr-2' />
          {subscriptionTextCTA}
        </div>
      )}
    </div>
  )
}
