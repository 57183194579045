import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { Dispatch, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'

import { CanvasTitle } from './CanvasTitle'
import { DEFAULT_THEME } from '../../../constants'
import { useThemeContext } from '../../../context'
import { useMenuToggle } from '../../../hooks/k2/useMenuToggle'
import { HomeIcon } from '../../../images/icons/HomeIcon'
import { ImageSparkleIcon } from '../../../images/icons/ImageSparkleIcon'
import KaiberLogo from '../../../images/icons/KaiberLogo.png'
import { QuestionCircleIcon } from '../../../images/icons/QuestionCircleIcon'
import { cn } from '../../../utils'
import { Account } from '../Account/Account'
import { Topbar } from '../Menus/Topbar'

interface CanvasNavbarProps {
  isMove: boolean
  isAutoSaving: boolean
  setIsMove: Dispatch<SetStateAction<boolean>>
  setDialogOpen: Dispatch<SetStateAction<boolean>>
}

export const CanvasNavbar = ({
  isMove,
  isAutoSaving,
  setIsMove,
  setDialogOpen,
}: CanvasNavbarProps) => {
  const { colors, activeTheme } = useThemeContext()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { handleMenuOpen, handleMenuClose } = useMenuToggle(
    () => setIsMenuOpen(true),
    () => setIsMenuOpen(false),
  )

  return (
    <div
      className={cn(
        'fixed top-0 left-0 right-0 flex z-30 h-14 py-4 px-4 items-center justify-between shadow-2xl',
        colors.background.neutral,
      )}
    >
      {/* left side */}
      <div className='flex-1 flex items-center gap-5'>
        <Menu open={isMenuOpen} handler={setIsMenuOpen}>
          <MenuHandler onMouseLeave={handleMenuClose}>
            <div className='flex items-center gap-1 group cursor-pointer'>
              <img
                src={KaiberLogo}
                alt='Kaiber logo'
                className={cn({
                  'filter invert': activeTheme === DEFAULT_THEME.Light,
                })}
              />
              <ChevronDownIcon
                className={cn(
                  `group-hover:opacity-100 transition-all duration-200 ease-in-out w-3 ${colors.text.default}`,
                  { 'rotate-180 opacity-100': isMenuOpen },
                )}
              />
            </div>
          </MenuHandler>
          <MenuList
            className={`ml-1.5 mt-3  border border-gray-600 shadow-xl rounded-xl ${colors.background.neutral} ${colors.text.default}`}
            onMouseLeave={handleMenuClose}
            onMouseEnter={handleMenuOpen}
          >
            <MenuItem className='active:bg-k2-gray-400 hover:bg-k2-gray-400 focus:bg-k2-gray-400 focus:text-k2-gray-100'>
              <Link to='/dashboard' className='flex items-center gap-3'>
                <HomeIcon />
                <span>Go to dashboard</span>
              </Link>
            </MenuItem>
            <MenuItem className='active:bg-k2-gray-400 hover:bg-k2-gray-400 focus:bg-k2-gray-400 focus:text-k2-gray-100'>
              <Link to='/create' className='flex items-center gap-3'>
                <ImageSparkleIcon size='1em' />
                <span>Switch to Kaiber 1</span>
              </Link>
            </MenuItem>
            <MenuItem className='active:bg-k2-gray-400 hover:bg-k2-gray-400 focus:bg-k2-gray-400 focus:text-k2-gray-100'>
              <a
                href='https://helpcenter.kaiber.ai/kaiber/en/collections/4044263-faq'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Get help'
                className='flex items-center gap-3'
              >
                <QuestionCircleIcon />
                <span>Get help</span>
              </a>
            </MenuItem>
          </MenuList>
        </Menu>
        <CanvasTitle />
      </div>

      <div className='flex-1 flex justify-center'>
        <Topbar
          isMove={isMove}
          isAutoSaving={isAutoSaving}
          setIsMove={setIsMove}
          setDialogOpen={setDialogOpen}
        />
      </div>

      {/* right side */}
      <Account />
    </div>
  )
}
