import React from 'react'

interface ImageSparkleIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const ImageSparkleIcon = React.forwardRef<
  SVGSVGElement,
  ImageSparkleIconProps
>(({ size = '20', ...props }, ref) => (
  <>
    <svg
      ref={ref}
      stroke='currentColor'
      fill='none'
      height={size}
      width={size}
      viewBox='0 0 24 24'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 6.09722C3.75 4.66282 4.91282 3.5 6.34722 3.5H18.1528C19.5872 3.5 20.75 4.66282 20.75 6.09722V17.9028C20.75 19.3372 19.5872 20.5 18.1528 20.5H6.34722C4.91282 20.5 3.75 19.3372 3.75 17.9028V6.09722ZM6.34722 4.91667C5.69522 4.91667 5.16667 5.44522 5.16667 6.09722V14.3038L6.63571 12.8348C7.64999 11.8205 9.29446 11.8205 10.3087 12.8348L16.5573 19.0833H18.1528C18.8048 19.0833 19.3333 18.5548 19.3333 17.9028V6.09722C19.3333 5.44522 18.8048 4.91667 18.1528 4.91667H6.34722ZM14.5538 19.0833L9.307 13.8365C8.84597 13.3755 8.09848 13.3755 7.63745 13.8365L5.16667 16.3073V17.9028C5.16667 18.5548 5.69522 19.0833 6.34722 19.0833H14.5538Z'
        fill='currentColor'
      />
      <path
        d='M13.596 8.72971C13.6416 8.70687 13.6787 8.66982 13.7016 8.62412L14.3996 7.22799C14.4866 7.05397 14.735 7.05397 14.822 7.22799L15.52 8.62412C15.5429 8.66982 15.58 8.70687 15.6256 8.72971L17.0218 9.42777C17.1957 9.51478 17.1957 9.76313 17.0218 9.85015L15.6256 10.5482C15.58 10.571 15.5429 10.6081 15.52 10.6538L14.822 12.0499C14.735 12.2239 14.4866 12.2239 14.3996 12.0499L13.7016 10.6538C13.6787 10.6081 13.6416 10.571 13.596 10.5482L12.1998 9.85015C12.0258 9.76313 12.0258 9.51478 12.1998 9.42777L13.596 8.72971Z'
        fill='currentColor'
      />
    </svg>
  </>
))
