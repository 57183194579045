import { useState } from 'react'

import RefreshIcon from '../images/RefreshIcon.svg'
import { RegenerateVideoModal } from './RegenerateVideoModal'

export const RegenerateVideoButton = ({ sceneData, runSettings }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        className='p-1.5 rounded-[6px] flex items-center justify-center text-white bg-[#232329] text-xl gap-2.5 max-w-[230px] cursor-pointer mb-4'
        onClick={() => setShowModal(true)}
      >
        <div className='rounded-[4px] bg-kaiberGreen flex items-center justify-center p-2.5'>
          <img src={RefreshIcon} alt='refresh icon' />
        </div>
        Regenerate Video
      </button>

      <RegenerateVideoModal
        isOpen={showModal}
        setVisible={setShowModal}
        sceneData={sceneData}
        runSettings={runSettings}
      />
    </>
  )
}
