// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scene-label {
  position: absolute;
  top: 1.6rem;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/timeStampSlider.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,OAAO;EACP,SAAS;EACT,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".scene-label {\n  position: absolute;\n  top: 1.6rem;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
