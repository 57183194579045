import StudioComponent from './StudioComponent'

const image1 = '/website/studio/community_art1.png'
const image2 = '/website/studio/community_art2.png'
const image3 = '/website/studio/community_art3.png'
const image4 = '/website/studio/community_art4.png'
const image5 = '/website/studio/community_art5.png'
const image6 = '/website/studio/community_art6.png'

function StudioSection() {
  return (
    <div className='grid w-full gap-x-10 gap-y-20  my-20 justify-centergap-10 sm:grid-cols-2 lg:grid-cols-3'>
      <StudioComponent
        image={image1}
        VIDEO_ID={'D262oTSRQXw'}
        title={`Linkin Park 'Meteora' 20th Anniversary Visualizers`}
      />
      <StudioComponent
        image={image2}
        VIDEO_ID={'Ysb4Md4XYsc'}
        title={`Linkin Park 'Meteora' 20th Anniversary Visualizers`}
      />
      <StudioComponent
        image={image3}
        VIDEO_ID={'iKBCVZqqooY'}
        title={`Linkin Park 'Meteora' 20th Anniversary Visualizers`}
      />
      <StudioComponent
        image={image4}
        VIDEO_ID={'bGyH1JF1Nu4'}
        title={`Mike Shinoda 'In My Head' Visualizer for the Scream VI`}
      />
      <StudioComponent
        image={image5}
        VIDEO_ID={'7NK_JOkuSVY'}
        title={`Linkin Park “Lost” Music Video for Meteora 20th Anniversary`}
      />
      <StudioComponent
        image={image6}
        VIDEO_ID={'udZoVwrrHSw'}
        title={`Kid Cudi “Entergalactic” Album Visuals`}
      />
    </div>
  )
}

export default StudioSection
