import http from '../services/HttpService'
import { VIDEO_TYPES_AND_VERSIONS, GENERATE_VIDEO_ENDPOINTS } from './constants'
import { getProxiedR2FileUrl } from './fileUtils'
import { VIDEO_PROPERTIES } from './video_properties'

const {
  FLIPBOOK_V1,
  ANIMATION_V1,
  TRANSFORM_V1,
  TRANSFORM_V2,
  MOTION_V1,
  MOTION_V2,
  MOTION_V3,
} = VIDEO_TYPES_AND_VERSIONS

/**
 * Returns formatted string of video type and version, ie. `motion_v1`
 *
 * @param {string} videoType
 * @param {string} version
 * @returns {string} typeAndVersion
 */
export const getFormattedTypeAndVersion = (videoType, version) => {
  // null coalesce check to support legacy flipbook vidoes with undefined type/version
  return `${videoType ?? 'flipbook'}_v${version ?? 1}`
}

/**
 * Returns the properties for a video type and version.
 *
 * @param {string} videoType
 * @param {string} version
 * @returns {object} properties
 */
const getProperties = (videoType, version) => {
  const typeAndVersion = getFormattedTypeAndVersion(videoType, version)

  let properties
  switch (typeAndVersion) {
    case FLIPBOOK_V1:
    case ANIMATION_V1: // deprecated
      properties = VIDEO_PROPERTIES.FLIPBOOK_V1
      break

    case TRANSFORM_V1:
      properties = VIDEO_PROPERTIES.TRANSFORM_V1
      break
    case TRANSFORM_V2:
      properties = VIDEO_PROPERTIES.TRANSFORM_V2
      break

    case MOTION_V1:
    case MOTION_V2:
      properties = VIDEO_PROPERTIES.MOTION_V2
      break
    case MOTION_V3:
      properties = VIDEO_PROPERTIES.MOTION_V3
      break

    default:
      throw new Error('Invalid video type')
  }

  return properties
}

/**
 * Returns endpoint for generating a video.
 *
 * @param {string} videoType
 * @param {string} version
 * @returns {string} endpoint
 */
const getEndpoint = (videoType, version) => {
  const typeAndVersion = getFormattedTypeAndVersion(videoType, version)

  let endpoint
  switch (typeAndVersion) {
    case FLIPBOOK_V1:
    case ANIMATION_V1:
      endpoint = GENERATE_VIDEO_ENDPOINTS.FLIPBOOK_V1
      break

    case MOTION_V1:
    case MOTION_V2:
      endpoint = GENERATE_VIDEO_ENDPOINTS.MOTION_V2
      break
    case MOTION_V3:
      endpoint = GENERATE_VIDEO_ENDPOINTS.MOTION_V3
      break

    case TRANSFORM_V1:
      endpoint = GENERATE_VIDEO_ENDPOINTS.TRANSFORM_V1
      break

    case TRANSFORM_V2:
      endpoint = GENERATE_VIDEO_ENDPOINTS.TRANSFORM_V2
      break

    default:
      throw new Error('Invalid video type')
  }

  return endpoint
}

/**
 * Generates a video request based on videoType and version.
 *
 * @param {object} requestData
 */
export const generateVideoRequest = async (requestData) => {
  const { videoType, version } = requestData

  const data = new FormData()
  Object.keys(getProperties(videoType, version)).forEach((key) => {
    if (requestData.hasOwnProperty(key)) {
      data.append(key, requestData[key])
    }
  })

  if (requestData['user_provided_starting_frame_s3_key']) {
    data.append(
      'user_provided_starting_frame_s3_key',
      requestData['user_provided_starting_frame_s3_key'],
    )
  }
  if (requestData['typeAndVersion']) {
    data.append('typeAndVersion', requestData['typeAndVersion'])
  }

  return await http.post(getEndpoint(videoType, version), data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getFirstFrame = async (file, videoRef, canvasRef) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('File is not provided'))
      return
    }

    let video
    let canvas

    if (videoRef) {
      video = videoRef.current
    } else {
      video = document.createElement('video')
      video.src = URL.createObjectURL(file)
    }

    if (canvasRef) {
      canvas = canvasRef.current
    } else {
      canvas = document.createElement('canvas')
    }

    const ctx = canvas.getContext('2d')

    video.load()
    video.currentTime = 0.001

    let metadataLoaded = false

    video.addEventListener('loadedmetadata', function () {
      // Set canvas dimensions same as video dimensions
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      metadataLoaded = true
    })

    video.addEventListener('canplaythrough', async function () {
      if (!metadataLoaded) {
        reject(new Error('Metadata not loaded'))
        return
      }

      await new Promise((r) => setTimeout(r, 1000))
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const thumbnailDataUrl = canvas.toDataURL()

      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        thumbnailDataUrl,
      })
    })
  })
}

export async function convertDataURLToFile(url, fileName = 'sample.mp4') {
  const proxiedUrl = getProxiedR2FileUrl(url, true)
  const blob = await (await fetch(proxiedUrl)).blob()
  return new File([blob], fileName, { type: blob.type })
}
