import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Button from '../components/welcome/Button'
import StudioSection from '../components/studio/StudioSection'
import QuoteSection from '../components/studio/QuoteSection'
import StudioReel from '../components/studio/StudioReel'
import { cn } from '../utils'
import { useUserAccountContext } from '../context/userContext'

const dImg = '/website/studio/d_studio.png'

export const Studio = () => {
  const { featureToggles } = useUserAccountContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  return (
    <div className='bg-black'>
      <Navbar />
      <main
        className={cn(
          'flex flex-col flex-1 w-full py-32 gap-24 text-white pt-28',
          { 'pt-40': isFeedbackBannerShown },
        )}
      >
        <div className='flex flex-col mx-auto flex-1 max-w-[1440px] w-full md:px-[3.5%] px-4 gap-24'>
          <div className='flex flex-col items-center sm:flex-row gap-14'>
            <img
              className='flex md:max-w-full max-w-[300px] md:w-[38%] w-full object-contain'
              src={dImg}
              alt='CTA Kaiber'
            />
            <div className='flex flex-col flex-1 max-w-3xl gap-10'>
              <div className='flex flex-col gap-5'>
                <h1 className='text-4xl font-semibold lg:text-8xl sm:text-6xl'>
                  Kaiber Studio
                </h1>
                <p className='text-2xl lg:text-4xl sm:text-3xl text-white/80 max-w-[620px]'>
                  We wield creativity and cutting-edge research to build
                  jaw-dropping projects and tell unprecedented, immersive
                  stories with leading artists and brands across industries.
                </p>
              </div>
              <a href='mailto:hello@kaiber.ai'>
                <Button btnText='Get in Touch' />
              </a>
            </div>
          </div>

          <StudioReel />

          <StudioSection />
        </div>

        <QuoteSection />
      </main>
      <Footer />
    </div>
  )
}
