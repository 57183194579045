import { SVGProps } from '@/types'

export const TrashIcon = ({ size = 28, className }: SVGProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 28 28'
    fill='currentColor'
    stroke='currentColor'
    strokeWidth='.5'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path d='M7.86174 5.16663H8.20134L8.32653 4.85094C9.18258 2.69226 11.2883 1.16663 13.751 1.16663C16.2139 1.16663 18.3196 2.69227 19.1756 4.85093L19.3008 5.16663H19.6404H26.0832C26.3593 5.16663 26.5832 5.39049 26.5832 5.66663C26.5832 5.94276 26.3593 6.16663 26.0832 6.16663H24.3541H23.8853L23.8551 6.63443L22.7431 23.8706C22.7431 23.8706 22.7431 23.8706 22.7431 23.8706C22.6356 25.5368 21.2527 26.8333 19.583 26.8333H7.91668C6.24691 26.8333 4.86408 25.5368 4.75657 23.8706L3.64457 6.63443L3.61439 6.16663H3.14561H1.4165C1.14037 6.16663 0.916504 5.94276 0.916504 5.66663C0.916504 5.39049 1.14037 5.16663 1.4165 5.16663H7.86174ZM9.67758 4.397L9.18476 5.16663H10.0986H17.4036H18.3175L17.8246 4.39698C16.9662 3.05647 15.462 2.16663 13.751 2.16663C12.0402 2.16663 10.536 3.05647 9.67758 4.397ZM12.2498 12.3333C12.2498 11.5049 11.5782 10.8333 10.7498 10.8333C9.92141 10.8333 9.24984 11.5049 9.24984 12.3333V19.6666C9.24984 20.495 9.92141 21.1666 10.7498 21.1666C11.5782 21.1666 12.2498 20.495 12.2498 19.6666V12.3333ZM18.2498 12.3333C18.2498 11.5049 17.5782 10.8333 16.7498 10.8333C15.9214 10.8333 15.2498 11.5049 15.2498 12.3333V19.6666C15.2498 20.495 15.9214 21.1666 16.7498 21.1666C17.5782 21.1666 18.2498 20.495 18.2498 19.6666V12.3333Z' />
  </svg>
)
