import { Dispatch, SetStateAction } from 'react'
import { PixelCrop } from 'react-image-crop'

import {
  RATIO_LEFT_SETTING_CONFIG,
  RATIO_RIGHT_SETTING_CONFIG,
} from '../../../constants'
import { RatioSettingConfig } from '../../../types'
import { cn } from '../../../utils'
interface RatioSettingsProps {
  selectedRatioId: number
  dimensions: {
    width: number
    height: number
  }
  onRatioChange: Dispatch<SetStateAction<number | undefined>>
  onRatioIdChange: Dispatch<SetStateAction<number>>
  onCropChange: (crop: PixelCrop) => void
  handleCompletedCrop: (crop: PixelCrop) => void
}

export const RatioSettings = ({
  selectedRatioId,
  dimensions,
  onRatioChange,
  onCropChange,
  onRatioIdChange,
  handleCompletedCrop,
}: RatioSettingsProps) => {
  const handleCropChange = (config: RatioSettingConfig) => {
    if (selectedRatioId === config.id) {
      const crop: PixelCrop = {
        unit: 'px',
        x: 0,
        y: 0,
        width: dimensions.width,
        height: dimensions.height,
      }

      onRatioIdChange(-1)
      onRatioChange(undefined)
      onCropChange(crop)
      handleCompletedCrop(crop)
    } else {
      const scaleX = Math.floor(dimensions.width / config.width)
      const scaleY = Math.floor(dimensions.height / config.height)

      const scale = Math.min(scaleX, scaleY)
      const width = config.width * scale
      const height = config.height * scale

      const x = (dimensions.width - width) / 2
      const y = (dimensions.height - height) / 2

      const crop: PixelCrop = {
        unit: 'px',
        x: x,
        y: y,
        width: width,
        height: height,
      }

      onRatioIdChange(config.id)
      onRatioChange(config.width / config.height)
      onCropChange(crop)
      handleCompletedCrop(crop)
    }
  }

  return (
    <div className='flex flex-row gap-4'>
      <div className='flex-1 flex flex-col gap-4 justify-between'>
        {RATIO_LEFT_SETTING_CONFIG.map((config, idx) => (
          <div
            key={idx}
            style={{ aspectRatio: config.ratio.w / config.ratio.h }}
            onClick={() => handleCropChange(config)}
            className={cn(
              'p-4 flex flex-col items-center justify-center gap-1 rounded border border-[#4F4F50] bg-[#3A3A3A] hover:border-[#A6A6A6] hover:bg-[#757575] transition-colors text-[#E3E3E3] cursor-pointer text-xs text-center',
              {
                'border-[#9F9F9F] bg-[#656565]': config.id === selectedRatioId,
              },
            )}
          >
            <span>{`${config.ratio.w}:${config.ratio.h}`}</span>
            <span>{config.label}</span>
          </div>
        ))}
      </div>
      <div className='flex-1 flex flex-col gap-4 justify-between'>
        {RATIO_RIGHT_SETTING_CONFIG.map((config, idx) => (
          <div
            key={idx}
            style={{ aspectRatio: config.ratio.w / config.ratio.h }}
            onClick={() => handleCropChange(config)}
            className={cn(
              'p-4 flex flex-col items-center justify-center gap-1 rounded border border-[#4F4F50] bg-[#3A3A3A] hover:border-[#A6A6A6] hover:bg-[#757575] transition-colors text-[#E3E3E3] cursor-pointer text-xs text-center',
              {
                'border-[#9F9F9F] bg-[#656565]': config.id === selectedRatioId,
              },
            )}
          >
            <span>{`${config.ratio.w}:${config.ratio.h}`}</span>
            <span>{config.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
