import { useState } from 'react'
const arrow = '/website/community/arrow.svg'

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleItem = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <div className='accordion'>
      {items.map((item, index) => (
        <div
          key={index}
          className={`border-t-[1px] py-[12px] md:pt-[20px] md:pb-[10px] border-[#1E1E1E] accordion-item ${
            activeIndex === index ? 'active' : ''
          }`}
        >
          <div
            className='accordion-title cursor-pointer flex items-center'
            onClick={() => toggleItem(index)}
          >
            <span
              className={`arrow transition-transform ${
                activeIndex === index ? 'rotate-180' : ''
              }`}
            >
              <img src={arrow} alt='arrow' />
            </span>
            <h3 className='text-[28px] md:text-[42px] pl-[15px] font-semibold'>
              {item.title}
            </h3>
          </div>
          <div
            className={`accordion-content overflow-hidden transition-max-h  ${
              activeIndex === index ? 'max-h-[300px]' : 'max-h-0'
            } text-[18px] md:text-[24px] pt-[10px] font-normal ml-[42px]`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion
