import { Collection } from '@kaiber/shared-types'
import { Slider } from '@material-tailwind/react'
import { useCallback, useState, memo } from 'react'
import { InView } from 'react-intersection-observer'
import { useThrottledCallback } from 'use-debounce'

import { useThemeContext } from '../../../context'
import { useMutateCollection, useFileUpload, useHotkeys } from '../../../hooks'
import { cn } from '../../../utils'
import { useDndHandlers } from '../../../utils/dndUtils'
import { MediaGrid } from '../Media'
import { ScaleWrapper } from '../ScaleWrapper'
import { FileWithSource, MediaUploadSource } from '@/types'

export interface CollectionProps {
  collection: Collection
  error?: Error
  isPending: boolean
  columns?: number
  handleColumnsChange?: (columns: number) => void
  width: number
}

const MAX_COLUMNS = 6
const MIN_COLUMNS = 1
const DEFAULT_COLUMNS = 3

const COLUMNS_SLIDER_PADDING_X = 100
const COLUMNS_SLIDER_MAX_HEIGHT = 20

export const CollectionDisplay = memo(
  ({
    collection,
    error,
    isPending,
    columns = DEFAULT_COLUMNS,
    handleColumnsChange,
    width,
  }: CollectionProps) => {
    // Infinite Scroll logic. It's very simple because FE already has
    // all the mediaIds in a collection (aka no fetching needed).
    const PAGE_SIZE = 10
    const [mediaCount, setMediaCount] = useState(PAGE_SIZE)
    const maxMediaCount = collection?.mediaIds?.length
    const handleLoadMore = useThrottledCallback(
      (inView) =>
        inView &&
        mediaCount < maxMediaCount &&
        setMediaCount(Math.min(maxMediaCount, mediaCount + PAGE_SIZE)),
      1000,
    )

    const { colors } = useThemeContext()
    const { isHotkeyPressed } = useHotkeys()
    const { prependMedia } = useMutateCollection()
    const { uploadAndProcessFiles } = useFileUpload()

    const handleFileDrop = useCallback(
      async (filesWithSource: FileWithSource[]) => {
        if (!collection?.collectionId) return
        try {
          const processedFiles = await uploadAndProcessFiles(
            filesWithSource,
            MediaUploadSource.CollectionUpload,
          )

          for (const media of processedFiles) {
            await prependMedia(collection.collectionId, media.mediaId)
          }
        } catch (error) {
          console.error('Error handling file drop:', error)
        }
      },
      [collection?.collectionId, prependMedia, uploadAndProcessFiles],
    )

    const {
      handleDrop,
      handleDragOver,
      handleDragEnter,
      handleDragLeave,
      handleDragEnd,
    } = useDndHandlers({ handleFileDrop })

    if (error)
      return (
        <div className='text-white'>
          Fetching collection {collection?.collectionId} failed. This collection
          may not exist or you dont have access to it. {error?.message}
        </div>
      )

    return (
      <div
        className='h-full flex flex-col'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragEnd}
      >
        <div
          className={cn('flex-grow overflow-y-auto', {
            'nowheel': !isHotkeyPressed(['Meta', 'Control', 'Shift']),
          })}
        >
          <MediaGrid
            mediaIds={collection?.mediaIds?.slice(0, mediaCount) || []}
            thumbnailClassName='border rounded-2xl border-[rgba(242,242,242,0.7)]'
            columns={columns}
            isLoading={isPending}
          />
          <InView className={'h-1'} onChange={handleLoadMore} />
        </div>
        {handleColumnsChange && (
          <div className='p-3 top-6'>
            <div className='absolute bottom-6 right-16 w-48'>
              <ScaleWrapper
                className='origin-bottom-right'
                maxWidth={width - COLUMNS_SLIDER_PADDING_X}
                maxHeight={COLUMNS_SLIDER_MAX_HEIGHT}
              >
                <Slider
                  className={cn(
                    'nodrag text-transparent rounded-lg',
                    colors.elevation.surface.secondary,
                  )}
                  value={MAX_COLUMNS - columns + MIN_COLUMNS}
                  onChange={(e) => {
                    const invertedValue =
                      MAX_COLUMNS - parseInt(e.target.value, 10) + MIN_COLUMNS
                    handleColumnsChange(invertedValue)
                  }}
                  min={MIN_COLUMNS}
                  max={MAX_COLUMNS}
                  step={1}
                  thumbClassName='[&::-moz-range-thumb]:bg-k2-gray-200 [&::-webkit-slider-thumb]:bg-k2-gray-200' // @todo: can't figure out how to get this to work with variables - the thumb is always invisible https://kaiberteam.atlassian.net/browse/ENG-2898
                  trackClassName={cn(
                    `[&::-moz-range-track]:${colors.elevation.surface.secondary} [&::-webkit-slider-runnable-track]:${colors.elevation.surface.secondary}`,
                  )}
                  aria-labelledby='continuous-slider'
                />
              </ScaleWrapper>
            </div>
          </div>
        )}
      </div>
    )
  },
)

CollectionDisplay.displayName = 'CollectionDisplay'
