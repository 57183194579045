import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { IN_THE_STYLE_OF, USER_AGENTS } from './constants'

const MOBILE_MAX_WIDTH = 499
const BETA_HOSTNAME = 'beta.kaiber.ai'

/**
 * Is the client a mobile device?
 *
 * @returns bool
 */
export const isMobile = () => {
  const mediaQuery = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`)
  return mediaQuery.matches || isUserAgent(USER_AGENTS.mobile)
}

/**
 * Scrolls to top of window smoothly
 */
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

/**
 * Takes a prompt and returns an object made of prompt parts.
 *
 * Example:
 * 'a futuristic cyberpunk, in the style of Entergalactic'
 *
 * Returns:
 * {
 *   subject: 'a futuristic cyberpunk',
 *   artStyle: 'Entergalactic'
 * }
 *
 * @param {string} prompt
 * @returns {} object
 */
export const parsePrompt = (prompt) => {
  let subject = '',
    artStyle = ''

  if (prompt) {
    ;[subject, artStyle] = prompt.split(IN_THE_STYLE_OF)
    artStyle ??= ''
  }

  return { subject, artStyle }
}

/**
 * Formats duration of time in the format M:SS
 *
 * @param value time in seconds
 * @returns string in the format 0:01
 */
export const formatDuration = (value) => {
  const minute = Math.floor(value / 60)
  const secondLeft = Math.ceil(value - minute * 60)
  return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`
}

/**
 * Returns aspect ratio styles based on aspect ratio
 *
 * @param string aspectRatio
 * @returns string of tailwind classnames
 */
export const getAspectStyle = (aspectRatio) => {
  switch (aspectRatio) {
    case '16:9':
      return 'aspect-[16/9] h-12'
    case '4:3':
      return 'aspect-[4/3] h-12'
    case '1:1':
      return 'aspect-square h-12'
    case '3:4':
      return 'aspect-[3/4] w-12'

    // case '9:16': // this is default
    //   return 'aspect-[9/16] w-12'

    default:
      return 'aspect-[9/16] w-12'
  }
}

/**
 * Gets aspect ratio from width and height
 *
 * @param number width
 * @param number height
 * @returns string
 */
export const getAspectRatioFromWidthAndHeight = (width, height) => {
  const widthAndHeightMappings = {
    '1024x576': '16:9',
    '768x576': '4:3',
    '512x512': '1:1',
    '576x768': '3:4',
    '576x1024': '9:16',
  }

  return widthAndHeightMappings[`${width}x${height}`]
}

/**
 * Tells you if the client is on iOS, Android, etc.
 *
 * @returns bool
 */
export const isUserAgent = (deviceName) => {
  if (typeof window === 'undefined') return false

  const { iOS, Android, iPhone, iPad, mobile } = USER_AGENTS

  const userAgent = window.navigator.userAgent

  switch (deviceName) {
    case iOS:
      return /iPhone|iPad|iPod/i.test(userAgent)
    case Android:
      return /Android/i.test(userAgent)
    case iPhone:
      return /iPhone/i.test(userAgent)
    case iPad:
      return /iPad/i.test(userAgent)
    case mobile:
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent,
      )
    default:
      return false
  }
}

/**
 * Returns true if user is a non-subscribed user with credits
 * @param {object} user
 * @returns {boolean}
 * @example
 * isNonSubscribedUserWithCredits({ subscriptionTier: null, credits: 1 }) // true
 */
export const isNonSubscribedUserWithCredits = (user) => {
  return user?.subscriptionTier == null && user?.credits && user?.credits > 0
}

/**
 * Shuffles an array in place.
 *
 * @param {array} arr
 * @returns {array}
 */
export const shuffleArray = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }
  return arr
}

/**
 * @example
 * cn('text-white',
 *  'text-red-500': validationError,
 *  'text-green-500': !validationError,
 * )
 */
export const cn = (...inputs) => twMerge(clsx(inputs))

/**
 * Returns true if the client is on the beta site.
 * @returns {boolean}
 */
export const isBeta = () => window.location.hostname === BETA_HOSTNAME

/**
 * Returns a prompt in the format `{subject}, in the style of {style}`
 *
 * @param {string} subject
 * @param {string} style
 * @returns string
 */
export const getPrompt = (subject, style) => {
  return `${subject}${IN_THE_STYLE_OF}${style}`
}

/**
 * Returns the type and version in the format `{type}_v{version}`, ie. `motion_v3`
 *
 * @param {string} type
 * @param {string} version
 * @returns string
 */
export const getTypeAndVersion = (type, version) => {
  return `${type}_v${version}`
}
