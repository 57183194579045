interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const LoadingIcon = (props: SVGProps) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 100 100'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    {...props}
  >
    <g transform='rotate(0 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.875s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(45 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.75s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(90 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.625s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(135 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.5s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(180 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.375s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(225 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.25s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(270 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='-0.125s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(315 50 50)'>
      <rect
        x='48.5'
        y='15'
        rx='2'
        ry='2'
        width='3'
        height='20'
        fill='currentColor'
      >
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1s'
          begin='0s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
  </svg>
)
