import React from 'react'

import { SVGProps } from '@/types'

export const BookmarkIcon: React.FC<SVGProps> = ({ size = 20, ...props }) => (
  <svg
    stroke='currentColor'
    fill='none'
    strokeWidth='0'
    viewBox='0 0 20 20'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.0413 16.8762V3.95829C16.0413 3.03782 15.2952 2.29163 14.3747 2.29163H5.62467C4.7042 2.29163 3.95801 3.03782 3.95801 3.95829V16.8762C3.95801 17.5435 4.70312 17.94 5.25667 17.5675L9.06901 15.0014C9.63167 14.6227 10.3677 14.6227 10.9303 15.0014L14.7427 17.5675C15.2963 17.9401 16.0413 17.5435 16.0413 16.8762Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
