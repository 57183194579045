import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import {
  init as initSentry,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { STAGE } from './constants/environment'
import { UserAccountProvider } from './context/userContext'
import { VideoEventsProvider } from './context/videoEventsContext'
import { ExternalProviders } from './ExternalProviders'

initSentry({
  dsn: 'https://9348ec458b83e7582c3a35f036e91604@o4507955203997696.ingest.us.sentry.io/4507960281661440',
  integrations: [browserTracingIntegration(), replayIntegration()],
  environment: STAGE,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/kaiber\.ai\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// eslint-disable-next-line no-restricted-globals
if (top && top.location.hostname !== self.location.hostname) {
  // eslint-disable-next-line no-restricted-globals
  top.location.href = self.location.href
}

const config = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  loader_config: {
    accountID: '4480399',
    trustKey: '4480399',
    agentID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    sa: 1,
  },
}

new BrowserAgent(config)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <UserAccountProvider>
    <ExternalProviders>
      <VideoEventsProvider>
        <App />
      </VideoEventsProvider>
    </ExternalProviders>
  </UserAccountProvider>,
)

if (process.env.NODE_ENV === 'production') {
  console.log('%cTake caution!', 'font-size: 36px; color: red;')
  console.log(
    `%cThis is a developer-oriented browser feature. If someone tells you to copy and paste something here to activate a Kaiber feature, that's a scam and you'll give them access to your account.`,
    'font-size: 22px',
  )
}
