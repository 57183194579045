function CommunityComponent({ image, title, url }) {
  return (
    <div className='flex flex-col sm:gap-7 gap-3'>
      <a
        href={url}
        target='_blank'
        rel='noreferrer'
        className='overflow-hidden rounded-3xl pt-[60%] relative'
      >
        <img
          className='object-contain md:w-auto w-[120%] max-w-[110%] object-center absolute left-0 top-0'
          src={image}
          alt='Kaiber Community Art'
        />
      </a>
      <p className='lg:text-2xl sm:text-xl text-lg text-white'>{title}</p>
    </div>
  )
}

export default CommunityComponent
