import { useRef, useState } from 'react'

import { cn } from '../../../utils'
import { useDndHandlers } from '../../../utils/dndUtils'
import { useCanvasContext } from '../../../context'
import { ElementType } from '@/types'
import { ELEMENTS_CONFIG } from '../../../config'

interface ElementMenuItemProps {
  element: ElementType
  formId: string
  closePopover: () => void
}

export const ElementMenuItem = ({
  element,
  formId,
  closePopover,
}: ElementMenuItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isHover, setIsHover] = useState<boolean>(false)
  const { handleDragStart } = useDndHandlers({})
  const { addCanvasElement } = useCanvasContext()

  return (
    <div
      className='hover:scale-105 transition-all ease-in-out duration-200 cursor-pointer'
      ref={ref}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        addCanvasElement(formId, element)
        closePopover()
      }}
    >
      <div
        className={cn(
          'w-28 h-16 bg-k2-red-200 rounded-md mb-1 overflow-hidden ',
          { 'border-2 border-solid border-k2-gold-300': isHover },
        )}
      >
        {ELEMENTS_CONFIG[element].imageSource && (
          <img
            src={ELEMENTS_CONFIG[element].imageSource}
            alt={ELEMENTS_CONFIG[element].label}
            className='w-full h-full object-cover select-none'
            onDragStart={handleDragStart}
          />
        )}
      </div>
      <p className='text-xs font-light select-none'>
        {ELEMENTS_CONFIG[element].label}
      </p>
    </div>
  )
}
