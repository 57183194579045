import { useEffect } from 'react'

const VideoItem = ({ videoSrc, index }) => (
  <div key={index} className='scroller__item'>
    <div className='block w-full rounded-[10px] md:overflow-hidden md:aspect-[1.8] max-w-[100%] cursor-pointer'>
      <iframe
        className='w-[350px] h-[196px] md:w-[560px] md:h-[315px]'
        src={videoSrc}
        title={`YouTube video player ${index + 1}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
      ></iframe>
    </div>
  </div>
)

const MusicianVideoSlider = () => {
  const videoUrls = [
    'https://www.youtube.com/embed/YvIxMU1UgfI?si=TvHRQj1MNL2qurx6',
    'https://www.youtube.com/embed/7NK_JOkuSVY?si=ow0qWpylGlartwQp',
    'https://www.youtube.com/embed/zr-PTLqbYqo?si=67pujbg2QxvdXpUA',
    'https://www.youtube.com/embed/6AXfUamllek?si=nQ-5PQhh1DbMKLEX',
    'https://www.youtube.com/embed/E-hfNBsbfOw?si=0UxGJ9hzCkhicKzD',
    'https://www.youtube.com/embed/CoAad6p_hGY?si=hGErCqgg4UeuaVT8',
  ]

  const movieItems = videoUrls.map((url, index) => (
    <VideoItem key={index} videoSrc={url} index={index} />
  ))

  const movieItemsBottom = [
    'https://www.youtube.com/embed/HbfsaP5WN6c?si=v36my_VWom3ydqNM',
    'https://www.youtube.com/embed/C0WyyMppPE0?si=gOkyh3LdjHjNGg_B',
    'https://www.youtube.com/embed/3IUpzhJ7P3c?si=UItel-uucQ-pBDpI',
    'https://www.youtube.com/embed/ucgqoyrAAz4?si=5VI6T4fgOT6sfWmU',
    'https://www.youtube.com/embed/8jjI_CuicY4?si=MHU9T1Xu-u2VEC_g',
    'https://www.youtube.com/embed/tUnSBKqSSKQ?si=EFozvSBe7YpyqB3V',
  ].map((url, index) => <VideoItem key={index} videoSrc={url} index={index} />)

  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller')

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation()
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute('data-animated', true)

        const scrollerInner = scroller.querySelector('.scroller__inner')
        const scrollerContent = Array.from(scrollerInner.children)

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true)
          duplicatedItem.setAttribute('aria-hidden', true)
          scrollerInner.appendChild(duplicatedItem)
        })

        // Add pause on hover functionality
        scroller.addEventListener('mouseenter', () => {
          scrollerInner.style.animationPlayState = 'paused'
        })

        scroller.addEventListener('mouseleave', () => {
          scrollerInner.style.animationPlayState = 'running'
        })
      })
    }
  }, [])

  return (
    <div>
      <div
        className='scroller max-w-[100%] w-full'
        data-direction='right'
        data-speed='slow'
      >
        <div className='scroller__inner flex flex-wrap gap-4'>{movieItems}</div>
      </div>

      <div
        className='scroller max-w-[100%] w-full mt-[20px]'
        data-direction='left'
        data-speed='slow'
      >
        <div className='scroller__inner flex flex-wrap gap-4'>
          {movieItemsBottom}
        </div>
      </div>
    </div>
  )
}

export default MusicianVideoSlider
