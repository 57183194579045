import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import { Modal } from './Modal'
import { useUserAccountContext } from '../context/userContext'
import { generateVideoRequest } from '../utils/video_util'
import { CoinsIcon } from '../images/CoinsIcon'
import { DEFAULTS } from '../utils/constants'
import { getCreditsCost } from '../utils/credits_util'

const COLORS = {
  PRIMARY: '#97E8A6',
  RED: '#F87171',
}

export const RegenerateVideoModal = ({
  isOpen,
  setVisible,
  sceneData,
  runSettings,
}) => {
  const navigate = useNavigate()
  const { currentUser, refreshCredits } = useUserAccountContext()
  const [note] = useState('')

  const {
    type: videoType,
    version,
    length,
    motion_magnitude,
    memory_id: reusedSettingsMemoryId,
    fps,
    init_image_s3_key: initImage,
    init_video_s3_key: userProvidedVideoFileS3Key, // userProvidedVideoS3Key indicates that a user has reused a previous video
    height,
    width,
    s3_images,
    use_init,
    original_prompt: originalPrompt,
    processed_frame_timestamp: processedFrameTimestamp,
    prompt,
    steps,
    use_img2img: useImg2Img,
    retainInitialImage,
    stableDiffusionCheckpoint,
    show_init_image,
    typeAndVersion,
    user_provided_starting_frame_s3_key,
  } = runSettings

  const {
    previewFramesSettings,
    aspectRatio,
    curatedStyle,
    evolve,
    subject,
    style,
    audioReactivity,
    length: sceneDataLength,
    prompt: sceneDataPrompt,
  } = sceneData

  let regenerateVersion = version
  let showUserProvidedStartingFrameInFirstFrameOfVideo = use_init

  // NOTE: creditsCost may not always be available, ie. motion v2
  const cost = getCreditsCost(
    videoType,
    regenerateVersion,
    length || sceneDataLength,
  )
  const userCredits = currentUser?.credits || 0
  const hasSufficientCredits = userCredits >= cost
  const scenes = JSON.stringify(runSettings.scenes)
  const s3Images = JSON.stringify(s3_images)

  const handleRegenerate = async () => {
    setVisible(false)
    toast('✨ Regenerating your video', { autoClose: 2200 })

    try {
      const response = await generateVideoRequest({
        videoType,
        version: regenerateVersion,
        scenes,
        aspectRatio,
        audioReactivity,
        initImage,
        userProvidedStartingFrame:
          previewFramesSettings?.userProvidedStartingFrame,
        reusedSettingsMemoryId,
        fps: fps || DEFAULTS.FPS,
        userProvidedVideoFileS3Key,
        height,
        width,
        motionMagnitude: motion_magnitude || DEFAULTS.MOTION_MAGNITUDE,
        curatedStyle,
        length,
        evolve,
        prompt: prompt || sceneDataPrompt,
        subject,
        style,
        s3Images,
        showUserProvidedStartingFrameInFirstFrameOfVideo,
        audioFileS3Key: runSettings.audio_file_s3_key,
        originalPrompt: originalPrompt || sceneDataPrompt,
        processedFrameTimestamp,
        steps,
        useImg2Img,
        isRegenerate: true,
        retainInitialImage,
        stableDiffusionCheckpoint,
        show_init_image,
        typeAndVersion,
        user_provided_starting_frame_s3_key,
      })
      refreshCredits()
      navigate('/memory/' + response.data.memory_id)
      window.location.reload()
    } catch (e) {
      const message =
        e.response?.data?.error ||
        e.response?.data ||
        e.message ||
        'Something went wrong.'
      toast.error(message)
    }
  }

  const Divider = () => <hr className='bg-stone w-full h-px border-0 my-4' />
  const Note = () => (
    <p className='text-tertiary/80 text-center text-sm py-3'>Note: {note}</p>
  )

  return (
    <Modal open={isOpen} setOpen={setVisible} containerClassName='sm:max-w-xs'>
      <div className='flex flex-col mt-2 sm:mt-4 mb-[22px] items-center mx-2 sm:mx-4 w-[90%] max-w-md'>
        <h2 className='text-white mb-1'>Regenerate Video</h2>
        <Divider />
        <div className='flex justify-between w-full text-white'>
          My credits
          <span className='flex gap-2 items-center'>
            {userCredits} <CoinsIcon />
          </span>
        </div>
        <Divider />
        <div
          className={classNames('flex justify-between w-full text-primary', {
            'text-[#F87171]': !hasSufficientCredits,
          })}
        >
          Cost
          <span className='flex gap-2 items-center'>
            {cost}
            <CoinsIcon
              color={hasSufficientCredits ? COLORS.PRIMARY : COLORS.RED}
            />
          </span>
        </div>
        <Divider />

        {hasSufficientCredits ? (
          <>
            {!!note && <Note />}
            <button
              onClick={handleRegenerate}
              className='mt-3 text-center w-full rounded-full border border-transparent radial-gradient hover:bg-secondary text-gray-700 bg-secondary px-3 py-3 text-lg font-bold focus:outline-none focus:ring-0'
            >
              Regenerate
            </button>
          </>
        ) : (
          <div className='flex flex-col items-center w-full space-y-5'>
            <p className='text-tertiary/80 text-center text-sm'>
              You don't have enough credits to regenerate.
            </p>
            <Link
              to='/pricing'
              className='block rounded-full text-center border bg-primary px-3 py-3 font-bold text-gray-700 shadow-sm border-primary w-full text-lg'
            >
              Buy More Credits
            </Link>
          </div>
        )}
      </div>
    </Modal>
  )
}
