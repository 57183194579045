import { SVGProps } from '@/types'

export const AddCollectionIcon = ({ size = 20, className }: SVGProps) => (
  <svg
    stroke='currentColor'
    fill='none'
    height={size}
    width={size}
    viewBox='0 0 20 18'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.25 16.25H2.75C1.64543 16.25 0.75 15.3546 0.75 14.25V2.75C0.75 1.64543 1.64543 0.75 2.75 0.75H6.92963C7.59834 0.75 8.2228 1.0842 8.5937 1.6406L9.4063 2.8594C9.7772 3.4158 10.4017 3.75 11.0704 3.75H17.25C18.3546 3.75 19.25 4.64543 19.25 5.75V7.25M16 10.75V14M16 14V17.25M16 14H12.75M16 14H19.25'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
