import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

export default function CTA({ title, body, buttonTitle }) {
  return (
    <div className='bg-dark p-12 cta-gradient'>
      <div className='rounded-xl max-w-7xl mx-auto'>
        <div className='mx-auto  py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8'>
          <h2 className='text-3xl font-semibold tracking-tight text-tertiary sm:text-7xl'>
            <span className='block'>{title || 'Unleash Your Imagination'}</span>
          </h2>
          <p className='mt-6 text-lg sm:text-2xl leading-6 text-tertiary/60'>
            {body || 'The art of storytelling, instantly.'}
          </p>
          <Link to='/register'>
            <button className='mx-auto radial-gradient text-gray-900 px-6 py-5 rounded-full text-base font-bold uppercase flex items-center gap-2 mt-12 button-shadow'>
              {buttonTitle || 'SIGN UP TO CREATE'}
              <ArrowRightIcon className='h-6 w-6' />
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
