/**
 * Inserts an item into an array at a specified index and trims the resulting array to a maximum length.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The original array.
 * @param {number} index - The index at which to insert the new item.
 * @param {T} newItem - The item to insert.
 * @param {number} maxLength - The maximum length of the resulting array.
 * @returns {T[]} A new array with the item inserted and trimmed to the specified maximum length.
 */
export const insertItemAndTrimArray = <T>(
  array: T[],
  index: number,
  newItem: T,
  maxLength: number,
): T[] => {
  const newArray = [...array]
  newArray[index] = newItem
  return newArray.slice(0, maxLength)
}
