import { ElementType, FlowConfig, MediaType, ModelType } from '@/types'

export const FLOWS_CONFIG: Partial<Record<ModelType, FlowConfig>> = {
  [ModelType.ImageLab]: {
    mediaType: MediaType.Image,
    requiredElements: [ElementType.Prompt],
    optionalElements: [
      ElementType.CheckpointSelector,
      ElementType.StyleReferenceUpload,
      ElementType.CompositionReferenceUpload,
      ElementType.ImageReferenceUpload,
      ElementType.FaceReferenceUpload,
      ElementType.ControlnetReferenceUpload,
      ElementType.ImageSettings,
    ],
  },
  [ModelType.InpaintV1]: {
    mediaType: MediaType.Image,
    requiredElements: [ElementType.InpaintPrompt, ElementType.ImageUpload],
    optionalElements: [],
  },
  [ModelType.MotionV3]: {
    mediaType: MediaType.Video,
    requiredElements: [ElementType.Prompt],
    optionalElements: [
      ElementType.ImageUpload,
      ElementType.AudioUpload,
      ElementType.MotionV3Settings,
    ],
  },
  // TODO - update this to use the new flow type with appropriate elements
  [ModelType.MotionV2]: {
    mediaType: MediaType.Video,
    requiredElements: [
      ElementType.ImageUpload,
      ElementType.Prompt,
      ElementType.MotionV2Settings,
    ],
    optionalElements: [ElementType.AudioUpload],
  },
  [ModelType.TransformV3]: {
    mediaType: MediaType.Video,
    requiredElements: [ElementType.VideoUpload, ElementType.Prompt],
    optionalElements: [ElementType.TransformV3Settings],
  },
  [ModelType.FlipbookV1]: {
    mediaType: MediaType.Video,
    requiredElements: [ElementType.Prompt, ElementType.FlipbookV1Settings],
    optionalElements: [ElementType.ImageUpload],
  },
  [ModelType.FluxImage]: {
    mediaType: MediaType.Image,
    requiredElements: [ElementType.Prompt, ElementType.FluxImageSettings],
    optionalElements: [],
  },
  [ModelType.UpscaleV1]: {
    mediaType: MediaType.Image,
    requiredElements: [ElementType.ImageUpload, ElementType.Prompt],
    optionalElements: [ElementType.UpscaleV1Settings],
  },
}
