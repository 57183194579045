import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function Terms() {
  return (
    <div className='bg-dark'>
      <Navbar />
      <div className='max-w-7xl mx-auto px-5 py-12 pt-32'>
        <div className='max-w-3xl space-y-4 mx-auto text-justify'>
          <h1 className='text-6xl text-tertiary'>Privacy Policy</h1>
          <h2 className='text-xl text-tertiary'>
            Date of Last Revision: October 31, 2023
          </h2>
          <p className='text-tertiary/60'>
            Kyber Corp. dba Kaiber (“Kaiber”, “we”, “us”, or “our”) has prepared
            this Privacy Policy to explain (1) what personal information we
            collect, (2) how we use and share that information, and (3) your
            choices concerning our privacy and information practices. This
            Privacy Policy applies to the personal information that we collect
            from users who have registered an account for us or otherwise
            interact with our website at{' '}
            <a className='text-primary' href='https://kaiber.ai'>
              https://kaiber.ai
            </a>{' '}
            , our mobile applications or any other products or services that
            link to this Privacy Policy (collectively, the
            &ldquo;Service&rdquo;).
          </p>
          <p className='text-tertiary/60 font-bold text-xl'>
            Personal information we collect
          </p>
          <p className='text-tertiary/60 font-bold'>
            Information you provide to us:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              <strong>Contact information</strong>, such as your name, email,
              and other similar information when you create an account with us.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Payment and transaction information</strong> needed to
              purchase a subscription from us, including name, payment card
              information, billing information. Your payment information is
              processed by our payment processor, and we do not have direct
              access to any credit card information
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Feedback or correspondence</strong>, such as information
              you provide when you contact us with questions, feedback, product
              reviews, or otherwise correspond with us online.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Marketing information</strong>, such as your preferences
              for receiving communications about our activities, events, and
              publications, and details about how you engage with our
              communications.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Other information</strong> that we may collect which is
              not specifically listed here, but which we will use in accordance
              with this Privacy Policy or as otherwise disclosed at the time of
              collection.
            </li>
          </ul>

          <p className='text-tertiary/60 font-bold'>
            Information we obtain from third parties:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              <strong>Social media information. </strong>We may maintain pages
              on social media platforms, such as Facebook, LinkedIn, Instagram,
              and other third-party platforms. When you visit or interact with
              our pages on those platforms, the platform provider’s privacy
              policy will apply to your interactions and their collection, use
              and processing of your personal information. You or the platforms
              may provide us with information through the platform, and we will
              treat such information in accordance with this Privacy Policy.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Other Sources.</strong>We may obtain your personal
              information from other third parties, such as marketing partners,
              data providers, and publicly-available sources.
            </li>
          </ul>

          <p className='text-tertiary/60'>
            <strong>Automatic data collection.</strong>We and our service
            providers may automatically log information about you, your computer
            or mobile device, and your interaction over time with our Services,
            our communications and other online services, such as:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              <strong>Device data,</strong>such as your computer’s or mobile
              device’s operating system type and version, manufacturer and
              model, browser type, screen resolution, RAM and disk size, CPU
              usage, device type (e.g., phone, tablet), IP address, unique
              identifiers (including identifiers used for advertising purposes),
              language settings, mobile device carrier, radio/network
              information (e.g., WiFi, LTE, 4G), and general location
              information such as city, state or geographic area.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Online activity data,</strong>such as the pages or screens
              you viewed, how long you spent on a page or screen, browsing
              history, navigation paths between pages or screens, information
              about your activity on a page or screen, access times, and
              duration of access, and whether you have opened our marketing
              emails or clicked links within them.
            </li>
          </ul>

          <p className='text-tertiary/60 font-bold'>
            We use the following tools for automatic data collection:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              <strong>Cookies,</strong>which are text files that websites store
              on a visitor’s device to uniquely identify the visitor’s browser
              or to store information or settings in the browser for the purpose
              of helping you navigate between pages efficiently, remembering
              your preferences, enabling functionality, helping us understand
              user activity and patterns, and facilitating online advertising.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Local storage technologies,</strong>like HTML5, that
              provide cookie- equivalent functionality but can store larger
              amounts of data, including on your device outside of your browser
              in connection with specific applications.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Web beacons,</strong>also known as pixel tags or clear
              GIFs, which are used to demonstrate that a webpage or email was
              accessed or opened, or that certain content was viewed or clicked.
            </li>
          </ul>

          <p className='text-tertiary/60 text-xl font-bold'>
            Legal bases for processing (for United Kingdom and EEA individuals)
          </p>
          <p className='text-tertiary/60'>
            If you are an individual in the United Kingdom or European Economic
            Area (EEA), we collect and process information about you only where
            we have legal bases for doing so under applicable United Kingdom
            and/or EU laws. The legal bases depend on the Services you use and
            how you use them. This means we collect and use your information
            only where:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              We need it to provide you the Services, including to operate the
              Services, provide customer support and personalized features and
              to protect the safety and security of the Services
            </li>
            <li className='text-tertiary/60 mb-2'>
              It satisfies a legitimate interest (which is not overridden by
              your data protection interests), such as for research and
              development, to market and promote the Services and to protect our
              legal rights and interests
            </li>
            <li className='text-tertiary/60 mb-2'>
              You give us consent to do so for a specific purpose or
            </li>
            <li className='text-tertiary/60 mb-2'>
              We need to process your data to comply with a legal obligation.
            </li>
          </ul>

          <p className='text-tertiary/60'>
            If you have consented to our use of information about you for a
            specific purpose, you have the right to change your mind at any
            time, but this will not affect any processing that has already taken
            place. Where we are using your information because we have a
            legitimate interest to do so, you have the right to object to that
            use though, in some cases, this may mean no longer using the
            Services.
          </p>
          <p className='text-tertiary/60 text-xl font-bold'>
            How we use your personal information
          </p>
          <p className='text-tertiary/60 font-bold'>To operate our Services:</p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              Provide, operate, maintain, secure and improve our Services.
            </li>
            <li className='text-tertiary/60 mb-2'>
              Fulfill payments and transactions.
            </li>
            <li className='text-tertiary/60 mb-2'>
              Provide information about our Services.
            </li>
            <li className='text-tertiary/60 mb-2'>
              Communicate with you about our Services, including by sending you
              announcements, updates, security alerts, and support and
              administrative messages.
            </li>
            <li className='text-tertiary/60 mb-2'>
              Understand your needs and interests, and personalize your
              experience with our Services and our communications.
            </li>
            <li className='text-tertiary/60 mb-2'>
              Respond to your requests, questions and feedback.
            </li>
          </ul>

          <p className='text-tertiary/60'>
            <strong>For marketing and advertising.</strong>We may collect and
            use your personal information for marketing and advertising
            purposes. We may from time-to-time send you direct marketing emails
            as permitted by law, including, but not limited to, notifying you of
            special promotions, offers and events.
          </p>
          <p className='text-tertiary/60'>
            <strong>To comply with law.</strong>As we believe necessary or
            appropriate to comply with applicable laws, lawful requests, and
            legal process, such as to respond to subpoenas or requests from
            government authorities.
          </p>
          <p className='text-tertiary/60'>
            <strong>For compliance, fraud prevention, and safety. </strong>To:
            (a) protect our, your or others’ rights, privacy, safety or property
            (including by making and defending legal claims) (b) enforce the
            terms and conditions that govern our Services and (c) protect,
            investigate and deter against fraudulent, harmful, unauthorized,
            unethical or illegal activity.
          </p>
          <p className='text-tertiary/60'>
            <strong>For research and development.</strong>We may create and use
            de-identified information for our business purposes, including to
            analyze the effectiveness of our services, to improve and add
            features to our services, and to analyze the general behavior and
            characteristics of users of our Services. We may use this anonymous,
            aggregated, or de-identified data and share it with third parties
            for our lawful business purposes.
          </p>
          <p className='text-tertiary/60 text-xl font-bold'>
            Cross-border processing of your personal information
          </p>
          <p className='text-tertiary/60'>
            Kaiber is headquartered in the United States. To provide and operate
            our Services, it is necessary for us to process your personal
            information in the United States. If we transfer personal
            information across borders such that we are required to apply
            appropriate safeguards to personal information under applicable data
            protection laws, we will do so. Please contact us for further
            information about any such transfers or the specific safeguards
            applied.
          </p>
          <p className='text-tertiary/60 text-xl font-bold'>
            How we share your personal information
          </p>
          <p className='text-tertiary/60'>
            <strong>Service providers.</strong>We may share your personal
            information among our affiliates or with third-party companies that
            provide services on our behalf or help us operate our Services (such
            as customer support, hosting, analytics, email delivery, marketing,
            billing).
          </p>
          <p className='text-tertiary/60'>
            <strong>Professional advisors.</strong>We may disclose your personal
            information to professional advisors, such as lawyers, bankers,
            auditors and insurers, where necessary in the course of the
            professional services that they render to us.
          </p>
          <p className='text-tertiary/60'>
            <strong>For compliance, fraud prevention and safety.</strong>We may
            share your personal information for the compliance, fraud prevention
            and safety purposes described above. 
          </p>
          <p className='text-tertiary/60'>
            <strong>Business transfers.</strong>We may sell, transfer or
            otherwise share some or all of our business or assets, including
            your personal information, in connection with a business transaction
            (or potential business transaction) such as a corporate divestiture,
            merger, consolidation, acquisition, reorganization or sale of
            assets, or in the event of bankruptcy or dissolution. In such a
            case, we will make reasonable efforts to require the recipient to
            honor this Privacy Policy.
          </p>
          <p className='text-tertiary/60 text-xl font-bold'>Your choices</p>
          <p className='text-tertiary/60'>
            In this section, we describe the rights and choices available to all
            users.
          </p>
          <p className='text-tertiary/60'>
            <strong>Personal information requests:</strong> In certain
            circumstances (including based on where you are located), you may
            have the following rights in relation to your personal information:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              the right to learn more about what personal information of yours
              is being processed, how and why such information is processed and
              the third parties who have access to such personal information. We
              have made this information available to you without having to
              request it by including it in this Privacy Policy
            </li>
            <li className='text-tertiary/60 mb-2'>
              the right to access your personal information
            </li>
            <li className='text-tertiary/60 mb-2'>
              the right to rectify/correct your personal information
            </li>
            <li className='text-tertiary/60 mb-2'>
              the right to restrict the use of your personal information where
              permitted under applicable law
            </li>
            <li className='text-tertiary/60 mb-2'>
              the right to request that your personal information is
              erased/deleted where permitted under applicable law
            </li>
            <li className='text-tertiary/60 mb-2'>
              the right to data portability (i.e. receive your personal
              information or have it transferred to another controller in a
              structured, commonly-used, machine readable format) where
              permitted under applicable law and
            </li>
            <li className='text-tertiary/60 mb-2'>
              the right to object to processing of your personal information or
              to direct us not to share your personal information with a
              non-affiliated third party where permitted under applicable law.
            </li>
          </ul>

          <p className='text-tertiary/60'>
            To make a request, please email us as provided in the{' '}
            <strong>“How to Contact Us”</strong> section below. We may ask for
            specific information from you to help us confirm your identity. We
            will require authorized agents to confirm their identity and
            authority, in accordance with applicable laws. You are entitled to
            exercise the rights described above free from discrimination.
          </p>
          <p className='text-tertiary/60'>
            In addition, where you have provided your consent to processing for
            the purposes indicated above, you may withdraw your consent at any
            time (or otherwise exercise your aforementioned rights in relation
            to your personal information) by contacting us below(see contact
            details below).
          </p>
          <p className='text-tertiary/60'>
            Please note that in some circumstances, we may not be able to fully
            comply with your request, for example if we are required to retain
            certain information about you to comply with applicable laws and
            regulations or if the information is necessary in order for us to
            provide the services you requested. In particular, we, and our
            collection and processing of your personal information, may be
            governed by laws and regulations on anti-money laundering, fraud
            prevention, taxation and financial services. We will not
            discriminate against you for exercising your rights. We will not
            deny you access to our services, or provide you a lower quality of
            services if you exercise your rights.
          </p>
          <p className='text-tertiary/60'>
            You also have the right to lodge a complaint with the relevant
            authority (as applicable) or a supervisory authority in the UK or EU
            member state of your usual residence or place of work or of the
            place of the alleged breach, if you consider that the processing of
            your personal information carried out by Kaiber or any of our
            affiliates or third-party service providers, has breached data
            protection laws. Individuals and data protection supervisory
            authorities in the EU and the UK may contact our data protection
            representatives according to Articles 27 of EU and UK GDPR
          </p>
          <p className='text-tertiary/60'>
            You may also appeal to certain courts against (A) any failure of the
            relevant authority to give written notice of whether the complaint
            is either being investigated or not being investigated and, where
            applicable, the progress and the outcome of the investigation or (B)
            a determination of the relevant authority not to investigate the
            complaint or a determination that a controller or processor has not
            breached or is not likely to breach an operative provision in
            connection with the complaint.
          </p>
          <p className='text-tertiary/60'>
            <strong>Opt out of marketing communications. </strong>You may opt
            out of marketing-related communications by following the opt-out or
            unsubscribe instructions within the marketing communication we send
            you.
          </p>
          <p className='text-tertiary/60'>
            <strong>Cookies.</strong>Most browser settings let you delete and
            reject cookies placed by websites. Many browsers accept cookies by
            default until you change your settings. If you do not accept
            cookies, you may not be able to use all functionality of the website
            and it may not work properly. For more information about cookies,
            including how to see what cookies have been set on your browser and
            how to manage and delete them, visit{' '}
            <a className='text-primary' href='https://allaboutcookies.org/'>
              www.allaboutcookies.org
            </a>
            .
          </p>
          <p className='text-tertiary/60'>
            <strong>Online tracking opt-out.</strong>There are a number of ways
            to opt out of having your online activity and device data collected
            through our website, which we have summarized below:
          </p>

          <ul className='list-disc ml-5'>
            <li className='text-tertiary/60 mb-2'>
              <strong>Blocking cookies in your browser.</strong>Most browsers
              let you remove or reject cookies, including cookies used for
              interest-based advertising. To do this, follow the instructions in
              your browser settings. Many browsers accept cookies by default
              until you change your settings. For more information about
              cookies, including how to see what cookies have been set on your
              device and how to manage and delete them,{' '}
              <a className='text-primary' href='https://allaboutcookies.org/'>
                www.allaboutcookies.org
              </a>
              .
            </li>
            <p className='text-tertiary/60'>
              Use the following links to learn more about how to control cookies
              and online tracking through your browser:
            </p>
            <ul className='list-disc ml-5'>
              <li className='text-tertiary/60 mb-2'>
                <a
                  className='text-primary'
                  href='https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop'
                >
                  Firefox
                </a>{' '}
                <a
                  className='text-primary'
                  href='https://support.google.com/chrome/answer/95647'
                >
                  Chrome
                </a>{' '}
                <a
                  className='text-primary'
                  href='https://support.microsoft.com/en-us/microsoft-edge/learn-about-tracking-prevention-in-microsoft-edge-5ac125e8- 
                   9b90-8d59-fa2c-7f2e9a44d869'
                >
                  Microsoft Edge
                </a>{' '}
                <a
                  className='text-primary'
                  href='https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac'
                >
                  Safari
                </a>
              </li>
            </ul>

            <li className='text-tertiary/60 mb-2'>
              <strong>
                Blocking advertising ID use in your mobile settings.
              </strong>
              Your mobile device settings may provide functionality to limit use
              of the advertising ID associated with your mobile device for
              interest-based advertising purposes.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Google Analytics.</strong>We use Google Analytics to help
              us better understand how people engage with our Services by
              collecting information and creating reports about how users use
              our Services. For more information on Google Analytics, click{' '}
              <a
                className='text-primary'
                href='https://marketingplatform.google.com/about/analytics/'
              >
                here
              </a>
              . For more information about Google’s privacy practices, click{' '}
              <a
                className='text-primary'
                href='https://policies.google.com/technologies/partner-sites'
              >
                here
              </a>
              . You can opt out of Google Analytics by downloading and
              installing the browser plug-in available at:{' '}
              <a
                className='text-primary'
                href='https://tools.google.com/dlpage/gaoptout'
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Using privacy plug-ins or browsers.</strong>You can block
              our Services from setting cookies used for interest-based ads by
              using a browser with privacy features, like{' '}
              <a className='text-primary' href='https://brave.com/'>
                Brave
              </a>
              , or installing browser plugins like{' '}
              <a className='text-primary' href='https://privacybadger.org/'>
                Privacy Badger
              </a>
              ,
              <a className='text-primary' href='https://duckduckgo.com/'>
                DuckDuckGo
              </a>
              ,
              <a className='text-primary' href='https://www.ghostery.com/'>
                Ghostery
              </a>{' '}
              or 
              <a className='text-primary' href='https://ublock.org/en'>
                uBlock Origin
              </a>
              , and configuring them to block third party cookies/trackers.
            </li>
            <li className='text-tertiary/60 mb-2'>
              <strong>Platform opt-outs.</strong>Some third-party ad networks,
              including third-party ad servers, ad agencies, ad technology
              vendors and research firms, allow you to opt-out directly by using
              their opt-out tools. Some of these providers, and links to their
              opt-out tools, are:
            </li>

            <ul className='list-disc ml-5'>
              <li className='text-tertiary/60 mb-2'>
                <a
                  className='text-primary'
                  href='https://adssettings.google.com/'
                >
                  Google
                </a>
              </li>
              <li className='text-tertiary/60 mb-2'>
                <a
                  className='text-primary'
                  href='https://www.facebook.com/about/ads'
                >
                  Facebook
                </a>
              </li>
            </ul>

            <li className='text-tertiary/60 mb-2'>
              <strong>Advertising industry opt-out tools. </strong>
              You can also use these opt-out options to limit use of your
              information for interest-based advertising by participating
              companies:
            </li>

            <ul className='list-disc ml-5'>
              <li className='text-tertiary/60 mb-2'>
                <a
                  className='text-primary'
                  href='https://optout.aboutads.info/?c=2&lang=EN'
                >
                  Digital Advertising Alliance
                </a>
              </li>
              <li className='text-tertiary/60 mb-2'>
                <a
                  className='text-primary'
                  href='https://optout.networkadvertising.org/?c=1'
                >
                  Network Advertising Initiative
                </a>
              </li>
            </ul>
            <p className='text-tertiary/60'>
              <strong>Do Not Track.</strong>Some Internet browsers may be
              configured to send “Do Not Track” signals to the online services
              that you visit. We currently do not respond to “Do Not Track” or
              similar signals. To find out more about “Do Not Track,” please
              visit{' '}
              <a className='text-primary' href='https://allaboutdnt.com/'>
                http://www.allaboutdnt.com
              </a>
              .
            </p>
          </ul>

          <p className='text-tertiary/60  text-xl font-bold'>
            Other sites, mobile applications and services
          </p>
          <p className='text-tertiary/60'>
            Our Services may contain links to other websites, mobile
            applications, and other online services operated by third
            parties. These links are not an endorsement of, or representation
            that we are affiliated with, any third party. In addition, our
            content may be included on web pages or in mobile applications or
            online services that are not associated with us. We do not control
            third-party websites, mobile applications or online services, and we
            are not responsible for their actions. Other websites and services
            follow different rules regarding the collection, use and sharing of
            your personal information. We encourage you to read the privacy
            policies of the other websites and mobile applications and online
            services you use.
          </p>
          <p className='text-tertiary/60  text-xl font-bold'>Retention</p>
          <p className='text-tertiary/60'>
            We retain your personal information for as long as appropriate to
            fulfill the purposes for which we collected it, including for the
            purposes of satisfying any legal, accounting, or reporting
            requirements, to establish or defend legal claims, or for fraud
            prevention purposes. To determine the appropriate retention period
            for your personal information, we consider the amount, nature, and
            sensitivity of the personal information, the potential risk of harm
            from unauthorized use or disclosure of personal information, the
            purposes for which we process personal information and whether we
            can achieve those purposes through other means, and the applicable
            legal requirements.
          </p>
          <p className='text-tertiary/60  text-xl font-bold'>
            Security practices
          </p>
          <p className='text-tertiary/60'>
            We use organizational, technical and administrative measures
            designed to protect against unauthorized access, misuse, loss,
            disclosure, alteration and destruction of personal information we
            maintain. Unfortunately, data transmission over the Internet cannot
            be guaranteed as completely secure. Therefore, while we strive to
            protect your personal information, we cannot guarantee the security
            of personal information.
          </p>
          <p className='text-tertiary/60  text-xl font-bold'>Children </p>
          <p className='text-tertiary/60'>
            Our Services are not intended for use by children under 18 years of
            age. If we learn that we have collected personal information through
            our Services from a child under 18 without the consent of the
            child’s parent or guardian as required by law, we will delete it.
          </p>
          <p className='text-tertiary/60  text-xl font-bold'>
            Changes to this Privacy Policy
          </p>
          <p className='text-tertiary/60'>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy.
          </p>
          <p className='text-tertiary/60  text-xl font-bold'>
            How to contact us
          </p>
          <p className='text-tertiary/60'>
            Please direct any questions or comments about this Privacy Policy or
            privacy practices to{' '}
            <a className='text-primary' href='mailto:support@kaiber.ai'>
              support@kaiber.ai
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
