import { Navigate } from 'react-router-dom'

import { BaseComponentPropsWithChildren } from '../types'

interface FeatureFlaggedProps extends BaseComponentPropsWithChildren {
  by: boolean
  fallbackRoute?: string
  bypassForDev?: boolean
}

/**
 * Feature flag route wrapper.
 *
 * Exmaple Usage:
 *   <Route
 *     path='/my-awesome-page'
 *     element={
 *       <FeatureFlagged by={featureToggles['my-awesome-flag']}>
 *         <MyAwesomePage />
 *       </FeatureFlagged>
 *     }
 *   />
 *
 * Optional Props:
 *   - fallbackRoute={'/'}  - if feature flag condition not met,
 *                            redirect user to this route,
 *                            default is '/'
 *   - bypassForDev={false} - if false, feature flag will be
 *                            enforced in development mode,
 *                            default is bypass for development
 */
export const FeatureFlagged = ({
  children,
  by,
  fallbackRoute = '/',
  bypassForDev = process.env.NODE_ENV === 'development',
}: FeatureFlaggedProps) => {
  if (bypassForDev) {
    return children
  } else {
    return by ? children : <Navigate to={fallbackRoute} replace={true} />
  }
}
