import { useState, useContext, createContext, useRef, useCallback } from 'react'

import { ElementFloatingWindow } from './ElementFloatingWindow'
import { DrawerHandle } from '../../../components/DrawerHandle'
import { useThemeContext } from '../../../context'
import { cn } from '../../../utils'
import { BaseComponentPropsWithChildren } from '@/types'

interface CanvasBaseElementProps extends BaseComponentPropsWithChildren {
  label?: string
  errorMessage?: string
  isFloatingWindowOpen?: boolean
  floatingWindowClassname?: string
  floatingWindowContent?: React.ReactElement
  floatingWindowPlacement?: 'left' | 'right'
}

interface CollapseContextProps {
  isCollapsed: boolean
  registerCollapsible: () => () => void
}

const CollapseContext = createContext<CollapseContextProps>({
  isCollapsed: false,
  registerCollapsible: () => () => {},
})

export const useCollapseContext = () => useContext(CollapseContext)

export const CanvasBaseElement = ({
  className,
  label,
  errorMessage,
  children,
  isFloatingWindowOpen = false,
  floatingWindowClassname,
  floatingWindowContent,
  floatingWindowPlacement,
}: CanvasBaseElementProps) => {
  const { colors } = useThemeContext()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const collapsibleCount = useRef(0)

  const registerCollapsible = useCallback(() => {
    collapsibleCount.current += 1

    return () => {
      collapsibleCount.current -= 1
    }
  }, [])

  return (
    <div className='nodrag'>
      <CollapseContext.Provider value={{ isCollapsed, registerCollapsible }}>
        <div
          className={cn(
            `p-4 rounded-2xl shadow-k2-10`,
            colors.background.element,
          )}
        >
          {label && (
            <div
              className={cn(
                'mb-4 text-base text-left font-semibold',
                colors.text.default,
              )}
            >
              {label}
            </div>
          )}
          <div
            className={cn(
              'flex flex-col items-center relative text-left text-gray-500',
              className,
              {
                'border-red-500': errorMessage,
              },
            )}
          >
            {children}
            {errorMessage && (
              <div className='text-kaiber-red pt-4 text-left text-xs'>
                {errorMessage}
              </div>
            )}

            {isFloatingWindowOpen && (
              <ElementFloatingWindow
                className={floatingWindowClassname}
                placement={floatingWindowPlacement}
              >
                {floatingWindowContent}
              </ElementFloatingWindow>
            )}

            {collapsibleCount.current > 0 && (
              <DrawerHandle onClick={() => setIsCollapsed(!isCollapsed)} />
            )}
          </div>
        </div>
      </CollapseContext.Provider>
    </div>
  )
}
