import { BaseComponentProps } from '../../types'

interface PlusIconProps extends BaseComponentProps {
  size?: string
}

export const PlusIcon = ({ size = '1em', className }: PlusIconProps) => (
  <svg
    stroke='currentColor'
    fill='none'
    height={size}
    width={size}
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 4.5V8M8 8V11.5M8 8H4.5M8 8H11.5'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </svg>
)
