import { useState } from 'react'
import { useForm } from 'react-hook-form'

export function CommunityForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    isSubmitted: false,
    isError: false,
  })

  const onSubmit = (data) => {
    const formData = new FormData()

    // Hidden field key/values.
    formData.append('u', '3')
    formData.append('f', '3')
    formData.append('s', 's')
    formData.append('c', '0')
    formData.append('m', '0')
    formData.append('act', 'sub')
    formData.append('v', '2')
    formData.append('or', '07e48b76bb284a4f41c45048fb6f60a3')

    // Form field key/values.
    formData.append('email', data.email)

    // Pass FormData values into a POST request to ActiveCampaign.
    // Mark form submission successful, otherwise return error state.
    fetch('https://secretgarden.activehosted.com/proc.php', {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then((response) => {
        setState({
          isSubmitted: true,
        })
      })
      .catch((err) => {
        setState({
          isError: true,
        })
      })
  }

  return (
    <div>
      {!state.isSubmitted ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-wrap flex-col content-center'
        >
          <input
            id='email'
            name='email'
            placeholder='Your email'
            type='email'
            className={`mt-5 p-5 border-b border-white bg-transparent text-white text-center outline-none max-w-[550px] w-[100%] text-[30px] ${
              errors.email ? 'border-red-700' : 'border-transparent'
            }`}
            {...register('email', {
              required: true,
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            })}
          />
          <div>
            <input
              className='mt-10 mb-7 px-4 py-4 text-xl font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40 max-w-[190px] w-[100%] cursor-pointer'
              type='submit'
              value='Sign up'
            />
          </div>
          {state.isError ? (
            <p className='my-[20px] text-white text-[20px] md:text-[35px] font-normal'>
              Unfortunately, your submission could not be sent. Please try again
              later.
            </p>
          ) : null}
        </form>
      ) : (
        <p className='my-[20px] text-white text-[20px] md:text-[35px] font-normal'>
          Thanks for your email address. Check your email for the discount code.
        </p>
      )}
    </div>
  )
}
