import React from 'react'
import { Chart } from 'react-charts'
import moment from 'moment'

export default function Line({ data, numberType = 'normal', className }) {
  const formatHighNumber = (n) => {
    if (n >= 1000) {
      return `${Math.round(n / 1000)}K`
    }

    return `${n}`
  }

  const formatPercent = (n) => {
    return `${n?.toFixed(2)}%`
  }

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.date,
      formatters: {
        scale: (value, formatters) => {
          return moment(value).utc().format('MMM DD')
        },
        tooltip: (value, formatters) => {
          return moment(value).utc().format('MMM DD[,] YYYY')
        },
      },
    }),
    [],
  )

  const secondaryAxes = React.useMemo(
    () => [
      {
        scaleType: 'linear',
        min: 0,
        tickCount: 5,
        getValue: (datum) => datum.value,
        formatters: {
          scale: (value, formatters) => {
            switch (numberType) {
              case 'normal':
                return formatHighNumber(value)
              case 'percent':
                return `${value}%`
              default:
                return value
            }
          },
          tooltip: (value, formatters) => {
            switch (numberType) {
              case 'normal':
                return Intl.NumberFormat('en-US').format(value)
              case 'percent':
                return formatPercent(value)
              default:
                return value
            }
          },
        },
      },
    ],
    [numberType],
  )

  return (
    <div className={`block w-auto ${className}`}>
      <div className='w-full h-[400px]'>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            defaultColors: ['#9AE39A', '#435b43'],
            dark: true,
          }}
        />
      </div>
    </div>
  )
}
