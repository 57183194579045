import * as Slider from '@radix-ui/react-slider'
import { useState } from 'react'
import { cn } from '../utils'

interface SliderInputProps {
  min: number
  max: number
  step: number
  value: number
  size?: 'sm' | 'md' | 'lg'
  onChange: (value: any) => void
}

export const SliderInput = ({
  min,
  max,
  step,
  value,
  size = 'md',
  onChange,
}: SliderInputProps) => {
  const [labelValue, setLabelValue] = useState(value)

  const handleValueChange = (values: number[]) => {
    setLabelValue(values[0])
    onChange(values[0])
  }

  return (
    <Slider.Root
      className={cn(
        'relative flex items-center select-none touch-none w-full border border-[#8c8c8c] rounded-lg bg-[#333] overflow-hidden',
        { 'h-6': size === 'sm', 'h-8': size === 'md', 'h-10': size === 'lg' },
      )}
      value={[value]}
      onValueChange={handleValueChange}
      min={min}
      max={max}
      step={step}
    >
      <Slider.Track
        className={cn('rounded-none bg-black', {
          'h-6': size === 'sm',
          'h-8': size === 'md',
          'h-10': size === 'lg',
        })}
      >
        <Slider.Range
          className={cn('absolute rounded-none bg-slider', {
            'h-6': size === 'sm',
            'h-8': size === 'md',
            'h-10': size === 'lg',
          })}
        />
        <div
          className={cn(
            'absolute flex items-center justify-center text-xs text-[#E3E3E3] w-full h-full transition-opacity',
            { 'opacity-0': Math.abs((min + max) / 2 - labelValue) < 3 },
          )}
        >
          {labelValue}
        </div>
      </Slider.Track>
      <Slider.Thumb
        className={cn('block  bg-transparent border border-white rounded-lg', {
          'w-1 h-5': size === 'sm',
          'w-1.5 h-6': size === 'md',
          'w-2 h-8': size === 'lg',
        })}
        aria-label='Volume'
      />
    </Slider.Root>
  )
}
