import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import LoginDetails from '../components/LoginDetails'
import Credits from '../components/Credits'
import ProfileSettings from '../components/ProfileSettings'
import { cn } from '../utils'
import { useUserAccountContext } from '../context/userContext'

export const Account = () => {
  const location = useLocation()
  const { featureToggles } = useUserAccountContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tab = params.get('tab')

    const intervalId = setInterval(() => {
      if (tab) {
        const element = document.getElementById(tab)
        if (element) {
          window.scrollTo({ top: element.offsetTop - 170, behavior: 'smooth' })
          clearInterval(intervalId)
        }
      } else {
        clearInterval(intervalId)
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [location])

  return (
    <div className='min-h-full bg-black'>
      <Navbar />
      <div
        className={cn('justify-center py-10  max-w-7xl m-auto pt-28', {
          'pt-40': isFeedbackBannerShown,
        })}
      >
        <div className='px-5 m-auto mx-auto space-y-8'>
          <div className='pb-5 border-b border-lightGray sm:pb-0'>
            <h3 className='text-3xl font-medium text-tertiary'>Account</h3>
            <p className='mt-4 text-tertiary/60'>
              Manage your account settings here.
            </p>
          </div>
        </div>
        <div className='px-5 pt-10 mt-10 space-y-6 md:mt-0'>
          <ProfileSettings />
          <Credits />
          <LoginDetails />
        </div>
      </div>
      <Footer />
    </div>
  )
}
