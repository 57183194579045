import { v4 as uuidV4 } from 'uuid'
import { SHA1 } from 'crypto-js'

export const getCustomerDataFromUser = (user: {
  distinctId: string
  email: string
}) => {
  const customerId = user ? user.distinctId : ''
  const customerEmail = user ? SHA1(user.email).toString() : ''
  const customProfileId = (() => {
    if (localStorage && localStorage.getItem('customProfileId')) {
      return localStorage.getItem('customProfileId')
    }
    const uuid = uuidV4()
    localStorage.setItem('customProfileId', uuid)
    return uuid
  })() as string

  return { customerId, customerEmail, customProfileId }
}

export const getNewCustomerData = ({
  customerId,
  email,
}: {
  customerId: string
  email: string
}) => {
  const customerEmail = SHA1(email).toString()
  // we don't track visitors, at this time, only customers
  // customProfileId = visitors uuid
  // customerId = customers uuid (after registering)
  return { customerId, customerEmail, customProfileId: customerId }
}

export const getIreFunction = () => {
  const ire = (window as any).ire
  if (!ire) {
    throw new Error(
      `Impact's ire function is not available on the window object. Make sure you have the Impact script loaded on the page.`,
    )
  }
  return ire
}

/**
 * Identify the user in Impact
 * @param {Object | null} user Currently logged in user or null
 */
export const impactIdentify = ({
  user,
}: {
  user: {
    distinctId: string
    email: string
  }
}) => {
  const ire = getIreFunction()

  const { customerId, customerEmail, customProfileId } =
    getCustomerDataFromUser(user)

  ire('identify', {
    customerId,
    customerEmail,
    customProfileId: customProfileId,
  })
}

export const accountCreation = ({
  user,
  orderId,
}: {
  user: {
    uuid: string
    email: string
  }
  orderId: string
}) => {
  const ireFunction = getIreFunction()

  if (!ireFunction) return

  const { customerId, customerEmail, customProfileId } = getNewCustomerData({
    customerId: user.uuid,
    email: user.email,
  })

  if (!orderId) {
    orderId = customerId
  }

  ireFunction(
    'trackConversion',
    44259,
    {
      orderId,
      customProfileId,
      customerId,
      customerEmail,
    },
    {
      verifySiteDefinitionMatch: true,
    },
  )
}

export const subscriptionPurchased = ({
  user,
  orderId,
  orderPromoCode,
  orderDiscount,
  items,
}: {
  user: {
    distinctId: string
    email: string
  }
  orderId: string
  orderPromoCode: string
  orderDiscount: number
  items: {
    subTotal: number
    category: string
    sku: string
    quantity: number
    name: string
  }[]
}) => {
  const ireFunction = getIreFunction()
  const { customerId, customerEmail, customProfileId } =
    getCustomerDataFromUser(user)

  const customerStatus = user ? 'Existing' : 'New'

  ireFunction(
    'trackConversion',
    44260,
    {
      orderId,
      customerId,
      customProfileId,
      customerEmail,
      customerStatus,
      currencyCode: 'USD', // Todo
      orderPromoCode,
      orderDiscount,
      items,
    },
    {
      verifySiteDefinitionMatch: true,
    },
  )
}
