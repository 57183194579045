import {
  AddMediaRequestBody,
  Collection,
  CollectionAction,
  RemoveMediaRequestBody,
  RenameRequestBody,
} from '@kaiber/shared-types'
import { API_ENDPOINTS } from '../constants'
import axios from 'axios'

// This service is responsible for all collection related operations.
// Utilize this functionality through the useMutateCollection hook instead of directly calling this service.
export class CollectionService {
  static async createCollection(): Promise<Collection> {
    const response = await axios.post(API_ENDPOINTS.CREATE_COLLECTION)
    return response.data.collection
  }

  static async listCollections(): Promise<Collection[]> {
    const response = await axios.get(API_ENDPOINTS.LIST_COLLECTIONS)
    return response.data.collections
  }

  static async getCollection(collectionId: string): Promise<Collection> {
    const response = await axios.get(
      API_ENDPOINTS.GET_COLLECTION.replace(':collectionId', collectionId),
    )
    return response.data.collection
  }

  static async updateCollection(
    collectionId: string,
    action: CollectionAction,
    payload: RenameRequestBody | AddMediaRequestBody | RemoveMediaRequestBody,
  ): Promise<Collection> {
    const response = await axios.patch(
      API_ENDPOINTS.UPDATE_COLLECTION + `/${collectionId}`,
      { action, ...payload },
    )
    return response.data.collection
  }

  static async deleteCollection(collectionId: string): Promise<string> {
    const response = await axios.delete(
      API_ENDPOINTS.DELETE_COLLECTION.replace(':collectionId', collectionId),
    )
    return response.data.collectionId
  }
}
