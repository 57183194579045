import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { HiOutlineX } from 'react-icons/hi'

import { cn } from '../utils'

export const Modal = ({
  containerClassName,
  children,
  open,
  setOpen,
  handleClose,
  persistent = false,
  title = null,
  closeIconClassName = '',
}) => {
  const containerClass = cn(
    'relative px-5 pt-5 pb-12 overflow-hidden text-left transition-all transform shadow-xl rounded-t-3xl sm:rounded-3xl bg-dark sm:my-8 w-full sm:max-w-lg sm:p-6',
    containerClassName,
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-40' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
        </Transition.Child>

        <div className='fixed inset-0 z-40 overflow-y-auto '>
          <div className='flex items-end justify-center min-h-full p-0 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={containerClass}>
                {/* TITLE */}
                {title && (
                  <div className='absolute top-5'>
                    <h2 className='text-kaiberGreen'>{title}</h2>
                  </div>
                )}

                {/* CLOSE BUTTON */}
                <div className='absolute top-5 right-5'>
                  <button
                    type='button'
                    className='rounded-full outline-none hover:text-gray-500'
                    onClick={() => {
                      setOpen(false)
                      if (handleClose) {
                        handleClose()
                      }
                    }}
                  >
                    {!persistent && (
                      <>
                        <span className='sr-only'>Close</span>
                        <HiOutlineX
                          className={`flex w-6 h-6 border-[1px] rounded-full p-0.5 hover:text-black cursor-pointer transition ease-in-out duration-300 ${closeIconClassName}`}
                        />
                      </>
                    )}
                  </button>
                </div>

                {/* MODAL CONTENT */}
                <div className='flex items-center justify-center'>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
