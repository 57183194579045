import { Input } from '@material-tailwind/react'
import { useFormContext } from 'react-hook-form'

import {
  DEFAULT_FORM_VALUES,
  FIELD_LABELS,
  LABELS,
  LENGTH_CONFIG,
} from '../../../../../constants'
import { FieldType } from '../../../../../types'
import { CanvasBaseElement } from '../../CanvasBaseElement'
import { useDebouncedCallback } from 'use-debounce'
import { ChangeEvent } from 'react'

export const MotionV2Settings = () => {
  const { register, setValue } = useFormContext()

  const validate = useDebouncedCallback((length) => {
    setValue(
      FieldType.Length,
      Math.min(
        LENGTH_CONFIG.MOTION_V2.max,
        Math.max(LENGTH_CONFIG.MOTION_V2.min, length),
      ),
    )
  }, 300)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const length = parseInt(e.target.value)
    validate(length)
  }

  return (
    <CanvasBaseElement label={LABELS.VIDEO_DETAILS}>
      <div className='flex gap-2 flex-col'>
        <Input
          type='number'
          className='text-white'
          label={FIELD_LABELS[FieldType.Length]}
          min={LENGTH_CONFIG.MOTION_V2.min}
          max={LENGTH_CONFIG.MOTION_V2.max}
          {...register(FieldType.Length, {
            min: LENGTH_CONFIG.MOTION_V2.min,
            max: LENGTH_CONFIG.MOTION_V2.max,
            onChange: onChange,
          })}
          defaultValue={DEFAULT_FORM_VALUES[FieldType.Length]}
        />
        <Input
          className='text-white'
          label={FIELD_LABELS[FieldType.Motion]}
          {...register(FieldType.Motion)}
          defaultValue={DEFAULT_FORM_VALUES[FieldType.Motion]}
        />
      </div>
    </CanvasBaseElement>
  )
}
