import clsx from 'clsx'

import { DEFAULT_THEME } from '../../constants'
import { useThemeContext } from '../../context'
import { AspectRatio } from '../../types'

interface SkeletonProps {
  aspectRatio?: AspectRatio
  className?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({
  aspectRatio,
  className,
}) => {
  const { activeTheme } = useThemeContext()

  const skeletonGradient =
    activeTheme === DEFAULT_THEME.Dark
      ? 'bg-skeleton-gradient'
      : 'bg-skeleton-gradient-light'

  return (
    <div
      // required to use clsx over cn here because cn was overwriting first bg property when using 2 bg properties
      className={clsx(
        `skeleton rounded-2xl w-full animate-skeleton ${skeletonGradient} bg-skeleton-size z-20`,
        !aspectRatio && 'h-full',
        className,
      )}
      // TODO: ENG-1907 fix on server conversion logic, style needs aspectRatio with / delimiter
      {...(aspectRatio && {
        style: { aspectRatio: aspectRatio.replace(':', '/') },
      })}
    ></div>
  )
}

Skeleton.displayName = 'Skeleton'
