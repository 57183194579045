import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

export default function CreatorOptionsSelect({
  handleSelect,
  disabled,
  title,
  options,
  defaultOption,
  subscriptionTextCTA,
  showSubscriptionTextCTA,
  mutuallyExclusiveOptions = {},
  className = '',
}) {
  const [selectedNames, setSelectedNames] = useState(defaultOption)

  const onSelect = (name, value) => {
    const updatedSelectedNames = [...selectedNames]
    if (selectedNames.includes(name)) {
      updatedSelectedNames.splice(updatedSelectedNames.indexOf(name), 1)
    } else {
      updatedSelectedNames.push(name)
    }

    if (mutuallyExclusiveOptions[name]) {
      // toggle off everything else in the array
      mutuallyExclusiveOptions[name].forEach((option) => {
        if (updatedSelectedNames.includes(option)) {
          updatedSelectedNames.splice(updatedSelectedNames.indexOf(option), 1)
        }
      })
    }

    setSelectedNames(updatedSelectedNames)
    handleSelect(updatedSelectedNames)
  }

  useEffect(() => {
    setSelectedNames(defaultOption)
  }, [defaultOption])

  if (disabled) {
    return null
  }

  return (
    <>
      {!disabled && !showSubscriptionTextCTA && (
        <div className={`my-5 text-base text-primary ${className}`}>
          <div className='flex gap-3 mb-5'>
            <h2 className='text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
              {title}
            </h2>

            {/* TOOLTIP */}
            <div className='flex has-tooltip'>
              <span className='px-2 py-1 -mt-8 text-gray-100 bg-gray-800 rounded shadow-lg tooltip'>
                You can select multiple camera movements
              </span>
              <AiOutlineQuestionCircle className='self-center w-6 h-6 select-none text-kaiberGreen' />
            </div>
          </div>

          <div className='flex flex-wrap max-w-lg gap-2 mb-5'>
            {options.map((option) => (
              <button
                key={option.name}
                data-label={option.name}
                className={classNames(
                  'border relative buttonPrompt px-4 py-2 sm:text-base text-sm rounded-full border-primary hover:bg-primary hover:text-black transition-all capitalize',
                  {
                    'bg-primary text-black font-bold': selectedNames.includes(
                      option.name,
                    ),
                  },
                )}
                onClick={() => onSelect(option.name, option.value)}
              >
                <span className='absolute top-2 bottom-2 left-4 right-4'>
                  {option.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}

      {showSubscriptionTextCTA && (
        <div className='bg-[#131315] rounded-2xl p-5 max-w-md self-start mt-[20px] mb-[30px]'>
          <h2 className='mb-5 text-xl whitespace-pre-line lg:text-2xl xl:text-3xl opacity-30'>
            {title}
          </h2>
          <a href='/pricing'>
            <div className='rounded-full font-bold bg-gray-500/80 text-primary w-fit text-sm px-4 py-2.5'>
              <StarIcon className='inline w-6 h-6 mr-2' />
              {subscriptionTextCTA}
            </div>
          </a>
        </div>
      )}
    </>
  )
}
