import cx from 'classnames'
import { twMerge } from 'tailwind-merge'

/**
 * Input component that uses the Kaiber design pattern.
 *
 * @param {Object} props - The props object
 * @param {string} props.name - Name of the input, used to identify the component in the DOM and for form validation
 * @param {string} [props.label] - Label for the input
 * @param {string} props.placeHolder - Placeholder for the input
 * @param {string} props.value - Value of the input
 * @param {string} [props.type='text'] - Type of the input
 * @param {(e: Event) => void} props.handleChange - Function to handle the change of the input
 * @param {string[]} props.requirements - Array of strings to display as requirements for the input
 * @param {string} props.error - Error message to display if the input is invalid
 * @param {boolean} [props.required=false] - Whether the input is required or not
 * @param {boolean} [props.disabled] - Whether the input is disabled or not
 * @param {string} [props.className] - Classname to apply to the div containing label and input
 * @returns {JSX.Element} Element to render
 */
function FormInput({
  name,
  label,
  placeHolder,
  value,
  handleChange,
  required = false,
  requirements,
  error,
  disabled = false,
  type = 'text',
  className,
}) {
  return (
    <div className={twMerge('flex-1', className)} key={name}>
      {label && (
        <label
          htmlFor={name}
          className={cx('block text-tertiary px-5 font-light', {
            'text-red-500': error,
            'text-white': !error,
          })}
        >
          <strong
            className={cx('', {
              'text-red-500': error,
              'text-white': !error,
            })}
          >
            {label} {required && <span className='text-red-500'>*</span>}
          </strong>
        </label>
      )}
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeHolder}
        onChange={handleChange}
        className={cx(
          ` w-full mt-2 block border-transparent border rounded-full shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3 px-5 bg-[#303030]`,
          {
            'border-red-600 border-[1px] placeholder:text-red-600 text-red-600':
              error,
            'text-white': !error,
          },
        )}
        defaultValue={value}
        required
        disabled={disabled}
      />
      {requirements?.length &&
        requirements.map((req, i) => (
          <div className='ml-3' key={`${name}req[${i}]`}>
            <small className='text-gray-500 text-sm'>* {req}</small>
          </div>
        ))}
    </div>
  )
}

export { FormInput }
