import React from 'react'

import { SVGProps } from '@/types'

export const MinimizeIcon: React.FC<SVGProps> = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='19'
    viewBox='0 0 20 19'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M1.75 7.25V2.75C1.75 1.64543 2.64543 0.75 3.75 0.75H17.25C18.3546 0.75 19.25 1.64543 19.25 2.75V10.25C19.25 11.3546 18.3546 12.25 17.25 12.25H12.75M11.75 8.25V5.25M11.75 8.25H14.75M11.75 8.25L15 5M7.25 18.25H2.75C1.64543 18.25 0.75 17.3546 0.75 16.25V12.75C0.75 11.6454 1.64543 10.75 2.75 10.75H7.25C8.3546 10.75 9.25 11.6454 9.25 12.75V16.25C9.25 17.3546 8.3546 18.25 7.25 18.25Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
