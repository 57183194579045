import { Input } from '@material-tailwind/react'
import { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import {
  DEFAULT_FORM_VALUES,
  FIELD_LABELS,
  LABELS,
  LENGTH_CONFIG,
} from '../../../../../constants'
import { CanvasBaseElement } from '../../CanvasBaseElement'
import { FieldType } from '@/types'

export const FlipbookV1Settings = () => {
  const { register, setValue } = useFormContext()

  const validate = useDebouncedCallback((length) => {
    setValue(
      FieldType.Length,
      Math.min(
        LENGTH_CONFIG.FLIPBOOK_V1.max,
        Math.max(LENGTH_CONFIG.FLIPBOOK_V1.min, length),
      ),
    )
  }, 300)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const length = parseInt(e.target.value)
    validate(length)
  }

  return (
    // TODO Determine if we want all model type settings to use "Video Details" as the label
    <CanvasBaseElement label={LABELS.FLIPBOOK_V1_SETTINGS}>
      <div className='flex gap-2 flex-col'>
        <Input
          type='number'
          className='text-white'
          label={FIELD_LABELS[FieldType.Length]}
          min={LENGTH_CONFIG.FLIPBOOK_V1.min}
          max={LENGTH_CONFIG.FLIPBOOK_V1.max}
          {...register(FieldType.Length, {
            min: LENGTH_CONFIG.FLIPBOOK_V1.min,
            max: LENGTH_CONFIG.FLIPBOOK_V1.max,
            onChange,
          })}
          defaultValue={DEFAULT_FORM_VALUES[FieldType.Length]}
        />
        <Input
          className='text-white'
          label={FIELD_LABELS[FieldType.AspectRatio]}
          {...register(FieldType.AspectRatio)}
          defaultValue={DEFAULT_FORM_VALUES[FieldType.AspectRatio]}
        />
        <Input
          className='text-white'
          label={FIELD_LABELS[FieldType.Evolve]}
          {...register(FieldType.Evolve)}
          defaultValue={DEFAULT_FORM_VALUES[FieldType.Evolve]}
        />
      </div>
    </CanvasBaseElement>
  )
}
