import { useFormContext } from 'react-hook-form'
import { useRef, useState } from 'react'
import { AUDIO_UPLOAD } from '../../../../constants'
import { CanvasBaseElement } from '../CanvasBaseElement'
import { FileUploadIcon } from '../../../../images/icons/FileUploadIcon'
import { LoadingIcon } from '../../../../images/icons/LoadingIcon'

/**
 * AudioUploadElement is used for uploading an audio file
 */
export const AudioUpload = () => {
  const { register, setValue, watch } = useFormContext()
  const [, setAudioUrl] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const inputRef = useRef(null)

  const handleFileUpload = (fileOrUrl: File | string) => {
    setIsUploading(true)

    if (typeof fileOrUrl === 'string') {
      // Fetch the audio from the URL and create a Blob
      fetch(fileOrUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a File object from the Blob
          const file = new File([blob], 'uploaded_audio.mp3', {
            type: 'audio/mpeg',
          })
          setValue(AUDIO_UPLOAD, file)
          setAudioUrl(URL.createObjectURL(file))
        })
        .catch((error) => {
          console.error('Error fetching audio from URL:', error)
        })
    } else {
      setValue(AUDIO_UPLOAD, fileOrUrl)
      setAudioUrl(URL.createObjectURL(fileOrUrl))
    }

    setIsUploading(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file && file.type.startsWith('audio/')) {
      handleFileUpload(file)
    } else {
      console.warn('Invalid file type. Only audio files are allowed.')
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.items[0]

    if (file && file.type.startsWith('audio/')) {
      event.dataTransfer.dropEffect = 'copy'
    } else {
      event.dataTransfer.dropEffect = 'none'
    }
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      handleFileUpload(file)
    }
  }

  const uploadedFile = watch(AUDIO_UPLOAD)

  const isFileUploaded = (file: unknown): file is File => {
    return file instanceof Blob
  }

  const isFileListEmpty = (fileList: unknown): fileList is FileList => {
    return fileList instanceof FileList && fileList.length === 0
  }

  return (
    <CanvasBaseElement className='p-0' label='Audio Upload'>
      <div
        className={`p-2 border border-gray-500 rounded bg-neutral-75 h-full flex flex-col items-center justify-center gap-2`}
        data-upload-droppable={true}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragEnd}
        onClick={() => {
          if (
            inputRef?.current &&
            inputRef.current !== document.activeElement
          ) {
            inputRef.current.click()
          }
        }}
      >
        <div className='flex flex-col items-center justify-center space-y-2'>
          {isFileUploaded(uploadedFile) ? (
            <div className='flex flex-col items-center'>
              <svg
                className='w-8 h-8 text-green-500 mb-2'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                  clipRule='evenodd'
                />
              </svg>
              <p className='text-green-500 font-semibold'>
                Audio file uploaded
              </p>
              <p className='text-gray-500 text-sm mt-1'>{uploadedFile.name}</p>
            </div>
          ) : (
            <div className='flex flex-col items-center'>
              {isUploading ? (
                <>
                  <LoadingIcon width='24px' height='24px' />
                  <div className='text-white text-[12px]'>
                    Uploading media...
                  </div>
                </>
              ) : (
                <>
                  <FileUploadIcon width='24px' height='24px' />
                  <div className='text-white text-[12px]'>Drop audio here</div>
                </>
              )}
            </div>
          )}
          <label
            htmlFor='audioUpload'
            className='py-1 px-4 rounded-full cursor-pointer border border-[#EABD1E] text-[#EABD1E] text-[10px] border-full'
            onClick={(e) => e.stopPropagation()}
          >
            {isFileUploaded(uploadedFile) || isFileListEmpty(uploadedFile)
              ? 'Change File'
              : 'Choose File'}
          </label>
          <input
            ref={inputRef}
            id='audioUpload'
            type='file'
            accept='audio/*'
            className='hidden'
            {...register(AUDIO_UPLOAD)}
            onChange={handleInputChange}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    </CanvasBaseElement>
  )
}
