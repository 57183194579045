import { SVGProps } from '@/types'

export const DownloadIcon = ({ size = 20 }: SVGProps) => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 20 20'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3 14v3a1 1 0 001 1h12a1 1 0 001-1v-3M10 3v11m-3-3l3 3 3-3'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </svg>
)
