import { DEFAULT_THEME } from '../../constants'
import { useThemeContext } from '../../context'
import { SVGProps } from '../../types'

export const HorizontalGradientBar = ({
  width = 72,
  height = 4,
  className = '',
}: SVGProps) => {
  const { activeTheme } = useThemeContext()

  const startColor = activeTheme === DEFAULT_THEME.Light ? '#6C47FF' : '#B54CC7'
  const endColor = activeTheme === DEFAULT_THEME.Light ? '#33B6D3' : '#D9E123'

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect
        x='0.5'
        y='0.5'
        width='71'
        height='3'
        rx='1.5'
        stroke='url(#paint0_linear_5991_24317)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5991_24317'
          x1='0'
          y1='2'
          x2='72'
          y2='1.99999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={startColor} />
          <stop offset='1' stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
