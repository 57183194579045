import React from 'react'

import {
  BRIGHTNESS,
  CONTRAST,
  FIELD_LABELS,
  GRAYSCALE,
  SATURATION,
} from '../../../constants/k2'
import { ImageEditSettingsInput } from '../Elements/Settings/ImageEdit/ImageEditSettingsInput'

interface ColorSettingsProps {
  onImageEditChange: () => void
}

export const ColorSettings = React.forwardRef<
  HTMLDivElement,
  ColorSettingsProps
>(({ onImageEditChange }, ref) => {
  return (
    <div ref={ref} className='flex flex-col gap-4 nodrag'>
      <ImageEditSettingsInput
        name={BRIGHTNESS}
        label={FIELD_LABELS[BRIGHTNESS]}
        min={0}
        max={200}
        step={1}
        defaultValue={100}
        handleChange={onImageEditChange}
      />
      <ImageEditSettingsInput
        name={CONTRAST}
        label={FIELD_LABELS[CONTRAST]}
        min={0}
        max={200}
        step={1}
        defaultValue={100}
        handleChange={onImageEditChange}
      />
      <ImageEditSettingsInput
        name={GRAYSCALE}
        label={FIELD_LABELS[GRAYSCALE]}
        min={0}
        max={100}
        step={1}
        defaultValue={0}
        handleChange={onImageEditChange}
      />
      <ImageEditSettingsInput
        name={SATURATION}
        label={FIELD_LABELS[SATURATION]}
        min={0}
        max={200}
        step={1}
        defaultValue={100}
        handleChange={onImageEditChange}
      />
    </div>
  )
})
