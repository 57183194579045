import { Fragment } from 'react'

export const GallerySkeleton = ({ columns }) => {
  const renderSkeleton = () => (
    <div className='h-[500px] object-cover mb-4 rounded-xl bg-darkGray/25'></div>
  )

  return (
    <div className={`grid grid-cols-${columns} gap-6`}>
      {[...Array(columns)].map((_, index) => (
        <Fragment key={index}>{renderSkeleton()}</Fragment>
      ))}
    </div>
  )
}
