import { useEffect, useState } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Modal } from './Modal'
import { useUserAccountContext } from '../context/userContext'
import { AUDIO_REACTIVE_OPTIONS } from '../utils/constants'

import { Base64 } from 'js-base64'

const DELAY = 1500 // add synthetic delay to improve UX otherwise too quick

function VideoInfoModal({ settingsUsed, isOpen, onClose }) {
  const [numberOfScenes, setNumberOfScenes] = useState(null)

  useEffect(() => {
    if (settingsUsed?.scenes) {
      setNumberOfScenes(Object.values(settingsUsed?.scenes).length)
    }
  }, [settingsUsed])

  const navigate = useNavigate()
  const { currentUser } = useUserAccountContext()

  const isSubscribed = currentUser?.subscriptionType === 'Standard'

  const handleUseTheseSettings = () => {
    const settingsInBase64 = Base64.encode(JSON.stringify(settingsUsed), true)
    toast('✨ Preparing your masterpiece', { autoClose: DELAY })
    setTimeout(() => navigate(`/create?settings=${settingsInBase64}`), DELAY)
  }

  const getVideoTypeText = (string) => {
    const videoTypes = {
      animation: 'Flipbook',
      flipbook: 'Flipbook',
      motion: 'Motion',
      transform: 'Transform',
      restylization: 'Transform',
    }
    if (string) {
      return videoTypes[string]
    }

    return '-'
  }

  const getAudioReactivityName = (value, hasAudio) => {
    if (hasAudio) {
      const audioReactivity = AUDIO_REACTIVE_OPTIONS.find(
        (option) => option.value === value,
      )

      return audioReactivity?.name || '-'
    }
    return '-'
  }

  const getEvolveText = (value) => {
    // 0 is a valid value for evolve but for conditional statements it count as false.
    // So we need to validate against it or the value won't be shown correctly
    if (value || value === 0) {
      return value
    }
    return '-'
  }

  return (
    <Modal open={isOpen} setOpen={() => onClose(false, settingsUsed)}>
      <div className='w-full px-6 py-6 sm:px-12 sm:py-10'>
        <p className='text-2xl sm:text-5xl text-tertiary'>Settings used</p>
        <div className='flex justify-between py-3 mt-8 border-b border-[#1E1F1E]'>
          <p className='text-tertiary/80'>
            <strong>Video Type</strong>
          </p>
          <p className='text-tertiary/80'>
            {getVideoTypeText(settingsUsed?.videoType)}
          </p>
        </div>
        {(settingsUsed?.videoType === 'flipbook' ||
          settingsUsed?.videoType === 'animation') && (
          <div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Show first frame</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.showFirstFrame ? 'Yes' : 'No'}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Subject</strong>
              </p>
              <p className='w-3/5 text-tertiary/80'>
                {settingsUsed?.promptSubject}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Art style</strong>
              </p>
              <p className='w-3/5 text-tertiary/80'>
                {settingsUsed?.promptArtStyle}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Duration</strong>
              </p>
              <p className='text-tertiary/80'>{settingsUsed?.duration} s</p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Aspect ratio</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.aspectRatio || '-'}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Camera Movement</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.cameraMovementName}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Evolve</strong>
              </p>
              <p className='text-tertiary/80'>
                {getEvolveText(settingsUsed.evolve)}
              </p>
            </div>
            {/* show audio reactivity if video contains only 1 scene */}
            {numberOfScenes === 1 &&
              settingsUsed?.scenes?.[0].audioReactivity && (
                <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
                  <p className='text-tertiary/80'>
                    <strong>Audio Reactivity</strong>
                  </p>
                  <p className='text-tertiary/80'>
                    {getAudioReactivityName(
                      settingsUsed?.scenes[0].audioReactivity,
                      settingsUsed?.userProvidedAudioFileUrl,
                    )}
                  </p>
                </div>
              )}
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Boomerang</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.boomerang ? 'Yes' : 'No'}
              </p>
            </div>
          </div>
        )}

        {settingsUsed?.videoType === 'motion' && (
          <div>
            {' '}
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Subject</strong>
              </p>
              <p className='w-3/5 text-tertiary/80'>
                {settingsUsed?.promptSubject}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Art style</strong>
              </p>
              <p className='w-3/5 text-tertiary/80'>
                {settingsUsed?.promptArtStyle}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Duration</strong>
              </p>
              <p className='text-tertiary/80'>{settingsUsed?.duration} s</p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Aspect ratio</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.aspectRatio || '-'}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Evolve</strong>
              </p>
              <p className='text-tertiary/80'>
                {getEvolveText(settingsUsed.evolve)}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Motion</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.motionMagnitude || '-'}
              </p>
            </div>
          </div>
        )}

        {settingsUsed?.videoType === 'transform' && (
          <div>
            {' '}
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Subject</strong>
              </p>
              <p className='w-3/5 text-tertiary/80'>
                {settingsUsed?.promptSubject}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Art style</strong>
              </p>
              <p className='w-3/5 text-tertiary/80'>
                {settingsUsed?.promptArtStyle}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Duration</strong>
              </p>
              <p className='text-tertiary/80'>{settingsUsed?.duration} s</p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Aspect ratio</strong>
              </p>
              <p className='text-tertiary/80'>
                {settingsUsed?.aspectRatio || '-'}
              </p>
            </div>
            <div className='flex justify-between py-3 border-b border-[#1E1F1E]'>
              <p className='text-tertiary/80'>
                <strong>Evolve</strong>
              </p>
              <p className='text-tertiary/80'>
                {getEvolveText(settingsUsed.evolve)}
              </p>
            </div>
          </div>
        )}

        <div className='mt-8 text-center'>
          {!isSubscribed ? (
            <Link to='/pricing'>
              <div className='px-4 py-4 text-sm font-bold rounded-full bg-gray-500/80 text-primary'>
                <StarIcon className='inline w-6 h-6 mr-2' />
                Go Pro to use these settings
              </div>
            </Link>
          ) : (
            <button
              type='button'
              className='w-full gap-2 px-4 py-4 text-sm font-bold text-center text-gray-700 uppercase border rounded-full shadow-sm bg-primary border-primary'
              onClick={handleUseTheseSettings}
            >
              Use these settings
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default VideoInfoModal
