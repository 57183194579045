import { useState } from 'react'
import Lottie from 'lottie-react'

import logo from '../../images/welcome/logo.png'
import google from '../../images/google.svg'
import appleLogo from '../../images/apple-white.svg'
import LoadingWhite from '../../images/lottie/loaderWhite.json'
import { login, getGoogleAuthorisationURL } from '../../services/AuthService'
import { Alert } from '../../components/Alert'
import { Modal } from '../../components/Modal'
import AppleLogin from 'react-apple-login'

export default function Login() {
  const [errors, setErrors] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [passwordResetModal, setPasswordResetModal] = useState(false)
  const [password, setPassword] = useState(null)

  const clearError = (field) => {
    setErrors((errors) => ({
      ...errors,
      [field]: null,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    const email = e.target[0].value
    //const password = e.target[1].value

    try {
      await login(email, password)

      const query = new URLSearchParams(window.location.search)
      const redirect = query.get('redirect')

      // FOR MOBILE APP AFFILIATE TRACKING -- Nov 1, 2023 -- leaving out of initial Paddle launch
      // if (isAndroid || isIOS) {
      //   if (redirect) {
      //     window.location.replace('/get-mobile?redirect=' + redirect)
      //   }
      //   else {
      //     window.location.replace('/get-mobile')
      //   }
      // }
      // else if (redirect) {
      //   window.location.replace(window.location.origin + redirect)
      // } else {
      //   window.location.replace('/dashboard')
      // }

      if (redirect) {
        window.location.replace(window.location.origin + redirect)
      } else {
        window.location.replace('/dashboard')
      }
    } catch (err) {
      setIsSubmitting(false)

      if (err.response) {
        switch (err.response.status) {
          case 401:
            setErrors({
              email: err.response.data.message,
            })

            if (err.response.data.code === 'NEED_PASSWORD_RESET') {
              setPasswordResetModal(true)
            }
            break
          case 400:
            setErrors(err.response.data.errors)
            break
          default:
            setAlertMessage('Oops! Something went wrong.')
            setShowAlert(true)
        }
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
        setShowAlert(true)
      }
    }
  }

  const handleSignInWithGoogle = async () => {
    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    try {
      const response = await getGoogleAuthorisationURL()

      const query = new URLSearchParams(window.location.search)
      const redirect = query.get('redirect')

      if (redirect) {
        window.sessionStorage.setItem('LOGIN_REDIRECT_TO', redirect)
      }

      window.addEventListener('pagehide', handlePageHide)

      window.location.href = response.data.authUrl
    } catch (err) {
      setIsSubmitting(false)
      setShowAlert(true)

      if (err.response) {
        setAlertMessage(err.response.data.message)
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    }
  }

  const handlePageHide = (e) => {
    setIsSubmitting(false)
    window.removeEventListener('pagehide', handlePageHide)
  }

  return (
    <>
      <div
        className='flex h-full overflow-y-auto sm:p-10 bg-darkGray sm:bg-transparent bg-cover bg-center'
        style={{
          backgroundImage: 'url(../website/welcome/bg_quote_new.png)',
        }}
      >
        <div className='w-full p-10 m-auto sm:max-w-sm bg-black sm:rounded-3xl sm:relative'>
          <div className='mb-8 px-6 text-center'>
            <a
              href={process.env.REACT_APP_SITE_URL}
              className='relative inline-block'
            >
              <span className='border border-primary/25 rounded-full py-0.5 px-1.5 text-[#3bc07d] text-xs absolute -right-8 -top-4'>
                BETA
              </span>
              <img src={logo} alt='Logo' />
            </a>
          </div>

          {showAlert && (
            <div className='mb-4'>
              <Alert
                open={showAlert}
                setOpen={setShowAlert}
                message={alertMessage}
                type='alert'
              />
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Email</strong>
              </label>
              <input
                type='email'
                id='email'
                onInput={() => clearError('email')}
                required
                placeholder='Enter email address'
                className={`w-full p-3 px-5 mt-3 block ${
                  errors.email ? 'border-red-700' : 'border-transparent'
                } border rounded-full bg-[#303030] text-white`}
                disabled={isSubmitting}
              />
              {errors.email && (
                <span className='text-red-500'>{errors.email}</span>
              )}
            </div>
            <div className='mb-1'>
              <label
                htmlFor='password'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Password</strong>
              </label>
              <input
                type='password'
                id='password'
                onInput={(e) => {
                  clearError('password')
                  setPassword(e.target.value)
                }}
                required
                placeholder='Enter password'
                className={`w-full p-3 px-5 mt-3 block ${
                  errors.password ? 'border-red-700' : 'border-transparent'
                } border rounded-full bg-[#303030] text-white`}
                disabled={isSubmitting}
              />
              {errors.password && (
                <span className='text-red-500'>{errors.password}</span>
              )}
            </div>
            <div className='my-3 mt-0 mx-5 text-left'>
              <a
                href='/password/forgot'
                className='text-[#545854] font-medium hover:text-primary text-xs'
              >
                Forgot Password?
              </a>
            </div>
            <button
              type='submit'
              className='w-full p-4 py-3 text-base font-medium text-gray-900 border-2 rounded-full shadow-sm bg-primary border-primary'
              disabled={isSubmitting}
            >
              Login
            </button>
          </form>

          <a
            href='/register'
            className='block w-full px-4 py-3 mt-4 text-base font-medium text-center text-primary border-2 rounded-full shadow-sm border-primary'
          >
            Sign Up
          </a>

          <p className='my-5 text-center text-gray-400'>or</p>

          <button
            type='button'
            className='flex items-center justify-center w-full py-1 p-2 border-2 rounded-full shadow-sm border-primary'
            onClick={handleSignInWithGoogle}
            disabled={isSubmitting}
          >
            <img src={google} alt='Google Icon' />
            <span className='text-primary'>Sign in with Google</span>
          </button>

          <div className='mt-4'>
            <AppleLogin
              clientId={process.env.REACT_APP_APPLE_WEB_CLIENT_ID}
              redirectURI={process.env.REACT_APP_APPLE_WEB_REDIRECT_URI}
              usePopup={false}
              scope='email name'
              responseMode='form_post'
              responseType='code'
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  type='button'
                  className='flex items-center justify-center w-full py-1 p-2 border-2 rounded-full shadow-sm border-primary'
                >
                  <img
                    className='m-2 mr-3'
                    width={20}
                    height={20}
                    src={appleLogo}
                    alt='Apple Icon'
                  />
                  <span className='text-primary'>Sign in with Apple</span>
                </button>
              )}
            />
          </div>

          {isSubmitting && (
            <div className='absolute inset-0 flex w-full h-full bg-gray-500 bg-opacity-75 sm:rounded-3xl'>
              <div className='flex flex-col items-center m-auto space-y-4'>
                <div className='p-6 bg-darkGray rounded-3xl'>
                  <Lottie
                    animationData={LoadingWhite}
                    loop={true}
                    className='w-16 h-16'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PasswordResetModal
        isOpen={passwordResetModal}
        onClose={setPasswordResetModal}
      />
    </>
  )
}

function PasswordResetModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='w-full px-6 py-6 space-y-8 sm:px-12 sm:py-10'>
        <p className='text-2xl sm:text-4xl text-tertiary'>
          You need to reset your password
        </p>
        <p className='text-tertiary/80'>
          Due to our latest update, some users will need to reset their password
          to continue using our service.
        </p>
        <div className='flex flex-col items-center justify-end gap-2 mt-4'>
          <a
            href='/password/forgot'
            className='w-full gap-2 px-4 py-4 text-sm font-bold text-center text-gray-700 uppercase border rounded-full shadow-sm bg-primary border-primary'
          >
            RESET PASSWORD
          </a>
        </div>
      </div>
    </Modal>
  )
}
