import { Tooltip as MaterialTooltip } from '@material-tailwind/react'
import { ReactNode } from 'react'

import { useThemeContext } from '../../context'
import { cn } from '../../utils'

export interface TooltipProps {
  content: string
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
  className?: string
  children: ReactNode
}

export const Tooltip = ({
  content,
  placement = 'top',
  className = '',
  children,
}: TooltipProps) => {
  const { colors } = useThemeContext()
  return (
    <MaterialTooltip
      animate={{
        mount: { scale: 1 },
        unmount: { scale: 0 },
      }}
      placement={placement}
      content={content}
      className={cn(
        `${colors.background.default} border-[0.5px] border-gray-400 ${colors.text.default} font-public-sans`,
        className,
      )}
    >
      {children}
    </MaterialTooltip>
  )
}
