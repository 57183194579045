import { useEffect, useRef } from 'react'

/**
 *  useInitialLoad is a custom hook that runs a callback function only once when the component mounts
 */
export const useInitialLoad = (callback: () => Promise<void>) => {
  const callbackRef = useRef(callback)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const fetchData = async () => {
      try {
        await callbackRef.current()
      } catch (error) {
        if (signal.aborted) {
          return // Ignore the error if the request was cancelled
        }
      }
    }

    fetchData()

    return () => {
      controller.abort() // Cancel the fetch request if the component unmounts
    }
  }, [])

  callbackRef.current = callback
}
