import React from 'react'
import { Helmet } from 'react-helmet'

function ImpactHead() {
  return (
    <Helmet>
      <script>
        {'(' +
          function () {
            const query = new URLSearchParams(window.location.search)
            const partnerId = query.get('irpid')
            const partnerToken = query.get('via')

            const domain = window.location.origin.includes('localhost')
              ? 'localhost'
              : '.kaiber.ai'

            if (partnerId) {
              document.cookie = `impact_partner_id=${partnerId}; Domain=${domain}; Max-Age=2592000; Path=/; SameSite=none; Secure;`
            } else if (partnerToken) {
              document.cookie = `impact_partner_token=${partnerToken}; Domain=${domain}; Max-Age=2592000; Path=/; SameSite=none; Secure;`
            }
          }.toString() +
          ')()'}
      </script>
    </Helmet>
  )
}

export default ImpactHead
