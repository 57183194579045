import { Modal } from './Modal'
import DownloadButton from './DownloadButton'
import { getPrompt } from '../utils'
import { useEffect, useState } from 'react'

function DownloadModal({ isOpen, onClose, memory }) {
  const [prompt, setPrompt] = useState('')

  useEffect(() => {
    if (!memory) {
      return
    }

    if (memory.prompt) {
      setPrompt(memory.prompt)
      return
    }

    if (memory.scenes) {
      setPrompt(
        getPrompt(memory.scenes['0']?.subject, memory.scenes['0']?.style),
      )
      return
    }

    if (memory.settingsUsed) {
      setPrompt(memory.settingsUsed.prompt)
      return
    }

    console.log('DEBUG: No prompt found for memory:', memory)
    return
  }, [memory])

  return (
    <Modal open={isOpen} setOpen={onClose}>
      {memory ? (
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          {/* <p className='text-tertiary/80'>
            You're about to reuse the settings used by the creator of this video.
          </p> */}
          <div className='mt-4 flex flex-col items-center justify-end gap-2 w-full'>
            <DownloadButton
              url={memory.mediaUrl || memory.url}
              prompt={prompt}
            />
            {memory.versions
              ? memory.versions.map((version, index) => (
                  <div key={index}>
                    <DownloadButton
                      url={version.mediaUrl}
                      prompt={prompt}
                      scale_multiplier={version.scale_multiplier}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

export default DownloadModal
