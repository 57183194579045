import { CollectionAction } from '@kaiber/shared-types'
import {
  Node,
  NodeResizer,
  Position,
  useHandleConnections,
  useReactFlow,
} from '@xyflow/react'
import { ResizeDragEvent, ResizeParams } from '@xyflow/system'
import { memo, useCallback, useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'

import {
  MEDIA_CANVAS_DEFAULT_SIZE,
  DEFAULT_BORDER_COLOR,
} from '../../../constants'
import { useCanvasContext, useThemeContext } from '../../../context'
import { useMutateCollection, useNodeUtility } from '../../../hooks'
import { useQueryCollection } from '../../../hooks/k2/useQueryCollection'
import { XMarkIcon2 } from '../../../images/icons/XMarkIcon2'
import { cn } from '../../../utils'
import { CustomHandle } from '../Canvas/CustomHandle'
import { CollectionDisplay } from '../Collection/CollectionDisplay'
import { NodeToolbar, ToolbarButton } from '../NodeToolbar'
import { ScaleWrapper } from '../ScaleWrapper'
import { ErrorNode } from './ErrorNode'
import { Media, NodeType } from '@/types'

export interface CollectionNodeData extends Record<string, unknown> {
  collectionId: string
  columns?: number
}

export interface CollectionNodeProps {
  id: string
  data: CollectionNodeData
}

const BaseCollectionNode: React.FC<CollectionNodeProps> = memo(
  ({ id, data }) => {
    const {
      isPending,
      data: collection, // Note: this is undefined in initial render because query is not yet done. Use `status` described in the doc above to check status.
      error,
    } = useQueryCollection(data.collectionId)

    const { registerOnNodeIntersection } = useCanvasContext()
    const { updateNode, getNode } = useReactFlow()
    const { prependMedia, updateCollection } = useMutateCollection()
    const { deleteNodeById } = useNodeUtility()
    const { colors } = useThemeContext()

    const [isEditing, setIsEditing] = useState(false)
    const [editingName, setEditingName] = useState('')
    const [isHovering, setIsHovering] = useState(false)

    const node = getNode(id)
    const width = node?.measured?.width || 720

    const handleMouseEnter = useCallback(() => {
      setIsHovering(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
      setIsHovering(false)
    }, [])

    const onResize = useCallback(
      (
        event: ResizeDragEvent,
        { width: newWidth, height: newHeight }: ResizeParams,
      ) => {
        updateNode(id, (_n) => ({
          style: {
            width: newWidth,
            height: newHeight,
          },
        }))
      },
      [id, updateNode],
    )

    const handleNameChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingName(event.target.value)
      },
      [],
    )

    const saveNameChange = useCallback(() => {
      setIsEditing(false)
      if (editingName && editingName !== collection?.name) {
        updateCollection({
          collectionId: data.collectionId,
          action: 'RENAME' as CollectionAction,
          payload: { name: editingName },
        })
      }
    }, [data.collectionId, editingName, collection?.name, updateCollection])

    useEffect(() => {
      if (data.collectionId) {
        registerOnNodeIntersection(
          id,
          async (selfNode: Node, intersectingNode: Node) => {
            if (intersectingNode.type === NodeType.MediaNode) {
              const mediaNodeData = intersectingNode.data as { media: Media }
              const media = mediaNodeData.media

              if (media && media.mediaId) {
                prependMedia(data.collectionId, media.mediaId)
                deleteNodeById(intersectingNode.id)
              }
            }
          },
        )
      }

      // prependMedia and deleteNodebyId are both causing massive re-render loops, and I cannot figure out why
    }, [
      id,
      data.collectionId,
      registerOnNodeIntersection,
      prependMedia,
      deleteNodeById,
    ])

    const connections = useHandleConnections({
      type: 'target',
    })
    const isConnected = connections.length > 0

    const { updateNodeData } = useReactFlow()

    const handleColumnsChange = useCallback(
      (value: number) => {
        updateNodeData(id, { columns: value })
      },
      [id, updateNodeData],
    )

    const handleDelete = useCallback(() => {
      deleteNodeById(id) // Replace the existing line with this
    }, [id, deleteNodeById]) // Update dependencies

    const toolbarButtons: ToolbarButton[] = [
      {
        icon: XMarkIcon2,
        onClick: handleDelete,
        className: 'text-alarm',
        title: 'remove from canvas',
        size: 28,
      },
    ]

    return (
      <div
        className={cn(
          `border border-k2-gray-200 nopan rounded-xl p-8 flex flex-col min-w-[${MEDIA_CANVAS_DEFAULT_SIZE}px] min-h-[${MEDIA_CANVAS_DEFAULT_SIZE}px]`,
          !isConnected && colors.background.neutral,
        )}
        style={{
          width: width,
          height: node?.measured?.height || 720,
          background: isConnected ? colors.background.flowGradient : undefined,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NodeResizer
          minWidth={MEDIA_CANVAS_DEFAULT_SIZE}
          minHeight={MEDIA_CANVAS_DEFAULT_SIZE}
          onResize={onResize}
          lineStyle={{
            border: 'none', // tailwind doesn't work with raw react-flow components
            padding: '10px',
          }}
          handleStyle={{
            width: '20px',
            height: '20px',
            border: 'none',
            background: 'transparent',
            padding: '10px', // Add padding to increase the draggable area
          }}
        />

        <div
          className={cn(
            'absolute left-1/2 -translate-x-1/2 -top-12 transition-opacity duration-300 pointer-events-none z-50',
            isHovering ? 'opacity-100' : 'opacity-0',
          )}
        >
          <div className='pointer-events-auto'>
            <NodeToolbar buttons={toolbarButtons} />
          </div>
        </div>
        <ScaleWrapper className='flex flex-row items-center gap-2 absolute -top-12 left-0 origin-bottom-left'>
          {isEditing ? (
            <input
              type='text'
              value={editingName}
              onChange={handleNameChange}
              onBlur={saveNameChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  saveNameChange()
                }
              }}
              className={cn(
                'border border-solid border-white rounded-lg px-3 py-2  h-9',
                colors.background.element,
                colors.text.default,
              )}
              autoFocus
            />
          ) : (
            <>
              {collection && (
                <div
                  className={cn(
                    `header border border-solid ${DEFAULT_BORDER_COLOR} rounded-lg px-3 py-2 cursor-pointer h-9 flex items-center`,
                    colors.background.element,
                    colors.text.default,
                  )}
                  onClick={() => {
                    setEditingName(collection?.name || '')
                    setIsEditing(true)
                  }}
                >
                  {collection.name}
                </div>
              )}
            </>
          )}
        </ScaleWrapper>
        <CollectionDisplay
          collection={collection}
          error={error}
          isPending={isPending}
          columns={data.columns}
          handleColumnsChange={handleColumnsChange}
          width={width}
        />
        <CustomHandle type='target' position={Position.Left} isConnectable />
      </div>
    )
  },
)

BaseCollectionNode.displayName = 'CollectionNode'

export const CollectionNode = withErrorBoundary(BaseCollectionNode, {
  FallbackComponent: ErrorNode,
  onError(error: any, info: any) {
    console.error('CollectionNode Error:', error, info)
  },
})
