import { IChange } from 'json-diff-ts'
import { ReactFlowJsonObject } from '@xyflow/react'
import { API_ENDPOINTS } from '../constants'
import http from './HttpService'
import { Canvas, CanvasVersion } from '../types'

export const load = async (
  canvasId: string,
  timestamp?: number,
): Promise<ReactFlowJsonObject> => {
  const response = await http.get(API_ENDPOINTS.LOAD_CANVAS, {
    params: {
      canvasId,
      timestamp,
    },
  })
  return response.data
}

export const createCanvas = async (): Promise<Canvas> => {
  const response = await http.post(API_ENDPOINTS.CREATE_CANVAS)
  return response.data
}

export const getCanvases = async (): Promise<Canvas[]> => {
  const response = await http.get(API_ENDPOINTS.GET_CANVASES)
  return response.data
}

export const getVersionIds = async (
  canvasId: string,
): Promise<CanvasVersion[]> => {
  const response = await http.get(API_ENDPOINTS.GET_CANVAS_VERSION_IDS, {
    params: {
      canvasId,
    },
  })
  return response.data
}

export const save = async (
  canvasId: string,
  diffs: IChange[],
  isResetting: boolean = true, // development purpose
) => {
  const response = await http.patch(API_ENDPOINTS.SAVE_CANVAS, {
    canvasId,
    diffs,
    isResetting,
  })
  return response.data
}

export const updateTitle = async (
  canvasId: string,
  title: string,
): Promise<Canvas> => {
  const response = await http.patch(API_ENDPOINTS.UPDATE_TITLE, {
    canvasId,
    title,
  })
  return response.data
}
