interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const FileUploadIcon = (props: SVGProps) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    stroke='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.99967 16.0416V9.79165M9.99967 9.79165L12.083 11.875M9.99967 9.79165L7.91634 11.875M12.4997 16.0416H15.1038C17.0023 16.0416 18.5413 14.5026 18.5413 12.6041C18.5413 10.7056 17.0023 9.16665 15.1038 9.16665C15.0675 9.16665 15.0313 9.16723 14.9953 9.16831C14.9983 9.09865 14.9997 9.02865 14.9997 8.95831C14.9997 6.19689 12.7611 3.95831 9.99967 3.95831C7.80934 3.95831 5.94795 5.36672 5.27173 7.32731C3.12057 7.6028 1.45801 9.44056 1.45801 11.6666C1.45801 14.0829 3.41677 16.0416 5.83301 16.0416H7.49967'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
