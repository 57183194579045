import { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { Link } from 'react-router-dom'

const Accordion = ({ items, user }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index)
  }

  /**
   *
   * @param {Object} param0
   * @param {Object} param0.item
   * @param {string} param0.item.tier
   * @returns
   */
  function showUpgrade({ item }) {
    if (!user) {
      return true
    }

    if (!item.tier) {
      return false
    }

    if (!user.subscriptionType) {
      return true
    }

    // Explorer, Pro, Artist
    const userTier = user.subscriptionTierDescription.split(' ')[0]

    const tiers = {
      Explorer: 1,
      Pro: 2,
      Artist: 2,
    }

    if (tiers[userTier] < tiers[item.tier]) {
      return true
    }
    return false
  }

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex ? 'active' : ''

    return (
      <div
        key={index}
        className={`border-b-[1px] border-[#4E4E5B] py-4 item ${isActive}`}
      >
        <div
          className='title cursor-pointer flex justify-between items-center'
          onClick={() => onTitleClick(index)}
        >
          {item.title}

          <div className='arrow'>
            {isActive ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>
        </div>
        <div
          className='accordion-content-container'
          style={{ maxHeight: isActive ? '800px' : '0' }}
        >
          <div className='content'>
            {isActive && item.content}

            {isActive && showUpgrade({ item }) && (
              <Link
                className='mt-6 rounded-full py-2 px-3 text-base text-[#161619] font-semibold bg-kaiberGreen inline-block'
                to='/pricing'
              >
                Upgrade
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className='ui styled accordion plan-details-accordion'>
      {renderedItems}
    </div>
  )
}

export default Accordion
