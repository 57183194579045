import { useQuery } from '@tanstack/react-query'

import { MediaDisplay } from './MediaDisplay'
import { getMedia } from '../../../api'
import { MEDIA_CANVAS_DEFAULT_SIZE } from '../../../constants'
import { mediaStore } from '../../../stores'
import { cn } from '../../../utils'
import { calculateScaledDimensions } from '../../../utils/imageUtils'
import { Media } from '@/types'

interface MediaItemProps {
  mediaId: string
  thumbnailClassName?: string
  hideProgress?: boolean
}

export const MediaItem = ({
  mediaId,
  thumbnailClassName,
  hideProgress,
}: MediaItemProps) => {
  const media: Media = mediaStore.useMedia(mediaId)

  const queryFn = async () => {
    if (media) return media
    const fetched = await getMedia({ mediaId })
    mediaStore.setMedia(fetched)
    return fetched
  }

  // https://tanstack.com/query/latest/docs/framework/react/guides/queries
  const { isPending, error } = useQuery({
    queryKey: ['media', mediaId],
    queryFn,
  })

  let scaledDimensions = calculateScaledDimensions(
    media?.width,
    media?.height,
    MEDIA_CANVAS_DEFAULT_SIZE,
  )

  if (isPending) {
    return null
  }

  if (error)
    return (
      <div className='text-white'>
        Media fetch error: {mediaId} {error.message}
      </div>
    )

  return (
    <div
      key={media.mediaId}
      className='relative group cursor-pointer w-full nodrag'
    >
      <MediaDisplay
        media={media}
        className={cn('mb-4', thumbnailClassName)}
        showDownloadButton
        width={scaledDimensions.width}
        height={scaledDimensions.height}
        hideProgress={hideProgress}
      />
    </div>
  )
}
