/* eslint-disable */
// TODO - fix eslint errors related to loading patterns here

import { useState, useReducer, useEffect, useRef, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { PiGearBold } from 'react-icons/pi'
import { useParams } from 'react-router-dom'
import Lottie from 'lottie-react'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'

import { Bio } from '../components/Bio'
import { UpdateNameModal } from '../components/UpdateNameModal'
import { ProfileShareButton } from '../components/ProfileShareButton'
import { getUserProfile } from '../services/UserProfileService'
import { useUserAccountContext } from '../context/userContext'
import { ProfilePicture } from '../components/ProfilePicture'
import { SocialLinks } from '../components/SocialLinks'
import Navbar from '../components/Navbar'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import http from '../services/HttpService'
import VideoDeletionModal from '../components/VideoDeletionModal'
import VideoInfoModal from '../components/VideoInfoModal'
import { UpscaleModal } from '../components/UpscaleModal'
import DownloadModal from '../components/DownloadModal'
import { VideoGallery } from '../components/VideoGallery'
import { SearchBar } from '../components/SearchBar'
import { useVideoEventsContext } from '../context/videoEventsContext'
import { cn, isMobile } from '../utils'
import NotFound from './NotFound'

export const Profile = ({ publicView }) => {
  const { username } = useParams()
  const { currentUser, featureToggles } = useUserAccountContext()

  const [isMyProfile, setIsMyProfile] = useState(false)
  const [isProfileLoading, setIsProfileLoading] = useState(true)
  const [videosLoading, setVideosLoading] = useState(true)
  const [userProfile, setUserProfile] = useState(null)
  const [videosList, setVideosList] = useState([])
  const [userProfileNotFound, setUserProfileNotFound] = useState(false)
  const videosListRef = useRef(videosList)
  videosListRef.current = videosList

  let currentIndex = 0

  // stores videos for gallery state
  const [videos, setVideos] = useState([])
  const [isVideoDeletionModalActive, setIsVideoDeletionModalActive] =
    useState(false)
  const [videoDeletionId, setVideoDeletionId] = useState(null)
  const [isVideoInfoModalActive, setIsVideoInfoModalActive] = useState(false)
  const [videoInfoSettingsUsed, setVideoInfoSettingsUsed] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [offsetDescription, setOffsetDescription] = useState(null)
  const [hasPagination, setHasPagination] = useState(false)
  const [isUpscalingActive, setIsUpscalingActive] = useState(false)
  const [memory, setMemory] = useState(false)
  const [canvasIsUpscaling, setCanvasIsUpscaling] = useState([])
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)
  const [isMakingPublic, setIsMakingPublic] = useState(false)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef(null)

  const { trackVideos } = useVideoEventsContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  const getVideos = useCallback(
    async (page = 1, query = null) => {
      setVideosLoading(true)

      if (page === 1 && videos.length > 0) {
        setVideos([])
        setCurrentPage(1)
      }

      try {
        let perPage = 20
        if (isMobile()) {
          perPage = 12
        }

        let endpoint = `/api/get_my_videos?concurrent_loading=true&page=${page}&per_page=${perPage}`
        if (publicView) {
          endpoint = `/api/get_videos_for_user?concurrent_loading=true&page=${page}&per_page=${perPage}`
        }

        const { data } = await http.get(endpoint, {
          params: {
            q: query ?? searchInputRef?.current?.value ?? '',
            username,
          },
        })

        let originalVideosList = data.items
        currentIndex = 0

        setVideosList(originalVideosList)
        setVideos(originalVideosList)
        setTotalPages(data.totalPages)

        // for pagination
        setTotalItems(data.totalItems)
        setOffsetDescription(data.offsetDescription)
        setHasPagination(data.hasPagination)

        trackVideos(data.items)
      } catch (error) {
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.message ===
            'Not enough information to get memories for user'
        ) {
          setUserProfileNotFound(true)
        } else {
          console.error(error)
        }
      } finally {
        setVideosLoading(false)
      }
    },
    [setVideos, setVideosLoading, searchInputRef, videos, publicView],
  )

  useEffect(() => {
    if (isProfileLoading) return

    getVideos(currentPage)
    // note: do not include getVideos in dependency array otherwise infinite calls
  }, [isProfileLoading])

  const handleDownloadOpen = (memory) => {
    setMemory(memory)
    setIsDownloadOpen(true)
  }

  const toggleUpscaleModal = (_memory) => {
    setMemory(_memory)
    setIsUpscalingActive(!isUpscalingActive)
  }

  const handleOnCloseUpscaleModal = (memoryId = false) => {
    if (memoryId) {
      const arr = canvasIsUpscaling
      arr.push(memoryId)
      setCanvasIsUpscaling(arr)
    }
    setIsUpscalingActive(false)
  }

  const handleIsUpscaling = (memoryId) => {
    const currentVideo = videos.find((video) => video.memory_id === memoryId)

    return (
      currentVideo?.upscale_status === 'pending' ||
      canvasIsUpscaling.includes(memoryId)
    )
  }

  const handleUpscaleSuccess = async (memoryId) => {
    const index = videos.findIndex((v) => v.memory_id === memoryId)
    if (index) {
      const response = await http.get('/api/get_memory/' + memoryId)
      if (response) {
        videos[index] = response.data.memory
      }
    }

    const arr = canvasIsUpscaling
    let ind = arr.findIndex((id) => id === memoryId)
    if (ind >= 0) arr.splice(ind, 1)
    setCanvasIsUpscaling(arr)
    await new Promise((resolve) => setTimeout(resolve, 100))
    forceUpdate()
  }

  const handleUpscaleError = async (memoryId) => {
    const index = videos.findIndex((v) => v.memory_id === memoryId)
    if (index >= 0) {
      const response = await http.get('/api/get_memory/' + memoryId)
      if (response) {
        videos[index] = response.data.memory
      }
    }

    const arr = canvasIsUpscaling
    let ind = arr.findIndex((id) => id === memoryId)
    arr.splice(ind, 1)
    setCanvasIsUpscaling(arr)
    forceUpdate()
  }

  const toggleVideoDeletionModal = (isOpen, videoId) => {
    setIsVideoDeletionModalActive(isOpen)
    setVideoDeletionId(videoId || null)
  }

  const toggleVideoInfoModal = (isOpen, settingsUsed) => {
    setIsVideoInfoModalActive(isOpen)
    setVideoInfoSettingsUsed(settingsUsed)
  }

  // for delete
  const removeVideoFromUI = (videoId) => {
    setVideos((videos) => videos.filter((v) => v._id !== videoId))
  }

  const handlePageClick = (event) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    const page = event.selected + 1
    setCurrentPage(page)

    if (videos.length > 0) {
      // loop through videos and remove them from the DOM
      for (let i = 0; i < videos.length; i++) {
        if (document.getElementById(videos[i]._id) != null) {
          const videoElement = document.getElementById(videos[i]._id)
          const sourceElement = document
            .getElementById(videos[i]._id)
            .getElementsByTagName('source')[0]
          if (sourceElement) {
            videoElement.pause()
            sourceElement.src = ''
            videoElement.load()
            sourceElement.remove()
            videoElement.remove()
          }
        }
      }
    }

    // resetting the state to clear videos breaks scrollTo() (for some unknown reason) on Firefox and iOS Safari.
    // Use a timeout to delay the state change.
    setTimeout(() => {
      currentIndex = 0
      setVideos([])
      setVideosList([])

      getVideos(page, searchText)
    }, 250)
  }

  const handleSearchVideos = (page, searchText) => {
    currentIndex = 0
    setVideos([])
    setVideosList([])

    getVideos(page, searchText)
  }

  const handleVideoStatusChange = (videoId, status, url) => {
    setVideos((videos) =>
      videos.map((v) => (v._id === videoId ? { ...v, status, url } : v)),
    )
  }

  const handleMakePublic = async (videoId) => {
    if (isMakingPublic) return
    setIsMakingPublic(true)

    try {
      const res = await http.post(`/api/make_public/${videoId}`)
      const { is_public } = res.data

      if (res.status === 200) {
        toast.success(
          `Your video is now ${is_public ? 'public 🎉' : 'private 🔒'}`,
        )
        setVideos((videos) =>
          videos.map((v) => (v._id === videoId ? { ...v, is_public } : v)),
        )
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.error || err.response.data)
      } else if (err?.message) {
        toast.error(err.message)
      } else {
        toast.error('Something went wrong.')
      }
    } finally {
      setIsMakingPublic(false)
    }
  }

  // Update local state when user updates profile for immediate display changes
  const onProfileUpdate = (updatedProfileFields) => {
    const updatedProfile = {
      ...userProfile,
      ...updatedProfileFields,
    }
    setUserProfile(updatedProfile)
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile(username)
        setUserProfile(userProfile)
        setIsProfileLoading(false)
      } catch (err) {
        if (err?.response?.status === 404) {
          setUserProfileNotFound(true)
        } else {
          console.error(err)
        }
      } finally {
        setIsProfileLoading(false)
      }
    }

    // Utilize local state to avoid unnecessary API calls if viewing own profile
    if (currentUser?.username === username || !username) {
      const {
        username: viewingUsername,
        bio,
        links,
        profilePicture,
      } = currentUser
      setIsMyProfile(true)
      setUserProfile({ bio, links, profilePicture, username: viewingUsername })
      setIsProfileLoading(false)
    } else {
      fetchUserProfile()
    }
  }, [currentUser?.username, username])

  const usernameBaseClasses = 'text-lg break-all'
  const usernameDesktopClasses = cn(
    usernameBaseClasses,
    userProfile?.username?.length > 16 && 'text-base',
    userProfile?.username?.length > 22 && 'text-sm',
  )

  const renderDesktopProfileInfo = () => (
    <div
      className={cn(
        'flex flex-col justify-top gap-4 mx-4 text-white sm:w-1/5 sm:sticky top-[118px]',
        { 'top-[168px]': isFeedbackBannerShown },
      )}
    >
      <ProfilePicture
        canEdit={isMyProfile && !publicView}
        src={userProfile?.profilePicture}
        onUpdate={onProfileUpdate}
      />
      <div className='p-4 rounded-xl bg-darkGray'>
        <h3 className={usernameDesktopClasses}>@{userProfile?.username}</h3>
      </div>
      <Bio
        canEdit={isMyProfile && !publicView}
        bio={userProfile?.bio}
        onUpdate={onProfileUpdate}
      />
      <SocialLinks
        links={userProfile?.links}
        canEdit={isMyProfile && !publicView}
      />
      <ProfileShareButton username={userProfile?.username} />
      {isMyProfile && !publicView && (
        <Link
          className='border-solid border border-primary rounded-xl text-primary py-2 px-4'
          to='/account'
        >
          <PiGearBold className={'inline mb-1'} /> Account Settings
        </Link>
      )}
    </div>
  )

  const renderMobileProfileInfo = () => (
    <div className='flex flex-col justify-top gap-4 px-4 text-white w-full'>
      <div className='p-4 rounded-xl bg-darkGray'>
        <h3 className={usernameBaseClasses}>@{userProfile?.username}</h3>
      </div>
      <div className='flex flex-row w-full gap-4'>
        <ProfilePicture
          canEdit={isMyProfile && !publicView}
          src={userProfile?.profilePicture}
          onUpdate={onProfileUpdate}
        />
        <div className='flex flex-col w-1/2 gap-4'>
          <Bio
            canEdit={isMyProfile && !publicView}
            bio={userProfile?.bio}
            onUpdate={onProfileUpdate}
          />
          <SocialLinks
            links={userProfile?.links}
            canEdit={isMyProfile && !publicView}
          />
        </div>
      </div>
      <ProfileShareButton username={userProfile?.username} />
      {isMyProfile && !publicView && (
        <Link
          className='border-solid border border-primary rounded-xl text-primary py-2 px-4'
          to='/account'
        >
          <PiGearBold className={'inline mb-1'} /> Account Settings
        </Link>
      )}
    </div>
  )

  return (
    <main
      className={cn('flex flex-col bg-black pt-[94px] min-h-screen', {
        'pt-[144px]': isFeedbackBannerShown,
      })}
    >
      <Navbar />

      {isProfileLoading ? (
        <Lottie
          animationData={LoadingWhite}
          loop={true}
          className='mt-8 h-16'
        />
      ) : userProfileNotFound ? (
        <NotFound customMessage='The profile you are looking for could not be found.' />
      ) : (
        <div className='flex flex-col sm:flex-row w-full max-w-6xl py-2 sm:py-6 mx-auto items-start'>
          {isMobile() ? renderMobileProfileInfo() : renderDesktopProfileInfo()}
          <div className='container px-5 mx-auto space-y-8 sm:w-4/5'>
            <div className='flex flex-col items-left gap-4 mt-4 sm:m-0 sm:items-center sm:justify-between sm:flex-row'>
              <h3 className='text-white'> Materials </h3>
              {/* SEARCH */}
              {(videos.length !== 0 || searchText != null) && (
                <SearchBar
                  ref={searchInputRef}
                  getVideos={handleSearchVideos}
                  isLoading={videosLoading}
                  setSearchText={setSearchText}
                  searchText={searchText}
                  className='w-full'
                />
              )}
            </div>

            <VideoGallery
              videos={videos}
              getVideos={getVideos}
              setSearchText={setSearchText}
              searchInputRef={searchInputRef}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              isLoading={videosLoading}
              isDashboard={isMyProfile && !publicView}
              onVideoStatusChange={handleVideoStatusChange}
              onDelete={toggleVideoDeletionModal}
              onInfo={toggleVideoInfoModal}
              onMakePublic={handleMakePublic}
              onUpscaleOpen={toggleUpscaleModal}
              isUpscaling={handleIsUpscaling}
              isMakingPublic={isMakingPublic}
              onUpscaleSuccess={handleUpscaleSuccess}
              onUpscaleError={handleUpscaleError}
              onDownload={handleDownloadOpen}
              username={username}
              columns={3}
            />

            {/* PAGINATION */}
            <div>
              {offsetDescription && hasPagination && (
                <div className='mb-5 text-center'>
                  <span className='text-gray-500'>{offsetDescription}</span>
                </div>
              )}

              {!(videos.length === 0 && searchText == null) &&
                hasPagination &&
                totalItems > 0 && (
                  <ReactPaginate
                    forcePage={currentPage - 1}
                    breakLabel='...'
                    previousLabel={
                      <ChevronDoubleLeftIcon className='w-6 h-6' />
                    }
                    nextLabel={<ChevronDoubleRightIcon className='w-6 h-6' />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName='flex flex-wrap justify-center gap-2'
                    breakClassName='page-item'
                    pageClassName='page-item'
                    previousClassName='page-item'
                    nextClassName='page-item'
                    activeClassName='active'
                    breakLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                    pageLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                    previousLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                    nextLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                    activeLinkClassName='radial-gradient shadow-sm text-gray-900 font-bold'
                    disabledLinkClassName='border-gray-900 text-gray-700'
                  />
                )}
            </div>
          </div>

          {/* MODALS */}
          <VideoDeletionModal
            isOpen={isVideoDeletionModalActive}
            videoId={videoDeletionId}
            onClose={toggleVideoDeletionModal}
            onDelete={removeVideoFromUI}
          />
          <VideoInfoModal
            settingsUsed={videoInfoSettingsUsed}
            isOpen={isVideoInfoModalActive}
            onClose={toggleVideoInfoModal}
          />
          <DownloadModal
            isOpen={isDownloadOpen}
            onClose={() => {
              setIsDownloadOpen(false)
            }}
            memory={memory}
          />
          <UpscaleModal
            isOpen={isUpscalingActive}
            onClose={handleOnCloseUpscaleModal}
            memory={memory}
            onSuccess={handleUpscaleSuccess}
            onError={handleUpscaleError}
          />
          {isMyProfile && featureToggles['feature-user-profiles'] && (
            <UpdateNameModal onUpdate={onProfileUpdate} />
          )}
        </div>
      )}
    </main>
  )
}
