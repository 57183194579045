import { useState, useEffect } from 'react'
import http from '../services/HttpService'
import axios from 'axios'

function AudioCreator() {
  const [prompts, setPrompts] = useState([])
  const [audioFile, setAudioFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [audioUrls, setAudioUrls] = useState([])
  const [jobId, setJobId] = useState(null)
  const [duration, setDuration] = useState(10) // Add this line to initialize the duration state variable

  // ... rest of your component

  const handleDurationChange = (e) => {
    setDuration(e.target.value)
  }

  useEffect(() => {
    if (!jobId) return

    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`/api/sieve_job/${jobId}`)
        if (response.data.status === 'finished') {
          const audioData = response.data.data
          const urls = audioData.map((item) => item.url)
          setAudioUrls(urls)
          setIsLoading(false)
          clearInterval(intervalId)
        }
      } catch (error) {
        console.error(error)
      }
    }, 5000) // Polls every 5 seconds

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [jobId])

  const addPrompt = () => {
    if (prompts.length < 4) {
      setPrompts([...prompts, ''])
    }
  }

  const removePrompt = (index) => {
    setPrompts(prompts.filter((_, i) => i !== index))
  }

  const updatePrompt = (index, value) => {
    const newPrompts = [...prompts]
    newPrompts[index] = value
    setPrompts(newPrompts)
  }

  const handleAudioUpload = (e) => {
    setAudioFile(e.target.files[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)

    const formData = new FormData()

    formData.append('prompts', JSON.stringify(prompts))
    formData.append('userProvidedAudioFile', audioFile)
    formData.append('duration', duration)

    const response = await http.post('/api/generate_audio', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (response) {
      setJobId(response.data.job_id)
    }
  }

  return (
    <div className='flex flex-col items-center bg-gray-900 text-white min-h-screen p-4'>
      {prompts.map((prompt, index) => (
        <div
          key={index}
          className='w-full flex justify-between items-center my-2'
        >
          <input
            type='text'
            value={prompt}
            onChange={(e) => updatePrompt(index, e.target.value)}
            className='border-2 border-gray-200 rounded-md p-2 flex-grow mr-2 bg-gray-800 text-white'
          />
          <button
            onClick={() => removePrompt(index)}
            className='px-2 py-1 bg-red-600 text-white rounded'
          >
            Remove
          </button>
        </div>
      ))}
      <input
        type='file'
        accept='audio/*'
        onChange={handleAudioUpload}
        className='my-2 text-white'
      />
      <button
        onClick={addPrompt}
        className='px-2 py-1 bg-blue-600 text-white rounded mt-2'
      >
        Add prompt
      </button>
      <label htmlFor='duration' className='mr-2'>
        Duration: {duration}
      </label>
      <input
        id='duration'
        name='duration'
        type='range'
        min='1'
        max='120'
        value={duration}
        onChange={handleDurationChange}
        className='text-white'
      />
      <button
        onClick={handleSubmit}
        className='px-2 py-1 bg-green-600 text-white rounded mt-2'
      >
        Submit
      </button>
      {isLoading && <div className='mt-2'>Loading...</div>}
      <div>
        {audioUrls.map((url, index) => (
          <audio key={index} controls>
            <source src={url} type='audio/wav' />
            Your browser does not support the audio element.
          </audio>
        ))}
      </div>
    </div>
  )
}

export default AudioCreator
