import { useCallback, useEffect, useState } from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'

export function PasswordStrengthPopover({
  open,
  password,
  setPasswordStrength,
  children,
}) {
  const [hasMinLength, setHasMinLength] = useState(false)
  const [hasEnoughCharacterTypes, setHasEnoughCharacterTypes] = useState(false)
  const [hasLowerCaseLetter, setHasLowerCaseLetter] = useState(false)
  const [hasUpperCaseLetter, setHasUpperCaseLetter] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false)

  const checkStrength = useCallback(() => {
    const patterns = [
      /[a-z]/,
      /[A-Z]/,
      /[0-9]/,
      // eslint-disable-next-line no-useless-escape
      /[ `!@#$%^&*()_+\-=\[\]{}':'\\|,.<>\/?~]/,
    ]

    let matches = 0

    if (patterns[0].test(password)) {
      matches++
      setHasLowerCaseLetter(true)
    } else {
      setHasLowerCaseLetter(false)
    }

    if (patterns[1].test(password)) {
      matches++
      setHasUpperCaseLetter(true)
    } else {
      setHasUpperCaseLetter(false)
    }

    if (patterns[2].test(password)) {
      matches++
      setHasNumber(true)
    } else {
      setHasNumber(false)
    }

    if (patterns[3].test(password)) {
      matches++
      setHasSpecialCharacter(true)
    } else {
      setHasSpecialCharacter(false)
    }

    if (password.length >= 8) {
      setHasMinLength(true)
    } else {
      setHasMinLength(false)
    }

    if (matches >= 3) {
      setHasEnoughCharacterTypes(true)
    } else {
      setHasEnoughCharacterTypes(false)
    }

    if (password.length >= 8 && matches >= 3) {
      setPasswordStrength('STRONG_PASSWORD')
    } else {
      setPasswordStrength('WEAK_PASSWORD')
    }
  }, [password, setPasswordStrength])

  useEffect(() => {
    checkStrength()
  }, [checkStrength])

  return (
    <div className='relative'>
      <Transition
        show={open}
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <div className='absolute top-[60px] w-full sm:max-w-sm bg-white text-black cursor-default p-6 rounded-md text-sm leading-6'>
          <ul>
            <li>
              {hasMinLength ? (
                <CheckCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-green-500' />
              ) : (
                <XCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-red-500' />
              )}
              <span
                className={`ml-1 align-middle ${
                  hasMinLength ? 'text-green-700' : 'text-red-700'
                }`}
              >
                At least 8 characters in length
              </span>
            </li>
            <li>
              {hasEnoughCharacterTypes ? (
                <CheckCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-green-500' />
              ) : (
                <XCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-red-500' />
              )}
              <span
                className={`ml-1 align-middle ${
                  hasEnoughCharacterTypes ? 'text-green-700' : 'text-red-700'
                }`}
              >
                Contain at least 3 of the following 4 types of characters:
              </span>
              <ul className='ml-4'>
                <li>
                  {hasLowerCaseLetter ? (
                    <CheckCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-green-500' />
                  ) : (
                    <XCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-gray-300' />
                  )}
                  <span
                    className={`ml-1 align-middle ${
                      hasLowerCaseLetter ? 'text-green-700' : ''
                    }`}
                  >
                    Lower case letters (a-z)
                  </span>
                </li>
                <li>
                  {hasUpperCaseLetter ? (
                    <CheckCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-green-500' />
                  ) : (
                    <XCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-gray-300' />
                  )}
                  <span
                    className={`ml-1 align-middle ${
                      hasUpperCaseLetter ? 'text-green-700' : ''
                    }`}
                  >
                    Upper case letters (A-Z)
                  </span>
                </li>
                <li>
                  {hasNumber ? (
                    <CheckCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-green-500' />
                  ) : (
                    <XCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-gray-300' />
                  )}
                  <span
                    className={`ml-1 align-middle ${
                      hasNumber ? 'text-green-700' : ''
                    }`}
                  >
                    Numbers (i.e. 0-9)
                  </span>
                </li>
                <li>
                  {hasSpecialCharacter ? (
                    <CheckCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-green-500' />
                  ) : (
                    <XCircleIcon className='inline-block h-5 w-5 min-w-[1.25rem] text-gray-300' />
                  )}
                  <span
                    className={`ml-1 align-middle ${
                      hasSpecialCharacter ? 'text-green-700' : ''
                    }`}
                  >
                    Special characters (e.g. !@#$%^&*)
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Transition>
      {children}
    </div>
  )
}
