function Button({ btnText, onClick, ...args }) {
  return (
    <button
      className='flex justify-center items-center min-w-[200px] w-fit leading-5 text-xl rounded-full bg-primary hover:bg-primary/90 active:bg-primary/40 px-4 py-3 font-medium text-black shadow-sm duration-300'
      onClick={onClick}
      {...args}
    >
      {btnText}
    </button>
  )
}

export default Button
