import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Team from '../components/about/Team'
import VisitStudio from '../components/about/VisitStudio'
import WorkWithUs from '../components/about/WorkWithUs'
import CommunitySection from '../components/about/CommunitySection'
import { cn } from '../utils'
import { useUserAccountContext } from '../context/userContext'

const aboutLogo = '/website/about/about_logo.png'

export const About = () => {
  const { featureToggles } = useUserAccountContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  return (
    <div className='bg-black'>
      <Navbar />

      <main
        className={cn(
          'flex flex-col mx-auto flex-1 max-w-[1440px] w-full md:px-[3.5%] px-4 py-32 gap-24 pt-28',
          { 'pt-40': isFeedbackBannerShown },
        )}
      >
        {/* SECTION 1 - TOP SECTION */}
        <div className='relative flex flex-col sm:flex-row gap-14'>
          <img
            className='flex h-fit sm:w-5/12 w-full max-h-[568px] flex-1 sm:ml-[-3.5%] ml-0'
            src={aboutLogo}
            alt='Kaiber About'
          />
          <div className='flex flex-col justify-center w-full gap-8 sm:w-7/12'>
            <h1 className='text-4xl font-semibold text-white lg:text-6xl sm:text-5xl'>
              About Kaiber
            </h1>
            <p className='max-w-2xl text-lg lg:text-2xl sm:text-xl text-white/80'>
              Made for artists, by artists, Kaiber is an AI creative lab on a
              mission to empower people everywhere to discover the artist
              within. Creatives at all stages of their career can use Kaiber to
              tell stories in a whole new way through the platform's free trial,
              subscriber, and studio offerings. Dive deep into the world of
              generative art and be a part of the creative revolution.
              Experience, explore, and express.
            </p>
            <div className='flex flex-wrap gap-5 sm:gap-20 sm:flex-nowrap'>
              <Link to='/studio'>
                <span className='font-semibold duration-300 lg:text-2xl sm:text-xl text-primary hover:text-primary/90 active:text-primary/40 '>
                  Visit Kaiber Studio
                </span>
              </Link>
              <Link to='/create'>
                <span className='font-semibold duration-300 lg:text-2xl sm:text-xl text-primary hover:text-primary/90 active:text-primary/40 '>
                  Explore Kaiber App
                </span>
              </Link>
            </div>
          </div>
        </div>

        {/* SECTION 2 - TEAM*/}
        <Team />

        {/* SECTION 3 - STUDIO SECTION*/}
        <VisitStudio />

        {/* SECTION 4 - FEATURED COMMUNITY ART*/}
        <CommunitySection />

        {/* SECTION 4 - WORK WITH US */}
        <WorkWithUs />
      </main>

      <Footer />
    </div>
  )
}
