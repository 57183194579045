import { SVGProps } from '@/types'

export const LibraryIcon = ({ size = 24, ...props }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M3.75 5.74998V18.25M7.75 4.74998V19.25M20.25 6.87241V17.1276C20.25 18.0518 19.6168 18.8556 18.7182 19.072L14.2182 20.1556C12.9602 20.4586 11.75 19.5052 11.75 18.2112V5.78877C11.75 4.49477 12.9602 3.54141 14.2182 3.84435L18.7182 4.92799C19.6168 5.14436 20.25 5.94821 20.25 6.87241Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
