import { useReactFlow, useStoreApi } from '@xyflow/react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { HOTKEYS, HTML_TAGS } from '../../constants'
import { getHotKey } from '../../utils/keyboardUtils'

type HotkeyHandler = (event: KeyboardEvent) => void

interface HotkeyConfig {
  [key: string]: {
    handler: HotkeyHandler
    ignoreActiveInput?: boolean // Renamed from overrideInput
  }
}

interface KeyState {
  [key: string]: boolean
}

export const useHotkeys = (customHandlers: HotkeyConfig = {}) => {
  const store = useStoreApi()
  const { fitView } = useReactFlow()
  const [keyState, setKeyState] = useState<KeyState>({})

  const isInputLikeElement = (element: Element | null): boolean => {
    if (!element) return false
    const tagName = element.tagName.toLowerCase()
    return tagName === HTML_TAGS.input || tagName === HTML_TAGS.textarea
  }

  const globalHandlers = useMemo<HotkeyConfig>(
    () => ({
      [HOTKEYS.SELECT_ALL]: {
        handler: (event: KeyboardEvent) => {
          const nodes = store.getState().nodes
          event.preventDefault()
          store.getState().addSelectedNodes(nodes.map((node) => node.id))
        },
      },
      [HOTKEYS.FIT_VIEW]: {
        handler: (event: KeyboardEvent) => {
          event.preventDefault()
          fitView({ duration: 1000, padding: 0.5 })
        },
      },
    }),
    [store],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const hotkey = getHotKey(event)
      setKeyState((prevState) => ({ ...prevState, [event.key]: true }))

      const customHandler = customHandlers[hotkey]
      const globalHandler = globalHandlers[hotkey]

      if (customHandler) {
        if (
          customHandler.ignoreActiveInput ||
          !isInputLikeElement(document.activeElement)
        ) {
          customHandler.handler(event)
        }
      } else if (globalHandler) {
        if (!isInputLikeElement(document.activeElement)) {
          globalHandler.handler(event)
        }
      }
    },
    [customHandlers, globalHandlers],
  )

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    setKeyState((prevState) => ({ ...prevState, [event.key]: false }))
  }, [])

  const isHotkeyPressed = useCallback(
    (keyList: string[]) => keyList.some((key) => !!keyState[key]),
    [keyState],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

  return {
    handleKeyDown,
    isHotkeyPressed,
  }
}
