import { TagFilter } from '../types'
import { useGlobalStore } from './globalStore'
import { isTempMediaId } from '../utils/mediaUtils'

export interface MyLibraryStoreState {
  myLibraryMediaIds: string[]
  myLibraryTagFilters: TagFilter[]
  // In the future, we may add My Library's other UI states here
  // to allow other parts of the webpage to modify them.
}

/**
 * MyLibraryStore conceptually represents the things being displayed in MyLibrary.
 * Maintaince Note: This store must be kept as a view into the MediaStore,
 *  which is the only source of truth for media objects.
 */
class MyLibraryStore {
  useStore = useGlobalStore

  /** Use a React Hook to subscribe to `myLibraryMediaIds`. */
  public useMediaIds() {
    return this.useStore((state) => state.myLibraryMediaIds)
  }

  /**
   * @param updater  Either an array or a function, similar to React's setState().
   *                 Warning: like React, you must not modify the input array after you pass it here.
   */
  public setMediaIds(updater: ((oldState: string[]) => string[]) | string[]) {
    const newMediaIds =
      typeof updater === 'function'
        ? updater(this.useStore.getState().myLibraryMediaIds)
        : updater
    this.useStore.setState({ myLibraryMediaIds: newMediaIds })
  }

  /** Use a React Hook to subscribe to `myLibraryTagFilters`. */
  public useTagFilters(): TagFilter[] {
    return this.useStore((state) => state.myLibraryTagFilters)
  }

  /**
   * @param updater  Either an array or a function, similar to React's setState().
   *                 Warning: like React, you must not modify the input array after you pass it here.
   */
  public setTagFilters(
    updater: ((oldState: TagFilter[]) => TagFilter[]) | TagFilter[],
  ) {
    const newTagFilters =
      typeof updater === 'function'
        ? updater(this.useStore.getState().myLibraryTagFilters)
        : updater
    this.useStore.setState({ myLibraryTagFilters: newTagFilters })
  }

  /**
   * Prepends the `mediaId` to MyLibrary so that the media is displayed.
   *
   * Caveat: This function does nothing if:
   * - The media is already in MyLibrary.
   * - Any filter in MyLibrary is selected. This is becuase the media to add might not match
   *   the filter. In the future, we may add complex logic to handle this case, but
   *   it will be complex because filters can be a combination of multiple tags, keywords,
   *   and thus we need to basically mimic Backend and MongoDB's filter logic here.
   */
  public prependMediaId(mediaId: string) {
    if (this.useStore.getState().myLibraryMediaIds.includes(mediaId)) return
    if (this.useStore.getState().myLibraryTagFilters.some((f) => f.selected))
      return
    if (isTempMediaId(mediaId)) {
      // because we haven't implemented updating this store's `mediaIds` state after backend returns the real media Id.
      throw new Error('Temp media cannot be added: ' + mediaId)
    }

    this.setMediaIds((curIds) => [mediaId, ...curIds])
  }
}

export const myLibraryStore = new MyLibraryStore()
