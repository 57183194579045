export const MIXPANEL_EVENTS = {
  VIDEO_DOWNLOAD: 'video_download',
  VIDEO_SHARE: 'video_share',
  CLICK_RELATED_VIDEO: 'click_related_video',
  CLICK_COPY_PROMPT: 'click_copy_prompt',
  CLICK_OPEN_IN_PROMPT_EDITOR: 'click_open_in_prompt_editor',
  CLICK_CHANGE_PASSWORD: 'click_change_password',
  CLICK_REFERRED_BY: 'click_referred_by',
  CLICK_REFER: 'click_refer',
  SUBSCRIPTION_FLOW_STARTED: 'subscription_flow_started',
  USER_NPS_SURVEY: 'user_nps_survey',
  USER_OFFBOARDING: 'user_offboarding',
  PREVIEW_FRAME_DOWNLOAD: 'preview_frame_download',
  CREDITS_PURCHASE_FLOW_STARTED: 'credits_purchase_flow_started',
  PROFILE_SHARE: 'profile_share',
}

export const trackEvent = (mixpanel, currentUser, eventName, data) => {
  const eventData = {
    platform: 'web',
    environment: process.env.NODE_ENV,
    ...data,
    ...(currentUser && {
      distinct_id: currentUser.distinctId,
      user_id: currentUser.uuid,
      user_subscription: currentUser.subscriptionTierDescription
        ? currentUser.subscriptionTierDescription
        : 'Discovery',
      user_is_trial: currentUser.subscriptionStatus === 'trialing',
    }),
  }

  mixpanel.track(eventName, eventData)
}
