import { useCallback, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share'
import { EmailIcon, FacebookIcon, TwitterIcon } from 'react-share'
import { useMixpanel } from 'react-mixpanel-browser'
import Lottie from 'lottie-react'
import { toast } from 'react-toastify'

import tiktokIcon from '../images/icons/tiktok-icon.png'
import shareIcon from '../images/Share.svg'
import { getProxiedR2FileUrl } from '../utils/fileUtils'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'
import { useUserAccountContext } from '../context/userContext'
import EmailVerificationButton from './EmailVerificationButton'
import http from '../services/HttpService'
import { Alert } from '../components/Alert'
import LoadingWhite from '../images/lottie/loaderWhite.json'

export default function ShareOptions({
  isMobile,
  shareUrl,
  memory,
  title,
  bodyText,
  handleModalClose,
  requireAuth = false,
}) {
  const [didCopy, setDidCopy] = useState(false)
  const [shareLoading, setShareLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [showDescriptionInput, setShowDescriptionInput] = useState(false)
  const [description, setDescription] = useState('')
  const mixpanel = useMixpanel()
  const { currentUser, isAuthenticated, featureToggles } =
    useUserAccountContext()
  const enableTikTokShare = featureToggles['enable-tiktok-sharing']

  const handleCopy = () => {
    navigator.clipboard.writeText(shareUrl)
    setDidCopy(true)
    setTimeout(() => {
      setDidCopy(false)
    }, 2000)

    mixpanel.track('Copy Share Link', { source: 'Share Options' })

    trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.VIDEO_SHARE, {
      memory_id: memory.memory_id,
      video_type: memory.settingsUsed.videoType,
      video_is_upscaled: memory.isUpscaled,
      video_version: memory.settingsUsed.version,
      video_shared_to: 'copy_link',
      video_share_link_copied: didCopy,
      video_is_watermarked: memory.isWatermarked,
      page_url: window.location.origin,
      suggested_subject:
        memory.settingsUsed?.scenes[0]?.inversionPrompt ?? 'null', // Null values that are forwarded from the frontend, become "null" in mixpanel, we intentionally added this so all be "null" since null renders as undefined in Mixpanel.
      // curated_subject //  to implement in the future https://height.app/M9jzUFTFNu/T-6200
      curated_style: memory.settingsUsed?.scenes[0]?.curatedStyle ?? 'null', // Null values that are forwarded from the frontend, become "null" in mixpanel, we intentionally added this so all be "null" since null renders as undefined in Mixpanel
      exclusive_style: memory.settingsUsed?.scenes[0]?.exclusiveStyle ?? 'null',
      stableDiffusionCheckpoint:
        memory.settingsUsed?.stableDiffusionCheckpoint ?? 'null',
      // exclusive_audio:, //  to implement in the future https://height.app/M9jzUFTFNu/T-6200
    })
  }

  const handleShare = async () => {
    setShowAlert(false)
    setAlertMessage('')
    if (memory != null) {
      // use proxy server to get around CORS errors with R2
      const response = await fetch(getProxiedR2FileUrl(memory.mediaUrl, true))
      const blob = await response.blob()
      const data = {
        files: [
          new File([blob], 'video.mp4', {
            type: 'video/mp4',
          }),
        ],
        title: 'Video',
        text: 'video',
      }

      try {
        if (navigator.share && !navigator.canShare(data)) {
          setShowAlert(true)
          setAlertMessage(`Your system doesn't support sharing these files.`)
        } else if (navigator.share) {
          await navigator
            .share(data)
            .then(() => {
              toast.success(SuccessInstagramShare)
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          setShowAlert(true)
          setAlertMessage('Web Share API is not supported in your browser.')
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const postToTiktok = useCallback(async () => {
    const description = window.sessionStorage.getItem('SHARE_DESCRIPTION')
    const data = new FormData()
    if (memory != null) {
      // use proxy server to get around CORS errors with R2
      const response = await fetch(getProxiedR2FileUrl(memory.mediaUrl, true))
      const blob = await response.blob()
      const fileData = new File([blob], 'video.mp4', {
        type: 'video/mp4',
      })

      data.append('video', fileData)
    }
    data.append('description', description)
    const response = await http.post('/api/tiktok/post-video', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (description) {
      window.sessionStorage.removeItem('SHARE_DESCRIPTION')
    }

    return response
  }, [memory])

  const tiktokLoginAuth = async () => {
    window.sessionStorage.setItem('SHARE_DESCRIPTION', description)
    setShareLoading(true)
    setShowDescriptionInput(false)
    const response = await http.get('/api/tiktok/auth', {
      params: {
        redirectUrl: window.location.href,
      },
    })

    if (response && response.data) {
      if (response.data.authorized) {
        const postResponse = await postToTiktok()

        if (!postResponse.data.success) {
          setShowAlert(true)
          setAlertMessage(
            postResponse.data.message || 'Error sharing your video',
          )
        } else {
          toast.success(SuccessTiktokShare)
          if (handleModalClose) {
            handleModalClose(false)
          }
        }
        setShareLoading(false)
      } else {
        window.location.href = response.data.url
      }
    }
  }

  const toggleDescription = () => {
    setShowDescriptionInput(!showDescriptionInput)
  }

  const handleChange = (event) => {
    setDescription(event.target.value)
  }

  const SuccessTiktokShare = () => (
    <span>
      🎉 Successfully posted on{' '}
      <a href='https://www.tiktok.com/' target='_blank' rel='noreferrer'>
        TikTok
      </a>
      !
    </span>
  )

  const SuccessInstagramShare = () => (
    <span>
      🎉 Draft saved! Go to{' '}
      <a href='https://www.instagram.com/' target='_blank' rel='noreferrer'>
        Instagram
      </a>{' '}
      to post
    </span>
  )

  useEffect(() => {
    const autoShare = async () => {
      const query = new URLSearchParams(window.location.search)
      const postTiktok = query.get('post_tiktok')
      if (postTiktok === true || postTiktok === 'true') {
        setShareLoading(true)
        setShowDescriptionInput(false)
        window.history.replaceState(null, '', window.location.pathname)
        const postResponse = await postToTiktok()

        if (!postResponse.data.success) {
          setShowAlert(true)
          setAlertMessage(
            postResponse.data.message || 'Error sharing your video',
          )
        } else {
          toast.success(SuccessTiktokShare)
        }
        setShareLoading(false)
      }
    }

    autoShare()
  }, [postToTiktok])

  return (
    <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-6 w-full'>
      <p className='text-2xl sm:text-5xl text-tertiary'>{title}</p>
      {requireAuth &&
        isAuthenticated &&
        !currentUser?.emailVerifiedAt &&
        currentUser?.subscriptionType !== 'Standard' && (
          <EmailVerificationButton text='Your email must be verified to earn credits.' />
        )}
      <p className='text-tertiary/80'>{bodyText}</p>
      {shareLoading ? (
        <div className='flex flex-col items-center space-y-4'>
          <p className='text-tertiary/80'>
            Sharing your video, don't close the popup
          </p>
          <Lottie
            animationData={LoadingWhite}
            loop={true}
            className='w-16 h-16'
          />
        </div>
      ) : (isAuthenticated || !requireAuth) && !shareLoading ? (
        showDescriptionInput ? (
          <div className='relative mt-1 items-center'>
            <label className='text-secondary text-lg' htmlFor='description'>
              Enter a description to share your video in TikTok
            </label>
            <textarea
              type='text'
              rows={2}
              name='description'
              id='description'
              defaultValue={''}
              maxLength={150}
              onChange={handleChange}
              className='resize-none block mt-4 w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 pl-3 bg-[#545854]/25 text-white'
            />
            <div className='flex mx-auto'>
              <button
                className='mt-4 mr-2 inline-flex items-center justify-center px-3 py-2 font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40'
                onClick={tiktokLoginAuth}
              >
                Confirm
              </button>
              <button
                onClick={() => {
                  setDescription('')
                  toggleDescription()
                }}
                className='mt-4 ml-2 inline-flex items-center justify-center px-3 py-2 font-medium leading-5 text-secondary duration-300 border rounded-full buttonPrompt border-primary hover:bg-primary hover:text-black w-fit '
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className='flex gap-2'>
              <EmailShareButton
                onClick={(_, link) => {
                  window.location.href = link

                  setShowDescriptionInput(false)
                  mixpanel.track('Clicked Share to Email', {
                    source: 'Share Options',
                  })

                  trackEvent(
                    mixpanel,
                    currentUser,
                    MIXPANEL_EVENTS.VIDEO_SHARE,
                    {
                      memory_id: memory.memory_id,
                      video_type: memory.settingsUsed.videoType,
                      video_is_upscaled: memory.isUpscaled,
                      video_version: memory.settingsUsed.version,
                      video_shared_to: 'email',
                      video_share_link_copied: didCopy,
                      video_is_watermarked: memory.isWatermarked,
                      page_url: window.location.origin,
                      suggested_subject:
                        memory.settingsUsed?.scenes[0]?.inversionPrompt ??
                        'null',
                      // curated_subject // to add in the future
                      curated_style:
                        memory.settingsUsed?.scenes[0]?.curatedStyle ?? 'null',
                      exclusive_style:
                        memory.settingsUsed?.scenes[0]?.exclusiveStyle ??
                        'null',
                      stableDiffusionCheckpoint:
                        memory.settingsUsed?.stableDiffusionCheckpoint ??
                        'null',
                      // exclusive_audio:, // to add in the future
                    },
                  )
                }}
                url={shareUrl}
                subject={'Check out this video I made at https://kaiber.ai!'}
                body={'Check out this video I made at https://kaiber.ai!'}
              >
                <EmailIcon size={48} round />
              </EmailShareButton>
              <FacebookShareButton
                onClick={() => {
                  setShowDescriptionInput(false)
                  mixpanel.track('Clicked Share to Facebook', {
                    source: 'Share Options',
                  })

                  trackEvent(
                    mixpanel,
                    currentUser,
                    MIXPANEL_EVENTS.VIDEO_SHARE,
                    {
                      memory_id: memory.memory_id,
                      video_type: memory.settingsUsed.videoType,
                      video_is_upscaled: memory.isUpscaled,
                      video_version: memory.settingsUsed.version,
                      video_shared_to: 'facebook',
                      video_share_link_copied: didCopy,
                      video_is_watermarked: memory.isWatermarked,
                      page_url: window.location.origin,
                      suggested_subject:
                        memory.settingsUsed?.scenes[0]?.inversionPrompt ??
                        'null',
                      // curated_subject // to add in the future
                      curated_style:
                        memory.settingsUsed?.scenes[0]?.curatedStyle ?? 'null',
                      exclusive_style:
                        memory.settingsUsed?.scenes[0]?.exclusiveStyle ??
                        'null',
                      stableDiffusionCheckpoint:
                        memory.settingsUsed?.stableDiffusionCheckpoint ??
                        'null',
                      // exclusive_audio:, // to add in the future
                    },
                  )
                }}
                url={shareUrl}
                quote={'Check out this video I made at https://kaiber.ai!'}
                hashtag='#kaibervideo'
              >
                <FacebookIcon size={48} round />
              </FacebookShareButton>
              <TwitterShareButton
                onClick={() => {
                  setShowDescriptionInput(false)
                  mixpanel.track('Clicked Share to Twitter', {
                    source: 'Share Options',
                  })
                  trackEvent(
                    mixpanel,
                    currentUser,
                    MIXPANEL_EVENTS.VIDEO_SHARE,
                    {
                      memory_id: memory.memory_id,
                      video_type: memory.settingsUsed.videoType,
                      video_is_upscaled: memory.isUpscaled,
                      video_version: memory.settingsUsed.version,
                      video_shared_to: 'twitter',
                      video_share_link_copied: didCopy,
                      video_is_watermarked: memory.isWatermarked,
                      page_url: window.location.origin,
                      suggested_subject:
                        memory.settingsUsed?.scenes[0]?.inversionPrompt ??
                        'null',
                      // curated_subject // to add in the future
                      curated_style:
                        memory.settingsUsed?.scenes[0]?.curatedStyle ?? 'null',
                      exclusive_style:
                        memory.settingsUsed?.scenes[0]?.exclusiveStyle ??
                        'null',
                      stableDiffusionCheckpoint:
                        memory.settingsUsed?.stableDiffusionCheckpoint ??
                        'null',
                      // exclusive_audio:, // to add in the future
                    },
                  )
                }}
                url={shareUrl}
                title={
                  'Check out this video I made at https://kaiber.ai! @KaiberAI'
                }
              >
                <TwitterIcon size={48} round />
              </TwitterShareButton>
              {isMobile ? (
                <button className='text-secondary' onClick={handleShare}>
                  <div className='flex items-center justify-center w-[48px] h-[48px]'>
                    <img src={shareIcon} alt='Social Icon' />
                  </div>
                </button>
              ) : null}
              {enableTikTokShare ? (
                <button className='text-secondary' onClick={toggleDescription}>
                  <div className='flex items-center justify-center w-[48px] h-[48px]'>
                    <img src={tiktokIcon} alt='Social Icon' />
                  </div>
                </button>
              ) : null}
            </div>
            <div className='relative mt-1 flex items-center'>
              <input
                type='text'
                name='search'
                id='search'
                defaultValue={shareUrl}
                className='block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 pl-3 bg-[#545854]/25 text-white'
              />
              <div className='absolute inset-y-0 right-0 flex py-1.5 pr-1.5'>
                <kbd
                  className='inline-flex items-center rounded-full radial-gradient button-shadow px-2 font-sans text-sm font-medium text-black cursor-pointer'
                  onClick={handleCopy}
                >
                  {didCopy ? 'Copied!' : 'Copy'}
                </kbd>
              </div>
            </div>
            {showAlert && (
              <div className='mb-4'>
                <Alert
                  open={showAlert}
                  setOpen={setShowAlert}
                  message={alertMessage}
                  type='alert'
                />
              </div>
            )}
          </>
        )
      ) : (
        <Link
          to={`/login?redirect=${window.location.pathname}`}
          className='w-full block text-center rounded-full border bg-primary border-primary p-4 text-sm font-bold text-gray-900 shadow-sm uppercase'
        >
          Login
        </Link>
      )}
    </div>
  )
}
