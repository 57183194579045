import { Media, Status, Video } from '../types'
import { useGlobalStore } from './globalStore'
import { MediaPoller } from '../utils/MediaPoller'
import { isTempMedia, signedUrlExpired } from '../utils/mediaUtils'

export interface MediaStoreState {
  medias: { [mediaId: string]: Media }
}

/**
 * MediaStore is conceptually a store for Media objects. It's the single
 * source of truth of Media objects on the UI.
 *
 * A media has 2 conceptual states in this store:
 *  "Temporary": Backend has not returned the actual media ID yet. This is typically
 *     when an image is generating, a file is uploading. This state is defined by
 *     a (FE-generated) media ID starting with "temp-" with some random number suffix.
 *  "Mature": Backend has returned the actual media ID to FE.
 */
class MediaStore {
  poller = new MediaPoller()
  useStore = useGlobalStore

  constructor(poller: MediaPoller) {
    this.poller = poller
  }

  /**
   * Inserts or updates a Media into the MediaStore.
   * This also has side effect (due to Zustand) that this notifies all subscribers and Hooks that subscribe to this media.
   * @param autoPoll If true, calls @link{MediaPoller#startPolling} to start async polling the media
   *    if certain conditions are met (see that method for the conditions)
   */
  public setMedia(media: Media, autoPoll = true) {
    if (!media.mediaId)
      throw new Error('mediaId missing: ' + JSON.stringify(media))

    // Make a copy because otherwise if the caller mutates the `media` later,
    // it also accidentally mutates the media in the store which is assumed to be immutable.
    media = { ...media }
    const setFn = (newMedia: Media) =>
      this.useStore.setState((state) => {
        // TODO(ENG-2459): remove this hack once fixed.
        // If the media is being polled (Status.Pending) and already has a valid thumbnailSource,
        // don't update it. This is to prevent the node thumbnail from flickering,
        // because each time the media is polled, backend updates all signedURLs, which
        // makes this node updates its thumbnail.
        const prevMedia = state.medias[newMedia.mediaId]
        if (prevMedia) {
          const prevThumbnailSource = (prevMedia as Video).thumbnailSource
          if (
            newMedia.status === Status.Pending &&
            prevMedia.status === Status.Pending &&
            prevThumbnailSource &&
            !signedUrlExpired(prevThumbnailSource)
          ) {
            newMedia = { ...newMedia, thumbnailSource: prevThumbnailSource }
          }
        }

        state.medias[newMedia.mediaId] = newMedia
      })
    setFn(media)

    if (autoPoll) {
      this.poller.startPolling(media, setFn)
    }
  }

  /**
   * @param callback callback to invoke when the media is updated.
   */
  public subscribeToMediaId(
    mediaId: string,
    callback: (media: Media, prevMedia: Media) => void,
  ) {
    const unsubscribe = this.useStore.subscribe(
      (state) => state.medias[mediaId],
      callback,
      {
        // Ensures the subscriber gets the current state immediately,
        // rather than when the first change happens.
        fireImmediately: true,
      },
    )
    return unsubscribe
  }

  /** Use a React Hook to subscribe to a Media in this store. */
  public useMedia(mediaId: string) {
    return this.useStore((state) => state.medias[mediaId])
  }

  /**
   * Signals that a temp media has "matured" (i.e. the backend has returned the actual media ID for the temp media).
   *
   * This const finds =  and updates tempMedia.mediaId in the store to the actual media's mediaId,
   * causing the `MediaNode`s or the asset in MyMaterials which subscribes to `tempMedia.mediaId`
   * to re-render and re-subscribe to the new media ID.
   * @param tempMedia the original temporary media
   * @param media the matured media
   * @throws if the given media is not a temporary media. To avoid hidden bugs, this const enforces =  you to only call it with a temporary media.
   */
  public signalTempMediaMatured(tempMedia: Media, media: Media) {
    if (!isTempMedia(tempMedia)) {
      throw new Error(
        'Expected a temp media, but got: ' + JSON.stringify(tempMedia),
      )
    }
    this.setMedia(media)
    // All the MediaNodes which subscribes to `tempMedia.mediaId` will
    // re-render and re-subscribe to the new media ID due to the following line.
    this.useStore.setState((state) => {
      state.medias[tempMedia.mediaId].mediaId = media.mediaId
    })
  }

  /** Finds the first media whose `source` is equal to the given `source`. */
  public findMediaBySource = (source: string): Media | null => {
    for (const [, media] of Object.entries(this.useStore.getState().medias)) {
      if (media.source === source) {
        return media
      }
    }
    return null
  }
}

export const mediaStore = new MediaStore(new MediaPoller())
