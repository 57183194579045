import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useThemeContext } from '../../../context'
import { RefreshIcon } from '../../../images/icons/RefreshIcon'
import { getVersionIds } from '../../../services/CanvasService'
import { CanvasVersion } from '../../../types'
import { Spinner } from '../../loading'
import { Modal } from '../../Modal'

interface VersionDialogProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  loadVersion: (timestamp?: number) => Promise<void>
  resetCanvas: () => void
}

export const VersionDialog = ({
  isOpen,
  setIsOpen,
  loadVersion,
  resetCanvas,
}: VersionDialogProps) => {
  const { colors } = useThemeContext()
  const { canvasId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [versions, setVersions] = useState<CanvasVersion[]>([])

  const handleReset = () => {
    setIsOpen(false)
    resetCanvas()
  }

  const handleRestore = (timestamp: number) => {
    setIsOpen(false)
    loadVersion(timestamp)
  }

  useEffect(() => {
    // let's discuss with the design team whether `history` makes more sense than `versions`.
    const fetchHistory = async () => {
      setIsLoading(true)
      try {
        const data = await getVersionIds(canvasId)
        setVersions(data)
      } catch (error) {
        console.error('Error loading versions:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (isOpen && canvasId) {
      fetchHistory()
    }
  }, [isOpen, canvasId])

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      handleClose={() => {}}
      containerClassName={`w-80 ${colors.background.neutral}`}
      closeIconClassName={`${colors.text.default} ${colors.border.inverse}`}
    >
      <div className='flex flex-col gap-4 w-full'>
        <h3 className={`text-center ${colors.text.default}`}>History</h3>
        {isLoading ? (
          <Spinner
            containerClassName='bg-transparent w-auto h-auto'
            iconClassName={`w-12 h-12`}
          />
        ) : (
          <>
            <ul className='list-none w-full'>
              {versions.map((version) => (
                <li
                  key={version.timestamp}
                  className={`w-full px-3 py-2 rounded-2xl cursor-pointer flex justify-between items-center hover:${colors.text.brand} ${colors.text.default}`}
                >
                  <span>
                    {format(version.timestamp, 'yyyy-MM-dd HH:mm:ss')}
                  </span>
                  <button
                    className={`rounded-2xl px-2 py-1 ${colors.background.brand} text-sm ${colors.text.inverse}`}
                    onClick={() => handleRestore(version.timestamp)}
                  >
                    Restore
                  </button>
                </li>
              ))}
            </ul>
            <button
              className='rounded-2xl px-2 py-2 bg-k2-red-500 hover:bg-k2-red-400 text-white text-sm flex items-center justify-center gap-2'
              onClick={handleReset}
            >
              <RefreshIcon className='text-white' />
              Reset Current Canvas
            </button>
          </>
        )}
      </div>
    </Modal>
  )
}
