import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

export function useReCaptcha() {
  const [token, setToken] = useState(null)
  const [timestamp, setTimestamp] = useState(0)

  const reCaptchaRef = useRef()

  const now = () => {
    return new Date().getTime()
  }

  const needNewToken = () => {
    const refreshTime = 90 * 1000

    if (timestamp > 0 && now() > timestamp + refreshTime) {
      return true
    }

    return false
  }

  const removeChallengeCloseListener = () => {
    return new Promise(async (resolve) => {
      const target = document.querySelector(
        `iframe[src*='/recaptcha/api2/bframe']`,
      )?.parentNode?.previousSibling

      if (target) {
        target.replaceWith(target.cloneNode())
        resolve()
      } else {
        await new Promise((resolve) => setTimeout(resolve, 100))
        await removeChallengeCloseListener()
        resolve()
      }
    })
  }

  return {
    getToken: async () => {
      if (process.env.REACT_APP_RECAPTCHA_ENABLED) {
        const _needNewToken = needNewToken()

        if (!token || _needNewToken) {
          if (_needNewToken) {
            reCaptchaRef.current.reset()
          }

          await removeChallengeCloseListener()

          const _token = await reCaptchaRef.current.executeAsync()

          setToken(_token)
          setTimestamp(now())

          return _token
        }

        return token
      }

      return 'norecaptcha'
    },
    element: process.env.REACT_APP_RECAPTCHA_ENABLED ? (
      <ReCAPTCHA
        ref={reCaptchaRef}
        size='invisible'
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
    ) : null,
  }
}
