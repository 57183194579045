import { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'

import { MakePublicButton } from './MakePublicButton'
import { DownloadButton } from './DownloadButton'
import { PhraseFilter } from './PhraseFilter'
import { DeleteButton } from './DeleteButton'
import { InfoButton } from './InfoButton'
import { ExpandPromptButton } from './ExpandPromptButton'
import { CopyPromptButton } from './CopyPromptButton'
import { UpScaleButton } from './UpScaleButton'

export const VideoOverlay = forwardRef(
  (
    {
      showOverlay,
      isVideoHover,
      canvas,
      handlePhraseFilter,
      isDashboard,
      isSubscribed,
      onDelete,
      onInfo,
      onMakePublic,
      isMakingPublic,
      showMakePublic,
      isDownloading,
      onDownload,
      downloadVideo,
      downloadPercent,
      isAdmin,
      isUpscaled,
    },
    ref,
  ) => {
    // TODO: Figure out how this is still working without prompt hover state being set
    // eslint-disable-next-line no-unused-vars
    const [isPromptHover, setIsPromptHover] = useState(false)
    const [expandLineClampPartial, setExpandLineClampPartial] = useState(false)
    const [expandPromptFull, setExpandPromptFull] = useState(false)

    useEffect(() => {
      // if not prompt hover, reset expand prompt full
      if (!isPromptHover) setExpandPromptFull(false)

      // prompt expand delay, otherwise add'l lines of text will flash before the UI transition completes
      const timeout = setTimeout(
        () => setExpandLineClampPartial(isPromptHover),
        100,
      )
      return () => {
        clearTimeout(timeout)
      }
    }, [isPromptHover])

    const handleExpandPromptClick = () => {
      setExpandPromptFull(!expandPromptFull)
    }

    return (
      <>
        {/* ADMIN DELETED */}
        {isAdmin && canvas.adminDeleted && (
          <div className='bg-gray-800 bg-opacity-50 absolute w-full h-full flex flex-col justify-center items-center text-center top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
            <p className='text-center text-red-700 uppercase font-bold'>
              Admin Deleted
            </p>
          </div>
        )}
        {/* HIGHLIGHT UPSCALE */}
        {isDashboard &&
          !isAdmin &&
          isSubscribed &&
          (canvas.status === 'done' || typeof canvas.status === 'undefined') &&
          isUpscaled && (
            <div
              className={classNames(
                'absolute top-2 left-2 transition duration-300 ease-in-out hover:opacity-100 opacity-100',
              )}
            >
              <UpScaleButton canvas={canvas} />
            </div>
          )}

        {/* MAKE PUBLIC */}
        {isDashboard &&
          !isAdmin &&
          isSubscribed &&
          (canvas.status === 'done' ||
            typeof canvas.status === 'undefined') && (
            <div
              className={classNames(
                'absolute top-2 right-2 transition duration-300 ease-in-out hover:opacity-100',
                {
                  'opacity-100': showMakePublic || isPromptHover,
                  'opacity-0': !showMakePublic,
                },
              )}
            >
              <MakePublicButton
                onMakePublic={onMakePublic}
                canvas={canvas}
                isCanvasCellFocused={isVideoHover}
                disabled={isMakingPublic}
              />
            </div>
          )}

        {/* PROMPT */}
        <div
          className={classNames(
            'absolute bottom-0 w-full p-2 pl-3 transition duration-500 ease-in-out bg-black backdrop-blur bg-opacity-20 opacity-0',
            { 'opacity-100': showOverlay || isPromptHover },
          )}
          // onMouseEnter={() => setIsPromptHover(true)}
          // onMouseLeave={() => setIsPromptHover(false)}
        >
          {canvas.prompt && (
            <div
              className={classNames(
                'flex justify-between items-center transition-all ease-in-out duration-300 overflow-hidden',
                {
                  'h-9': !expandPromptFull,
                  'h-24': expandPromptFull,
                  'h-[200px]': expandPromptFull && canvas.H > canvas.W, // portrait
                  'h-[160px]': expandPromptFull && canvas.H === canvas.W, // square
                  'h-[150px]': expandPromptFull && canvas.H < canvas.W, // landscape
                },
              )}
            >
              <div
                className={classNames('text-sm text-tertiary cursor-none', {
                  'line-clamp-2': !expandLineClampPartial,
                  'line-clamp-5': expandLineClampPartial && !expandPromptFull,
                  'line-clamp-[10]': expandPromptFull,
                })}
                ref={ref}
              >
                <PhraseFilter
                  prompt={canvas.prompt}
                  canvasId={canvas._id}
                  onClick={handlePhraseFilter}
                />
              </div>
            </div>
          )}

          {/* BUTTONS */}
          <div className='flex justify-between pt-3'>
            <div className='flex gap-2'>
              <ExpandPromptButton
                onClick={handleExpandPromptClick}
                isPromptFullyExpanded={expandPromptFull}
              />
              {!isAdmin && canvas.settingsUsed && (
                <CopyPromptButton settingsUsed={canvas.settingsUsed} />
              )}
            </div>

            {(isDashboard || isAdmin) && (
              <div className='flex gap-2'>
                {!isAdmin && (
                  <DownloadButton
                    isDownloading={isDownloading}
                    canvas={canvas}
                    onDownload={onDownload}
                    downloadVideo={downloadVideo}
                    downloadPercent={downloadPercent}
                  />
                )}
                {!isAdmin && <InfoButton onInfo={onInfo} canvas={canvas} />}
                <DeleteButton
                  onDelete={onDelete}
                  canvas={canvas}
                  isAdmin={isAdmin}
                />
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)
