import { memo } from 'react'
import Masonry from 'react-masonry-css'

import { MediaItem } from './MediaItem'
import { SkeletonsLoader } from '../../loading'

export interface MediaGridProps {
  mediaIds: string[]
  isLoading: boolean
  columns?: number
  thumbnailClassName?: string
  hideProgress?: boolean
}

export const MediaGrid = memo(
  ({
    mediaIds,
    columns = 1,
    isLoading,
    thumbnailClassName,
    hideProgress,
  }: MediaGridProps) => {
    const masonryCols = { default: columns }

    return (
      <Masonry
        breakpointCols={masonryCols}
        className='flex w-auto media-masonry'
        columnClassName='media-masonry-col'
      >
        {mediaIds.map((mediaId, index) => (
          <MediaItem
            key={`${mediaId}-${index}`}
            mediaId={mediaId}
            thumbnailClassName={thumbnailClassName}
            hideProgress={hideProgress}
          />
        ))}

        {/* Media Skeletons are loaded as a grid of 'columns' width and 3 height as Masonry won't balance the columns with a single component */}
        {isLoading &&
          Array.from({ length: columns }).map((_, index) => (
            <SkeletonsLoader
              key={index}
              count={3}
              className={'mb-4'}
              randomize
            />
          ))}
      </Masonry>
    )
  },
)

MediaGrid.displayName = 'MediaGrid'
