import { ModelType } from '../types'

export const CREDITS_PER_SECOND = {
  [ModelType.ImageLab]: 1,
  [ModelType.TransformV3]: 7,
  [ModelType.MotionV2]: 4,
  [ModelType.MotionV3]: 5,
  [ModelType.FlipbookV1]: 1,
  [ModelType.Assemble]: 1,
  [ModelType.InpaintV1]: 1,
  [ModelType.UpscaleV1]: 1,
  [ModelType.FluxImage]: 1,
}

export const FLAT_CREDITS_COST: Partial<Record<ModelType, number>> = {
  [ModelType.ImageLab]: 1,
  [ModelType.UpscaleV1]: 1,
  [ModelType.FluxImage]: 5,
}

export const getCreditsCost = (modelType: ModelType, length: number) => {
  const flatCost = FLAT_CREDITS_COST[modelType]
  if (flatCost !== undefined) {
    return flatCost
  }
  return Math.max(0, length) * CREDITS_PER_SECOND[modelType]
}
