import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function PortfolioComponent({ title, date, videoId }) {
  return (
    <div className='flex flex-col text-tertiary'>
      <h1 className='text-2xl font-bold'>{title}</h1>
      <span className='text-tertiary/60'>{date}</span>
      <iframe
        className='max-w-[854px] w-full h-auto aspect-video mt-3'
        width='854'
        height='480'
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title}
        allow='accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture web-share'
        allowFullScreen='1'
      />
    </div>
  )
}

export default function Portfolio() {
  return (
    <div className='bg-dark'>
      <Navbar />
      <div className='container mx-auto max-w-7xl space-y-8 px-5 py-10 pt-[74px]'>
        <PortfolioComponent
          title={`Linkin Park 'Meteora' 20th Anniversary Visualizers`}
          date='April 6, 2023'
          videoId='iKBCVZqqooY'
        />
        <PortfolioComponent
          title={`Mike Shinoda 'In My Head' Visualizer for the Scream VI`}
          date='March 9, 2023'
          videoId='bGyH1JF1Nu4'
        />
        <PortfolioComponent
          title='Kid Cudi “Entergalactic” Album Visuals'
          date='October 6, 2022'
          videoId='zr-PTLqbYqo'
        />
        <PortfolioComponent
          title='Linkin Park “Lost” Music Video for Meteora 20th Anniversary'
          date='February 9, 2023'
          videoId='7NK_JOkuSVY'
        />
      </div>
      <Footer />
    </div>
  )
}
