import { useReactFlow } from '@xyflow/react'
import { useEffect, useCallback } from 'react'
import { SubmitHandler, useFormContext, FieldValues } from 'react-hook-form'

import { FlowElement } from './FlowElement'
import { ELEMENTS_CONFIG, FLOWS_CONFIG } from '../../../config'
import { DEFAULT_FORM_VALUES, WEIGHTS } from '../../../constants'
import { useCanvasContext } from '../../../context'
import { useAnalytics } from '../../../hooks'
import { useCreateMediaNode } from '../../../hooks/k2/useCreateMediaNode'
import { isImageReferenceField } from '../../../utils/formUtils'
import {
  AnalyticsEvent,
  MediaForm,
  ModelType,
  FormOverrides,
  ElementType,
  FieldType,
  MODEL_DIMENSION_VALIDATION,
} from '@/types'

interface CanvasFlowProps {
  modelType: ModelType
  nodeIndex: number
  parentId: string
  overrides?: FormOverrides
  elements: ElementType[]
  isCollapsed: boolean
  setIsCollapsed: (collapsed: boolean) => void
}

const roundToNearest = (value: number, multiple: number) => {
  return Math.round(value / multiple) * multiple
}

export const CanvasFlow = ({
  modelType,
  parentId,
  overrides,
  elements,
  nodeIndex,
  isCollapsed,
  setIsCollapsed,
}: CanvasFlowProps) => {
  const { setValue, reset, handleSubmit, unregister, watch, register } =
    useFormContext()
  const { createMediaNode } = useCreateMediaNode()
  const { removeCanvasElement } = useCanvasContext()
  const { updateNodeData } = useReactFlow()
  const { trackEvent } = useAnalytics()

  /**
   *  Update the fields based on the overrides
   *
   *  Use-cases:
   *    - When linking an image or prior flow's output to a new flow's element,
   *      we need to be able to update and show that there's an overriding value.
   *    - For timeline, when you dragged a flow into a timeline slot for audio,
   *      it would trim the audio to auto-fill the audio upload element.
   */
  useEffect(() => {
    if (overrides) {
      Object.entries(overrides).forEach(([fieldName, fieldValue]) => {
        setValue(fieldName, fieldValue)
      })
    } else {
      reset()
    }
  }, [overrides, reset, setValue])

  // sync formValue changes to nodeData
  useEffect(() => {
    const subscription = watch((data) => {
      updateNodeData(parentId, { formValues: data })
    })

    // unsubscribe previous side effects
    return () => subscription.unsubscribe()
  }, [watch, parentId, updateNodeData])

  const validateAndAdjustDimensions = useCallback(
    (formData: MediaForm) => {
      const validationRule = MODEL_DIMENSION_VALIDATION[modelType]
      if (validationRule && validationRule.multipleOf > 1) {
        const { multipleOf } = validationRule
        if (formData.width) {
          const adjustedWidth = roundToNearest(formData.width, multipleOf)
          /**
           * We need to set the value and the formData because
           * the formData is what gets sent to the backend
           * and the value is what gets displayed in the UI
           */
          setValue(FieldType.Width, adjustedWidth)
          formData.width = adjustedWidth
        }
        if (formData.height) {
          const adjustedHeight = roundToNearest(formData.height, multipleOf)
          setValue(FieldType.Height, adjustedHeight)
          formData.height = adjustedHeight
        }
      }
      return formData
    },
    [modelType, setValue],
  )

  const onSubmit: SubmitHandler<MediaForm> = async (formData) => {
    const adjustedFormData = validateAndAdjustDimensions(formData)
    await createMediaNode(parentId, adjustedFormData, modelType)
  }

  const handleElementDelete = (index: number) => {
    const elementToDelete = elements[index]
    removeCanvasElement(parentId, index)

    // Unregistering the fields will remove the fields from the form
    // However, we might have required, default values for optional elements
    // So we should just reset their values instead of unregistering them
    ELEMENTS_CONFIG[elementToDelete].fields.forEach((field: string) => {
      if (field in DEFAULT_FORM_VALUES) {
        setValue(
          field,
          DEFAULT_FORM_VALUES[field as keyof typeof DEFAULT_FORM_VALUES],
        )
      } else {
        // Both setValue and unregister are needed to clear the field here,
        // as any watch() listeners will not be triggered if we only call unregister() for some reason
        setValue(field, undefined)
        unregister(field)
      }

      // When we clear the image reference fields, we should also clear the weights (set to a default value, not null, as the backend expects an array at all times)
      if (isImageReferenceField(field as FieldType)) {
        setValue(
          `${field}${WEIGHTS}`,
          DEFAULT_FORM_VALUES[
            `${field}${WEIGHTS}` as keyof typeof DEFAULT_FORM_VALUES
          ],
        )
      }
    })

    trackEvent(AnalyticsEvent.ElementRemoved, {
      modelType,
      nodeId: parentId,
      elementType: elementToDelete,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      id={parentId}
    >
      <input
        id='formIndex'
        {...register('formIndex', { value: nodeIndex })}
        className='hidden'
      />
      <div className='flex flex-wrap gap-4'>
        {elements?.map((element, index) => {
          return (
            <FlowElement
              element={ELEMENTS_CONFIG[element].component}
              elementIcon={ELEMENTS_CONFIG[element].elementIcon}
              key={`${ELEMENTS_CONFIG[element].label}-${index}`}
              handleDelete={() => handleElementDelete(index)}
              required={FLOWS_CONFIG[modelType].requiredElements.includes(
                element,
              )}
              isCollapsed={isCollapsed}
              label={ELEMENTS_CONFIG[element].label}
              setIsCollapsed={setIsCollapsed}
            />
          )
        })}
      </div>
    </form>
  )
}
