import { useEffect, useState } from 'react'

import { useThemeContext } from '../../context'
import { cn } from '../../utils'

interface AutoSavingAlertProps {
  isSaving: boolean
}

const SUPERFICIAL_DELAY = 1000

export const AutoSavingAlert = ({ isSaving }: AutoSavingAlertProps) => {
  const { colors } = useThemeContext()
  const [visible, setVisible] = useState(false)

  // add superficial delay on message otherwise message may disappear too quickly
  useEffect(() => {
    if (isSaving) {
      setVisible(true)
    } else {
      const timer = setTimeout(() => setVisible(false), SUPERFICIAL_DELAY)
      return () => clearTimeout(timer)
    }
  }, [isSaving])

  return (
    // gradient border wrapper
    <div
      className={cn(
        `absolute top-[72px] left-1/2 transform -translate-x-1/2 z-40 rounded-[9px] p-[1px] border-gray-600 border select-none ease-in-out transition-all opacity-0 duration-300 pointer-events-none`,
        {
          'opacity-100': visible,
        },
      )}
    >
      <div
        className={`relative bg-[#2C2C2C] flex items-center px-4 py-2 rounded-lg ${colors.background.element}`}
      >
        <span
          className={cn(colors.text.default, {
            'animate-pulse duration-700': visible,
          })}
        >
          Autosaving...
        </span>
      </div>
    </div>
  )
}
