import { Controller, useFormContext } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import { SliderInput } from '../../../../SliderInput'
import { BaseComponentProps } from '@/types'

interface ImageEditSettingsInputProps extends BaseComponentProps {
  name: string
  label: string
  hidden?: boolean
  min?: number
  max?: number
  step?: number
  defaultValue?: number
  handleChange?: () => void
}

export const ImageEditSettingsInput = ({
  name,
  label,
  hidden = false,
  className,
  min = 0,
  max = 100,
  step = 1,
  defaultValue = 0,
  handleChange = () => {},
}: ImageEditSettingsInputProps) => {
  const { control } = useFormContext()

  const onChange = useDebouncedCallback(() => {
    handleChange()
  }, 500)

  const handleSliderClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <div className={className} hidden={hidden}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <div className='flex flex-col gap-1'>
            <label className='text-gray-300'>{label}</label>
            <div onClick={handleSliderClick}>
              <SliderInput
                size='lg'
                min={min}
                max={max}
                step={step}
                value={field.value}
                onChange={(value: any) => {
                  field.onChange(value)
                  onChange()
                }}
              />
            </div>
          </div>
        )}
      />
    </div>
  )
}
