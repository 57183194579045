import { useState, useEffect, useRef } from 'react'
import emptyStarImage from '../images/EmptyStar.svg'
import filledStarImage from '../images/FilledStar.svg'
import http from '../services/HttpService'
import { HiOutlineX } from 'react-icons/hi'

function VideoUserFeedback({
  setShowModal,
  memoryId,
  setShowFeedback,
  feedbackSent,
  setFeedbackSent,
}) {
  const [selectedStars, setSelectedStars] = useState(Array(5).fill(false))
  const [clickedStars, setClickedStars] = useState(Array(5).fill(false))
  const [hoveredStars, setHoveredStars] = useState(Array(5).fill(false))
  const timerRef = useRef(null)

  const handleStarClick = (index) => {
    let updatedStars = [...selectedStars]
    const updatedClickedStars = [...clickedStars]

    updatedStars = selectedStars.map((star, i) => (i <= index ? true : false))

    updatedClickedStars[index] = true

    setSelectedStars(updatedStars)
    setClickedStars(updatedClickedStars)

    const selectedCount = updatedStars.filter((star) => star).length
    if (selectedCount >= 1 && selectedCount <= 3) {
      setShowModal(true)
    } else {
      setShowModal(false)
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }
    sendFeedback(selectedCount)
  }

  const sendFeedback = (selectedCount) => {
    http.post(`/api/create_video_feedback/${memoryId}`, {
      score: selectedCount,
    })
    if (selectedCount > 3) setFeedbackSent(true)
  }

  const handleStarHover = (index) => {
    const updatedHoveredStars = Array(5).fill(false)
    for (let i = 0; i <= index; i++) {
      updatedHoveredStars[i] = true
    }
    setHoveredStars(updatedHoveredStars)
  }

  const resetHover = () => {
    setHoveredStars(Array(5).fill(false))
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (feedbackSent) {
      timerRef.current = setTimeout(() => {
        setShowFeedback(false)
      }, 5000)
    }
  })

  return (
    <div className='flex flex-col p-1 flex-wrap items-center rounded-lg bg-darkGray bg-opacity-80'>
      <div className='absolute top-[6px] right-[6px]'>
        <button
          type='button'
          className='text-gray-400 rounded-full outline-none hover:text-gray-500'
          onClick={() => setShowFeedback(false)}
        >
          <>
            <HiOutlineX className='flex w-6 h-6 text-kaiberGreen border-kaiberGreen border-[1px] rounded-full p-0.5 hover:bg-kaiberGreen hover:text-black cursor-pointer transition ease-in-out duration-300' />
          </>
        </button>
      </div>
      {feedbackSent ? (
        <div className='flex flex-col flex-wrap items-center p-4'>
          <p className='text-xl py-2 font-PublicSans font-semibold text-[#ECFBEC]'>
            Thank you for your feedback!
          </p>
          <p className='text-xs px-12 font-PublicSans font-normal text-[#ECFBEC] text-center'>
            This will help us improve and give you better results over time
          </p>
        </div>
      ) : (
        <div className='flex flex-row w-full justify-center items-center p-0 ml-[-15px]'>
          <div>
            <p className='text-sm font-PublicSans font-semibold text-[#ECFBEC]'>
              Rate your video
            </p>
          </div>
          <div
            onMouseLeave={resetHover}
            className='flex items-start gap-0.5 ml-[5px]'
          >
            {selectedStars.map((isSelected, index) => (
              <img
                key={index}
                src={
                  hoveredStars[index] || isSelected
                    ? filledStarImage
                    : emptyStarImage
                }
                alt={`Star ${index + 1}`}
                className={`w-7 h-7 cursor-pointer`}
                onClick={() => handleStarClick(index)}
                onMouseEnter={() => handleStarHover(index)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoUserFeedback
