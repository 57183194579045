export const BORDER_COLORS = {
  ImageLab: 'border-gray-400',
  MotionV3: 'border-gray-400',
  MotionV2: 'border-gray-400',
  TransformV3: 'border-gray-400',
  FlipbookV1: 'border-gray-400',
  Assemble: 'border-gray-400',
  Cropper: 'border-gray-400',
  LoadVersion: 'border-gray-400',
  InpaintV1: 'border-gray-400',
  FluxImage: 'border-gray-400',
  UpscaleV1: 'border-gray-400',
}

export const DEFAULT_BORDER_COLOR = 'border-gray-400'

export const STROKE_COLORS = {
  ImageLab: 'rgb(34, 197, 94)', // green-500
  InpaintV1: 'rgb(34, 197, 94)', // green-500
  MotionV3: 'rgb(59, 130, 246)', // blue-500 (vibrant blue)
  MotionV2: 'rgb(147, 197, 253)', // blue-300 (muted blue)
  TransformV3: 'rgb(147, 51, 234)', // purple-600 (vibrant purple)
  FlipbookV1: 'rgb(252, 165, 165)', // red-300 (muted red)
  Assemble: 'rgb(245, 158, 11)', // amber-500
  Cropper: 'rgb(146, 64, 14)', // amber-800
  LoadVersion: 'rgb(236, 72, 153)', // pink-500
  FluxImage: 'rgb(34, 197, 94)', // green-500
  UpscaleV1: 'rgb(34, 197, 94)', // green-500
}

export const DEFAULT_STROKE_COLOR = 'rgb(255, 255, 255)'
