import React from 'react'

import { IconButton } from '../Button'
import { ScaleWrapper } from './ScaleWrapper'
import { DEFAULT_BORDER_COLOR } from '../../constants'
import { useThemeContext } from '../../context/themeContext'
import type { TTailwindOrigin } from '../../types/tailwind'
import { cn } from '../../utils'

export interface ToolbarButton {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  onClick: () => void
  title: string
  disabled?: boolean
  className?: string
  size?: number
}

interface NodeToolbarProps {
  buttons: ToolbarButton[]
  scaleOriginClassName?: TTailwindOrigin
  disableScaling?: boolean
  className?: string
}

export const NodeToolbar: React.FC<NodeToolbarProps> = ({
  buttons,
  scaleOriginClassName = 'origin-bottom-left',
  disableScaling = false,
  className = '',
}) => {
  const { colors } = useThemeContext()

  return (
    <ScaleWrapper
      disableScaling={disableScaling}
      className={scaleOriginClassName}
    >
      <div
        className={`flex justify-center border border-solid ${DEFAULT_BORDER_COLOR} rounded-lg h-9 ${className}`}
      >
        <div
          className={cn(
            'rounded-md px-4 py-2 flex items-center space-x-4',
            colors.background.element,
            colors.text.default,
          )}
        >
          {buttons.map((button: ToolbarButton) => (
            <IconButton
              key={button.title}
              onClick={button.onClick}
              icon={button.icon}
              title={button.title}
              disabled={button.disabled}
              className={button.className}
              size={button.size}
            />
          ))}
        </div>
      </div>
    </ScaleWrapper>
  )
}
