import { useState } from 'react'
import http from '../services/HttpService'
import { HiOutlineX } from 'react-icons/hi'

const FeedbackUserModal = ({
  isOpen,
  setShowModal,
  memoryId,
  setFeedbackSent,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [otherOption, setOtherOption] = useState('')

  const toggleOption = (option) => {
    const updatedOptions = { ...selectedOptions }

    if (selectedOptions[option]) {
      updatedOptions[option] = false
    } else {
      updatedOptions[option] = true
    }
    setSelectedOptions(updatedOptions)
  }

  const handleSetOther = (otherInput) => {
    const updatedOptions = { ...selectedOptions }
    setOtherOption(otherInput)
    updatedOptions['other'] = otherInput
    setSelectedOptions(updatedOptions)
  }

  const handleSubmit = () => {
    http.post(`/api/create_video_feedback/${memoryId}`, {
      selectedOptions,
    })
    closeModal()
  }

  const closeModal = () => {
    setShowModal(false)
    setFeedbackSent(true)
  }

  if (!isOpen) {
    return null
  }

  return (
    <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
      <div className='bg-[#212127] w-4/5 lg:w-3/4 xl:w-1/3 p-8 rounded-lg shadow-lg flex flex-col items-center space-y-4 relative'>
        <h2 className='text-[#ECFBEC] text-2xl'>Share your feedback</h2>
        <div className='absolute top-4 right-4'>
          <button
            type='button'
            className='text-gray-400 rounded-full outline-none hover:text-gray-500 px-2'
            onClick={() => closeModal()}
          >
            <>
              <HiOutlineX className='flex w-8 h-8 text-kaiberGreen border-kaiberGreen border-[1px] rounded-full p-0.5 hover:bg-kaiberGreen hover:text-black cursor-pointer transition ease-in-out duration-300' />
            </>
          </button>
        </div>
        <p className='text-[#ECFBEC]'>What can we do better next time?</p>
        <div className='flex flex-col space-y-3'>
          <button
            className={`w-[248px] px-4 py-2 text-[16px] font-semibold border rounded-full ${
              selectedOptions.artistic_quality
                ? 'bg-kaiberGreen text-[#212127] border-kaiberGreen'
                : 'text-[#ECFBEC] border-[#ECFBEC] hover:bg-kaiberGreen hover:text-[#212127] hover:border-kaiberGreen'
            }`}
            onClick={() => toggleOption('artistic_quality')}
          >
            Artistic quality
          </button>
          <button
            className={`w-[248px] px-4 py-2 text-[16px] font-semibold border rounded-full ${
              selectedOptions.didnt_match_prompt
                ? 'bg-kaiberGreen text-[#212127] border-kaiberGreen'
                : 'text-[#ECFBEC] border-[#ECFBEC] hover:bg-kaiberGreen hover:text-[#212127] hover:border-kaiberGreen'
            }`}
            onClick={() => toggleOption('didnt_match_prompt')}
          >
            Didn't match prompt
          </button>
          <button
            className={`w-[248px] px-4 py-2 text-[16px] font-semibold border rounded-full ${
              selectedOptions.human_animal_anatomy
                ? 'bg-kaiberGreen text-[#212127] border-kaiberGreen'
                : 'text-[#ECFBEC] border-[#ECFBEC] hover:bg-kaiberGreen hover:text-[#212127] hover:border-kaiberGreen'
            }`}
            onClick={() => toggleOption('human_animal_anatomy')}
          >
            Human / animal anatomy
          </button>
          <button
            className={`w-[248px] px-4 py-2 text-[16px] font-semibold border rounded-full ${
              selectedOptions.didnt_match_preview
                ? 'bg-kaiberGreen text-[#212127] border-kaiberGreen'
                : 'text-[#ECFBEC] border-[#ECFBEC] hover:bg-kaiberGreen hover:text-[#212127] hover:border-kaiberGreen'
            }`}
            onClick={() => toggleOption('didnt_match_preview')}
          >
            Didn't match preview
          </button>
          <button
            className={`w-[248px] px-4 py-2 text-[16px] font-semibold border rounded-full ${
              selectedOptions.lack_of_movement
                ? 'bg-kaiberGreen text-[#212127] border-kaiberGreen'
                : 'text-[#ECFBEC] border-[#ECFBEC] hover:bg-kaiberGreen hover:text-[#212127] hover:border-kaiberGreen'
            }`}
            onClick={() => toggleOption('lack_of_movement')}
          >
            Lack of movement
          </button>
          <button
            className={`w-[248px] px-4 py-2 text-[16px] font-semibold border rounded-full ${
              selectedOptions.objects_transform_too_much
                ? 'bg-kaiberGreen text-[#212127] border-kaiberGreen'
                : 'text-[#ECFBEC] border-[#ECFBEC] hover:bg-kaiberGreen hover:text-[#212127] hover:border-kaiberGreen'
            }`}
            onClick={() => toggleOption('objects_transform_too_much')}
          >
            Objects transform too much
          </button>
        </div>
        <div className='flex flex-col w-[292px] xl:w-[392px]'>
          <label className='text-[#ECFBEC] pb-2'>Other:</label>
          <input
            type='text'
            placeholder='Your response'
            className='py-3 px-2 text-[#ECFBEC] bg-[#212127] rounded-xl border border-[#ECFBEC] focus:outline-none'
            value={otherOption || ''}
            onChange={(e) => handleSetOther(e.target.value)}
          />
          <button
            className='ml-auto mt-3 px-7 py-2 border-primary/25 text-[#212127] bg-kaiberGreen rounded-full hover:bg-kaiberGreen focus:outline-none'
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default FeedbackUserModal
