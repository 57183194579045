import { useEffect } from 'react'

import { BaseComponentPropsWithChildren } from '@/types'
import { useCollapseContext } from './CanvasBaseElement'

/**
 * Collapsible fields are advanced element fields that can be shown or hidden through the UI
 */
export const CollapsibleFields = ({
  children,
}: BaseComponentPropsWithChildren) => {
  const { isCollapsed, registerCollapsible } = useCollapseContext()

  useEffect(() => {
    const unregister = registerCollapsible()
    return unregister
  }, [registerCollapsible])

  return (
    <div
      className={`
        w-full overflow-hidden transition-all duration-500 ease-in-out
        ${isCollapsed ? 'max-h-0 opacity-0' : 'max-h-screen opacity-100'}
      `}
    >
      <div className='pt-4'>{children}</div>
    </div>
  )
}
