import { LABELS } from '../constants'

// TODO Determine how we plan/are using this, don't see any references yet
export const MODELS_CONFIG = {
  ImageLab: {
    label: LABELS.IMAGE,
  },
  FlipbookV1: {
    label: LABELS.FLIPBOOK_V1,
  },
  MotionV3: {
    label: LABELS.MOTION_V3,
  },
  TransformV3: {
    label: LABELS.TRANSFORM_V3,
  },
  Assemble: {
    label: LABELS.ASSEMBLE,
  },
  Cropper: {
    label: LABELS.CROPPER,
  },
}
