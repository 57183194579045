import React from 'react'

interface ClockIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const ClockIcon = React.forwardRef<SVGSVGElement, ClockIconProps>(
  ({ size = '20', ...props }, ref) => (
    <>
      <svg
        ref={ref}
        stroke='currentColor'
        fill='none'
        height={size}
        width={size}
        viewBox='0 0 32 32'
        {...props}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16 11.4054V16L18.973 18.973M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  ),
)
