import { DoubleImageIcon } from '../images/icons/DoubleImageIcon'
import { ImageIcon } from '../images/icons/ImageIcon'
import { ProfileIcon } from '../images/icons/ProfileIcon'
import { TextIcon } from '../images/icons/TextIcon'
import { VideoIcon } from '../images/icons/VideoIcon'

interface PresetConfig {
  fromIcon: React.ReactElement
  toIcon: React.ReactElement
}

export const PRESETS_CONFIG: Record<string, PresetConfig> = {
  'Image to Video': {
    fromIcon: <ImageIcon />,
    toIcon: <VideoIcon />,
  },
  'Text to Video': {
    fromIcon: <TextIcon />,
    toIcon: <VideoIcon />,
  },
  'Text to Image': {
    fromIcon: <TextIcon />,
    toIcon: <ImageIcon />,
  },
  'Image Mix': {
    fromIcon: <DoubleImageIcon />,
    toIcon: <ImageIcon />,
  },
  'Image Restyle': {
    fromIcon: <TextIcon />,
    toIcon: <ImageIcon />,
  },
  'Image Remix': {
    fromIcon: <DoubleImageIcon />,
    toIcon: <ImageIcon />,
  },
  'Profile Picture': {
    fromIcon: <ImageIcon />,
    toIcon: <ProfileIcon />,
  },
  'Image to Image': {
    fromIcon: <ImageIcon />,
    toIcon: <ImageIcon />,
  },
  'Flux': {
    fromIcon: <TextIcon />,
    toIcon: <ImageIcon />,
  },
  'Image Upscale': {
    fromIcon: <ImageIcon />,
    toIcon: <ImageIcon />,
  },
  'Video to Video': {
    fromIcon: <VideoIcon />,
    toIcon: <VideoIcon />,
  },
}
