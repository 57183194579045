import { Routes, Route, useLocation } from 'react-router-dom'
import { IoIosArrowDown } from 'react-icons/io'
import { Link as LinkScroll } from 'react-scroll'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import RelatedArticles from '../components/features/RelatedArticles'
import FeaturesIndex from '../components/features/Index/FeaturesIndex'
import Audioreactivity from '../components/features/Audioreactivity/Audioreactivity'
import TextToVideo from '../components/features/Text-to-video/TextToVideo'
import { cn } from '../utils'
import { useUserAccountContext } from '../context/userContext'

const image1 = '/website/features/image1.png'
const image2 = '/website/features/image2.png'
const image3 = '/website/features/image3.png'
const image4 = '/website/features/image4.png'

export const Product = () => {
  const location = useLocation()
  const { featureToggles } = useUserAccountContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  let title
  let description
  let btnText
  if (location.pathname.endsWith('/product')) {
    title = 'Kaiber Core Features'
    description =
      'Made for artists, by artists, we are an AI creative lab on a mission to unlock creativity through powerful and intuitive generative audio and video tools.'
    btnText = 'Explore our features'
  } else if (location.pathname.includes('/audioreactivity')) {
    title = 'Audioreactivity'
    description =
      'Creating stunning visualizers, music videos, art for your Spotify Canvases, social media, and more in just minutes with our advanced audio analysis technology.'
    btnText = 'Explore Audioreactivity'
  } else if (location.pathname.includes('/animation')) {
    title = 'Text-to-Video'
    description =
      'Turn your words or uploaded images into dynamic videos. Describe a scene or start with a photo of your choice, define your subject and style, and see your ideas take shape.'
    btnText = 'Explore Text-to-Video'
  }

  return (
    <div className='bg-black'>
      <Navbar />

      <main
        className={cn(
          'flex flex-col mx-auto flex-1 max-w-[1440px] w-full md:px-[3.5%] px-4 py-32 gap-24 text-white pt-28',
          { 'pt-40': isFeedbackBannerShown },
        )}
      >
        <div className='flex flex-col items-end justify-end flex-1 w-full gap-10 sm:flex-row sm:items-center '>
          <div className='flex flex-col max-w-2xl lg:min-w-[700px] md:min-w-[435px] min-w-[320px] md:gap-10 gap-5 items-end'>
            <div className='flex justify-end gap-5 md:gap-10'>
              <img
                className='flex flex-grow max-w-[269px] w-[40%]  object-cover rounded-3xl'
                src={image1}
                alt='Features Page'
              />
              <img
                className='flex flex-1 max-w-[381px] w-[60%] object-cover rounded-3xl'
                src={image2}
                alt='Features Page'
              />
            </div>
            <img
              className='w-full max-w-[630px] object-cover rounded-3xl'
              src={image3}
              alt='Features Page'
            />
          </div>
          <div className='flex w-full min-w-[55%] flex-col gap-8 justify-center'>
            <h1 className='font-semibold text-white lg:text-6xl sm:text-5xl'>
              {title}
            </h1>
            <p className='max-w-2xl text-lg lg:text-2xl sm:text-xl text-white/80'>
              {description} 
            </p>
            <img
              className='object-cover w-full max-w-xl my-5 rounded-3xl'
              src={image4}
              alt='Features Page'
            />
            <LinkScroll
              to='content'
              className='inline-flex cursor-pointer'
              spy={true}
              smooth={true}
              offset={-112}
            >
              <div className='inline-flex text-2xl font-semibold duration-300 lg:text-4xl sm:text-3xl text-primary hover:text-primary/90 active:text-primary/40 '>
                <span>{btnText}</span>
                <IoIosArrowDown className='mt-1' />
              </div>
            </LinkScroll>
          </div>
        </div>

        <Routes className='overflow-hidden'>
          <Route path='/' element={<FeaturesIndex id='content' />} />
          <Route
            path='audioreactivity'
            element={<Audioreactivity id='content' />}
          />
          <Route path='text-to-video' element={<TextToVideo id='content' />} />
        </Routes>

        <RelatedArticles />
      </main>

      <Footer />
    </div>
  )
}
