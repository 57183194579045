import { SVGProps } from '@/types'

export const DotGridIcon = ({ color = 'white', size = 20 }: SVGProps) => (
  <svg
    stroke={color}
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 20 20'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.0003 10.8333C10.4606 10.8333 10.8337 10.4602 10.8337 9.99996C10.8337 9.53971 10.4606 9.16663 10.0003 9.16663C9.54008 9.16663 9.16699 9.53971 9.16699 9.99996C9.16699 10.4602 9.54008 10.8333 10.0003 10.8333Z'
      fill='white'
    />
    <path
      d='M16.8753 10.8333C17.3356 10.8333 17.7087 10.4602 17.7087 9.99996C17.7087 9.53971 17.3356 9.16663 16.8753 9.16663C16.4151 9.16663 16.042 9.53971 16.042 9.99996C16.042 10.4602 16.4151 10.8333 16.8753 10.8333Z'
      fill='white'
    />
    <path
      d='M3.12533 10.8333C3.58556 10.8333 3.95866 10.4602 3.95866 9.99996C3.95866 9.53971 3.58556 9.16663 3.12533 9.16663C2.66509 9.16663 2.29199 9.53971 2.29199 9.99996C2.29199 10.4602 2.66509 10.8333 3.12533 10.8333Z'
      fill='white'
    />
    <path
      d='M10.0003 10.8333C10.4606 10.8333 10.8337 10.4602 10.8337 9.99996C10.8337 9.53971 10.4606 9.16663 10.0003 9.16663C9.54008 9.16663 9.16699 9.53971 9.16699 9.99996C9.16699 10.4602 9.54008 10.8333 10.0003 10.8333Z'
      stroke='white'
      strokeWidth='1.1875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.8753 10.8333C17.3356 10.8333 17.7087 10.4602 17.7087 9.99996C17.7087 9.53971 17.3356 9.16663 16.8753 9.16663C16.4151 9.16663 16.042 9.53971 16.042 9.99996C16.042 10.4602 16.4151 10.8333 16.8753 10.8333Z'
      stroke='white'
      strokeWidth='1.1875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.12533 10.8333C3.58556 10.8333 3.95866 10.4602 3.95866 9.99996C3.95866 9.53971 3.58556 9.16663 3.12533 9.16663C2.66509 9.16663 2.29199 9.53971 2.29199 9.99996C2.29199 10.4602 2.66509 10.8333 3.12533 10.8333Z'
      stroke='white'
      strokeWidth='1.1875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
