import { useState } from 'react'
import { toast } from 'react-toastify'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'
import Lottie from 'lottie-react'

import LoadingWhite from '../images/lottie/loaderWhite.json'
import { Modal } from './Modal'
import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import { Logtail } from '@logtail/browser'

const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN)

export const AskForCancelSubscriptionModal = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { currentUser, featureToggles } = useUserAccountContext()
  const mixpanel = useMixpanel()
  const enableCancellationFlow = featureToggles['enable-cancellation-flow']

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      logtail.info(
        `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() request cancel subscription flow`,
        { user: currentUser },
      )
      logtail.flush()

      let endpoint = ''
      if (!currentUser.subscriptionCancelAtPeriodEnd) {
        endpoint = 'cancel'
        logtail.info(
          `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() request typeof profitwell`,
          { typeof: typeof profitwell },
        )
        logtail.flush()

        if (enableCancellationFlow && typeof profitwell === 'function') {
          // Profitwell JS is dynamically loaded by externally hosted paddle.js
          logtail.info(
            `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() immediately before profitwell() using subscriptionId`,
            { typeof: typeof profitwell },
          )
          logtail.flush()
          // eslint-disable-next-line no-undef
          const result = await profitwell('init_cancellation_flow', {
            subscriptionId:
              currentUser.paymentGateway === 'paddle'
                ? currentUser.paddleSubscriptionId
                : currentUser.stripeSubscriptionId,
          }).then((result) => {
            return result
          })
          logtail.info(
            `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() immediately after profitwell()`,
            { result: result },
          )
          logtail.flush()

          trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.USER_OFFBOARDING, {
            user_offboarding_reason: result.cancelReason,
            user_offboarding_salvage: result.salvageOfferResult,
            user_offboarding_retained:
              result.status === 'aborted' || result.status === 'retained'
                ? true
                : false,
            user_offboarding_flow_exit:
              result.status === 'aborted' ? true : false,
            user_offboarding_feedback: result.additionalFeedback,
            page_url: window.location.origin,
          })

          if (result.status === 'retained' || result.status === 'aborted') {
            setIsSubmitting(false)
            onClose(false)
            return
          }
        } else {
          // Profitwell function sometimes has a problem being dynamically loaded by paddle.js, my hunch is that usome user have adblocker that blocks it.
          // Special Mixpanel event for users where they didn't go through Retain flow and went straight to cancel.
          trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.USER_OFFBOARDING, {
            user_offboarding_reason: 'profitwell retain error',
            user_offboarding_salvage: 'profitwell retain error',
            user_offboarding_retained: false,
            user_offboarding_flow_exit: false,
            user_offboarding_feedback: '',
            page_url: window.location.origin,
          })
        }
      } else {
        endpoint = 'renew'
      }

      logtail.info(
        `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() request /api/auth/myaccount/subscription/${endpoint}`,
        { user: currentUser },
      )
      logtail.flush()

      const response = await http.post(
        `/api/auth/myaccount/subscription/${endpoint}`,
      )
      logtail.info(
        `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() response from /api/auth/myaccount/subscription/${endpoint}`,
        { response: response },
      )
      logtail.flush()
      await new Promise((resolve) => setTimeout(resolve, 15000)) // hacky solution to wait for Paddle webhook to update subscription status

      window.location.reload()
    } catch (err) {
      logtail.info(
        `${currentUser?.distinctId} felog AskForCancelSubscriptionModal.js handleSubmit() error`,
        { user: currentUser, error: err },
      )
      logtail.flush()

      setIsSubmitting(false)

      if (err.response) {
        toast.error(
          err.response.data?.message ||
            err.response.data?.error ||
            err.response.data,
        )
      } else {
        toast.error(err.message)
      }
    }
  }

  return (
    <Modal
      open={isOpen}
      setOpen={isSubmitting ? () => {} : onClose}
      persistent={isSubmitting}
    >
      <div className='px-6 py-6 sm:px-12 sm:py-10 w-full space-y-8'>
        {isSubmitting ? (
          <div className='flex justify-center'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        ) : (
          <>
            <p className='text-2xl sm:text-5xl text-tertiary'>
              {!currentUser.subscriptionCancelAtPeriodEnd ? 'Cancel' : 'Renew'}{' '}
              plan?
            </p>
            <div className='text-center'>
              <button
                onClick={handleSubmit}
                className={`${
                  !currentUser.subscriptionCancelAtPeriodEnd
                    ? 'bg-red-500 border-red-500 text-white'
                    : 'bg-primary border-primary text-gray-700'
                } block rounded-full text-center border px-4 py-4 text-sm font-bold shadow-sm uppercase gap-2 w-full`}
              >
                {!currentUser.subscriptionCancelAtPeriodEnd
                  ? 'CANCEL'
                  : 'RENEW'}{' '}
                PLAN
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
