import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: 'I created a video with Kaiber.... what are my rights?',
    answer: (
      <p>
        If you are using a free account, you are granted a{' '}
        <a
          href='https://creativecommons.org/licenses/by-nc/4.0/legalcode'
          className='underline'
        >
          Commons Noncommercial 4.0 Attribution International License
        </a>
        , which means that {'you’d'} be able to use the images as long as you{' '}
        {'don’t'}
        sell them or make money off them, and as long as you give credit
        (“attribution”) to Kaiber. <br /> <br />
        If you pay for your account, your creation is yours and you have
        commercial rights.
      </p>
    ),
  },
  {
    question: 'How long does it take for a video to render?',
    answer:
      'It can take up to 30 seconds to generate your style previews, and final videos can take minutes to hours, depending on the length.  It takes very long because we are asking the AI to generate multiple frames per second, we’re working on solutions to shorten this.',
  },
  {
    question: 'Will my video have the Kaiber watermark and logo on it?',
    answer:
      'If you have a Free account, your videos will be generated with the Kaiber logo watermark.',
  },
  {
    question: 'How do credits work?',
    answer: (
      <>
        <p className='mb-2'>
          The credit cost varies depending on the feature you want to use.
        </p>
        <p className='mb-2'>
          <ul className='list-disc ml-7'>
            <li>
              Our standard prompt-to-video and image upload features cost 1
              credit per 1 second of video.
            </li>
            <li>
              Our new Transform (video-to-video / restylize) feature costs 5
              credits per 1 second of video.
            </li>
            <li>
              Upscaling any video costs extra credits. 2x = 1 credit for every
              10 seconds. 4x = 1 credit for every 5 seconds.
            </li>
          </ul>
        </p>
        <p>
          Credits will not be deducted from your account until your video
          appears in your Dashboard.
        </p>
      </>
    ),
  },
  {
    question: 'Can I buy more credits?',
    answer: (
      <p>
        You must have a subscription to purchase more credits and credit packs
        cannot be purchased while under free trial period. Subscribers can
        purchase more credits anytime by clicking on your account icon and
        selecting “Credits.” Choose how many credits you’d like to purchase.
        Learn more{' '}
        <a
          href='https://helpcenter.kaiber.ai/kaiber/en/articles/7935580-how-do-kaiber-credits-work'
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
          here
        </a>
        .
      </p>
    ),
  },
  {
    question: 'Do my credits roll over each month?',
    answer: (
      <p>
        Yes, your credits will roll over each month.
        <br />
        <br />
        Please note that you must have a Standard paid account to have
        watermark-free videos. So if you earned/bought additional credits with
        your Standard paid account but then downgraded to a Free account, your
        videos will revert to having a watermark.
        <br />
        <br />
        Please <a href='mailto:support@kaiber.ai'>get in touch</a> if you are
        experiencing issues with credits.
      </p>
    ),
  },
  {
    question: 'Why do I need to pay for AI videos?',
    answer: (
      <p>
        AI video generations require huge computing power, which is expensive.
        For this reason, we had to limit the free version and make extra credits
        a paid feature. But we hope you love your videos and see the power of
        this technology.
      </p>
    ),
  },
  {
    question: 'Does Kaiber have an affiliate program?',
    answer: (
      <p>
        Yes. Kaiber’s affiliate program is an opportunity for you to earn a 10%
        commission on every subscription sale you refer to us, and you’ll
        continue to earn that commission as long as the customer you referred
        remains a subscriber.
        <br />
        <br />
        To apply,{' '}
        <a
          href='https://znavcmypr3t.typeform.com/to/ImpnNpqQ'
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
          please fill out this application
        </a>
        . We’ll review your application and get back to you within a few
        business days.
      </p>
    ),
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FAQs() {
  return (
    <div className='mx-auto max-w-7xl py-12 sm:py-16 px-5'>
      <div className='mx-auto'>
        <div className='lg:grid lg:grid-cols-3 lg:gap-8 space-y-4 sm:space-y-0'>
          <div>
            <h2 className='text-3xl font-bold tracking-tight text-tertiary'>
              Frequently asked questions
            </h2>
            <p className='mt-4 text-lg text-tertiary/60'>
              Everything you need to know about the product and billing.
            </p>
          </div>
          <div className='lg:col-span-2'>
            <dl className='space-y-8'>
              {faqs.map((faq) => (
                <Disclosure as='div' key={faq.question}>
                  {({ open }) => (
                    <>
                      <dt className='text-lg'>
                        <Disclosure.Button className='flex w-full items-start justify-between text-left text-tertiary'>
                          <span className='font-medium text-white'>
                            {faq.question}
                          </span>
                          <span className='ml-6 flex h-7 items-center'>
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform',
                              )}
                              aria-hidden='true'
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                        <p className='text-base text-tertiary/60'>
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
              <div className='flex justify-between border py-8 px-8 items-center rounded-3xl border-tertiary/25'>
                <div className='text-white space-y-2'>
                  <p className='font-bold text-tertiary'>
                    Still have questions?
                  </p>
                  <p className='text-tertiary/60'>
                    Discuss with the team on Discord
                  </p>
                </div>
                <a href='https://discord.com/invite/kaiber'>
                  <button className='inline-flex items-center rounded-full border bg-transparent border-primary/25 text-primary px-4 py-2 text-lg font-medium shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'>
                    Get in touch
                  </button>
                </a>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
