import { IoMdShare } from 'react-icons/io'
import { toast } from 'react-toastify'

import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'

import { useUserAccountContext } from '../context/userContext'

/**
 * ProfileShareButton component
 * @param {Object} param0 props
 * @param {string} param0.username - username of the user profile
 * @returns {JSX.Element}
 */
export const ProfileShareButton = ({ username }) => {
  const { currentUser } = useUserAccountContext()
  const mixpanel = useMixpanel()

  const copyProfileLink = () => {
    const profileLink = `${window.location.origin}/profile/${username}`
    navigator.clipboard.writeText(profileLink)
    toast('Profile link copied to clipboard')
    trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.PROFILE_SHARE, {
      username: username,
      page_url: window.location.origin,
    })
  }

  return (
    <button
      onClick={copyProfileLink}
      className='border-solid border border-primary rounded-xl text-primary text-left py-2 px-4'
    >
      <IoMdShare className='inline h-4 w-4 mb-1 mr-1' />
      Share Profile Link
    </button>
  )
}
