import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

import http from '../services/HttpService'
import { Modal } from '../components/Modal'

function BanAccountModal({
  isOpen,
  userBanningEmail,
  userBanningId,
  userBanningObjectId,
  onClose,
  onBanAcoount = (isOpen, userBanningObjectId) => undefined,
  isBanned = false,
}) {
  const [reasonText, setReasonText] = useState('')
  const [isBanning, setIsBanning] = useState(false)

  const handleBanAccount = async () => {
    try {
      onClose(false)
      onBanAcoount(userBanningObjectId)
      setIsBanning(true)
      await http.post(`/api/admin/ban-account`, {
        userObjectId: userBanningObjectId,
        reasonText: reasonText,
      })
      toast.success('The account has been banned.')
    } catch (error) {
      console.log(error)
    }
  }

  const handleRestore = async () => {
    try {
      onClose(false)
      onBanAcoount(userBanningObjectId)
      setIsBanning(true)
      await http.post(`/api/admin/restore-account`, {
        userObjectId: userBanningObjectId,
        reasonText: reasonText,
      })
      toast.success('The account has been restored.')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsBanning(false)
    }
  }, [isOpen])

  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
        <p className='text-2xl sm:text-5xl text-tertiary'>Are you sure?</p>
        <div>
          {!isBanned ? (
            <div>
              <div className='flex flex-col'>
                <p className='text-tertiary/80'>
                  You're about to ban an account created by
                </p>
                <a
                  className='flex items-center text-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`/admin/users/${userBanningId}`}
                >
                  <p className='mr-1'>{userBanningEmail}</p>
                  <ArrowTopRightOnSquareIcon className='w-4 h-4' />.
                </a>
                <br />
                <p className='text-tertiary/80'>
                  An email will be sent to the user notifying them of the ban.
                </p>
              </div>
              <input
                type='text'
                id='reason'
                onChange={(e) => setReasonText(e.target.value)}
                placeholder='Enter reason for banning (not visible to user)'
                required
                className={`w-full mt-3 block ${
                  true ? 'border-red-700' : 'border-transparent'
                } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
              />
            </div>
          ) : (
            <div>
              <p className='text-tertiary/80'>
                This account has been banned by an administrator, would you like
                to restore it?
              </p>
              <input
                type='text'
                id='reason'
                onChange={(e) => setReasonText(e.target.value)}
                placeholder='Enter a reason for restoring.'
                required
                className={`w-full mt-3 block ${
                  true ? 'border-red-700' : 'border-transparent'
                } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
              />
            </div>
          )}
        </div>
        <div className='mt-4 flex items-center justify-end space-x-2'>
          <button
            type='button'
            className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
            onClick={() => onClose(false)}
            disabled={isBanning}
          >
            No, cancel
          </button>
          {isBanned ? (
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestore}
              disabled={isBanning}
            >
              Yes, restore account
            </button>
          ) : (
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleBanAccount}
              disabled={isBanning}
            >
              Yes, ban account
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default BanAccountModal
