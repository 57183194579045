// client-side accessible video properties
// NOTE: initial property values are ignored

const COMMON_PROPERTIES = {
  memoryId: null,
  videoType: '',
  version: 0,
  H: null,
  W: null,
  height: null,
  width: null,
  prompt: '',
  originalPrompt: '',
  negativePrompt: '',
  evolve: 0,
  steps: 0,
  seed: 0,
  length: 0,
  useImg2Img: true,
  userProvidedStartingFrameS3Key: '',
  initImage: '',
  creditsCost: 0,
  s3Images: {},
  scenes: {},
  isRegenerate: false,
  reusedSettingsMemoryId: null,
}

const FLIPBOOK_V1_PROPERTIES = {
  ...COMMON_PROPERTIES,
  fps: 0,
  aspectRatio: null,
  userProvidedStartingFrame: null,
  createBoomerangVideo: false,
  audioReactivity: null,
  showUserProvidedStartingFrameInFirstFrameOfVideo: true,
  userProvidedAudioFile: null,
  inversionPrompt: null,
  audioFileS3Key: '',
}

const MOTION_V2_PROPERTIES = {
  ...COMMON_PROPERTIES,
  aspectRatio: null,
  userProvidedStartingFrame: null,
  motionMagnitude: null,
  subject: null,
  style: null,
  curatedStyle: null,
  inversionPrompt: '',
  userSkipPrompt: false,
  stableDiffusionCheckpoint: null,
}

const MOTION_V3_PROPERTIES = {
  ...COMMON_PROPERTIES,
  audioReactivity: null,
  aspectRatio: null,
  userProvidedStartingFrame: null,
  motionMagnitude: null,
  subject: null,
  style: null,
  curatedStyle: null,
  userProvidedAudioFile: null,
  retainInitialImage: null,
  audioFileS3Key: null,
  inversionPrompt: null,
  userSkipPrompt: false,
  mask: null,
  stableDiffusionCheckpoint: null,
}

const TRANSFORM_PROPERTIES = {
  ...COMMON_PROPERTIES,
  showUserProvidedStartingFrameInFirstFrameOfVideo: true,
  fps: 0,
  reusedSettingsMemoryId: null,
  userProvidedVideoFileS3Key: null,
  processedFrameTimestamp: 0,
}

const TRANSFORM_V1_PROPERTIES = {
  ...TRANSFORM_PROPERTIES,
}

const TRANSFORM_V2_PROPERTIES = {
  ...TRANSFORM_PROPERTIES,
  inversionPrompt: null,
  guidanceScale: 0,
  stableDiffusionCheckpoint: null,
}

export const VIDEO_PROPERTIES = {
  FLIPBOOK_V1: FLIPBOOK_V1_PROPERTIES,
  MOTION_V2: MOTION_V2_PROPERTIES,
  MOTION_V3: MOTION_V3_PROPERTIES,
  TRANSFORM_V1: TRANSFORM_V1_PROPERTIES,
  TRANSFORM_V2: TRANSFORM_V2_PROPERTIES,
}
