import { useEffect, useRef, useState } from 'react'
import http from '../../services/HttpService'
import { Link } from 'react-router-dom'
import AdminNavbar from '../../components/AdminNavbar'
import Footer from '../../components/Footer'
import { toast } from 'react-toastify'
import moment from 'moment'
import Pagination from '../../components/admin/Pagination'

export default function Announcements() {
  const [isLoading, setIsLoading] = useState(true)
  const [announcements, setAnnouncements] = useState(null)
  const [searchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [, setHasPagination] = useState(false)

  const tableWrapperRef = useRef()
  const fetchAnnouncements = async (page = 1, q = '') => {
    setIsLoading(true)
    const response = await http.get(`/api/admin/announcements`)
    setAnnouncements(response.data.items)

    setTotalItems(response.data.totalItems)
    setTotalPages(response.data.totalPages)
    setHasPagination(response.data.hasPagination)

    setIsLoading(false)

    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTo(0, 0)
    }
  }

  const formatDate = (createdAt) => {
    return moment(createdAt).format('M/DD/YY h:mm a')
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    fetchAnnouncements(newPage, searchText)
  }

  const handleNewAnnouncement = () => {
    window.location.href = '/admin/announcement'
  }

  const handleDeleteAnnouncement = async (distinctId) => {
    try {
      await http.post(`/api/admin/announcement-delete/${distinctId}`, {
        announcementId: distinctId,
      })
      toast.success('The announcement has been deleted.')
      setAnnouncements((announcements) =>
        announcements.filter((announcement) => announcement._id !== distinctId),
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAnnouncements()
  }, [])

  return (
    <div className='bg-dark'>
      <AdminNavbar />
      <div className='section py-10'>
        <div className='container mx-auto max-w-7xl space-y-8 px-5'>
          <div className='flex flex-col sm:flex-row justify-between'>
            <h1 className='text-tertiary/80 flex gap-2 text-5xl font-bold'>
              Announcements
            </h1>
            <div className='flex gap-2 items-center	'>
              <button
                type='button'
                className='mt-8 sm:mt-0 inline-flex px-6 py-2 transition-all duration-200 ease-in-out border rounded-md buttonPrompt border-primary hover:bg-primary hover:text-black w-fit text-kaiberGreen'
                onClick={handleNewAnnouncement}
              >
                New
              </button>
            </div>
          </div>
          {
            // eslint-disable-next-line eqeqeq
            isLoading && totalItems == 0 ? (
              <div className='flex h-full'>
                <div className='m-auto bg-darkGray p-6 rounded-3xl'>
                  <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                </div>
              </div>
            ) : (
              <>
                {totalItems > 0 && (
                  <>
                    <div className='bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl relative'>
                      <div ref={tableWrapperRef} className='overflow-x-auto'>
                        <table className='w-full text-white table-auto border-collapse min-w-[500px]'>
                          <thead>
                            <tr>
                              <th className='text-left p-3 border-b border-[#545854]/25'>
                                Title
                              </th>
                              <th className='text-left p-3 border-b border-[#545854]/25'>
                                Updated at
                              </th>
                              <th className='text-left p-3 border-b border-[#545854]/25'>
                                Publish on
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {announcements.map((announcement, index) => (
                              <tr
                                key={announcement.distinctId}
                                className='cursor-pointer transition-all hover:bg-[#545854]/25'
                              >
                                <td
                                  className={`${
                                    index < announcements.length - 1
                                      ? 'border-b border-[#545854]/25'
                                      : ''
                                  }`}
                                >
                                  <Link
                                    to={`/admin/announcement/${announcement._id}`}
                                    className='block p-3'
                                  >
                                    <div className='flex items-center'>
                                      {/* <img src={announcement.picture || '/favicon-32x32.png'} alt='User's Picture' className='rounded-full w-10 h-10' referrerPolicy='no-referrer' /> */}
                                      <div className='ml-4'>
                                        <span className='block text-primary'>
                                          {announcement.title || 'No title'}
                                        </span>
                                        <span className='block text-gray-400'>
                                          {announcement.email}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td
                                  className={`${
                                    index < announcements.length - 1
                                      ? 'border-b border-[#545854]/25'
                                      : ''
                                  }`}
                                >
                                  <Link
                                    to={`/admin/announcement/${announcement._id}`}
                                    className='flex items-center p-3 h-[72px]'
                                  >
                                    {formatDate(announcement.updatedAt)}
                                  </Link>
                                </td>
                                {announcement.publishAt ? (
                                  <td
                                    className={`${
                                      index < announcements.length - 1
                                        ? 'border-b border-[#545854]/25'
                                        : ''
                                    }`}
                                  >
                                    <Link
                                      to={`/admin/announcement/${announcement._id}`}
                                      className='flex items-center p-3 h-[72px]'
                                    >
                                      {formatDate(announcement.publishAt)}
                                    </Link>
                                  </td>
                                ) : (
                                  <td
                                    className={`${
                                      index < announcements.length - 1
                                        ? 'border-b border-[#545854]/25'
                                        : ''
                                    }`}
                                  >
                                    <Link
                                      to={`/admin/announcement/${announcement._id}`}
                                      className='flex items-center p-3 h-[72px]'
                                    >
                                      No publish date
                                    </Link>
                                  </td>
                                )}
                                <td
                                  className={`${
                                    index < announcements.length - 1
                                      ? 'border-b border-[#545854]/25'
                                      : ''
                                  }`}
                                >
                                  <button
                                    type='button'
                                    className='inline-flex px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-red-400 hover:bg-red-400 hover:text-black w-fit'
                                    onClick={() =>
                                      handleDeleteAnnouncement(announcement._id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {isLoading && (
                        <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 rounded-3xl'>
                          <div className='flex flex-col items-center space-y-4 m-auto'>
                            <div className='bg-darkGray p-6 rounded-3xl'>
                              <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      disabled={isLoading}
                      onPageChange={handlePageChange}
                    />
                  </>
                )}

                {
                  // eslint-disable-next-line eqeqeq
                  totalItems == 0 && (
                    <p className='text-tertiary/80'>
                      No announcements found
                      {searchText && (
                        <>
                          {' '}
                          for '<strong>{searchText}</strong>'
                        </>
                      )}
                      .
                    </p>
                  )
                }
              </>
            )
          }
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
