import React, { useMemo } from 'react'

import { Skeleton } from './Skeleton'
import { AspectRatio } from '../../types'

interface SkeletonsLoaderProps {
  count: number
  aspectRatio?: AspectRatio
  randomize?: boolean // provides variance in aspect ratio when loading content
  className?: string
}

// SkeletonsLoader is a component that generates a specified number of Skeleton components
export const SkeletonsLoader: React.FC<SkeletonsLoaderProps> = ({
  count,
  aspectRatio,
  randomize = false,
  className,
}) => {
  const getRandomAspectRatio = () => {
    const ratios = Object.values(AspectRatio)
    const randomIndex = Math.floor(Math.random() * ratios.length)
    return ratios[randomIndex]
  }

  const skeletons = useMemo(() => {
    const generatedSkeletons = []

    for (let i = 0; i < count; i++) {
      const ratio = randomize ? getRandomAspectRatio() : aspectRatio

      generatedSkeletons.push(
        <Skeleton key={i} aspectRatio={ratio} className={className} />,
      )
    }

    return generatedSkeletons
  }, [count, aspectRatio, randomize, className])

  return skeletons
}

SkeletonsLoader.displayName = 'SkeletonsLoader'
