import { ANGLE, FIELD_LABELS } from '../../../constants'
import { ImageEditSettingsInput } from '../Elements/Settings/ImageEdit/ImageEditSettingsInput'

interface RotateSettingsProps {
  onImageEditChange: () => void
}

export const RotateSettings = ({ onImageEditChange }: RotateSettingsProps) => {
  return (
    <ImageEditSettingsInput
      name={ANGLE}
      label={FIELD_LABELS[ANGLE]}
      min={-180}
      max={180}
      step={1}
      defaultValue={0}
      handleChange={onImageEditChange}
    />
  )
}
