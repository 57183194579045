import { ChangePasswordForm } from './ChangePasswordForm'
import { DeleteAccountForm } from './DeleteAccountForm'

export default function LoginDetails() {
  return (
    <>
      {/* <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-3xl font-medium leading-6 text-tertiary'>
              Email Address
            </h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <form action='#' method='POST'>
            <div className='overflow-hidden shadow sm:rounded-2xl'>
              <div className='bg-darkGray px-4 py-5 sm:p-6'>
                <div className='grid grid-cols-6 gap-6'>
                  <div className='col-span-12'>
                    <label
                      htmlFor='email-address'
                      className='block text-sm font-medium text-gray-50'
                    >
                      Email address
                    </label>
                    <input
                      type='text'
                      name='email-address'
                      id='email-address'
                      autoComplete='email'
                      className='shadow-sm bg-transparent border border-gray-300/25 focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm rounded-md py-2 text-white px-2 mt-2'
                    />
                  </div>
                </div>
              </div>
              <div className='bg-darkGray px-4 py-4 text-right sm:px-6 sm:py-6'>
                <button
                  type='submit'
                  className='inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-primary/60 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div> */}
      <ChangePasswordForm />
      <DeleteAccountForm />
    </>
  )
}
