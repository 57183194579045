import React from 'react'

interface UploadIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const UploadIcon = React.forwardRef<SVGSVGElement, UploadIconProps>(
  ({ size = '20', ...props }, ref) => (
    <>
      <svg
        ref={ref}
        stroke='currentColor'
        fill='none'
        height={size}
        width={size}
        viewBox='0 0 20 14'
        {...props}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.0002 13.0423V6.79232M10.0002 6.79232L12.0835 8.87565M10.0002 6.79232L7.91683 8.87565M12.5002 13.0423H15.1043C17.0028 13.0423 18.5418 11.5033 18.5418 9.60482C18.5418 7.70632 17.0028 6.16732 15.1043 6.16732C15.068 6.16732 15.0318 6.1679 14.9958 6.16898C14.9987 6.09932 15.0002 6.02932 15.0002 5.95898C15.0002 3.19756 12.7616 0.958984 10.0002 0.958984C7.80983 0.958984 5.94844 2.36739 5.27222 4.32798C3.12105 4.60348 1.4585 6.44123 1.4585 8.66732C1.4585 11.0836 3.41725 13.0423 5.8335 13.0423H7.50016'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  ),
)
