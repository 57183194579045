import { useCanvasContext } from '../context'

interface InputHandlers {
  handleFocusCapture: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  handleBlurCapture: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export const useInputHandlers = (): InputHandlers => {
  const canvasCtx = useCanvasContext()
  const handleFocusCapture = (
    _: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    canvasCtx?.setPreventScrolling(false)
  }

  const handleBlurCapture = (
    _: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    canvasCtx?.setPreventScrolling(true)
  }

  return {
    handleFocusCapture,
    handleBlurCapture,
  }
}
