import { CREDITS_PER_SECOND } from './constants'
import { getFormattedTypeAndVersion } from './video_util'

/**
 * Returns credits cost for a video.
 *
 * @param {*} videoType
 * @param {*} version
 * @param {*} length
 * @returns {number} creditsCost
 */
export const getCreditsCost = (videoType, version, length) =>
  +length * CREDITS_PER_SECOND[getFormattedTypeAndVersion(videoType, version)]
