import { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import Navbar from '../components/Navbar'
import classNames from 'classnames'
import { useUserAccountContext } from '../context/userContext'
import PaymentModal from '../components/paymentModal'
import PricingBottomItem from '../components/pricing/PricingBottomItem'
import { CREDITS } from '../utils/constants'

export default function Pricing() {
  const [pricingInterval, setPricingInterval] = useState('yearly')
  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState(0)
  const [selectedTier, setSelectedTier] = useState(null)
  const { currentUser, isAuthenticated } = useUserAccountContext()
  const isSubscribed = currentUser?.subscriptionType === 'Standard'
  const subscriptionInterval = currentUser?.subscriptionInterval

  const openModal = (_amount = 0, tier = null) => {
    if (!isAuthenticated) {
      window.location.href = '/login?redirect=/pricing'
      return
    }

    if (pricingInterval === 'packs') {
      setAmount(_amount)
    } else {
      setSelectedTier(`${tier.name.toLowerCase()}_tier_${pricingInterval}`)
    }

    setIsOpen(!isOpen)
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const tiers = [
    {
      name: 'Explorer',
      href: '#',
      priceMonthly: 5,
      priceYearly: null,
      description: 'Unlock all that Kaiber has to offer',
      buttonText:
        pricingInterval === 'yearly' ? 'Monthly plan only' : 'Subscribe now',
      disabled: pricingInterval === 'yearly',
      // yearlySubtext: 'Annual one time charge of $120',
      buttonAction: openModal,
      isCurrentPlan:
        isSubscribed &&
        currentUser?.subscriptionTierDescription?.startsWith('Explorer'),
      includedFeatures: {
        accessAndUsage: {
          Trial: currentUser?.freeTrialClaimed
            ? null
            : `7-day free trial, ${CREDITS.FREE_TRIAL} credits`,
          Credits: '$5 billed/mo for 300 credits',
          'Purchase more credits': 'Full price',
          'Video length': '1-minute videos',
          Upscaling: false,
          Gallery: true,
        },
        features: {
          Audioreactivity: true,
          'Camera movements': true,
          'Animation (text-to-video)': true,
          'Image upload': true,
          'Transform (video-to-video)': true,
          Storyboard: true,
        },
      },
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
        monthly: process.env.PAYPAL_MONTHLY_PLAN,
        yearly: process.env.PAYPAL_YEARLY_PLAN,
      },
    },
    {
      name: 'Pro',
      href: '#',
      priceMonthly: 15,
      priceYearly: 120,
      description: 'More credits for committed creators',
      buttonText: 'Subscribe now',
      disabled: false,
      // yearlySubtext: 'Annual one time charge of $120',
      buttonAction: openModal,
      isCurrentPlan:
        isSubscribed &&
        currentUser?.subscriptionTierDescription?.startsWith('Pro'),
      includedFeatures: {
        accessAndUsage: {
          Credits:
            pricingInterval === 'yearly'
              ? '$120 billed/yr for 12,000 credits'
              : '$15 billed/mo for 1,000 credits',
          'Purchase more credits': 'Discounted credit packs',
          'Video length': 'Up to 5 mins',
          Upscaling: '2x & 4x',
          Gallery: true,
        },
        features: {
          Audioreactivity: true,
          'Camera movements': true,
          'Animation (text-to-video)': true,
          'Image upload': true,
          'Transform (video-to-video)': true,
          Storyboard: true,
        },
      },
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
        monthly: process.env.PAYPAL_MONTHLY_PLAN,
        yearly: process.env.PAYPAL_YEARLY_PLAN,
      },
    },
    {
      name: 'Artist',
      href: '#',
      priceMonthly: 30,
      priceYearly: 300,
      description: 'For power users who want the most value',
      buttonText: 'Subscribe now',
      disabled: false,
      // yearlySubtext: 'Annual one time charge of $120',
      buttonAction: openModal,
      isCurrentPlan:
        isSubscribed &&
        currentUser?.subscriptionTierDescription?.startsWith('Artist'),
      includedFeatures: {
        accessAndUsage: {
          Credits:
            pricingInterval === 'yearly'
              ? '$300 billed/yr for 33,333 credits'
              : '$30 billed/mo for 2,500 credits',
          'Purchase more credits': 'Discounted credit packs',
          'Video length': 'Up to 10 mins',
          Upscaling: '2x & 4x',
          Gallery: true,
        },
        features: {
          Audioreactivity: true,
          'Camera movements': true,
          'Animation (text-to-video)': true,
          'Image upload': true,
          'Transform (video-to-video)': true,
          Storyboard: true,
        },
      },
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
        monthly: process.env.PAYPAL_MONTHLY_PLAN,
        yearly: process.env.PAYPAL_YEARLY_PLAN,
      },
    },
  ]
  const packs = [
    {
      name: 'Pack 1',
      id: 1,
      href: '#',
      price: 15,
      amount: 1000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['1,000 credits'],
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
    {
      name: 'Pack 2',
      id: 2,
      href: '#',
      price: 65,
      amount: 5000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['Save up to 13.3%', '5,000 credits'],
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
    {
      name: 'Pack 3',
      id: 3,
      href: '#',
      price: 120,
      amount: 10000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['Save up to 20%', '10,000 credits'],
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
  ]

  const PricingPageButton = ({ id, text, action, isDisabled }) => {
    return (
      <button
        id={!isDisabled ? id : null}
        type='button'
        className={
          isDisabled
            ? 'uppercase mt-8 block w-full rounded-full border border-gray-800 bg-gray-100/50 py-2 text-center text-sm font-semibold text-gray-900'
            : 'uppercase mt-8 block w-full rounded-full border border-gray-800 radial-gradient button-shadow py-2 text-center text-sm font-semibold text-gray-900 hover:bg-white'
        }
        onClick={() => action()}
        disabled={isDisabled}
      >
        {text}
      </button>
    )
  }

  const isCurrentPlan = (tier) => {
    return (
      (tier.isCurrentPlan && tier.name === 'Free') ||
      (tier.isCurrentPlan &&
        pricingInterval === subscriptionInterval + 'ly' &&
        currentUser?.subscriptionStatus !== 'trialing')
    )
  }

  const pricingPageButtonAction = (tier) => {
    if (isCurrentPlan(tier) && currentUser?.subscriptionCancelAtPeriodEnd) {
      window.location.href = process.env.REACT_APP_STRIPE_BILLING_URL
    } else {
      tier.buttonAction(null, tier)
    }
  }

  const formatDate = (unix) => {
    return moment(unix * 1000).format('LL')
  }

  const imageO = '/website/welcome/img_o.png'
  const imageD = '/website/welcome/img_d.png'
  const imageK = '/website/welcome/img_k.png'
  const item1Img =
    'https://website-public-assets.kybercorp.org/web/pricing/entreprise.png'
  const item2Img =
    'https://website-public-assets.kybercorp.org/web/pricing/pricing2.png'

  return (
    <div className='bg-black'>
      <Navbar />
      <div className='flex flex-col mx-auto flex-1 max-w-[1440px] w-full md:px-[3.5%] px-4 py-32 sm:pt-52 gap-24'>
        {/* TOP SECTION */}
        <div className='flex sm:flex-row flex-col gap-14 relative items-center justify-end'>
          <div className='flex w-full lg:max-w-4xl md:max-w-xl max-w-sm min-w-full sm:gap-10 gap-4 justify-center sm:order-first order-last'>
            <div className='flex flex-col h-fit items-center gap-4'>
              <img
                className='max-h-[435px] flex-grow h-fit'
                src={imageO}
                alt='Kaiber ODK'
              />
            </div>
            <div className='flex flex-col h-fit items-center gap-4'>
              <img
                className='max-h-[435px] flex-grow h-fit'
                src={imageD}
                alt='Kaiber ODK'
              />
            </div>
            <div className='flex flex-col h-fit items-center gap-4'>
              <img
                className='max-h-[432px] flex-grow'
                src={imageK}
                alt='Kaiber ODK'
              />
            </div>
          </div>

          <h1 className='flex sm:w-1/2 sm:min-w-max w-full lg:text-6xl sm:text-5xl font-semibold text-4xl text-white sm:order-last order-first sm:whitespace-pre-line'>
            Limitless{'\n'} creativity, one click{'\n'} away
          </h1>
        </div>

        {/* PRICING SECTION */}
        <div className='mx-auto relative w-full sm:py-24 py-12 px-4 sm:px-6 lg:px-8'>
          <div className='flex flex-col md:sticky md:top-[94px] top-[74px] bg-black'>
            <div className='flex flex-col'>
              {isSubscribed &&
                currentUser?.subscriptionStatus === 'past_due' && (
                  <div className='max-w-[700px] mx-auto rounded-md bg-gray-800 text-left text-sm px-4 py-2.5 mb-6 sm:mb-8'>
                    <ExclamationCircleIcon className='inline w-6 h-6 mr-2 text-red-400' />
                    <p className='inline text-red-400'>
                      We apologize for the inconvenience, but it appears that
                      your recent payment attempt was unsuccessful. To prevent
                      any disruptions to your subscription service, we kindly
                      ask you to update your card information by{' '}
                      <a
                        href={process.env.REACT_APP_STRIPE_BILLING_URL}
                        className='font-bold underline'
                      >
                        clicking here
                      </a>
                      .
                    </p>
                  </div>
                )}
              <div className='flex sm:flex-row flex-col sm:gap-20'>
                <h1 className='lg:text-6xl md:text-5xl font-semibold tracking-tight text-white '>
                  Pricing plans
                </h1>
                <div className='relative flex flex-wrap w-fit rounded-lg sm:mt-0 mt-5 sm:gap-5 gap-3'>
                  <button
                    type='button'
                    className={
                      pricingInterval === 'monthly'
                        ? 'relative whitespace-nowrap rounded-md text-xl font-medium text-primary underline shadow-sm focus:z-10 outline-none sm:w-auto duration-300'
                        : 'relative whitespace-nowrap rounded-md text-xl font-medium text-white hover:text-primary/60 focus:z-10 outline-none sm:w-auto duration-300'
                    }
                    onClick={() => setPricingInterval('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    type='button'
                    className={
                      pricingInterval === 'yearly'
                        ? 'relative whitespace-nowrap rounded-md text-xl font-medium text-primary underline shadow-sm focus:z-10 outline-none sm:w-auto duration-300'
                        : 'relative whitespace-nowrap rounded-md text-xl font-medium text-white hover:text-primary/60 focus:z-10 outline-none sm:w-auto duration-300'
                    }
                    onClick={() => setPricingInterval('yearly')}
                  >
                    Annual
                  </button>
                  {isSubscribed && (
                    <button
                      type='button'
                      className={
                        pricingInterval === 'packs'
                          ? 'relative sm:whitespace-nowrap rounded-md text-xl font-medium text-primary underline shadow-sm focus:z-10 outline-none sm:w-auto duration-300'
                          : 'relative sm:whitespace-nowrap rounded-md text-xl font-medium text-white hover:text-primary/60 focus:z-10 outline-none sm:w-auto duration-300'
                      }
                      onClick={() => setPricingInterval('packs')}
                    >
                      Credit Packs
                    </button>
                  )}
                </div>
              </div>
              <p className='mt-5 text-white/50 w-fit'>
                {pricingInterval !== 'packs'
                  ? 'Save up to 33% when you pay annually'
                  : 'Available to Pros and Artists only'}
              </p>
            </div>

            <div
              className={`mt-5 grid sm:grid-cols-2 ${
                pricingInterval !== 'packs'
                  ? 'xl:grid-cols-4'
                  : 'xl:grid-cols-3'
              } sm:space-y-0 gap-6`}
            >
              {pricingInterval !== 'packs'
                ? tiers.map((tier, index) => (
                    <div
                      key={tier.name}
                      className={`rounded-3xl ${
                        index === 0 ? 'col-start-2' : ''
                      } ${
                        tier.disabled ? ' opacity-50 pointer-events-none' : ''
                      } `}
                    >
                      <div className='flex flex-col justify-between h-full p-6'>
                        {/* NAME + DESCRIPTION + PRICE SECTION */}
                        <div>
                          <h2 className='text-4xl font-semibold leading-6 text-white'>
                            {tier.name}
                          </h2>
                          <p className='mt-4 text-xl text-white/50'>
                            {tier.description}
                          </p>
                          {pricingInterval === 'monthly' ? (
                            <p className='mt-8'>
                              <span className='text-4xl font-bold tracking-tight text-white'>
                                ${tier.priceMonthly}
                              </span>{' '}
                              <span className='text-base font-medium text-gray-300'>
                                /mo
                              </span>
                            </p>
                          ) : (
                            <p className='mt-8'>
                              <span className='text-4xl font-bold tracking-tight text-white'>
                                $
                                {tier.priceYearly != null
                                  ? tier.priceYearly / 12
                                  : tier.priceMonthly}
                              </span>{' '}
                              <span className='text-base font-medium text-gray-300'>
                                /mo
                              </span>
                            </p>
                          )}
                          {pricingInterval === 'yearly' &&
                          tier.yearlySubtext ? (
                            <p className='mt-4 text-sm text-gray-300'>
                              {tier.yearlySubtext}
                            </p>
                          ) : null}
                        </div>

                        {currentUser?.subscriptionCancelAtPeriodEnd &&
                          isCurrentPlan(tier) && (
                            <p className='mt-4 text-tertiary/80'>
                              <ExclamationTriangleIcon className='float-left w-5 h-5 mt-1 text-orange-500' />
                              <span className='block ml-8'>
                                Your plan will be canceled at{' '}
                                {formatDate(currentUser.subscriptionCancelAt)}.
                              </span>
                            </p>
                          )}

                        {/* pricing page button defaulted isDisabled to false, revise logic */}
                        <PricingPageButton
                          id={`pricing-${tier.name.toLowerCase()}-${pricingInterval}`}
                          text={
                            isCurrentPlan(tier)
                              ? currentUser?.subscriptionCancelAtPeriodEnd
                                ? 'Renew Plan'
                                : 'Current Plan'
                              : tier.buttonText
                          }
                          action={() => pricingPageButtonAction(tier)}
                          isDisabled={
                            tier.disabled ||
                            (isCurrentPlan(tier) &&
                              !currentUser?.subscriptionCancelAtPeriodEnd) ||
                            (tier.name === 'Free' &&
                              currentUser?.subscriptionCancelAtPeriodEnd) ||
                            (isSubscribed &&
                              currentUser?.subscriptionStatus === 'past_due')
                          }
                        />
                      </div>
                    </div>
                  ))
                : null}

              {pricingInterval === 'packs' &&
              currentUser?.subscriptionTier !== 'explorer_tier_monthly'
                ? packs.map((pack, index) => (
                    <div
                      key={index}
                      className={classNames('divide-y  rounded-3xl')}
                    >
                      <div className='flex flex-col justify-between h-full p-6'>
                        <div>
                          <h2 className='text-4xl font-semibold leading-6 text-white'>
                            {pack.name}
                          </h2>
                          <p className='mt-4 text-xl text-white/50'>
                            {pack.description}
                          </p>
                          <p className='mt-8'>
                            <span className='text-4xl font-bold tracking-tight text-white'>
                              ${pack.price}
                            </span>
                          </p>
                        </div>
                        <ul className='mt-6 space-y-4'>
                          {pack.includedFeatures.map((feature) => (
                            <li key={feature} className='flex space-x-3'>
                              <CheckIcon
                                className='flex-shrink-0 w-5 h-5 text-green-500'
                                aria-hidden='true'
                              />
                              <span className='text-sm text-gray-300'>
                                {feature}
                              </span>
                            </li>
                          ))}
                        </ul>
                        <PricingPageButton
                          text={
                            isCurrentPlan(pack)
                              ? 'Current Plan'
                              : pack.buttonText
                          }
                          action={() => {
                            pack.buttonAction(pack)
                          }}
                          isDisabled={isCurrentPlan(pack)}
                        />
                      </div>
                    </div>
                  ))
                : null}
              {pricingInterval === 'packs' &&
                currentUser?.subscriptionTier === 'explorer_tier_monthly' && (
                  <Link
                    to='/account?tab=credits'
                    className='col-span-3 w-72 justify-self-center rounded-full border radial-gradient px-4 py-4 text-sm font-bold text-center text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase border-primary'
                  >
                    BUY INDIVIDUAL CREDITS
                  </Link>
                )}
            </div>
          </div>

          {/* FEATURES TABLE */}
          {pricingInterval !== 'packs' && (
            <div className='flex flex-col w-full text-white gap-10'>
              {/* 
                        <div className='grid grid-cols-4'>
                            <div className='w-1/4'></div>
                            {tiers.map((tier) => (
                                <div className='text-left text-xl' key={tier.name}>
                                    {tier.name}
                                </div>
                            ))}
                        </div>
                         */}
              <div
                className='text-left text-xl font-bold mt-10'
                colSpan={tiers.length + 1}
              >
                ACCESS AND USAGE
              </div>
              {Object.keys(tiers[0].includedFeatures.accessAndUsage).map(
                (feature) => (
                  <div className='grid grid-cols-4' key={feature}>
                    <div className='text-left text-xl'>{feature}:</div>
                    {tiers.map((tier) => (
                      <div className='text-left text-xl px-6' key={tier.name}>
                        {typeof tier.includedFeatures.accessAndUsage[
                          feature
                        ] === 'boolean' ? (
                          tier.includedFeatures.accessAndUsage[feature] ? (
                            <CheckIcon
                              className={`h-7 w-7 text-green-500`}
                              aria-hidden='true'
                            />
                          ) : (
                            '❌'
                          )
                        ) : (
                          tier.includedFeatures.accessAndUsage[feature]
                        )}
                      </div>
                    ))}
                  </div>
                ),
              )}
              <div
                className='text-left text-xl font-bold mt-10'
                colSpan={tiers.length + 1}
              >
                FEATURES
              </div>
              {Object.keys(tiers[0].includedFeatures.features).map(
                (feature) => (
                  <div className='grid grid-cols-4' key={feature}>
                    <div className='text-left text-xl'>{feature}:</div>
                    {tiers.map((tier) => (
                      <div className='text-center px-6' key={tier.name}>
                        {typeof tier.includedFeatures.features[feature] ===
                        'boolean' ? (
                          tier.includedFeatures.features[feature] ? (
                            <CheckIcon
                              className={`h-7 w-7 text-green-500`}
                              aria-hidden='true'
                            />
                          ) : (
                            '❌'
                          )
                        ) : (
                          tier.includedFeatures.features[feature]
                        )}
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          )}

          {/* IN TABLE FORMAT
                    
                    <table className='table-fixed w-full text-white'>
                        <thead>
                            <tr>
                                <th className='w-1/4'></th>
                                {tiers.map((tier) => (
                                    <th className='w-1/4 text-left text-xl' key={tier.name}>
                                        {tier.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-left font-bold' colSpan={tiers.length + 1}>ACCESS AND USAGE</td>
                            </tr>
                            {Object.keys(tiers[0].includedFeatures.accessAndUsage).map((feature) => (
                                <tr key={feature}>
                                    <td className='text-left text-xl'>{feature}:</td>
                                    {tiers.map((tier) => (
                                        <td className='text-left text-xl' key={tier.name}>
                                            {typeof tier.includedFeatures.accessAndUsage[feature] === 'boolean'
                                                ? (tier.includedFeatures.accessAndUsage[feature] ?
                                                    <CheckIcon
                                                        className={`h-5 w-5  ${tier.disabled ? ' text-gray-500' : ' text-green-500'}`}
                                                        aria-hidden='true'
                                                    /> : '❌')
                                                : tier.includedFeatures.accessAndUsage[feature]
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td className='text-left font-bold' colSpan={tiers.length + 1}>FEATURES</td>
                            </tr>
                            {Object.keys(tiers[0].includedFeatures.features).map((feature) => (
                                <tr key={feature}>
                                    <td className='text-left text-xl'>{feature}:</td>
                                    {tiers.map((tier) => (
                                        <td className='text-center' key={tier.name}>
                                            {typeof tier.includedFeatures.features[feature] === 'boolean'
                                                ? (tier.includedFeatures.features[feature] ?
                                                    <CheckIcon
                                                        className={`h-5 w-5 ${tier.disabled ? ' text-gray-500' : ' text-green-500'}`}
                                                        aria-hidden='true'
                                                    /> : '❌')
                                                : tier.includedFeatures.features[feature]
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                    
                    */}
        </div>

        {/* BOTTOM SECTION */}
        <PricingBottomItem
          title={'Enterprise'}
          description={'For teams and companies staying ahead of the curve.'}
          img={item1Img}
          size={'sm:max-w-[35%]'}
          btnText={'Contact Us'}
          btnUrl='mailto:hello@kaiber.ai'
        />

        <PricingBottomItem
          title={'50% off for musicians'}
          description={
            'Music and visuals are better together. Kaiber is excited to support musicians with specialized pricing to help bring your art to life.'
          }
          img={item2Img}
          size={'sm:max-w-[50%]'}
          btnText={'Get started'}
          btnUrl='/musician'
          orderTxt='sm:order-last'
          orderImg='sm:order-first'
        />
      </div>
      <Footer />
      {pricingInterval === 'packs' ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PaymentModal.pack
          isOpen={isOpen}
          onClose={handleOnClose}
          pack={amount}
        />
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <PaymentModal.subscribe
          isOpen={isOpen}
          onClose={handleOnClose}
          tier={selectedTier}
        />
      )}
    </div>
  )
}
