import { memo, useCallback, useEffect, useState, MouseEvent } from 'react'
import { useReactFlow, Node } from '@xyflow/react'
import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions.js'

import { NodeType } from '@/types'
import { AUDIO_UPLOAD } from '../../../constants'
import { useCanvasContext } from '../../../context'
import { FlowNodeData } from './FlowNode/FlowNode'

const bufferToWave = (
  abuffer: AudioBuffer,
  len: number,
  channel_offset = 0,
) => {
  const numOfChan = abuffer.numberOfChannels
  const length = len * numOfChan * 2 + 44

  let buffer = new ArrayBuffer(length)
  let view = new DataView(buffer)
  let channels = []
  let offset = 0
  let pos = 0
  let i
  let sample

  // write WAVE header
  setUint32(0x46464952) // 'RIFF'
  setUint32(length - 8) // file length - 8
  setUint32(0x45564157) // 'WAVE'

  setUint32(0x20746d66) // 'fmt ' chunk
  setUint32(16) // length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChan)
  setUint32(abuffer.sampleRate)
  setUint32(abuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
  setUint16(numOfChan * 2) // block-align
  setUint16(16) // 16-bit (hardcoded in this demo)

  setUint32(0x61746164) // 'data' - chunk
  setUint32(length - pos - 4) // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++)
    channels.push(abuffer.getChannelData(i))

  while (pos < length && offset + channel_offset < channels[0].length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset + channel_offset])) // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
      view.setInt16(pos, sample, true) // write 16-bit sample
      pos += 2
    }
    offset++ // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: 'audio/wav' })

  function setUint16(data: any) {
    view.setUint16(pos, data, true)
    pos += 2
  }

  function setUint32(data: any) {
    view.setUint32(pos, data, true)
    pos += 4
  }
}

export interface AssembleNodeData extends Record<string, unknown> {
  audioFile?: File
}

export interface AssembleNodeProps {
  id: string
  data: AssembleNodeData
  className?: string
}

export const AssembleNode: React.FC<AssembleNodeProps> = memo((props) => {
  const { id, data } = props
  const { addNodes, getNodes, setNodes } = useReactFlow()
  const { registerOnNodeDragStop } = useCanvasContext()
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const [wsRegions, setWsRegions] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [slots, setSlots] = useState<Node[]>([])

  const slotWidth = 376
  const slotHeight = 376
  const slotSpacing = 20
  const wrapperHeight = slotHeight + 200
  const wrapperWidth =
    slots.length * (slotWidth + slotSpacing) - slotSpacing + 500

  useEffect(() => {
    setWavesurfer(
      WaveSurfer.create({
        container: `#waveform`,
        waveColor: 'rgb(173, 216, 230)', // Light blue wave color
        progressColor: 'rgb(135, 206, 235)', // Light blue progress color
        barWidth: 4, // Increase bar width for bigger bars
        barGap: 2, // Adjust bar gap for desired spacing
        barRadius: 4, // Increase bar radius for bubbly appearance
        sampleRate: 44100,
      }),
    )
  }, [])

  const handleRegionUpdated = useCallback(
    async (region: Region, ws: any) => {
      const slotText = region.content.innerHTML || region.content.outerText
      const slotIndex = parseInt(slotText.match(/Slot (\d+)/)?.[1]) - 1

      if (slotIndex >= 0) {
        const start = region.start
        const end = region.end

        try {
          const audioBuffer: AudioBuffer = ws.getDecodedData()
          const startOffset = Math.floor(start * audioBuffer.sampleRate)
          const endOffset = Math.floor(end * audioBuffer.sampleRate)
          const length = endOffset - startOffset

          const audioBlob = bufferToWave(
            audioBuffer,
            length,
            startOffset,
          ) as File

          const slotNodeId = `${id}-slot-${slotIndex}`
          const childFlowNode = getNodes().find(
            (node) => node.data.slotId === slotNodeId,
          )

          if (childFlowNode) {
            setNodes((nodes) =>
              nodes.map((node) => {
                if (node.id === childFlowNode.id) {
                  const updatedData = {
                    ...node.data,
                    overrides: {
                      ...(node.data as FlowNodeData).overrides,
                      [AUDIO_UPLOAD]: audioBlob,
                    },
                  }
                  return { ...node, data: updatedData }
                }
                return node
              }),
            )
          }
        } catch (error) {
          console.error('Error updating region:', error)
          // Handle the error appropriately (e.g., show an error message to the user)
        }
      }
    },
    [getNodes, id, setNodes],
  )

  useEffect(() => {
    if (data.audioFile) {
      const wsRegionsPlugin = wavesurfer.registerPlugin(RegionsPlugin.create())

      const handleContainerAttach = (_: MouseEvent, updatedNode: Node) => {
        if (
          updatedNode &&
          updatedNode.type === NodeType.FlowNode &&
          updatedNode.data.slotId
        ) {
          const slotId = updatedNode.data.slotId as string
          const slotIndex = parseInt(slotId.split('-').pop())
          const regions: Region[] = wsRegionsPlugin.getRegions()
          const correspondingRegion = Object.values(regions).find(
            (region) =>
              parseInt(region.content.innerHTML.match(/Slot (\d+)/)?.[1]) -
                1 ===
              slotIndex,
          )

          if (correspondingRegion) {
            handleRegionUpdated(correspondingRegion, wavesurfer)
          }
        }
      }

      registerOnNodeDragStop(handleContainerAttach)

      wavesurfer.on('pause', () => {
        setIsPlaying(false)
      })

      wavesurfer.on('play', () => {
        setIsPlaying(true)
      })

      wavesurfer.on('ready', () => {
        wsRegionsPlugin.on('region-created', (region) => {
          region.on('update-end', () => handleRegionUpdated(region, wavesurfer))
        })
      })

      wsRegionsPlugin.on('region-updated', (region: Region) => {
        const regions: Region[] = wsRegionsPlugin.getRegions()
        const currentRegion = region
        const currentStart = currentRegion.start
        const currentEnd = currentRegion.end
        const currentWidth = currentEnd - currentStart

        let newStart = currentStart
        let newEnd = currentEnd

        for (const regionId in regions) {
          if (regions.hasOwnProperty(regionId)) {
            const compareRegion = regions[regionId]
            if (compareRegion !== currentRegion) {
              const compareStart = compareRegion.start
              const compareEnd = compareRegion.end

              if (currentStart < compareEnd && currentEnd > compareStart) {
                // Overlap detected, adjust regions
                if (
                  Math.abs(currentStart - compareEnd) <
                  Math.abs(currentEnd - compareStart)
                ) {
                  // Snap to the end of the compare region
                  newStart = compareEnd
                } else {
                  // Snap to the start of the compare region
                  newEnd = compareStart
                }
              }
            }
          }
        }

        // Recalculate the new start and end values if needed
        if (newStart !== currentStart) {
          newEnd = newStart + currentWidth
        } else if (newEnd !== currentEnd) {
          newStart = newEnd - currentWidth
        }

        // Check for overlaps with other regions and adjust the current region
        for (const regionId in regions) {
          if (regions.hasOwnProperty(regionId)) {
            const compareRegion = regions[regionId]
            if (compareRegion !== currentRegion) {
              const compareStart = compareRegion.start
              const compareEnd = compareRegion.end

              if (newStart < compareEnd && newEnd > compareStart) {
                // Overlap detected, adjust the current region
                if (newStart < compareStart) {
                  // Current region overlaps the start of the compare region
                  newEnd = compareStart
                } else if (newEnd > compareEnd) {
                  // Current region overlaps the end of the compare region
                  newStart = compareEnd
                }
              }
            }
          }
        }

        if (newStart !== currentStart || newEnd !== currentEnd) {
          currentRegion.setOptions({
            start: newStart,
            end: newEnd,
          })
        }
      })

      wavesurfer.loadBlob(data.audioFile)
      setWsRegions(wsRegionsPlugin)

      return () => {
        wavesurfer.destroy()
      }
    }
  }, [data.audioFile, handleRegionUpdated, registerOnNodeDragStop, wavesurfer])

  useEffect(() => {
    if (wavesurfer && wsRegions) {
      const existingRegions = wsRegions.getRegions()
      const duration = wavesurfer.getDuration()
      const slotDuration = duration / slots.length

      // Remove regions that no longer have a corresponding slot
      existingRegions.forEach((region: Region, index: number) => {
        if (index >= slots.length) {
          wsRegions.removeRegion(region)
        }
      })

      // Add or update regions for each slot
      slots.forEach((slot, index) => {
        const existingRegion = existingRegions[index]
        const start = index * slotDuration
        const end = (index + 1) * slotDuration

        if (existingRegion) {
          // Update the existing region
          existingRegion.setOptions({
            start,
            end,
            content: `Slot ${index + 1}`,
            color: 'rgba(255, 255, 255, 0.7)',
            drag: true,
            resize: true,
          })
        } else {
          // Add a new region
          wsRegions.addRegion({
            start,
            end,
            content: `Slot ${index + 1}`,
            color: 'rgba(255, 255, 255, 0.7)',
            drag: true,
            resize: true,
          })
        }
      })
    }
  }, [slots, wavesurfer, wsRegions])

  const handlePlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause()
    }
  }

  const handleAudioUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      data.audioFile = file
    }
  }

  const handleAddSlot = () => {
    const newSlot: Node = {
      id: `${id}-slot-${slots.length}`,
      type: NodeType.AssembleSlotNode,
      position: { x: slots.length * (slotWidth + slotSpacing), y: 250 },
      parentId: id,
      width: slotWidth,
      height: slotHeight,
      data: { slotIndex: slots.length },
      extent: 'parent',
      draggable: false,
    }
    setSlots([...slots, newSlot])
    addNodes({
      ...newSlot,
      position: { x: slots.length * (slotWidth + slotSpacing), y: 250 },
      width: slotWidth,
      height: slotHeight,
    } as Node)
  }

  return (
    <div
      style={{
        width: wrapperWidth,
        height: wrapperHeight,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '10px 20px',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <div>
          <label
            htmlFor={`audio-upload-${id}`}
            className='custom-file-upload rounded'
          >
            Upload Audio
          </label>
          <input
            id={`audio-upload-${id}`}
            type='file'
            accept='audio/*'
            onChange={handleAudioUpload}
            style={{ display: 'none' }}
          />
        </div>
        <div>
          <button
            onClick={handlePlayPause}
            className={`play-pause-button ${isPlaying ? 'pause' : 'play'} rounded`}
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
        </div>
        <div>
          <button onClick={handleAddSlot} className='add-slot-button rounded'>
            + Add Slot
          </button>
        </div>
      </div>
      <div
        id={`waveform`}
        style={{
          height: 100,
          width: '100%',
          marginTop: 20,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 4,
        }}
      ></div>
    </div>
  )
})

export interface AssembleSlotNodeData extends Record<string, unknown> {
  slotIndex: number
}

export interface AssembleSlotNodeProps {
  data: AssembleSlotNodeData
}

export const AssembleSlotNode: React.FC<AssembleSlotNodeProps> = memo(
  ({ data }) => {
    const { slotIndex } = data
    return (
      <div
        style={{
          border: '2px dashed #EAB308',
          borderRadius: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 24,
          fontWeight: 'bold',
          color: '#EAB308',
        }}
      >
        Slot {slotIndex + 1}
      </div>
    )
  },
)
