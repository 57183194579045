import { useMemo, useRef, useState } from 'react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import Pagination from './Pagination'

export default function UserMemoriesTab({ memories }) {
  const [currentPage, setCurrentPage] = useState(1)

  const tableWrapperRef = useRef()

  const getMemoryLink = (memoryId) => {
    return `${window.location.origin}/memory/${memoryId}`
  }

  const formatGenerationDate = (createdAt) => {
    return moment(createdAt).utc().format('LLL')
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)

    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTo(0, 0)
    }
  }

  const memoriesDone = useMemo(() => {
    return memories.filter((m) => m.status === 'done').length
  }, [memories])

  const memoriesPending = useMemo(() => {
    return memories.filter((m) => m.status === 'pending').length
  }, [memories])

  const memoriesFailed = useMemo(() => {
    return memories.filter((m) => m.status === 'failed').length
  }, [memories])

  const perPage = useMemo(() => {
    return 10
  }, [])

  const totalPages = useMemo(() => {
    return Math.ceil(memories.length / perPage)
  }, [memories.length, perPage])

  const paginatedMemories = useMemo(() => {
    return memories.slice(
      perPage * currentPage - perPage,
      perPage * currentPage,
    )
  }, [currentPage, memories, perPage])

  return (
    <>
      {memories.length > 0 && (
        <>
          <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-6 bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl mb-8'>
            <div>
              <h4 className='text-tertiary/80'>Total</h4>
              <p className='text-white'>{memories.length}</p>
            </div>
            <div>
              <h4 className='text-tertiary/80'>Done</h4>
              <p className='text-white'>{memoriesDone}</p>
            </div>
            <div>
              <h4 className='text-tertiary/80'>Pending</h4>
              <p className='text-white'>{memoriesPending}</p>
            </div>
            <div>
              <h4 className='text-tertiary/80'>Failed</h4>
              <p className='text-white'>{memoriesFailed}</p>
            </div>
          </div>
          <div className='bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
            <div ref={tableWrapperRef} className='overflow-x-auto'>
              <table className='w-full text-white table-auto border-collapse min-w-[1000px]'>
                <thead>
                  <tr>
                    <th className='text-left p-3 border-b border-[#545854]/25'>
                      Memory ID
                    </th>
                    <th className='text-left p-3 border-b border-[#545854]/25'>
                      Status
                    </th>
                    <th className='text-left p-3 border-b border-[#545854]/25'>
                      Credits Cost
                    </th>
                    <th className='text-left p-3 border-b border-[#545854]/25'>
                      Duration
                    </th>
                    <th className='text-left p-3 border-b border-[#545854]/25'>
                      Deleted
                    </th>
                    <th className='text-left p-3 border-b border-[#545854]/25'>
                      Generated At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedMemories.map((memory, index) => (
                    <tr key={memory._id}>
                      <td
                        className={`${
                          index < memories.length - 1
                            ? 'border-b border-[#545854]/25'
                            : ''
                        }`}
                      >
                        <a
                          href={getMemoryLink(memory.memory_id)}
                          className='p-3 hover:text-primary transition-all'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {memory.memory_id}
                          <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
                        </a>
                      </td>
                      <td
                        className={`p-3 ${
                          index < memories.length - 1
                            ? 'border-b border-[#545854]/25'
                            : ''
                        }`}
                      >
                        <span
                          className={
                            memory.status === 'failed'
                              ? 'text-red-500'
                              : memory.status === 'pending'
                                ? 'text-yellow-400'
                                : ''
                          }
                        >
                          {memory.status}
                        </span>
                      </td>
                      <td
                        className={`p-3 ${
                          index < memories.length - 1
                            ? 'border-b border-[#545854]/25'
                            : ''
                        }`}
                      >
                        {memory.creditsCost}
                      </td>
                      <td
                        className={`p-3 ${
                          index < memories.length - 1
                            ? 'border-b border-[#545854]/25'
                            : ''
                        }`}
                      >
                        {memory.duration}s
                      </td>
                      <td
                        className={`p-3 ${
                          index < memories.length - 1
                            ? 'border-b border-[#545854]/25'
                            : ''
                        }`}
                      >
                        <span className={memory.deleted ? 'text-red-500' : ''}>
                          {memory.deleted ? 'Yes' : 'No'}
                        </span>
                      </td>
                      <td
                        className={`p-3 ${
                          index < memories.length - 1
                            ? 'border-b border-[#545854]/25'
                            : ''
                        }`}
                      >
                        {formatGenerationDate(memory.createdAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mt-8'>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              disabled={false}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}

      {memories.length === 0 && (
        <p className='text-tertiary/80'>No memories found.</p>
      )}
    </>
  )
}
