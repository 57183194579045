import { AspectRatio, Dimensions } from '@/types'

/**
 * Get dimensions from aspect ratio.
 * @param {string} aspectRatio - Aspect ratio in the form of "width:height".
 * @returns {Dimensions} Dimensions object.
 * @throws {Error} If aspect ratio is invalid.
 */
export const getDimensionsFromAspectRatio = (
  aspectRatio: string,
): Dimensions => {
  if (!aspectRatio.includes(':')) {
    throw new Error(`Invalid aspect ratio format, got ${aspectRatio}`)
  }

  const aspectRatioMappings: Record<string, Array<number>> = {
    '16:9': [1344, 768], // @todo: this isn't strictly 16:9, but we need this to be a multiple of 64 for Runware: https://kaiberteam.atlassian.net/browse/ENG-2844
    '4:3': [1280, 960],
    '1:1': [1024, 1024],
    '3:4': [960, 1280],
    '9:16': [768, 1344],
  }

  if (!Object.prototype.hasOwnProperty.call(aspectRatioMappings, aspectRatio)) {
    throw new Error('Invalid aspect ratio')
  }

  const [width, height] = aspectRatioMappings[aspectRatio]

  return { width, height }
}

/*
 * Get aspect ratio value.
 * @param {AspectRatio} ratio - Aspect ratio.
 * @returns {number} Aspect ratio value.
 */
export const getAspectRatioValue = (
  ratio: AspectRatio,
  dimensions?: Dimensions,
): number => {
  if (ratio === AspectRatio.Custom) {
    if (!dimensions) {
      return 1
    }

    return dimensions.width / dimensions.height
  }
  const [w, h] = ratio.split(':').map(Number)
  return w / h
}

/**
 * Fetch image dimensions from a URL.
 * @param {string} imageUrl - URL of the image.
 * @returns {Promise<{ width: number, height: number }>} Promise resolving to image dimensions.
 */
export const fetchImageDimensions = async (
  imageUrl: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({ width: img.width, height: img.height })
    img.onerror = reject
    img.src = imageUrl
  })

/**
 * Calculate scaled dimensions based on width and height.
 * @param {number} width - Override width.
 * @param {number} height - Override height.
 * @param {number} maxWidth - Maximum allowed width.
 * @returns {Dimensions} Calculated dimensions.
 */
export const calculateScaledDimensions = (
  width: number,
  height: number,
  maxWidth: number,
): Dimensions => {
  const aspectRatio = width / height
  let scaledWidth = width
  let scaledHeight = height

  if (scaledWidth > maxWidth) {
    scaledWidth = maxWidth
    scaledHeight = maxWidth / aspectRatio
  }

  return { width: scaledWidth, height: scaledHeight }
}
