import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function AffiliateTerms() {
  return (
    <div className='bg-dark'>
      <Navbar />
      <div className='max-w-7xl mx-auto px-5 py-24'>
        <div className='max-w-xl space-y-4'>
          <h1 className='text-tertiary text-6xl'>
            Kaiber Referral Program Terms
          </h1>
          <h2 className='text-tertiary text-xl'>
            Date of Last Revision: May 15, 2023
          </h2>
          <p className='text-tertiary/60'>
            This Kaiber Affiliate Program Agreement ('Agreement') is entered
            into by Kyber Corp. ('Kaiber') and the party executing this
            Agreement ('Affiliate'). This Agreement governs Affiliate’s
            participation in the Kaiber Affiliate Program described herein (the
            'Program'). You represent and warrant that: (i) you have full legal
            authority to enter into this Agreement (ii) you have read and
            understand the Agreement, and (iii) you agree to the terms of this
            Agreement. If you do not have legal authority to enter into this
            Agreement or do not agree to these terms, please do not click to
            accept this Agreement below.
          </p>

          <p className='text-tertiary/60'>
            This Agreement is effective between Affiliate and Kaiber as of the
            date Affiliate electronically accepts this Agreement.
          </p>

          <p className='text-tertiary/60'>1. Definitions</p>

          <p className='text-tertiary/60'>
            'Custom URL' means the URL to be provided by Kaiber to Affiliate and
            which will correlate Eligible Customers signed up by Affiliate to
            Affiliate’s Program account.
          </p>

          <p className='text-tertiary/60'>
            'Eligible Customer' means an eligible net new user for the direct
            online purchase of the Services directly from Kaiber, excluding
            Ineligible Customers.
          </p>

          <p className='text-tertiary/60'>
            'Kaiber Services' or 'Services' means the Kaiber AI video generation
            platform generally sold by Kaiber and further described
            www.kaiber.ai, as such URL, and Services description (including
            branding of such services), may be updated by Kaiber from time to
            time.
          </p>

          <p className='text-tertiary/60'>
            “Incentives” means the affiliate commission, which is 10% of the
            subscription of the Eligible Customer signed up through the Custom
            URL, for use by the Affiliate under this Agreement.
          </p>

          <p className='text-tertiary/60'>
            'Ineligible Customer' means government officials, government owned
            or -controlled entities and any of their employees, political
            parties and candidates, Services customers of resellers, any other
            individuals or entities already in Kaiber’s systems, and Kaiber
            representatives, agents or employees.
          </p>

          <p className='text-tertiary/60'>
            'Affiliate' means a member of the Program with a primary place of
            business or residence in the Territory that is a business, entity,
            or other individual with a valid tax ID number but may not include
            (i) government officials, government owned or -controlled entities
            and any of their employees, (ii) political parties and candidates,
            and (iii) representatives, agents, or employees of Kaiber.
          </p>

          <p className='text-tertiary/60'>
            'Program Guide' means any set of applicable Program terms in
            addition to this Agreement that Kaiber may make available to
            Affiliate via the Resource Portal and that will govern Affiliate's
            continued participation in the Program.
          </p>

          <p className='text-tertiary/60'>
            'Affiliate Link' is a new user link for Services submitted by
            Affiliate, via its Custom URL, to Kaiber.
          </p>

          <p className='text-tertiary/60'>
            'Resource Portal' means the website provided by Kaiber to Affiliate
            containing Program information.
          </p>

          <p className='text-tertiary/60'>
            'Valid Transaction' is a confirmed subscription between an Eligible
            Customer for Services and Kaiber or its agents, originating through
            an Affiliate’s Custom URL and which is made online directly between
            an Eligible Customer and Kaiber in compliance with the requirements
            for processing of an Incentive.
          </p>

          <p className='text-tertiary/60'>2. Program Overview</p>

          <p className='text-tertiary/60'>
            In compliance with this Agreement Affiliate will promote the
            Services only to Eligible Referrals. Affiliate will not market the
            Services to any Ineligible Customers. Kaiber will provide Affiliate
            with a Custom URL for the purpose of correlating any Eligible
            Referrals resulting in a Valid Transaction under this Agreement.
            Kaiber may issue Incentives to Affiliate in accordance with the
            restrictions and guidelines in this Agreement. As applicable, and
            subject to the requirements herein, Affiliate may include on its
            website and in its marketing materials for the Services the Custom
            URL provided by Kaiber. A Valid Transaction must always originate
            from Affiliate’s Custom URL to qualify.
          </p>

          <p className='text-tertiary/60'>
            Kaiber may elect to create an applicable Program Guide which may
            include updates regarding applicable Incentives and other relevant
            Program details. The terms of any such Program Guide are made part
            of this Agreement and will control over any conflicting term in the
            body of this Agreement.
          </p>

          <p className='text-tertiary/60'>
            3. Distribution of Custom URL and Incentives
          </p>

          <p className='text-tertiary/60'>
            Affiliate will use best efforts to distribute the Custom URL and
            Incentives: (a) only to Eligible Referrals and (b) only after Kaiber
            has approved the distribution by providing Affiliate with the Custom
            URL and any applicable Incentives. All marketing materials
            (including without limitation the text of email distributions, if
            any) must be (i) strictly consistent with any Program instructions
            provided by Kaiber, (ii) compliant with the terms and conditions of
            this Agreement and all applicable marketing, privacy and data
            protection laws and governmental regulations. Any e-mail
            distribution must: (x) offer recipients the ability to opt-out of
            future Affiliate communications and (y) contain the Kaiber governing
            terms and conditions for any Incentives when offered. At Kaiber’s
            request, Affiliate will block distribution of Custom URL and
            Incentives to parties as Kaiber designates in its sole discretion,
            consistent with applicable laws.
          </p>

          <p className='text-tertiary/60'>4. Compliance</p>

          <p className='text-tertiary/60'>
            In addition to the other legal requirements in this Agreement,
            Affiliate expressly agrees to comply with the following:
          </p>

          <p className='text-tertiary/60'>
            4.1. Anti-Bribery Laws and Reporting. Affiliate will comply with all
            applicable commercial and public anti-bribery laws ('Anti-Bribery
            Laws'), including the U.S. Foreign Corrupt Practices Act of 1977 and
            the UK Bribery Act of 2010, which prohibit corrupt offers of
            anything of value, either directly or indirectly to anyone,
            including government officials, to obtain or keep business or to
            secure any other improper commercial advantage.
          </p>

          <p className='text-tertiary/60'>
            'Government officials' include any government employee candidate for
            public office and employee of government-owned or
            government-controlled companies, public international organizations,
            and political parties. Furthermore, Affiliate will not make any
            facilitation payments, which are payments to induce officials to
            perform routine functions they are otherwise obligated to perform.
            If Affiliate becomes aware of suspicious, illegal or fraudulent
            activity occurring in relation to this Agreement, Affiliate will
            report the suspicious or fraudulent activity to Kaiber within 24
            hours of identifying the suspicious or fraudulent activity via
            sending an email to{' '}
            <a className='text-primary' href='mailto:legal@kaiber.ai'>
              legal@kaiber.ai
            </a>
            .
          </p>

          <p className='text-tertiary/60'>
            4.2 Export Control Laws. Affiliate will comply with all applicable
            export and re-export control laws and regulations, including (i) the
            Export Administration Regulations ('EAR') maintained by the U.S.
            Department of Commerce, (ii) trade and economic sanctions maintained
            by the U.S. Treasury Department's Office of Foreign Assets Control,
            and (iii) the International Traffic in Arms Regulations ('ITAR')
            maintained by the U.S. Department of State.
          </p>

          <p className='text-tertiary/60'>
            4.3 Acceptable Use Policy. Affiliate will not send, post, transmit
            or otherwise use any Kaiber provided content, including the Kaiber
            name or the Services, in connection with any materials, sites or
            otherwise that: (i) will generate or facilitate unsolicited bulk
            commercial emails (ii) will violate, or otherwise encourage the
            violation of, the legal rights of others (iii) is for an unlawful,
            invasive, infringing, defamatory, or fraudulent purpose (iv)
            contains obscene or pornographic content.
          </p>

          <p className='text-tertiary/60'>
            4.4 Certification. Affiliate represents, warrants and certifies to
            Kaiber that: (i) it has legal authority to enter into these terms
            and participate in the Program (ii) its employment contract (if
            applicable) does not restrict it from participating in the Program,
            and where Affiliate is also a representative, agent or an employee
            of an Kaiber reseller it has also obtained a valid consent from its
            employer to participate (iii) each Referral submitted by Affiliate
            will be reasonably made in good faith as an Eligible Referral and
            (iv) it will comply with all applicable laws and governmental
            regulations, including without limitation, as described in this
            Section 4 above.
          </p>

          <p className='text-tertiary/60'>
            5. Termination and Modification of Terms
          </p>

          <p className='text-tertiary/60'>
            Kaiber may at any time and in its sole discretion: (i) modify or
            update the terms of, including but not limited to, the Program, this
            Agreement, Incentives (and their governing terms and conditions) and
            will make such modified or updated terms available in writing, or
            (ii) revoke a Affiliate's account, and/or terminate Affiliate’s
            participation in the Program in its entirety. If Kaiber provides
            Affiliate with an updated Custom URL, or Incentives (and applicable
            terms and conditions), Affiliate agrees will begin using, and will
            be subject to, such updated versions no later than 30 days after
            receiving them.
          </p>

          <p className='text-tertiary/60'>6. Brand Features</p>

          <p className='text-tertiary/60'>
            Each party will own all right, title and interest to trade names,
            trademarks, service marks, logos and domain names it secures from
            time to time ('Brand Feature(s)'). Subject to the terms and
            conditions of the Agreement (including without limitation the
            following sentence), Kaiber grants to Affiliate a nonexclusive and
            non-sublicensable license during the Term to display Kaiber’s Brand
            Features only to the extent Brand Features are provided by Kaiber
            for use with the Program and solely for the limited purpose of
            promoting the Services consistent with this Agreement. Additionally,
            all use of Kaiber Brand Features is at Kaiber’s sole discretion and
            subject to Kaiber’s then-current Brand Feature use guidelines as may
            be updated from time to time by Kaiber.
          </p>

          <p className='text-tertiary/60'>
            Subject to the terms and conditions of the Agreement, Affiliate
            grants to Kaiber a nonexclusive and non-sublicensable license during
            the Term to display Affiliate’s Brand Features solely for the
            purpose of marketing the Program or as otherwise mutually agreed
            upon (email permitted).
          </p>

          <p className='text-tertiary/60'>7. Incentives</p>

          <p className='text-tertiary/60'>
            Kaiber may elect to provide Affiliate with Incentives to offer its
            Eligible Referrals from time to time. Use of any applicable
            Incentives will be subject to the governing terms and conditions as
            provided by Kaiber.
          </p>

          <p className='text-tertiary/60'>8. Restrictions</p>

          <p className='text-tertiary/60'>
            Affiliate will not, and will not allow any third party to: (a)
            'frame,' minimize, remove, or otherwise inhibit, the full and
            complete display of any Kaiber web page (b) cause any hyperlinks to
            web pages on the Kaiber web site to create a new browser window or
            (c) otherwise display Kaiber web pages or Brand Features in a
            distorted or diluted fashion.
          </p>

          <p className='text-tertiary/60'>9. Term Termination</p>

          <p className='text-tertiary/60'>
            The Term will continue until terminated by either party as provided
            herein. Either party may terminate this Agreement immediately with
            or without cause upon written notice to the other party (email
            notice permitted). Upon any termination or expiration of this
            Agreement, all licenses and rights granted will terminate and each
            party will have no right to use the Brand Features of the other
            party. In the event of a termination, Affiliate will only have
            Kaiber process an Incentive that was earned in full for a Valid
            Transaction that occurred prior to the effective termination date.
            Termination notices to Kaiber must be sent via email to{' '}
            <a className='text-primary' href='mailto:marketing@kaiber.ai'>
              marketing@kaiber.ai
            </a>
            .
          </p>

          <p className='text-tertiary/60'>10. Confidentiality</p>

          <p className='text-tertiary/60'>
            Affiliate may not disclose the terms, conditions or existence of any
            non-public aspect of the Program to any third party, except to its
            professional advisors under a strict duty of confidentiality or as
            necessary to comply with law.
          </p>

          <p className='text-tertiary/60'>
            11. Disclaimers Limitation of Liability
          </p>

          <p className='text-tertiary/60'>
            EACH PARTY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING WITHOUT
            LIMITATION FOR NON-INFRINGEMENT, SATISFACTORY QUALITY,
            MERCHANTABILITY AND FITNESS FOR ANY PURPOSE. TO THE FULLEST EXTENT
            PERMITTED BY LAW, THE PROGRAM AND CUSTOM URL IS PROVIDED 'AS IS' AND
            AT PARTICIPANT’S OPTION AND RISK AND KAIBER DOES NOT GUARANTEE ANY
            RESULTS. EXCEPT FOR (i) BREACHES OF SECTION 4 (COMPLIANCE) (ii)
            BREACHES OF SECTION 6 (BRAND FEATURES) AND (iii) SECTION 12
            (INDEMNIFICATION), TO THE FULL EXTENT PERMITTED BY LAW REGARDLESS OF
            THE THEORY OR TYPE OF CLAIM: (A) NO PARTY MAY BE HELD LIABLE UNDER
            THIS AGREEMENT OR ARISING OUT OF PERFORMANCE OF THIS AGREEMENT FOR
            ANY DAMAGES OTHER THAN DIRECT DAMAGES, EVEN IF THE PARTY IS AWARE OR
            SHOULD KNOW THAT SUCH DAMAGES ARE POSSIBLE AND EVEN IF DIRECT
            DAMAGES DO NOT SATISFY A REMEDY AND (B) NO PARTY MAY BE HELD LIABLE
            FOR DAMAGES UNDER THIS AGREEMENT IN THE AGGREGATE OF MORE THAN THE
            AMOUNT PAID BY KAIBER TO PARTICIPANT UNDER THIS AGREEMENT IN THE 3
            MONTHS BEFORE THE DATE OF THE ACTIVITY GIVING RISE TO THE FIRST
            CLAIM.
          </p>

          <p className='text-tertiary/60'>12. Indemnification</p>

          <p className='text-tertiary/60'>
            Affiliate will defend, indemnify and hold harmless Kaiber, its
            affiliates, directors, officers and employees against all
            liabilities, damages, losses, costs, fees (including legal fees),
            and expenses relating to any allegation or third-party legal
            proceeding to the extent arising out of: (a) Affiliate’s
            participation in the Program (b) Affiliate’s web site(s), Affiliate
            Brand Features and Kaiber’s use of any Affiliate content (provided
            that such use complies with the requirements of the Agreement) and
            (c) Affiliate’s breach of any representation or warranty in this
            Agreement.
          </p>

          <p className='text-tertiary/60'>13. Representations and Warranties</p>

          <p className='text-tertiary/60'>
            Affiliate warrants that (a) Affiliate will use all information
            provided by Kaiber (including without limitation the Kaiber Brand
            Features) in a manner that complies with applicable law (b)
            Affiliate will clearly and conspicuously display the text of the
            applicable terms and conditions for Incentives provided to Customers
            in accordance with this Agreement and (c) Affiliate will conduct all
            activities in furtherance of this Agreement in accordance with
            applicable law.
          </p>

          <p className='text-tertiary/60'>14. Governing Law and Arbitration</p>

          <p className='text-tertiary/60'>
            14.1 Governing Law. ALL CLAIMS ARISING OUT OR RELATING TO THIS
            AGREEMENT OR ANY RELATED Kaiber PRODUCTS OR SERVICES (INCLUDING ANY
            DISPUTE REGARDING THE INTERPRETATION OR PERFORMANCE OF THE
            AGREEMENT) ('Dispute') WILL BE GOVERNED BY THE LAWS OF THE STATE OF
            CALIFORNIA, USA, EXCLUDING CALIFORNIA'S CONFLICTS OF LAWS RULES.
          </p>

          <p className='text-tertiary/60'>
            14.2 Arbitration and Disputes. The parties will try in good faith to
            settle any Dispute within 30 days after the Dispute arises. If the
            Dispute is not resolved within 30 days, any Dispute will be
            litigated exclusively in the Federal or State Courts of San
            Francisco County, California, USA, and the parties consent to
            personal jurisdiction in those courts.
          </p>

          <p className='text-tertiary/60'>15. Miscellaneous</p>

          <p className='text-tertiary/60'>
            All notices, unless otherwise stated herein, must be in writing and
            addressed to the attention of the other party’s primary point of
            contact. Notice will be deemed given when delivered (a) when
            verified by written receipt if sent by personal courier, overnight
            courier or mail or (b) when verified by automated receipt of
            electronic logs if sent by facsimile or email. Affiliate will not
            assign or otherwise transfer its rights or delegate its obligations
            under the Agreement, in whole or in part, and any attempt to do so
            will be null and void. The Agreement is not intended to benefit, nor
            shall it be deemed to give rise to, any rights in any third party.
            The parties are independent contractors and the Agreement does not
            create any agency, partnership, or joint venture. No party is liable
            for inadequate performance to the extent caused by a condition that
            was beyond its reasonable control. Failure to enforce any provision
            will not constitute a waiver. If any provision is found
            unenforceable, the balance of the provisions will remain in full
            force and effect. In the event of any termination or expiration of
            the Agreement, Sections 5, 9, 10, 11 and 12 shall survive. This
            Agreement is the parties’ entire agreement relating to its subject
            and supersedes any prior or contemporaneous agreements on that
            subject. Any amendments must be in writing and executed by both
            parties (electronic form acceptable).
          </p>

          <p className='text-tertiary/60'>
            Questions? Concerns? Suggestions? Please contact us at{' '}
            <a className='text-primary' href='mailto:support@kaiber.ai'>
              support@kaiber.ai
            </a>{' '}
            to report any violations of these Terms or to pose any questions
            regarding these Terms or the Platform.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  )
}
