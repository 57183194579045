import { useRef, useState } from 'react'

import { useThemeContext } from '../../context'
import { ImageIcon } from '../../images/icons/ImageIcon'
import { LoadingIcon } from '../../images/icons/LoadingIcon'
import { useDndHandlers } from '../../utils/dndUtils'

interface UploadDropzoneProps {
  onFileUpload: (file: File) => Promise<void>
  className?: string
}

export const UploadDropzone: React.FC<UploadDropzoneProps> = ({
  onFileUpload,
  className = '',
}) => {
  const { colors } = useThemeContext()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileDrop = async (
    filesWithSource: { file: File; source: string }[],
  ) => {
    if (!filesWithSource.length) return
    if (filesWithSource.length > 1) {
      console.error('Please upload one file at a time.')
      return
    }

    const { file } = filesWithSource[0]
    setIsUploading(true)

    try {
      await onFileUpload(file)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsUploading(false)
    }
  }

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const filesWithSource = Array.from(event.target.files || []).map(
      (file) => ({
        file,
        source: URL.createObjectURL(file),
      }),
    )
    if (filesWithSource.length) {
      await handleFileDrop(filesWithSource)
    }
    if (inputRef.current) inputRef.current.value = ''
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    inputRef.current?.click()
  }

  const {
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDragEnd,
  } = useDndHandlers({ handleFileDrop })

  return (
    <div
      className={`p-2 border border-gray-500 rounded ${colors.background.neutral} h-full flex flex-col items-center justify-center gap-2 ${className}`}
      data-upload-droppable={true}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
    >
      {isUploading ? (
        <>
          <LoadingIcon width='24px' height='24px' />
          <div className={`${colors.text.default} text-[12px]`}>
            Uploading media...
          </div>
        </>
      ) : (
        <>
          <ImageIcon className='w-6 h-6' />
          <div className={`${colors.text.default} text-[12px]`}>
            Upload image
          </div>
          <label
            className={`py-1 px-4 rounded-full cursor-pointer border ${colors.border.brand} ${colors.text.brand} text-[10px] border-full`}
          >
            Choose File
          </label>
        </>
      )}
      <input
        ref={inputRef}
        type='file'
        accept='image/*'
        className='hidden'
        onChange={handleInputChange}
        onClick={(e) => e.stopPropagation()}
        title='Upload file'
      />
    </div>
  )
}
