import { SVGProps } from '@/types'

export const TrashOutlineIcon = ({ size = 20, className }: SVGProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 20 20'
    stroke='currentColor'
    strokeWidth='.5'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M2.29163 4.16667C1.94645 4.16667 1.66663 4.4465 1.66663 4.79167C1.66663 5.13685 1.94645 5.41667 2.29163 5.41667V4.16667ZM17.7083 5.41667C18.0535 5.41667 18.3333 5.13685 18.3333 4.79167C18.3333 4.4465 18.0535 4.16667 17.7083 4.16667V5.41667ZM8.74996 8.95834C8.74996 8.61317 8.47013 8.33334 8.12496 8.33334C7.77978 8.33334 7.49996 8.61317 7.49996 8.95834H8.74996ZM7.49996 13.5417C7.49996 13.8868 7.77978 14.1667 8.12496 14.1667C8.47013 14.1667 8.74996 13.8868 8.74996 13.5417H7.49996ZM12.5 8.95834C12.5 8.61317 12.2201 8.33334 11.875 8.33334C11.5298 8.33334 11.25 8.61317 11.25 8.95834H12.5ZM11.25 13.5417C11.25 13.8868 11.5298 14.1667 11.875 14.1667C12.2201 14.1667 12.5 13.8868 12.5 13.5417H11.25ZM12.623 4.94746C12.709 5.28174 13.0498 5.48298 13.384 5.39695C13.7184 5.3109 13.9196 4.97017 13.8335 4.63589L12.623 4.94746ZM3.33459 4.83191L4.06732 16.1893L5.31473 16.1088L4.58199 4.75143L3.33459 4.83191ZM6.35423 18.3333H13.6457V17.0833H6.35423V18.3333ZM15.9326 16.1893L16.6653 4.83191L15.418 4.75143L14.6852 16.1088L15.9326 16.1893ZM16.0416 4.16667H3.95829V5.41667H16.0416V4.16667ZM2.29163 5.41667H3.95829V4.16667H2.29163V5.41667ZM16.0416 5.41667H17.7083V4.16667H16.0416V5.41667ZM13.6457 18.3333C14.854 18.3333 15.8548 17.3951 15.9326 16.1893L14.6852 16.1088C14.6498 16.6568 14.195 17.0833 13.6457 17.0833V18.3333ZM4.06732 16.1893C4.14512 17.3951 5.14586 18.3333 6.35423 18.3333V17.0833C5.80497 17.0833 5.35008 16.6568 5.31473 16.1088L4.06732 16.1893ZM7.49996 8.95834V13.5417H8.74996V8.95834H7.49996ZM11.25 8.95834V13.5417H12.5V8.95834H11.25ZM9.99996 2.91667C11.2611 2.91667 12.3223 3.77921 12.623 4.94746L13.8335 4.63589C13.3942 2.92876 11.8453 1.66667 9.99996 1.66667V2.91667ZM7.37693 4.94746C7.67762 3.77921 8.73888 2.91667 9.99996 2.91667V1.66667C8.15469 1.66667 6.60577 2.92876 6.16638 4.63589L7.37693 4.94746Z'
      fill='currentColor'
    />
  </svg>
)
