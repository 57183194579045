import { ReactNode } from 'react'

interface OverlayMenuProps {
  children: ReactNode
}

export const OverlayBackground = ({ children }: OverlayMenuProps) => (
  <div className='absolute inset-0'>
    <div className='absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
      <div className='absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-black to-transparent rounded-t-2xl opacity-70' />
      <div className='absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-black to-transparent rounded-b-2xl opacity-70' />
      {children}
    </div>
  </div>
)
