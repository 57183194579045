import React from 'react'
import { useFormContext } from 'react-hook-form'

import { DEFAULT_FORM_VALUES, LABELS } from '../../../../../constants'
import { Slider } from '../../../Fields'
import { CanvasBaseElement } from '../../CanvasBaseElement'

import { ImageDimensionsInput } from './ImageDimensionsInput'

import { FieldType, ModelType, MODEL_DIMENSION_VALIDATION } from '@/types'

const validationRule = MODEL_DIMENSION_VALIDATION[ModelType.FluxImage]
const multipleOf = validationRule ? validationRule.multipleOf : undefined

export const FluxImageSettings = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const imageUpload = watch(FieldType.ImageUpload)
  const isImageUploaded = !!imageUpload && imageUpload.length > 0

  const errorMessage =
    (errors[FieldType.Width]?.message as string) ||
    (errors[FieldType.Height]?.message as string)

  return (
    <CanvasBaseElement label={LABELS.IMAGE_DETAILS} errorMessage={errorMessage}>
      <div className='flex flex-col gap-4'>
        <ImageDimensionsInput
          isImageUploaded={isImageUploaded}
          multipleOf={multipleOf}
        />

        <Slider
          label={FieldType.Guidance}
          min={1}
          max={3}
          step={0.1}
          {...register(FieldType.Guidance, { valueAsNumber: true })}
          value={
            watch(FieldType.Guidance) ?? DEFAULT_FORM_VALUES[FieldType.Guidance]
          }
        />
        <Slider
          label={FieldType.Steps}
          min={25}
          max={50}
          step={1}
          {...register(FieldType.Steps, { valueAsNumber: true })}
          value={watch(FieldType.Steps) ?? DEFAULT_FORM_VALUES[FieldType.Steps]}
        />
      </div>
    </CanvasBaseElement>
  )
}
