import { useFormContext } from 'react-hook-form'

import { DEFAULT_FORM_VALUES, LABELS } from '../../../../../constants'
import { FieldType } from '../../../../../types'
import { Slider } from '../../../Fields'
import { CanvasBaseElement } from '../../CanvasBaseElement'

const MIN_EVOLVE = 1
const MAX_EVOLVE = 10
const STEP_EVOLVE = 1

export const TransformV3Settings = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors[FieldType.Evolve]?.message as string

  return (
    <CanvasBaseElement
      label={LABELS.TRANSFORM_V3_SETTINGS}
      errorMessage={errorMessage}
    >
      <Slider
        label={LABELS.EVOLVE}
        min={MIN_EVOLVE}
        max={MAX_EVOLVE}
        step={STEP_EVOLVE}
        {...register(FieldType.Evolve, { valueAsNumber: true })}
        value={watch(FieldType.Evolve) ?? DEFAULT_FORM_VALUES[FieldType.Evolve]}
      />
    </CanvasBaseElement>
  )
}
