import { cn } from '../../utils'
import { Tooltip } from '../k2/Tooltip'
import { SVGProps } from '@/types'

interface IconButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon: React.ComponentType<SVGProps>
  title: string
  className?: string
  disabled?: boolean
  size?: number
}

export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  icon: Icon,
  title,
  className = '',
  disabled = false,
  size,
}) => (
  <Tooltip placement='top' content={title}>
    <button
      onClick={onClick}
      className={cn(
        'outline-none transition-all duration-250 ease-in-out pointer-events-auto',
        disabled
          ? 'opacity-40 cursor-not-allowed'
          : 'hover:scale-110 active:scale-90',
        className,
      )}
      type='button'
      disabled={disabled}
    >
      <Icon size={size} />
    </button>
  </Tooltip>
)
