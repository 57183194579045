export const FLIPBOOK = 'flipbook'
export const TRANSFORM = 'transform'
export const MOTION = 'motion'
export const ANIMATION = 'animation' // deprecated in favor of FLIPBOOK

export const VIDEO_TYPES_AND_VERSIONS = {
  FLIPBOOK_V1: 'flipbook_v1',
  ANIMATION_V1: 'animation_v1', // deprecated in favor of FLIPBOOK_V1
  TRANSFORM_V1: 'transform_v1',
  TRANSFORM_V2: 'transform_v2',
  MOTION_V1: 'motion_v1',
  MOTION_V2: 'motion_v2',
  MOTION_V3: 'motion_v3',
}

export const GENERATE_VIDEO_ENDPOINTS = {
  FLIPBOOK_V1: '/api/generate_video',
  MOTION_V2: '/api/motion/v2/generate_video',
  MOTION_V3: '/api/motion/v3/generate_video',
  TRANSFORM_V1: '/api/transform/v1/generate_video',
  TRANSFORM_V2: '/api/transform/v2/generate_video',
}

const STEP_1_UPLOAD_FILES = 1
const STEP_2_PROMPT_INPUT = 2
const STEP_3_VIDEO_SETTINGS = 3
const STEP_4_PREVIEW_FRAMES = 4
const STEP_5_SUMMARY_PAGE = 5

export const STEPS = {
  STEP_1_UPLOAD_FILES,
  STEP_2_PROMPT_INPUT,
  STEP_3_VIDEO_SETTINGS,
  STEP_4_PREVIEW_FRAMES,
  STEP_5_SUMMARY_PAGE,
}
export const HERO_MOBILE_VIDEO_SRC =
  'https://video.gumlet.io/648aa4ae0a8ccf79944a2e69/649218d1fdae81aad3aa97ab/main.m3u8'
export const HERO_DESKTOP_VIDEO_SRC =
  'https://video.gumlet.io/648aa4ae0a8ccf79944a2e69/64909f5c13376820a616aeb3/main.m3u8'
export const MOTION_HERO =
  'https://video.gumlet.io/648aa4ae0a8ccf79944a2e69/64d119abc28971a733900aea/main.m3u8'

export const MAX_NUMBER_OF_SCENES = 10
export const IN_THE_STYLE_OF = ', in the style of '

export const PLACEHOLDERS = {
  SUBJECT: '(describe subject)',
  STYLE: '(describe details)',
  NEGATIVE: '(negative prompt)',
}

const FLIPBOOK_STYLE_PRESETS = {
  Cinematic:
    'photo taken on film, film grain, vintage, 8k ultrafine detail, private press, associated press photo, masterpiece, cinematic',
  'Photo-realistic':
    'Photo real, hyper-realistic, high dynamic range, rich colors, lifelike textures, 8K UHD, high color depth',
  '3D Rendering': '3D, octane render, 8k, ray-tracing, blender, hyper-detailed',
  Watercolour:
    'watercolor, soft edges, color gradations, luminous, water pigment, Pierre-Joseph Redouté, Thomas Moran, JMW Turne',
  'Oil Painting':
    'an oil painting, 1600s, exquisite detail, baroque painting, voluminous lighting, painterly brushstrokes, edgar degas, john singer sargent, john william waterhouse, masterpiece',
}

const MOTION_STYLE_PRESETS = {
  'Art Nouveau':
    'alphonse mucha, illustration, painterly strokes, detailed sketches, beautiful, glowing',
  Cinematic:
    'photo taken on film, film grain, vintage, 8k ultrafine detail, private press, associated press photo, masterpiece, cinematic',
  Watercolour:
    'watercolor, soft edges, color gradations, luminous, water pigment, Pierre-Joseph Redouté, Thomas Moran, JMW Turne',
  'Oil Painting':
    'an oil painting, 1600s, exquisite detail, baroque painting, voluminous lighting, painterly brushstrokes, edgar degas, john singer sargent, john william waterhouse, masterpiece',
  Illustration:
    'illustration, illustrated style, anime illustration, soft glow, soft lighting, sketch, painterly strokes, line art, drawing',
}

const SUBJECTS = [
  'Art Studio',
  "Dragon's Lair",
  'Steampunk Workshop',
  "Giant's Workshop",
  'Victorian Greenhouse',
  'Tapestry Workshop',
  'Futuristic Spaceship',
  "Pirate's Treasure",
  'Witch’s Potions',
  'Seance Artifacts',
  'Cosmic Landscape',
  'Alien Desert',
  'Sunset Desert',
  'Moonlit Seashore',
  'Serengeti Sunrise',
  'Winter Village',
  'Rainforest',
  'Wildflower Meadow',
  'Ancient Forest',
  'Enchanted Forest',
  'Flappers & Jazz',
  'Philosophers',
  'Medieval Crowd',
  'Renaissance Fair',
  'Tudor Figures',
  'Samurai',
  'Carnival Performers',
  'Masquerade Guests',
  'Subway Commuters',
  'Futuristic Citizens',
  'Mythical Beings',
  'Elven Creatures',
  'Alien Species',
  'Merfolk Royalty',
  'Magical Creatures',
  'Marine Life',
  'Subterranean Creatures',
  'Jungle Wildlife',
  'Ghostly Apparitions',
  'Dream Library',
  'Dream Meadow',
  'Surreal Dreamscape',
  'Surreal Circus',
  'Surreal Garden',
  'Secret Garden',
  'Cosmic Ocean',
  'Abstract City',
  'Dystopian City',
  'Megacity',
  'Jazz Club',
  'Speakeasy',
  'Botanical Garden',
  'Japanese Garden',
  'Grecian Garden',
  'Rooftop Garden',
  'City Park',
  'Gothic Cathedral',
  'Gothic Library',
  'Art Nouveau',
  '1920s Paris',
  '1930s New York',
  '1950s Suburbia',
  'Space Control',
  'Colonial Town',
  'Egyptian City',
  'Mayan City',
  'Japanese Village',
  'Battlefield',
  'Apocalyptic Tokyo',
]

export const STYLE_PRESETS = {
  // T-2384: we need this here until we are able to completely migrate away from 'animation' as a video style
  animation: FLIPBOOK_STYLE_PRESETS,
  flipbook: FLIPBOOK_STYLE_PRESETS,
  motion: MOTION_STYLE_PRESETS,
  transform: FLIPBOOK_STYLE_PRESETS,
  restylization: FLIPBOOK_STYLE_PRESETS,
}

export const CATEGORY_OPTIONS = {
  subjects: SUBJECTS,
  artStyle: Object.keys(STYLE_PRESETS),
  artColor: ['warm', 'cool', 'neutral'],
  artEmotion: ['sad', 'happy', 'angry'],
}

export const CURATED_CATEGORY_OPTIONS = {
  artStyle: [
    'Lost',
    'Entergalactic',
    'Meteora Graffiti',
    'Meteora Graphic',
    'Meteora Watercolour',
    'Necropunk', // HALLOWEEN_2023_STYLE_PACK
    'Haunting Hues', // HALLOWEEN_2023_STYLE_PACK
    'Harvest Tales', // HALLOWEEN_2023_STYLE_PACK
    'Festive Storybook', // HOLIDAY_2023_STYLE_PACK
    'Fairytale Illustration', // HOLIDAY_2023_STYLE_PACK
    'Winter Wonderland', // HOLIDAY_2023_STYLE_PACK
  ],
}

// @TODO - we want to eventually remove this and use the server-side version
// @see /server/utilities/curated_prompts_util.ts
export const ROTATING_STYLES = [
  {
    name: 'Lost',
    image: '/curated/lost.png',
  },
  {
    name: 'Entergalactic',
    image: '/curated/entergalactic.png',
  },
  {
    name: 'Meteora Graffiti',
    image: '/curated/meteora-graffiti.png',
  },
  {
    name: 'Meteora Graphic',
    image: '/curated/meteora-graphic.png',
  },
  {
    name: 'Meteora Watercolour',
    image: '/curated/meteora-watercolour.png',
  },
]

export const AUDIO_REACTIVE_OPTIONS = [
  {
    name: 'None',
    value: 0,
  },
  {
    name: 'Low',
    value: 3,
  },
  {
    name: 'Medium',
    value: 6,
  },
  {
    name: 'High',
    value: 9,
  },
]

export const CAMERA_OPTIONS = [
  { name: 'None', value: 'translation_z' },
  { name: 'Zoom In', value: 'translation_z' },
  { name: 'Zoom Out', value: 'translation_z' },
  { name: 'Rotate Clockwise', value: 'rotation_3d_z' },
  { name: 'Rotate Counter-Clockwise', value: 'rotation_3d_z' },
  { name: 'Up', value: 'translation_y' },
  { name: 'Down', value: 'translation_y' },
  { name: 'Left', value: 'translation_x' },
  { name: 'Right', value: 'translation_x' },
]

export const MUTUALLY_EXCLUSIVE_CAMERA_OPTIONS = {
  None: [
    'Zoom In',
    'Zoom Out',
    'Up',
    'Down',
    'Left',
    'Right',
    'Rotate Clockwise',
    'Rotate Counter-Clockwise',
  ],
  'Zoom In': ['Zoom Out', 'None'],
  'Zoom Out': ['Zoom In', 'None'],
  'Rotate Clockwise': ['Rotate Counter-Clockwise', 'None'],
  'Rotate Counter-Clockwise': ['Rotate Clockwise', 'None'],
  Up: ['Down', 'None'],
  Down: ['Up', 'None'],
  Left: ['Right', 'None'],
  Right: ['Left', 'None'],
}

export const USER_AGENTS = {
  iOS: 'iOS',
  Android: 'Android',
  iPhone: 'iPhone',
  iPad: 'iPad',
  mobile: 'mobile',
}

export const DEFAULTS = {
  ANIMATION_KEYFRAME: {
    'Zoom In': { translation_z: '0:(1)' },
    'Zoom Out': { translation_z: '0:(-1)' },
    Right: { translation_x: '0:(1)' },
    Left: { translation_x: '0:(-1)' },
    Up: { translation_y: '0:(1)' },
    Down: { translation_y: '0:(-1)' },
    'Rotate Clockwise': { rotation_3d_z: '0:(1)' },
    'Rotate Counter-Clockwise': { rotation_3d_z: '0:(-1)' },
    None: {
      translation_x: '0:(0)',
      translation_y: '0:(0)',
      translation_z: '0:(0)',
      rotation_3d_z: '0:(0)',
    },
  },
  ANIMATION_KEYFRAME_EXPRESSION: {
    'Zoom In': { translation_z: '1 + 10(x^3)' },
    'Zoom Out': { translation_z: '-1 - 10(x^3)' },
    Right: { translation_x: '1 + 10(x^3)' },
    Left: { translation_x: '-1 - 10(x^3)' },
    Up: { translation_y: '1 + 10(x^3)' },
    Down: { translation_y: '-1 - 10(x^3)' },
    'Rotate Clockwise': { rotation_3d_z: '1 + 10(x^3)' },
    'Rotate Counter-Clockwise': { rotation_3d_z: '-1 - 10(x^3)' },
    None: {
      translation_x: '0',
      translation_y: '0',
      translation_z: '0',
      rotation_3d_z: '0',
    },
  },
  VIDEO_LENGTH: 8,
  NONSUBSCRIBER_VIDEO_LENGTH: 6,
  VIDEO_STRENGTH: 4,
  AUDIO_REACTIVITY: 6,
  MOTION_MAGNITUDE: 5,
  FPS: 12,
}

export const NONE = 'None'
export const MIN_SCENE_LENGTH = 2 // in seconds
export const MIN_LENGTH_FOR_ADDING_SCENE = 2 * MIN_SCENE_LENGTH // 2 x 2 = 4 seconds

export const ASPECT_RATIO_OPTIONS = [
  { name: '16:9', value: '16:9', style: 'aspect-[16/9] sm:h-12 h-10' },
  { name: '4:3', value: '4:3', style: 'aspect-[4/3] sm:h-12 h-10' },
  { name: '1:1', value: '1:1', style: 'aspect-square sm:h-12 h-10' },
  { name: '3:4', value: '3:4', style: 'aspect-[3/4] sm:w-12 w-10' },
  { name: '9:16', value: '9:16', style: 'aspect-[9/16] sm:w-12 w-10' },
]

export const CAMERA_MOVEMENT_OPTIONS = [
  { name: 'None', value: 'translation_z' },
  { name: 'Zoom In', value: 'translation_z' },
  { name: 'Zoom Out', value: 'translation_z' },
  { name: 'Rotate Clockwise', value: 'rotation_3d_z' },
  { name: 'Rotate Counter-Clockwise', value: 'rotation_3d_z' },
  { name: 'Up', value: 'translation_y' },
  { name: 'Down', value: 'translation_y' },
  { name: 'Left', value: 'translation_x' },
  { name: 'Right', value: 'translation_x' },
]

export const CAMERA_MOVEMENT_MUTUALLY_EXCLUSIVE_OPTIONS = {
  None: [
    'Zoom In',
    'Zoom Out',
    'Up',
    'Down',
    'Left',
    'Right',
    'Rotate Clockwise',
    'Rotate Counter-Clockwise',
  ],
  'Zoom In': ['Zoom Out', 'None'],
  'Zoom Out': ['Zoom In', 'None'],
  'Rotate Clockwise': ['Rotate Counter-Clockwise', 'None'],
  'Rotate Counter-Clockwise': ['Rotate Clockwise', 'None'],
  Up: ['Down', 'None'],
  Down: ['Up', 'None'],
  Left: ['Right', 'None'],
  Right: ['Left', 'None'],
}

export const CREDITS = {
  FREE_TRIAL: 100,
}

export const MODEL_VERSION_MAX = {
  flipbook: 1,
  motion: 3,
  transform: 2,
}

export const MODEL_VERSIONS = {
  flipbook: [1],
  motion: [2, 3],
  transform: [1, 2],
}

export const CREDITS_PER_SECOND = {
  transform_v1: 5,
  transform_v2: 6,
  motion_v1: 4,
  motion_v2: 4,
  motion_v3: 5,
  flipbook_v1: 1,
  animation_v1: 1, // deprecated in favor of flipbook_v1
}

export const SUBSCRIPTIONS = {
  EXPLORER_TIER_MONTHLY: 'explorer_tier_monthly',
}

export const BETA_MOTION_V3 = {
  NAME: 'Motion 3.0',
  FLAG: 'enable-motion-v3', // @see https://us.posthog.com/project/33670/feature_flags/32641
}

export const MOTION_V3_CAMERA_MOVEMENTS = {
  NAME: 'Motion 3.0 Camera Movements',
  FLAG: 'enable-motion-v3-camera-movements', // @see https://us.posthog.com/project/33670/feature_flags/39302
}

export const MOTION_V3_BRUSH = {
  NAME: 'Motion 3.0 Brush',
  FLAG: 'enable-motion-v3-brush', // @see https://us.posthog.com/project/33670/feature_flags/39563
}

export const STABLE_DIFFUSION_CHECKPOINT_DROPDOWN = {
  NAME: 'Select Stable Diffusion Checkpoints',
  FLAG: 'enable-stable-diffusion-checkpoint-dropdown',
}

export const SUPPORTED_AUDIO_EXTENSIONS = [
  'm4a',
  'x-m4a',
  'wav',
  'x-wav',
  'ogg',
  'mp3',
  'mpeg',
  'aac',
  'vnd.wave',
  'vnd.dlna.adts',
  'octet-stream',
]

export const FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
}

// TODO: we need to share this image mime types between server and client
export const IMAGE_MIMETYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  WEBP: 'image/webp',
  BINARY: 'binary/octet-stream',
}

// TODO: we need to share this video mime types between server and client
export const VIDEO_MIMETYPES = {
  MP4: 'video/mp4',
  WEBM: 'video/webm',
  OGG: 'video/ogg',
  MPEG: 'video/mpeg',
  QUICKTIME: 'video/quicktime',
}

// TODO: we need to share this audio mime types between server and client
export const AUDIO_MIMETYPES = {
  MP3: 'audio/mpeg',
  WAV: 'audio/wav',
  OGG: 'audio/ogg',
  FLAC: 'audio/flac',
  AAC: 'audio/aac',
}

export const ANIMATED_IMAGE_PROMPT = ',, in the style of ,'

export const ANIMATED_IMAGE_PROMPT_COPY =
  'This video was generated using attributes from the uploaded image'

export const STABLE_DIFFUSION_CHECKPOINTS = {
  'Animated': 'dreamshaper',
  'Photorealistic': 'realistic',
}
