import { BaseComponentPropsWithChildren } from '../../../types'
import { cn } from '../../../utils'

type Placement = 'left' | 'right'

interface ElementFloatingWindowProps extends BaseComponentPropsWithChildren {
  placement?: Placement
}

export const ElementFloatingWindow: React.FC<ElementFloatingWindowProps> = ({
  children,
  className,
  placement = 'right',
}) => (
  <div
    className={cn(
      'absolute p-4 z-10 -top-14 shadow-2xl rounded-2xl bg-k2-dark-primary min-w-44 min-h-[196px]',
      {
        'left-full ml-8': placement === 'right',
        'right-full mr-8': placement === 'left',
      },
      className,
    )}
  >
    {children}
  </div>
)
