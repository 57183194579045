import { useState } from 'react'

import { cn } from '../utils'
import { MagicWandIcon } from '../images/MagicWandIcon'

/**
 * Represents a magic wand button component.
 *
 * @param {boolean} isLoading - Indicates whether the button is in a loading state.
 * @param {function} onClick - The function to be called when the button is clicked.
 * @param {string} color - The color of the button in hexadecimal format. Default is '#95D0B7'.
 * @param {string} height - The height of the button. Default is 'h-8'.
 * @param {string} width - The width of the button. Default is 'w-8'.
 * @returns {JSX.Element} The rendered MagicWandButton component.
 */
export const MagicWandButton = ({
  isLoading,
  onClick,
  color = '#95D0B7',
  height = 'h-8',
  width = 'w-8',
  margin = 'ml-7',
  className,
}) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <button
      onClick={() => {
        onClick()
        setIsHovering(false)
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disabled={isLoading}
      className={cn(
        `transition-all duration-200 ease-in-out rounded-full hover:text-dark flex items-center justify-center border has-tooltip mt-0 mb-[7px] align-middle
        ${height}
        ${width}
        ${margin}
        `,
        {
          'opacity-50': isLoading,
          'border-quaternary text-quaternary': color === '#A59CDA',
          'hover:bg-quaternary': color === '#A59CDA' && !isLoading,
          'border-primary-light text-primary-light': color === '#95D0B7',
          'hover:bg-primary-light': color === '#95D0B7' && !isLoading,
        },
        className,
      )}
    >
      {/* TOOLTIP */}
      <span
        className={
          'px-2 py-1 text-gray-100 text-sm transition-all duration-200 ease-in-out bg-gray-800 rounded shadow-lg -mt-8 tooltip'
        }
      >
        {isLoading ? 'Analyzing uploaded media...' : 'Autofill prompt subject'}
      </span>
      {isLoading ? (
        <div className='w-5 h-5 ml-1 border-b-2 rounded-full animate-spin border-primary' />
      ) : (
        <MagicWandIcon
          color={isHovering ? 'black' : color}
          className='transition-all duration-200 ease-in-out ml-1'
          size='.9em'
        />
      )}
    </button>
  )
}
