import { useEffect, useState, useRef, useCallback } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import Lottie from 'lottie-react'

import { CanvasFailed } from './CanvasFailed'
import http from '../../services/HttpService'
import { Skeleton } from '../loading'
import { useUserAccountContext } from '../../context/userContext'
import { useVideoEventsContext } from '../../context/videoEventsContext'
import { VideoOverlay } from './VideoOverlay'
import { scrollToTop } from '../../utils'
import LoadingWhite from '../../images/lottie/loaderWhite.json'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../../utils/mixpanel'
import playButtonImage from '../../images/play-button-thin.png'
import {
  ANIMATED_IMAGE_PROMPT,
  ANIMATED_IMAGE_PROMPT_COPY,
} from '../../utils/constants'
import { getProxiedR2FileUrl } from '../../utils/fileUtils'

const VIDEO_TIME_OFFSET = 0 // for video initial frame to display on load and hover off
const CURSOR_AUTOHIDE_TIME = 1200 // amount of time before cursor disappears if no movement
const CURSOR_AUTOHIDE_TIME_FAST = 600 // for smaller frames to hide more quickly
const FAILED = 'failed'

export default function CanvasCell({
  canvas,
  isDashboard = false,
  allowExternalLink = true,
  onVideoStatusChange,
  onDelete,
  onInfo,
  onMakePublic,
  onUpscaleOpen = false,
  isUpscaling = false,
  isMakingPublic = false,
  onUpscaleSuccess = false,
  onUpscaleError = false,
  onDownload = false,
  setSearchText,
  searchInputRef,
  getVideos,
  isAdmin = false,
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDownloading, setIsDownloading] = useState(0)
  const [isRequeueing, setIsRequeueing] = useState(false)
  const [downloadPercent, setDownloadPercent] = useState(0)
  const [isVideoHover, setIsVideoHover] = useState(false)
  const [isUpscaled, setIsUpscaled] = useState(false)

  const [isMobilePressed, setIsMobilePressed] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileScrolling, setIsMobileScrolling] = useState(false)

  const isMounted = useRef(true)
  const [showOverlay, setShowOverlay] = useState(false)
  const [showMakePublic, setShowMakePublic] = useState(true)
  const [firstVideoEvent, setFirstVideoEvent] = useState(null)
  const [onProgressTriggered, setOnProgressTriggered] = useState(false)
  const [timerValue, setTimerValue] = useState(null)
  const [imgError, setImgError] = useState(false)
  const [srcUpdated] = useState(false)
  const [previewFrame, setPreviewFrame] = useState('')
  const [aspectRatio, setAspectRatio] = useState('1 / 1')
  const intervalRef = useRef(null)
  const videoRef = useRef(null)
  const promptRef = useRef(null)
  const timeoutRef = useRef(null)
  const mixpanel = useMixpanel()

  const { refreshCredits, currentUser, isAuthenticated } =
    useUserAccountContext()
  const isOwner =
    isAuthenticated && currentUser.distinctId === canvas.auth0_user_id
  const isSubscribed = currentUser?.subscriptionType === 'Standard'

  const {
    setEventStartTime,
    setEventEndTime,
    setEventMethod,
    setEventMemoryId,
  } = useVideoEventsContext()

  const navigate = useNavigate()
  let href

  if (allowExternalLink) {
    if (canvas.externalLink) {
      href = canvas.externalLink
    } else {
      href = `/${isOwner ? 'memory' : 'share'}/${canvas.memory_id}`
    }
  } else {
    href = null
  }

  const onDownloadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    setDownloadPercent(percentCompleted)
  }

  const downloadVideo = async (videoUrl, videoTitle) => {
    // Start downloading
    setIsDownloading(true)

    // use Cloudflare proxy server to get around CORS errors with R2
    videoUrl = getProxiedR2FileUrl(videoUrl, true)

    try {
      // Fetch the video as a Blob using axios
      const response = await http({
        url: videoUrl,
        method: 'GET',
        responseType: 'blob', // Tell axios to return the response as a Blob
        onDownloadProgress,
        transformRequest: (data, headers) => {
          delete headers.Authorization
          return data
        },
      })

      const blob = response.data
      let videoSize = (blob.size / (1024 * 1024)).toFixed(2)

      // Create an object URL that points to the Blob
      const objectUrl = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')
      link.href = objectUrl

      // Set the download attribute and the file name
      link.download = videoTitle

      // Add the link to the document
      document.body.appendChild(link)

      // Click the link to start the download
      link.click()

      // Remove the link from the document
      document.body.removeChild(link)

      // Revoke the object URL
      URL.revokeObjectURL(objectUrl)

      mixpanel.track('video_downloaded', {
        file_size: videoSize,
        memoryId: canvas.memory_id,
      })

      trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.VIDEO_DOWNLOAD, {
        memory_id: canvas.memory_id,
        video_type: canvas.settingsUsed.videoType,
        video_file_size: videoSize,
        video_quality: 1, // to implement for different video qualities https://height.app/M9jzUFTFNu/T-6200
        video_version: canvas.settingsUsed?.version,
        video_is_watermarked: isSubscribed ? 'null' : true, // Null values that are forwarded from the frontend, become "null" in mixpanel, we intentionally added this so all be "null" since null renders as undefined in Mixpanel
        video_download_url: videoUrl,
        page_url: window.location.origin,
        stableDiffusionCheckpoint:
          canvas.settingsUsed?.stableDiffusionCheckpoint,
      })

      // Enable download button
      setIsDownloading(false)
      setDownloadPercent(0)
      toast.success('Video downloaded.')
    } catch (error) {
      console.log(error)

      // open videourl in new tab
      window.open(videoUrl, '_blank')
    }
  }

  const onLoaded = () => {
    setIsLoaded(true)
  }

  const fetchMemory = useCallback(async () => {
    console.log('fetchMemory function called')
    try {
      if (isMounted.current) {
        const response = await http.get('/api/get_memory/' + canvas.memory_id)
        if (isUpscaling) {
          if (response.data.memory.upscale_status === FAILED) {
            onUpscaleError(canvas.memory_id)
            toast.error('Upscaling failed')
          } else if (
            !response.data.memory.upscale_status ||
            response.data.memory.upscale_status !== 'pending'
          ) {
            onUpscaleSuccess(canvas.memory_id)
            refreshCredits()
          } else {
            await new Promise((resolve) => setTimeout(resolve, 3000))
            if (isMounted.current) fetchMemory() // Check if component is still mounted before making another request
          }
        } else {
          if (response.data.memory.status === 'done') {
            onVideoStatusChange(
              canvas._id,
              'done',
              response.data.memory.mediaUrl,
            )
            setIsLoaded(false)
            refreshCredits()
          } else if (response.data.memory.status === FAILED) {
            onVideoStatusChange(canvas._id, FAILED, null)
          } else {
            await new Promise((resolve) => setTimeout(resolve, 3000))
            if (isMounted.current) fetchMemory() // Check if component is still mounted before making another request
          }
        }
      }
    } catch (err) {
      console.error(err)
      await new Promise((resolve) => setTimeout(resolve, 3000))
      if (isMounted.current) fetchMemory() // Check if component is still mounted before making another request
    }
  }, [
    canvas._id,
    canvas.memory_id,
    isUpscaling,
    onUpscaleError,
    onUpscaleSuccess,
    onVideoStatusChange,
    setIsLoaded,
    refreshCredits,
  ])

  const handleTryAgain = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()

      if (isRequeueing) {
        return
      }

      setIsRequeueing(true)

      // Send request to rerun video generation
      await http.post('/api/requeue_video', {
        memory_id: canvas.memory_id,
      })

      onVideoStatusChange(canvas._id, 'pending', null)
      fetchMemory()
    } catch (err) {
      console.log(err)
      toast.error(err.response?.data || 'Oops! Something went wrong.')
    } finally {
      setIsRequeueing(false)
    }
  }

  const handleMouseEnter = () => {
    setIsVideoHover(true)
    setShowOverlay(true)
    console.log('canvas', canvas)
    clearTimeout(timeoutRef.current)
    videoRef.current?.play().catch((error) => {
      videoRef.current.load()
    })
    setEventStartTime(videoRef.current ? videoRef.current.currentTime : 0)
  }

  const handleMouseMove = () => {
    setShowOverlay(true)
    setShowMakePublic(true)
    clearTimeout(timeoutRef.current)
    if (videoRef.current) {
      let autoHideTime = CURSOR_AUTOHIDE_TIME
      if (
        aspectRatio === '16 / 9' ||
        aspectRatio === '14 / 9' ||
        aspectRatio === ' / '
      ) {
        autoHideTime = CURSOR_AUTOHIDE_TIME_FAST
      }
      timeoutRef.current = setTimeout(() => {
        setShowOverlay(false)
        setShowMakePublic(false)
      }, autoHideTime)
    }
  }

  const handleMouseLeave = () => {
    // MOBILE - mouse leave is triggered when you click in the video then click anywhere
    // outside the video, which runs the code below to pause the video.
    setIsVideoHover(false)
    setShowOverlay(false)
    setShowMakePublic(true)
    clearTimeout(timeoutRef?.current)

    setEventEndTime(videoRef?.current?.currentTime)
    setEventMemoryId(canvas.memory_id)
    setEventMethod('hover')

    if (videoRef?.current) {
      videoRef.current.currentTime = VIDEO_TIME_OFFSET
      videoRef.current.pause()
    }
  }

  useEffect(() => {
    const setVideo = async () => {
      // mobile only code ///////
      const mobile = checkMobile()
      setIsMobile(mobile)
      function handleMobileScroll() {
        setIsMobileScrolling(true)
        setIsMobilePressed(false)
      }
      function handleStopMobileScroll() {
        setIsMobileScrolling(false)
      }
      if (mobile) {
        // event listener to stop videos when the user scrolls on mobile
        window.addEventListener('scroll', () => handleMobileScroll())

        let scrollTimeout
        window.addEventListener('scroll', () => {
          clearTimeout(scrollTimeout)
          scrollTimeout = setTimeout(() => {
            handleStopMobileScroll()
          }, 250)
        })
      }
      // /mobile only code ///

      if (canvas.initImageUrl) {
        // use preview frame for video thumbnail if available
        setPreviewFrame(canvas.initImageUrl)
        setIsLoaded(true)
      } else if (canvas.initImageKey) {
        // get S3 image URL - we do it as a separate API call because it takes too long
        // to include this data in the initial API call for all videos
        const signedUrl = await http.get(`/api/get_signed_url`, {
          params: {
            asset: canvas?.initImageKey ?? '',
          },
        })
        setPreviewFrame(signedUrl.data.signedUrl)
        setIsLoaded(true)
      }
      // if not available, this is a Motion video - just use the 5 second Motion video on autoplay

      // get video settings for Copy Prompt function if not set -- we do it as a separate API call because
      // it takes too long to include this data in the initial API call for all videos
      if (!canvas.settingsUsed) {
        const videoSettings = await http.get(
          `/api/get_video_settings/` + canvas.memory_id,
        )
        canvas.settingsUsed = videoSettings.data.settings
      }

      if (canvas.status === 'pending' || canvas.upscale_status === 'pending') {
        console.log('fetchMemory')
        fetchMemory()
      }

      return () => {
        isMounted.current = false
        window.removeEventListener('scroll', handleMobileScroll)
      }
    }

    if (canvas.upscale_status === 'done') setIsUpscaled(true)

    setVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUpscaling) setIsLoaded(false)
  }, [isUpscaling])

  useEffect(() => {
    const video = videoRef.current

    const handleLoadedMetadata = () => {
      // this fixes an iOS bug for no inital frame when preload='metadata' on video element
      if (video) video.currentTime = 0
    }

    if (video) {
      video.addEventListener('loadedmetadata', handleLoadedMetadata)
    }

    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [])

  useEffect(() => {
    if (
      firstVideoEvent === 'abort' ||
      firstVideoEvent === 'error' ||
      firstVideoEvent === 'stalled'
    ) {
      //if the first event of the video is abort or error, reload the video
      if (!!videoRef?.current) {
        videoRef.current.load()
      }
    }
  }, [firstVideoEvent])

  const updateTimer = useCallback(() => {
    intervalRef.current =
      !intervalRef?.current &&
      setInterval(() => {
        setTimerValue((prevTimerValue) => prevTimerValue + 1)
      }, 1000)

    if (!onProgressTriggered && timerValue > 2) {
      let imgElement = document.getElementById(`img-${canvas.initImageUrl}`)

      if (imgElement && !isLoaded && !imgError) {
        setImgError(true)
        setIsLoaded(true)
      }
    }

    if (!onProgressTriggered && timerValue > 7) {
      //after 7 seconds reload the video if on progress never triggered
      if (!!videoRef?.current) {
        if (srcUpdated) {
          videoRef.current.load()
        }
      }
    } else if (!onProgressTriggered && timerValue > 12) {
      clearInterval(intervalRef.current)
    } else if (onProgressTriggered) {
      clearInterval(intervalRef.current)
    }
  }, [
    canvas.initImageUrl,
    imgError,
    isLoaded,
    onProgressTriggered,
    srcUpdated,
    timerValue,
  ])

  useEffect(() => {
    if (timerValue !== null) {
      updateTimer()
    }

    return () => clearInterval(intervalRef.current)
  }, [timerValue, updateTimer])

  const handlePhraseFilter = (e) => {
    const innerText = e.target.innerText
    const phrase =
      innerText === ANIMATED_IMAGE_PROMPT_COPY
        ? ANIMATED_IMAGE_PROMPT
        : innerText.replace(',', '').trim()
    setSearchText(phrase)
    searchInputRef.current.value = phrase
    searchInputRef.current.focus()
    scrollToTop()
    getVideos()
  }

  const handleVideoEvent = (value) => {
    if (firstVideoEvent === null) {
      setFirstVideoEvent(value)
    }

    if (value === 'progress') {
      setOnProgressTriggered(true)
    }
  }

  const onImgError = () => {
    setImgError(true)
    setIsLoaded(true)
  }

  const playVideoMobile = async () => {
    if (isMobilePressed) {
      navigate(href)
    } else {
      setIsMobilePressed(true)
    }
  }

  // Function to check if user is on a mobile device
  const checkMobile = () => {
    const userAgent = window.navigator.userAgent
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    )
  }

  useEffect(() => {
    const calculateAspectRatio = async () => {
      const img = new Image()
      img.src = previewFrame

      try {
        await img.decode()
        const width = img.width
        const height = img.height
        const ratio = width / height

        // Round the aspect ratio to the nearest fraction
        const fractionPrecision = 0.05
        const roundedRatio =
          Math.round(ratio / fractionPrecision) * fractionPrecision

        setAspectRatio(`${roundedRatio} / 1`)
      } catch (error) {
        console.error('Error loading image:', error)
      }
    }

    calculateAspectRatio()
  }, [previewFrame])

  return (
    <div className='shadow pb-7 break-inside-avoid-column fade-in'>
      <div
        className='relative overflow-hidden transition-all duration-500 ease-in-out rounded-xl hover:rounded-none min-h-[140px]'
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <Link to={href}>
          {(typeof canvas.status === 'undefined' || canvas.status === 'done') &&
          !isUpscaling ? (
            <div
              style={{
                aspectRatio: aspectRatio,
                backgroundImage: `url(${previewFrame})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {!isVideoHover && !isMobilePressed ? (
                <div></div>
              ) : (
                <video-js>
                  <video
                    id={canvas._id}
                    preload='metadata'
                    controls={false}
                    loop={true}
                    muted={true}
                    playsInline={true}
                    autoPlay='autoplay'
                    onCanPlay={onLoaded}
                    onError={() => {
                      handleVideoEvent('error')
                    }}
                    onAbort={() => {
                      handleVideoEvent('abort')
                    }}
                    onStalled={() => {
                      handleVideoEvent('stalled')
                    }}
                    onSuspend={() => {
                      handleVideoEvent('suspend')
                    }}
                    onProgress={() => {
                      handleVideoEvent('progress')
                    }}
                    className={classNames(
                      'object-cover w-full ease-in-out transition-all duration-700',
                      {
                        'scale-[1.03]': isVideoHover,
                        'cursor-none': !showOverlay,
                      },
                    )}
                    style={{ display: isLoaded ? 'block' : 'none' }}
                    ref={videoRef}
                  >
                    <source src={canvas.url} type='video/mp4' />
                    {/* <source src={`${canvas.url}#t=${VIDEO_TIME_OFFSET}`} type='video/mp4' /> */}
                  </video>
                </video-js>
              )}
            </div>
          ) : (
            <>
              {!imgError ? (
                <img
                  id={`img-${canvas.initImageUrl}`}
                  src={canvas.initImageUrl}
                  onLoad={onLoaded}
                  onError={onImgError}
                  className='object-cover w-full rounded-xl'
                  style={{ display: isLoaded ? 'block' : 'none' }}
                  alt={canvas.prompt}
                />
              ) : (
                <Skeleton aspectRatio={aspectRatio} />
              )}

              {isLoaded && (
                <div className='absolute inset-0 bg-black bg-opacity-75'>
                  <div className='flex flex-col items-center justify-center w-full h-full backdrop-blur-sm'>
                    <div>
                      {(canvas.status === 'pending' || isUpscaling) && (
                        <>
                          <div className='flex flex-col items-center m-auto space-y-4'>
                            <div
                              className={`bg-darkGray ${
                                canvas.H > canvas.W ? 'p-6' : 'p-4'
                              } rounded-3xl`}
                            >
                              <Lottie
                                animationData={LoadingWhite}
                                loop={true}
                                className={` ${
                                  canvas.H > canvas.W
                                    ? 'w-16 h-16'
                                    : 'w-10 h-10'
                                }`}
                              />
                            </div>
                          </div>
                          <p
                            className={`text-white ${
                              canvas.H > canvas.W ? 'mt-8' : 'mt-4'
                            }`}
                          >
                            {canvas.status === 'pending'
                              ? 'Crafting your masterpiece...'
                              : 'Video is being upscaled...'}
                          </p>
                        </>
                      )}

                      {canvas.status === FAILED && (
                        <CanvasFailed
                          canvas={canvas}
                          handleTryAgain={handleTryAgain}
                          isRequeueing={isRequeueing}
                          onDelete={onDelete}
                          showFailedIcon={canvas.H > canvas.W}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Link>

        {isMobile && (
          <div className='absolute inset-0' onClick={playVideoMobile}>
            <div
              className={isMobileScrolling ? 'fade-in-200' : 'fade-out-200'}
              style={{
                backgroundSize: '30%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${playButtonImage})`,
                aspectRatio: aspectRatio,
                opacity: isMobileScrolling ? 0.6 : 0,
                transition: 'opacity 0.5s ease',
              }}
            ></div>
          </div>
        )}

        {canvas.status !== FAILED && !isMobile && (
          <VideoOverlay
            showOverlay={showOverlay}
            isVideoHover={isVideoHover}
            canvas={canvas}
            handlePhraseFilter={handlePhraseFilter}
            isDashboard={isDashboard}
            isSubscribed={isSubscribed}
            onDelete={onDelete}
            onInfo={onInfo}
            onMakePublic={onMakePublic}
            isMakingPublic={isMakingPublic}
            showMakePublic={showMakePublic}
            isDownloading={isDownloading}
            onDownload={onDownload}
            downloadVideo={downloadVideo}
            downloadPercent={downloadPercent}
            ref={promptRef}
            isAdmin={isAdmin}
            isUpscaled={isUpscaled}
          />
        )}
      </div>
    </div>
  )
}
