import http from './HttpService'

// TODO: Move to /api directory and convert to TS
export const getUserProfile = async (username) => {
  const response = await http.get(`/api/v1/profile`, {
    params: {
      username,
    },
  })

  return response.data
}

export const updateSocialLinks = async (links) =>
  await http.patch('/api/v1/profile/links', { links })

export const updateBio = async (bio) => {
  return await http.patch(`/api/v1/profile/bio`, { bio })
}
