import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { RestoreSubscriptionModal } from '../../components/RestoreSubscriptionModal'
import { impersonateLogin } from '../../services/AuthService'
import http from '../../services/HttpService'
import { cn } from '../../utils'
import { Button } from '../Button'
import { UpdateUserCreditsForm } from './UpdateUserCreditsForm'

export default function UserDetailsTab({
  user,
  setUser,
  creditsSpent,
  purchasedCredits,
  onAccountBan,
  refreshUser,
}) {
  // state vars for restore Stripe subscription functionality
  const [
    isRestoreSubscriptionModalActive,
    setIsRestoreSubscriptionModalActive,
  ] = useState(false)

  const [paddleSubscriptionMismatch, setPaddleSubscriptionMismatch] =
    useState(false)
  const [missingPaddleSubscription, setMissingPaddleSubscription] =
    useState(false)
  const [missingStripeSubscription, setMissingStripeSubscription] =
    useState(false)

  const [hasGoogleSubscription, setHasGoogleSubscription] = useState(false)
  const [hasAppleSubscription, setHasAppleSubscription] = useState(false)
  const [subscriptions, setSubscriptions] = useState({})
  const [areSubscriptionsLoaded, setAreSubscriptionsLoaded] = useState(false)

  const formatRegisterDate = (createdAt) => {
    return moment(createdAt).utc().format('LLL')
  }

  const formatLastActiveDate = (lastActive) => {
    if (lastActive) {
      return moment
        .duration({
          from: lastActive,
        })
        .humanize()
    }

    return null
  }

  const formatPasswordExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return null
    }
    return moment.duration({ to: expiryDate }).as('minutes') >= 0
      ? moment.duration({ from: expiryDate }).humanize()
      : moment.duration({ to: expiryDate }).humanize(true)
  }

  const capitalize = (str) => {
    return str[0].toUpperCase() + str.substr(1)
  }

  const handleImpersonateLogin = async (e) => {
    await impersonateLogin(user.distinctId)
    window.location.href = '/'
  }

  const toggleRestoreSubscriptionModal = async (isOpen) => {
    setIsRestoreSubscriptionModalActive(isOpen)
  }

  const customerLink = useMemo(() => {
    if (user.paymentGateway === 'paddle') {
      return `https://vendors.paddle.com/customers-v2/${user.paddleCustomerId}`
    }

    return `https://dashboard.stripe.com/customers/${user.stripeCustomerId}`
  }, [user])

  const analyzeSubscription = (subscriptions) => {
    // Scenarios that can be resolved by the code:
    // user has no Kaiber subscription but has an active subscription in Stripe
    // user has no Kaiber subscription but has an active subscription in Paddle
    // user has Kaiber subscription but doesn't match Paddle
    //
    // No subscription state -- link to add IAP subscription by Google or Apple order ID
    //    Problem to watch out for: it could have been added somewhere else -- way to check if
    //    that order ID is associated with another Kaiber user.
    //    User gives us
    //        GPA.3340-7993-8915-04573 for Google Play subscription or
    //        460001697345755 for Apple subscription.
    //
    // Warning
    // user has subscriptions in both Stripe and Paddle
    // user has multiple subscriptions in Stripe
    // user has multiple subscriptions in Paddle
    // user has a Kaiber IAP subscription and also a Stripe or Paddle or both subscription

    const paddleSubscriptionsMap = {
      'Kaiber Explorer sub': 'Explorer Monthly',
      'Kaiber Explorer sub trial': 'Explorer Monthly',
      'Kaiber Pro Monthly': 'Pro Monthly',
      'Kaiber Artist Monthly': 'Artist Monthly',
      'Kaiber Pro Yearly': 'Pro Yearly',
      'Kaiber Artist Yearly': 'Artist Yearly',
    }

    const {
      stripeSubscriptions,
      paddleSubscriptions,
      googleTransaction,
      appleTransaction,
    } = subscriptions

    if (!user.subscriptionType) {
      if (stripeSubscriptions.length > 0) {
        // user has no Kaiber subscription but has an active subscription in Stripe
        setMissingStripeSubscription(true)
      }
      if (paddleSubscriptions.length > 0) {
        // user has no Kaiber subscription but has an active subscription in Stripe
        setMissingPaddleSubscription(true)
      }
      if (googleTransaction) {
        // user has no Kaiber subscription but has an active Google subscription
        setHasGoogleSubscription(true)
      }
      if (appleTransaction) {
        // user has no Kaiber subscription but has an active Apple subscription
        setHasAppleSubscription(true)
      }
    }
    // Handle subscription mismatch
    if (
      user.subscriptionType === 'Standard' &&
      user.paymentGateway === 'paddle'
    ) {
      // user Kaiber subscription doesn't match
      let paddleMatch = false
      for (const paddleSubscription of paddleSubscriptions) {
        if (
          user.subscriptionTierDescription ===
          paddleSubscriptionsMap[paddleSubscription.items[0].price.description]
        ) {
          paddleMatch = true
        }
      }
      if (!paddleMatch) {
        setPaddleSubscriptionMismatch(true)
      }
    }
  }

  useEffect(() => {
    const checkStripeRestoreSubscriptions = async () => {
      // If user doesn't have an active subscription, run a check to see if they have an active Stripe subscription
      // This check is done due to a bug where Stripe subscriptions and MongoDB can become out of sync.
      try {
        const res = await http.get(
          `/api/admin/users/${user.distinctId}/subscriptions`,
          {},
        )
        if (res.status === 204) {
          console.log('No active subscriptions')
          return
        }
        setSubscriptions(res.data)
        setAreSubscriptionsLoaded(true)
        analyzeSubscription(res.data)
      } catch (error) {
        console.error(error)
      }
    }
    setAreSubscriptionsLoaded(false)
    checkStripeRestoreSubscriptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.distinctId])

  return (
    <>
      <OrderSearchSection
        user={user}
        toggleRestoreSubscriptionModal={toggleRestoreSubscriptionModal}
        setHasAppleSubscription={setHasAppleSubscription}
        setHasGoogleSubscription={setHasGoogleSubscription}
        setSubscriptions={setSubscriptions}
        subscriptions={subscriptions}
      />
      <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-6 bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
        <div>
          <h4 className='text-tertiary/80'>Name</h4>
          <p className='text-white'>{user.name || 'None'}</p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Email</h4>
          <p className='text-white'>{user.email}</p>
          <p className='text-tertiary/80'>
            ({!!user.emailVerifiedAt ? 'verified' : 'not verified'})
          </p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Signed Up</h4>
          <p className='text-white'>{formatRegisterDate(user.createdAt)}</p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Role</h4>
          <p className='text-white'>{capitalize(user.role)}</p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Subscription</h4>
          <div className='flex gap-3 mb-5'>
            <p className='text-white'>
              {user?.subscriptionType
                ? `${user?.paymentGateway ? capitalize(user?.paymentGateway) : ''}: ${
                    user?.subscriptionTierDescription
                  }`
                : 'None'}
            </p>

            {
              // User does not have a Kaiber subscription
              areSubscriptionsLoaded && !user.subscriptionType ? (
                // User has a Stripe subscription
                subscriptions.stripeSubscriptions?.length > 0 ? (
                  hasGoogleSubscription ? (
                    <SubscriptionMismatchIcon
                      message='This user has an active Stripe and Google subscription but no Kaiber subscription.'
                      toggleModalCta={toggleRestoreSubscriptionModal}
                    />
                  ) : hasAppleSubscription ? (
                    <SubscriptionMismatchIcon
                      message='This user has an active Stripe and Apple subscription but no Kaiber subscription.'
                      toggleModalCta={toggleRestoreSubscriptionModal}
                    />
                  ) : (
                    <SubscriptionMismatchIcon
                      message='This user has an active Stripe subscription but no Kaiber subscription.'
                      toggleModalCta={toggleRestoreSubscriptionModal}
                    />
                  )
                ) : // User has a Paddle subscription
                subscriptions.paddleSubscriptions?.length > 0 ? (
                  hasGoogleSubscription ? (
                    <SubscriptionMismatchIcon
                      message='This user has an active Paddle and Google subscription but no Kaiber subscription.'
                      toggleModalCta={toggleRestoreSubscriptionModal}
                    />
                  ) : hasAppleSubscription ? (
                    <SubscriptionMismatchIcon
                      message='This user has an active Paddle and Apple subscription but no Kaiber subscription.'
                      toggleModalCta={toggleRestoreSubscriptionModal}
                    />
                  ) : (
                    <SubscriptionMismatchIcon
                      message='This user has an active Paddle subscription but no Kaiber subscription.'
                      toggleModalCta={toggleRestoreSubscriptionModal}
                    />
                  )
                ) : hasGoogleSubscription && hasAppleSubscription ? (
                  <SubscriptionMismatchIcon
                    message='This user has an active Google and Apple subscription but no Kaiber subscription.'
                    toggleModalCta={toggleRestoreSubscriptionModal}
                  />
                ) : hasGoogleSubscription ? (
                  <SubscriptionMismatchIcon
                    message='This user has an active Google subscription but no Kaiber subscription.'
                    toggleModalCta={toggleRestoreSubscriptionModal}
                  />
                ) : hasAppleSubscription ? (
                  <SubscriptionMismatchIcon
                    message='This user has an active Apple subscription but no Kaiber subscription.'
                    toggleModalCta={toggleRestoreSubscriptionModal}
                  />
                ) : null
              ) : paddleSubscriptionMismatch ? (
                <SubscriptionMismatchIcon
                  message='This user has a Kaiber subscription that does not match their Paddle subscription.'
                  toggleModalCta={toggleRestoreSubscriptionModal}
                />
              ) : null
            }
          </div>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Last Active</h4>
          <p className='text-white'>
            {formatLastActiveDate(user.lastActive) || 'None'}
          </p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>
            <a
              href={customerLink}
              className='hover:text-primary transition-all'
              target='_blank'
              rel='noreferrer'
            >
              Purchased Credits
              <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
            </a>
          </h4>
          <p className='text-white'>{purchasedCredits}</p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Credits Spent</h4>
          <p className='text-white'>{creditsSpent}</p>
        </div>
        <div>
          <h4 className='text-tertiary/80'>Current Credits</h4>
          <p className='text-white'>{user.credits}</p>
        </div>
        {/* Impersonate */}
        <div>
          <h4 className='text-tertiary/80'>Login</h4>
          <div
            onClick={(e) => {
              handleImpersonateLogin()
            }}
            className='group cursor-pointer'
          >
            <p className='text-primary group-hover:underline'>
              Login as {user.email}
            </p>
          </div>
        </div>
        {/* Ban Account */}
        <div>
          <h4 className='text-tertiary/80'>Ban Account</h4>
          <div
            onClick={(e) => {
              onAccountBan(
                true,
                user.distinctId,
                user._id,
                user.email,
                user.banned,
              )
            }}
            className='group cursor-pointer'
          >
            {!user.banned ? (
              <p className='text-primary group-hover:underline'>
                Ban {user.email}
              </p>
            ) : (
              <p className='group-hover:underline text-red-500'>
                Unban {user.email}
              </p>
            )}
          </div>
        </div>
        {/* Reset password */}
        {user.passwordResetToken && (
          <div>
            <h4 className='text-tertiary/80'>
              <a
                href={
                  process.env.REACT_APP_SITE_URL +
                  '/password/reset/' +
                  user.passwordResetToken
                }
                className='hover:text-primary transition-all'
                target='_blank'
                rel='noreferrer'
              >
                Reset Password Link
                <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
              </a>
            </h4>
            <p className='text-white'>
              Expires:{' '}
              {formatPasswordExpiryDate(user.passwordResetTokenExpiresAt) ||
                'None'}
            </p>
          </div>
        )}
      </div>
      <h2 className='text-tertiary/80 text-3xl font-bold mt-8'>
        Update Credits
      </h2>
      <div className='mt-4'>
        <UpdateUserCreditsForm user={user} setUser={setUser} />
      </div>

      <RestoreSubscriptionModal
        isOpen={isRestoreSubscriptionModalActive}
        distinctId={user.distinctId}
        missingPaddleSubscription={missingPaddleSubscription}
        missingStripeSubscription={missingStripeSubscription}
        paddleSubscriptionMismatch={paddleSubscriptionMismatch}
        hasGoogleSubscription={hasGoogleSubscription}
        hasAppleSubscription={hasAppleSubscription}
        subscriptions={subscriptions}
        onClose={toggleRestoreSubscriptionModal}
        refreshUser={refreshUser}
      />
    </>
  )
}

function OrderSearchSection({
  user,
  toggleRestoreSubscriptionModal,
  setHasAppleSubscription,
  subscriptions,
  setSubscriptions,
  setHasGoogleSubscription,
}) {
  const [googleSubscriptionQuery, setGoogleSubscriptionQuery] = useState('')
  const [googlePurchaseTokenQuery, setGooglePurchaseTokenQuery] = useState('')
  const [googleProductId, setGoogleProductId] = useState('')

  const [googleSubscriptionQueryLoading, setGoogleSubscriptionQueryLoading] =
    useState(false)

  const [showMoreGoogleQueryOptions, setShowMoreGoogleQueryOptions] =
    useState(false)

  const [appleSubscriptionQuery, setAppleSubscriptionQuery] = useState('')
  const [appleSubscriptionQueryLoading, setAppleSubscriptionQueryLoading] =
    useState(false)

  const [order, setOrder] = useState()
  const [showOrder, setShowOrder] = useState(true)

  function handleGoogleSubscriptionSearch() {
    setGoogleSubscriptionQueryLoading(true)
    http
      .get('/api/get_google_order', {
        params: {
          orderId: googleSubscriptionQuery,
          purchaseToken: googlePurchaseTokenQuery,
          productId: googleProductId,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          toast.warning('No order found.')
          return
        }
        if (res.status === 406) {
          toast.error(
            'Error searching for order in Google Play, missing purchase token or product ID.',
          )
          return
        }
        const { data } = res
        if (data.startTimeMillis) {
          data.startTimeMillis = new Date(parseInt(data.startTimeMillis, 10))
        }
        if (data.expiryTimeMillis) {
          data.expiryTimeMillis = new Date(parseInt(data.expiryTimeMillis, 10))
        }
        setOrder(data)
        setShowOrder(true)
      })
      .catch((err) => {
        console.error('err', err)
        const res = err.response
        const statusCode = res.status
        if (statusCode === 406) {
          toast.error(res.data.message)
          return
        }
        toast.error('Something went wrong.')
      })
      .finally(() => {
        setGoogleSubscriptionQueryLoading(false)
      })
  }

  function handleAppleSubscriptionSearch() {
    setAppleSubscriptionQueryLoading(true)
    http
      .get('/api/get_apple_order', {
        params: {
          id: appleSubscriptionQuery,
          userId: user.distinctId,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          toast.warning('No Apple subscription found with that ID.')
          return
        }
        setOrder(res.data)
        setShowOrder(true)
      })
      .catch((err) => {
        console.error('err', err)
      })
      .finally(() => {
        setAppleSubscriptionQueryLoading(false)
      })
  }

  function handleRestoreButtonClick() {
    if (!order) {
      toast.warning('No order found to restore.')
      return
    }

    if (order.transactionId) {
      setHasAppleSubscription(true)
      setHasGoogleSubscription(false)
      setSubscriptions({
        ...subscriptions,
        appleTransactions: [order],
      })
      toggleRestoreSubscriptionModal(true)

      return
    }

    // Handle apple restore
    if (order.transaction_id) {
      setHasAppleSubscription(true)
      setSubscriptions({
        ...subscriptions,
        appleTransactions: [order],
      })
      toggleRestoreSubscriptionModal(true)
      return
    }
    // Handle google restore
    if (order.orderId) {
      if (googleProductId) {
        order.productId = googleProductId
      }
      setHasGoogleSubscription(true)
      setSubscriptions({
        ...subscriptions,
        googleTransaction: order,
      })
      toggleRestoreSubscriptionModal(true)
      return
    }
  }

  return (
    <div className='border-[1px] p-1 border-red-800 rounded-md mb-3 bg-red-950'>
      <p className='text-red-700 mb-3 uppercase'>
        This feature is under development, proceed with caution
      </p>
      <div className='flex flex-row gap-3 mb-3 items-start'>
        {/* Google query */}
        <div className='flex flex-row gap-0 w-full items-start justify-center'>
          <button
            type='button'
            className='inline-flex items-center rounded-l-full border bg-transparent px-4 py-2 text-xs h-10 font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
            onClick={() => {
              setShowMoreGoogleQueryOptions(!showMoreGoogleQueryOptions)
            }}
          >
            {showMoreGoogleQueryOptions ? (
              <ChevronDownIcon className='w-4 h-4 inline-block' />
            ) : (
              <ChevronRightIcon className='w-4 h-4 inline-block' />
            )}
          </button>
          <div className='flex-col w-full'>
            <input
              type='text'
              placeholder='Google Play Order ID'
              className='w-full px-4 py-2 text-white bg-darkGray focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50'
              name='googleSubscriptionQuery'
              onChange={(e) => {
                setGoogleSubscriptionQuery(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGoogleSubscriptionSearch()
                }
              }}
            />
            {showMoreGoogleQueryOptions ? (
              <>
                <input
                  type='text'
                  placeholder='Google Play Purchase Token'
                  className='w-full px-4 py-2 text-white bg-darkGray border-t-[1px] border-primary/25 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50'
                  name='googlePurchaseTokenQuery'
                  onChange={(e) => {
                    setGooglePurchaseTokenQuery(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleGoogleSubscriptionSearch()
                    }
                  }}
                />
                <input
                  type='text'
                  placeholder='Google Play Product ID'
                  className='w-full px-4 py-2 text-white bg-darkGray rounded-b-2xl border-t-[1px] border-primary/25 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50'
                  name='googleProductIdQuery'
                  onChange={(e) => {
                    setGoogleProductId(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleGoogleSubscriptionSearch()
                    }
                  }}
                />
              </>
            ) : null}
          </div>
          <button
            type='button'
            className='inline-flex items-center rounded-r-full border bg-transparent px-4 py-2 text-xs h-10 font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
            onClick={handleGoogleSubscriptionSearch}
          >
            {googleSubscriptionQueryLoading ? 'Loading' : 'Search'}
          </button>
        </div>
        {/* Apple */}
        <div className='flex flex-row gap-0 w-full items-center justify-center'>
          <input
            type='text'
            name='appleSubscriptionQuery'
            placeholder='Search Apple Order ID'
            className='w-full px-4 py-2 text-white bg-darkGray rounded-l-2xl focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50'
            onChange={(e) => {
              setAppleSubscriptionQuery(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAppleSubscriptionSearch()
              }
            }}
          />
          <button
            type='button'
            className='inline-flex items-center rounded-r-full border bg-transparent px-4 py-2 text-xs h-10 font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
            onClick={handleAppleSubscriptionSearch}
          >
            {appleSubscriptionQueryLoading ? 'Loading' : 'Search'}
          </button>
        </div>
      </div>
      <div>
        {order && showOrder ? (
          <div className='bg-darkGray px-2 py-1 sm:p-6 shadow rounded-2xl mb-3'>
            <div className='flex justify-between w-full'>
              <div>
                {order.auth0_user_id &&
                user.distinctId !== order.auth0_user_id ? (
                  <div className='flex items-center text-white text-sm gap-1'>
                    <ExclamationTriangleIcon className='w-4 h-4 inline-block text-orange-400' />
                    <span className='italic'>
                      IMPORTANT: The owner of this transaction is not the same
                      as the current user
                    </span>
                  </div>
                ) : null}
              </div>
              <div className='flex gap-3'>
                {(user.distinctId === order.auth0_user_id ||
                  !order.auth0_user_id) && (
                  <Button
                    className={cn(
                      'text-xs h-4 flex items-center justify-center w-6',
                    )}
                    onClick={handleRestoreButtonClick}
                  >
                    Restore
                  </Button>
                )}
                <Button
                  className={cn(
                    'text-xs h-4 flex items-center justify-center w-6',
                  )}
                  onClick={() => {
                    setOrder(null)
                  }}
                >
                  X
                </Button>
              </div>
            </div>
            <div>
              {Object.entries(order).map(([key, value]) => {
                return <OrderDetailsField key={key} label={key} value={value} />
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function OrderDetailsField({ label, value }) {
  if (label === 'autoRenewing') {
    return (
      <div className='flex flex-row gap-3' key={label}>
        <h4 className='text-tertiary/80'>{label}:</h4>
        <p className='text-white font-mono'>{value ? 'true' : 'false'}</p>
      </div>
    )
  }

  return (
    <div className='flex flex-row gap-3'>
      <h4 className='text-tertiary/80'>{label}:</h4>
      <p className='text-white font-mono'>{String(value)}</p>
    </div>
  )
}

function SubscriptionMismatchIcon({ message, toggleModalCta }) {
  function handleClick() {
    toggleModalCta(true)
  }

  return (
    <div
      className='flex has-tooltip'
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <span className='px-2 py-1 -mt-8 text-gray-100 bg-gray-800 rounded shadow-lg tooltip'>
        {message}
        <br />
        Click here to restore the subscription.
      </span>
      <ExclamationTriangleIcon
        className='w-4 h-4 inline-block text-orange-400'
        style={{ marginTop: '.35em' }}
      />
    </div>
  )
}
