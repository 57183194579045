import { cn } from '../../utils'

/**
 * Create a custom button component.
 *
 * @param {Object} props - The properties for the button.
 * @param {Function} props.onClick - The click event handler.
 * @param {string} [props.type="button"] - The type of the button (button, reset, submit).
 * @param {string} [props.className=""] - The CSS class for the button.
 * @param {string} [props.name=""] - A name for the button.
 * @param {string} [props.value=""] - An initial value for the button.
 * @param {boolean} [props.autofocus=false] - Specifies if the button should automatically get focus.
 * @param {boolean} [props.disabled=false] - Specifies if the button should be disabled.
 * @returns {React.ReactNode} A React button element.
 */
const Button = ({
  onClick,
  type = 'button',
  className = '',
  name = '',
  value = '',
  autofocus = false,
  disabled = false,
  form = '',
  children,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={cn(
        'p-3 w-full mt-14 md:mt-0 md:w-28 text-base font-bold text-black border rounded-full shadow-sm bg-kaiberGreen border-primary relative',
        className,
        disabled && 'bg-primary/25 border-primary/25',
      )}
      name={name}
      value={value}
      autoFocus={autofocus}
      disabled={disabled}
      form={form}
    >
      {children}
    </button>
  )
}

export { Button }
