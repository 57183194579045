import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { HomeIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

/**
 * Not Found page
 * @param {Object} param0 props
 * @param {String | undefined} [param0.customMessage] Custom message to display
 * @returns {JSX.Element} Not Found page
 */
export default function NotFound({ customMessage }) {
  const defaultMessage = 'The page you are looking for could not be found.'
  const message = customMessage ?? defaultMessage

  return (
    <div className='bg-black'>
      <Navbar />
      <div className='flex px-5 text-center py-12 lg:py-24 pt-28 min-h-[60vh] justify-center items-center'>
        <div className='space-y-4'>
          <h1 className='text-white text-8xl'>
            <span className='font-bold'>404</span>
          </h1>
          <p className='text-white/50'>{message}</p>
          <Link to='/'>
            <button className='inline-flex mt-6 justify-center items-center min-w-[200px] w-fit leading-5 text-xl rounded-full bg-primary hover:bg-primary/90 active:bg-primary/40 px-4 py-3 font-medium text-black shadow-sm duration-300'>
              Go back home
              <HomeIcon className='w-6 h-6' />
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}
