import { useEffect, useState } from 'react'

import EyeOpened from '../../images/EyeOpened.svg'
import EyeClosed from '../../images/EyeClosed.svg'
import classNames from 'classnames'

export const MakePublicButton = ({
  onMakePublic,
  canvas,
  isCanvasCellFocused,
  disabled = false,
}) => {
  const [isHover, setIsHover] = useState(false)
  const { _id, is_public: isPublic, status } = canvas

  useEffect(() => {
    // after click, remove hover state for better UX
    setIsHover(false)
  }, [isPublic])

  return (
    <button
      onClick={(e) => {
        if (onMakePublic) onMakePublic(_id)
      }}
      className={classNames(
        'p-0 rounded-full shadow-md hover:bg-gray-700 focus:outline-none h-7 w-7 transition duration-500 ease-in-out hover:opacity-100 hover:scale-105',
        { 'opacity-0': !isCanvasCellFocused && !isPublic },
        { 'opacity-75': isCanvasCellFocused || isPublic },
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      disabled={
        disabled || (typeof status !== 'undefined' && status !== 'done')
      }
    >
      {isHover ? (
        <img
          src={isPublic ? EyeClosed : EyeOpened}
          alt={`Make ${isPublic ? 'Private' : 'Public'} Icon`}
        />
      ) : (
        <img
          src={isPublic ? EyeOpened : EyeClosed}
          alt={`Make ${isPublic ? 'Public' : 'Private'} Icon`}
        />
      )}
    </button>
  )
}
