import { Modal } from './Modal'

function AskForCreditsModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='px-6 py-6 sm:px-12 sm:py-10 w-full space-y-8'>
        <p className='text-2xl sm:text-5xl text-tertiary'>
          Insufficient credits
        </p>
        <p className='text-tertiary/80'>
          You don't have enough credits to run this.
        </p>
        <div className='text-center'>
          <a
            href='/pricing'
            className='block rounded-full text-center border bg-primary px-4 py-4 text-sm font-bold text-gray-700 shadow-sm uppercase gap-2 border-primary w-full'
          >
            BUY MORE CREDITS
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default AskForCreditsModal
