import { useNavigate } from 'react-router-dom'
import { Base64 } from 'js-base64'

import { Modal } from './Modal'

function AskUseTheseSettingsModal({ settingsUsed, isOpen, onClose }) {
  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate('/create')
  }

  const handleUseTheseSettings = () => {
    const settingsInBase64 = Base64.encode(JSON.stringify(settingsUsed), true)
    navigate(`/create?settings=${settingsInBase64}`)
  }

  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
        <p className='text-2xl sm:text-4xl text-tertiary'>
          Use these settings?
        </p>
        <p className='text-tertiary/80'>
          You're about to reuse the settings used by the creator of this video.
        </p>
        <div className='mt-4 flex flex-col items-center justify-end gap-2'>
          <button
            type='button'
            className='rounded-full text-center border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25 w-full'
            onClick={handleRedirect}
          >
            No, just take me to the creation page
          </button>
          <button
            type='button'
            className='rounded-full text-center border bg-primary px-4 py-4 text-sm font-bold text-gray-700 shadow-sm uppercase gap-2 border-primary w-full'
            onClick={handleUseTheseSettings}
          >
            Yes, use these settings
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AskUseTheseSettingsModal
