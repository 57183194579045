interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const ImageElementIcon = (props: SVGProps) => {
  return (
    <svg
      width='75'
      height='42'
      viewBox='0 0 75 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.83479 38.8222C10.3946 36.7618 12.3138 33.9492 13.8449 30.8399C15.373 27.7366 16.5338 24.2996 16.5338 21C16.5338 17.7005 15.373 14.2634 13.8449 11.1601C12.3141 8.05141 10.3953 5.23927 8.83565 3.17897C8.01626 2.08297 8.79804 0.5 10.292 0.5H51.4934C52.034 0.5 52.5441 0.738045 52.8769 1.12599C54.0383 2.48149 56.4529 5.46005 58.5746 9.10882C60.7037 12.7701 62.5 17.0427 62.5 21C62.5 24.9573 60.7037 29.2299 58.5746 32.8912C56.4527 36.5402 54.038 39.5189 52.8767 40.8742L52.8734 40.8781C52.5449 41.2696 52.039 41.5 51.4934 41.5H10.292C8.79708 41.5 8.01781 39.9061 8.83479 38.8222ZM8.83479 38.8222L8.43614 38.5204M8.83479 38.8222C8.83495 38.8219 8.83512 38.8217 8.83528 38.8215L8.43614 38.5204M8.43614 38.5204C11.5332 34.4293 16.0338 27.3812 16.0338 21C16.0338 14.6189 11.5332 7.57068 8.43614 3.47962L53.2564 41.1996C52.8272 41.711 52.1777 42 51.4934 42H10.292C8.42451 42 7.34579 39.9656 8.43614 38.5204Z'
        fill='white'
        fillOpacity='0.75'
        stroke='url(#paint0_linear_4995_2754)'
      />
      <g clipPath='url(#clip0_4995_2754)'>
        <g filter='url(#filter0_b_4995_2754)'>
          <rect
            y='15'
            width='12'
            height='12'
            rx='2'
            fill='white'
            fillOpacity='0.75'
          />
          <rect
            x='0.375'
            y='15.375'
            width='11.25'
            height='11.25'
            rx='1.625'
            stroke='url(#paint1_linear_4995_2754)'
            strokeOpacity='0.7'
            strokeWidth='0.75'
          />
        </g>
      </g>
      <g clipPath='url(#clip1_4995_2754)'>
        <g filter='url(#filter1_b_4995_2754)'>
          <path
            d='M71.9611 19.2635C73.3048 20.0313 73.3048 21.9687 71.9611 22.7365L56.9923 31.2901C55.659 32.052 54 31.0893 54 29.5536V12.4464C54 10.9107 55.659 9.94798 56.9923 10.7099L71.9611 19.2635Z'
            fill='white'
            fillOpacity='0.75'
          />
          <path
            d='M71.7131 19.6976C72.7208 20.2735 72.7208 21.7265 71.7131 22.3024L56.7442 30.856C55.7442 31.4274 54.5 30.7054 54.5 29.5536V12.4464C54.5 11.2946 55.7442 10.5726 56.7442 11.144L71.7131 19.6976Z'
            stroke='url(#paint2_linear_4995_2754)'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_b_4995_2754'
          x='-4'
          y='11'
          width='20'
          height='20'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_4995_2754'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_4995_2754'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_b_4995_2754'
          x='50'
          y='6.44336'
          width='26.9688'
          height='29.1133'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_4995_2754'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_4995_2754'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_4995_2754'
          x1='8'
          y1='0'
          x2='23.7827'
          y2='53.557'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4995_2754'
          x1='-2.77091'
          y1='12.2291'
          x2='12'
          y2='27'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.47' stopColor='#D3D3D3' stopOpacity='0.791518' />
          <stop offset='0.842645' stopColor='#F2F2F2' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4995_2754'
          x1='75'
          y1='9'
          x2='48.8748'
          y2='17.8216'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <clipPath id='clip0_4995_2754'>
          <rect
            width='12'
            height='12'
            fill='white'
            transform='translate(0 15)'
          />
        </clipPath>
        <clipPath id='clip1_4995_2754'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(51 9)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
