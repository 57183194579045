import { Dispatch, SetStateAction } from 'react'

import { Modal } from './Modal'
import { useThemeContext } from '../context'
import whatIsNewCover from '../images/what-is-new.png'
import { Announcement } from '@/types'

interface AnnouncementsModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  announcement: Announcement
}

export const AnnouncementsModal = ({
  isOpen,
  setIsOpen,
  announcement,
}: AnnouncementsModalProps) => {
  const { colors } = useThemeContext()
  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      handleClose={() => {}}
      containerClassName={`${colors.background.neutral}`}
      closeIconClassName={`${colors.text.default} ${colors.border.inverse}`}
    >
      <div className='w-full py-2 space-y-6 sm:py-1'>
        {announcement.headerText ? (
          <p
            className={`text-[24px] justify-center flex text-center px-12 ${colors.text.default}`}
          >
            {announcement.headerText}
          </p>
        ) : (
          <p
            className={`text-[24px] justify-center flex text-center ${colors.text.default}`}
          >
            ✨ What&apos;s new ✨
          </p>
        )}
        {announcement.headerImage ? (
          <img src={announcement.headerImage} alt={`What's new`} width='100%' />
        ) : (
          <img src={whatIsNewCover} alt={`What's new`} width='100%' />
        )}
        <div
          className={`max-h-80 overflow-y-auto rounded-xl ${colors.text.default}`}
        >
          {announcement.title ? (
            <h3 className='px-2 py-2 pl-[15px]'>{announcement.title}</h3>
          ) : (
            <h3 className='px-2 py-2 pl-[15px]'>Updates</h3>
          )}
          {announcement.announcementHtml && (
            <div
              className='py-4 px-4'
              dangerouslySetInnerHTML={{
                __html: announcement.announcementHtml,
              }}
            />
          )}
        </div>
        <div className='flex flex-col items-center justify-end gap-2 mt-4'>
          <style>
            {`
              div a {
                color: #4099ff
                text-decoration: underline
              }
            `}
          </style>
          <div
            className={`py-4 px-4 ${colors.text.default}`}
            dangerouslySetInnerHTML={{ __html: announcement.footerText }}
          />
        </div>
      </div>
    </Modal>
  )
}
