import { getPrompt } from '.'
import { ModelType, VideoForm } from '../types'

export const getFormattedInitialScene = (
  type: ModelType,
  video: VideoForm,
): string => {
  let initialScene = {}

  if (type === ModelType.MotionV3) {
    initialScene = {
      '0': {
        length: video.length,
        evolve: 0,
        motionMagnitude: video.motionMagnitude,
        audioReactivity: 9,
        style: video.style,
        subject: video.subject,
        prompt: getPrompt(video.subject, video.style),
        curatedStyle: video.style,
      },
    }
  } else if (type === ModelType.FlipbookV1) {
    initialScene = {
      '0': {
        length: video.length,
        evolve: 0,
        style: video.style,
        subject: video.subject,
        prompt: getPrompt(video.subject, video.style),
        curatedStyle: video.style,
      },
    }
  }

  // TODO determine if we need to stringify here or if it will properly come across as Scene object if not
  return JSON.stringify(initialScene)
}
