import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { StarIcon } from '@heroicons/react/24/outline'
import { cn } from '../utils'

export default function CreatorCheckboxSelect({
  handleSelect,
  disabled,
  title,
  upgradeTitle,
  options,
  checked,
  subscriptionTextCTA,
  showSubscriptionTextCTA,
  description,
  disabledDescription,
}) {
  const onChange = (event) => {
    if (!disabled) {
      handleSelect(event.target.checked)
    }
  }

  return (
    <>
      {!showSubscriptionTextCTA && (
        <div className='flex items-center gap-3 mb-10 sm:flex-wrap'>
          <input
            type='checkbox'
            id={title}
            checked={checked}
            onChange={onChange}
            className={cn(
              'sm:w-7 sm:h-7 w-6 h-6 accent-primary focus:accent-primary/90 bg-gray-100 border-gray-300 focus:ring-primary/50',
              disabled && 'opacity-50 cursor-not-allowed',
            )}
            disabled={disabled}
          />
          <label
            htmlFor={title}
            className={cn(
              'flex flex-col text-primary focus:bg-primary-light',
              disabled && 'opacity-50 cursor-not-allowed',
            )}
          >
            <span className='text-sm font-medium whitespace-pre-line sm:text-base '>
              {' '}
              {title}{' '}
            </span>
          </label>
          {/* TOOLTIP */}
          {(!!description || (disabled && !!disabledDescription)) && (
            <div className='flex has-tooltip'>
              <span className='px-2 py-1 -mt-8 text-gray-100 bg-gray-800 rounded shadow-lg tooltip'>
                {disabled && disabledDescription
                  ? disabledDescription
                  : description}
              </span>
              <AiOutlineQuestionCircle className='self-center w-6 h-6 select-none' />
            </div>
          )}
        </div>
      )}

      {showSubscriptionTextCTA ? (
        <div className='bg-[#131315] rounded-2xl p-5 max-w-md self-start mt-[20px] mb-[30px]'>
          <h2 className='mb-5 text-xl whitespace-pre-line lg:text-2xl xl:text-3xl opacity-30'>
            {upgradeTitle}
          </h2>
          <a href='/pricing'>
            <div className='rounded-full font-bold w-fit bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
              <StarIcon className='inline w-6 h-6 mr-2' />
              {subscriptionTextCTA}
            </div>
          </a>
        </div>
      ) : null}
    </>
  )
}
