import { Checkbox, CheckboxProps } from '@material-tailwind/react'
import { forwardRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import { useThemeContext } from '../../../context/themeContext'
import { cn } from '../../../utils'
import { toTitleCase } from '../../../utils/formUtils'

interface CheckboxFieldProps extends Omit<CheckboxProps, 'ref'> {
  registration?: Partial<UseFormRegisterReturn>
  label: string
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ registration, label, ...props }, ref) => {
    const { colors } = useThemeContext()

    return (
      <div className='flex items-center justify-between w-full'>
        <label
          htmlFor={label}
          className={cn(
            'text-sm text-gray-300 mr-2 capitalize',
            colors.text.subtle,
          )}
        >
          {toTitleCase(label)}
        </label>
        <Checkbox
          ripple={false}
          ref={ref}
          id={label}
          className='border-gray-600 bg-transparent checked:border-gray-600 checked:bg-gray-600 checked:text-gray-600 transition-all hover:scale-105 hover:before:opacity-0 before:p-0'
          containerProps={{ className: 'px-1 py-2' }}
          {...registration}
          {...props}
        />
      </div>
    )
  },
)

CheckboxField.displayName = 'CheckboxField'
