import { useQueries, useQuery } from '@tanstack/react-query'
import { collectionUtils } from '../../utils/collectionUtils'
import { CollectionService } from '../../services/CollectionService'

/**
 * Wraps React Query `useQuery` to query a collection by its ID.
 * @param collectionId collection ID. If null or undefined, the returned data is always null.
 * @returns The result of the useQuery function.
 */
export const useQueryCollection = (collectionId: string | null | undefined) => {
  // https://tanstack.com/query/latest/docs/framework/react/guides/queries
  return useQuery({
    queryKey: collectionUtils.keyOf(collectionId),
    queryFn: async () => {
      if (!collectionId) return null
      return CollectionService.getCollection(collectionId)
    },
  })
}

/**
 * Wraps React Query `useQueries` to query a list of collections by a list of IDs.
 * @returns An array of the return values of `useQuery`, with one entry for each collection ID.
 */
export const useQueryCollections = (collectionIds: string[]) => {
  // https://tanstack.com/query/latest/docs/framework/react/guides/parallel-queries#dynamic-parallel-queries-with-usequeries
  // https://tanstack.com/query/latest/docs/framework/react/reference/useQueries
  return useQueries({
    queries: collectionIds.map((collectionId) => ({
      queryKey: collectionUtils.keyOf(collectionId),
      queryFn: async () => CollectionService.getCollection(collectionId),
    })),
  })
}
