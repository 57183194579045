import { useCallback, useEffect, useState, useRef } from 'react'
import { TbCoins } from 'react-icons/tb'
import { toast } from 'react-toastify'
import moment from 'moment'

import SingleSelect from './SingleSelect'
import http from '../services/HttpService'
import { Modal } from './Modal'

export const UpscaleModal = ({
  isOpen,
  onClose,
  memory,
  onSuccess,
  onError,
}) => {
  const [amount, setAmount] = useState(null)
  const [quality, setQuality] = useState(null)
  const [scaleMultiplier, setScaleMultiplier] = useState(null)
  const [isUpscaling, setIsUpscaling] = useState(false)
  const loadingUpscaleToastId = useRef()

  const handleSelectQuality = useCallback(
    (name, value) => {
      const interval = value === 4 ? 5 : 10
      const time = memory?.settingsUsed?.duration
      const cost = Math.ceil(time / interval)
      setAmount(cost)
      setQuality(name)
      setScaleMultiplier(value)
    },
    [memory?.settingsUsed?.duration],
  )

  const handleUpscaleButton = () => {
    upscale_video(scaleMultiplier)
    setIsUpscaling(true)
  }

  const handleOptions = (memory) => {
    const arr = []
    if (memory.versions) {
      if (!memory.versions.some((e) => e.scale_multiplier === 2))
        arr.push({ name: '2x', value: 2 })
      if (!memory.versions.some((e) => e.scale_multiplier === 4))
        arr.push({ name: '4x', value: 4 })
    } else {
      arr.push({ name: '2x', value: 2 })
      arr.push({ name: '4x', value: 4 })
    }
    return arr
  }

  const upscale_video = async (scale_multiplier) => {
    try {
      onClose(memory.memory_id)
      await http.post('/api/upscale_video', {
        memory_id: memory.memory_id,
        scale_multiplier,
      })

      const estimatedUpscaleTime = 45 * memory.settingsUsed.duration
      const momentTime = moment
        .duration(Math.max(60, estimatedUpscaleTime), 'seconds')
        .humanize()

      toast.success(
        'Video is being upscaled... Estimated time: about ' + momentTime,
      )

      setIsUpscaling(true)
      pollForUpscaledVideo(
        memory.memory_id,
        scaleMultiplier,
        loadingUpscaleToastId.current,
      )
    } catch (err) {
      console.error(err)

      if (err.response) {
        toast.error(err.response.data?.error || err.response.data)
      } else if (err?.message) {
        toast.error(err.message)
      } else {
        toast.error('Something went wrong.')
      }

      setIsUpscaling(false)
      setScaleMultiplier(null)
      onError(memory.memory_id)
    }
  }

  const pollForUpscaledVideo = async (memory_id, scale_multiplier, toastId) => {
    try {
      const response = await http.get('/api/get_memory/' + memory_id)
      const memory = response.data.memory
      //check if the selected (scale / quality) exists in the videos array
      const success =
        memory.versions &&
        memory.versions.filter((e) => e.scale_multiplier === scale_multiplier)
          .length > 0 //response.data.memory.isUpscaled
      const failed = memory.upscale_status === 'failed'

      if (success) {
        onSuccess(memory_id)
        setIsUpscaling(false)
        setScaleMultiplier(null)
        toast.update(toastId, {
          render: 'Your video has been upscaled successfully!',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 10000,
          closeOnClick: true,
          closeButton: true,
        })
      } else if (failed) {
        setIsUpscaling(false)
        onError(memory_id)
        toast.update(toastId, {
          render: 'Upscaling failed',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 10000,
          closeOnClick: true,
          closeButton: true,
        })
      } else {
        setTimeout(() => {
          pollForUpscaledVideo(memory_id, scale_multiplier, toastId)
        }, 3000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (memory && isOpen) {
      setAmount(null)
      setQuality(null)
      setScaleMultiplier(null)
      setIsUpscaling(false)

      const firstOption = handleOptions(memory)[0]

      if (firstOption) {
        handleSelectQuality(firstOption.name, firstOption.value)
      }
    }
  }, [memory, isOpen, handleSelectQuality])

  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='m-6 rounded-3xl p-6 sm:w-[600px] md:w-[680px]'>
        <dt className='text-lg'>
          <div className='text-left w-full flex justify-between items-start text-white'>
            <div className='flex items-center justify-center gap-4'>
              <p className='text-tertiary/80 font-bold text-4xl'>
                {/* <AdjustmentsHorizontalIcon className='h-6 w-6 inline mr-4' /> */}
                Upscale Video
              </p>
            </div>
          </div>
        </dt>
        <div className='mt-8'>
          <p className='text-tertiary/60'>
            <strong className='text-tertiary/80'>2x</strong> ={' '}
            <strong className='text-tertiary/80'>1</strong> credit for every{' '}
            <strong className='text-tertiary/80'>10</strong> seconds.
          </p>
          <p className='text-tertiary/60'>
            <strong className='text-tertiary/80'>4x</strong> ={' '}
            <strong className='text-tertiary/80'>1</strong> credit for every{' '}
            <strong className='text-tertiary/80'>5</strong> seconds.
          </p>
        </div>
        <div className='mt-0'>
          {memory ? (
            <SingleSelect
              handleSelect={handleSelectQuality}
              disabled={null}
              options={handleOptions(memory)}
              title={''}
              defaultOption={quality}
              showSubscriptionTextCTA={false}
            />
          ) : null}
        </div>

        {amount ? (
          <div className=''>
            <p className='text-gray-400 mt-2'>
              <TbCoins className='inline w-4 h-4 text-[#CAD7CA] place-self-center mr-1' />
              Credits Cost:
              <strong className='text-tertiary/80 ml-1'>{amount}</strong>
            </p>
          </div>
        ) : null}

        <div className='px-6 sm:px-12 w-full'>
          <div className='flex flex-col items-center justify-end gap-2'>
            <button
              onClick={handleUpscaleButton}
              className='w-full mt-8 text-center rounded-full border border-transparent radial-gradient hover:bg-secondary text-gray-700 bg-secondary px-6 py-4 text-base font-bold focus:outline-none focus:ring-0 flex-grow gap-2'
              disabled={scaleMultiplier && !isUpscaling ? false : true}
            >
              UPSCALE {quality ? `TO ` + quality : null}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
