import { useMemo } from 'react'

import { usePresetContext } from '../../../context'
import { useThemeContext } from '../../../context'
import { LibraryIcon } from '../../../images/icons/LibraryIcon'
import { QuestionMagnifyingGlassIcon } from '../../../images/icons/QuestionMagnifyingGlassIcon'
import { TagFilter } from '../../../types'

interface LibraryEmptyStateProps {
  tagFilters: TagFilter[]
  setIsFilterDropdownOpen: (isOpen: boolean) => void
}

export const LibraryEmptyState = ({
  tagFilters,
  setIsFilterDropdownOpen,
}: LibraryEmptyStateProps) => {
  const { togglePresetMenu } = usePresetContext()
  const { colors } = useThemeContext()

  const emptyTagFilters = useMemo(() => {
    return tagFilters.every((tag) => !tag.selected)
  }, [tagFilters])

  return (
    <div className='flex flex-col items-center justify-center h-full py-20 text-center'>
      {emptyTagFilters ? (
        <LibraryIcon size={64} />
      ) : (
        <QuestionMagnifyingGlassIcon size='64px' />
      )}
      <div className={colors.text.default}>
        {emptyTagFilters ? 'Your library is empty' : 'No results found'}
      </div>
      <div className={`${colors.text.subtle} pt-2`}>
        {emptyTagFilters
          ? 'Drag media into the canvas or start with a flow'
          : 'Try different keywords or filters'}
      </div>
      <button
        className={`mt-4 px-4 py-2 border ${colors.border.brand} ${colors.text.brand} rounded-lg`}
        onClick={() =>
          emptyTagFilters ? togglePresetMenu() : setIsFilterDropdownOpen(true)
        }
      >
        {emptyTagFilters ? 'Start a new flow' : 'Open filters'}
      </button>
    </div>
  )
}
