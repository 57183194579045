import { useCallback, useEffect, useRef, useState } from 'react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import CurrencyFormat from 'react-currency-format'
import http from '../../services/HttpService'
import Pagination from './Pagination'

export default function UserCreditLogsTab({ user }) {
  const [isLoading, setIsLoading] = useState(true)
  const [creditLogs, setCreditLogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const tableWrapperRef = useRef()

  const fetchUserCreditLogs = useCallback(
    async (page = 1) => {
      setIsLoading(true)

      const response = await http.get(
        `/api/admin/users/${user.distinctId}/credit-logs`,
        {
          params: {
            page,
          },
        },
      )

      setCreditLogs(response.data.items)
      setTotalItems(response.data.totalItems)
      setTotalPages(response.data.totalPages)
      setIsLoading(false)

      if (tableWrapperRef.current) {
        tableWrapperRef.current.scrollTo(0, 0)
      }
    },
    [user.distinctId],
  )

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    fetchUserCreditLogs(newPage)
  }

  let paymentGateway = (invoiceId) => {
    switch (user.paymentGateway) {
      case 'paddle':
        return (
          <a
            href={`${process.env.REACT_APP_PADDLE_URL}transactions-v2/${invoiceId}`}
            className='hover:text-primary transition-all'
            target='_blank'
            rel='noreferrer'
          >
            Invoice page
            <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
          </a>
        )
      case 'stripe':
        return (
          <a
            href={`https://dashboard.stripe.com/invoices/${invoiceId}`}
            className='hover:text-primary transition-all'
            target='_blank'
            rel='noreferrer'
          >
            Invoice page
            <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
          </a>
        )
      default:
        return ''
    }
  }

  useEffect(() => {
    fetchUserCreditLogs()
  }, [fetchUserCreditLogs])

  return (
    <>
      {
        // eslint-disable-next-line eqeqeq
        isLoading && totalItems == 0 ? (
          <div className='flex h-full'>
            <div className='m-auto bg-darkGray p-6 rounded-3xl'>
              <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
            </div>
          </div>
        ) : (
          <>
            {totalItems > 0 && (
              <>
                <div className='bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl relative'>
                  <div ref={tableWrapperRef} className='overflow-x-auto'>
                    <table className='w-full text-white table-auto border-collapse min-w-[1000px]'>
                      <thead>
                        <tr>
                          {/* <th className='text-left p-3 border-b border-[#545854]/25'>
                          Current Credits
                        </th>
                        <th className='text-left p-3 border-b border-[#545854]/25'>
                          Final Credits
                        </th> */}
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Credits Change
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Log Type
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Extra
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            When
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {creditLogs.map((creditLog, index) => (
                          <tr key={creditLog._id}>
                            {/* <td className={`p-3 ${index < creditLogs.length - 1 ? 'border-b border-[#545854]/25' : ''}`}>
                            {creditLog.currentCredits}
                          </td>
                          <td className={`p-3 ${index < creditLogs.length - 1 ? 'border-b border-[#545854]/25' : ''}`}>
                            {creditLog.finalCredits}
                          </td> */}
                            <td
                              className={`p-3 ${
                                index < creditLogs.length - 1
                                  ? 'border-b border-[#545854]/25'
                                  : ''
                              }`}
                            >
                              <span
                                className={
                                  creditLog.creditsChange > 0
                                    ? 'text-primary'
                                    : 'text-red-500'
                                }
                              >
                                {creditLog.creditsChange > 0 ? '+' : ''}
                                {creditLog.creditsChange}
                              </span>
                            </td>
                            <td
                              className={`p-3 ${
                                index < creditLogs.length - 1
                                  ? 'border-b border-[#545854]/25'
                                  : ''
                              }`}
                            >
                              {creditLog.type}
                            </td>
                            <td
                              className={`p-3 ${
                                index < creditLogs.length - 1
                                  ? 'border-b border-[#545854]/25'
                                  : ''
                              }`}
                            >
                              {creditLog.type === 'ADMIN_UPDATE' ? (
                                <p>
                                  <a
                                    href={`/admin/users/${creditLog.adminId}`}
                                    className='hover:text-primary transition-all'
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    Admin page
                                    <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
                                  </a>
                                </p>
                              ) : creditLog.type === 'REFERRAL' ? (
                                <p>
                                  <a
                                    href={`/admin/users/${creditLog.referredId}`}
                                    className='hover:text-primary transition-all'
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    Claimed by
                                    <ArrowTopRightOnSquareIcon className='w-4 h-4 inline-block ml-2 mb-1' />
                                  </a>
                                </p>
                              ) : creditLog.type === 'BETA_TESTING' ? (
                                <p>{creditLog.description}</p>
                              ) : (
                                <>
                                  <p>{paymentGateway(creditLog.invoiceId)}</p>
                                  <p>
                                    <CurrencyFormat
                                      value={creditLog.amountPaid / 100}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                      fixedDecimalScale={true}
                                      decimalScale={2}
                                    />
                                  </p>
                                </>
                              )}
                            </td>
                            <td
                              className={`p-3 ${
                                index < creditLogs.length - 1
                                  ? 'border-b border-[#545854]/25'
                                  : ''
                              }`}
                            >
                              {creditLog.createdAt}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {isLoading && (
                    <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 rounded-3xl'>
                      <div className='flex flex-col items-center space-y-4 m-auto'>
                        <div className='bg-darkGray p-6 rounded-3xl'>
                          <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='mt-8'>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    disabled={isLoading}
                    onPageChange={handlePageChange}
                  />
                </div>
              </>
            )}

            {
              // eslint-disable-next-line eqeqeq
              totalItems == 0 && (
                <p className='text-tertiary/80'>No credit logs found.</p>
              )
            }
          </>
        )
      }
    </>
  )
}
