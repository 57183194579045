import { useCallback, Dispatch, SetStateAction } from 'react'
import { Node } from '@xyflow/react'

import { ModelType } from '@/types'
import { BORDER_COLORS, DEFAULT_BORDER_COLOR } from '../../constants'
import { ContextMenuProps } from '../../components/k2/Menus'

export const useContextMenu = (
  setMenu: Dispatch<SetStateAction<ContextMenuProps>>,
) => {
  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => setMenu(null), [setMenu])

  const setMenuConfig = useCallback(
    (
      event: React.MouseEvent<Element, MouseEvent>,
      id: string | null,
      borderColor: string,
      nodeRect: DOMRect,
    ) => {
      const contextMenu = {
        height: 178,
        width: 146,
      }

      setMenu({
        id,
        borderColor,
        onClick: onPaneClick,
        // Ensure it stays within the node's top and bottom and pane height
        top: Math.max(
          nodeRect.top,
          Math.min(event.clientY, nodeRect.bottom - contextMenu.height),
        ),
        // Ensure it stays within the node's left and right and pane width
        left: Math.max(
          nodeRect.left,
          Math.min(event.clientX, nodeRect.right - contextMenu.width),
        ),
      })
    },
    [onPaneClick, setMenu],
  )

  /**
   * Handles right clicks on a specific node.
   */
  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, node: Node) => {
      // Prevent native context menu from showing
      const { modelType } = node.data
      const borderColor =
        BORDER_COLORS[modelType as ModelType] ?? DEFAULT_BORDER_COLOR

      event.preventDefault()

      // Use the mouse event's coordinates to find the node element
      const nodeElement = document.elementFromPoint(
        event.clientX,
        event.clientY,
      ) as HTMLElement

      if (nodeElement) {
        const nodeRect = nodeElement.getBoundingClientRect()
        setMenuConfig(event, node.id, borderColor, nodeRect)
      }
    },
    [setMenuConfig],
  )

  /**
   * Handles right clicks on selection of nodes/edges.
   */
  const onSelectionContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, _: Node[]) => {
      // Prevent native context menu from showing
      event.preventDefault()

      // Use the mouse event's coordinates to find the node element
      const nodeElement = document.elementFromPoint(
        event.clientX,
        event.clientY,
      ) as HTMLElement

      if (nodeElement) {
        const nodeRect = nodeElement.getBoundingClientRect()
        setMenuConfig(event, null, DEFAULT_BORDER_COLOR, nodeRect)
      }
    },
    [setMenuConfig],
  )

  return {
    onPaneClick,
    onNodeContextMenu,
    onSelectionContextMenu,
  }
}
