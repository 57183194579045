import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useUserAccountContext } from '../context/userContext'
import ReferForm from './ReferForm'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import http from '../services/HttpService'
import PaymentModal from './paymentModal'
import classNames from 'classnames'
import ReferrerIdForm from './ReferrerIdForm'
import { AskForCancelSubscriptionModal } from './AskForCancelSubscriptionModal'
import { toast } from 'react-toastify'

export default function Credits() {
  const { currentUser, isFreeTrial } = useUserAccountContext()
  const [pricingInterval, setPricingInterval] = useState('packs')
  const [amount, setAmount] = useState(0)
  const [quantityForPurchase] = useState(100)
  const [isOpen, setIsOpen] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false)

  const isSubscribed = currentUser?.subscriptionType === 'Standard'
  const [isMobileUser, setIsMobileUser] = useState(false)

  const [showHiddenDiv, setShowHiddenDiv] = useState(false)

  const navigate = useNavigate()

  const handleMouseEnter = () => {
    setShowHiddenDiv(true)
  }

  const handleMouseLeave = () => {
    setShowHiddenDiv(false)
  }

  const planExpirationDate = useMemo(() => {
    if (subscription?.current_billing_period) {
      // Paddle
      return moment
        .utc(subscription.current_billing_period.ends_at)
        .format('LLL z')
    } else if (subscription) {
      // Stripe
      return moment(subscription.current_period_end * 1000)
        .utc()
        .format('LLL z')
    }

    return '...'
  }, [subscription])

  const getSubscription = async () => {
    const response = await http.get('/api/auth/myaccount/subscription')

    setSubscription(response.data)
  }

  const handlePaymentMethod = async () => {
    if (currentUser?.paymentGateway === 'stripe') {
      window.location.href = process.env.REACT_APP_STRIPE_BILLING_URL
    } else {
      if (!isSubscribed) {
        navigate('/pricing')
        return
      }

      try {
        const response = await http.get('/api/update_billing')

        window.Paddle.Checkout.open({
          settings: {
            theme: 'dark',
            successUrl: process.env.REACT_APP_SITE_URL + '/dashboard',
            allowLogout: false,
          },
          transactionId: response.data.id,
        })
      } catch (err) {
        toast.error('An unexpected error occurred. Please try again later.')
      }
    }
  }

  const handleCancelRenew = async () => {
    // await http.post('/api/cancel_renewal')

    // window.location.reload()
    // window.location.href = process.env.REACT_APP_STRIPE_BILLING_URL
    setIsCancelSubscriptionModalOpen(true)
  }

  const openModal = (_amount = 1) => {
    console.log(_amount)
    setIsOpen(!isOpen)
    if (pricingInterval === 'packs') {
      setAmount(_amount)
    }
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const subscriptionExpirationDate = useMemo(() => {
    // For mobile we use current_period_end because is a value from the google/apple APIs
    // this value determinate both when the subscription will be renew or when it will be canceled if the user cancel the auto renew.
    // The value subscriptionCancelAtPeriodEnd is a boolean that determinate if the user has canceled the subscription.
    // For web (paddle/stripe) the value subscriptionCancelAt is the value that determinate when the subscription will be canceled.
    let unix = isMobileUser
      ? currentUser.current_period_end
      : currentUser.subscriptionCancelAt
    return moment(unix * 1000).format('LL')
  }, [isMobileUser, currentUser])

  const packs = [
    {
      name: 'Pack 1',
      id: 1,
      href: '#',
      price: 15,
      amount: 1000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['1,000 credits'],
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
    {
      name: 'Pack 2',
      id: 2,
      href: '#',
      price: 65,
      amount: 5000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['5,000 credits', 'Save up to 13.3%'],
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
    {
      name: 'Pack 3',
      id: 3,
      href: '#',
      price: 120,
      amount: 10000,
      description: 'Buy credits.',
      buttonText: 'Select pack',
      buttonAction: openModal,
      includedFeatures: ['10,000 credits', 'Save up to 20%'],
      paypalSeting: {
        clientId: process.env.PAYPAL_CLIENT_ID,
      },
    },
  ]

  const isCurrentPlan = (tier) => {
    return (
      (tier.isCurrentPlan && tier.name === 'Free') ||
      (tier.isCurrentPlan &&
        pricingInterval === currentUser?.subscriptionInterval + 'ly')
    )
  }

  const PricingPageButton = ({ text, action, isDisabled }) => {
    return (
      <button
        type='button'
        className={
          isDisabled
            ? 'text-base mt-4 block w-full rounded-full border border-gray-800 bg-gray-100/50 py-2 text-center font-semibold text-gray-900'
            : 'text-base mt-4 block w-full rounded-full border border-gray-800 button-shadow py-2 text-center font-semibold text-gray-900 hover:bg-white bg-kaiberGreen'
        }
        onClick={() => action()}
        disabled={isDisabled}
      >
        {text}
      </button>
    )
  }

  useEffect(() => {
    // used for users who were subscribed to Paddle initially but are now denied from accessing due to location
    window.addEventListener('paddleCheckoutFailed', async (data) => {
      toast.error('An unexpected error occurred. Please try again later.')
    })
  }, [])

  useEffect(() => {
    if (isSubscribed) {
      getSubscription()
    }

    if (
      currentUser?.paymentGateway === 'apple' ||
      currentUser?.paymentGateway === 'google'
    ) {
      setIsMobileUser(true)
    }
  }, [currentUser?.paymentGateway, isSubscribed])

  return (
    <>
      <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl leading-6 text-kaiberGreen'>Credits</h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <form action='#' method='POST'>
            <div className='shadow rounded-2xl'>
              <div className='px-4 py-5 sm:p-6 sm:pt-0'>
                <div className='space-y-2'>
                  <div className='flex items-center'>
                    <p className='text-tertiary text-2xl'>Available credits</p>
                    <div className='group relative'>
                      <InformationCircleIcon
                        className='hover-trigger h-5 w-5 text-tertiary/60 shrink-0 ml-1 cursor-pointer'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />

                      <div className='absolute -top-4 left-10 w-[0px] md:w-full overflow-hidden gap-2 rounded-2xl md:min-w-[500px] max-w-[500px] bg-infoGrey p-4 hidden group-hover:block invisible md:visible'>
                        <div className='text-tertiary/60'>
                          <p className='mb-2'>
                            Use credits to generate and upscale Kaiber videos:
                          </p>
                          <ul className='list-disc ml-7'>
                            <li>1 credit per second of Flipbook generation</li>
                            <li>4 credits per second of Motion generation</li>
                            <li>
                              5 credits per second of Transform V1 generation
                            </li>
                            <li>
                              6 credits per second of Transform V2 generation
                            </li>
                            <li>1 credit per 10 seconds of 2x upscaling</li>
                            <li>1 credit per 5 seconds of 4x upscaling</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showHiddenDiv && (
                    <div className='relative visible md:hidden'>
                      <div className='absolute top-2 left-0 w-full flex gap-2 rounded-2xl bg-infoGrey p-4'>
                        <div className='text-tertiary/60'>
                          <p className='mb-2'>
                            Use credits to generate and upscale Kaiber videos:
                          </p>
                          <ul className='list-disc ml-7'>
                            <li>1 credit per second of Flipbook generation</li>
                            <li>4 credits per second of Motion generation</li>
                            <li>
                              5 credits per second of Transform V1 generation
                            </li>
                            <li>
                              6 credits per second of Transform V2 generation
                            </li>
                            <li>1 credit per 10 seconds of 2x upscaling</li>
                            <li>1 credit per 5 seconds of 4x upscaling</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentUser.credits != null && (
                    <p className='text-kaiberGreen flex gap-2 text-6xl font-bold'>{`${currentUser.credits}`}</p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isSubscribed ? (
        <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 border-b border-lightGray pb-10'>
          <div className='md:col-span-1'>
            <div className='px-4 sm:px-0'></div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='overflow-hidden shadow rounded-2xl'>
              <div className='px-4 py-5 sm:p-6 space-y-8'>
                <p className='text-tertiary text-2xl'>Buy more credits</p>

                {/* <div className='flex flex-col sm:flex-row gap-4 justify-center sm:justify-between border p-8 border-primary/20 rounded-3xl items-center'>
                    <QuantityCounter
                      quantity={quantityForPurchase}
                      increment={100}
                      min={100}
                      setQuantity={handleQuantity}
                    />
                    <p className='font-bold text-tertiary'>
                      <b>Total: </b>
                      <CurrencyFormat
                        value={(quantityForPurchase / 100) * 1.5}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                      &nbspfor {quantityForPurchase} credits
                    </p>
                    <button
                      onClick={() => {
                        setPricingInterval('credit')
                        openModal()
                      }}
                      className='inline-flex justify-center border border-transparent radial-gradient py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-primary/60 focus:outline-none focus:ring-0 rounded-full'
                    >
                      BUY
                    </button>
                  </div> */}
                {isSubscribed && !isFreeTrial && (
                  <div className='mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 xl:grid-cols-3 sm:space-y-0 xl:mx-auto xl:max-w-5xl gap-24'>
                    {packs.map((pack, index) => (
                      <div
                        key={index}
                        className={classNames(
                          'divide-y rounded-3xl',
                          pack?.price > 0 && '',
                          pack?.id === 3
                            ? 'sm:col-span-3 md:col-span-2 xl:col-span-1'
                            : 'sm:col-span-1',
                        )}
                      >
                        <div className='flex flex-col justify-between h-full mb-10 sm:mb-0'>
                          <div>
                            <h2 className='text-3xl font-medium leading-6 text-white'>
                              {pack.name}
                            </h2>

                            <ul className='min-h-[64px] mt-6 space-y-4'>
                              {pack.includedFeatures.map((feature) => (
                                <li key={feature} className='flex space-x-3'>
                                  {/* <CheckIcon
                                    className='h-5 w-5 flex-shrink-0 text-green-500'
                                    aria-hidden='true'
                                  /> */}
                                  ✅
                                  <span className='ml-2 text-base text-lightGray'>
                                    {feature}
                                  </span>
                                </li>
                              ))}
                            </ul>

                            {/* <p className='mt-4 text-sm text-gray-300'>{pack.description}</p> */}
                            <p className='mt-8'>
                              <span className='text-4xl tracking-tight text-white'>
                                ${pack.price}
                              </span>
                            </p>
                          </div>

                          <PricingPageButton
                            text={
                              isCurrentPlan(pack)
                                ? 'Current Plan'
                                : pack.buttonText
                            }
                            action={() => {
                              setPricingInterval('packs')
                              pack.buttonAction(pack)
                            }}
                            isDisabled={isCurrentPlan(pack)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 md:mt-10 pt-10'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl font-medium leading-6 text-kaiberGreen'>
              Referrals
            </h3>
          </div>
        </div>
        {isSubscribed && (
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <ReferForm
              shareUrl={`${process.env.REACT_APP_SITE_URL}?referrer_id=${currentUser.uuid}`}
            />
          </div>
        )}
      </div>
      <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 border-b border-lightGray pb-10 mb-1'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'></div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='overflow-hidden shadow rounded-2xl'>
            <div className='px-4 py-5 sm:p-6 space-y-8 sm:pt-0'>
              <ReferrerIdForm />
            </div>
          </div>
        </div>
      </div>

      <div className='md:grid md:grid-cols-2 lg:grid-cols-3  md:gap-6 border-b border-lightGray pb-10 mb-1 pt-10'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl font-medium leading-6 text-kaiberGreen'>
              Affiliate Program
            </h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='overflow-hidden shadow rounded-2xl'>
            <div className='px-4 py-5 sm:p-6 sm:pt-0'>
              <p className='text-tertiary text-2xl'>
                Love Kaiber? Help Spread the Word!
              </p>
              <p className='text-lightGray mt-2 mb-2'>
                Become an affiliate to earn commissions on every subscription
                sale you refer to us.{' '}
              </p>
              <a
                href='https://znavcmypr3t.typeform.com/to/ImpnNpqQ'
                target='_blank'
                rel='noreferrer'
                className='mt-6 font-bold inline-block rounded-full border border-gray-800 bg-kaiberGreen button-shadow py-2 px-8 text-center text-base text-gray-900 hover:bg-white'
              >
                Apply here
              </a>
              {/* INSERT GLEAM EMBED HERE */}
            </div>
          </div>
        </div>
      </div>
      <div
        className='md:grid md:grid-cols-2 lg:grid-cols-3  md:gap-6 border-b border-lightGray pb-10 mb-1 pt-10'
        id='my-plan'
      >
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl font-medium leading-6 text-kaiberGreen'>
              My Plan
            </h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='overflow-hidden shadow rounded-2xl'>
            <div className='px-4 py-5 sm:p-6 sm:pt-0 space-y-8'>
              <p className='text-tertiary text-2xl'>Current plan</p>
              <p className='text-kaiberGreen flex gap-2 text-6xl'>
                {isSubscribed
                  ? `${
                      isFreeTrial
                        ? 'Explorer Free Trial'
                        : currentUser.subscriptionTierDescription
                    }`
                  : 'Free'}
              </p>
              {isSubscribed && !currentUser.subscriptionCancelAtPeriodEnd && (
                <p className='text-lightGray mt-2 mb-2'>
                  Your plan will renew on {planExpirationDate}.
                </p>
              )}
              {isSubscribed ? (
                <>
                  {currentUser.subscriptionCancelAtPeriodEnd && (
                    <p className='text-tertiary/80'>
                      <ExclamationTriangleIcon className='w-5 h-5 text-orange-500 inline-block mr-1' />
                      Your plan will expire on {subscriptionExpirationDate}.
                    </p>
                  )}

                  {!isMobileUser ? (
                    <div>
                      <Link
                        to='/pricing'
                        className='inline-flex items-center rounded-full border bg-transparent border-kaiberGreen text-primary px-8 py-2 text-lg font-medium shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mr-3 mb-5'
                      >
                        Change Plan
                      </Link>

                      <button
                        onClick={handleCancelRenew}
                        className={
                          currentUser.subscriptionCancelAtPeriodEnd
                            ? 'inline-flex items-center rounded-full border bg-kaiberGreen px-8 py-3 text-sm font-bold text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase gap-2 border-primary'
                            : 'inline-flex items-center rounded-full border bg-transparent border-kaiberGreen text-primary px-8 py-2 text-lg font-medium shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
                        }
                      >
                        {currentUser.subscriptionCancelAtPeriodEnd
                          ? 'Renew Plan'
                          : 'Unsubscribe'}
                      </button>
                    </div>
                  ) : (
                    <p className='text-lightGray mt-2 mb-2'>
                      Please manage your subscription on the Kaiber mobile app.
                    </p>
                  )}
                </>
              ) : null}

              {!isMobileUser && (
                <div>
                  <p className='text-tertiary text-2xl mt-6 mb-8'>Billing</p>

                  <button
                    onClick={handlePaymentMethod}
                    className='inline-flex items-center rounded-full border bg-transparent border-kaiberGreen text-primary px-8 py-2 text-lg font-medium shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
                  >
                    Update Payment Method
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {pricingInterval === 'packs' ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PaymentModal.pack
          isOpen={isOpen}
          onClose={handleOnClose}
          pack={amount}
        />
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <PaymentModal.credit
          isOpen={isOpen}
          onClose={handleOnClose}
          amount={quantityForPurchase}
        />
      )}
      {/* <FAQs /> */}
      <AskForCancelSubscriptionModal
        isOpen={isCancelSubscriptionModalOpen}
        onClose={setIsCancelSubscriptionModalOpen}
      />
    </>
  )
}
