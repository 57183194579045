import HowItWorksSection from '../components/home/howItWorks'
import FAQs from '../components/FAQs'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import CTA from '../components/home/CTA'

export default function HowItWorks() {
  return (
    <div className='bg-dark'>
      <Navbar />
      <HowItWorksSection />
      <FAQs />
      <CTA />

      <Footer />
    </div>
  )
}
