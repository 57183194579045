export enum EStage {
  LOCAL = 'local',
  STAGING = 'staging',
  PREVIEW = 'preview',
  PRODUCTION = 'production',
  UNKNOWN = 'unknown',
}

// TODO: this is not foolproof, example test.kaiber.ai would be considered production. Refactor to use STAGE env var
export const STAGE: EStage = (() => {
  const siteUrl = process.env.REACT_APP_SITE_URL

  if (siteUrl.includes('localhost')) {
    return EStage.LOCAL
  } else if (siteUrl.includes('staging.kaiber.ai')) {
    return EStage.STAGING
  } else if (siteUrl.includes('preview.kaiber.ai')) {
    return EStage.PREVIEW
  } else if (siteUrl.includes('kaiber.ai')) {
    return EStage.PRODUCTION
  } else {
    return EStage.UNKNOWN
  }
})()
