import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'

import { RoutesComponent } from './Routes'
import MarkerioHead from './components/MarkerioHead'
import RewardfullHead from './components/RewardfullHead'
import ReferrerIdHead from './components/ReferrerIdHead'
import PaddleHead from './components/PaddleHead'
import ImpactHead from './components/ImpactHead'
import { Survey } from './components/posthog-surveys/Survey'
import 'react-toastify/dist/ReactToastify.css'
import './styles/tailwind.css'
import './styles/global.css'
import './styles/slider.css'

export const App = () => {
  return (
    <BrowserRouter>
      <PaddleHead />
      <RewardfullHead />
      <ReferrerIdHead />
      <MarkerioHead />
      <ImpactHead />
      <RoutesComponent />
      <ToastContainer theme='dark' />
      <Survey />
    </BrowserRouter>
  )
}
