import React from 'react'

import { TagIcon } from './TagIcon'
import { useThemeContext } from '../../../context'
import { FilterIcon } from '../../../images/icons/FilterIcon'
import { cn } from '../../../utils'
import { TagFilter, TagNamespace } from '@/types'

interface TagFiltersProps {
  tagFilters: TagFilter[]
  handleFilterToggle: (tag: TagFilter) => Promise<void>
  isExpanded: boolean
  isDropdownOpen: boolean
  setIsDropdownOpen: (isOpen: boolean) => void
}

// Customizes Tag Namespaces's sort order according to UX requirements
const nsSortOrder = [
  TagNamespace.MediaType,
  TagNamespace.Feature,
  TagNamespace.UserTags,
  TagNamespace.Uploaded,
  TagNamespace.Upscale,
]
const tagSortFn = (a: TagFilter, b: TagFilter) => {
  if (a.ns === b.ns) {
    // If ns values are equal, sort by name
    return a.name.localeCompare(b.name)
  }
  const nsIndexA = nsSortOrder.indexOf(a.ns as TagNamespace)
  const nsIndexB = nsSortOrder.indexOf(b.ns as TagNamespace)
  return nsIndexA - nsIndexB
}

export const TagFilters: React.FC<TagFiltersProps> = ({
  tagFilters,
  handleFilterToggle,
  isExpanded,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const { colors } = useThemeContext()

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const tagsGroupedByNamespace = tagFilters
    .slice()
    .sort(tagSortFn)
    .reduce((acc, tag) => {
      if (!acc.has(tag.ns)) {
        acc.set(tag.ns, [])
      }
      acc.get(tag.ns).push(tag)
      return acc
    }, new Map<string, TagFilter[]>())

  return (
    <>
      <div
        className={cn(
          `flex gap-2 items-center p-2 rounded-lg cursor-pointer text-xs ${colors.background.element} ${colors.text.default}`,
          isDropdownOpen &&
            `${colors.elevation.surface.highlight} ${colors.text.default}`,
        )}
        onClick={toggleDropdown}
      >
        <FilterIcon size={12} />
        {isExpanded && <span className='text-xs'>Filter</span>}
      </div>
      {isDropdownOpen && (
        <div
          className={cn(
            `flex flex-col gap-6 absolute top-12 right-4 mt-2 p-4 w-72 ${colors.background.element} rounded-md shadow-xl z-20 max-h-[calc(100vh-300px)] overflow-y-auto`,
            isDropdownOpen &&
              `${colors.background.default} ${colors.text.default}`,
          )}
        >
          {[...tagsGroupedByNamespace].map(([ns, tags]) => (
            <div key={ns} className='flex flex-col gap-2'>
              <div className='text-xs font-semibold cursor-default'>{ns}</div>
              <div className='flex gap-2 flex-wrap'>
                {tags.map((tag) => (
                  <label
                    key={`${tag.ns}_${tag.name}`}
                    className={cn(
                      `flex items-center rounded-2xl border ${colors.border.inverse} text-xs font-semibold px-4 py-1 bg-transparent ${colors.text.default} cursor-pointer`,
                      tag.selected &&
                        `${colors.elevation.surface.highlight} ${colors.border.inverse} hover:${colors.elevation.surface.highlight}`,
                    )}
                  >
                    <input
                      type='checkbox'
                      checked={tag.selected}
                      className='hidden'
                      onChange={() => {
                        handleFilterToggle(tag)
                      }}
                    />
                    <TagIcon ns={tag.ns} name={tag.name} />
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
