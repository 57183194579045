import { useRef, useState, useEffect } from 'react'
import WideArrow from '../images/WideArrow.svg'
import { PlayIcon } from '@heroicons/react/24/outline'
import { isMobile } from 'react-device-detect'

const TrimAudio = ({
  file,
  handleTrimmedAudio,
  handleCancel,
  maxDuration = 60,
}) => {
  const audioRef = useRef(null)
  const waveformRef = useRef(null)
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)

  const [duration, setDuration] = useState(0)
  const [dragging, setDragging] = useState(false)
  const startRef = useRef(null)
  const endRef = useRef(null)

  useEffect(() => {
    if (!file) return
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    let audioBuffer = null

    const reader = new FileReader()
    reader.onload = (e) => {
      const bufferFile = e.target.result

      audioCtx.decodeAudioData(
        bufferFile,
        (buffer) => {
          audioBuffer = buffer
          setDuration(audioBuffer.duration)
          //set end to max duration if audio is longer than max duration
          if (audioBuffer.duration > maxDuration) {
            setEnd(maxDuration)
          } else {
            setEnd(audioBuffer.duration)
          }

          const canvasCtx = waveformRef.current.getContext('2d')
          canvasCtx.clearRect(
            0,
            0,
            waveformRef.current.width,
            waveformRef.current.height,
          )
          canvasCtx.fillStyle = '#000'
          canvasCtx.fillRect(
            0,
            0,
            waveformRef.current.width,
            waveformRef.current.height,
          )
          const bufferLength = audioBuffer.length
          const channelData = audioBuffer.getChannelData(0)
          const resolution = 500
          const height = waveformRef.current.height
          const width = waveformRef.current.width
          canvasCtx.beginPath()
          canvasCtx.strokeStyle = '#ECFBEC'
          for (let i = 0; i < bufferLength; i += resolution) {
            const x = (i / bufferLength) * width
            const y = ((1 + channelData[i]) * height) / 2
            if (i === 0) {
              canvasCtx.moveTo(x, y)
            } else {
              canvasCtx.lineTo(x, y)
            }
          }

          canvasCtx.stroke()
        },
        (error) => {
          console.error(error)
        },
      )
    }
    reader.readAsArrayBuffer(file)
  }, [file, maxDuration])

  const bufferToWave = (abuffer, len, channel_offset = 0) => {
    let numOfChan = abuffer.numberOfChannels,
      length = len * numOfChan * 2 + 44,
      buffer = new ArrayBuffer(length),
      view = new DataView(buffer),
      channels = [],
      offset = 0,
      i,
      sample,
      pos = 0

    // write WAVE header
    setUint32(0x46464952) // 'RIFF'
    setUint32(length - 8) // file length - 8
    setUint32(0x45564157) // 'WAVE'

    setUint32(0x20746d66) // 'fmt ' chunk
    setUint32(16) // length = 16
    setUint16(1) // PCM (uncompressed)
    setUint16(numOfChan)
    setUint32(abuffer.sampleRate)
    setUint32(abuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
    setUint16(numOfChan * 2) // block-align
    setUint16(16) // 16-bit (hardcoded in this demo)

    setUint32(0x61746164) // 'data' - chunk
    setUint32(length - pos - 4) // chunk length

    // write interleaved data
    for (i = 0; i < abuffer.numberOfChannels; i++)
      channels.push(abuffer.getChannelData(i))

    while ((pos < length) & (offset + channel_offset < channels[0].length)) {
      for (i = 0; i < numOfChan; i++) {
        // interleave channels
        sample = Math.max(-1, Math.min(1, channels[i][offset + channel_offset])) // clamp
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
        view.setInt16(pos, sample, true) // write 16-bit sample
        pos += 2
      }
      offset++ // next source sample
    }

    // create Blob
    return new Blob([buffer], { type: 'audio/wav' })

    function setUint16(data) {
      view.setUint16(pos, data, true)
      pos += 2
    }

    function setUint32(data) {
      view.setUint32(pos, data, true)
      pos += 4
    }
  }

  const cutAudio = (e) => {
    const duration = end - start
    console.log(duration)
    console.log(end)
    console.log(start)
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    let audioBuffer = null

    const reader = new FileReader()

    reader.onload = (e) => {
      const bufferFile = e.target.result

      audioCtx.decodeAudioData(
        bufferFile,
        (buffer) => {
          audioBuffer = buffer

          const newAudioBlob = bufferToWave(
            audioBuffer,
            2 * Math.round((duration * audioBuffer.sampleRate) / 2),
            2 * Math.round((start * audioBuffer.sampleRate) / 2),
          )
          console.log(newAudioBlob)
          handleTrimmedAudio(newAudioBlob)
        },
        (error) => {
          console.error(error)
        },
      )
    }
    reader.readAsArrayBuffer(file)
  }

  //   const handleEndChange = (e) => {
  //     const x = e.clientX / waveformRef.current.offsetWidth

  //     console.log({
  //       x,
  //       clientX: e.clientX,
  //       width: waveformRef.current.offsetWidth,
  //     })
  //     if (x === 0 || x <= start / duration) {
  //       return
  //     }

  //     setEnd(x * duration)
  //   }
  const getRelativeX = ({ clientX }) => {
    const canvasRect = waveformRef.current.getBoundingClientRect()
    const relativeX = clientX - canvasRect.left
    return relativeX
  }

  const handlePointerDown = (e) => {
    e.preventDefault()

    if (!isMobile) {
      return
    }
    setDragging(true)
    handlePointerMove(e)
  }

  const handlePointerUp = (e) => {
    e.preventDefault()

    if (!isMobile) {
      return
    }
    setDragging(false)
  }

  const handleDrag = (e) => {
    e.preventDefault()

    if (isMobile) {
      return
    }

    if (e.target.classList.contains('start-slider')) {
      handleStartChange(e)
    } else if (e.target.classList.contains('end-slider')) {
      handleEndChange(e)
    } else if (e.target.classList.contains('full-slider')) {
      handleStartEndChange(e)
    }
  }

  const handlePointerMove = (e) => {
    e.preventDefault()
    if (!isMobile) {
      return
    }

    if (dragging) {
      if (e.target.classList.contains('start-slider')) {
        handleStartChange(e)
      } else if (e.target.classList.contains('end-slider')) {
        handleEndChange(e)
      } else if (e.target.classList.contains('full-slider')) {
        handleStartEndChange(e)
      }
    }
  }

  const handleStartChange = (e) => {
    let clientX
    if (isMobile) {
      clientX = e.touches[0].clientX
    } else {
      clientX = e.clientX
    }
    const canvasRect = waveformRef.current.getBoundingClientRect()
    const relativeX = getRelativeX({ clientX })
    const newStart =
      Math.min(Math.max(relativeX / canvasRect.width, 0), 1) * duration

    const endX = getRelativeX({
      clientX: endRef.current.getBoundingClientRect().left,
    })

    if (relativeX > endX) {
      return
    }

    //check if new duration is not greater than max duration
    if (end - newStart > maxDuration) {
      return
    }

    if (newStart) {
      setStart(newStart)
    }
  }

  const handleEndChange = (e) => {
    let clientX
    if (isMobile) {
      clientX = e.touches[0].clientX
    } else {
      clientX = e.clientX
    }

    const canvasRect = waveformRef.current.getBoundingClientRect()

    const relativeX = getRelativeX({ clientX })
    const startX = getRelativeX({
      clientX: startRef.current.getBoundingClientRect().left,
    })

    if (relativeX < startX) {
      return
    }

    const newEnd =
      Math.min(Math.max(relativeX / canvasRect.width, 0), 1) * duration

    if (newEnd - start > maxDuration) {
      return
    }
    if (newEnd) {
      setEnd(newEnd)
    }

    // }
  }

  const handleStartEndChange = (e) => {
    let clientX
    if (isMobile) {
      clientX = e.touches[0].clientX
    } else {
      clientX = e.clientX
    }

    const canvasRect = waveformRef.current.getBoundingClientRect()
    const relativeX = getRelativeX({ clientX })

    const newStart =
      Math.min(Math.max(relativeX / canvasRect.width, 0), 1) * duration

    const newEnd = newStart + (end - start)

    if (newEnd > duration) {
      return
    }
    if (newStart) {
      setStart(newStart)
      setEnd(newEnd)
    }
  }

  const calcLeft = () => {
    if (!duration) return 0
    const left = (start / duration) * 100
    if (left >= 100) {
      return 0
    }
    return left
  }

  const calcRight = () => {
    const right = ((duration - end) / duration) * 100

    if (right >= 100) {
      return 0
    }
    return right
  }

  const calcDistance = () => {
    return `calc((${((end - start) / duration) * 100}%) - 32px)`
  }

  const formatTime = (time) => {
    if (isNaN(time)) {
      return '0:00'
    }
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const handleStartTimeChange = (event) => {
    event.preventDefault()
    const time = event.target.value.split(':')
    const minutes = parseInt(time[0], 10)
    const seconds = parseInt(time[1], 10)
    const startTime = minutes * 60 + seconds
    if (!startTime || startTime >= end) {
      return
    }

    if (end - startTime > maxDuration) {
      return
    }

    setStart(startTime)
  }

  const handleEndTimeChange = (event) => {
    const time = event.target.value.split(':')
    const minutes = parseInt(time[0], 10)
    const seconds = parseInt(time[1], 10)
    const endTime = minutes * 60 + seconds
    if (!endTime || endTime <= start || endTime > duration) {
      return
    }

    if (endTime - start > maxDuration) {
      return
    }

    setEnd(endTime)
  }

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = start
      audioRef.current.play()
    }
  }

  return (
    <div className='trim-audio space-y-10 p-5 sm:p-12'>
      <div className='space-y-2'>
        <p className='font-bold text-2xl text-tertiary'>Trim the audio</p>
        <p className='text-tertiary/[60]'>
          Description on how the audio clip length will affect cost in credits.
        </p>
      </div>

      <div className='flex relative w-full'>
        <canvas ref={waveformRef} className='w-full h-20'></canvas>
        <div
          className='start-slider absolute w-4 top-0 bottom-0 bg-primary left-0 cursor-col-resize rounded-l-3xl  flex items-center justify-center'
          style={{ left: `${calcLeft()}%` }}
          draggable
          onDragStart={(e) => {
            e.dataTransfer.setDragImage(new Image(), 0, 0)
          }}
          onDrag={handleDrag}
          onTouchStart={handlePointerDown}
          onTouchEnd={handlePointerUp}
          onTouchMove={handlePointerMove}
          ref={startRef}
        >
          <img
            src={WideArrow}
            className='rotate-180 select-none pointer-events-none'
            alt='wide arrow'
          />
        </div>
        <div
          className='full-slider top-0 bottom-0 bg-transparent border-primary border-2 absolute cursor-pointer'
          style={{
            left: `calc(${calcLeft()}% + 16px)`,
            width: calcDistance(),
          }}
          draggable
          onDragStart={(e) => {
            e.dataTransfer.setDragImage(new Image(), 0, 0)
          }}
          onDrag={handleDrag}
          onTouchStart={handlePointerDown}
          onTouchEnd={handlePointerUp}
          onTouchMove={handlePointerMove}
        ></div>

        <div
          className='end-slider absolute w-4 top-0 bottom-0 bg-primary right-0 cursor-col-resize rounded-r-3xl flex items-center justify-center'
          style={{ right: `${calcRight()}%` }}
          draggable
          onDragStart={(e) => {
            e.dataTransfer.setDragImage(new Image(), 0, 0)
          }}
          onDrag={handleDrag}
          onTouchStart={handlePointerDown}
          onTouchEnd={handlePointerUp}
          onTouchMove={handlePointerMove}
          ref={endRef}
        >
          <img
            src={WideArrow}
            className='select-none pointer-events-none'
            alt='wide arrow'
          />
        </div>
      </div>
      <div className='flex items-center gap-4'>
        <p className='text-tertiary/[60]'>Cut from</p>
        <label>
          <input
            type='text'
            className='bg-gray-300/25 px-4 py-3 text-tertiary/80 w-20 text-center'
            value={formatTime(start)}
            onChange={(e) => handleStartTimeChange(e)}
          />
        </label>
        <p className='text-tertiary/[60]'>To</p>
        <label>
          <input
            type='text'
            className='bg-gray-300/25 px-4 py-3 text-tertiary/80 w-20 text-center'
            value={formatTime(end)}
            onChange={(e) => handleEndTimeChange(e)}
          />
        </label>
      </div>
      <div className='flex items-center gap-4'>
        <audio
          src={URL.createObjectURL(file)}
          type={file.type}
          ref={audioRef}
          controls
          className='hidden'
        ></audio>
        <button className='border rounded-full border-primrary/25 px-3 py-3 text-sm font-medium text-primary shadow-sm  hover:border-primary focus:outline-none focus:ring-0 button-shadow gap-2'>
          <PlayIcon onClick={playAudio} className='w-4 h-4' />
        </button>
        <p className='text-tertiary/[60]'>
          Final output: <b>{Math.round(end - start)} s</b>
        </p>
      </div>
      <div className='flex items-center gap-4'>
        <button
          className='inline-flex items-center rounded-full border border-primary bg-transparent px-3 py-2 text-sm font-medium text-primary shadow-sm  hover:border-primary focus:outline-none focus:ring-0 button-shadow gap-2'
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className='inline-flex items-center gap-2 rounded-full border border-transparent radial-gradient px-3 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-0 button-shadow w-full sm:w-auto'
          onClick={(e) => cutAudio(e)}
        >
          Trim Audio
        </button>
      </div>
    </div>
  )
}

export default TrimAudio
