import { useState } from 'react'
import { toast } from 'react-toastify'
import http from '../services/HttpService'

import { useUserAccountContext } from '../context/userContext'
import { updateSocialLinks } from '../services/UserProfileService'
import { FormInput } from './FormInput'
import Button from './welcome/Button'

export default function AccountProfile() {
  const { currentUser, featureToggles, setCurrentUser } =
    useUserAccountContext()
  const [isPersonalLoading, setIsPersonalLoading] = useState(false)
  const [isSocialLoading, setIsSocialLoading] = useState(false)

  const [personalInformation, setPersonalInformation] = useState({
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    username: currentUser.username,
  })

  const [personalInformationError, setPersonalInformationError] = useState('')

  // This should be optional since not all users have them
  const [socialInformation, setSocialInformation] = useState({
    spotify: currentUser.links?.spotify ?? '',
    instagram: currentUser.links?.instagram ?? '',
    x: currentUser.links?.x ?? '',
    youtube: currentUser.links?.youtube ?? '',
    website: currentUser.links?.website ?? '',
    tiktok: currentUser.links?.tiktok ?? '',
  })

  const [socialInformationError, setSocialInformationError] = useState('')

  const UPDATE_INFORMATION_ERROR_MESSAGES = {
    FIRST_NAME_REQUIRED: 'First name is required',
    USERNAME_REQUIRED: 'Username is required',
    INVALID_NAME_CHARS:
      'First and last name can only contain letters and hyphens and spaces in-between.',
    NAME_LENGTH: 'Name must be between 2 and 32 characters.',
    INVALID_USERNAME_LENGTH: 'Username must be between 4 and 26 characters.',
    INVALID_USERNAME_CHARS:
      'Username can only contain lowercase letters, numbers, underscores, and periods.',
    USERNAME_TAKEN: 'This username is already taken.',
  }

  // eslint-disable-next-line no-useless-escape
  const nameRegex = /^([\p{L}\-]+\s?)*[\p{L}\-]+$/u
  const usernameRegex = /^[a-z0-9_.]+$/

  const profileFeatureToggle = featureToggles['feature-user-profiles']

  function validateFirstName(firstName) {
    if (firstName === '' || !firstName) {
      setPersonalInformationError(
        UPDATE_INFORMATION_ERROR_MESSAGES.FIRST_NAME_REQUIRED,
      )
      return false
    }
    if (!firstName.match(nameRegex)) {
      setPersonalInformationError(
        UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_NAME_CHARS,
      )
      return false
    }
    if (firstName.length > 32 || firstName.length < 2) {
      setPersonalInformationError(UPDATE_INFORMATION_ERROR_MESSAGES.NAME_LENGTH)
      return false
    }
    return true
  }

  function validateLastName(lastName) {
    // If there is a last name, check that it isn't less 2 or greater 32
    if (!lastName || lastName === '') return true

    if (!lastName.match(nameRegex)) {
      setPersonalInformationError(
        UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_NAME_CHARS,
      )
      return false
    }

    if (lastName.length > 32 || lastName.length < 2) {
      setPersonalInformationError(UPDATE_INFORMATION_ERROR_MESSAGES.NAME_LENGTH)
      return false
    }
    return true
  }

  function validateUsername(username) {
    if (username === '') {
      setPersonalInformationError(
        UPDATE_INFORMATION_ERROR_MESSAGES.USERNAME_REQUIRED,
      )
      return false
    }

    if (username.length > 26 || username.length < 3) {
      setPersonalInformationError(
        UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_USERNAME_LENGTH,
      )
      return false
    }
    if (!username.match(usernameRegex)) {
      setPersonalInformationError(
        UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_USERNAME_CHARS,
      )
      return false
    }
    return true
  }

  /**
   * @param {string} name
   * @param {string} link
   * @returns {boolean}
   */
  function validateSocialLink(name, link) {
    if (!link) return true

    const validateInstagram = (link) => {
      const regex = new RegExp(
        `^(https?://)?(www.)?instagram.com/([a-zA-Z0-9_.]+)/?.*$`,
      )
      return regex.test(link)
    }

    const validateTikTok = (link) => {
      const regex = new RegExp(
        `^(https?://)?(www.)?tiktok.com/([@a-zA-Z0-9_.]+)/?.*$`,
      )
      return regex.test(link)
    }

    const validateX = (link) => {
      const xRegex = new RegExp(
        `^(https?://)?(www.)?x.com/([@a-zA-Z0-9_.]+)/?.*$`,
      )
      const twitterRegex = new RegExp(
        `^(https?://)?(www.)?twitter.com/([@a-zA-Z0-9_.]+)/?.*$`,
      )
      return xRegex.test(link) || twitterRegex.test(link)
    }

    const validateYouTube = (link) => {
      const regex = new RegExp(
        /^(https?:\/\/)?(www\.)?youtube\.com\/(c\/|channel\/|user\/|[a-zA-Z0-9_-]+\/?)\??.*$/,
      )
      return regex.test(link)
    }

    const validateSpotify = (link) => {
      const artistRegex = new RegExp(
        `^(https?://)?open.spotify.com/artist/[a-zA-Z0-9_]+/?.*$`,
      )

      const userRegex = new RegExp(
        `^(https?://)?open.spotify.com/user/[a-zA-Z0-9_]+/?.*$`,
      )

      const isValid = artistRegex.test(link) || userRegex.test(link)

      return isValid
    }

    const validateWebsite = (link) => {
      const urlString = link.trim()
      // eslint-disable-next-line no-useless-escape
      const urlRegex = /^(https?:\/\/)?[^\s\/$.?#].[^\s]*$/
      return urlRegex.test(urlString)
    }

    let isValid = false

    switch (name.toLowerCase()) {
      case 'instagram':
        isValid = validateInstagram(link)
        break
      case 'tiktok':
        isValid = validateTikTok(link)
        break
      case 'x':
        isValid = validateX(link)
        break
      case 'youtube':
        isValid = validateYouTube(link)
        break
      case 'spotify':
        isValid = validateSpotify(link)
        break
      case 'website':
        isValid = validateWebsite(link)
        if (isValid && !link.startsWith('http')) {
          link = `https://${link}`
          setSocialInformation({ ...socialInformation, website: link })
          socialInformation.website = link
        }
        break
      default:
        // Handle additional social media platforms if needed
        break
    }

    if (!isValid && name !== 'website') {
      // If URL pattern validation fails, treat as a username and construct the social link
      let username = link.trim()
      if (username && !username.includes('.com')) {
        switch (name.toLowerCase()) {
          case 'instagram':
            link = `https://www.instagram.com/${username}`
            setSocialInformation({ ...socialInformation, instagram: link })
            socialInformation.instagram = link
            break
          case 'tiktok':
            if (!username.includes('@')) {
              username = `@${username}`
            }
            link = `https://www.tiktok.com/${username}`
            setSocialInformation({ ...socialInformation, tiktok: link })
            socialInformation.tiktok = link
            break
          case 'x':
            link = `https://x.com/${username}`
            setSocialInformation({ ...socialInformation, x: link })
            socialInformation.x = link
            break
          case 'youtube':
            link = `https://www.youtube.com/channel/${username}`
            setSocialInformation({ ...socialInformation, youtube: link })
            socialInformation.youtube = link
            break
          case 'spotify':
            link = `https://open.spotify.com/artist/${username}`
            setSocialInformation({ ...socialInformation, spotify: link })
            socialInformation.spotify = link
            break
          default:
            // Handle additional social media platforms if needed
            break
        }
        isValid = true
      } else {
        setSocialInformationError(`Invalid ${name} link or username`)
        return false
      }
    }

    if (!isValid) {
      setSocialInformationError(`Invalid ${name} link`)
    }

    return isValid
  }

  const submitNames = async (e) => {
    e.preventDefault()

    const isFirstNameValid = validateFirstName(personalInformation.firstName)
    const isLastNameValid = validateLastName(personalInformation.lastName)

    let isUsernameValid = true

    if (profileFeatureToggle) {
      isUsernameValid = validateUsername(personalInformation.username)
    }

    if (!isFirstNameValid || !isLastNameValid || !isUsernameValid) return
    setPersonalInformationError('')
    setIsPersonalLoading(true)
    try {
      const res = await http.patch('/api/auth/me', personalInformation)
      if (res.status !== 204) {
        toast.error(`Something went wrong: ${res.data.message}`)
        return
      }
      setCurrentUser({
        ...currentUser,
        firstName: personalInformation.firstName,
        lastName: personalInformation.lastName,
        username: personalInformation.username,
      })
      toast.success('Successfully updated your info!')
    } catch (error) {
      if (error.response.data.code === 'INPUT_VALIDATION_ERROR') {
        if (error.response.data.errors?.username) {
          setPersonalInformationError(
            UPDATE_INFORMATION_ERROR_MESSAGES.USERNAME_TAKEN,
          )
        } else {
          setPersonalInformationError(
            `Error updating information: ${error.message}`,
          )
        }
      } else if (error.response.data.code === 'NSFW_CONTENT_ERROR') {
        setPersonalInformationError(
          `Error updating information: ${error.message}`,
        )
      } else {
        setPersonalInformationError(`Something went wrong: ${error.message}`)
      }
      console.log(error)
    }
    setIsPersonalLoading(false)
  }

  async function submitSocials(e) {
    e.preventDefault()

    setIsSocialLoading(true)
    try {
      const entries = Object.entries(socialInformation)

      for (const [key, value] of entries) {
        if (!validateSocialLink(key, value)) {
          setIsSocialLoading(false)
          return
        }

        // Check to make sure that the link has https in front if it's not a username
        if (value && !value.startsWith('http')) {
          socialInformation[key] = `https://${value}`
        }
      }
      setSocialInformationError('')
      const res = await updateSocialLinks(socialInformation)
      if (res.status !== 204) {
        toast.error(res.data.message)
        setIsSocialLoading(false)
        return
      }
      setCurrentUser({
        ...currentUser,
        links: socialInformation,
      })
      toast.success('Successfully updated your info!')
    } catch (error) {
      toast.error(`Something went wrong: ${error}`)
    }
    setIsSocialLoading(false)
  }

  function handleSocialInformationChange(event) {
    setSocialInformationError('')
    setSocialInformation({
      ...socialInformation,
      [event.target.name]: event.target.value,
    })
  }

  function handlePersonalInformationChange(event) {
    setPersonalInformationError('')
    setPersonalInformation({
      ...personalInformation,
      [event.target.name]: event.target.value,
    })
  }

  if (!profileFeatureToggle) {
    return <></>
  }

  return (
    <>
      <AccountProfileSection
        id={'personalInformation'}
        title='Personal Information'
      >
        <div className='space-y-2'>
          <div className='grid grid-flow-row gap-3'>
            <FormInput
              name={'firstName'}
              label='First name'
              value={personalInformation.firstName}
              handleChange={handlePersonalInformationChange}
              placeHolder='Enter your first name'
            />
            <FormInput
              name={'lastName'}
              label='Last name'
              value={personalInformation.lastName}
              handleChange={handlePersonalInformationChange}
              placeHolder='Enter your last name'
            />
            <FormInput
              name={'username'}
              label='Username'
              value={personalInformation.username}
              handleChange={handlePersonalInformationChange}
              placeHolder='Enter your username'
            />
            <p className='text-red-400 text-xs px-5 min-h-[16px]'>
              {personalInformationError}
            </p>
            {!isPersonalLoading ? (
              <Button btnText='Save' onClick={submitNames} />
            ) : (
              <Button btnText='Loading...' disabled />
            )}
          </div>
        </div>
      </AccountProfileSection>
      <AccountProfileSection id={'socialLinks'} title='Social Links'>
        <div className='space-y-2'>
          <div className='grid grid-flow-row gap-3'>
            <FormInput
              name={'instagram'}
              label='Instagram'
              value={socialInformation.instagram}
              handleChange={handleSocialInformationChange}
              placeHolder='Enter your Instagram profile link'
            />
            <FormInput
              name={'tiktok'}
              label='Tiktok'
              value={socialInformation.tiktok}
              handleChange={handleSocialInformationChange}
              placeHolder='Enter your TikTok profile link'
            />
            <FormInput
              name={'x'}
              label='X (Twitter)'
              value={socialInformation.x}
              handleChange={handleSocialInformationChange}
              placeHolder='Enter your X (Twitter) profile link'
            />
            <FormInput
              name={'youtube'}
              label='Youtube'
              value={socialInformation.youtube}
              handleChange={handleSocialInformationChange}
              placeHolder='Enter your Youtube profile link'
            />
            <FormInput
              name={'spotify'}
              label='Spotify'
              value={socialInformation.spotify}
              handleChange={handleSocialInformationChange}
              placeHolder='Enter your Spotify profile link'
            />
            <FormInput
              name={'website'}
              label='Website'
              value={socialInformation.website}
              handleChange={handleSocialInformationChange}
              placeHolder='Enter your website link'
            />
          </div>
        </div>
        <p className='text-red-400 px-5 min-h-[16px]'>
          {socialInformationError}
        </p>
        <div className='mt-5'>
          {!isSocialLoading ? (
            <Button btnText='Save' onClick={submitSocials} />
          ) : (
            <Button btnText='Loading...' disabled />
          )}
        </div>
      </AccountProfileSection>
    </>
  )
}

function AccountProfileSection({ title, id, children }) {
  return (
    <>
      <div
        id={id}
        className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 border-b border-lightGray pb-10 mb-1'
      >
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl leading-6 text-kaiberGreen'>{title}</h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='shadow rounded-2xl'>
            <div className='px-4 py-5 sm:p-6 sm:pt-0'>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}
