import { useState } from 'react'
import { toast } from 'react-toastify'
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom'

import http from '../services/HttpService'
import { Modal } from '../components/Modal'
import { Alert } from '../components/Alert'

const options = [
  `This isn't what I prompted`,
  `This is what I prompted but I don't like the quality`,
  `This is harmful/unsafe`,
]

export default function VideoFeedbackModal({
  open,
  setOpen,
  page,
  status,
  setDone,
}) {
  const params = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)

  const sendFeedback = async (e) => {
    e.preventDefault()

    const text = e.target[0].value
    const feedbackOptions = []

    if (text.length < 3) {
      setShowAlert(true)
      setAlertMessage('The message must be at least 3 characters.')
      return
    }

    if (setDone) {
      setDone(true)
    }

    setIsSubmitting(true)

    // eslint-disable-next-line eqeqeq
    if (status == 0) {
      for (let i = 0, c = 1; i < options.length; i++) {
        const { checked } = e.target[c++]

        if (checked) {
          feedbackOptions.push(options[i])
        }
      }
    }

    await http.post(`/api/create_video_feedback/${params.memory_id}`, {
      page,
      // eslint-disable-next-line eqeqeq
      status: status == 0 ? 'Negative' : 'Positive',
      text,
      options: feedbackOptions,
      url: window.location.href,
    })

    setIsSubmitting(false)
    setOpen(false)

    toast.success('Thank you for your feedback!')
  }

  const handleMessageChange = () => {
    setShowAlert(false)
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <form onSubmit={sendFeedback}>
        <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
          <div className='flex items-center gap-4'>
            <span
              className={`${
                // eslint-disable-next-line eqeqeq
                status == 0
                  ? 'text-red-500 bg-red-500/25'
                  : 'text-primary bg-primary/25'
              } p-3 rounded-full`}
            >
              {status === 1 ? (
                <HandThumbUpIcon className='h-6 w-6' />
              ) : (
                <HandThumbDownIcon className='h-6 w-6' />
              )}
            </span>

            <p className='text-2xl sm:text-4xl text-tertiary'>
              Provide aditional feedback
            </p>
          </div>
          <div>
            <textarea
              rows='3'
              id='feedback-text'
              name='feedback-text'
              className='w-full block rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 pl-3 bg-[#545854]/25 text-white'
              placeholder={
                status === 1
                  ? 'What did you like about the image/video?'
                  : 'What would be your ideal image/video?'
              }
              onInput={handleMessageChange}
            ></textarea>
            <div className='mt-3 mb-4'>
              <Alert
                open={showAlert}
                setOpen={setShowAlert}
                message={alertMessage}
                type='alert'
              />
            </div>
            {
              // eslint-disable-next-line eqeqeq
              status == 0 &&
                options.map((option, index) => (
                  <div
                    key={`feedback-check-${index}`}
                    className={index === 0 ? 'mt-3' : ''}
                  >
                    <input
                      type='checkbox'
                      name={`feedback-check-${index}`}
                      id={`feedback-check-${index}`}
                      className='w-4 h-4 mr-2 align-middle'
                    />
                    <label
                      htmlFor={`feedback-check-${index}`}
                      className='text-tertiary align-middle'
                    >
                      {option}
                    </label>
                  </div>
                ))
            }
          </div>
          <div className='mt-4 flex items-center justify-end space-x-2'>
            <button
              type='submit'
              className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
              disabled={isSubmitting}
            >
              Submit feedback
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
