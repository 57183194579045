import classNames from 'classnames'
import { useEffect, useState, useMemo } from 'react'
import {
  IoMdArrowRoundBack,
  IoIosArrowDown,
  IoIosArrowUp,
} from 'react-icons/io'
import { RiArrowRightDownLine } from 'react-icons/ri'

import { MagicWandButton } from './MagicWandButton'
import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import { cn, shuffleArray } from '../utils'
import { MOTION, PLACEHOLDERS, TRANSFORM } from '../utils/constants'

export default function PromptSelection({
  selectedCategory,
  handleCategoryChange,
  handleNextCategory,
  handleNextCategoryMobile,
  checkCategoryValueSelected,
  checkCategoryHasValue,
  useUserProvidedStartingFrame,
  isLoading,
  subject,
  artStyle,
  negativePrompt,
  isMobile,
  inputRefSubject,
  inputRefStyle,
  inputRefNegative,
  handleKeyDown,
  selectSubjectText,
  selectStyleText,
  selectNegativeText,
  isInterrogating,
  interrogate,
  isVideo,
  videoType,
  skipPrompt,
  userProvidedStartingFrame,
  userProvidedVideoFile,
}) {
  const { featureToggles } = useUserAccountContext()
  const enableNegativePrompt = featureToggles['negative-prompt']
  const [showNegativePrompt, setShowNegativePrompt] = useState(false)
  const [curatedPrompts, setCuratedPrompts] = useState(null)
  const [showExpandedStyles, setShowExpandedStyles] = useState(false)
  const NUM_OF_PRESET_SUBJECTS = 6

  const subjects = useMemo(
    () =>
      curatedPrompts?.subjects
        ? shuffleArray(curatedPrompts.subjects).slice(0, NUM_OF_PRESET_SUBJECTS)
        : [],
    [curatedPrompts],
  )

  const featuredStyles = useMemo(
    () => curatedPrompts?.featuredStyles || [],
    [curatedPrompts],
  )

  const generalStyles = useMemo(
    () => curatedPrompts?.generalStyles || [],
    [curatedPrompts],
  )

  const handleStyleChange = (style) => {
    if (
      featuredStyles.some((featuredStyle) => featuredStyle.name === style.name)
    ) {
      // If the selected style is a featured style, populate the style field with its name
      handleCategoryChange('artStyle', style.name, false, true)
    } else {
      // If the selected style is a general style, use the stylePrompt
      handleCategoryChange('artStyle', style.stylePrompt)
    }
  }

  useEffect(() => {
    const fetchCuratedPrompts = async () => {
      try {
        const response = await http.get(
          `/api/v2/curated_prompts?videoType=${videoType}`,
        )

        setCuratedPrompts(response.data)
      } catch (error) {
        console.error('Error fetching curated prompts:', error)
      }
    }

    fetchCuratedPrompts()
  }, [videoType])

  const isVideoSettingsButtonDisabled = (() => {
    if (videoType === MOTION && userProvidedStartingFrame) {
      return false
    }
    if (videoType === TRANSFORM && userProvidedVideoFile) {
      return false
    } else if (videoType === TRANSFORM && !userProvidedVideoFile) {
      return true
    }
    return (
      !checkCategoryHasValue('artStyle') || !checkCategoryHasValue('subject')
    )
  })()

  const toggleNegativePrompt = () => {
    setShowNegativePrompt(!showNegativePrompt)
  }

  const handleNextCategoryClick = () => {
    handleNextCategory()
  }

  let generateText = 'Describe Style'

  if (selectedCategory === 'artStyle') {
    generateText = 'Video settings'
  }

  const handleMobileButtonClick = () => {
    if (
      (!subject || subject === PLACEHOLDERS.SUBJECT) &&
      (!artStyle || artStyle.length === 0) &&
      userProvidedStartingFrame
    ) {
      skipPrompt()
      return
    }
    handleNextCategoryMobile()
  }

  const allStyles = useMemo(() => {
    return [...featuredStyles, ...generalStyles]
  }, [featuredStyles, generalStyles])

  return (
    <div className='flex flex-col text-quaternary'>
      {!isMobile && (
        <>
          <span className='mb-5'>SUBJECT</span>
          <h1 className='mb-6 text-2xl whitespace-pre-line xl:text-3xl'>
            Write your own description or select an idea.
          </h1>
        </>
      )}

      <>
        {/*  BUTTONS SUBJECT */}
        {(selectedCategory !== 'artStyle' || !isMobile) && (
          <div className='flex flex-col gap-2 pb-2'>
            {isMobile && (
              <>
                <div className='leading-normal whitespace-pre-line lg:leading-[2.5rem] text-xl mb-5'>
                  <p className='text-quaternary'>
                    I want to create a video of{' '}
                    {useUserProvidedStartingFrame || isVideo ? (
                      <MagicWandButton
                        onClick={interrogate}
                        isLoading={isInterrogating}
                        color='#A59CDA'
                        className='inline text-xl'
                      />
                    ) : null}
                  </p>
                  <textarea
                    ref={inputRefSubject}
                    type='text'
                    onKeyDown={handleKeyDown}
                    onFocus={(e) => {
                      if (e.target.value === PLACEHOLDERS.SUBJECT) {
                        e.target.value = ''
                      }
                    }}
                    onChange={(e) =>
                      handleCategoryChange(
                        'subject',
                        e.target.value || ' ',
                        true,
                      )
                    }
                    onBlur={(e) => {
                      handleCategoryChange(
                        'subject',
                        e.target.value.trim() !== PLACEHOLDERS.SUBJECT &&
                          e.target.value.trim().replace(/\s+/g, ' '),
                        true,
                      )
                    }}
                    value={subject || PLACEHOLDERS.SUBJECT}
                    className={classNames(
                      'bg-quaternary py-1 px-2 font-bold cursor-text outline-none min-w-[250px] max-w-[90%] whitespace-pre-wrap inline-flex resize-none h-auto mt-2',
                      !subject ? 'text-black/50' : 'text-black',
                    )}
                    rows={1}
                  />
                  <IoMdArrowRoundBack
                    className='inline w-8 h-8 cursor-pointer text-quaternary hover:scale-90 align-top mt-[10px]'
                    onClick={selectSubjectText}
                  />
                </div>
              </>
            )}
            <div className='max-w-[100vw] relative w-[calc(100%+1.25rem)]'>
              <div className='flex flex-wrap gap-2 items-center'>
                {subjects.map((option, index) => (
                  <button
                    data-label={option.name}
                    key={`categoryOption-${index}`}
                    className={classNames(
                      'border relative buttonPrompt px-4 py-2 sm:text-base text-sm rounded-full border-quaternary/50 hover:bg-quaternary hover:text-black transition-all capitalize whitespace-nowrap',
                      checkCategoryValueSelected(
                        'subject',
                        option.subjectPrompt,
                      ) && 'bg-quaternary text-black font-bold',
                    )}
                    onClick={() =>
                      handleCategoryChange(
                        'subject',
                        option.subjectPrompt,
                        false,
                        false,
                        true,
                      )
                    }
                  >
                    <span className='absolute top-2 bottom-2 left-4 right-4 select-none'>
                      {option.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>

            {enableNegativePrompt && isMobile && videoType !== TRANSFORM && (
              <div className='mt-4'>
                <p
                  className='text- text-[20px] text-[#B6B6B6] flex items-center cursor-pointer mb-[10px]'
                  onClick={toggleNegativePrompt}
                >
                  I don’t want to see
                  <div className='text-[#B6B6B6] ml-[5px] mt-[3px]'>
                    {showNegativePrompt ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </div>
                </p>

                <div
                  className={classNames(
                    'overflow-hidden w-full transition-[height]',
                    showNegativePrompt ? 'h-[100px]' : 'h-0',
                  )}
                >
                  <textarea
                    ref={inputRefNegative}
                    type='text'
                    onKeyDown={handleKeyDown}
                    onFocus={(e) => {
                      if (e.target.value === PLACEHOLDERS.NEGATIVE) {
                        e.target.value = ''
                      }
                    }}
                    onChange={(e) =>
                      handleCategoryChange(
                        'negative',
                        e.target.value || ' ',
                        true,
                      )
                    }
                    onBlur={(e) => {
                      handleCategoryChange(
                        'negative',
                        e.target.value.trim() !== PLACEHOLDERS.NEGATIVE &&
                          e.target.value.trim().replace(/\s+/g, ' '),
                        true,
                      )
                    }}
                    value={negativePrompt || PLACEHOLDERS.NEGATIVE}
                    className={classNames(
                      'bg-[#B6B6B6] py-1.5 px-2 font-bold cursor-text outline-none whitespace-pre-wrap inline-flex h-auto resize-none max-w-[90%]',
                      !negativePrompt ? 'text-black/50' : 'text-black',
                    )}
                    rows={1}
                  />
                  <IoMdArrowRoundBack
                    className='inline w-8 h-8 cursor-pointer text-[#B6B6B6] hover:scale-90 align-top mt-[2px]'
                    onClick={selectNegativeText}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* FEATURED CURATED STYLES */}
        {(selectedCategory === 'artStyle' || !isMobile) && (
          <div
            className={`${
              isMobile ? 'flex flex-col gap-2 sm:pb-4 sm:mt-10' : 'mt-8'
            }`}
          >
            {isMobile && (
              <>
                {/* <h1 className='text-primary'>PROMPT</h1> */}
                {/* <span className='w-4/5 mb-5 text-base leading-4 text-primary'>Describe style or choose from our Exclusive artist-inspired styles collection</span> */}
                <div className='leading-normal whitespace-pre-line lg:leading-[2.5rem] text-xl mb-5'>
                  <p className='text-primary'>in the style of </p>
                  <textarea
                    ref={inputRefStyle}
                    type='text'
                    onKeyDown={handleKeyDown}
                    onFocus={(e) => {
                      if (e.target.value === PLACEHOLDERS.STYLE) {
                        e.target.value = ''
                      }
                    }}
                    onChange={(e) => {
                      handleCategoryChange(
                        'artStyle',
                        e.target.value || ' ',
                        true,
                      )
                    }}
                    onBlur={(e) => {
                      handleCategoryChange(
                        'artStyle',
                        e.target.value.trim() !== PLACEHOLDERS.STYLE &&
                          e.target.value.trim().replace(/\s+/g, ' '),
                        true,
                      )
                    }}
                    value={
                      artStyle && artStyle.length > 0
                        ? artStyle.join(', ')
                        : PLACEHOLDERS.STYLE
                    }
                    className={classNames(
                      'bg-primary py-1 px-2 font-bold cursor-text min-w-[222px] max-w-[90%] outline-none whitespace-pre-wrap inline-flex resize-none h-auto',
                      !artStyle || artStyle.length === 0
                        ? 'text-black/50'
                        : 'text-black',
                    )}
                    rows={1}
                  />
                  <IoMdArrowRoundBack
                    className='inline w-8 h-8 cursor-pointer text-primary hover:scale-90 align-top mt-[5px]'
                    onClick={selectStyleText}
                  />
                </div>
              </>
            )}
            <h1
              className={`mb-8 text-2xl whitespace-pre-line xl:text-3xl text-primary ${
                isMobile ? 'hidden' : 'block'
              }`}
            >
              Curated styles collection
            </h1>

            <button
              onClick={() => setShowExpandedStyles(!showExpandedStyles)}
              className='text-primary mt-4 flex items-center relative pr-8'
            >
              <span className='mr-1'>
                {showExpandedStyles ? 'Hide All Styles' : 'See All Styles'}
              </span>
              {!showExpandedStyles && (
                <span className='top-0 text-xs text-primary font-bold'>
                  New!
                </span>
              )}
              <svg
                className={`h-4 w-4 transition-transform absolute right-2 ${
                  showExpandedStyles ? 'transform rotate-180' : ''
                }`}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>

            {/* GENERAL CURATED STYLES */}
            <div className='expanded-styles-container mt-3 mb-8'>
              <div
                className={`grid grid-cols-3 gap-4 relative ${
                  showExpandedStyles ? '' : 'max-h-[200px] overflow-y-hidden'
                }`}
              >
                {allStyles.map((style, index) => (
                  <button
                    key={`style-${index}`}
                    className='flex flex-col items-center'
                    onClick={() => handleStyleChange(style)}
                  >
                    <div
                      className={`w-full pb-[75%] relative rounded-lg overflow-hidden border-2 ${
                        checkCategoryValueSelected(
                          'artStyle',
                          style.stylePrompt,
                        )
                          ? 'border-primary'
                          : 'border-transparent'
                      }`}
                    >
                      <img
                        src={style.imageUrl}
                        alt={style.name}
                        className='absolute inset-0 w-full h-full object-cover object-center'
                      />
                    </div>
                    <span className='mt-2 text-center text-k2-gray-100 text-xs font-normal'>
                      {style.name}
                    </span>
                  </button>
                ))}
                {!showExpandedStyles && (
                  <div className='absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-black to-transparent pointer-events-none'></div>
                )}
              </div>
              <div className='flex justify-center mt-4'>
                <button
                  onClick={() => setShowExpandedStyles(!showExpandedStyles)}
                  className='text-primary flex items-center bg-darkGray rounded-md p-2'
                >
                  <svg
                    className={`h-4 w-4 transition-transform text-primary ${
                      showExpandedStyles ? 'transform rotate-180' : ''
                    }`}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}

        {!isLoading && (
          <>
            {isMobile ? (
              <div className='w-full bg-black fixed bottom-0 pt-5 pb-5 inset-x-0'>
                <button
                  className={classNames(
                    'inline-flex w-fit items-center rounded-full ml-5 mb-2 bg-primary pl-3 pr-5 py-1 text-base font-semibold text-gray-900 gap-2',
                    (videoType === MOTION && userProvidedStartingFrame) ||
                      checkCategoryHasValue(selectedCategory)
                      ? 'hover:opacity-80 active:opacity-50 duration-100'
                      : 'bg-primary/25 text-black/50 cursor-not-allowed',
                  )}
                  onClick={handleMobileButtonClick}
                  disabled={
                    !(
                      (videoType === MOTION && userProvidedStartingFrame) ||
                      checkCategoryHasValue(selectedCategory)
                    )
                  }
                >
                  <RiArrowRightDownLine className='w-6 h-6 font-black' />
                  {generateText}
                </button>
              </div>
            ) : (
              <button
                className={cn(
                  'inline-flex w-fit items-center rounded-full bg-primary pl-3 pr-5 py-1 sm:text-xl text-base font-semibold text-gray-900 gap-2 select-none',
                  !isVideoSettingsButtonDisabled &&
                    checkCategoryHasValue('artStyle') &&
                    checkCategoryHasValue('subject')
                    ? 'hover:opacity-80 active:opacity-50 duration-100'
                    : 'bg-primary/25 text-black/50 cursor-not-allowed',
                )}
                onClick={() => {
                  if (
                    isVideoSettingsButtonDisabled ||
                    !checkCategoryHasValue('artStyle') ||
                    !checkCategoryHasValue('subject')
                  )
                    return
                  handleNextCategoryClick()
                }}
                disabled={isVideoSettingsButtonDisabled}
              >
                <RiArrowRightDownLine className='w-6 h-6 font-black sm:w-8 sm:h-8' />
                Video settings
              </button>
            )}
          </>
        )}
      </>
    </div>
  )
}
