import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import { useUserAccountContext } from '../context/userContext'

import { isAndroid, isIOS } from 'react-device-detect'
import logoFooter from '../images/welcome/logo_footer.png'

const radial = '/website/mobilelanding/radient-gradient.png'
const heroPhoneTwo = '/website/mobilelanding/phone-mock-two.png'
const apple = '/website/mobilelanding/apple.svg'
const android = '/website/mobilelanding/android.svg'

function GetMobile({ id }) {
  const { currentUser, isAuthenticated } = useUserAccountContext()
  const mixpanel = useMixpanel()

  const query = new URLSearchParams(window.location.search)
  const redirectParam = query.get('redirect')
  let redirect = '/dashboard'
  if (redirectParam) {
    redirect = redirectParam
  }

  useEffect(() => {
    // TODO: Kevin - check client\src\components\Onboarding.js to determine if we need this.
    // Since this page is shown first after registration for mobile users (whereas onboarding
    // was previously shown first), need to ensure mixpanel is identifying and tracking properly.
    if (isAuthenticated) mixpanel.identify(currentUser.distinctId)
  }, [currentUser.distinctId, isAuthenticated, mixpanel])

  return (
    <div className='w-full h-full onboardingBg flex justify-center'>
      <div className='w-full min-h-[100vh] relative'>
        <img
          className='absolute top-0 right-0'
          src={radial}
          alt='radial-gradient'
        />

        <div className='w-full min-h-[100vh] flex justify-center relative py-[80px]'>
          <div className='flex h-full items-center justify-between w-[95%] flex-col md:flex-row max-w-[1200px]'>
            <img className='max-w-[60%]' src={logoFooter} alt='logo footer' />

            {isAndroid && (
              <div
                className='text-[#ECFBEC] max-w-[100%] md:max-w-[60%] mt-[40px] text-center'
                id='download'
              >
                <h1 className='text-[32px] md:text-[48px] leading-[1.2]'>
                  Get the Mobile App
                </h1>
                <p className='text-base md:text-[20px] mt-[20px] mb-[30px]'>
                  Turn text, videos, photos, and music into stunning AI
                  animations in minutes. Available now on Google Play.
                </p>

                <div className='flex items-center gap-[20px] md:gap-[90px] justify-center flex-col md:flex-row mt-[55px]'>
                  <a href='https://play.google.com/store/apps/details?id=ai.kaiber.mobile&pli=1'>
                    <img
                      src={android}
                      alt='android'
                      className='max-w-[275px] w-[198px] md:w-[221px]'
                    />
                  </a>
                </div>
              </div>
            )}

            {isIOS && (
              <div
                className='text-[#ECFBEC] max-w-[100%] md:max-w-[60%] mt-[40px] text-center'
                id='download'
              >
                <h1 className='text-[32px] md:text-[48px] leading-[1.2]'>
                  Get the Mobile App
                </h1>
                <p className='text-base md:text-[20px] mt-[20px] mb-[30px]'>
                  Turn text, videos, photos, and music into stunning AI
                  animations in minutes. Available now on iOS.
                </p>

                <div className='flex items-center gap-[20px] md:gap-[90px] justify-center flex-col md:flex-row mt-[55px]'>
                  <a href='https://apps.apple.com/app/kaiber/id6458980808'>
                    <img src={apple} alt='apple' className='max-w-[275px]' />
                  </a>
                </div>
              </div>
            )}

            <Link to={redirect} className='text-[#fff] my-[30px] text-center'>
              Continue on web
            </Link>

            <div className='flex items-center justify-center mt-[20px]'>
              <img
                src={heroPhoneTwo}
                alt='phone'
                className='max-w-[300px] mr-[-10px]'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetMobile
