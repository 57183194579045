import { useInfiniteQuery } from '@tanstack/react-query'

import { getMyMedia } from '../../api'
import { mediaStore, myLibraryStore } from '../../stores'
import { Media, TagFilter } from '../../types'

const MEDIAS_FETCH_SIZE = 20

/**
 * React Query for listing Medias with pagination.
 * Doc:
 *  https://tanstack.com/query/latest/docs/framework/react/guides/infinite-queries
 *  https://tanstack.com/query/v5/docs/framework/react/reference/useInfiniteQuery
 * Example:
 *  https://tanstack.com/query/latest/docs/framework/react/examples/load-more-infinite-scroll
 */
export const useListMedia = (tagFilters: TagFilter[] | null) => {
  const queryFn = async ({ pageParam = null }) => {
    const response = await getMyMedia(MEDIAS_FETCH_SIZE, pageParam, tagFilters)
    response.medias.forEach((media: Media) => {
      mediaStore.setMedia(media)
    })
    // Write the fetched Media IDs to myLibraryStore.
    if (pageParam) {
      // The user is trying to load more; so append.
      myLibraryStore.setMediaIds((prevMediaIds) => [
        ...prevMediaIds,
        ...response.medias.map((media: Media) => media.mediaId),
      ])
    } else {
      // pageParam is null, meaning a fresh load; so replace.
      myLibraryStore.setMediaIds(
        response.medias.map((media: Media) => media.mediaId),
      )
    }
    return {
      medias: response.medias as Media[],
      nextCursor: response.nextCursor,
    }
  }

  return useInfiniteQuery({
    queryKey: ['medias', { tagFilters }],
    queryFn,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: null,
  })
}
