import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import http from '../../services/HttpService'
import AdminNavbar from '../../components/AdminNavbar'
import Footer from '../../components/Footer'
import Pagination from '../../components/admin/Pagination'
import { cn } from '../../utils'

export default function Users() {
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)

  const tableWrapperRef = useRef()

  const fetchUsers = async (page = 1, q = '') => {
    setIsLoading(true)

    const response = await http.get('/api/admin/users', {
      params: {
        page,
        q,
      },
    })

    setUsers(response.data.items)
    setTotalItems(response.data.totalItems)
    setIsLoading(false)

    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTo(0, 0)
    }
  }

  const formatRegisterDate = (createdAt) => {
    return moment
      .duration({
        from: createdAt,
      })
      .humanize()
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    fetchUsers(newPage, searchText)
  }

  const handleSearch = async (e) => {
    if (e.key === 'Enter' && !isLoading) {
      setSearchText(e.target.value)
      setCurrentPage(1)
      fetchUsers(1, e.target.value)
    }
  }

  const handleClearFilter = () => {
    setSearchText('')
    fetchUsers()
    document.getElementById('search').value = ''
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <div className='bg-dark'>
      <AdminNavbar />
      <div className='section py-10'>
        <div className='container mx-auto max-w-7xl space-y-8 px-5'>
          <div className='flex flex-col sm:flex-row justify-between'>
            <h1 className='text-tertiary/80 flex gap-2 text-5xl font-bold'>
              Users
            </h1>
            <div className='flex gap-2'>
              <div className={cn('flex-1 relative', isLoading && 'opacity-50')}>
                <input
                  type='text'
                  name='search'
                  id='search'
                  placeholder='Search for users'
                  className='w-full mt-8 sm:mt-0 sm:w-auto block rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-3 pl-3 bg-[#545854]/25 text-white'
                  onKeyDown={handleSearch}
                  disabled={isLoading}
                />
                <button
                  className='absolute flex mt-8 sm:mt-0 items-center justify-end h-12 pr-3 w-full top-0 pointer-events-none'
                  onClick={handleSearch}
                >
                  <MagnifyingGlassIcon
                    className='h-5 w-5 text-tertiary/80'
                    aria-hidden='true'
                  />
                </button>
              </div>
              <button
                type='button'
                onClick={handleClearFilter}
                className={`w-12 inline-flex justify-center items-center gap-2 mt-8 sm:mt-0 bg-[#545854]/25 p-3 rounded-lg text-white text-center block${
                  searchText === '' || isLoading ? ' opacity-50' : ''
                }`}
                disabled={searchText === '' || isLoading}
              >
                <XMarkIcon
                  className='h-5 w-5 text-tertiary/80'
                  aria-hidden='true'
                />
              </button>
            </div>
          </div>
          {
            // eslint-disable-next-line eqeqeq
            isLoading && totalItems == 0 ? (
              <div className='flex h-full'>
                <div className='m-auto bg-darkGray p-6 rounded-3xl'>
                  <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                </div>
              </div>
            ) : (
              <>
                <div className='bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl relative'>
                  <div ref={tableWrapperRef} className='overflow-x-auto'>
                    <table className='w-full text-white table-auto border-collapse min-w-[500px]'>
                      <thead>
                        <tr>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Name
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Signed Up
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr
                            key={user.distinctId}
                            className='cursor-pointer transition-all hover:bg-[#545854]/25'
                          >
                            <td
                              className={`${
                                index < users.length - 1
                                  ? 'border-b border-[#545854]/25'
                                  : ''
                              }`}
                            >
                              <Link
                                to={`/admin/users/${user.distinctId}`}
                                className='block p-3'
                              >
                                <div className='flex items-center'>
                                  <img
                                    src={user.picture || '/favicon-32x32.png'}
                                    alt={`Profile icon`}
                                    className='rounded-full w-10 h-10'
                                    referrerPolicy='no-referrer'
                                  />
                                  <div className='ml-4'>
                                    <span className='block text-primary'>
                                      {user.name || user.email}
                                    </span>
                                    <span className='block text-gray-400'>
                                      {user.email}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </td>
                            <td
                              className={`${
                                index < users.length - 1
                                  ? 'border-b border-[#545854]/25'
                                  : ''
                              }`}
                            >
                              <Link
                                to={`/admin/users/${user.distinctId}`}
                                className='flex items-center p-3 h-[72px]'
                              >
                                {formatRegisterDate(user.createdAt)}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {isLoading && (
                    <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 rounded-3xl'>
                      <div className='flex flex-col items-center space-y-4 m-auto'>
                        <div className='bg-darkGray p-6 rounded-3xl'>
                          <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isLoading && (
                  <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 rounded-3xl'>
                    <div className='flex flex-col items-center space-y-4 m-auto'>
                      <div className='bg-darkGray p-6 rounded-3xl'>
                        <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                      </div>
                    </div>
                  </div>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={0}
                  disabled={isLoading}
                  onPageChange={handlePageChange}
                />
              </>
            )
          }
          <div></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
