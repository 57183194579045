import { useCallback, useId, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { saveMedia } from '../../../../api'
import { VIDEO_UPLOAD_S3_KEY } from '../../../../constants'
import { useThemeContext } from '../../../../context/themeContext'
import { useAnalytics } from '../../../../hooks'
import { FileUploadIcon } from '../../../../images/icons/FileUploadIcon'
import { LoadingIcon } from '../../../../images/icons/LoadingIcon'
import { mediaStore, myLibraryStore } from '../../../../stores'
import {
  FileWithSource,
  VideoMetadata,
  TagNamespace,
  ElementType,
  AnalyticsEvent,
  MediaUploadSource,
} from '../../../../types'
import { cn } from '../../../../utils'
import { useDndHandlers } from '../../../../utils/dndUtils'
import { processDroppedFiles } from '../../../../utils/fileUtils'
import { extractAssetKey } from '../../../../utils/mediaUtils'
import { CanvasBaseElement } from '../CanvasBaseElement'

/**
 * VideoUploadElement is used for uploading a video file
 */
export const VideoUpload = () => {
  const { register, setValue, watch, formState } = useFormContext()
  const inputRef = useRef(null)
  const [isFileProcessing, setIsFileProcessing] = useState(false)
  const { trackEvent } = useAnalytics()
  const id = useId()
  const { colors } = useThemeContext()
  const [isDroppable, setIsDroppable] = useState(false)

  const errorMessage = useMemo(() => {
    if (!formState.isValid && formState.errors) {
      return (formState.errors[VIDEO_UPLOAD_S3_KEY]?.message ?? '') as string
    }
    return undefined
  }, [formState])

  const handleFileDrop = useCallback(
    async (
      files: FileWithSource[],
      event: React.DragEvent<HTMLDivElement> | null,
      external: boolean = false,
    ) => {
      try {
        setIsFileProcessing(true)

        const isExternalFile = event
          ? event.dataTransfer.types[0] === 'Files'
          : external

        const processedFiles = await processDroppedFiles(files, 'video')
        const loadedFile = processedFiles[0]

        let assetKey = extractAssetKey(loadedFile.source)

        if (isExternalFile) {
          const savedMedia = await saveMedia(
            loadedFile.file,
            [
              { ns: TagNamespace.MediaType, name: 'Video' },
              { ns: TagNamespace.Uploaded, name: 'Yes' },
            ],
            loadedFile.metadata,
          )
          trackEvent(AnalyticsEvent.MediaUploaded, {
            mediaId: savedMedia.mediaId,
            mediaType: savedMedia.type,
            fileSize: loadedFile.file.size,
            fileType: loadedFile.file.type,
            uploadSource: MediaUploadSource.VideoUpload,
          })
          mediaStore.setMedia(savedMedia)
          myLibraryStore.prependMediaId(savedMedia.mediaId)
          assetKey = savedMedia.assetKey
        }

        const metadata = loadedFile.metadata as VideoMetadata

        setValue(
          'length',
          Math.min(Math.max(Math.ceil(metadata?.duration ?? 0), 1)),
        )
        setValue('width', metadata.width)
        setValue('height', metadata.height)
        setValue(VIDEO_UPLOAD_S3_KEY, assetKey)
      } catch (error) {
        console.error('Error processing dropped files:', error)
      } finally {
        setIsFileProcessing(false)
      }
    },
    [setValue, trackEvent],
  )

  const {
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDragEnd,
  } = useDndHandlers({ handleFileDrop })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesWithSource = Array.from(event.target.files).map((file) => ({
      file,
      source: URL.createObjectURL(file),
    }))
    if (filesWithSource) {
      handleFileDrop(Array.from(filesWithSource), null, true)
    }
  }

  const assetKey: string = watch(VIDEO_UPLOAD_S3_KEY)

  return (
    <CanvasBaseElement label='Video Upload' errorMessage={errorMessage}>
      <div
        className={cn(
          `p-2 border border-gray-500 rounded-2xl ${colors.elevation.surface.sunken} h-full flex flex-col items-center justify-center gap-2`,
          {
            'border-green-500 border-dashed': isDroppable && !isFileProcessing,
          },
        )}
        data-droppable={true}
        data-element-type={ElementType.VideoUpload}
        onDrop={(event: React.DragEvent<HTMLDivElement>) => {
          setIsDroppable(false)
          handleDrop(event)
        }}
        onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
          setIsDroppable(true)
          handleDragOver(event)
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={(event: React.DragEvent<HTMLDivElement>) => {
          setIsDroppable(false)
          handleDragLeave(event)
        }}
        onDragEnd={handleDragEnd}
        onClick={() => inputRef.current?.click()}
      >
        <div className='flex flex-col items-center justify-center space-y-2'>
          {assetKey ? (
            <div className='relative w-full h-max group'>
              <div className={`${colors.text.default} text-[12px]`}>
                Video file uploaded
              </div>
            </div>
          ) : (
            <div className='flex flex-col items-center'>
              {isFileProcessing ? (
                <>
                  <LoadingIcon
                    className={`${colors.text.default}`}
                    width='24px'
                    height='24px'
                  />
                  <div className={`${colors.text.default} text-[12px]`}>
                    Uploading media...
                  </div>
                </>
              ) : (
                <>
                  <FileUploadIcon
                    className={`${colors.text.default}`}
                    width='24px'
                    height='24px'
                  />
                  <div className={`${colors.text.default} text-[12px]`}>
                    Upload video
                  </div>
                </>
              )}
            </div>
          )}
          <label
            htmlFor={id}
            className={`py-1 px-4 rounded-full cursor-pointer border ${colors.border.brand} ${colors.text.brand} text-[10px] border-full`}
            onClick={(e) => e.stopPropagation()}
          >
            {assetKey ? 'Change File' : 'Choose File'}
          </label>

          {/* hidden field for file upload */}
          <input
            ref={inputRef}
            disabled={isFileProcessing}
            id={id}
            type='file'
            accept='video/*'
            className='hidden'
            onChange={handleInputChange}
            onClick={(e) => e.stopPropagation()}
          />

          {/* hidden field for assetKey */}
          <input
            className='hidden'
            {...register(VIDEO_UPLOAD_S3_KEY, {
              validate: (assetKey: string) =>
                !!assetKey || 'Need to upload a video first.',
            })}
          />
          <input type='hidden' {...register('length', { value: 0 })} />
        </div>
      </div>
    </CanvasBaseElement>
  )
}
