import { Link } from 'react-router-dom'

export const Banner = () => {
  return (
    <div className='py-[10px] min-[420px]:py-[6px] z-20 items-center justify-center w-full h-10 text-sm min-[420px]:text-lg text-center shadow-2xl cursor-default bg-[#303030] text-kaiberGreen'>
      🎉 The Kaiber Mobile App is here!{' '}
      <Link className='underline' to='/mobile'>
        Download now
      </Link>
      . 🎉
    </div>
  )
}
