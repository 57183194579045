const CtaToast = ({ description, cta, onClick }) => (
  <span className='text-base text-kaiberGreen'>
    {description}
    <span
      className='inline-block mt-4 transition cursor-pointer opacity-80 hover:opacity-100'
      onClick={onClick}
    >
      🚀<span className='ml-2 underline'>{cta}</span>
    </span>
  </span>
)

export default CtaToast
