import { useState } from 'react'
import { PasswordStrengthPopover } from '../components/PasswordStrengthPopover'

export function PasswordInput({
  id = 'password',
  required = false,
  disabled = false,
  className = '',
  placeholder = '',
  onInput = () => {},
}) {
  const [password, setPassword] = useState('')
  const [passwordFocus, setPasswordFocus] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(null)

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    onInput(e)
  }

  const handlePasswordFocus = (e) => {
    setPasswordFocus(true)

    if (passwordStrength === 'WEAK_PASSWORD') {
      setPopoverOpen(true)
    }
  }

  const handlePasswordBlur = (e) => {
    setPasswordFocus(false)
    setPopoverOpen(false)
  }

  const handlePasswordKeydown = (e) => {
    if (e.key === 'Enter') {
      setPopoverOpen(false)
    }
  }

  const handlePasswordStrengthChange = (_passwordStrength) => {
    if (_passwordStrength === 'WEAK_PASSWORD' && passwordFocus) {
      setPopoverOpen(true)
    } else {
      setPopoverOpen(false)
    }

    setPasswordStrength(_passwordStrength)
  }

  return (
    <PasswordStrengthPopover
      open={popoverOpen}
      password={password}
      setPasswordStrength={handlePasswordStrengthChange}
    >
      <input
        type='password'
        id={id}
        onInput={handlePasswordChange}
        onFocus={handlePasswordFocus}
        onBlur={handlePasswordBlur}
        onKeyDown={handlePasswordKeydown}
        required={required}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        maxLength={128}
      />
    </PasswordStrengthPopover>
  )
}
