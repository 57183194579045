import { Link } from 'react-router-dom'
import { FaSpotify, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa'
import { FaXTwitter, FaLink } from 'react-icons/fa6'
import { TiPlus } from 'react-icons/ti'

export const SocialLinks = ({ links, canEdit }) => {
  const hasLinks = links && Object.keys(links).length > 0

  return hasLinks ? (
    <div className='grid grid-cols-3 gap-3 justify-items-center bg-darkGray text-3xl p-4 rounded-xl'>
      {links.spotify && (
        <a
          href={links.spotify}
          target='_blank'
          rel='noreferrer'
          className={' hover:cursor-pointer text-gray-400'}
        >
          <FaSpotify />
        </a>
      )}
      {links.instagram && (
        <a
          href={links.instagram}
          target='_blank'
          rel='noreferrer'
          className={' hover:cursor-pointer text-gray-400'}
        >
          <FaInstagram />
        </a>
      )}
      {links.x && (
        <a
          href={links.x}
          target='_blank'
          rel='noreferrer'
          className={' hover:cursor-pointer text-gray-400'}
        >
          <FaXTwitter />
        </a>
      )}
      {links.youtube && (
        <a
          href={links.youtube}
          target='_blank'
          rel='noreferrer'
          className={' hover:cursor-pointer text-gray-400'}
        >
          <FaYoutube />
        </a>
      )}
      {links.website && (
        <a
          href={links.website}
          target='_blank'
          rel='noreferrer'
          className={' hover:cursor-pointer text-gray-400'}
        >
          <FaLink />
        </a>
      )}
      {links.tiktok && (
        <a
          href={links.tiktok}
          target='_blank'
          rel='noreferrer'
          className={' hover:cursor-pointer text-gray-400'}
        >
          <FaTiktok />
        </a>
      )}
    </div>
  ) : (
    canEdit && (
      <Link className='bg-darkGray p-4 rounded-xl text-gray-400' to='/account'>
        <TiPlus className={'text-primary inline mb-1 w-6 h-6'} /> Connect{' '}
        <span className='hidden sm:inline'> your </span> socials
      </Link>
    )
  )
}
