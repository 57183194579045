import { Link } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import { PlusIcon } from '@heroicons/react/24/outline'
import Lottie from 'lottie-react'
import LoadingWhite from '../images/lottie/loaderWhite.json'

import { GallerySkeleton } from '../components/GallerySkeleton'
import CanvasCell from '../components/CanvasCell/CanvasCell'

const INFINITE_SCROLL_BUFFER = 5 // how many videos from end should trigger a fetch

export const VideoGallery = ({
  videos,
  getVideos,
  setSearchText,
  searchInputRef,
  currentPage,
  setCurrentPage,
  totalPages,
  isLoading,
  isDashboard = false,
  onVideoStatusChange = (videoId, status, url) => undefined,
  onDelete = (isOpen, videoId, userData) => undefined,
  onInfo = (isOpen, settingsUsed) => undefined,
  onMakePublic = () => undefined,
  onUpscaleOpen = (_memory) => undefined,
  isUpscaling = false,
  isMakingPublic = false,
  onUpscaleSuccess = (memoryId) => undefined,
  onUpscaleError = (memoryId) => undefined,
  onDownload = (memory) => undefined,
  isAdmin = false,
  username = '',
  columns = 4,
}) => {
  const handleNextPageLoad = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const searchText = searchInputRef?.current?.value ?? ''

  const handleClearSearch = () => {
    setSearchText('')
    searchInputRef.current.value = ''
    getVideos()
  }

  const masonryCols =
    columns === 3
      ? { default: 3, 500: 1, 800: 2 }
      : { default: 4, 500: 1, 800: 2, 1100: 3 }

  return (
    <>
      {/* SPINNER ON INITIAL LOAD */}
      {isLoading && videos.length === 0 && (
        <div className='flex flex-col items-center space-y-4'>
          <div className='p-6 bg-darkGray rounded-3xl'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        </div>
      )}

      {/* NO VIDEOS */}
      {isDashboard && !isLoading && videos.length === 0 && !searchText && (
        <div className='relative'>
          <GallerySkeleton columns={columns} />
          {username ? (
            <div className='absolute inset-0 flex items-center justify-center text-center'>
              <div>
                <p className='text-tertiary/80'>
                  This user has no public videos
                </p>
              </div>
            </div>
          ) : (
            <div className='absolute inset-0 flex items-center justify-center text-center'>
              <div>
                <p className='text-tertiary/80'>
                  Each video you create will be saved here.
                </p>
                <Link to='/create'>
                  <button className='inline-flex items-center gap-3 px-6 py-5 mt-10 text-base font-bold text-black uppercase border border-transparent rounded-full shadow-sm radial-gradient hover:bg-green-700 focus:outline-none focus:ring-0'>
                    <PlusIcon className='w-6 h-6' /> create your first video
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}

      {/* VIDEOS */}
      {videos.length > 0 && (
        <Masonry
          breakpointCols={masonryCols}
          className='flex w-auto masonry'
          columnClassName='masonry-col'
        >
          {videos.map((video, index) => (
            <CanvasCell
              key={`video-${video._id}`}
              canvas={video}
              isDashboard={isDashboard}
              isAdmin={isAdmin}
              onVideoStatusChange={onVideoStatusChange}
              onDelete={onDelete}
              onInfo={onInfo}
              onMakePublic={onMakePublic}
              onUpscaleOpen={onUpscaleOpen}
              isUpscaling={isUpscaling ? isUpscaling(video.memory_id) : false}
              isMakingPublic={isMakingPublic}
              onUpscaleSuccess={onUpscaleSuccess}
              onUpscaleError={onUpscaleError}
              onDownload={onDownload}
              setSearchText={setSearchText}
              searchInputRef={searchInputRef}
              getVideos={getVideos}
              onInfiniteScroll={handleNextPageLoad}
              isLast={index === videos.length - INFINITE_SCROLL_BUFFER}
            />
          ))}
        </Masonry>
      )}

      {/* EMPTY SEARCH RESULTS */}
      {!isLoading && videos.length === 0 && searchText && (
        <div className='text-center'>
          <p className='mt-4 text-tertiary/60'>
            No videos found for '<strong>{searchText}</strong>'.
          </p>
          <button
            className='inline-flex items-center justify-between flex-grow gap-2 px-6 py-4 mt-3 text-base font-bold text-white uppercase border border-transparent rounded-full shadow-sm bg-secondary hover:bg-green-700 focus:outline-none focus:ring-0'
            onClick={handleClearSearch}
          >
            Clear filter
          </button>
        </div>
      )}
    </>
  )
}
