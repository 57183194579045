import RelatedArticlesItem from './RelatedArticlesItem'

function RelatedArticles() {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-col'>
        <h1 className='sm:text-3xl text-2xl font-semibold'>Related Articles</h1>
        <hr className='w-full bg-white/50 border-0 h-px my-5' />
      </div>
      <div className='flex flex-col gap-10'>
        <RelatedArticlesItem
          title={'Getting started with Kaiber'}
          description={'Your quick start guide to using all Kaiber features.'}
          articleUrl={
            'https://helpcenter.kaiber.ai/kaiber/en/articles/7934569-what-is-kaiber-and-how-does-it-work'
          }
        />
        <RelatedArticlesItem
          title={'Kaiber Mobile App'}
          description={
            'Use our platform on iOS or Android to make stunning AI art on the go.'
          }
          articleUrl={
            'https://helpcenter.kaiber.ai/kaiber/en/articles/8540406-getting-started-with-kaiber-s-mobile-app'
          }
        />
        <RelatedArticlesItem
          title={'AI video prompting 101'}
          description={'New to AI art prompting? We’ve got you covered.'}
          articleUrl={
            'https://helpcenter.kaiber.ai/kaiber/en/articles/7935594-what-are-best-practices-for-ai-video-prompting'
          }
        />
        <RelatedArticlesItem
          title={'What types of media can I upload to Kaiber?'}
          description={
            'From images to audio and video files, there are many ways to create on Kaiber.'
          }
          articleUrl={
            'https://helpcenter.kaiber.ai/kaiber/en/articles/7935656-what-types-of-media-can-i-upload-to-kaiber'
          }
        />
      </div>
    </div>
  )
}

export default RelatedArticles
