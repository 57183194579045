import { formatDuration } from '../utils'
import { Waveform } from './Waveform'

export const SliderMusicPlayer = ({ currentTime, audioUrl }) => {
  return (
    <div className='relative flex items-center w-full gap-5 mb-4'>
      {/* WAVEFORM AUDIO VISUALIZER */}
      <div className='absolute top-0 left-0 w-[calc(100%-93px)] ml-[45px]'>
        <Waveform audioUrl={audioUrl} />
      </div>

      <span className={`text-kaiberGreen py-1 text-lg font-light`}>
        {formatDuration(currentTime || 0)}
      </span>
    </div>
  )
}
