import DownloadIcon from '../../images/Download.svg'
import { getPrompt } from '../../utils'

export const DownloadButton = ({
  isDownloading,
  canvas,
  onDownload,
  downloadVideo,
  downloadPercent,
}) => {
  const prompt = () => {
    if (canvas.prompt) return canvas.prompt
    else return getPrompt(canvas.scenes[0]?.subject, canvas.scenes[0]?.style)
  }

  return (
    <button
      onClick={(e) => {
        if (!isDownloading) {
          if (canvas.versions && onDownload) {
            onDownload(canvas)
          } else {
            downloadVideo(
              canvas.upscaledVideoUrl || canvas.url,
              `${prompt()
                .substr(0, 150)
                .replace(/\r\n/g, ' ')} (${Date.now()}).mp4`,
            )
          }
        }
      }}
      disabled={
        typeof canvas.status !== 'undefined' && canvas.status !== 'done'
      }
      className={`${
        isDownloading
          ? 'bg-gray-700 hover:bg-gray-700 focus:ring-0 focus:ring-offset-0 cursor-default'
          : 'hover:bg-green-300'
      }
      ${
        typeof canvas.status !== 'undefined' && canvas.status !== 'done'
          ? 'pointer-events-none opacity-50'
          : ''
      }
      p-0 rounded-full text-white hover:text-gray-700 hover:bg-opacity-70 focus:text-gray-700 shadow-md focus:outline-none transition ease-in-out duration-200 h-7 w-7`}
    >
      {isDownloading ? (
        <span className='flex justify-center text-[10px] text-white'>
          {downloadPercent}%
        </span>
      ) : (
        <img src={DownloadIcon} alt='download icon' />
      )}
    </button>
  )
}
