import { useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import MusicianVideoSlider from '../components/MusicianVideoSlider'
import MusicianVideoSliderMobile from '../components/MusicianVideoSliderMobile'
import http from '../services/HttpService'
import { toast } from 'react-toastify'
import { isMobile } from '../utils'

const universalMusicGroupImg =
  'https://website-public-assets.kybercorp.org/web/musician/universal-music-group-logo-white.png'
const warnerMusicGroupImg =
  'https://kaiber-public-assets.s3.amazonaws.com/web/musician/Warner_Music_Group_logo-white.png'
const heroBackground =
  'https://website-public-assets.kybercorp.org/web/musician/hero.jpg'
const radial =
  'https://website-public-assets.kybercorp.org/web/mobilelanding/radient-gradient.png'
const heroPhone =
  'https://website-public-assets.kybercorp.org/web/musician/device.gif'
const experience =
  'https://website-public-assets.kybercorp.org/web/musician/experience.gif'
const offerBanner =
  'https://website-public-assets.kybercorp.org/web/musician/offer-banner_360.gif'

export default function Musician() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    const name = e.target[0].value
    const email = e.target[1].value
    const musicUrl = e.target[2].value
    const data = {
      name: name,
      email: email,
      musicUrl: musicUrl,
    }
    try {
      await http.post(`/api/musician_subscribe`, data)

      setTimeout(() => {
        e.target[0].value = ''
        e.target[1].value = ''
        e.target[2].value = ''
        toast.success(
          'Thanks for your interest in Kaiber! You will receive your discount code shortly.',
        )
        setIsSubmitting(false)
        setShowSuccessMessage(true)
      }, 1000)
    } catch (err) {
      setIsSubmitting(false)
      toast.error(
        'There was a problem with your submission. Please try again later.',
      )
    }
  }

  return (
    <>
      <div className='bg-dark'>
        <Navbar faded />
        <main className='pt-[74px]'>
          <div className='w-full relative overflow-hidden'>
            <img
              className='min-w-[200%] md:ml-0 md:min-w-[100%] w-full'
              src={heroBackground}
              alt='Hero Banner'
            />
            <h1 className='h-[110px] font-normal text-[#ECFBEC] text-5xl md:text-[94px] leading-[1.2] absolute top-0 bottom-0 m-auto left-0 right-0 text-left w-[95%] max-w-[1200px] z-10'>
              Kaiber for Musicians
            </h1>
          </div>

          <div className='w-full min-h-[100vh] relative'>
            <img
              className='absolute top-0 right-[-20px] hidden md:block'
              src={radial}
              alt='radial-gradient'
            />

            <div className='w-full flex justify-center relative py-[50px] pb-0 md:py-[80px] md:pb-0'>
              <div className='flex h-full items-center justify-between w-[95%] flex-col-reverse md:flex-row max-w-[1200px]'>
                <img src={heroPhone} alt='phone' className='max-w-[375px]' />
                <div className='text-[#ECFBEC] max-w-[100%] md:max-w-[65%] flex flex-col mb-[120px] md:mb-0'>
                  <h2 className='text-[48px] md:text-[64px] leading-[1.2]'>
                    Music visuals made easy
                  </h2>
                  <p className='text-[20px] mt-[20px] mb-[30px]'>
                    Create audioreactive visualizers, music videos, and art for
                    your Spotify Canvases, social media, and more in just
                    minutes.
                  </p>

                  <Link
                    to='/create'
                    className='w-full max-w-xs px-4 text-2xl py-4 font-bold text-center text-gray-900 rounded-full bg-kaiberGreen'
                  >
                    Try for Free
                  </Link>
                </div>
              </div>
            </div>

            <section className='px-5 py-[120px] md:py-[200px] text-center text-white flex flex-col justify-center items-center'>
              <h4 className='text-[64px] font-bold sm:text-6xl md:text-7xl text-tertiary'>
                5,000,000+
              </h4>
              <p className='mt-4 text-4xl text-tertiary m-0 mt-[10px]'>
                creatives choose Kaiber
              </p>
              <p className='mt-4 text-xl text-tertiary mt-[24px] mb-[24px]'>
                Musicians enjoy 50% off first 3 months
              </p>

              <LinkScroll
                to='ac-form'
                className='w-full max-w-xs px-4 text-2xl py-4 font-bold text-center text-gray-900 rounded-full bg-kaiberGreen relative cursor-pointer'
                spy={true}
                smooth={true}
                offset={-112}
              >
                Claim Discount
              </LinkScroll>
            </section>

            <section className='text-center text-white overflow-hidden'>
              <div className='block md:flex w-full relative'>
                <img
                  src={experience}
                  alt='phone'
                  className='w-full lg:w-[60%]'
                />

                <div className='w-[95%] md:w-[45%] flex-end md:absolute right-0 bg-black h-full shadow-[0_20px_70px_120px_rgba(0,0,0,1)] items-center md:flex m-auto mt-[24px] md:mt-0'>
                  <div className='md:ml-[-130px] text-white text-left max-w-[100%] flex flex-col gap-[24px]'>
                    <h2 className='text-[32px] lg:text-[48px] leading-[1.2]'>
                      Experience Your Sound
                    </h2>
                    <ul className='list-disc pl-[25px] box-border flex flex-col gap-[15px] md:gap-[20px]'>
                      <li className=''>
                        <p className='text-base lg:text-[20px]'>
                          <strong>Music Videos and Visualizers:</strong> Simply
                          add your music and the video will automatically sync
                          to your beats.
                        </p>
                      </li>
                      <li className=''>
                        <p className='text-base lg:text-[20px]'>
                          <strong>Spotify Canvases and Apple Motion:</strong>{' '}
                          Capture attention and boost your streams with
                          stunning, full-screen visuals.
                        </p>
                      </li>
                      <li className=''>
                        <p className='text-base lg:text-[20px]'>
                          <strong>Live Event Visuals:</strong> Create an
                          unforgettable visual experience for your fans—no
                          additional resources required.
                        </p>
                      </li>
                      <li className=''>
                        <p className='text-base lg:text-[20px]'>
                          <strong>Album and Banner Art:</strong> Upload existing
                          artwork and get dynamic, on-brand assets for your
                          channels.
                        </p>
                      </li>
                      <li className=''>
                        <p className='text-base lg:text-[20px]'>
                          <strong>Social Media:</strong> Automate content
                          creation with eye-catching visuals in any size you
                          need.
                        </p>
                      </li>
                    </ul>

                    <LinkScroll
                      to='ac-form'
                      className='w-full max-w-xs px-4 text-2xl py-4 font-bold text-center text-gray-900 rounded-full bg-kaiberGreen cursor-pointer'
                      spy={true}
                      smooth={true}
                      offset={-112}
                    >
                      Unlock 50% Off
                    </LinkScroll>
                  </div>
                </div>
              </div>
            </section>

            <section className='px-0 py-24 text-white'>
              <h2 className='mb-16 text-5xl font-bold text-center sm:text-6xl md:text-7xl text-tertiary'>
                Made with Kaiber
              </h2>

              {isMobile() ? (
                <div className='md:hidden block overflow-hidden max-w-[100%] w-full'>
                  <MusicianVideoSliderMobile />
                </div>
              ) : (
                <div className='hidden md:block'>
                  <MusicianVideoSlider />
                </div>
              )}
            </section>
          </div>

          <section className='px-5 py-16 text-center text-white flex flex-col gap-[80px] items-center'>
            <p className='my-0 text-3xl text-tertiary'>
              <strong>Trusted by</strong>
            </p>
            <div className='flex flex-col items-center justify-center gap-24 lg:flex-row lg:gap-32'>
              <img
                src={universalMusicGroupImg}
                alt='Universal Music Group'
                className='w-full max-w-[400px]'
              />
              <img
                src={warnerMusicGroupImg}
                alt='Warner Music Group'
                className='w-full max-w-[400px]'
              />
            </div>

            <LinkScroll
              to='ac-form'
              className='w-full max-w-xs px-6 py-4 font-bold text-center text-gray-900 uppercase border rounded-full shadow-sm sm:w-auto radial-gradient sm:px-12 sm:py-6 focus:outline-none focus:ring-0 border-primary cursor-pointer'
              spy={true}
              smooth={true}
              offset={-112}
            >
              Claim Discount
            </LinkScroll>
          </section>

          <section className='w-full flex justify-center items-center pt-24'>
            <img
              src={offerBanner}
              alt='banner'
              className='max-w-6xl m-auto rounded-[20px] md:rounded-[40px] mb-[32px] md:mb-[53px] w-[95%]'
            />
          </section>

          <section
            className='max-w-4xl px-5 py-24 pt-0 mx-auto text-center text-white'
            id='ac-form'
          >
            <h2 className='text-[28px] font-bold md:text-[48px] text-[#ECFBEC] leading-[38px] md:leading-[48px]'>
              Special Musician Offer: 50% off of your first 3 months*
            </h2>
            <p className='md:max-w-[90%] mx-auto mt-8 text-base text-center text-[#ECFBEC]'>
              Fill out the form below to verify that you’re a musician, join our
              mailing list, and unlock 50% off of your first 3 months of
              Kaiber’s monthly Pro or Artist subscriptions.
            </p>
            <div className='mx-auto max-w-md my-[40px] md:my-[80px]'>
              <form onSubmit={handleSubmit}>
                <div className='mt-4'>
                  <input
                    type='text'
                    name='name'
                    placeholder='Name'
                    required
                    className='placeholder-gray-500 w-full bg-[#232323] text-white placeholder-white rounded-lg p-4 my-3 outline-none ring-transparent'
                  />
                </div>
                <div>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email'
                    required
                    className='placeholder-gray-500 w-full bg-[#232323] text-white placeholder-white rounded-lg p-4 my-3 outline-none ring-transparent'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    name='url'
                    placeholder='Spotify or SoundCloud URL'
                    required
                    className='placeholder-gray-500 w-full bg-[#232323] text-white placeholder-white rounded-lg p-4 my-3 outline-none ring-transparent'
                  />
                </div>
                {!isSubmitting && !showSuccessMessage && (
                  <button
                    type='submit'
                    className='mt-5 bg-kaiberGreen text-black hover:bg-primary/90 rounded-lg px-[30px] py-[10px] font-medium'
                  >
                    Submit
                  </button>
                )}

                {isSubmitting && (
                  <div className='mt-5 lds-ring'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}

                {showSuccessMessage && (
                  <div>
                    <div className='animated-checkmark-wrapper my-[30px]'>
                      <svg
                        className='checkmark'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 52 52'
                      >
                        <circle
                          className='checkmark__circle'
                          cx='26'
                          cy='26'
                          r='25'
                          fill='none'
                        />
                        <path
                          className='checkmark__check'
                          fill='none'
                          d='M14.1 27.2l7.1 7.2 16.7-16.8'
                        />
                      </svg>
                    </div>
                    <p className=''>
                      Thanks for your interest in Kaiber! You will receive your
                      discount code shortly.
                    </p>
                  </div>
                )}
              </form>
            </div>

            <p className='md:max-w-[90%] mx-auto text-base text-[#ECFBEC] italic'>
              *Offer valid only for first-time subscribers, limited to Kaiber’s
              monthly Pro or Artist subscriptions. Not applicable to annual
              Kaiber plans
            </p>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}
