import logoFooter from '../images/welcome/logo_footer.png'
import { Link } from 'react-router-dom'
import appleAppStoreIcon from '../images/apple-app-store.png'
import googlePlayStoreIcon from '../images/google-play-store.png'
import { showCookieBanner } from '../utils/cookieUtils'

const navigation = {
  Product: [
    { name: 'Overview', href: '/product' },
    { name: 'Pricing', href: '/pricing' },
    {
      name: 'FAQs',
      href: 'https://helpcenter.kaiber.ai/kaiber/en/collections/4044263-faq',
    },
    //    { name: 'Credits', href: '/credits' },
  ],
  About: [
    { name: 'Company', href: '/about' },
    { name: 'Blog', href: 'https://blog.kaiber.ai/' },
    { name: 'Kaiber Studio', href: '/studio' },
    { name: 'Community', href: '/community' },
    {
      name: 'Media Kit',
      href: 'https://storage.googleapis.com/kaiber_files/01.Media_Kit_Kaiber_2023.zip',
    },
    { name: 'Help Center', href: 'https://helpcenter.kaiber.ai' },
    //  { name: 'Terms of Service', href: '/terms' },
    //  { name: 'Privacy Policy', href: '/privacy' },
  ],
  'Join Us': [
    {
      name: 'Discord',
      href: 'https://discord.com/invite/kaiber',
    },
    { name: 'Twitter', href: 'https://twitter.com/KaiberAI' },
    { name: 'Instagram', href: 'https://www.instagram.com/kaiber.ai/' },
    { name: 'Tiktok', href: 'https://www.tiktok.com/@kaiberai' },
  ],
}

export default function Footer() {
  return (
    <footer
      className='bg-black border-t border-[#1E1F1E]'
      aria-labelledby='footer-heading'
    >
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='py-12 lg:py-16'>
        <div className='px-5 mx-auto max-w-7xl xl:grid xl:grid-cols-3 xl:gap-8'>
          <div className='space-y-8 xl:col-span-1'>
            <Link to='/' className='relative inline-block'>
              <img
                className='max-w-[200px]'
                src={logoFooter}
                alt='logo footer'
              />
            </Link>
            <div className='flex text-white'>
              <a
                href='https://apps.apple.com/app/kaiber/id6458980808'
                target='_blank'
                rel='noreferrer'
                aria-label='Download on the App App Store'
                className='mr-4'
              >
                <img
                  src={appleAppStoreIcon}
                  alt='Download on the Apple App Store'
                  className='w-[119px] max-h-[40px]'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=ai.kaiber.mobile'
                target='_blank'
                rel='noreferrer'
                aria-label='Download on the Google Play Store'
              >
                <img
                  src={googlePlayStoreIcon}
                  alt='Download on the Google Play Store'
                  className='w-[134px] max-h-[40px]'
                />
              </a>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-8 mt-12 xl:col-span-2 xl:mt-0'>
            <div className='md:grid md:grid-cols-3 md:gap-8'>
              {Object.keys(navigation).map((k) => (
                <ul key={k} className='mt-4 mb-8 space-y-2'>
                  <li className='mb-4'>
                    <span className='text-xl font-black text-white'>{k}</span>
                  </li>
                  {navigation[k].map((item) => (
                    <li key={item.name}>
                      {item.href.startsWith('https://') ? (
                        <a
                          href={item.href}
                          className='text-xl text-primary hover:text-white'
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          to={item.href}
                          className='text-xl text-primary hover:text-white'
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              ))}
              {/* <ul className='mt-4 space-y-4'>
                {navigation.solutions.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-base text-tertiary/60 hover:text-primary'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul> */}
              {/* <ul className='mt-4 space-y-4'>
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-base text-tertiary/60 hover:text-primary'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              <ul className='mt-4 space-y-4'>
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-base text-tertiary/60 hover:text-primary'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul> */}
            </div>
          </div>
        </div>
        <div className='mt-12 pt-8 gap-5 border-t border-[#484452]'>
          <div className='flex flex-col mx-auto sm:flex-row max-w-7xl'>
            <p className='px-5 text-base text-center sm:text-xl text-white/50 xl:text-left'>
              &copy; 2024 Kaiber. All rights reserved.
            </p>
            <Link to='/terms'>
              <p className='px-5 text-base text-center sm:text-xl text-white/50 xl:text-left hover:underline'>
                Terms of Service
              </p>
            </Link>
            <Link to='/privacy'>
              <p className='px-5 text-base text-center sm:text-xl text-white/50 xl:text-left hover:underline'>
                Privacy Policy
              </p>
            </Link>
            <button
              className='px-5 text-base text-center sm:text-xl text-white/50 xl:text-left hover:underline'
              onClick={showCookieBanner}
            >
              Cookie Settings
            </button>
          </div>
        </div>
      </div>
    </footer>
  )
}
