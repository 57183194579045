import ImageDnD from '../components/ImageDnD'
import AudioDnD from '../components/AudioDnD'
import VideoDnD from '../components/VideoDnD'
import { IoMdArrowRoundForward } from 'react-icons/io'
import { useUserAccountContext } from '../context/userContext'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { isMobile } from '../utils'
import classNames from 'classnames'

export default function InitialFilesUpload({
  handleAudioFileChange,
  handleFileInputChange,
  handleVideoFileChange,
  handleNextStep,
  setAlertMessage,
  setShowAlert,
  audio,
  audioUrl,
  image,
  video,
  step,
  audioFileName,
  setAudioFileName,
  isAudioProcessing,
  setIsAudioProcessing,
  longVideoMaintenanceMode,
  disabled,
  videoS3Key,
  maxAudioUploadLength,
  maxVideoUploadLength,
  didReuseSettings,
  allowAudioUpload,
  allowImageUpload,
  allowVideoUpload,
  stepOneSection,
  selectedVideoType,
  backToVideoTypeSection,
  overlayImage,
}) {
  const { currentUser } = useUserAccountContext()

  const isSubscribed = currentUser?.subscriptionType === 'Standard'

  const nextStepTitle =
    (step === 1 && audio) || image || video
      ? 'Edit your prompt'
      : 'Just start writing prompt'

  let hideVideo = false
  if (isMobile() && image) {
    hideVideo = true
  } else {
    hideVideo = false
  }
  let hideImage = false
  if (isMobile() && video) {
    hideImage = true
  } else {
    hideImage = false
  }

  return (
    <div className='mb-[20px] text-white sm:flex sm:flex-col w-full max-w-sm sm:max-w-5xl sm:text-white sm:center fadeIn'>
      <div
        className={
          selectedVideoType && stepOneSection === 'uploadMedia' ? '' : 'hidden'
        }
      >
        {(allowImageUpload || allowVideoUpload || allowAudioUpload) && (
          <div className='flex flex-col gap-0.5'>
            <h1 className='text-2xl mb-1 mt-4 font-medium text-nutral-200 sm:text-4xl flex justify-between'>
              Upload Media
              <div onClick={backToVideoTypeSection}>
                <IoMdArrowRoundBack className='inline w-10 h-10 cursor-pointer text-quaternary hover:scale-90 align-top' />
              </div>
            </h1>
            <p className='font-medium text-16 text-neutral-400 mb-3'>
              This step is optional for Motion and Flipbook
            </p>
          </div>
        )}
        {isSubscribed && longVideoMaintenanceMode && (
          <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5 mb-4'>
            Long video generations are temporarily disabled as we scale up our
            servers. Thank you for your patience.
          </div>
        )}
        {allowImageUpload || allowVideoUpload || allowAudioUpload ? (
          <div className='grid gap-4 sm:grid-cols-3 sm:mb-6 mt-6 mb-[80px]'>
            {!hideImage && (
              <div
                className={
                  'flex flex-col flex-1 w-full ' +
                  (!!video || disabled || selectedVideoType === 'transform'
                    ? 'hidden'
                    : '')
                }
              >
                <ImageDnD
                  onChange={handleFileInputChange}
                  title='Image'
                  description='Max size 20MB.'
                  color='quaternary'
                  image={image}
                  overlayImage={overlayImage}
                  disabled={
                    !!video || disabled || selectedVideoType === 'transform'
                  }
                />
              </div>
            )}

            {
              <div
                className={classNames('flex flex-col flex-1 w-full', {
                  hidden: !!video || disabled || !allowAudioUpload,
                })}
              >
                <AudioDnD
                  onChange={handleAudioFileChange}
                  setAlertMessage={setAlertMessage}
                  setShowAlert={setShowAlert}
                  title='Audio'
                  description={`Max length ${parseInt(
                    maxVideoUploadLength / 60,
                  )} ${
                    parseInt(maxVideoUploadLength / 60) !== 1 ? 'mins.' : 'min.'
                  }`}
                  color='quaternary'
                  audio={audio}
                  audioUrl={audioUrl}
                  fileName={audioFileName}
                  setFileName={setAudioFileName}
                  isProcessing={isAudioProcessing}
                  setIsProcessing={setIsAudioProcessing}
                  longVideoMaintenanceMode={longVideoMaintenanceMode}
                  disabled={!!video || disabled || !allowAudioUpload}
                  maxDuration={maxAudioUploadLength}
                  didReuseSettings={didReuseSettings}
                />
              </div>
            }

            {!hideVideo && (
              <div
                className={
                  'flex flex-col flex-1 w-full ' +
                  (!!image ||
                  !!audio ||
                  isAudioProcessing ||
                  disabled ||
                  (selectedVideoType !== 'transform' &&
                    selectedVideoType != null)
                    ? 'hidden'
                    : '')
                }
              >
                <VideoDnD
                  onChange={handleVideoFileChange}
                  setAlertMessage={setAlertMessage}
                  color='primary-light'
                  setShowAlert={setShowAlert}
                  video={video}
                  disabled={
                    !!image ||
                    !!audio ||
                    isAudioProcessing ||
                    disabled ||
                    (selectedVideoType !== 'transform' &&
                      selectedVideoType != null)
                  }
                  videoS3Key={videoS3Key}
                  title='Video'
                  description={`Max length ${parseInt(
                    maxVideoUploadLength / 60,
                  )} ${
                    parseInt(maxVideoUploadLength / 60) !== 1 ? 'mins.' : 'min.'
                  }`}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>

      {stepOneSection === 'uploadMedia' && selectedVideoType && (
        <div className='max-[500px]:bg-black max-[500px]:fixed max-[500px]:bottom-0 max-[500px]:p-4 max-[500px]:inset-x-0 max-[500px]:text-center mt-10'>
          <button
            className={`${
              isMobile()
                ? 'w-full hover:bg-[#30303a] rounded-2xl p-5 items-center justify-center text-black duration-300 shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40'
                : 'inline-flex w-full hover:bg-[#30303a] rounded-2xl p-5 items-center justify-center text-black duration-300 shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40'
            } ${
              !video && selectedVideoType === 'transform'
                ? 'opacity-50'
                : 'hover:bg-[#30303a]'
            }`}
            onClick={handleNextStep}
            disabled={!video && selectedVideoType === 'transform'}
          >
            <IoMdArrowRoundForward className='inline w-10 h-10' />
            <span className='my-5 font-semibold text-l sm:text-2xl leading'>
              {nextStepTitle}
            </span>
          </button>
        </div>
      )}
    </div>
  )
}
