import SectionTitle from '../welcome/SectionTitle'
import OdkComponent from '../welcome/OdkComponent'
import { Link } from 'react-router-dom'

function VisitStudio() {
  return (
    <div className='flex flex-col flex-1 w-full items-center'>
      <SectionTitle
        title='Kaiber Studio'
        description='Where artistry meets technological innovation'
      />
      <OdkComponent />
      <Link className='mb-20' to='/studio'>
        <span className='font-semibold lg:text-4xl sm:text-3xl text-2xl underline text-primary hover:text-primary/90 active:text-primary/40 duration-300 '>
          Visit Kaiber Studios
        </span>
      </Link>
    </div>
  )
}

export default VisitStudio
