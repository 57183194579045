/* eslint-disable */
// TODO - fix eslint errors related to loading patterns here

import { useCallback, useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CTA from '../components/home/CTA'
import http from '../services/HttpService'
import { VideoGallery } from '../components/VideoGallery'
import { SearchBar } from '../components/SearchBar'
import { useVideoEventsContext } from '../context/videoEventsContext'
import { isMobile, cn } from '../utils'
import { useUserAccountContext } from '../context/userContext'

export const Gallery = () => {
  const { featureToggles } = useUserAccountContext()

  // stores list of videos
  const [videosList, setVideosList] = useState([])
  const videosListRef = useRef(videosList)
  videosListRef.current = videosList
  let currentIndex = 0

  // stores videos for gallery state
  const [videos, setVideos] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [offsetDescription, setOffsetDescription] = useState(null)
  const [totalItems, setTotalItems] = useState(null)
  const [hasPagination, setHasPagination] = useState(false)

  const searchInputRef = useRef(null)
  const { trackVideos } = useVideoEventsContext()
  const isFeedbackBannerShown = featureToggles['enable-feedback-banner']

  const getVideos = useCallback(
    async (page = 1) => {
      setIsLoading(true)

      if (page === 1 && videos.length > 0) {
        setVideos([])
        setCurrentPage(1)
      }

      try {
        let perPage = 20
        if (isMobile()) {
          perPage = 12
        }

        // concurrent_loading=true to skip the signed S3 URLs for this API call, and instead
        // get them as separate API calls for each gallery item.
        // Added this to ensure /get_gallery_videos is backwards compatible with the mobile app.
        const { data } = await http.get(
          `/api/get_gallery_videos?concurrent_loading=true&page=${page}&per_page=${perPage}`,
          {
            params: {
              q: searchInputRef?.current?.value ?? '',
            },
          },
        )

        let originalVideosList = data.items
        currentIndex = 0
        // for (let i = 0; i < originalVideosList.length; i++) {
        //   originalVideosList[i].showLoadingState = true
        // }

        setVideosList(originalVideosList)
        setVideos(originalVideosList)
        setTotalPages(data.totalPages)

        // for pagination
        setTotalItems(data.totalItems)
        setOffsetDescription(data.offsetDescription)
        setHasPagination(data.hasPagination)

        trackVideos(data.items)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    },
    [setVideos, setIsLoading, searchInputRef, videos],
  )

  const handlePageClick = (event) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    const page = event.selected + 1
    setCurrentPage(page)

    if (videos.length > 0) {
      // loop through videos and remove them from the DOM
      for (let i = 0; i < videos.length; i++) {
        if (document.getElementById(videos[i]._id) != null) {
          const videoElement = document.getElementById(videos[i]._id)
          const sourceElement = document
            .getElementById(videos[i]._id)
            .getElementsByTagName('source')[0]
          if (sourceElement) {
            videoElement.pause()
            sourceElement.src = ''
            videoElement.load()
            sourceElement.remove()
            videoElement.remove()
          }
        }
      }
    }

    // resetting the state to clear videos breaks scrollTo() (for some unknown reason) on Firefox and iOS Safari.
    // Use a timeout to delay the state change.
    setTimeout(() => {
      currentIndex = 0
      setVideos([])
      setVideosList([])

      getVideos(page, searchText)
    }, 250)
  }

  const handleSearchVideos = (page, searchText) => {
    currentIndex = 0
    setVideos([])
    setVideosList([])

    getVideos(page, searchText)
  }

  useEffect(() => {
    getVideos(currentPage)
    // note: do not include getVideos in dependency array otherwise infinite calls
  }, [])

  return (
    <div className='bg-black'>
      <Navbar
        searchRef={searchInputRef}
        getVideos={handleSearchVideos}
        isLoading={isLoading}
        setSearchText={setSearchText}
        searchText={searchText}
      />

      <div
        className={cn('px-5 section pt-[74px]', {
          'pt-[124px]': isFeedbackBannerShown,
        })}
      >
        <div className='py-16 mx-auto space-y-8 max-w-7xl sm:py-24'>
          <div className='space-y-6 text-center'>
            <p className='font-bold text-primary'>Gallery</p>
            <p className='mt-1 text-7xl font-medium tracking-tight sm:text-5xl lg:text-[120px] lg:leading-[128px] text-tertiary'>
              Made with Kaiber
            </p>
            <p className='max-w-xl mx-auto mt-5 text-xl lg:text-2xl text-tertiary/60'>
              Transform your ideas into masterpieces with Kaiber's state of the
              art AI video generator.
            </p>
          </div>

          {(videos.length !== 0 || searchText !== null) && (
            <SearchBar
              ref={searchInputRef}
              getVideos={handleSearchVideos}
              isLoading={isLoading}
              setSearchText={setSearchText}
              searchText={searchText}
              className='w-[89vw] sm:w-[30rem]'
            />
          )}
          <VideoGallery
            videos={videos}
            getVideos={getVideos}
            setSearchText={setSearchText}
            searchInputRef={searchInputRef}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            isLoading={isLoading}
          />

          {/* PAGINATION */}
          <div>
            {offsetDescription && hasPagination && (
              <div className='mb-5 text-center'>
                <span className='text-gray-500'>{offsetDescription}</span>
              </div>
            )}

            {!(videos.length === 0 && searchText == null) &&
              hasPagination &&
              totalItems > 0 && (
                <ReactPaginate
                  forcePage={currentPage - 1}
                  breakLabel='...'
                  previousLabel={<ChevronDoubleLeftIcon className='w-6 h-6' />}
                  nextLabel={<ChevronDoubleRightIcon className='w-6 h-6' />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalPages}
                  renderOnZeroPageCount={null}
                  marginPagesDisplayed={2}
                  containerClassName='flex flex-wrap justify-center gap-2'
                  breakClassName='page-item'
                  pageClassName='page-item'
                  previousClassName='page-item'
                  nextClassName='page-item'
                  activeClassName='active'
                  breakLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                  pageLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                  previousLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                  nextLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                  activeLinkClassName='radial-gradient shadow-sm text-gray-900 font-bold'
                  disabledLinkClassName='border-gray-900 text-gray-700'
                />
              )}
          </div>
        </div>
      </div>
      <CTA />
      <Footer />

      {/* tailwind issue not loading unused pseudo classes
          unless previously used, so adding this hidden div */}
      <div className='hidden underline bg-black' />
    </div>
  )
}
