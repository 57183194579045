import { Box } from '@xyflow/system'
import { Node } from '@xyflow/react'

import { NodeType } from '../types/nodeTypes'

// we have to make sure that parent nodes are rendered before their children
const nodeHierarchy = [
  NodeType.AssembleNode,
  NodeType.AssembleSlotNode,
  NodeType.FlowNode,
  NodeType.MediaNode,
]

export const sortNodes = (a: Node, b: Node): number => {
  const aIndex = nodeHierarchy.indexOf(a.type as NodeType)
  const bIndex = nodeHierarchy.indexOf(b.type as NodeType)

  if (aIndex === -1 && bIndex === -1) {
    return 0
  } else if (aIndex === -1) {
    return 1
  } else if (bIndex === -1) {
    return -1
  } else {
    return aIndex - bIndex
  }
}

export const getId = (prefix = 'node') => `${prefix}_${Math.random() * 10000}`

export const getNodePositionInsideParent = (
  node: Partial<Node>,
  groupNode: Node,
) => {
  const position = node.position ?? { x: 0, y: 0 }
  const nodeWidth = node.width ?? 0
  const nodeHeight = node.height ?? 0
  const groupWidth = groupNode.width ?? 0
  const groupHeight = groupNode.height ?? 0

  if (position.x < groupNode.position.x) {
    position.x = 0
  } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
    position.x = groupWidth - nodeWidth
  } else {
    position.x = position.x - groupNode.position.x
  }

  if (position.y < groupNode.position.y) {
    position.y = 0
  } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
    position.y = groupHeight - nodeHeight
  } else {
    position.y = position.y - groupNode.position.y
  }

  return position
}

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2),
})

export const getDistance = (nodeA: Node, nodeB: Node) => {
  const aCenterX = (nodeA.position?.x ?? 0) + (nodeA.measured?.width ?? 0) / 2
  const aCenterY = (nodeA.position?.y ?? 0) + (nodeA.measured?.height ?? 0) / 2
  const bCenterX = (nodeB.position?.x ?? 0) + (nodeB.measured?.width ?? 0) / 2
  const bCenterY = (nodeB.position?.y ?? 0) + (nodeB.measured?.height ?? 0) / 2

  const dx = aCenterX - bCenterX
  const dy = aCenterY - bCenterY

  return Math.sqrt(dx * dx + dy * dy)
}
