import { Helmet } from 'react-helmet'

function MarkerioHead() {
  const flag = process.env.REACT_APP_MARKERIO_FLAG

  if (flag !== true) {
    return null
  }

  return (
    <Helmet>
      <script>
        {`window.markerConfig = {
          project: '64e67cd8c2c7efd5b024c6e1', 
          source: 'snippet'
        }`}
      </script>
      <script>
        {`!(function(e, r, a) {
          if (!e.__Marker) {
            e.__Marker = {}
            let t = [],
                n = { __cs: t }
            ['show', 'hide', 'isVisible', 'capture', 'cancelCapture', 'unload', 'reload', 'isExtensionInstalled', 'setReporter', 'setCustomData', 'on', 'off'].forEach(function(e) {
              n[e] = function() {
                let r = Array.prototype.slice.call(arguments)
                r.unshift(e)
                t.push(r)
              }
            })
            e.Marker = n
            let s = r.createElement('script')
            s.async = 1
            s.src = 'https://edge.marker.io/latest/shim.js'
            let i = r.getElementsByTagName('script')[0]
            i.parentNode.insertBefore(s, i)
          }
        })(window, document)`}
      </script>
    </Helmet>
  )
}

export default MarkerioHead
