import Lottie from 'lottie-react'

import LoadingWhite from '../../images/lottie/loaderWhite.json'
import { cn } from '../../utils'

interface SpinnerProps {
  containerClassName?: string
  iconClassName?: string
}

export const Spinner = ({
  containerClassName,
  iconClassName,
}: SpinnerProps) => (
  <div
    className={cn(
      'w-full h-20 bg-darkGray rounded-lg flex items-center justify-center',
      containerClassName,
    )}
  >
    <Lottie
      animationData={LoadingWhite}
      loop={true}
      className={cn('w-8 h-8', iconClassName)}
    />
  </div>
)
