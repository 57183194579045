import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCanvasContext, useThemeContext } from '../../../context'
import { useAnalytics, useInitialLoad } from '../../../hooks'
import { PlusIcon } from '../../../images/icons/PlusIcon'
import { cn } from '../../../utils'
import { AnalyticsEvent, CanvasLoadedAction } from '@/types'

interface SidebarProps {
  onSaveBeforeCanvasSwitch: () => void
}

interface SidebarMenuItemProps {
  active?: boolean
  justify?: 'justify-start' | 'justify-center' | 'justify-end'
  icon: ReactNode
  description: string
  handleClick: () => void
}

const SidebarMenuItem = ({
  active = false,
  justify = 'justify-start',
  icon,
  description,
  handleClick,
}: SidebarMenuItemProps) => {
  const { colors } = useThemeContext()
  return (
    <div
      className={cn(
        `relative flex group-hover/canvas-navigation:gap-1 items-center font-bold rounded-full p-2 z-50 cursor-pointer text-k2-gray-300 hover:${colors.text.inverse} hover:bg-k2-gray-600/80`,
        justify,
        {
          [`${colors.background.brand} ${colors.text.inverse} hover:${colors.background.brand} `]:
            active,
        },
      )}
      onClick={handleClick}
    >
      <span className='w-6 h-6 flex justify-center items-center'>{icon}</span>
      <span className='w-0 group-hover/canvas-navigation:w-48 ease-out duration-300 text-nowrap text-ellipsis overflow-hidden'>
        {description}
      </span>
    </div>
  )
}

export const Sidebar = ({ onSaveBeforeCanvasSwitch }: SidebarProps) => {
  const { colors } = useThemeContext()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const { trackEvent } = useAnalytics()
  const { canvasId } = useParams()
  const { canvases, getCanvases, createCanvas } = useCanvasContext()

  const onCanvasChange = useCallback(
    async (canvasId: string) => {
      onSaveBeforeCanvasSwitch()
      trackEvent(AnalyticsEvent.CanvasLoaded, {
        action: CanvasLoadedAction.SwitchedCanvas,
        canvasId, // necessary as the canvasId in the params is not updated yet
      })
      navigate(`/flow-canvas/${canvasId}`)
    },
    [navigate, onSaveBeforeCanvasSwitch, trackEvent],
  )

  const handleCreateCanvas = useCallback(async () => {
    const canvas = await createCanvas()
    onCanvasChange(canvas.id)
  }, [createCanvas, onCanvasChange])

  useEffect(() => {
    const canvasArr = Object.values(canvases)
    const canvasCnt = canvasArr.length
    const canvasIdx = canvasArr.findIndex((canvas) => canvas.id === canvasId)
    if (containerRef.current) {
      const height = containerRef.current.scrollHeight
      containerRef.current.scrollTo({
        top: (height / canvasCnt) * canvasIdx,
        behavior: 'smooth',
      })
    }
  }, [canvases, canvasId])

  useInitialLoad(async () => {
    await getCanvases()
  })

  return (
    <div
      className={cn(
        'fixed top-[130px] left-3 z-20 group/canvas-navigation rounded-[32px] hover:rounded-2xl flex flex-col gap-2.5 px-2.5 py-4 ease-out duration-300 border-k2-gray-200 border',
        colors.background.neutral,
      )}
    >
      <div
        ref={containerRef}
        className='flex flex-col gap-2.5 max-h-60 overflow-y-hidden group-hover/canvas-navigation:overflow-y-auto'
      >
        {Object.values(canvases).map((canvas, idx) => (
          <SidebarMenuItem
            key={canvas.id}
            active={canvasId === canvas.id}
            icon={<>{idx + 1}</>}
            description={canvas.title}
            handleClick={() => onCanvasChange(canvas.id)}
          />
        ))}
      </div>
      <SidebarMenuItem
        justify='justify-center'
        icon={<PlusIcon />}
        description='Create new canvas'
        handleClick={handleCreateCanvas}
      />
    </div>
  )
}
