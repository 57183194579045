import { formatDuration, getAspectStyle } from '../utils'
import { VideoLengthDisplay } from './VideoLengthDisplay'
import { AudioPlayback } from './AudioPlayback'

export const ConfigsSummary = ({
  activeScene,
  length,
  timestamp,
  aspectRatio,
  cameraMovementNames,
  className = '',
  audioUrl,
  showAspectRatio = false,
}) => {
  return (
    <div>
      <div className={`flex items-center gap-5 select-none ${className}`}>
        {/* length */}
        <VideoLengthDisplay length={length} />

        {/* timestamp */}
        {activeScene > 1 && (
          <div className='w-40 py-2 text-center text-black rounded-md bg-primary min-w-[11.5rem]'>
            Timestamp <span className='ml-3'>{formatDuration(timestamp)}</span>
          </div>
        )}

        {/* aspect ratio */}
        {activeScene === 1 && showAspectRatio && (
          <button>
            <label
              className={`flex input relative items-center rounded-md justify-center bg-primary text-dark [&>div]:border-dark ${getAspectStyle(
                aspectRatio,
              )}`}
            >
              <div className='absolute top-0 left-0 m-1 border-t border-l w-2 h-2 $' />
              <div className='absolute bottom-0 right-0 w-2 h-2 m-1 border-b border-r' />
              {aspectRatio}
            </label>
          </button>
        )}

        {/* camera */}
        {cameraMovementNames && (
          <button
            data-label={`Camera - ${cameraMovementNames?.join(', ')}`}
            className='relative px-4 py-2 text-black capitalize transition-all border rounded-full cursor-default buttonPrompt bg-primary border-primary'
          >
            <span className='absolute mt-auto mb-auto -translate-y-1/2 top-1/2 left-4 right-4'>
              Camera • {cameraMovementNames?.join(', ')}
            </span>
          </button>
        )}
      </div>
      {!!audioUrl && (
        <div className='bg-[#212129] p-5 rounded-xl mt-8'>
          <h3 className='pt-2 pb-4 text-2xl text-kaiberGreen'>Song Playback</h3>
          <AudioPlayback audioUrl={audioUrl} />
        </div>
      )}
    </div>
  )
}
