import SectionTitle from './SectionTitle'

function AnimationSection() {
  return (
    <div className='flex w-full justify-center overflow-hidden mb-10 md:mb-[250px]'>
      <div className='flex flex-col flex-1 max-w-[1440px] w-full items-center md:px-[3.5%] px-4'>
        <SectionTitle title='Animation' />

        <div className='relative pt-[35%] w-full'>
          <iframe
            className='select-none pointer-events-none aspect-[9/16] w-full h-full absolute top-0 left-0'
            loading='lazy'
            title='Gumlet video player'
            src='https://play.gumlet.io/embed/64d24055d81e20d125274ab5'
            allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
            allowFullScreen
          ></iframe>
        </div>

        <div className='flex justify-between max-w-[96%]'>
          <div className='w-[49%]'>
            <h2 className='text-lg md:text-[32px] font-semibold text-white'>
              Flipbook
            </h2>
            <p className='text-sm md:text-2xl text-lightGray'>
              A frame-by-frame animation style, where one layer evolves based on
              another, allowing you to create with timeless creativity.
            </p>
          </div>

          <div className='w-[49%]'>
            <h2 className='text-lg md:text-[32px] font-semibold text-white'>
              Motion
            </h2>
            <p className='text-sm md:text-2xl text-lightGray'>
              A smooth, consistent animation style. Just pop in your prompt, and
              watch it transform into a visual experience with enthralling
              fluidity and motion.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnimationSection
