import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useCanvasContext } from '../../../context'
import { useThemeContext } from '../../../context'
import { CheckIcon } from '../../../images/icons/CheckIcon'
import { PencilIcon } from '../../../images/icons/PencilIcon'
import { XMarkIcon } from '../../../images/icons/XMarkIcon'
import { cn } from '../../../utils'

const MAX_TITLE_INPUT_SIZE = 100

export const CanvasTitle = () => {
  const { colors } = useThemeContext()

  const [title, setTitle] = useState('')
  const { canvasId } = useParams()
  const { canvases, updateTitle } = useCanvasContext()
  const [isTitleEditing, setIsTitleEditing] = useState(false)
  const [isTitleUpdating, setIsTitleUpdating] = useState(false)

  const titleInputRef = useRef<HTMLInputElement | null>(null)
  const handleTitleSave = async () => {
    if (title.length === 0) {
      toast.error('Canvas title can not be empty', {
        position: 'bottom-center',
      })
      return
    }

    if (title.length > MAX_TITLE_INPUT_SIZE) {
      toast.error(`Canvas title can not be longer than ${MAX_TITLE_INPUT_SIZE}`)
      return
    }

    setIsTitleUpdating(true)
    try {
      await updateTitle(canvasId, title)
      toast.success('Successfully updated title', {
        position: 'bottom-center',
      })
    } catch (error) {
      setTitle(canvases[canvasId]?.title || '')
      console.error('Error updating title', error)
    } finally {
      setIsTitleUpdating(false)
      setIsTitleEditing(false)
    }
  }

  const handleTitleReset = () => {
    setTitle(canvases[canvasId]?.title || '')
    setIsTitleEditing(false)
  }

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const titleInputSize = useMemo(() => {
    return Math.min(Math.max(1, title?.length || 0), 30)
  }, [title])

  const handleFocusTitleInput = () => {
    if (!isTitleEditing) setIsTitleEditing(true)
  }

  useEffect(() => {
    if (isTitleEditing && titleInputRef.current) {
      titleInputRef.current.focus()
    }
  }, [isTitleEditing])

  useEffect(() => {
    setTitle(canvases[canvasId]?.title || '')
    setIsTitleEditing(false)
    setIsTitleUpdating(false)
  }, [canvasId, canvases])

  return (
    <div
      className={`flex items-center text-xl font-medium select-none ${colors.text.default} group/title`}
    >
      <input
        value={title}
        ref={titleInputRef}
        size={titleInputSize}
        disabled={isTitleUpdating}
        onChange={handleTitleChange}
        onClick={handleFocusTitleInput}
        className={cn(
          'bg-transparent outline-none border-transparent transition-colors overflow-hidden text-ellipsis text-base font-normal',
          {
            'text-k2-gray-300': isTitleUpdating,
          },
        )}
      />

      {isTitleEditing ? (
        <>
          <button
            className='ml-2 hover:scale-110 text-k2-green-300 hover:text-k2-green-400'
            onClick={handleTitleSave}
          >
            <CheckIcon />
          </button>
          <button
            className='ml-2 hover:scale-110 text-k2-red-300 hover:text-k2-red-400'
            onClick={handleTitleReset}
          >
            <XMarkIcon />
          </button>
        </>
      ) : (
        <button
          className='ml-2 hover:scale-110 text-k2-toolbar-primary hidden group-hover/title:block'
          onClick={handleFocusTitleInput}
        >
          <PencilIcon size='16px' />
        </button>
      )}
    </div>
  )
}
