import { useCallback, useState, useEffect, useRef } from 'react'
import * as Slider from '@radix-ui/react-slider'
import { IoMdArrowRoundUp } from 'react-icons/io'
import { formatDuration } from '../utils'
import { MIN_SCENE_LENGTH } from '../utils/constants'
import '../styles/timeStampSlider.css'

export const AudioTimestampSlider = ({
  onSlide,
  onValuesChange,
  min = 1,
  max = 10,
  timestamp,
  activeScene,
  numberOfScenes,
  scenes,
  length,
}) => {
  const [sliderValues, setSliderValues] = useState([])
  const [timestampValue, setTimestampValue] = useState(0)
  const [, setAlreadyLoaded] = useState(false)
  const [selectedThumb, setSelectedThumb] = useState(0)
  const [minSteps, setMinSteps] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [isNewScene, setIsNewScene] = useState(true)
  const elementsRef = useRef([])
  const mounted = useRef(false)

  const calculateMinSteps = useCallback(() => {
    let steps = MIN_SCENE_LENGTH
    if (length !== null) {
      steps = Math.ceil(length * 0.1)
    }

    if (steps < MIN_SCENE_LENGTH) {
      steps = MIN_SCENE_LENGTH
    }

    setMinSteps(steps)

    return steps
  }, [length, setMinSteps])

  useEffect(() => {
    mounted.current = true
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
      mounted.current = false
    }
  }, [])

  function handleWindowSizeChange() {
    if (window.innerWidth <= 640) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    const minStepsValue = calculateMinSteps()
    if (scenes && mounted.current) {
      let lastTimeStamp = 2
      let timeStamps = []
      for (const [i, scene] of Object.entries(scenes)) {
        if (parseInt(i) !== 0) {
          if (scene.timestamp !== 0) {
            if (i > 1) {
              if (
                scene.timestamp - scenes[i - 1].timestamp < minStepsValue &&
                scenes[i - 1].timestamp + minStepsValue < length
              ) {
                scene.timestamp = lastTimeStamp + minStepsValue
              }
            }
            timeStamps.push(scene.timestamp)
            lastTimeStamp = scene.timestamp
          } else {
            lastTimeStamp = lastTimeStamp + minStepsValue
            timeStamps.push(lastTimeStamp)
          }
        }
      }

      setSliderValues(timeStamps)

      if (timestampValue === 0) {
        setTimestampValue(timestamp)
        onValuesChange(timeStamps)
      }

      if (timestampValue !== timeStamps[activeScene - 2] && isNewScene) {
        setTimestampValue(timeStamps[activeScene - 2])
        setIsNewScene(false)
      }

      if (selectedThumb === 0) {
        if (elementsRef.current[activeScene - 1] !== null) {
          elementsRef.current[activeScene - 1]?.focus()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenes])

  useEffect(() => {
    if (mounted.current) {
      //this set alreadyLoaded to true if first load and ref is not null, this updates trigger the below useEffect
      if (selectedThumb === 0) {
        if (elementsRef.current[activeScene - 1] !== null) {
          setAlreadyLoaded(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestampValue])

  useEffect(() => {
    if (mounted.current) {
      //set the focus to the thumb that corresponds to sceneActive on first load
      if (selectedThumb === 0) {
        if (elementsRef.current[activeScene - 1] !== null) {
          elementsRef.current[activeScene - 1]?.focus()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsRef.current[activeScene - 1]])

  const handleSlides = (valueArray) => {
    updateTimestamp(valueArray)
    setSliderValues(valueArray)
    onValuesChange(valueArray)
  }

  const updateTimestamp = (valueArray) => {
    for (let i = 0; i < valueArray.length; i++) {
      let currentValue = sliderValues[i]
      let newValue = valueArray[i]
      if (currentValue !== newValue) {
        setTimestampValue(newValue)
        break
      }
    }
  }

  return (
    <div>
      <h3 className='text-[#8BC6AD] lg:text-2xl xl:text-3xl'>
        Adjust Location within video.
      </h3>
      <div className='w-full max-w-sm space-y-4'>
        {/* <div className='flex content-between'> */}
        <div className='w-40 py-1 mt-4 mb-8 text-base text-center text-black rounded-md bg-primary'>
          Timestamp{' '}
          <span className='ml-3'>{formatDuration(timestampValue)}</span>
        </div>
        {/* <span className='text-xs'>
            {timestamp === min && 'Min timestamp reached.'}
            {timestamp === max && 'Max timestamp reached.'}
          </span> */}
        {/* </div> */}

        {scenes != null && sliderValues.length > 0 && minSteps != null ? (
          <div className='flex justify-center'>
            <Slider.Root
              className='relative flex items-center w-[calc(100%-4rem)] h-5 select-none touch-none'
              minStepsBetweenThumbs={minSteps}
              value={sliderValues}
              max={length != null ? length - 2 : max}
              min={2}
              step={1}
              onValueChange={handleSlides}
              aria-label='Timestamp'
            >
              <Slider.Track className='bg-primary relative grow h-[2px]'>
                {/* left circle */}
                <div className='absolute top-[-5px] -left-8 flex flex-col gap-4 items-center w-6'>
                  <div className='w-3 h-3 rounded-full bg-primary' />
                  <span className='text-xs text-primary'>S1</span>
                </div>

                <Slider.Range className='absolute top-0 bottom-0 m-auto bg-primary h-[2px]' />

                {/* right square */}
                <div className='absolute top-[-5px] -right-8 flex flex-col gap-4 items-center w-6'>
                  <div className='w-3 h-3 bg-primary' />
                  <span className='text-xs text-primary'>
                    {activeScene < numberOfScenes
                      ? `S${activeScene + 1}`
                      : 'END'}
                  </span>
                </div>
              </Slider.Track>
              {Object.keys(scenes).map((keyName, index) =>
                parseInt(keyName) !== 0 ? (
                  <Slider.Thumb
                    tabIndex='0'
                    onClick={() => {
                      setSelectedThumb(parseInt(keyName))
                      elementsRef.current[keyName].focus()
                      setTimestampValue(scenes[keyName].timestamp)
                    }}
                    ref={(ref) => {
                      elementsRef.current[keyName] = ref
                    }}
                    key={`thumb${keyName}`}
                    className={`isActive flex cursor-pointer justify-center group w-2 h-2 ${
                      isMobile ? 'focus:w-3 focus:h-3' : 'focus:w-4 focus:h-4'
                    } bg-primary ${
                      isMobile ? 'shadow-[0_0_0_5px]' : 'shadow-[0_0_0_10px]'
                    } shadow-[#0b0b0b] rounded-[10px] focus:outline-none focus:shadow-none`}
                  >
                    <span className='scale-y-0 bg-primary text-primary group-focus:scale-y-100 mt-[1.6rem]'>
                      <IoMdArrowRoundUp size={24} />
                    </span>
                    <span className='scale-y-100 bg-primary text-primary text-xs group-focus:scale-y-0 scene-label'>
                      {`S${parseInt(keyName) + 1}`}
                    </span>
                  </Slider.Thumb>
                ) : null,
              )}
            </Slider.Root>
          </div>
        ) : null}
      </div>
    </div>
  )
}
