import React from 'react'

interface VideoIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const VideoIcon = React.forwardRef<SVGSVGElement, VideoIconProps>(
  ({ size = '20', ...props }, ref) => (
    <>
      <svg
        ref={ref}
        stroke='currentColor'
        fill='none'
        height={size}
        width={size}
        viewBox='0 0 16 16'
        {...props}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.125 8H4.45833M1.125 8V4.5625M1.125 8V11.4375M4.45833 8H11.5417M4.45833 8V11.4375M4.45833 8V4.5625M1.125 4.5625V2.79167C1.125 1.87119 1.87119 1.125 2.79167 1.125H4.45833M1.125 4.5625H4.45833M1.125 11.4375V13.2083C1.125 14.1288 1.87119 14.875 2.79167 14.875H4.45833M1.125 11.4375H4.45833M11.5417 8H14.875M11.5417 8V11.8194M11.5417 8V4.5625M4.45833 11.4375V14.875M4.45833 4.5625V1.125M14.875 8V4.5625M14.875 8V11.8194M11.5417 11.8194V14.875M11.5417 11.8194H14.875M11.5417 4.5625V1.125M11.5417 4.5625H14.875M14.875 4.5625V2.79167C14.875 1.87119 14.1288 1.125 13.2083 1.125H11.5417M14.875 11.8194V13.2083C14.875 14.1288 14.1288 14.875 13.2083 14.875H11.5417M11.5417 14.875H4.45833M11.5417 1.125H4.45833'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
      </svg>
    </>
  ),
)
