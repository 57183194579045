import { Link } from 'react-scroll'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { isUserAgent } from '../utils'
import { USER_AGENTS } from '../utils/constants'

const heroBackground = '/website/mobilelanding/hero-background.jpg'
const heroPhoneTwo = '/website/mobilelanding/phone-mock-two.png'
const radial = '/website/mobilelanding/radient-gradient.png'
const phoneGroup = '/website/mobilelanding/phone-group.png'
const kaiberK = '/website/mobilelanding/kaiber-k.png'
const apple = '/website/mobilelanding/apple.svg'
const android = '/website/mobilelanding/android.svg'
const joinCom = '/website/mobilelanding/join-com.jpg'
const learnWith = '/website/mobilelanding/learn-with-image.png'

const creativeOnepp = '/website/mobilelanding/creative-onepp.png'
const creativeTwopp = '/website/mobilelanding/creative-twopp.png'
const creativeThreepp = '/website/mobilelanding/creative-threepp.png'
const creativeFourpp = '/website/mobilelanding/creative-fourpp.png'

const userOne = '/website/mobilelanding/user-one.svg'
const userTwo = '/website/mobilelanding/user-two.svg'
const userThree = '/website/mobilelanding/user-three.svg'
const userFour = '/website/mobilelanding/user-four.svg'

export default function MobileLanding({ isAuthenticated }) {
  const isMobile = isUserAgent(USER_AGENTS.mobile)

  return (
    <div className='bg-black'>
      <Navbar />

      <div className='w-full md:mt-[100px] mt-[75px]'>
        <img
          className='min-w-[200%] ml-[-50%] md:ml-0 md:min-w-[100%] w-full'
          src={heroBackground}
          alt='Hero Banner'
        />
        <h1 className='font-semibold text-[#ECFBEC] text-[32px] md:text-[94px] leading-[1.2] absolute top-[212px] left-0 m-auto text-center w-full z-10'>
          Kaiber Mobile App
        </h1>
      </div>

      <div className='w-full min-h-[100vh] relative'>
        <img
          className='absolute top-0 right-0'
          src={radial}
          alt='radial-gradient'
        />

        <div className='w-full flex justify-center relative py-[80px] md:pb-[110px] '>
          <div className='flex h-full items-center justify-between w-[95%] flex-col-reverse md:flex-row max-w-[1200px]'>
            <div
              className='text-[#ECFBEC] max-w-[100%] md:max-w-[60%]'
              id='download'
            >
              <h2 className='text-[32px] md:text-[48px] leading-[1.2]'>
                The generative art platform for creatives, now on the go.
              </h2>
              <p className='text-base md:text-[20px] mt-[20px] mb-[30px]'>
                Turn text, videos, photos, and music into stunning AI animations
                in minutes. Available now on iOS and Android.
              </p>
              {/* <Link to='offer' smooth={!isMobile} duration={isMobile ? 0 : 500} offset={-360} className='items-center justify-center inline-flex px-[80px] py-4 text-xl font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40 cursor-pointer'>
                        Join Waitlist
                    </Link> */}
              <div className='flex items-center gap-[20px] md:gap-[90px] justify-center flex-col md:flex-row mt-[55px]'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://apps.apple.com/app/kaiber/id6458980808'
                >
                  <img src={apple} alt='apple' className='max-w-[275px]' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://play.google.com/store/apps/details?id=ai.kaiber.mobile&pli=1'
                >
                  <img
                    src={android}
                    alt='android'
                    className='max-w-[275px] w-[198px] md:w-[221px]'
                  />
                </a>
              </div>
            </div>

            <img src={heroPhoneTwo} alt='phone' className='max-w-[275px]' />
          </div>
        </div>

        <div className='w-full flex justify-center relative mt-[120px] md:mt-0'>
          <div className='flex flex-col max-w-[1300px] w-[95%]'>
            <div className='flex justify-center mb-[80px]'>
              <div className='text-[#ECFBEC] text-center w-[95%] max-w-[660px]'>
                <h2 className='mb-[16px] text-[30px] md:text-[48px]'>
                  Loved by 60,000+ creatives
                </h2>
                <p className='text-base md:text-[20px]'>
                  Tailor-made for modern creatives who want to blend their
                  artistry with the power of AI. Experiment with different
                  generative styles and make visuals like never before.
                </p>
              </div>
            </div>

            <div className='flex justify-between'>
              <div className='w-[48%] md:w-[23%]'>
                <div className='relative w-full pt-[178%] overflow-hidden'>
                  <iframe
                    className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[16px]'
                    title='Gumlet video player'
                    src='https://play.gumlet.io/embed/6513509052ccfd817db19cb1'
                    allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='flex items-center mt-[16px]'>
                  <img
                    src={userOne}
                    alt=''
                    className='max-h-[50px] md:max-h-[70px]'
                  />{' '}
                </div>
              </div>

              <div className='w-[48%] md:w-[23%]'>
                <div className='relative w-full pt-[178%] overflow-hidden'>
                  <iframe
                    className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[16px]'
                    title='Gumlet video player'
                    src='https://play.gumlet.io/embed/651351e952ccfd817db1a44a'
                    allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='flex items-center mt-[16px]'>
                  <img
                    src={userTwo}
                    alt=''
                    className='max-h-[50px] md:max-h-[70px]'
                  />
                </div>
              </div>

              <div className='w-[48%] md:w-[23%] hidden md:block'>
                <div className='relative w-full pt-[178%] overflow-hidden'>
                  <iframe
                    className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[16px]'
                    title='Gumlet video player'
                    src='https://play.gumlet.io/embed/6513522152ccfd817db1a58f'
                    allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='flex items-center mt-[16px]'>
                  <img
                    src={userThree}
                    alt=''
                    className='max-h-[50px] md:max-h-[70px]'
                  />
                </div>
              </div>

              <div className='w-[48%] md:w-[23%] hidden md:block '>
                <div className='relative w-full pt-[178%] overflow-hidden'>
                  <iframe
                    className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[16px]'
                    title='Gumlet video player'
                    src='https://play.gumlet.io/embed/6513525a808b49c9a4a64c2e'
                    allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='flex items-center mt-[16px]'>
                  <img
                    src={userFour}
                    alt=''
                    className='max-h-[50px] md:max-h-[70px]'
                  />
                </div>
              </div>
            </div>

            <div className='flex justify-between mt-[32px] md:hidden'>
              <div className='w-[48%]'>
                <div className='relative w-full pt-[178%] overflow-hidden'>
                  <iframe
                    className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[16px]'
                    title='Gumlet video player'
                    src='https://play.gumlet.io/embed/6513522152ccfd817db1a58f'
                    allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='flex items-center mt-[16px]'>
                  <img
                    src={userThree}
                    alt=''
                    className='max-h-[50px] md:max-h-[70px]'
                  />
                </div>
              </div>

              <div className='w-[48%]'>
                <div className='relative w-full pt-[178%] overflow-hidden'>
                  <iframe
                    className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[16px]'
                    title='Gumlet video player'
                    src='https://play.gumlet.io/embed/6513525a808b49c9a4a64c2e'
                    allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                    allowFullScreen
                  ></iframe>
                </div>
                <div className='flex items-center mt-[16px]'>
                  <img
                    src={userFour}
                    alt=''
                    className='max-h-[50px] md:max-h-[70px]'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-center mt-[250px]'>
          <div className='max-w-[1000px] m-auto'>
            <div
              className='flex flex-col-reverse md:flex-row items-center max-w-[100%] m-auto gap-[60px] bg-no-repeat bg-[90%]'
              style={{ backgroundImage: `url(${kaiberK})` }}
            >
              <div className='min-w-[50%] flex justify-center'>
                <div className='text-[#ECFBEC] max-w-[95%]'>
                  <h2 className='text-[32px] md:text-[48px] mb-[32px] leading-[1.2]'>
                    Tap into your creativity
                  </h2>

                  <ul className='marker:text-[#ECFBEC] list-disc ml-[25px]'>
                    <li className='text-[20px] font-normal mb-[8px]'>
                      Co-create with top artists and musicians
                    </li>
                    <li className='text-[20px] font-normal mb-[8px]'>
                      Create audioreactive music visuals
                    </li>
                    <li className='text-[20px] font-normal mb-[8px]'>
                      Remix your existing videos
                    </li>
                    <li className='text-[20px] font-normal mb-[8px]'>
                      Convert images into animations
                    </li>
                    <li className='text-[20px] font-normal mb-[8px]'>
                      Generate art from text in minutes
                    </li>
                    <li className='text-[20px] font-normal mb-[32px]'>
                      Access all your videos and prompts from anywhere
                    </li>
                  </ul>

                  <Link
                    to='download'
                    smooth={!isMobile}
                    duration={isMobile ? 0 : 500}
                    offset={-360}
                    className='items-center justify-center inline-flex px-[80px] py-4 text-xl font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40 cursor-pointer'
                  >
                    Download Now
                  </Link>
                </div>
              </div>
              <div className='min-w-[100%] md:min-w-[50vw] flex items-center justify-center'>
                <img
                  className='block w-[100%] h-[100%] max-w-[95%] md:max-w-[70%] object-cover md:min-w-[484px]'
                  src={phoneGroup}
                  alt='kaiber-phones-group'
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className='mb-[42px] md:mb-[60px] text-[32px] text-center text-[#ECFBEC] mt-[250px] font-semibold'>
          What creatives are saying
        </h2>

        <div className='flex justify-center max-w-[95%] m-auto'>
          <div className='max-w-[790px] flex gap-[16px] md:gap-[36px] flex-col md:flex-row w-full md:w-auto'>
            <div className='flex gap-[16px] md:gap-[24px] flex-col md:w-[48%] w-full'>
              <div className='py-[24px] px-[32px] bg-[#212127] rounded-[16px]'>
                <p className='text-base text-[#8E978E]'>
                  “As I’m always traveling, Kaiber has been an amazing addition
                  to my workflow, enabling me to quickly generate creative ideas
                  in a mobile-friendly way. Their user experience is also very
                  accessible for beginners, while leaving enough control even
                  for experts.”
                </p>
                <div className='flex gap-[24px] mt-[24px] items-center'>
                  <img
                    className='max-w-[72px]'
                    src={creativeOnepp}
                    alt='user'
                  />

                  <div className=''>
                    <h3 className='text-[#ECFBEC] font-semibold'>
                      @manu.vision
                    </h3>
                    <p className='text-[20px] text-[#8E978E] font-normal'>
                      Caribbeanofuturist
                    </p>
                  </div>
                </div>
              </div>

              <div className='py-[24px] px-[32px] bg-[#212127] rounded-[16px]'>
                <p className='text-base text-[#8E978E]'>
                  'It is easy to navigate... I can quickly take a picture, add
                  some audio, and my prompt, then get notified when my video is
                  ready. This gives me a huge amount of freedom in where and how
                  I can create. “
                </p>
                <div className='flex gap-[24px] mt-[24px] items-center'>
                  <img
                    className='max-w-[72px]'
                    src={creativeTwopp}
                    alt='user'
                  />

                  <div className=''>
                    <h3 className='text-[#ECFBEC] font-semibold'>
                      @thenumber2
                    </h3>
                    <p className='text-[20px] text-[#8E978E] font-normal'>
                      Marketer
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex gap-[16px] md:gap-[24px] flex-col md:w-[48%] w-full'>
              <div className='py-[24px] px-[32px] bg-[#212127] rounded-[16px]'>
                <p className='text-base text-[#8E978E]'>
                  'The app's been pretty fast for me. This is a great way for
                  people who don't have a computer to do quick content. And it's
                  super easy for even first timers to understand.”
                </p>
                <div className='flex gap-[24px] mt-[24px] items-center'>
                  <img
                    className='max-w-[72px]'
                    src={creativeThreepp}
                    alt='user'
                  />

                  <div className=''>
                    <h3 className='text-[#ECFBEC] font-semibold'>@sky.nesss</h3>
                    <p className='text-[20px] text-[#8E978E] font-normal'>
                      Content Creator
                    </p>
                  </div>
                </div>
              </div>

              <div className='py-[24px] px-[32px] bg-[#212127] rounded-[16px]'>
                <p className='text-base text-[#8E978E]'>
                  “In a world where every single AI system is stumbling over
                  itself trying to replace your whole toolbox, Kaiber is taking
                  a different approach. It feels more like a tool that was
                  designed to make you pick up your other tools again.”
                </p>
                <div className='flex gap-[24px] mt-[24px] items-center'>
                  <img
                    className='max-w-[72px]'
                    src={creativeFourpp}
                    alt='user'
                  />

                  <div className=''>
                    <h3 className='text-[#ECFBEC] font-semibold'>
                      @august.kamp
                    </h3>
                    <p className='text-[20px] text-[#8E978E] font-normal'>
                      Musician
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-center items-center my-[200px]'>
          <div className='max-w-[1057px] w-[95%] flex flex-col gap-[53px]'>
            <img
              className='w-full md:rounded-[114px] rounded-[30px]'
              src={joinCom}
              alt='join'
            />

            <div className='flex justify-center mb-[80px]'>
              <div className='text-[#ECFBEC] text-center w-[95%] max-w-[660px]'>
                <h2 className='mb-[16px] text-[30px] md:text-[48px]'>
                  Join the Kaiber Community
                </h2>
                <p className='text-base md:text-[20px] mb-[30px]'>
                  Be part of the culture shift. Our community is a hub of
                  learning, sharing, and growing with fellow creators and
                  industry pioneers
                </p>
                <a
                  className='text-black bg-kaiberGreen rounded-full py-3 px-[70px] text-base xl:text-2xl font-semibold'
                  target='_blank'
                  rel='noreferrer'
                  href='https://discord.com/invite/kaiber'
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-[150px] md:mb-[270px] flex justify-center mt-[200px] md:mt-[240px]'>
          <div className='flex flex-col md:flex-row max-w-[1890px] w-full items-center relative md:h-[788px]'>
            <div className='md:absolute left-0 self-start'>
              <div className='relative pt-0 md:w-[445px] md:pt-[177.67%] overflow-hidden md:rounded-tr-[200px] md:rounded-br-[200px] rounded-tl-[0px] rounded-bl-[0px] h-[456px] p-0 rounded-tr-[100px] rounded-br-[100px] w-[260px]'>
                <img
                  src={learnWith}
                  className='absolute top-0'
                  alt='learn with kaiber'
                />
              </div>
            </div>

            <div className=' md:ml-[525px] mt-[20px] relative z-[1] max-w-[90%] m-auto md:m-0 md:max-w-[789px] w-full '>
              <h2 className='text-tertiary xl:text-5xl font-medium leading-tight mb-8 md:max-w-[90%]'>
                Learn with Kaiber
              </h2>
              <p className='text-base md:text-[24px] text-[#ECFBEC] mb-[50px] max-w-[678px] md:leading-[31px]  leading-[20px]'>
                From prompting tips and curated styles to community creations
                and behind-the-scenes Studio project—dive into the world of
                Kaiber.
              </p>
              <a
                className='text-black bg-kaiberGreen rounded-full py-3 px-[70px] text-base xl:text-2xl font-semibold'
                target='_blank'
                rel='noreferrer'
                href='https://blog.kaiber.ai/'
              >
                Start Learning
              </a>
            </div>
          </div>
        </div>

        <div className='text-center text-[#ECFBEC] my-[250px]' id='offer'>
          <h2 className='text-[32px] md:text-[48px] mb-[40px]'>
            The power of Kaiber in your pocket
          </h2>

          <Link
            to='download'
            smooth={!isMobile}
            duration={isMobile ? 0 : 500}
            offset={-360}
            className='items-center justify-center inline-flex px-[80px] py-4 text-xl font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40 cursor-pointer'
          >
            Download Now
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  )
}
