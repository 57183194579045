function TestimonyComponent({
  text,
  image,
  avatar,
  name,
  job,
  igLink,
  orderImg,
  orderText,
  justify,
}) {
  return (
    <div
      className={`flex flex-1 w-full sm:flex-row flex-col my-20 gap-14 items-center ${justify}`}
    >
      <div
        className={`sm:min-w-[672px] w-full h-[440px] overflow-hidden order-first ${orderImg}  order-first`}
      >
        <img
          className={`w-full h-full object-cover rounded-3xl order-first `}
          src={image}
          alt='Kaiber Art'
        />
      </div>
      <div
        className={`flex flex-1 min-w-[60%] flex-col gap-10 ${orderText} order-last`}
      >
        <p className='text-white/50 lg:text-3xl sm:text-2xl text-xl max-w-2xl'>
          {text}
        </p>
        <div className='flex lg:gap-10 gap-5 items-center'>
          <img
            className='lg:w-28 lg:h-28 w-16 h-16 rounded-full'
            src={avatar}
            alt='Rounded avatar'
          />
          <div className='flex flex-col text-white lg:text-3xl sm:text-2xl text-xl'>
            <span>{name}</span>
            <span className='text-white/50'>{job}</span>
            <div className='flex items-center space-x-3 mt-3'>
              {igLink && (
                <a
                  className='text-white hover:text-primary duration-300 transition-colors'
                  href={igLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label='twitter'
                >
                  <svg
                    className='sm:w-7 w-5 sm:h-7 h-5'
                    aria-hidden='true'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'></path>
                  </svg>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonyComponent
