import { useState } from 'react'
import { useParams } from 'react-router-dom'
import logo from '../../images/welcome/logo.png'
import { resetPassword } from '../../services/AuthService'
import { Alert } from '../../components/Alert'
import { PasswordInput } from '../../components/PasswordInput'
import Lottie from 'lottie-react'
import LoadingWhite from '../../images/lottie/loaderWhite.json'

export default function ResetPassword() {
  const { passwordResetToken } = useParams()
  const [errors, setErrors] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newPassword, setNewPassword] = useState(null)

  const clearError = (field) => {
    setErrors((errors) => ({
      ...errors,
      [field]: null,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    const email = e.target[0].value
    //const newPassword = e.target[1].value
    const newPasswordConfirmation = e.target[2].value

    try {
      await resetPassword(
        passwordResetToken,
        email,
        newPassword,
        newPasswordConfirmation,
      )

      setShowSuccess(true)

      setTimeout(() => {
        window.location.replace('/dashboard')
      }, 2000)
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            setAlertMessage(err.response.data.message)
            setShowAlert(true)
            break
          case 400:
            setErrors(err.response.data.errors)
            break
          default:
            setAlertMessage('Oops! Something went wrong.')
            setShowAlert(true)
        }
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
        setShowAlert(true)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <div
        className='flex h-full overflow-y-auto sm:p-10 bg-darkGray sm:bg-transparent bg-cover bg-center'
        style={{
          backgroundImage: 'url(../../website/welcome/bg_quote_new.png)',
        }}
      >
        <div className='w-full sm:max-w-sm bg-black sm:rounded-3xl m-auto p-10 sm:relative'>
          <div className='mb-8 text-center'>
            <a
              href={process.env.REACT_APP_SITE_URL}
              className='relative inline-block max-w-[250px] mt-[20px]'
            >
              <img src={logo} alt='Logo' />
            </a>
          </div>

          {showAlert && (
            <div className='mb-4'>
              <Alert
                open={showAlert}
                setOpen={setShowAlert}
                message={alertMessage}
                type='alert'
              />
            </div>
          )}

          {showSuccess && (
            <div className='mb-4'>
              <Alert
                open={showSuccess}
                message='Password reset successful!'
                type='success'
              />
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Email</strong>
              </label>
              <input
                type='email'
                id='email'
                onInput={() => clearError('email')}
                required
                className={`w-full p-3 px-5 mt-1 block ${
                  errors.email ? 'border-red-700' : 'border-transparent'
                } border rounded-full bg-[#303030] text-white`}
                disabled={isSubmitting || showSuccess}
              />
              {errors.email && (
                <span className='text-red-500'>{errors.email}</span>
              )}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='new-password'
                className='block text-tertiary px-5 font-light'
              >
                <strong>New Password</strong>
              </label>
              <PasswordInput
                id='new-password'
                onInput={(e) => {
                  clearError('newPassword')
                  setNewPassword(e.target.value)
                }}
                required
                className={`w-full p-3 px-5 mt-1 block ${
                  errors.newPassword ? 'border-red-700' : 'border-transparent'
                } border rounded-full bg-[#303030] text-white`}
                disabled={isSubmitting || showSuccess}
              />
              {errors.newPassword && (
                <span className='text-red-500'>{errors.newPassword}</span>
              )}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='password-confirmation'
                className='block text-tertiary px-5 font-light'
              >
                <strong>Confirm Password</strong>
              </label>
              <input
                type='password'
                id='password-confirmation'
                onInput={() => clearError('passwordConfirmation')}
                required
                className={`w-full p-3 px-5 mt-1 block ${
                  errors.passwordConfirmation
                    ? 'border-red-700'
                    : 'border-transparent'
                } border rounded-full bg-[#303030] text-white`}
                disabled={isSubmitting || showSuccess}
              />
              {errors.passwordConfirmation && (
                <span className='text-red-500'>
                  {errors.passwordConfirmation}
                </span>
              )}
            </div>
            <button
              type='submit'
              className='w-full rounded-full border bg-kaiberGreen border-primary p-4 text-sm font-bold text-gray-900 shadow-sm'
              disabled={isSubmitting || showSuccess}
            >
              Change Password
            </button>
          </form>

          {isSubmitting && (
            <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 sm:rounded-3xl'>
              <div className='flex flex-col items-center space-y-4 m-auto'>
                <div className='bg-darkGray p-6 rounded-3xl'>
                  <Lottie
                    animationData={LoadingWhite}
                    loop={true}
                    className='w-16 h-16'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
