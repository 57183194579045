export const DoubleImageIcon = ({ className }: { className?: string }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 28 28'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g filter='url(#filter0_d_3084_60851)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.75 3.83333C4.75 2.82081 5.57081 2 6.58333 2H14.9167C15.9292 2 16.75 2.82081 16.75 3.83333V12.1667C16.75 13.1792 15.9292 14 14.9167 14H6.58333C5.57081 14 4.75 13.1792 4.75 12.1667V3.83333ZM6.58333 3C6.12309 3 5.75 3.37309 5.75 3.83333V9.6262L6.78697 8.58927C7.50293 7.87327 8.66373 7.87327 9.37969 8.58927L13.7905 13H14.9167C15.3769 13 15.75 12.6269 15.75 12.1667V3.83333C15.75 3.37309 15.3769 3 14.9167 3H6.58333Z'
        fill='currentColor'
        fillOpacity='0.9'
      />
      <path
        d='M11.7002 5.69148C11.7324 5.67535 11.7586 5.6492 11.7747 5.61694L12.2675 4.63144C12.3289 4.5086 12.5042 4.5086 12.5656 4.63144L13.0583 5.61694C13.0745 5.6492 13.1007 5.67535 13.1329 5.69148L14.1184 6.18422C14.2412 6.24564 14.2412 6.42095 14.1184 6.48237L13.1329 6.9751C13.1007 6.99123 13.0745 7.01743 13.0583 7.04963L12.5656 8.03516C12.5042 8.15796 12.3289 8.15796 12.2675 8.03516L11.7747 7.04963C11.7586 7.01743 11.7324 6.99123 11.7002 6.9751L10.7147 6.48237C10.5919 6.42095 10.5919 6.24564 10.7147 6.18422L11.7002 5.69148Z'
        fill='currentColor'
        fillOpacity='0.9'
      />
    </g>
    <g filter='url(#filter1_d_3084_60851)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 10.7917C10.5 9.52602 11.526 8.5 12.7917 8.5H23.2083C24.474 8.5 25.5 9.52602 25.5 10.7917V21.2083C25.5 22.474 24.474 23.5 23.2083 23.5H12.7917C11.526 23.5 10.5 22.474 10.5 21.2083V10.7917ZM12.7917 9.75C12.2164 9.75 11.75 10.2164 11.75 10.7917V18.0327L13.0462 16.7366C13.9412 15.8416 15.3922 15.8416 16.2871 16.7366L21.8006 22.25H23.2083C23.7837 22.25 24.25 21.7837 24.25 21.2083V10.7917C24.25 10.2164 23.7837 9.75 23.2083 9.75H12.7917Z'
        fill='currentColor'
      />
      <path
        d='M19.1879 13.1143C19.2281 13.0942 19.2609 13.0615 19.281 13.0212L19.8969 11.7893C19.9737 11.6357 20.1929 11.6357 20.2696 11.7893L20.8855 13.0212C20.9057 13.0615 20.9384 13.0942 20.9787 13.1143L22.2106 13.7303C22.3641 13.8071 22.3641 14.0262 22.2106 14.103L20.9787 14.7189C20.9384 14.739 20.9057 14.7718 20.8855 14.812L20.2696 16.044C20.1929 16.1975 19.9737 16.1975 19.8969 16.044L19.281 14.812C19.2609 14.7718 19.2281 14.739 19.1879 14.7189L17.9559 14.103C17.8024 14.0262 17.8024 13.8071 17.9559 13.7303L19.1879 13.1143Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_3084_60851'
        x='-1.25'
        y='0'
        width='24'
        height='24'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_3084_60851'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_3084_60851'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d_3084_60851'
        x='4'
        y='6'
        width='28'
        height='28'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_3084_60851'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_3084_60851'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)
