import { TbCoins } from 'react-icons/tb'

import { useThemeContext } from '../../../context'
import { cn } from '../../../utils'
import { formatCredit } from '../../../utils/creditUtil'
import { User } from '@/types'

interface AccountPillProps {
  user: User
  isAccountSettingsOpen: boolean
  setIsAccountSettingsOpen: (isAccountSettingsOpen: boolean) => void
}

export const AccountPill = ({
  user,
  isAccountSettingsOpen,
  setIsAccountSettingsOpen,
}: AccountPillProps) => {
  const { colors } = useThemeContext()

  return (
    <div
      className='flex gap-4 items-center justify-between cursor-pointer'
      onClick={() => setIsAccountSettingsOpen(!isAccountSettingsOpen)}
    >
      {/* avatar component */}
      <div className='w-8 h-8 rounded-full bg-pink-200'>
        <img
          src={user.profilePicture || '/favicon-32x32.png'}
          alt='avatar'
          className={cn('w-full h-full rounded-full select-none', {
            'border border-shrek bg-lightgray': isAccountSettingsOpen,
          })}
        />
      </div>

      {/* acct balance */}
      {user.credits && (
        <div
          className={`h-8 flex items-center gap-1 text-xs ${colors.text.default} cursor-default select-none px-3 py-1 rounded-full ${colors.elevation.surface.sunken}`}
        >
          <TbCoins size={14} />
          <span className='tracking-wider'>{formatCredit(user.credits)}</span>
        </div>
      )}
    </div>
  )
}
