import { useState, useEffect } from 'react'
import http from '../services/HttpService'
import { toast } from 'react-toastify'
import { Modal } from '../components/Modal'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

function VideoDeletionModal({
  isOpen,
  videoId,
  onClose,
  onDelete,
  isAdmin,
  isAdminDeleted = false,
  userSelectedData,
}) {
  const [reasonText, setReasonText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    if (isAdmin) {
      try {
        onClose(false)
        onDelete(videoId)
        setIsDeleting(true)
        await http.post(`/api/admin/delete-video`, {
          videoId: videoId,
          email: userSelectedData.email,
          userName: userSelectedData.name,
          reasonText: reasonText,
        })

        toast.success('The video has been deleted.')
      } catch (error) {
        console.log(error)
      }
    } else {
      onClose(false)
      onDelete(videoId)
      setIsDeleting(true)
      await http.delete(`/api/delete_video/${videoId}`)

      toast.success('Your video has been deleted.')
    }
  }

  const handleRestore = async () => {
    try {
      onClose(false)
      onDelete(videoId)
      setIsDeleting(true)
      await http.post(`/api/admin/restore-video`, {
        videoId: videoId,
        reasonText: reasonText,
      })

      toast.success('The video has been restored.')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsDeleting(false)
    }
  }, [isOpen])

  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='px-6 py-6 sm:px-12 sm:py-10 space-y-8 w-full'>
        <p className='text-2xl sm:text-5xl text-tertiary'>Are you sure?</p>
        {isAdmin && userSelectedData ? (
          <div>
            {!isAdminDeleted ? (
              <div>
                <div className='flex flex-col'>
                  <p className='text-tertiary/80'>
                    You're about to delete a video created by
                  </p>
                  <a
                    className='flex items-center text-primary'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`/admin/users/${userSelectedData.distinctId}`}
                  >
                    <p className='mr-1'>{userSelectedData.email}</p>
                    <ArrowTopRightOnSquareIcon className='w-4 h-4' />.
                  </a>
                </div>
                <input
                  type='text'
                  id='reason'
                  onChange={(e) => setReasonText(e.target.value)}
                  placeholder='Enter a reason for deletion.'
                  required
                  className={`w-full mt-3 block ${
                    true ? 'border-red-700' : 'border-transparent'
                  } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                />
              </div>
            ) : (
              <div>
                <p className='text-tertiary/80'>
                  This video has been deleted by an administrator, would you
                  like to restore it?
                </p>
                <input
                  type='text'
                  id='reason'
                  onChange={(e) => setReasonText(e.target.value)}
                  placeholder='Enter a reason for restoring.'
                  required
                  className={`w-full mt-3 block ${
                    true ? 'border-red-700' : 'border-transparent'
                  } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                />
              </div>
            )}
          </div>
        ) : (
          <p className='text-tertiary/80'>
            You're about to delete a video and this action cannot be undone.
          </p>
        )}
        <div className='mt-4 flex items-center justify-end space-x-2'>
          <button
            type='button'
            className='inline-flex items-center rounded-full border bg-transparent px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-primary/25'
            onClick={() => onClose(false)}
            disabled={isDeleting}
          >
            No, cancel
          </button>
          {isAdminDeleted && isAdmin ? (
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleRestore}
              disabled={isDeleting}
            >
              Yes, restore video
            </button>
          ) : (
            <button
              type='button'
              className='inline-flex items-center rounded-full border bg-red-500 px-4 py-4 text-sm font-bold text-white shadow-sm uppercase gap-2 border-red-500'
              onClick={handleDelete}
              disabled={isDeleting}
            >
              Yes, delete video
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default VideoDeletionModal
