import { useEffect, useState } from 'react'

import { useUserAccountContext } from '../context/userContext'
import { Modal } from './Modal'
import { Button } from './_deprecated/Button'
import http from '../services/HttpService'

const CompleteOnboardingModal = ({ children }) => {
  const { currentUser, isOnboardingCompleted } = useUserAccountContext()

  const [isOpen, setIsOpen] = useState(false)
  const [videoCount, setVideoCount] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      const res = await http.get('/api/get_video_count')
      setVideoCount(res.data.videoCount)
    }

    fetchData()
  }, [])

  useEffect(() => {
    const checkModal = async () => {
      try {
        if (
          !currentUser ||
          window.location.pathname === '/onboarding' ||
          isOnboardingCompleted
        ) {
          return
        }
        const showModal = videoCount > 2 && currentUser.skippedOnboarding
        setIsOpen(showModal)
      } catch (error) {
        console.error('CompleteOnboardingModal', error)
      }
    }

    checkModal()
  }, [currentUser, isOnboardingCompleted, videoCount])

  function setOpen(newModalStatus) {
    setIsOpen(newModalStatus)
    if (newModalStatus === false) {
      window.location.replace('/onboarding')
    }
  }

  return (
    <>
      {children}
      <Modal open={isOpen} setOpen={setOpen} persistent>
        <section className='flex flex-col gap-3 items-center justify-center md:w-full'>
          <h2 className='text-white text-center md:w-72'>
            Complete the onboarding survey to continue using Kaiber.
          </h2>
          <Button className='w-48 mt-3 md:w-52'>
            <a className='' href='/onboarding'>
              Continue
            </a>
          </Button>
        </section>
      </Modal>
    </>
  )
}

export { CompleteOnboardingModal }
