import { useEffect, useState } from 'react'
import axios from '../../services/HttpService'
import { Link } from 'react-router-dom'
import SectionTitle from '../welcome/SectionTitle'
import CommunityComponent from './CommunityComponent'

function CommunitySection() {
  const [blogs, setBlogs] = useState([])
  const getBlogs = async () => {
    try {
      const response = await axios.get('/api/community_articles')
      if (response.data) {
        setBlogs(response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getUrl = (url) => {
    return `https://blog.kaiber.ai/blog/${url}`
  }

  useEffect(() => {
    getBlogs()
  }, [])

  return (
    <div className='flex flex-col flex-1 w-full items-center'>
      <SectionTitle title='Kaiber Community' />
      <div className='grid w-full gap-x-10 gap-y-20  my-20 justify-centergap-10 sm:grid-cols-2 lg:grid-cols-3'>
        {blogs.length &&
          blogs.map((blog, index) => {
            return (
              <CommunityComponent
                key={index}
                image={blog.fieldData['main-blog-article-image'].url}
                title={blog.fieldData.name}
                url={getUrl(blog.fieldData.slug)}
              />
            )
          })}
      </div>
      <Link className='mb-20' to='/gallery'>
        <span className='font-semibold lg:text-4xl sm:text-3xl text-2xl underline text-primary hover:text-primary/90 active:text-primary/40 duration-300 '>
          Join our community
        </span>
      </Link>
    </div>
  )
}

export default CommunitySection
