import { TagFilters } from './TagFilters'
import { useThemeContext } from '../../../context'
import { LibraryIcon } from '../../../images/icons/LibraryIcon'
import { cn } from '../../../utils'
import { IconButton } from '../../Button'
import { LibraryViewMode, TagFilter } from '@/types'

interface LibraryHeaderProps {
  tagFilters: TagFilter[]
  handleFilterToggle: (tag: TagFilter) => Promise<void>
  isExpanded: boolean
  isFilterDropdownOpen: boolean
  setIsFilterDropdownOpen: (isOpen: boolean) => void
  viewMode: LibraryViewMode
  onBackToOverview: () => void
}

export const LibraryHeader: React.FC<LibraryHeaderProps> = ({
  tagFilters,
  handleFilterToggle,
  isExpanded,
  isFilterDropdownOpen,
  setIsFilterDropdownOpen,
  viewMode,
  onBackToOverview,
}) => {
  const { colors } = useThemeContext()

  const renderLibraryHeaderTitle = () => (
    <div className='flex items-center'>
      <div className='flex group items-center'>
        <IconButton
          icon={LibraryIcon}
          onClick={onBackToOverview}
          title='back to overview'
          className={cn(
            `${colors.text.subtle} mr-2 hover:scale-100 cursor-default`,
            viewMode === LibraryViewMode.Collection &&
              `group-hover:${colors.text.default} group-hover:scale-105 cursor-pointer`,
          )}
          size={20}
        />
        <span
          className={cn(
            `${colors.text.subtle}`,
            viewMode === LibraryViewMode.Collection &&
              `group-hover:${colors.text.default} cursor-pointer`,
          )}
          onClick={onBackToOverview}
        >
          Materials
        </span>
      </div>
    </div>
  )

  return (
    <div className={'flex items-center justify-between z-40 py-4'}>
      {renderLibraryHeaderTitle()}
      <div className='flex items-center'>
        <TagFilters
          tagFilters={tagFilters}
          handleFilterToggle={handleFilterToggle}
          isExpanded={isExpanded}
          isDropdownOpen={isFilterDropdownOpen}
          setIsDropdownOpen={setIsFilterDropdownOpen}
        />
      </div>
    </div>
  )
}
