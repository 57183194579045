import React from 'react'

interface CropIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const CropIcon = React.forwardRef<SVGSVGElement, CropIconProps>(
  ({ size = `32`, ...props }, ref) => (
    <svg
      ref={ref}
      stroke='currentColor'
      fill='none'
      strokeWidth='0'
      viewBox='0 0 16 16'
      height={size}
      width={size}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.83301 3.83331H10.833C11.5694 3.83331 12.1663 4.43027 12.1663 5.16665V14.1666M3.83301 1.83331V10.8333C3.83301 11.5697 4.42996 12.1666 5.16634 12.1666H14.1663'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
      />
    </svg>
  ),
)
