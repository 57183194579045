import { useCallback, useEffect, useState, useRef } from 'react'
import http from '../services/HttpService'
import { toast } from 'react-toastify'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { getProxiedR2FileUrl } from '../utils/fileUtils'

function DownloadButton({ url, prompt, scale_multiplier = 1 }) {
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadPercent, setDownloadPercent] = useState(0)
  const abortController = useRef(new AbortController())

  const handleText = () => {
    let txt = 'DOWNLOAD'
    switch (scale_multiplier) {
      case 1:
        txt = 'DOWNLOAD SD'
        break
      case 2:
        txt = 'DOWNLOAD 2x'
        break
      case 4:
        txt = 'DOWNLOAD 4x'
        break
      default:
        break
    }
    return txt
  }

  const onDownloadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    setDownloadPercent(percentCompleted)
  }

  const stopDownload = useCallback(() => {
    if (abortController?.current) {
      abortController.current.abort()
    }
  }, [abortController])

  useEffect(() => {
    return () => stopDownload()
  }, [stopDownload])

  const downloadVideo = async (videoUrl, videoTitle, scale_multiplier) => {
    try {
      // Start downloading
      setIsDownloading(true)

      // use proxy server to get around CORS errors with R2
      videoUrl = getProxiedR2FileUrl(videoUrl, true)

      // Fetch the video as a Blob using axios
      const response = await http({
        url: videoUrl,
        method: 'GET',
        responseType: 'blob', // Tell axios to return the response as a Blob
        signal: abortController.current.signal,
        onDownloadProgress,
        transformRequest: (data, headers) => {
          delete headers.Authorization
          return data
        },
      })

      const blob = response.data

      // Create an object URL that points to the Blob
      const objectUrl = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')
      link.href = objectUrl

      // Set the download attribute and the file name
      link.download = videoTitle

      // Add the link to the document
      document.body.appendChild(link)

      // Click the link to start the download
      link.click()

      // Remove the link from the document
      document.body.removeChild(link)

      // Revoke the object URL
      URL.revokeObjectURL(objectUrl)

      // Enable download button
      setIsDownloading(false)
      setDownloadPercent(0)
      toast.success('Video downloaded.')
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      } else {
        window.open(videoUrl, '_blank')
      }
    }
  }

  return (
    <button
      onClick={() =>
        downloadVideo(
          url,
          `${prompt
            .substr(0, 150)
            .replace(/\r\n/g, ' ')}-${scale_multiplier} (${Date.now()}).mp4`,
          scale_multiplier,
        )
      }
      className={`${
        isDownloading ? 'bg-primary/75' : 'radial-gradient'
      } inline-flex flex-grow justify-between items-center rounded-full border border-transparent px-6 py-4 text-base font-bold text-gray-700 shadow-sm focus:outline-none focus:ring-0 gap-2 uppercase  min-w-min w-60 `}
    >
      {isDownloading ? (
        <>
          Downloading... {downloadPercent}%
          <div className='w-6 h-6 border-b-2 border-gray-700 rounded-full animate-spin'></div>
        </>
      ) : (
        <>
          {handleText()}
          <ArrowDownTrayIcon className='w-6 h-6' />
        </>
      )}
    </button>
  )
}

export default DownloadButton
