import { BaseComponentProps } from '../../types'

interface Props extends BaseComponentProps {
  size?: string
}

export const HomeIcon = ({ size = '1em', className }: Props) => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 24 24'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M21 19.9997C21 20.552 20.5523 20.9997 20 20.9997H4C3.44772 20.9997 3 20.552 3 19.9997V9.48882C3 9.18023 3.14247 8.88893 3.38606 8.69947L11.3861 2.47725C11.7472 2.19639 12.2528 2.19639 12.6139 2.47725L20.6139 8.69947C20.8575 8.88893 21 9.18023 21 9.48882V19.9997ZM19 18.9997V9.97791L12 4.53346L5 9.97791V18.9997H19Z'></path>
  </svg>
)
