import {
  Node,
  NodeChange,
  NodePositionChange,
  XYPosition,
  applyNodeChanges,
  Rect,
} from '@xyflow/react'

type GetHelperLinesResult = {
  horizontal?: number
  vertical?: number
  snapPosition: Partial<XYPosition>
}

function getBoundingBoxFromChanges(
  changes: NodePositionChange[],
  nodes: Node[],
): Rect {
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity

  changes.forEach((change) => {
    const node = nodes.find((n) => n.id === change.id)
    if (node && change.position) {
      const width = node.measured?.width ?? 0
      const height = node.measured?.height ?? 0

      minX = Math.min(minX, change.position.x)
      minY = Math.min(minY, change.position.y)
      maxX = Math.max(maxX, change.position.x + width)
      maxY = Math.max(maxY, change.position.y + height)
    }
  })

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

function calculateDynamicRadius(bounds: Rect, padding: number = 50): number {
  const width = bounds.width
  const height = bounds.height
  return Math.max(width, height) / 2 + padding
}

function getHelperLines(
  changes: NodePositionChange[],
  nodes: Node[],
  distance = 25,
  sensitivityRadiusPadding = 200,
): GetHelperLinesResult {
  const defaultResult: GetHelperLinesResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  }

  const selectedNodes = nodes.filter((node) => node.selected)
  const isMultipleNodesSelected = selectedNodes.length > 1

  let nodeABounds: Rect

  let draggingNodes: Node[] = []

  if (isMultipleNodesSelected) {
    // Calculate the bounding box of selected nodes with their new positions
    draggingNodes = selectedNodes.map((node) => {
      const change = changes.find((c) => c.id === node.id)
      return change ? { ...node, position: change.position } : node
    })
    nodeABounds = getBoundingBoxFromChanges(changes, nodes)
  } else {
    const nodeChange = changes[0]
    const nodeA = nodes.find((node) => node.id === nodeChange.id)
    if (!nodeA || !nodeChange.position) {
      return defaultResult
    }
    draggingNodes = [nodeA]
    nodeABounds = {
      x: nodeChange.position.x,
      y: nodeChange.position.y,
      width: nodeA.measured?.width ?? 0,
      height: nodeA.measured?.height ?? 0,
    }
  }

  let horizontalDistance = distance
  let verticalDistance = distance

  const dynamicRadius = calculateDynamicRadius(
    nodeABounds,
    sensitivityRadiusPadding,
  )

  return nodes
    .filter((node) => !node.selected && !draggingNodes.includes(node))
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        x: nodeB.position.x,
        y: nodeB.position.y,
        width: nodeB.measured?.width ?? 0,
        height: nodeB.measured?.height ?? 0,
      }

      const nodeACenterX = nodeABounds.x + nodeABounds.width / 2
      const nodeACenterY = nodeABounds.y + nodeABounds.height / 2
      const nodeBCenterX = nodeBBounds.x + nodeBBounds.width / 2
      const nodeBCenterY = nodeBBounds.y + nodeBBounds.height / 2

      const distanceBetweenNodes = Math.sqrt(
        Math.pow(nodeACenterX - nodeBCenterX, 2) +
          Math.pow(nodeACenterY - nodeBCenterY, 2),
      )

      const nodeBDynamicRadius = calculateDynamicRadius(
        nodeBBounds,
        sensitivityRadiusPadding,
      )
      const combinedRadius = dynamicRadius + nodeBDynamicRadius

      if (distanceBetweenNodes > combinedRadius) {
        return result
      }

      // Left alignment
      const distanceLeftLeft = Math.abs(nodeABounds.x - nodeBBounds.x)
      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.x
        result.vertical = nodeBBounds.x
        verticalDistance = distanceLeftLeft
      }

      // Right alignment
      const distanceRightRight = Math.abs(
        nodeABounds.x + nodeABounds.width - (nodeBBounds.x + nodeBBounds.width),
      )
      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x =
          nodeBBounds.x + nodeBBounds.width - nodeABounds.width
        result.vertical = nodeBBounds.x + nodeBBounds.width
        verticalDistance = distanceRightRight
      }

      // Left to Right alignment
      const distanceLeftRight = Math.abs(
        nodeABounds.x - (nodeBBounds.x + nodeBBounds.width),
      )
      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.x + nodeBBounds.width
        result.vertical = nodeBBounds.x + nodeBBounds.width
        verticalDistance = distanceLeftRight
      }

      // Right to Left alignment
      const distanceRightLeft = Math.abs(
        nodeABounds.x + nodeABounds.width - nodeBBounds.x,
      )
      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.x - nodeABounds.width
        result.vertical = nodeBBounds.x
        verticalDistance = distanceRightLeft
      }

      // Top alignment
      const distanceTopTop = Math.abs(nodeABounds.y - nodeBBounds.y)
      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.y
        result.horizontal = nodeBBounds.y
        horizontalDistance = distanceTopTop
      }

      // Bottom alignment
      const distanceBottomBottom = Math.abs(
        nodeABounds.y +
          nodeABounds.height -
          (nodeBBounds.y + nodeBBounds.height),
      )
      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y =
          nodeBBounds.y + nodeBBounds.height - nodeABounds.height
        result.horizontal = nodeBBounds.y + nodeBBounds.height
        horizontalDistance = distanceBottomBottom
      }

      // Top to Bottom alignment
      const distanceTopBottom = Math.abs(
        nodeABounds.y - (nodeBBounds.y + nodeBBounds.height),
      )
      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.y + nodeBBounds.height
        result.horizontal = nodeBBounds.y + nodeBBounds.height
        horizontalDistance = distanceTopBottom
      }

      // Bottom to Top alignment
      const distanceBottomTop = Math.abs(
        nodeABounds.y + nodeABounds.height - nodeBBounds.y,
      )
      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.y - nodeABounds.height
        result.horizontal = nodeBBounds.y
        horizontalDistance = distanceBottomTop
      }

      return result
    }, defaultResult)
}

export const applyHelperLinesToNodeChanges = (
  changes: NodeChange[],
  nodes: Node[],
  setHelperLineHorizontal: (value: number | undefined) => void,
  setHelperLineVertical: (value: number | undefined) => void,
): Node[] => {
  const positionChanges = changes.filter(
    (change): change is NodePositionChange =>
      change.type === 'position' && change.dragging && !!change.position,
  )

  if (positionChanges.length > 0) {
    const helperLines = getHelperLines(positionChanges, nodes)
    const selectedNodes = nodes.filter((node) => node.selected)

    if (selectedNodes.length > 1) {
      // Calculate the current bounding box based on the changes
      const currentBounds = getBoundingBoxFromChanges(positionChanges, nodes)

      // Apply the snap to all selected nodes
      positionChanges.forEach((change) => {
        const node = selectedNodes.find((n) => n.id === change.id)
        if (node) {
          if (helperLines.snapPosition.x !== undefined) {
            change.position.x += helperLines.snapPosition.x - currentBounds.x
          }

          if (helperLines.snapPosition.y !== undefined) {
            change.position.y += helperLines.snapPosition.y - currentBounds.y
          }
        }
      })
    } else {
      // Single node selection, apply snap directly (unchanged)
      if (helperLines.snapPosition.x !== undefined) {
        positionChanges[0].position.x = helperLines.snapPosition.x
      }
      if (helperLines.snapPosition.y !== undefined) {
        positionChanges[0].position.y = helperLines.snapPosition.y
      }
    }

    setHelperLineHorizontal(helperLines.horizontal)
    setHelperLineVertical(helperLines.vertical)
  }

  return applyNodeChanges(changes, nodes)
}
