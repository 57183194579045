import { NodeProps, Node, XYPosition } from '@xyflow/react'
import { useMemo } from 'react'

import { NodeType } from './nodeTypes'
import {
  AssembleNodeData,
  AssembleNode,
  AssembleSlotNodeData,
  AssembleSlotNode,
} from '../components/k2/Nodes/AssembleNode'
import {
  CollectionNode,
  CollectionNodeData,
} from '../components/k2/Nodes/CollectionNode'
import {
  FlowNode,
  FlowNodeData,
} from '../components/k2/Nodes/FlowNode/FlowNode'
import { CropperNode } from '../components/k2/Nodes/CropperNode'
import { MediaNodeData, MediaNode } from '../components/k2/Nodes/MediaNode'

// Define a base interface for common properties
interface BaseNodeData {
  dragStartPosition?: XYPosition
}
interface NodeDataMap {
  [NodeType.FlowNode]: FlowNodeData & BaseNodeData
  [NodeType.MediaNode]: MediaNodeData & BaseNodeData
  [NodeType.CropperNode]: MediaNodeData & BaseNodeData
  [NodeType.AssembleNode]: AssembleNodeData & BaseNodeData
  [NodeType.AssembleSlotNode]: AssembleSlotNodeData & BaseNodeData
  [NodeType.CollectionNode]: CollectionNodeData & BaseNodeData
}

export type NodeComponentsMap = {
  [K in NodeType]: React.FC<NodeProps<Node<NodeDataMap[K], K>>>
}

export type AllNodeTypes = {
  [K in NodeType]: Node<NodeDataMap[K] & BaseNodeData, K>
}[NodeType]

export const useNodeTypes = (): NodeComponentsMap => {
  const nodeTypes = useMemo(
    () => ({
      [NodeType.FlowNode]: FlowNode,
      [NodeType.MediaNode]: MediaNode,
      [NodeType.CropperNode]: CropperNode,
      [NodeType.AssembleNode]: AssembleNode,
      [NodeType.AssembleSlotNode]: AssembleSlotNode,
      [NodeType.CollectionNode]: CollectionNode,
    }),
    [],
  )

  return nodeTypes
}
