import { useThemeContext } from '../../../../context'
import { AspectRatio, RatioSettingConfig } from '../../../../types'
import { cn } from '../../../../utils'

export const RadioSelectButton = ({
  ratioSettingConfig,
  currentAspectRatio,
  onRatioChange,
}: {
  ratioSettingConfig: RatioSettingConfig
  currentAspectRatio: AspectRatio
  onRatioChange: (ratio: AspectRatio) => void
}) => {
  const { colors } = useThemeContext()
  const ratio =
    `${ratioSettingConfig.ratio.w}:${ratioSettingConfig.ratio.h}` as AspectRatio

  // Special case for 9:16 aspect ratio setting because the default size is too small
  const width = ratioSettingConfig.id === 5 ? 96 : ratioSettingConfig.width
  const height = ratioSettingConfig.id === 5 ? 170 : ratioSettingConfig.height

  return (
    <button
      key={ratioSettingConfig.id}
      className={cn(
        `${colors.elevation.surface.secondary} ${colors.text.default} rounded-[4px] h-full w-full outline-none hover:bg-opacity-80 hover:bg-k2-neutral-transparent-300 bg-opacity-75 border border-opacity-75 border-k2-gray-400  bg-clip-padding backdrop-filter backdrop-blur-xs hover:border-k2-gray-200 px-3 py-3 text-sm text-center text-white flex items-center justify-center`,
        ratio === currentAspectRatio
          ? `${colors.elevation.surface.highlight} ${colors.text.default}`
          : colors.text.default,
      )}
      onClick={() => onRatioChange(ratio)}
      type='button'
      style={{
        width,
        height,
      }}
    >
      {ratio} {ratioSettingConfig.label}
    </button>
  )
}
