import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import { useUserAccountContext } from '../context/userContext'
import {
  updateCurrentUser,
  updateOnboardingCompleted,
} from '../services/AuthService'
import http from '../services/HttpService'
import logo from '../images/welcome/logo.png'
import onboardingWelcomeDesktop from '../images/onboarding-desktop-welcome.png'
import onboardingWelcomeMobile from '../images/onboarding-mobile-welcome.png'
import onboardingFinishDesktop from '../images/onboarding-finish-desktop.png'
import onboardingFinishMobile from '../images/onboarding-finish-mobile.png'
import googleIcon from '../images/icons/google-icon.png'
import tiktokIcon from '../images/icons/tiktok-icon.png'
import youtubeIcon from '../images/icons/youtube-icon.png'
import instagramIcon from '../images/icons/instagram-icon.png'
import facebookIcon from '../images/icons/facebook-icon.png'
import twitterIcon from '../images/icons/twitter-icon.png'
import { FormInput } from './FormInput'
import { isMobile } from '../utils'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { Button } from './_deprecated/Button'
import { cn } from '../utils'

let socialMediaList = [
  {
    name: 'Google',
    icon: googleIcon,
  },
  {
    name: 'TikTok',
    icon: tiktokIcon,
  },
  {
    name: 'YouTube',
    icon: youtubeIcon,
  },
  {
    name: 'Instagram',
    icon: instagramIcon,
  },
  {
    name: 'Facebook',
    icon: facebookIcon,
  },
  {
    name: 'Twitter',
    icon: twitterIcon,
  },
  {
    name: 'Friend or Colleague',
    emoji: '🤝',
  },
  {
    name: 'News',
    emoji: '📰',
  },
  {
    name: 'Other',
    emoji: '🔍',
  },
]

let typeOfWorkList = [
  {
    name: 'Social media content creator',
    emoji: '📱',
  },
  {
    name: 'Filmmaker',
    emoji: '🎥',
  },
  {
    name: 'Videographer / Photographer',
    emoji: '📷',
  },
  {
    name: 'Musician / Producer',
    emoji: '🎵',
  },
  {
    name: 'Artist (Designer, Animator, Illustrator)',
    emoji: '🎨',
  },
  {
    name: 'Student',
    emoji: '🎓',
  },
  {
    name: 'Educator',
    emoji: '🏫',
  },
  {
    name: 'Software Engineer',
    emoji: '💻',
  },
  {
    name: 'Marketer',
    emoji: '💡',
  },
  {
    name: 'AI enthusiast',
    emoji: '🎉',
  },
  {
    name: 'Other',
    emoji: '🔍',
  },
]

let typeOfUseCasesList = [
  {
    name: 'Music video / visulizer',
    emoji: '📺',
  },
  {
    name: 'Film',
    emoji: '🎞',
  },
  {
    name: 'Animation',
    emoji: '🎬',
  },
  {
    name: 'Game',
    emoji: '🎮',
  },
  {
    name: 'Social Media Content',
    emoji: '✨',
  },
  {
    name: 'Website visualizer',
    emoji: '🌐',
  },
  {
    name: 'For fun',
    emoji: '🥳',
  },
  {
    name: 'Other',
    emoji: '🔍',
  },
]

const skillLevelList = [
  {
    name: 'Beginner',
  },
  {
    name: 'Intermediate',
  },
  {
    name: 'Advanced',
  },
]

function Onboarding({ id }) {
  const {
    currentUser,
    isAuthenticated,
    setCurrentUser,
    isOnboardingCompleted,
    featureToggles,
  } = useUserAccountContext()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()
  const [activeIndex, setActiveIndex] = useState(0)
  const [selectedOption1, setSelectedOption1] = useState(null)
  const [selectedOption2, setSelectedOption2] = useState([])
  const [primaryOption, setPrimaryOption] = useState(null)
  const [selectedOption3, setSelectedOption3] = useState([])
  const [selectedOption4, setSelectedOption4] = useState(null)

  const [firstName, setFirstName] = useState(currentUser?.firstName ?? '')
  const [lastName, setLastName] = useState(currentUser?.lastName ?? '')
  const [username, setUsername] = useState(currentUser?.username ?? '')

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [usernameError, setUsernameError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isSkippingOnboarding, setIsSkippingOnboarding] = useState(false)

  const refScroll0 = useRef(null)
  const refScroll1 = useRef(null)
  const refScroll2 = useRef(null)
  const refScroll3 = useRef(null)
  const refScroll4 = useRef(null)
  const refScroll5 = useRef(null)
  const refScroll6 = useRef(null)

  const [ref0] = useInView({ threshold: 0.5 })
  const [ref1] = useInView({ threshold: 0.5 })
  const [ref2] = useInView({ threshold: 0.5 })
  const [ref3] = useInView({ threshold: 0.5 })
  const [ref4] = useInView({ threshold: 0.5 })
  const [ref5] = useInView({ threshold: 0.5 })
  const [ref6] = useInView({ threshold: 0.5 })

  const profileFeatureToggle = featureToggles['feature-user-profiles']
  const randomizeOnboardingOptionsFeatureToggle =
    featureToggles['randomize-onboarding-options']

  const UPDATE_INFORMATION_ERROR_MESSAGES = {
    INVALID_NAME_CHARS:
      'First and last name can only contain letters and hyphens',
    NAME_LENGTH: 'Name must be between 2 and 32 characters.',
    INVALID_USERNAME_LENGTH: 'Username must be between 4 and 32 characters.',
    INVALID_USERNAME_CHARS:
      'Username can only contain lowercase letters, numbers, underscores, and periods.',
    USERNAME_TAKEN: 'This username is already taken.',
  }

  const nameRegex = /^[a-zA-Z-]+$/
  const usernameRegex = /^[a-z0-9_.]+$/

  useEffect(() => {
    if (isAuthenticated) mixpanel.identify(currentUser.distinctId)

    if (randomizeOnboardingOptionsFeatureToggle) {
      shuffleArrayKeepLast(socialMediaList)
      shuffleArrayKeepLast(typeOfWorkList)
      shuffleArrayKeepLast(typeOfUseCasesList)
    }
  }, [
    currentUser.distinctId,
    isAuthenticated,
    mixpanel,
    randomizeOnboardingOptionsFeatureToggle,
  ])

  useEffect(() => {
    if (isOnboardingCompleted) {
      setTimeout(() => {
        navigate('/dashboard')
      }, 100)
    }
  }, [isOnboardingCompleted, navigate])

  function validateFirstName(firstName) {
    if (!firstName.match(nameRegex)) {
      toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_NAME_CHARS)
      setFirstNameError(true)
      return false
    }
    if (firstName.length > 32 || firstName.length < 2) {
      toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.NAME_LENGTH)
      setFirstNameError(true)
      return false
    }
    return true
  }

  function validateLastName(lastName) {
    // If there is a last name, check that it isn't less 2 or greater 32
    if (lastName === '') return true
    if (!lastName.match(nameRegex)) {
      toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_NAME_CHARS)
      setLastNameError(true)
      return false
    }

    if (lastName.length > 32 || lastName.length < 2) {
      toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.NAME_LENGTH)
      setLastNameError(true)
      return false
    }
    return true
  }

  function validateUsername(username) {
    if (username === '') return true

    if (username.length > 26 || username.length < 4) {
      toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_USERNAME_LENGTH)
      setUsernameError(true)
      return false
    }
    if (!username.match(usernameRegex)) {
      toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.INVALID_USERNAME_CHARS)
      setUsernameError(true)
      return false
    }
    return true
  }

  function handleFirstNameChange({ target: { value } }) {
    setFirstNameError(false)
    setFirstName(value.trim())
  }

  function handleLastNameChange({ target: { value } }) {
    setLastNameError(false)
    setLastName(value.trim())
  }

  function handleUsernameChange({ target: { value } }) {
    setUsernameError(false)
    setUsername(value.trim())
  }

  const nextSlide = async () => {
    switch (activeIndex) {
      case 1:
        // TODO: Add name to mixpanel

        // This is to show all toast in a single submit try, instead of 1 by 1
        const isFirstNameValid = validateFirstName(firstName)
        const isLastNameValid = validateLastName(lastName)

        let isUsernameValid = true
        if (profileFeatureToggle) {
          isUsernameValid = validateUsername(username)
        }

        if (!isFirstNameValid || !isLastNameValid || !isUsernameValid) return
        try {
          setIsLoading(true)
          const updateUserData = {
            firstName,
            lastName,
          }
          if (profileFeatureToggle) {
            if (username === '') {
              const response = await http.get('/api/generate_username')
              updateUserData.username = response.data.username
            } else {
              updateUserData.username = username
            }
          }

          await updateCurrentUser(updateUserData)

          setCurrentUser({
            ...currentUser,
            ...updateUserData,
          })
          setIsLoading(false)
        } catch (error) {
          if (error.response.data.code === 'INPUT_VALIDATION_ERROR') {
            if (error.response.data.errors?.username) {
              toast.error(UPDATE_INFORMATION_ERROR_MESSAGES.USERNAME_TAKEN)
            } else {
              toast.error(`Error updating information: ${error.message}`)
            }
          } else if (error.response.data.code === 'NSFW_CONTENT_ERROR') {
            toast.error(`Error updating information: ${error.message}`)
          } else {
            toast.error(`Something went wrong: ${error.message}`)
          }
          setIsLoading(false)
          return
        }
        break
      case 2:
        mixpanel.people.set('discovery', socialMediaList[selectedOption1].name)
        http.put('/api/active_campaign/fieldValue', {
          email: currentUser.email,
          fieldName: 'discovery',
          value: socialMediaList[selectedOption1].name,
        })
        break
      case 3:
        mixpanel.set_group(
          'work',
          selectedOption2.map((index) => typeOfWorkList[index].name),
        )
        http.put('/api/active_campaign/fieldValue', {
          email: currentUser.email,
          fieldName: 'work',
          value: selectedOption2
            .map((index) => typeOfWorkList[index].name)
            .join(', '),
        })
        break
      case 4:
        mixpanel.people.set(
          'primary_use',
          typeOfUseCasesList[primaryOption].name,
        )
        http.put('/api/active_campaign/fieldValue', {
          email: currentUser.email,
          fieldName: 'primaryUse',
          value: typeOfUseCasesList[primaryOption].name,
        })
        mixpanel.set_group(
          'secondary_use',
          selectedOption3.map((index) => typeOfUseCasesList[index].name),
        )
        http.put('/api/active_campaign/fieldValue', {
          email: currentUser.email,
          fieldName: 'secondaryUse',
          value: selectedOption3
            .map((index) => typeOfUseCasesList[index].name)
            .join(', '),
        })
        break
      case 5:
        mixpanel.people.set('skill_level', skillLevelList[selectedOption4].name)
        http.put('/api/active_campaign/fieldValue', {
          email: currentUser.email,
          fieldName: 'skillLevel',
          value: skillLevelList[selectedOption4].name,
        })
        break
      case 6:
        const response = await updateOnboardingCompleted()

        setCurrentUser({
          ...currentUser,
          onboardingCompletedAt: response?.data.user.onboardingCompletedAt,
        })
        break
      default:
        break
    }

    setActiveIndex(activeIndex + 1)
  }

  const handleMultiSelectOption1 = (index) => {
    setSelectedOption1(index)
  }

  const handleMultiSelectOption2 = (index) => {
    if (selectedOption2.includes(index)) {
      setSelectedOption2(selectedOption2.filter((item) => item !== index))
    } else {
      if (selectedOption2.length >= 2) return

      setSelectedOption2([...selectedOption2, index])
    }
  }

  const handleMultiSelectOption3 = (index) => {
    if (selectedOption3.includes(index)) {
      // eslint-disable-next-line eqeqeq
      if (primaryOption == index) setPrimaryOption(null)
      setSelectedOption3(selectedOption3.filter((item) => item !== index))
    } else {
      // eslint-disable-next-line eqeqeq
      if (primaryOption == null) setPrimaryOption(index)
      setSelectedOption3([...selectedOption3, index])
    }
  }

  const handleMultiSelectOption4 = (index) => {
    setSelectedOption4(index)
  }

  function shuffleArrayKeepLast(array) {
    // keep the last element as last (used for 'Other', randomize the rest)
    const last = array.pop()
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    array.push(last)
  }

  async function handleSkip() {
    try {
      const newUserData = {
        skippedOnboarding: true,
      }
      setIsSkippingOnboarding(true)
      await updateCurrentUser(newUserData)
      setCurrentUser({
        ...currentUser,
        ...newUserData,
      })
      setIsSkippingOnboarding(false)
      navigate('/dashboard')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div
      id={id}
      className='w-full h-full onboardingBg flex justify-center items-center'
    >
      {/* <Link to='/' className='relative'>
                        <img src={logo} alt='Kaiber Logo' />
                      </Link> */}

      {/* PARALLAX/CAROUSEL FEATURES */}
      <div className=' w-full h-full md:w-[95%] max-w-[1179px] md:h-[675px] relative bg-black rounded-2xl overflow-hidden'>
        <div
          key={0}
          className={`h-[calc(100%-100px)] relative transition-opacity duration-500 ${
            activeIndex === 0 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll0} className=''>
            <div
              ref={ref0}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)] justify-center'
            >
              <img
                src={logo}
                alt='Kaiber Logo'
                className='md:absolute top-[30px] left-[30px] max-w-[233px] md:max-w-[172px]'
              />
              <img
                src={onboardingWelcomeDesktop}
                alt='Welcome to Kaiber'
                className='hidden md:block max-w-[475px] lg:max-w-full absolute right-0 bottom-[130px] lg:bottom-[34px]'
              />

              <div
                className='visible md:hidden w-full h-[300px] bg-[length:700px] bg-center bg-no-repeat'
                style={{ backgroundImage: `url(${onboardingWelcomeMobile})` }}
              ></div>

              <div className='md:absolute md:lg:bottom-[40px] md:bottom-[130px] md:left-[70px]'>
                <h1 className='text-white text-center md:text-left lg:text-5xl'>
                  Welcome to Kaiber!
                </h1>
                <p className='text-white text-center md:text-left text-base lg:text-xl lg:max-w-[400px] md:max-w-[260px] w-full p-2 md:p-0 mt-[18px] pt-0'>
                  We’d like to ask you three questions to customize your
                  experience
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          key={1}
          className={`h-full absolute top-0 transition-opacity duration-500 w-full ${
            activeIndex === 1 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll1} className='w-full h-full'>
            <div
              ref={ref1}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)] justify-center'
            >
              <div
                className={clsx(
                  'grid gap-3 mt-6 md:mt-32 lg:mt-32 justify-center mx-12',
                  {
                    'grid-rows-4': isMobile() && profileFeatureToggle,
                    'grid-rows-3': !isMobile() || !profileFeatureToggle,
                  },
                )}
              >
                <div className='my-6'>
                  <h1 className='text-tertiary'>
                    Tell us a little more about you
                  </h1>
                </div>
                {/* Names inputs */}
                {!isMobile() && profileFeatureToggle ? (
                  <div className='flex grid-cols-2 gap-3'>
                    <FormInput
                      name='firstName'
                      label='First name'
                      error={firstNameError}
                      handleChange={handleFirstNameChange}
                      type='text'
                      placeHolder='Enter your first name'
                      value={firstName}
                      required={true}
                      disabled={isLoading}
                    />
                    <FormInput
                      name='lastName'
                      label='Last name'
                      error={lastNameError}
                      handleChange={handleLastNameChange}
                      type='text'
                      placeHolder='Enter your last name'
                      value={lastName}
                      required={false}
                      disabled={isLoading}
                    />
                  </div>
                ) : (
                  <>
                    <FormInput
                      name='firstName'
                      label='First name'
                      error={firstNameError}
                      handleChange={handleFirstNameChange}
                      type='text'
                      placeHolder='Enter your first name'
                      value={currentUser?.firstName ?? ''}
                      required={true}
                      disabled={isLoading}
                    />
                    <FormInput
                      name='lastName'
                      label='Last name'
                      error={lastNameError}
                      handleChange={handleLastNameChange}
                      type='text'
                      placeHolder='Enter your last name'
                      value={currentUser?.lastName ?? ''}
                      required={false}
                      disabled={isLoading}
                    />
                  </>
                )}

                {/* Username input */}
                {profileFeatureToggle && (
                  <FormInput
                    name='username'
                    label='Username'
                    error={usernameError}
                    handleChange={handleUsernameChange}
                    type='text'
                    placeHolder='Enter your username'
                    value={username}
                    required={false}
                    disabled={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          key={2}
          className={`h-[calc(100%-100px)] absolute top-0 w-full transition-opacity duration-500 ${
            activeIndex === 2 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll2} className=''>
            <div
              ref={ref2}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)]'
            >
              <img
                src={logo}
                alt='Kaiber Logo'
                className='hidden md:block max-w-[233px] md:max-w-[172px] mt-[37px] ml-[48px]'
              />

              <div className='md:h-[430px] md:overflow-y-scroll pt-[80px] md:pt-[60px] flex flex-col justify-center items-center w-full no-scrollbar'>
                <div className='text-center flex flex-col items-center mb-[40px] md:mb-[30px] justify-center px-[10px]'>
                  <h1 className='text-white text-[24px] md:text-3xl'>
                    How did you discover us?
                  </h1>
                  <p className='text-white text-xs mt-[15px]'>Select one</p>
                </div>

                {/* rows of buttons in a 3x3 grid */}
                <div className='inline-flex flex-start flex-wrap w-[95%] md:max-w-[800px]  justify-between md:justify-center md:gap-4 overflow-y-scroll h-[calc(100vh-290px)] md:h-[calc(100vh-328px)] lg:h-full no-scrollbar content-start md:content-center'>
                  {socialMediaList.map((item, index) => (
                    <button
                      key={index}
                      className={`w-[calc(50%-5px)] md:w-[32%] h-[114px] md:h-[80px] mb-[10px] md:mb-0 font-semibold border border-kaiberGreen rounded-[12px] shadow flex flex-col items-center justify-center md:flex-row p-[10px] ${
                        selectedOption1 === index
                          ? 'bg-primary text-black'
                          : 'text-kaiberGreen'
                      }`}
                      onClick={() => handleMultiSelectOption1(index)}
                    >
                      {item.icon && (
                        <div className='w-[40px] mb-[10px] md:mb-0 md:w-[50px] md:ml-[30px]'>
                          <img src={item.icon} alt='Social Icon' />
                        </div>
                      )}

                      {item.emoji && (
                        <div className='text-[30px] w-[40px] mb-[10px] md:mb-0 md:w-[50px] md:ml-[10px]'>
                          {item.emoji}
                        </div>
                      )}

                      <div className='md:w-[calc(100%-50px)] text-center text-[12px] md:text-base'>
                        {item.name}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          key={3}
          className={`h-[calc(100%-100px)] absolute top-0 w-full transition-opacity duration-500 ${
            activeIndex === 3 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll3} className=''>
            <div
              ref={ref3}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)]'
            >
              <img
                src={logo}
                alt='Kaiber Logo'
                className='hidden md:block max-w-[233px] md:max-w-[172px] mt-[37px] ml-[48px]'
              />

              <div className='md:h-[430px] md:overflow-y-scroll pt-[80px] md:pt-[60px] flex flex-col justify-center items-center w-full no-scrollbar'>
                <div className='text-center flex flex-col items-center mb-[40px] md:mb-[30px] justify-center px-[10px]'>
                  <h1 className='text-white text-[24px] md:text-3xl'>
                    What best defines the type of work you do?
                  </h1>
                  <p className='text-white text-xs mt-[15px]'>
                    Select up to two options
                  </p>
                </div>

                {/* rows of buttons in a 3x3 grid */}
                <div className='inline-flex flex-start flex-wrap w-[95%] md:max-w-[1000px]  justify-between md:justify-center md:gap-4 overflow-y-scroll h-[calc(100vh-323px)] md:h-[calc(100vh-328px)] no-scrollbar content-start md:content-center'>
                  {typeOfWorkList.map((item, index) => (
                    <button
                      key={index}
                      className={`w-[calc(50%-5px)] md:w-[23%] h-[114px] md:h-[80px] mb-[10px] md:mb-0 font-semibold border border-kaiberGreen rounded-[12px] shadow flex flex-col items-center justify-center md:flex-row p-[10px] ${
                        selectedOption2.includes(index)
                          ? 'bg-primary text-black'
                          : 'text-primary'
                      }`}
                      onClick={() => handleMultiSelectOption2(index)}
                    >
                      {item.emoji && (
                        <div className='text-[30px] w-[40px] mb-[10px] md:mb-0 md:w-[50px] md:ml-[10px]'>
                          {item.emoji}
                        </div>
                      )}

                      <div className='md:w-[calc(100%-50px)] text-center text-[12px] md:text-base'>
                        {item.name}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          key={4}
          className={`h-[calc(100%-100px)] absolute top-0 w-full transition-opacity duration-500 ${
            activeIndex === 4 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll4} className=''>
            <div
              ref={ref4}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)]'
            >
              <img
                src={logo}
                alt='Kaiber Logo'
                className='hidden md:block max-w-[233px] md:max-w-[172px] mt-[37px] ml-[48px]'
              />

              <div className='md:h-[430px] md:overflow-y-scroll pt-[80px] md:pt-[60px] flex flex-col justify-center items-center w-full no-scrollbar'>
                <div className='text-center flex flex-col items-center mb-[40px] md:mb-[30px] justify-center px-[10px]'>
                  <h1 className='text-white text-[24px] md:text-3xl'>
                    What are your main and secondary use cases Kaiber?
                  </h1>
                  <p className='text-white text-xs mt-[15px]'>
                    Choose multiple. The first choice you select will be
                    considered your main use case
                  </p>
                </div>

                {/* rows of buttons in a 3x3 grid */}
                <div className='inline-flex flex-start flex-wrap w-[95%] md:max-w-[800px]  justify-between md:justify-center md:gap-4 overflow-y-scroll h-[calc(100vh-338px)] md:h-[calc(100vh-328px)] no-scrollbar content-start md:content-center'>
                  {typeOfUseCasesList.map((item, index) => (
                    <button
                      key={index}
                      className={`w-[calc(50%-5px)] md:w-[32%] h-[114px] md:h-[80px] mb-[10px] md:mb-0 font-semibold border border-kaiberGreen rounded-[12px] shadow flex flex-col items-center justify-center md:flex-row p-[10px] ${
                        // eslint-disable-next-line eqeqeq
                        primaryOption == index
                          ? 'bg-primary text-black'
                          : selectedOption3.includes(index)
                            ? 'bg-kaiberPurple border-kaiberPurple text-black'
                            : 'text-kaiberGreen'
                      }`}
                      onClick={() => handleMultiSelectOption3(index)}
                    >
                      {item.emoji && (
                        <div className='text-[30px] w-[40px] mb-[10px] md:mb-0 md:w-[50px] md:ml-[10px]'>
                          {item.emoji}
                        </div>
                      )}

                      <div className='md:w-[calc(100%-50px)] text-center text-[12px] md:text-base'>
                        {(() => {
                          return item.name === 'Music video / visulizer'
                            ? 'Music video / visualizer'
                            : item.name
                        })()}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          key={5}
          className={`h-[calc(100%-100px)] absolute top-0 w-full transition-opacity duration-500 ${
            activeIndex === 5 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll5} className=''>
            <div
              ref={ref5}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)]'
            >
              <img
                src={logo}
                alt='Kaiber Logo'
                className='hidden md:block max-w-[233px] md:max-w-[172px] mt-[37px] ml-[48px]'
              />

              <div className='md:h-[430px] md:overflow-y-scroll pt-[80px] md:pt-[60px] flex flex-col justify-center items-center w-full no-scrollbar'>
                <div className='text-center flex flex-col items-center mb-[40px] md:mb-[30px] justify-center px-[10px]'>
                  <h1 className='text-white text-[24px] md:text-3xl'>
                    How would you rate your skills in using generative AI tools?
                  </h1>
                </div>

                {/* rows of buttons in a 3x3 grid */}
                <div className='inline-flex flex-start flex-wrap w-[95%] md:max-w-[800px] justify-between md:justify-center md:gap-4 overflow-y-scroll h-[calc(100vh-290px)] md:h-[calc(100vh-328px)] lg:h-full no-scrollbar items-center content-start md:content-center'>
                  {skillLevelList.map((item, index) => (
                    <button
                      key={index}
                      className={`w-[95%] md:w-[32%] h-[114px] md:h-[80px] mb-[10px] md:mb-0 font-semibold border border-kaiberGreen rounded-[12px] shadow flex flex-col items-center justify-center md:flex-row p-[10px] ${
                        selectedOption4 === index
                          ? 'bg-primary text-black'
                          : 'text-kaiberGreen'
                      }`}
                      onClick={() => handleMultiSelectOption4(index)}
                    >
                      <div className='md:w-[calc(100%-50px)] text-center text-[12px] md:text-base'>
                        {item.name}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          key={6}
          className={`h-full absolute top-0 transition-opacity duration-500 w-full ${
            activeIndex === 6 ? 'opacity-100' : 'opacity-0 invisible'
          }`}
        >
          <div ref={refScroll6} className='w-full h-full'>
            <div
              ref={ref6}
              className='flex-col flex items-center gap-6 md:block h-[calc(100vh-100px)] justify-center'
            >
              <img
                src={logo}
                alt='Kaiber Logo'
                className='hidden md:block md:absolute top-[30px] left-[30px] max-w-[233px] md:max-w-[172px]'
              />
              <img
                src={onboardingFinishDesktop}
                alt='Welcome to Kaiber'
                className='hidden md:block lg:max-w-full absolute left-0 bottom-0 lg:bottom-0'
              />

              <div
                className='block md:hidden w-full h-[400px] bg-[length:380px] bg-center bg-no-repeat'
                style={{ backgroundImage: `url(${onboardingFinishMobile})` }}
              ></div>

              <div className='md:absolute md:lg:bottom-[40px] md:flex md:justify-end md:w-full md:h-full md:items-center'>
                <div className='md:w-[45%]'>
                  <h1 className='text-white text-center md:text-left text-4xl md:text-5xl'>
                    Thank you!
                  </h1>
                  <p className='text-white text-center md:text-left text-base md:text-xl md:max-w-[400px] w-full p-2 md:p-0 mt-[18px] pt-0'>
                    We’re excited to see what you create.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[100px]'>
          <div className='flex w-[95%] justify-center md:relative h-12 items-center m-auto max-w-[1400px]'>
            <div className='flex gap-4 absolute md:relative top-[10px] w-[98%] md:w-[200px] justify-around md:justify-center'>
              {activeIndex < 6 && (
                <>
                  <div
                    key={0}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 0 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                  <div
                    key={1}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 1 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                  <div
                    key={2}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 2 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                  <div
                    key={3}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 3 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                  <div
                    key={4}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 4 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                  <div
                    key={5}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 5 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                  <div
                    key={6}
                    className={`w-1/4 h-2 md:h-3 md:w-3 rounded-full ${
                      activeIndex === 6 ? 'bg-primary' : 'bg-[#303030]'
                    }`}
                  />
                </>
              )}
            </div>
            {activeIndex === 0 && !currentUser.skippedOnboarding && (
              <Button
                onClick={handleSkip}
                className='md:absolute left-0 mt-3 mr-3'
              >
                {isSkippingOnboarding ? 'Loading...' : 'Skip'}
              </Button>
            )}
            <button
              type='submit'
              className={cn(
                'p-3 w-full md:mt-0 md:w-[117px] text-base font-bold text-black border rounded-full shadow-sm bg-kaiberGreen border-primary relative md:absolute right-0',
                {
                  '!bg-[#000] text-kaiberGreen':
                    (activeIndex === 1 && firstName.trim().length === 0) ||
                    (activeIndex === 2 && selectedOption1 == null) ||
                    (activeIndex === 3 && selectedOption2.length < 1) ||
                    (activeIndex === 4 &&
                      primaryOption == null &&
                      selectedOption3.length === 0),
                  'mt-[60px]': activeIndex !== 0,
                  'mt-3': activeIndex === 0,
                },
              )}
              // disabled conditional depending on the activeIndex
              disabled={
                (activeIndex === 2 && selectedOption1 == null) ||
                (activeIndex === 3 && selectedOption2.length < 1) ||
                (activeIndex === 4 &&
                  primaryOption === null &&
                  selectedOption3.length === 0) ||
                (activeIndex === 5 && selectedOption4 == null)
              }
              onClick={nextSlide}
            >
              {isLoading ? 'Loading...' : activeIndex === 6 ? 'Finish' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding
