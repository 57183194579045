import { Modal } from './Modal'

function AskEditSettingsModal({
  handleEditSettings,
  handleGenerateVideo,
  isOpen,
  onClose,
}) {
  return (
    <Modal open={isOpen} setOpen={onClose}>
      <div className='w-full px-6 py-6 sm:px-12 sm:py-10 text-kaiberGreen'>
        <p className='mb-2 text-2xl sm:text-4xl'>Edit Settings?</p>
        <p className=''>
          Since you're reusing the previous settings,{'\n'}
          we encourage you to skip this step.
        </p>
        <div className='flex items-center justify-end gap-4 mt-8'>
          <button
            type='button'
            className='w-full gap-2 px-3 py-[6px] text-sm font-bold text-center bg-transparent border rounded-full shadow-sm text-kaiberGreen hover:opacity-100 opacity-[.85] border-kaiberGreen transition ease-in-out duration-300'
            onClick={handleEditSettings}
          >
            Edit Settings
          </button>
          <button
            type='button'
            className='w-full gap-2 px-3 py-[6px] text-sm font-bold text-center text-black border rounded-full shadow-sm bg-kaiberGreen border-kaiberGreen hover:opacity-100 opacity-[.85] transition ease-in-out duration-300'
            onClick={handleGenerateVideo}
          >
            Preview Frames
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AskEditSettingsModal
