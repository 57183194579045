import axios from 'axios'
import { setAuthTokens, removeAuthTokens, getAuthTokens } from './AuthService'
import { Logtail } from '@logtail/browser'

const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN)

/**
 * @see https://axios-http.com/docs/interceptors
 */
axios.interceptors.request.use(
  (config) => {
    const { token, refreshToken } = getAuthTokens()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (refreshToken) {
      config.headers['X-Refresh-Token'] = refreshToken
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorCode = error?.response?.data?.code
    const statusCode = error?.response?.status

    if (errorCode === 'TOKEN_EXPIRED') {
      removeAuthTokens()
      window.location.href = '/login'
      return Promise.reject(error)
    }
    if (statusCode === 401 && !error.config._retry) {
      try {
        const { token, refreshToken } = getAuthTokens()
        if (!token || !refreshToken) {
          removeAuthTokens()
          return Promise.reject(error)
        }
        const res = await axios.post('/api/auth/refresh', {
          token,
          refreshToken,
        })

        setAuthTokens({
          token: res.data.token,
          refreshToken: res.data.refreshToken,
        })

        error.config._retry = true

        return axios(error.config)
      } catch (err) {
        console.error(err)
        return Promise.reject(err)
      }
    }
    if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
      try {
        logtail.info(
          `${error.config.headers?.Authorization} felog ${error.config.url} error`,
          {
            user: error.config.headers?.Authorization,
            error: error,
            message: error.message,
            level: 'network_error',
          },
        )
        logtail.flush()
      } catch (err) {
        console.error(err)
      }
    }
    return Promise.reject(error)
  },
)

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['X-Timezone-Offset'] =
  new Date().getTimezoneOffset()
axios.defaults.headers.common['X-Platform'] = 'web'
axios.interceptors.response.use(
  async function (response) {
    if (response?.data?.state === 'forbidden') {
      // update users usePaymentGateway to stripe as they are forbidden from Paddle
      await axios.patch('/api/auth/me', {
        usePaymentGateway: 'stripe',
      })
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)

export function setAuthorization(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export function unsetAuthorization() {
  delete axios.defaults.headers.common['Authorization']
}

export default axios
