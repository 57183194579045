// Analytics

/**
 * Analytics Events and Actions
 *
 * Event:
 * An occurrence or interaction that we want to track in our analytics system.
 * Events are represented by the AnalyticsEvent enum and include things like
 * 'Canvas Loaded', 'Element Added', 'Media Uploaded', etc.
 *
 * Action:
 * A specific type or category of an event that provides additional context.
 * Actions are typically represented by separate enums (e.g., CanvasLoadedAction)
 * and are used as properties within event data to further classify the event.
 * For example, the 'Canvas Loaded' event can have actions like 'restored_version'
 * or 'switched_canvas'. This indicates the origin of the action usually.
 *
 * Events are the high-level occurrences we track, while actions provide
 * more detailed information about how or why an event occurred.
 */

export enum AnalyticsEvent {
  CanvasLoaded = 'Canvas Loaded',
  CanvasUpdated = 'Canvas Updated',
  ElementAdded = 'Element Added',
  ElementRemoved = 'Element Removed',
  MediaDownloaded = 'Media Downloaded',
  MediaProcessed = 'Media Processed',
  MediaUploaded = 'Media Uploaded',
  NodeAdded = 'Node Added',
  NodeRemoved = 'Node Removed',
}

export enum CanvasLoadedAction {
  RestoredVersion = 'restored_version',
  SwitchedCanvas = 'switched_canvas',
}

export enum MediaUploadSource {
  CanvasUpload = 'canvas_upload',
  ImageUpload = 'image_upload',
  VideoUpload = 'video_upload',
  CollectionUpload = 'collection_upload',
}

export enum NodeOrigin {
  // AI and Automation
  GeneratedMedia = 'generated_media',

  // Flows and Presets
  AddedFlow = 'added_flow',
  AddedPreset = 'added_preset',
  RecreatedFlow = 'recreated_flow',

  // Media Imports
  DragAndDropMedia = 'drag_and_drop_media',
  PastedMedia = 'paste_media',

  // User Actions
  DuplicatedNode = 'duplicated_node',
  PastedNode = 'pasted_node',

  // Collection
  CreatedCollectionFromSelection = 'created_collection_from_selection',
  CreatedCollectionFromSingleNode = 'created_collection_from_single_node',
}

export interface BaseElementEventProperties extends BaseNodeEventProperties {
  elementType: string
}

export interface BaseEventProperties {
  canvasId: string
  data: Record<any, any>
  platform: 'web' | 'mobile'
  subscriptionType: string
  userUuid: string
}

export interface BaseMediaEventProperties extends BaseEventProperties {
  mediaId: string
  mediaType: string
  modelType?: string
}

export interface BaseNodeEventProperties extends BaseEventProperties {
  modelType: string
  nodeId: string
  nodeType: string
  position: { x: number; y: number }
}

interface CanvasLoadedProperties extends BaseEventProperties {
  action: CanvasLoadedAction
}

interface CanvasUpdatedProperties extends BaseEventProperties {
  updatedFields: Record<string, any>
}

interface MediaDownloadedProperties extends BaseMediaEventProperties {
  fileSize: number
}

interface MediaProcessedProperties extends BaseMediaEventProperties {
  nodeId: string
}

interface MediaUploadedProperties extends BaseMediaEventProperties {
  fileSize: number
  fileType: string
  uploadSource: string
}

interface NodeAddedProperties extends BaseNodeEventProperties {
  nodeOrigin: NodeOrigin
}

export type EventProperties = {
  [AnalyticsEvent.CanvasLoaded]: CanvasLoadedProperties
  [AnalyticsEvent.CanvasUpdated]: CanvasUpdatedProperties
  [AnalyticsEvent.ElementAdded]: BaseElementEventProperties
  [AnalyticsEvent.ElementRemoved]: BaseElementEventProperties
  [AnalyticsEvent.MediaDownloaded]: MediaDownloadedProperties
  [AnalyticsEvent.MediaProcessed]: MediaProcessedProperties
  [AnalyticsEvent.MediaUploaded]: MediaUploadedProperties
  [AnalyticsEvent.NodeAdded]: NodeAddedProperties
  [AnalyticsEvent.NodeRemoved]: BaseNodeEventProperties
  // Add other event types as needed
}
