import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { AiFillCaretDown } from 'react-icons/ai'
import { HiOutlineX } from 'react-icons/hi'

export const SceneDropdown = ({
  numberOfScenes,
  activeScene,
  setActiveScene,
  lastCompletedScene,
  onTopLevelClick = () => true,
  onDelete = undefined,
  isLoadingPreview,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const showDeleteButton = !!onDelete

  // close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current?.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const sceneNumbers = Array.from(
    { length: numberOfScenes },
    (_, index) => index + 1,
  )

  const handleTopLevelClick = () => {
    if (onTopLevelClick()) {
      setIsOpen(!isOpen)
    }
  }

  const handleSelectScene = (event) => {
    const selectedValue = parseInt(event.target.dataset.value)
    setIsOpen(false)
    setActiveScene(selectedValue)
  }

  return (
    <div className='relative inline-block' ref={ref}>
      <div className='flex'>
        <button
          className='relative z-20 flex items-center justify-center w-[10.25rem] py-1 text-black shadow bg-kaiberPurple'
          onClick={handleTopLevelClick}
          disabled={numberOfScenes === 1 || isLoadingPreview}
        >
          <span>Scene {activeScene}</span>
          {numberOfScenes > 1 && (
            <AiFillCaretDown
              className={classNames('transition-transform ml-4', {
                'rotate-180': isOpen,
              })}
            />
          )}
        </button>

        {showDeleteButton && activeScene > 1 && (
          <button
            className='flex items-center ml-4'
            onClick={() => {
              onDelete()
            }}
            disabled={isLoadingPreview}
          >
            <HiOutlineX
              className={classNames(
                'flex w-8 h-8 text-kaiberPurple border-kaiberPurple border-[1px] rounded-full p-0.5',
                {
                  'opacity-60': isLoadingPreview,
                  'hover:bg-kaiberPurple hover:text-black cursor-pointer':
                    !isLoadingPreview,
                },
              )}
            />
          </button>
        )}
      </div>

      {isOpen && (
        <div className='absolute left-0 z-10'>
          <div className='w-[10.25rem] pt-9 -mt-8 shadow bg-kaiberPurple rounded-[19px] overflow-hidden'>
            {sceneNumbers.map((sceneNumber, i) => (
              <button
                key={`scene-${sceneNumber}`}
                className='block w-full px-4 py-1 text-center hover:bg-[#ada6d9] select-none'
                onClick={(event) => handleSelectScene(event)}
                data-value={sceneNumber}
              >
                Scene {sceneNumber}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
