import { XYPosition } from '@xyflow/react'

import { FileWithSource, PresetFlow, DropType } from '@/types'
import { Collection } from '@kaiber/shared-types'
import { getProxiedR2FileUrl, initializeFileFromBlob } from './fileUtils'
import { TEXT_URI_LIST } from '../constants'

type HandleFileDrop = (
  files: FileWithSource[],
  event?: React.DragEvent<HTMLDivElement>,
) => void

type HandlePresetDrop = (
  preset: PresetFlow,
  name: string,
  event: React.DragEvent<HTMLDivElement>,
) => void

type HandleCollectionDrop = (
  collection: Collection,
  event: React.DragEvent<HTMLDivElement>,
) => void

interface DragAndDropHandlers {
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragEnd: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragEnter: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragLeave: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragStart: (event: React.DragEvent<HTMLDivElement>) => void
}

interface UseDndnHandlersProps {
  handleFileDrop?: HandleFileDrop
  handlePresetDrop?: HandlePresetDrop
  handleCollectionDrop?: HandleCollectionDrop
}

export const useDndHandlers = ({
  handleFileDrop,
  handlePresetDrop,
  handleCollectionDrop,
}: UseDndnHandlersProps): DragAndDropHandlers => {
  const processItems = async (
    files: FileList | null,
    event: React.DragEvent<HTMLDivElement>,
  ): Promise<FileWithSource[]> => {
    if (files?.length) {
      return Array.from(files).map((file) => ({
        file,
        source: URL.createObjectURL(file),
      }))
    } else {
      const imageUrl = event.dataTransfer.getData(TEXT_URI_LIST)
      const corsProxyImageUrl = getProxiedR2FileUrl(imageUrl)

      try {
        const response = await fetch(corsProxyImageUrl)
        const blob = await response.blob()
        const file = await initializeFileFromBlob(blob)
        return [{ file, source: imageUrl }]
      } catch (error) {
        console.error('Error fetching file:', error)
        return []
      }
    }
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const dropType = event.dataTransfer.getData('type')

    if (dropType === DropType.Preset && handlePresetDrop) {
      const presetData = event.dataTransfer.getData(DropType.Preset)
      const preset = JSON.parse(presetData) as PresetFlow
      handlePresetDrop(preset, preset.name, event)
    } else if (dropType === DropType.Collection && handleCollectionDrop) {
      const collectionData = event.dataTransfer.getData(DropType.Collection)
      const collection = JSON.parse(collectionData) as Collection
      handleCollectionDrop(collection, event)
    } else if (handleFileDrop) {
      const files = event.dataTransfer.files
      const fileWithSources = await processItems(files, event)
      handleFileDrop(fileWithSources, event)
    }
  }

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  return {
    handleDrop,
    handleDragEnd,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDragStart,
  }
}

/**
 *  Calculate the distance from the dragged Node referencePosition to the center of the droppable element
 */
export const findNearestDroppableElement = (
  referencePosition: XYPosition,
  selector: string = '[data-droppable]',
): Element | null => {
  const droppables = document.querySelectorAll(selector)
  let nearestDroppableElement: Element | null = null
  let minDistanceSquared = Infinity

  for (const el of droppables) {
    const rect = el.getBoundingClientRect()
    const centerX = rect.left + rect.width / 2
    const centerY = rect.top + rect.height / 2

    // Calculate distance squared (avoid square root for performance)
    const distanceSquared =
      Math.pow(referencePosition.x - centerX, 2) +
      Math.pow(referencePosition.y - centerY, 2)

    if (distanceSquared < minDistanceSquared) {
      minDistanceSquared = distanceSquared
      nearestDroppableElement = el

      // Early exit if we're already at distance 0
      if (minDistanceSquared === 0) break
    }
  }

  return nearestDroppableElement
}

export type DropData = {
  type?: string
  [key: string]: string
}

/**
 * Dispatch a custom drop event to the target
 */
export const dispatchDropEvent = (
  target: Element,
  position: XYPosition,
  data: DropData,
) => {
  const dataTransfer = new DataTransfer()

  Object.entries(data).forEach(([key, value]) => {
    dataTransfer.setData(key, value)
  })

  const dropEvent = new DragEvent('drop', {
    bubbles: true,
    cancelable: true,
    clientX: position.x,
    clientY: position.y,
  })

  Object.defineProperty(dropEvent, 'dataTransfer', {
    value: dataTransfer,
    writable: false,
  })

  target.dispatchEvent(dropEvent)
}

/**
 * Grabs the index of the droppable element in the list of droppable elements
 */
export const getDroppableIndex = (droppableElement: Element): number => {
  return parseInt(droppableElement.getAttribute('data-index'), 10)
}
