import { useCallback, useEffect, useState } from 'react'
import { usePostHog } from 'posthog-js/react'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../../utils/mixpanel'

import { useUserAccountContext } from '../../context/userContext'
import RatingEmoji from './RatingEmoji'
import http from '../../services/HttpService'
import { isMobile as isMobileScreen } from '../../utils'

export const Survey = () => {
  //for single choice/rating surveys
  const [selectedValue, setSelectedValue] = useState(null)
  //for multi choice surveys
  const [selectedValues, setSelectedValues] = useState([])
  const [showSurvey, setShowSurvey] = useState(false)
  const [surveyTitle, setSurveyTitle] = useState(false)
  const [surveyId, setSurveyId] = useState(null)
  const [currentSurvey, setCurrentSurvey] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [userAnswer, setUserAnswer] = useState(null)
  const [answers, setAnswers] = useState([])
  const [nextQuestionIndex, setNexQuestionIndex] = useState(null)
  const [surveyConfig, setSurveyConfig] = useState(null)
  const [isUserQueued, setIsUserQueued] = useState(null)
  const [isQueueFull, setIsQueueFull] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const { currentUser, featureToggles } = useUserAccountContext()
  const enableNpsSurvey =
    featureToggles['survey-targeting-net-promoter-score-nps']

  const posthog = usePostHog()
  const mixpanel = useMixpanel()

  const handleResize = useCallback(() => {
    setIsMobile(checkMobile())
  }, [])

  useEffect(() => {
    setIsMobile(checkMobile())

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const checkMobile = () => {
    const isMobileSize = isMobileScreen()
    const userAgent = window.navigator.userAgent
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent,
      ) ||
      isMobileSize
    ) {
      return true
    } else {
      //this is for iPad Prod
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      )
    }
  }

  useEffect(() => {
    if (currentUser?.distinctId) {
      const fetchSurveyConfig = async () => {
        try {
          const res = await http.get('/api/get_survey_config')
          sessionStorage.setItem('surveyConfig', JSON.stringify(res.data))
          setSurveyConfig(res.data)
        } catch (err) {
          setSurveyConfig(null)
        }
      }
      if (!sessionStorage.getItem('surveyConfig')) fetchSurveyConfig()
      else setSurveyConfig(JSON.parse(sessionStorage.getItem('surveyConfig')))
    }
  }, [currentUser?.distinctId])

  const userQueued = useCallback(async () => {
    try {
      if (surveyId && !isUserQueued && !isQueueFull) {
        const res = await http.post('/api/add_to_survey_queue', {
          surveyId,
        })

        if (res.data) {
          setIsUserQueued(res.data.isInQueue)
          setIsQueueFull(res.data.isQueueFull)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [surveyId, isUserQueued, isQueueFull])

  const addUserToQueue = useCallback(async () => {
    //check if user apply to enter in survey queue
    if (surveyId && !showSurvey && !isMobile) {
      let lastFilledSurvey = null
      try {
        const res = await http.get('/api/get_last_survey_filled')
        if (res.data && res.data.created) {
          lastFilledSurvey = res.data
        }
      } catch (err) {
        console.error(err)
      }

      if (currentUser) {
        const now = new Date(Date.now())
        let userCreatedAt = new Date(currentUser.createdAt)
        let minCreatedDate = new Date(
          now.setDate(now.getDate() - surveyConfig?.MIN_USER_CREATED_DAYS),
        )
        let minLastCompletionDate = new Date(
          now.setMonth(
            now.getMonth() - surveyConfig?.MONTHS_SINCE_LAST_COMPLETION,
          ),
        )
        let lastFilledSurveyDate = null

        if (lastFilledSurvey) {
          lastFilledSurveyDate = new Date(lastFilledSurvey.created)
        }

        if (
          userCreatedAt <= minCreatedDate &&
          (!lastFilledSurvey ||
            (lastFilledSurvey &&
              lastFilledSurveyDate <= minLastCompletionDate)) &&
          currentUser.memoriesCount >= surveyConfig?.MIN_USER_CREATED_VIDEOS &&
          currentUser.loginCount >= surveyConfig?.MIN_USER_SESSIONS
        ) {
          userQueued()
        }
      }
    }
  }, [
    currentUser,
    showSurvey,
    surveyConfig?.MIN_USER_CREATED_DAYS,
    surveyConfig?.MIN_USER_CREATED_VIDEOS,
    surveyConfig?.MIN_USER_SESSIONS,
    surveyConfig?.MONTHS_SINCE_LAST_COMPLETION,
    surveyId,
    userQueued,
    isMobile,
  ])

  useEffect(() => {
    addUserToQueue()
  }, [surveyId, addUserToQueue])

  const updateQueue = useCallback(
    async (status) => {
      try {
        await http.post('/api/update_survey_queue', {
          surveyId,
          status,
        })

        if (status !== 'presented') {
          resetValues()
        }
      } catch (err) {
        console.log(err)
      }
    },
    [surveyId],
  )

  const toggleSurvey = useCallback(async () => {
    if (surveyId && !showSurvey && !isMobile) {
      if (surveyId === surveyConfig.ID) {
        let userInQueue = null
        try {
          const res = await http.get('/api/get_survey_queue')
          if (res.data && res.data.queueData) {
            userInQueue = res.data.queueData
          }
        } catch (err) {
          console.error(err)
        }

        if (userInQueue && userInQueue.status === 'queued') {
          setSurveyTitle(currentSurvey?.questions[0].question)
          setCurrentQuestion(currentSurvey?.questions[0])
          setShowSurvey(true)
          posthog.capture('survey seen', {
            $survey_id: surveyId, // required
            distinctId: currentUser.distinctId,
          })

          await updateQueue('presented')

          return
        }
      }
    }

    setShowSurvey(false)
  }, [
    currentSurvey?.questions,
    currentUser,
    posthog,
    showSurvey,
    surveyId,
    updateQueue,
    surveyConfig?.ID,
    isMobile,
  ])

  useEffect(() => {
    const setSurveys = (survey) => {
      setSurveyId(survey.id)
      setSurveyTitle(survey.questions[0].question)
      setCurrentSurvey(survey)
      setCurrentQuestion(survey.questions[0])
    }

    if (posthog && enableNpsSurvey) {
      if (currentUser && currentUser.distinctId) {
        posthog.identify(currentUser.distinctId, {
          email: currentUser.email,
        })
      }

      if (!sessionStorage.getItem('surveys'))
        posthog.getActiveMatchingSurveys((surveys) => {
          const filteredSurveys = surveys.filter(
            (survey) =>
              survey.type === 'api' &&
              surveyConfig &&
              survey.id === surveyConfig.ID,
          )

          sessionStorage.setItem('surveys', JSON.stringify(filteredSurveys))

          if (filteredSurveys.length > 0 && !surveyId) {
            const survey = filteredSurveys[0]
            setSurveys(survey)
          }
        }, true)
      else {
        const surveys = JSON.parse(sessionStorage.getItem('surveys'))
        if (surveys.length > 0 && !surveyId) {
          const survey = surveys[0]
          setSurveys(survey)
        }
      }
    }

    window.addEventListener('toggleSurvey', toggleSurvey)

    return () => {
      window.removeEventListener('toggleSurvey', toggleSurvey)
    }
  }, [
    posthog,
    currentUser,
    surveyConfig,
    enableNpsSurvey,
    toggleSurvey,
    surveyId,
  ]) // posthog may be undefined until it's had a chance to initialize. Hence use it as a dependency for useEffect

  const handleSelect = (value) => {
    setSelectedValue(value)
  }

  const handleAddValue = (value) => {
    let newSelectedValues = [...selectedValues]
    if (newSelectedValues.includes(value)) {
      const index = newSelectedValues.indexOf(value)
      if (index !== -1) {
        newSelectedValues.splice(index, 1)
      }
    } else {
      newSelectedValues.push(value)
    }

    setSelectedValues(newSelectedValues)
  }

  const handleSubmit = (value) => {
    let responseValue = null
    if (
      currentQuestion.type === 'rating' ||
      currentQuestion.type === 'single_choice'
    ) {
      responseValue = selectedValue
    } else if (currentQuestion.type === 'open') {
      responseValue = userAnswer
    } else if (currentQuestion.type === 'multiple_choice') {
      responseValue = selectedValues
    }

    if (surveyId === surveyConfig.ID) {
      if (currentQuestion === currentSurvey.questions[0]) {
        let nextQuestion
        if (responseValue >= 0 && responseValue <= 6) {
          nextQuestion = 1
        } else if (responseValue >= 7 && responseValue <= 8) {
          nextQuestion = 2
        } else {
          nextQuestion = 3
        }
        setSurveyTitle(currentSurvey.questions[nextQuestion].question)
        setCurrentQuestion(currentSurvey.questions[nextQuestion])
        setNexQuestionIndex(nextQuestion)
      }

      let newAnswers = [...answers]
      newAnswers.push(responseValue)
      setAnswers(newAnswers)
      if (newAnswers.length === 2) {
        let surveyData = {
          $survey_id: surveyId,
          $survey_response: newAnswers[0],
          distinctId: currentUser.distinctId,
        }

        surveyData[`$survey_response_${nextQuestionIndex}`] = newAnswers[1]
        setShowSurvey(false)
        posthog.capture('survey sent', surveyData)

        trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.USER_NPS_SURVEY, {
          nps_score: newAnswers[0],
          nps_feedback: newAnswers[1],
          nps_survey_id: surveyId,
          nps_dismissed: false,
        })

        updateQueue('submitted')
      }
      return
    }

    if (responseValue != null && responseValue.length > 0) {
      setShowSurvey(false)
      posthog.capture('survey sent', {
        $survey_id: surveyId,
        $survey_response: responseValue,
        distinctId: currentUser.distinctId,
      })

      trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.USER_NPS_SURVEY, {
        nps_score: responseValue,
        nps_feedback: null,
        nps_survey_id: surveyId,
        nps_dismissed: false,
      })

      updateQueue('submitted')

      window.localStorage.setItem(`hasInteractedWithSurvey_${surveyId}`, 'true')
    }
  }

  const handleDismiss = () => {
    setShowSurvey(false)
    posthog.capture('survey dismissed', {
      $survey_id: surveyId, // required
      distinctId: currentUser.distinctId,
    })
    trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.USER_NPS_SURVEY, {
      nps_score: null,
      nps_feedback: null,
      nps_survey_id: surveyId,
      nps_dismissed: true,
    })

    updateQueue('skipped')
    window.localStorage.setItem(`hasInteractedWithSurvey_${surveyId}`, 'true')
  }

  const resetValues = () => {
    setSelectedValue(null)
    setSelectedValues([])
    setUserAnswer(null)
    setAnswers([])
    setNexQuestionIndex(null)
    setIsUserQueued(null)
    setIsQueueFull(null)
  }

  const handleChange = (event) => {
    setUserAnswer(event.target.value)
  }

  return (
    <div>
      {showSurvey && !isMobile && currentQuestion && (
        <div className='survey-popup'>
          <h3 className='text-secondary'>{surveyTitle}</h3>
          {currentQuestion && currentQuestion.type === 'multiple_choice' ? (
            <div className='mt-5'>
              {currentQuestion.choices.map((item, i) => (
                <button
                  className={`border-solid text-lg w-full rounded-md border-2 border-gray-200 py-2 my-2 ${
                    selectedValues.includes(item) ? 'bg-primary' : ''
                  }`}
                  key={i + 1}
                  onClick={() => handleAddValue(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          ) : null}
          {currentQuestion && currentQuestion.type === 'single_choice' ? (
            <div className='mt-5'>
              {currentQuestion.choices.map((item, i) => (
                <button
                  className={`border-solid text-lg w-full rounded-md border-2 border-gray-200 py-2 my-2 ${
                    selectedValue === item ? 'bg-primary' : ''
                  }`}
                  key={i + 1}
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          ) : null}
          {currentQuestion &&
          currentQuestion.type === 'rating' &&
          currentQuestion.display !== 'emoji' ? (
            <div>
              <div className='mt-5 flex justify-center'>
                {[...Array(currentQuestion.scale)].map((_, i) => (
                  <button
                    className={`border-solid mr-[4px] border-2 border-gray-200 px-4 ${
                      selectedValue === i + 1 ? 'bg-primary' : ''
                    }`}
                    key={i + 1}
                    onClick={() => handleSelect(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
              <div className='flex justify-between justify-items-center mt-5'>
                <span>{currentQuestion.lowerBoundLabel}</span>
                <span>{currentQuestion.upperBoundLabel}</span>
              </div>
            </div>
          ) : null}
          {currentQuestion && currentQuestion.type === 'open' ? (
            <div className='mt-5'>
              <textarea
                type='text'
                rows={3}
                name='userAnswer'
                id='userAnswer'
                defaultValue={''}
                maxLength={150}
                onChange={handleChange}
                className='resize-none block mt-4 w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 pl-3 bg-[#545854]/25 text-black'
              />
            </div>
          ) : null}
          {currentQuestion &&
          currentQuestion.type === 'rating' &&
          currentQuestion.display === 'emoji' ? (
            <div>
              <div className='mt-5 flex justify-content justify-items-center'>
                {[...Array(currentQuestion.scale)].map((_, i) => (
                  <button
                    className='m-auto'
                    key={i + 1}
                    onClick={() => handleSelect(i + 1)}
                  >
                    <RatingEmoji
                      scale={currentQuestion.scale}
                      index={i + 1}
                      selectedValue={selectedValue}
                    />
                  </button>
                ))}
              </div>
              <div className='flex justify-between justify-items-center mt-5'>
                <span>{currentQuestion.lowerBoundLabel}</span>
                <span>{currentQuestion.upperBoundLabel}</span>
              </div>
            </div>
          ) : null}
          <div className='flex space-x-2 mt-1 items-center'>
            <button
              className='mt-4 ml-2 inline-flex items-center justify-center px-3 py-2 font-medium leading-5 text-secondary duration-300 buttonPrompt w-fit '
              onClick={handleDismiss}
            >
              Dismiss
            </button>
            <button
              className='mt-4 mr-2 inline-flex items-center justify-center px-3 py-2 font-medium leading-5 text-black duration-300 rounded-full shadow-sm bg-primary hover:bg-primary/90 active:bg-primary/40'
              onClick={handleSubmit}
            >
              {currentQuestion && currentQuestion.buttonText
                ? currentQuestion.buttonText
                : 'Submit'}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
