import { ReactFlowState, useStore } from '@xyflow/react'
import { CSSProperties, useEffect, useRef } from 'react'

import { DEFAULT_THEME } from '../../../constants'
import { useThemeContext } from '../../../context'

const canvasStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 10,
  pointerEvents: 'none',
}

const storeSelector = (state: ReactFlowState) => ({
  width: state.width,
  height: state.height,
  transform: state.transform,
})

export type HelperLinesProps = {
  horizontal?: number
  vertical?: number
  opacity?: number
  dashPattern?: number[]
}

export const HelperLines = ({
  horizontal,
  vertical,
  opacity = 0.5,
  dashPattern = [5, 5],
}: HelperLinesProps) => {
  const { width, height, transform } = useStore(storeSelector)
  const { activeTheme } = useThemeContext()

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')

    if (!ctx || !canvas) {
      return
    }

    const dpi = window.devicePixelRatio
    canvas.width = width * dpi
    canvas.height = height * dpi

    ctx.scale(dpi, dpi)
    ctx.clearRect(0, 0, width, height)

    if (typeof vertical === 'number' || typeof horizontal === 'number') {
      ctx.strokeStyle =
        activeTheme === DEFAULT_THEME.Dark
          ? `rgba(255, 255, 255, ${opacity})`
          : `rgba(0, 0, 0, ${opacity})`
      ctx.lineWidth = 1
      ctx.setLineDash(dashPattern)

      if (typeof vertical === 'number') {
        ctx.beginPath()
        ctx.moveTo(vertical * transform[2] + transform[0], 0)
        ctx.lineTo(vertical * transform[2] + transform[0], height)
        ctx.stroke()
      }

      if (typeof horizontal === 'number') {
        ctx.beginPath()
        ctx.moveTo(0, horizontal * transform[2] + transform[1])
        ctx.lineTo(width, horizontal * transform[2] + transform[1])
        ctx.stroke()
      }
    }
  }, [width, height, transform, horizontal, vertical, opacity, dashPattern])

  return (
    <canvas
      ref={canvasRef}
      className='react-flow__canvas'
      style={canvasStyle}
    />
  )
}
