import { useEffect, useState } from 'react'
import classNames from 'classnames'
import Lottie from 'lottie-react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { RiArrowRightDownLine } from 'react-icons/ri'
import { TbCoins } from 'react-icons/tb'

import { ConfigsSummary } from './ConfigsSummary'
import { PromptDisplay } from './PromptDisplay'
import { useUserAccountContext } from '../context/userContext'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import http from '../services/HttpService'
import {
  MAX_NUMBER_OF_SCENES,
  MOTION,
  VIDEO_TYPES_AND_VERSIONS,
} from '../utils/constants'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'
import { getProxiedR2FileUrl } from '../utils/fileUtils'

const INITIAL_IMAGES = {
  0: '',
  1: '',
  2: '',
  3: '',
}

const {
  TRANSFORM_V1,
  TRANSFORM_V2,
  MOTION_V1,
  MOTION_V2,
  MOTION_V3,
  FLIPBOOK_V1,
} = VIDEO_TYPES_AND_VERSIONS

function FramesPreview({
  memoryId,
  handleGoBackToPrompt,
  handleConfirmPreviewFrames,
  previewFrames,
  isLoadingMemoryId,
  prompt,
  subject,
  artStyle,
  selectedFrame,
  selectFrame,
  activeScene,
  numberOfScenes,
  length,
  isMobile,
  aspectRatio,
  cameraMovementNames,
  onAddStoryboard,
  timestamp,
  canAddScene = true,
  audioUrl,
  showAspectRatio,
  isLoadingPreview,
  handleNextCategory,
  handleNextCategoryMobile,
  hasAudio,
  hasVideo,
  hasImage,
  isNonSubscribedUserWithCredits,
  selectedVideoType,
  allowSkipPreviewFrames = false,
  creditsCost,
  typeAndVersion,
  isUploading,
  videoFPS,
  isUploadingMedia,
}) {
  const { currentUser } = useUserAccountContext()
  const [images, setImages] = useState(INITIAL_IMAGES)
  const [downloadButtonState, setDownloadButtonState] = useState(false)
  const mixpanel = useMixpanel()

  const inspiration_preview_frames_only =
    (activeScene > 1 && typeAndVersion !== MOTION_V1) ||
    (typeAndVersion === TRANSFORM_V2 && !isLoadingPreview)

  const can_create_video =
    (!isUploading &&
      ((selectedFrame &&
        !isLoadingPreview &&
        !selectedFrame.includes('init-images/')) ||
        inspiration_preview_frames_only ||
        (allowSkipPreviewFrames && !isLoadingMemoryId))) ||
    (typeAndVersion === TRANSFORM_V2 && videoFPS && !isUploadingMedia)

  const disable_storyboard =
    isLoadingPreview ||
    (!inspiration_preview_frames_only && !selectedFrame) ||
    !canAddScene ||
    isNonSubscribedUserWithCredits ||
    (typeAndVersion === MOTION_V1 && hasImage) ||
    typeAndVersion === MOTION_V3 ||
    (typeAndVersion === MOTION_V2 && hasImage)

  const can_add_scene =
    canAddScene &&
    (typeAndVersion === FLIPBOOK_V1 ||
      typeAndVersion === TRANSFORM_V1 ||
      (typeAndVersion === MOTION_V2 && !hasImage))

  const preview_is_video =
    previewFrames != null &&
    Object.keys(previewFrames).length > 0 &&
    Object.values(previewFrames)[0].includes('.mp4')

  useEffect(() => {
    if (Object.keys(previewFrames).length) {
      setImages(previewFrames)
    }
    if (previewFrames != null && Object.keys(previewFrames).length === 0) {
      setImages(INITIAL_IMAGES)
    }
  }, [previewFrames])

  const goBackToPrompt = () => {
    handleGoBackToPrompt()
  }

  const downloadFrame = async (base64Data, index) => {
    setDownloadButtonState(true)
    // use proxy server to get around CORS errors with R2
    base64Data = getProxiedR2FileUrl(base64Data)

    // Fetch the video as a Blob using axios
    const response = await http({
      url: base64Data,
      method: 'GET',
      responseType: 'blob', // Tell axios to return the response as a Blob
      transformRequest: (data, headers) => {
        delete headers.Authorization
        return data
      },
    })
    setDownloadButtonState(false)
    const blob = response.data

    // Create a link element
    const link = document.createElement('a')

    link.href = URL.createObjectURL(blob)

    // Set the download attribute and the file name
    link.download = prompt + ' ' + index + '.png'

    // Add the link to the document
    document.body.appendChild(link)

    // Click the link to start the download
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)
  }

  const Loading = () => (
    <>
      <div className='object-cover w-full h-[235px] rounded-full' />
      <div className='absolute inset-0 bg-black bg-opacity-75 rounded-full'>
        <div className='flex flex-col items-center justify-center w-full h-full backdrop-blur-sm'>
          <div className='flex flex-col items-center space-y-4'>
            <div className='p-4 bg-darkGray rounded-3xl'>
              <Lottie
                animationData={LoadingWhite}
                loop={true}
                className='w-10 h-10'
              />
            </div>
          </div>
          <p className='mt-4 text-sm text-white'>Generating preview frame...</p>
        </div>
      </div>
    </>
  )

  const DownloadButton = ({ imageId, index }) => (
    <button
      disabled={downloadButtonState}
      onClick={(e) => {
        downloadFrame(images[imageId], index + 1)

        trackEvent(
          mixpanel,
          currentUser,
          MIXPANEL_EVENTS.PREVIEW_FRAME_DOWNLOAD,
          {
            memory_id: memoryId,
            video_type: selectedVideoType,
            preview_frame_selected: imageId,
            page_url: window.location.origin,
          },
        )
        e.preventDefault()
      }}
      // disabled={!images[imageId]}
      className='absolute p-1 m-4 rounded shadow-sm bottom-1 right-2 bg-primary hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
    >
      <ArrowDownTrayIcon className='w-6 h-6 text-black' />
    </button>
  )

  return (
    <div className='mt-[30px]'>
      {isMobile && (
        <button
          onClick={() => {
            goBackToPrompt()
          }}
          className='pr-2 transition-all text-primary hover:bg-primary-light hover:text-dark'
        >
          <div className='flex'>
            <div className='bg-kaiberGreen rounded-full w-[20px] h-[20px] flex items-center justify-center mr-[10px]'>
              <IoMdArrowRoundBack className='inline w-10 h-10 cursor-pointer text-black' />
            </div>
            <span className='text-sm font-bold sm:text-base'>
              BACK TO PROMPT
            </span>
          </div>
        </button>
      )}

      <div className='flex flex-col grid-cols-8 gap-0 md:grid mt-0'>
        {/* COLUMN LEFT **/}
        <div className='order-2 col-span-5 sm:order-1'>
          <div className='flex flex-col pt-2 pb-10 sm:px-6 sm:py-10 text-quaternary'>
            {!isMobile && <span className='mb-5'>PREVIEW FRAMES</span>}
            <div className='mb-5'>
              <h1 className='mb-10 text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
                {!inspiration_preview_frames_only ? (
                  <>
                    {/* Select the best image for {'\n'} the{' '} */}
                    Select your favorite preview
                    {/* <span className='underline'>keyframe</span> of your video. */}
                  </>
                ) : preview_is_video && inspiration_preview_frames_only ? (
                  <>
                    Final video will have higher resolution than preview frames
                    below.
                  </>
                ) : (
                  <>
                    Preview image will be used as {'\n'}
                    <span className='underline'>inspiration</span> for Scene{' '}
                    {activeScene}.
                  </>
                )}
              </h1>

              <ul className='flex flex-wrap justify-center max-w-4xl gap-4 mx-auto md:flex-nowrap'>
                <div className='w-full lg:w-1/2'>
                  {Object.keys(images)
                    .filter((_, index) =>
                      inspiration_preview_frames_only || hasVideo
                        ? index === 0
                        : index % 2 === 0,
                    )
                    .map((imageId, index) => (
                      <div
                        className='relative flex justify-center mb-8'
                        key={`canvas-${imageId}`}
                      >
                        {!!images[imageId] ? (
                          <>
                            {!preview_is_video && (
                              <img
                                alt={`preview-${imageId}`}
                                className={classNames(
                                  'object-cover transition-[max-height] duration-500 ease-in-out border-4',
                                  selectedFrame === imageId ||
                                    inspiration_preview_frames_only
                                    ? `md:w-full w-72 max-h-[580px] aspect-[${aspectRatio}] rounded-2xl`
                                    : 'w-full max-h-[235px] rounded-2xl',
                                  {
                                    'cursor-pointer':
                                      !inspiration_preview_frames_only,
                                    'cursor-default':
                                      inspiration_preview_frames_only,
                                    'border-primary': selectedFrame === imageId,
                                    'border-transparent':
                                      inspiration_preview_frames_only ||
                                      selectedFrame !== imageId,
                                  },
                                )}
                                src={images[imageId]}
                                onClick={() => {
                                  if (!inspiration_preview_frames_only)
                                    selectFrame(imageId)
                                }}
                              />
                            )}
                            {preview_is_video && (
                              <video
                                preload='metadata'
                                controls={false}
                                loop={true}
                                muted={true}
                                playsInline={true}
                                autoPlay={true}
                                className={classNames(
                                  'object-cover transition-[max-height] duration-500 ease-in-out border-2',
                                  selectedFrame === imageId ||
                                    inspiration_preview_frames_only
                                    ? `md:w-full w-72 max-h-[580px] aspect-[${aspectRatio}] rounded-2xl`
                                    : 'w-full max-h-[235px] rounded-full',
                                  {
                                    'cursor-pointer':
                                      !inspiration_preview_frames_only,
                                    'cursor-default':
                                      inspiration_preview_frames_only,
                                    'border-primary': selectedFrame === imageId,
                                    'border-transparent':
                                      inspiration_preview_frames_only ||
                                      selectedFrame !== imageId,
                                  },
                                )}
                                onClick={() => {
                                  if (!inspiration_preview_frames_only)
                                    selectFrame(imageId)
                                }}
                              >
                                <source
                                  src={images[imageId]}
                                  type='video/mp4'
                                />
                              </video>
                            )}

                            <DownloadButton imageId={imageId} index={index} />
                          </>
                        ) : (
                          <Loading />
                        )}
                      </div>
                    ))}
                </div>

                {((activeScene === 1 && typeAndVersion !== TRANSFORM_V2) ||
                  typeAndVersion === MOTION_V1) && (
                  <div className='w-full lg:w-1/2 right-column lg:mt-[13%]'>
                    {Object.keys(images)
                      .filter((_, index) =>
                        inspiration_preview_frames_only || hasVideo
                          ? index === 1
                          : index % 2 !== 0,
                      )
                      .map((imageId, index) => (
                        <div
                          className='relative flex justify-center mb-8'
                          key={`canvas-${imageId}`}
                        >
                          {!!images[imageId] ? (
                            <>
                              {!preview_is_video && (
                                <img
                                  alt={`preview-${imageId}`}
                                  className={classNames(
                                    'object-cover transition-[max-height] duration-500 ease-in-out border-4',
                                    selectedFrame === imageId ||
                                      inspiration_preview_frames_only
                                      ? `md:w-full w-72 max-h-[580px] aspect-[${aspectRatio}] rounded-2xl`
                                      : 'w-full max-h-[235px] rounded-2xl',
                                    {
                                      'cursor-pointer':
                                        !inspiration_preview_frames_only,
                                      'cursor-default':
                                        inspiration_preview_frames_only,
                                      'border-primary':
                                        selectedFrame === imageId,
                                      'border-transparent':
                                        inspiration_preview_frames_only ||
                                        selectedFrame !== imageId,
                                    },
                                  )}
                                  src={images[imageId]}
                                  onClick={() => {
                                    if (!inspiration_preview_frames_only)
                                      selectFrame(imageId)
                                  }}
                                />
                              )}
                              {preview_is_video && (
                                <video
                                  preload='metadata'
                                  controls={false}
                                  loop={true}
                                  muted={true}
                                  playsInline={true}
                                  autoPlay={true}
                                  className={classNames(
                                    'object-cover transition-[max-height] duration-500 ease-in-out border-2',
                                    selectedFrame === imageId ||
                                      inspiration_preview_frames_only
                                      ? `md:w-full w-72 max-h-[580px] aspect-[${aspectRatio}] rounded-2xl`
                                      : 'w-full max-h-[235px] rounded-full',
                                    {
                                      'cursor-pointer':
                                        !inspiration_preview_frames_only,
                                      'cursor-default':
                                        inspiration_preview_frames_only,
                                      'border-primary':
                                        selectedFrame === imageId,
                                      'border-transparent':
                                        inspiration_preview_frames_only ||
                                        selectedFrame !== imageId,
                                    },
                                  )}
                                  onClick={() => {
                                    if (!inspiration_preview_frames_only)
                                      selectFrame(imageId)
                                  }}
                                >
                                  <source
                                    src={images[imageId]}
                                    type='video/mp4'
                                  />
                                </video>
                              )}

                              <DownloadButton imageId={imageId} index={index} />
                            </>
                          ) : (
                            <Loading />
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </ul>
            </div>

            {/* Scene Keyframe Preview - MOBILE */}
            {isMobile && (
              <div className='flex flex-col xl:justify-between xl:flex-row mb-8'>
                {!isLoadingPreview && selectedVideoType !== MOTION && (
                  <div
                    className={classNames(
                      'relative opacity-0 transition ease-in-out duration-300',
                      {
                        'opacity-100 block':
                          !!previewFrames[selectedFrame] || activeScene > 1,
                        hidden:
                          !previewFrames[selectedFrame] && activeScene === 1,
                      },
                    )}
                  >
                    <div className='flex items-center justify-center h-20 overflow-hidden border w-36 border-primary rounded-2xl z-[1] absolute'>
                      <img
                        src={
                          activeScene === 1
                            ? previewFrames[selectedFrame]
                            : Object.values(previewFrames)[0]
                        }
                        alt='Storyboard thumbnail'
                        className='w-full h-auto'
                      />
                    </div>
                    <div className='absolute bg-[#9B92D0] h-20 w-44 text-3xl text-black flex justify-end items-center rounded-2xl p-2 select-none'>
                      {activeScene}
                    </div>
                  </div>
                )}

                {numberOfScenes < MAX_NUMBER_OF_SCENES && (
                  <button
                    className={classNames(
                      'sm:inline-flex border border-[#9B92D0] w-fit items-center rounded-2xl h-16 xl:mt-8 bg-black pl-3 pr-5 py-1 text-xl font-semibold text-[#9B92D0] gap-2 mt-[130px]',
                      'disabled:opacity-50 disabled:text-gray-400 disabled:border-gray-400',
                      {
                        'hover:opacity-80 active:border-[#c7c1ee] active:text-[#c7c1ee] duration-100 mt-36':
                          !!selectedFrame,
                        'opacity-50': !selectFrame,
                      },
                    )}
                    onClick={onAddStoryboard}
                    disabled={disable_storyboard}
                  >
                    + Scene
                  </button>
                )}
              </div>
            )}
            {isMobile && !can_add_scene && (
              <div className='w-full bg-opacity-50 flex items-center justify-center mb-6'>
                <p className='text-white text-sm'>
                  The +Scene feature is only available on Flipbook, Motion 2.0
                  without an image and Transform 1.0
                </p>
              </div>
            )}

            {/* NEXT STEP - PREVIEW - MOBILE */}
            {isMobile && (
              <div className='flex items-center gap-2'>
                <div className='flex items-center justify-center w-8 h-8 rounded-full bg-secondary/10'>
                  <TbCoins className='w-4 text-[#CAD7CA]' />
                </div>
                <p className='text-gray-400'>
                  {' '}
                  Credits Cost: {creditsCost} / {currentUser?.credits}
                </p>
              </div>
            )}
            {typeAndVersion === TRANSFORM_V2 && isUploadingMedia && (
              <div className='mt-8 mb-8 sm:mt-24 text-primary font-medium text-sm sm:text-base px-8 transition-all'>
                <div className='flex flex-row justify-center items-center align-middle'>
                  <div className='p-4 bg-darkGray rounded-3xl'>
                    <Lottie
                      animationData={LoadingWhite}
                      loop={true}
                      className='w-10 h-10'
                    />
                  </div>
                  <span className='ml-2 text-lg'>Uploading media file</span>
                </div>
              </div>
            )}
            <button
              className={`${
                !can_create_video
                  ? 'opacity-50 '
                  : 'flex flex-row hover:scale-105 active:scale-95 transition-all '
              }sm:hidden inline-flex items-center gap-2 text-primary font-medium sm:mt-24 mt-2 text-sm sm:text-base`}
              onClick={handleConfirmPreviewFrames}
              disabled={!can_create_video}
            >
              <div className='w-full flex justify-center items-center bg-primary rounded-full px-4'>
                <img
                  className='w-25'
                  src='/button_create_mobile.png'
                  alt='Kaiber Create'
                />
                <span className='py-1 text-xl text-dark'>Create Video</span>
              </div>
            </button>
          </div>
        </div>

        {/* COLUMN RIGHT */}
        {!isMobile && (
          <div className='order-1 col-span-3 px-6 sm:order-2'>
            <div className='flex flex-col pt-10 pb-5 text-quaternary'>
              <h2 className='mb-5'>SUMMARY</h2>
              <PromptDisplay
                subject={subject}
                artStyle={artStyle}
                onBackClick={() => goBackToPrompt()}
                disabled={isLoadingPreview}
              />
            </div>

            {/* CONFIGS SUMMARY */}
            <ConfigsSummary
              activeScene={activeScene}
              timestamp={timestamp}
              aspectRatio={aspectRatio}
              length={length}
              cameraMovementNames={cameraMovementNames}
              audioUrl={audioUrl}
              showAspectRatio={showAspectRatio}
            />

            {/* Scene Keyframe Preview */}
            <div className='flex flex-col xl:justify-between xl:flex-row'>
              {!isLoadingPreview && selectedVideoType !== MOTION && (
                <div
                  className={classNames(
                    'relative opacity-0 transition ease-in-out duration-300 ',
                    {
                      'opacity-100 block ':
                        !!previewFrames[selectedFrame] || activeScene > 1,
                      'h-20 ': selectedVideoType === MOTION,
                      hidden:
                        !previewFrames[selectedFrame] && activeScene === 1,
                    },
                  )}
                >
                  <div className='flex items-center justify-center h-20 mt-8 overflow-hidden border w-36 border-primary rounded-2xl z-[1] absolute'>
                    <img
                      src={
                        activeScene === 1
                          ? previewFrames[selectedFrame]
                          : Object.values(previewFrames)[0]
                      }
                      alt='Storyboard thumbnail'
                      className='w-full h-auto'
                    />
                  </div>
                  <div className='absolute top-8 bg-[#9B92D0] h-20 w-44 text-3xl text-black flex justify-end items-center rounded-2xl p-2 select-none'>
                    {activeScene}
                  </div>
                </div>
              )}

              {numberOfScenes < MAX_NUMBER_OF_SCENES && (
                <button
                  className={classNames(
                    'sm:inline-flex border border-[#9B92D0] w-fit items-center rounded-2xl h-20 xl:mt-8 bg-black pl-3 pr-5 py-1 text-xl font-semibold text-[#9B92D0] mt-36 gap-2',
                    'disabled:opacity-50 disabled:text-gray-400 disabled:border-gray-400',
                    {
                      'hover:opacity-80 active:opacity-50 duration-100':
                        !!selectedFrame,
                      'opacity-50': !selectFrame,
                    },
                  )}
                  onClick={
                    !isNonSubscribedUserWithCredits ? onAddStoryboard : null
                  }
                  disabled={disable_storyboard}
                >
                  + Scene
                </button>
              )}
            </div>
            {!can_add_scene && (
              <div className='w-full bg-opacity-50 flex items-center justify-center mt-6'>
                <p className='text-white text-sm'>
                  The +Scene feature is only available on Flipbook, Motion 2.0
                  without an image and Transform 1.0
                </p>
              </div>
            )}

            {numberOfScenes > 1 &&
              activeScene > 1 &&
              (hasAudio || hasVideo) && (
                <button
                  className={classNames(
                    'inline-flex w-fit items-center rounded-full mt-8 bg-primary pl-3 pr-5 py-1 text-base font-semibold text-gray-900 gap-2',
                    !isLoadingPreview
                      ? 'hover:opacity-80 active:opacity-50 duration-100'
                      : 'bg-primary/25 text-black/50 cursor-not-allowed',
                  )}
                  onClick={() => {
                    handleNextCategoryMobile()
                  }}
                  disabled={isLoadingPreview}
                >
                  <RiArrowRightDownLine className='w-6 h-6 font-black' />
                  Adjust Timeline
                </button>
              )}

            {/* CREATE VIDEO BUTTON */}
            {typeAndVersion === TRANSFORM_V2 && isUploadingMedia && (
              <div className='mt-8 sm:mt-24 text-primary font-medium text-sm sm:text-base px-8 transition-all'>
                <div className='flex flex-row items-center align-middle'>
                  <div className='p-4 bg-darkGray rounded-3xl'>
                    <Lottie
                      animationData={LoadingWhite}
                      loop={true}
                      className='w-10 h-10'
                    />
                  </div>
                  <span className='ml-2 text-lg'>Uploading media file</span>
                </div>
              </div>
            )}
            <button
              className={`${
                !can_create_video
                  ? 'opacity-50 '
                  : 'flex sm:flex-row flex-col hover:scale-105 active:scale-95 transition-all '
              }sm:inline-flex hidden items-center justify-center gap-2 text-primary font-medium sm:mt-24 mt-20 text-sm sm:text-base px-8`}
              onClick={handleConfirmPreviewFrames}
              disabled={!can_create_video}
            >
              <img
                className='items-center w-64'
                src='/button_create.png'
                alt=''
              />
              <span className='text-lg'>Create Video</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FramesPreview
