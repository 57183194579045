function RelatedArticlesItem({ title, description, articleUrl }) {
  return (
    <div className='flex flex-col sm:text-xl text-lg'>
      <h2 className='text-white font-semibold'>{title}</h2>
      <div className='flex flex-wrap gap-3'>
        <p className='text-white/50'>{description}</p>
        <a
          href={articleUrl}
          target='_blank'
          className='text-primary hover:text-primary/90 active:text-primary/40 duration-300'
          rel='noreferrer'
        >
          read more
        </a>
      </div>
    </div>
  )
}

export default RelatedArticlesItem
