import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const RelatedSlider = ({ sliderContent, handleOnSlideSelected }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: 'unslick',
      },
    ],
  }

  let movieItem = []

  sliderContent.forEach(function (movie, i) {
    i = i + 1
    let item = (
      <div key={i}>
        {movie.memoryId ? (
          <div className='mb-[50px] md:mb-0'>
            {/* Link to video */}
            <div
              onClick={() => handleOnSlideSelected(movie.memoryId)}
              // href={`/share/${movie.memoryId}`}
              className='block w-full rounded-[10px] md:overflow-hidden md:aspect-[1.8] max-w-[100%] cursor-pointer'
            >
              <img
                className='object-cover object-center md:w-full h-full w-full sm:w-auto max-h-[500px] rounded-[10px]'
                src={movie.coverImageUrl}
                alt='cover'
              />
            </div>

            {/* Link to user */}
            <Link className='items-center hidden mt-[12px]'>
              <div className='w-[40px] h-[40px] rounded-[50%] bg-[#333] mr-[10px] overflow-hidden'>
                <img src={movie.userPictureUrl} alt='user pic' />
              </div>
              <div className='leading-[18px]'>
                <span className='text-[11px] text-[#7E7E89] font-bold uppercase'>
                  Made By
                </span>
                <p className='text-white text-[15px] font-bold'>
                  {movie.userName}
                </p>
              </div>
            </Link>
          </div>
        ) : (
          <div className='h-[149px] object-cover mb-4 rounded-xl bg-darkGray/25'></div>
        )}
      </div>
    )
    movieItem.push(item)
  })

  return (
    <Slider
      className='related-slider !h-auto !w-[100%] md:!w-auto'
      {...settings}
    >
      {movieItem}
    </Slider>
  )
}

export default RelatedSlider
