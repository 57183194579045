import MoreFeaturesItem from './MoreFeaturesItem'

const storyboardImg =
  'https://website-public-assets.kybercorp.org/web/features/feature_storyboard.png'
const galleryImg =
  'https://website-public-assets.kybercorp.org/web/features/feature_gallery.png'

function MoreFeatures() {
  return (
    <div className='flex flex-col gap-20'>
      <MoreFeaturesItem
        image={storyboardImg}
        title={'Storyboard'}
        description={'Design your narratives prompt-to-prompt with ease.'}
        pathUrl={'/create'}
        linkText={'Try Now'}
      />
      <MoreFeaturesItem
        image={galleryImg}
        title={'Gallery'}
        description={'Endless inspiration to fuel your creative journey.'}
        pathUrl={'/gallery'}
        linkText={'Gallery'}
        orderText={'sm:order-first'}
        orderImg={'sm:order-last'}
      />
    </div>
  )
}

export default MoreFeatures
