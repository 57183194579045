/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { ArrowRightIcon } from '@heroicons/react/20/solid'

import step5 from '../../images/HIW-5.png'
import step4 from '../../images/HIW-4.png'
import step3 from '../../images/HIW-3.png'
import step2 from '../../images/HIW-2.png'
import step1 from '../../images/HIW-1.png'
import { Link } from 'react-router-dom'

const steps = [
  {
    name: '1. Upload initial files',
    description:
      'Start with your own image, audio, or video to bring existing content to life',
    imageUrl: step1,
  },
  {
    name: '2. Direct your vision',
    description:
      'Describe what you want, or use our Curated Styles and Prompt Template',
    imageUrl: step2,
  },
  {
    name: '3. Adjust your settings',
    description:
      'Customize your length, dimensions, camera movements, and more',
    imageUrl: step3,
  },
  {
    name: '4. Select your starting frame',
    description:
      'Curate your vibe from the 4 starting frames we generate for you',
    imageUrl: step4,
  },
  {
    name: '5. Download your video',
    description: 'Export and share you creation with the world',
    imageUrl: step5,
  },
]

export default function HowItWorks({ referrer_id = '' }) {
  return (
    <div className='bg-dark px-5 pt-[74px]'>
      <div className='py-12 mx-auto max-w-7xl lg:py-24'>
        <div className='space-y-12'>
          <div className='space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none'>
            <h2 className='text-3xl font-bold tracking-tight sm:text-4xl text-tertiary'>
              Beautiful videos in minutes
            </h2>
          </div>
          <ul
            description='list'
            className='space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8'
          >
            {steps.map((step) => (
              <li key={step.name}>
                <div className='space-y-4'>
                  <div className='rounded-lg aspect-w-3 aspect-h-2 h-60'>
                    <img
                      className='object-cover rounded-lg shadow-lg'
                      src={step.imageUrl}
                      alt=''
                    />
                  </div>

                  <div className='space-y-2'>
                    <div className='space-y-1 text-lgleading-6'>
                      <h3 className='pt-6 text-2xl font-medium text-tertiary'>
                        {step.name}
                      </h3>
                      <p className='font-light text-tertiary/60'>
                        {step.description}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className='flex justify-end'>
            <Link to='/create'>
              <button className='flex items-center gap-2 px-6 py-5 mx-auto text-base font-bold text-gray-900 uppercase rounded-full radial-gradient button-shadow'>
                Direct my video <ArrowRightIcon className='w-6 h-6' />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
