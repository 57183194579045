import { Tab, TabsHeader } from '@material-tailwind/react'
import React from 'react'

import { TabData } from './PresetMenu'
import { useThemeContext } from '../../../../context'
import { MagnifyingGlassIcon } from '../../../../images/icons/MagnifyingGlassIcon'
import { ReorderIcon } from '../../../../images/icons/ReorderIcon'
import { XMarkIcon2 } from '../../../../images/icons/XMarkIcon2'
import { PresetCategory } from '../../../../types'
import { cn } from '../../../../utils'

interface PresetMenuHeaderProps {
  activeTab: PresetCategory
  closePresetMenu: () => void
  handleReorderClick: () => void
  handleSearchInput: (text: string) => void
  handleTabChange: (tab: PresetCategory) => void
  searchText: string
  showReorderButton: boolean
  tabs: TabData[]
}

export const PresetMenuHeader = React.forwardRef<
  HTMLInputElement,
  PresetMenuHeaderProps
>(
  (
    {
      activeTab,
      closePresetMenu,
      handleReorderClick,
      handleSearchInput,
      handleTabChange,
      searchText,
      showReorderButton,
      tabs,
    },
    ref,
  ) => {
    const { colors } = useThemeContext()
    return (
      <div className='flex flex-col'>
        <div className='flex justify-between items-center p-4 px-6 pb-1'>
          <TabsHeader
            className={`rounded-full ${colors.background.neutral} p-0`}
            indicatorProps={{
              className: `rounded-full ${colors.background.brand}`,
            }}
          >
            {tabs.map(({ label, value }: TabData) => (
              <Tab
                key={value}
                value={value}
                className={cn(
                  `text-xs w-36 p-3 ${colors.text.default} transition ease-in-out duration-300 opacity-75 hover:opacity-100`,
                  {
                    [`cursor-default opacity-100 ${colors.text.inverse}`]:
                      value === activeTab,
                  },
                )}
                onClick={() => handleTabChange(value)}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <div className='flex items-center gap-4'>
            {showReorderButton && (
              <div onClick={handleReorderClick}>
                <ReorderIcon
                  className={`${colors.text.inverse} ${colors.background.brand} p-1 w-8 h-8 rounded-md cursor-pointer transition ease-in-out duration-300`}
                />
              </div>
            )}
            <div
              className={`flex items-center gap-2 w-80 ${colors.background.neutral} border-k2-gray-200 border rounded-lg px-2 py-1 opacity-75 hover:opacity-100 transition ease-in-out duration-300 cursor-text`}
              onClick={() => {
                if (ref && 'current' in ref && ref.current) ref.current.focus()
              }}
            >
              <MagnifyingGlassIcon className={`${colors.text.default}`} />
              <input
                ref={ref}
                className={`text-xs ${colors.text.default} ${colors.placeholder.inverse} w-full outline-none bg-transparent`}
                placeholder='Search'
                value={searchText}
                onChange={(e) => handleSearchInput(e.target.value)}
              />
            </div>
            <XMarkIcon2
              onClick={closePresetMenu}
              className={`${colors.text.default} cursor-pointer opacity-75 hover:opacity-100 transition ease-in-out duration-300`}
            />
          </div>
        </div>
      </div>
    )
  },
)

PresetMenuHeader.displayName = 'PresetMenuHeader'
