import { useThemeContext } from '../../../context/themeContext'
import { ArrowCircleIcon } from '../../../images/ArrowCircleIcon'
import { cn } from '../../../utils'

interface GenerateButtonProps {
  formId: string
}

export const GenerateButton: React.FC<GenerateButtonProps> = ({ formId }) => {
  const { colors } = useThemeContext()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    /**
     * Dispatch a submit event to the form manually
     * This is necessary due to some strange interactions
     * between ReactFlow's node selection handler and form submission
     * that causes the form to not submit when the button is clicked the first time
     */
    const form = document.getElementById(formId) as HTMLFormElement
    if (form) {
      form.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      )
    } else {
      console.error(`Form with id ${formId} not found`)
    }
  }

  return (
    <button
      className={cn(
        `flex items-center justify-center h-8 px-4 rounded-lg text-white focus:outline-none z-10 ease-in-out transition-all duration-300 hover:${colors.background.highlight}`,
        colors.background.brand,
      )}
      form={formId}
      title='Generate Button'
      onClick={handleClick}
    >
      <ArrowCircleIcon className={colors.icon.inverse} />
    </button>
  )
}
