interface ArrowCircleIconProps {
  className?: string
  scale?: number
}

export const ArrowCircleIcon = ({
  className = '',
  scale = 1,
}: ArrowCircleIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={35 * scale}
    height={17 * scale}
    viewBox='0 0 35 17'
    stroke='none'
    fill='currentColor'
    className={className}
  >
    <ellipse cx='26.1305' cy='8.62201' rx='7.93471' ry='7.98529' />
    <path d='M2.86479 1.22757C3.16829 0.90516 3.67907 0.899998 3.98903 1.2162L13.4078 10.8249V1.82925C13.4078 1.39874 13.7568 1.04975 14.1873 1.04975H16.322C16.7525 1.04975 17.1015 1.39874 17.1015 1.82925V15.6901C17.1015 16.1206 16.7525 16.4696 16.322 16.4696H3.10605C2.67554 16.4696 2.32655 16.1206 2.32655 15.6901V13.6695C2.32655 13.239 2.67554 12.89 3.10605 12.89H10.5349L1.48989 3.78738C1.19359 3.48918 1.18711 3.00974 1.47526 2.70365L2.86479 1.22757Z' />
  </svg>
)
