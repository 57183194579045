import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'

function PricingBottomItem({
  title,
  description,
  img,
  btnUrl,
  size,
  btnText,
  orderTxt,
  orderImg,
}) {
  const isExternal =
    btnUrl &&
    (btnUrl.startsWith('http://') ||
      btnUrl.startsWith('https://') ||
      btnUrl.startsWith('mailto:'))
  const LinkComponent = isExternal ? 'a' : Link
  const linkProps = isExternal ? { href: btnUrl } : { to: btnUrl }
  return (
    <div className='flex flex-col'>
      <div className='flex flex-1 sm:flex-row flex-col gap-20 items-center justify-center'>
        <div
          className={`flex flex-1 w-full flex-shrink-0 flex-col ${size} min-w-[300px] order-first ${orderTxt}`}
        >
          <div className='flex flex-col gap-4 '>
            <h1 className='text-white font-semibold lg:text-5xl sm:text-4xl text-2xl'>
              {title}
            </h1>
            <p className='text-white/50 lg:text-4xl sm:text-3xl text-2xl'>
              {description}
            </p>
          </div>
          <LinkComponent
            className='inline-flex mt-10 items-center'
            {...linkProps}
          >
            <div className='inline-flex font-semibold lg:text-3xl sm:text-2xl text-xl text-primary hover:text-primary/90 active:text-primary/40 duration-300 '>
              <span>{btnText}</span>
              <IoIosArrowForward className='mt-1 lg:text-4xl sm:text-3xl text-2xl' />
            </div>
          </LinkComponent>
        </div>

        <div className={`flex  order-last ${orderImg}`}>
          <img
            className='max-h-[435px] rounded-3xl'
            src={img}
            alt='Kaiber Pricing'
          />
        </div>
      </div>
    </div>
  )
}

export default PricingBottomItem
