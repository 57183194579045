import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { TbCoins } from 'react-icons/tb'

import { GenerateButton } from './GenerateButton'
import { DEFAULT_THEME } from '../../../constants'
import { useThemeContext } from '../../../context'
import { cn } from '../../../utils'
import { getCreditsCost } from '../../../utils/k2_credit'
import { Spinner } from '../../loading'
import { ScaleWrapper } from '../ScaleWrapper'
import { ModelType, Status } from '@/types'

interface FlowHeaderProps {
  formId: string
  status?: Status
  modelType: ModelType
}

export const FlowHeader = ({ formId, modelType, status }: FlowHeaderProps) => {
  const { watch } = useFormContext()
  const { colors, activeTheme } = useThemeContext()

  const length = watch('length')
  const creditCost = useMemo(
    () => getCreditsCost(modelType, length),
    [modelType, length],
  )

  return (
    <ScaleWrapper
      className={cn(
        colors.background.element,
        'flex items-center px-5 py-2 rounded-xl gap-3 shadow-lg outline outline-1 outline-gray-500 origin-top-left',
      )}
    >
      <div className='flex items-center gap-3'>
        <div className='flex items-center gap-1 text-k2-primary-default'>
          <TbCoins className={colors.icon.brand} />
          <span className={colors.text.brand}>{creditCost}</span>
        </div>
        <GenerateButton formId={formId} />
        {status === Status.Pending && (
          <Spinner
            containerClassName={`flex justify-center items-center w-full h-full ${colors.background.brand}`}
            iconClassName={activeTheme === DEFAULT_THEME.Dark ? 'invert' : ''}
          />
        )}
      </div>
    </ScaleWrapper>
  )
}
