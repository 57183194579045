import classNames from 'classnames'
import { useState } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'

export default function SingleSelect({
  handleSelect,
  disabled,
  title,
  options,
  defaultOption,
  subscriptionTextCTA,
  showSubscriptionTextCTA,
}) {
  const [selectedName, setSelectedName] = useState(defaultOption)

  const onSelect = (name, value) => {
    setSelectedName(name)
    handleSelect(name, value)
  }

  if (disabled) {
    return null
  }

  return (
    <div className='space-y-2'>
      <p className='text-tertiary/60'>{title}</p>

      {!disabled && !showSubscriptionTextCTA ? (
        <div className='gap-2 flex flex-wrap py-4 mt-10'>
          {options.map((option) => (
            <button
              key={option.name}
              className={classNames(
                'border px-4 py-2 rounded-full text-white border-primary/50 capitalize',
                {
                  'border-primary/100 border-4': option.name === selectedName,
                },
              )}
              onClick={() => onSelect(option.name, option.value)}
            >
              {option.name}
            </button>
          ))}
        </div>
      ) : null}
      {showSubscriptionTextCTA ? (
        <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
          <StarIcon className='h-6 w-6 inline mr-2' />
          {subscriptionTextCTA}
        </div>
      ) : null}
    </div>
  )
}
