import { ReactNode, useState } from 'react'
import { IoCloseCircleOutline } from 'react-icons/io5'

import { ELEMENT_MAX_WIDTH } from '../../../constants'
import { useThemeContext } from '../../../context'
import { cn } from '../../../utils'
import { Tooltip } from '../Tooltip'

interface FlowElementProps {
  element: ReactNode
  elementIcon?: ReactNode
  required?: boolean
  handleDelete?: () => void
  isCollapsed: boolean
  label: string
  setIsCollapsed: (collapsed: boolean) => void
}

export const FlowElement = ({
  element,
  elementIcon,
  handleDelete,
  required,
  isCollapsed,
  label,
  setIsCollapsed,
}: FlowElementProps) => {
  const { colors } = useThemeContext()

  const [isIconHovering, setIsIconHovering] = useState(false)

  return (
    <div
      className={`flex-1 relative max-w-[${ELEMENT_MAX_WIDTH}px]`}
      onDoubleClick={() => {
        setIsCollapsed(false)
      }}
    >
      {isCollapsed && (
        <Tooltip
          placement='bottom'
          content={label}
          className={cn({ 'hidden': !isCollapsed })}
        >
          {elementIcon && (
            <div
              className={cn(
                'mb-10 pl-4 transition-transform transform duration-300 relative',
                {
                  'mt-10': isCollapsed,
                  'scale-125 delay-150': isIconHovering && isCollapsed,
                },
              )}
              onMouseEnter={() => setIsIconHovering(true)}
              onMouseLeave={() => setIsIconHovering(false)}
            >
              {elementIcon}
            </div>
          )}
        </Tooltip>
      )}

      {!isCollapsed && element}

      {!required && !isCollapsed && (
        <div className='absolute top-2 right-2 rounded-full'>
          <div
            className={cn(
              'absolute top-1 right-1 bg-transparent h-6 w-6 rounded-full transition ease-in-out duration-300',
            )}
          />
          <button
            title='Remove element'
            className='flex justify-center items-center opacity-80 hover:opacity-100 transition ease-in-out duration-300 rounded-full'
            onClick={handleDelete}
          >
            <IoCloseCircleOutline
              className={`h-8 w-8 z-10 ${colors.text.default}`}
            />
          </button>
        </div>
      )}
    </div>
  )
}
