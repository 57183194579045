const withMT = require('@material-tailwind/react/utils/withMT')

const config = {
  content: ['./src/**/*.{html,js,jsx,ts,tsx}'],
  theme: {
    extend: {
      fontSize: {
        k2: {
          xs: '10px',
          sm: '12px',
          base: '16px',
          lg: '24px',
          xl: '32px',
          '2xl': '40px',
          '3xl': '48px',
          '4xl': '54px',
          '5xl': '72px',
          '6xl': '80px',
          '7xl': '96px',
        },
      },
      colors: {
        primary: '#97E8A6',
        'primary-light': '#95D0B7',
        secondary: '#59A683',
        tertiary: '#ECFBEC',
        quaternary: '#a59cda',
        dark: '#0B0B0B',
        stone: '#515151',
        darkStone: '313137', // deprecate use of pascalCase in favor of kebab-case
        darkGray: '#212128', // deprecate use of pascalCase in favor of kebab-case
        lightGray: '#8C8C8C', // deprecate use of pascalCase in favor of kebab-case
        kaiberGreen: '#8DDE9C', // deprecate use of pascalCase in favor of kebab-case
        kaiberPurple: '#9B92D0', // deprecate use of pascalCase in favor of kebab-case
        infoGrey: '#303030', // deprecate use of pascalCase in favor of kebab-case
        kaiberRed: '#FF4646', // deprecate use of pascalCase in favor of kebab-case
        kaiber: {
          green: '#8DDE9C',
          purple: '#9B92D0',
          red: '#FF4646',
        },
        'neutral-75': '#121212bf',
        neutral: '#121212',
        'brand-light': '#3C44FF',
        'brand-dark': '#DDEB26',
        shrek: '#D9E123',
        alarm: '#E66464',
        k2: {
          blue: {
            50: '#C9D5FF',
            100: '#9DB1FB',
            200: '#6C88EE',
            300: '#4665D6',
            400: '#3655C2',
            500: '#2B439A',
            600: '#2B3D80',
            700: '#202D5C',
            800: '#151E40',
            900: '#0F162F',
          },
          green: {
            50: '#DFFEE5',
            100: '#B2F2BE',
            200: '#8DDE9C',
            300: '#75D286',
            400: '#5AB06A',
            500: '#388C48',
            600: '#286734',
            700: '#26572F',
            750: '#23E17F',
            800: '#204026',
            900: '#152918',
          },
          purple: {
            50: '#EEEBFE',
            100: '#DBD7F0',
            200: '#BCB6DF',
            300: '#9B92D0',
            400: '#857BC2',
            500: '#6F64AB',
            600: '#584F8F',
            700: '#473E7B',
            800: '#352E5E',
            900: '#242040',
          },
          red: {
            50: '#FFD7D4',
            100: '#FFB7B2',
            200: '#FF9892',
            300: '#FF736A',
            400: '#F4544A',
            500: '#C7433B',
            600: '#973A34',
            700: '#5E2B28',
            800: '#3A201E',
            900: '#271817',
          },
          gray: {
            25: '#EBEBEBBF',
            50: '#ECFBEC',
            100: '#C4D1C4',
            200: '#ABB6AB',
            300: '#7E817E',
            400: '#515151',
            450: '#49494D',
            500: '#3C3C40',
            600: '#313137',
            700: '#212127',
            800: '#161619',
            900: '#0E0E0E',
          },
          gold: {
            50: '#FFF3D6',
            100: '#FDE6AE',
            200: '#F2D384',
            300: '#D3AD4D',
            400: '#B59544',
            500: '#B59544',
            600: '#827249',
            700: '#5A5035',
            800: '#38311E',
            900: '#241E0F',
          },
          neutral: {
            50: '#F7F7F7',
            100: '#DEDEDE',
            200: '#CBCBCB',
            300: '#999999',
            600: '#4E4E4E',
            700: '#252525bf',
            800: '#282828',
          },
          primary: {
            default: '#D3AD4D', // gold-300
            inactive: '#B59544', // gold-500
            highlighted: '#F2D384', // gold-200
            active: '#EABD1E',
            'inactive-gray': '#F3F3F3',
          },
          secondary: {
            default: '#5A5035', // gold-700
            inactive: '#515151', // gray-400
            highlight: '#B59544', // gold-500
          },
          tertiary: '#353542',
          dark: {
            default: '#0E0E0E', // gray-900
            primary: '#252525',
          },
          medium: {
            default: '#212127', // gray-700
          },
          light: {
            default: '#ECFBEC', // gray-50
          },
          text: {
            default: '#ECFBEC', // gray-50
            secondary: '#ABB6AB', // gray-200
            inactive: '#7E817E', // gray-300
          },
          toolbar: {
            primary: '#D9E123', // shrek
            secondary: '#9F9F9F',
          },
        },

        'dark-stone': '#313137', // gray-600
        'dark-gray': '#212128',
        'light-gray': '#8C8C8C',
        'info-grey': '#303030',
        'neutral-800': '#161619', // gray-800
      },
      backgroundColor: {
        disabled: '#8E978E',
      },
      backgroundImage: {
        shadow:
          'linear-gradient(180deg, rgba(0,0,0,0.9) 30%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 90%)',
        slider: 'linear-gradient(297deg, #3c3c3c 0.83%, #8C8C8C 80.8%)',
        'skeleton-gradient': `linear-gradient(
            135deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.075) 25%,
            rgba(255, 255, 255, 0.15) 50%,
            rgba(255, 255, 255, 0.075) 75%,
            rgba(255, 255, 255, 0) 100%
          )`,
        'skeleton-gradient-light': `linear-gradient(
            135deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.075) 25%,
            rgba(0, 0, 0, 0.15) 50%,
            rgba(0, 0, 0, 0.075) 75%,
            rgba(0, 0, 0, 0) 100%
          )`,
        node: 'linear-gradient(180deg, #343434 0%, #404040 100%)',
        'gradient-border':
          'linear-gradient(to right, #FFFFFF 0%, #D3D3D3 55.41%, #F2F2F2 70%)',
      },
      backgroundSize: {
        'skeleton-size': '200% 200%',
      },
      keyframes: {
        'skeleton': {
          '0%': {
            backgroundPosition: '100% 100%',
          },
          '50%': {
            backgroundPosition: '0% 0%',
          },
          '100%': {
            backgroundPosition: '100% 100%',
          },
        },
        'slide-up': {
          '0%': {
            transform: 'translateY(0)',
            opacity: '0',
          },
          '100%': {
            transform: 'translateY(-100%)',
            opacity: '1',
          },
        },
        'slide-down': {
          '0%': {
            transform: 'translateY(-100%)',
            opacity: '1',
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: '0',
          },
        },
        'fade-out': {
          '0%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0',
          },
        },
        'jiggle': {
          '0%, 100%': { transform: 'rotate(0deg)' },
          '25%': { transform: 'rotate(0.5deg)' },
          '75%': { transform: 'rotate(-0.5deg)' },
        },
        'march': {
          '0%': { strokeDashoffset: 10 },
          '100%': { strokeDashoffset: 0 },
        },
      },
      animation: {
        'skeleton': 'skeleton 3s linear infinite',
        'slide-up': 'slide-up 1s ease-in-out',
        'slide-down': 'slide-down 1s ease-in-out',
        'fade-out': 'fade-out 1s ease-in-out forwards',
        'jiggle': 'jiggle 0.3s infinite',
        'march': 'march 0.5s linear infinite',
      },
      spacing: {
        'elements-container': 'var(--elements-container-width)',
        'main-container': 'var(--main-container-width)',
        'full-minus-2': 'calc(100% - 2px)',
      },
      boxShadow: {
        k2: {
          10: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
          25: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        },
        'glow-gold': '0 0 9px 2px rgba(252, 211, 77, 0.5)',
      },
      blur: {
        'xs': '2.5px',
      },
    },
  },
  plugins: [
    require('tailwindcss-animate'),
    require('tailwind-scrollbar-hide'),
    function ({ addBase, theme }) {
      addBase({
        ':root': Object.entries(theme('colors')).reduce(
          (vars, [key, value]) => {
            if (typeof value === 'string') {
              vars[`--color-${key}`] = value
            } else if (typeof value === 'object') {
              Object.entries(value).forEach(([subKey, subValue]) => {
                vars[`--color-${key}-${subKey}`] = subValue
              })
            }
            return vars
          },
          {},
        ),
      })
    },
  ],
}

export default withMT(config)

export const theme = config.theme
