import { useCallback, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Button from '../components/welcome/Button'
import HomeFeature from '../components/welcome/FeatureSection'
import ArtistTestimony from '../components/welcome/TestimonySection'
import AnimationSection from '../components/welcome/AnimationSection'
import SectionOdk from '../components/welcome/OdkSection'
import CTA from '../components/welcome/CTASection'
import {
  HERO_DESKTOP_VIDEO_SRC,
  HERO_MOBILE_VIDEO_SRC,
} from '../utils/constants'

const ctaImg = '/website/welcome/cta.png'
const galleryImg = '/website/welcome/gallery_section.png'
const gallery1 = '/website/welcome/gallery_art1.png'
const gallery2 = '/website/welcome/gallery_art2.png'
const gallery3 = '/website/welcome/gallery_art3.png'

export default function Welcome() {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const handleWindowResize = useCallback(() => {
    const newVideoSrc = getVideoSource(window.innerWidth)
    const currentVideoSrc = playerRef.current.currentSrc()
    const currentTime = playerRef.current.currentTime()

    if (newVideoSrc.src !== currentVideoSrc) {
      playerRef.current.src(newVideoSrc)
      playerRef.current.ready(() => {
        playerRef.current.currentTime(currentTime)
        playerRef.current.play()
      })
    }
  }, [])

  useEffect(() => {
    playerRef.current = videojs(videoRef.current, {
      autoplay: true,
      controls: false,
      loop: true,
      muted: true,
      html5: {
        hls: {
          overrideNative: true,
        },
      },
    })

    const videoSrc = getVideoSource(window.innerWidth)
    playerRef.current.src(videoSrc)

    // Enable autoplay on user interaction, necessary for mobile devices
    const handleUserInteraction = () => {
      playerRef.current.play()
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
      window.removeEventListener('scroll', handleUserInteraction)
    }

    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('touchstart', handleUserInteraction)
    window.addEventListener('scroll', handleUserInteraction)
    window.addEventListener('resize', handleWindowResize)

    return () => {
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
      window.removeEventListener('resize', handleWindowResize)
      window.removeEventListener('scroll', handleUserInteraction)
      playerRef.current.dispose()
    }
  }, [handleWindowResize])

  const getVideoSource = (windowWidth) => {
    if (windowWidth <= 640) {
      return {
        src: HERO_MOBILE_VIDEO_SRC,
        type: 'application/x-mpegURL',
      }
    } else {
      return {
        src: HERO_DESKTOP_VIDEO_SRC,
        type: 'application/x-mpegURL',
      }
    }
  }

  return (
    <div className='bg-black'>
      <Navbar showBanner={false} />
      <main className='flex flex-col w-full h-fit '>
        <div className='z-10 flex flex-col items-center flex-1 text-white'>
          <div className='relative w-full sm:h-screen h-[85vh] overflow-hidden'>
            <div className='relative w-auto h-full'>
              <video
                ref={videoRef}
                className='video-js vjs-default-skin absolute top-0 left-50% transform -translate-x-50% sm:min-h-[56.25vw] min-h-[177.77vw] sm:aspect-video aspect-[9/16] w-screen h-screen home-hero-video'
                playsInline
                muted
              />
            </div>
          </div>
          <div className='flex w-full flex-col items-center sm:-translate-y-[115px] -translate-y-[185px] gap-24'>
            {/* SECTION 1 */}
            <div className='flex flex-col flex-1 max-w-[1440px] w-full gap-14 md:px-[3.5%] px-4 overflow-hidden'>
              <div className='flex flex-col sm:flex-row gap-14'>
                <img
                  className='flex md:max-w-full max-w-[300px] md:w-[38%] w-full object-contain'
                  src={ctaImg}
                  alt='CTA Kaiber'
                />
                <div className='flex flex-col flex-1 max-w-3xl gap-10'>
                  <div className='flex flex-col gap-5'>
                    <h1 className='font-semibold lg:text-8xl sm:text-6xl'>
                      Discover the artist within you
                    </h1>
                    <p className='text-2xl text-white font-extralight lg:text-4xl sm:text-3xl'>
                      Turn text, videos, photos, and music into stunning videos
                      with our advanced AI generation engine.
                    </p>
                  </div>
                  <Link to='/create'>
                    <Button btnText='Start creating' />
                  </Link>
                </div>
              </div>

              <div className='flex flex-col justify-between flex-1 w-full gap-20 sm:flex-row lg:gap-44'>
                <div className='flex flex-col min-w-[33%] sm:max-w-xs max-w-full gap-10 sm:items-center items-start'>
                  <img className='flex' src={galleryImg} alt='Gallery Text' />
                  <Link to='/gallery'>
                    <Button btnText='Visit the gallery' />
                  </Link>
                </div>
                <div className='flex flex-col max-w-2xl md:min-w-[700px] min-w-[435px] gap-5'>
                  <img className='col' src={gallery1} alt='Gallery left' />
                  <div className='flex items-center w-full gap-5'>
                    <img
                      className='flex flex-1 w-2/3 max-h-80'
                      src={gallery2}
                      alt='Gallery 2'
                    />
                    <img
                      className='flex flex-1 w-1/3 max-h-80'
                      src={gallery3}
                      alt='Gallery 3'
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SECTION 2 */}
            <HomeFeature />

            {/* SECTION 3 */}
            <AnimationSection />

            {/* SECTION 4 */}
            <ArtistTestimony />

            {/* SECTION 5 */}
            <SectionOdk />

            {/* SECTION 6 */}
            <CTA />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}
