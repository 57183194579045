import { Route, Routes } from 'react-router-dom'
import { About } from '../../pages/About'
import Blog from './blog/Blog'

export const AboutRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<About />} />
      <Route path='blog' element={<Blog />} />
    </Routes>
  )
}
