import { useReactFlow } from '@xyflow/react'
import { useMemo } from 'react'

import { NodeToolbar, ToolbarButton } from './NodeToolbar'
import { useCanvasContext } from '../../context'
import { useCreateCollection, useNodeUtility } from '../../hooks'
import { AddToCollectionIcon } from '../../images/icons/AddToCollectionIcon'
import { AddToFlowIcon } from '../../images/icons/AddToFlowIcon'
import { XMarkIcon2 } from '../../images/icons/XMarkIcon2'
import { NodeType } from '../../types'

interface SelectionToolToolbarProps {
  selectionBounds: { x: number; y: number; width: number }
}

export const SelectionToolToolbar = ({
  selectionBounds,
}: SelectionToolToolbarProps) => {
  const { flowToScreenPosition, getNode } = useReactFlow()
  const { anyNodesSelected, recreateSelectedFlows, deleteSelectedNodes } =
    useNodeUtility()
  const { selectedNodeIds } = useCanvasContext()
  const { createCollectionFromSelection } = useCreateCollection()

  const toolbarButtons: ToolbarButton[] = useMemo(() => {
    const selectedMediaNodes = Array.from(selectedNodeIds).filter(
      (id) => getNode(id)?.type === NodeType.MediaNode,
    )
    const hasMultipleSelectedMediaNodes = selectedMediaNodes.length >= 2

    return hasMultipleSelectedMediaNodes
      ? [
          {
            icon: AddToCollectionIcon,
            onClick: createCollectionFromSelection,
            className: 'text-white',
            title: 'Create Collection from selection',
          },
          {
            icon: AddToFlowIcon,
            onClick: recreateSelectedFlows,
            className: 'text-white',
            title: 'Recreate formulas',
          },
          {
            icon: XMarkIcon2,
            onClick: () => deleteSelectedNodes(),
            className: 'text-alarm',
            title: 'Remove from canvas',
            size: 28,
          },
        ]
      : []
  }, [
    selectedNodeIds,
    getNode,
    createCollectionFromSelection,
    deleteSelectedNodes,
    recreateSelectedFlows,
  ])

  if (!selectionBounds || !anyNodesSelected() || toolbarButtons.length === 0)
    return null

  const { x, y, width } = selectionBounds
  const screenPosition = flowToScreenPosition({ x: x + width / 2, y })

  const toolbarStyle: React.CSSProperties = {
    position: 'absolute',
    left: `${screenPosition.x}px`,
    top: `${screenPosition.y}px`,
    transform: 'translate(-50%, -100%)',
    zIndex: 1000,
  }

  return (
    <div style={toolbarStyle}>
      <NodeToolbar buttons={toolbarButtons} disableScaling />
    </div>
  )
}
