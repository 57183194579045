import { useState } from 'react'

import { ModalVideo } from '../ModalVideo.js'

function StudioComponent({ image, title, videoUrl, VIDEO_ID }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const thumbnailUrl = `http://i3.ytimg.com/vi/${VIDEO_ID}/maxresdefault.jpg`

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        handleOpen()
      }}
      className='flex flex-col sm:gap-7 gap-3 group cursor-pointer'
    >
      <div className='w-full sm:max-w-md max-w-full aspect-video sm:min-h-[261px] rounded-3xl overflow-hidden'>
        <img
          className='w-full h-full object-cover group-hover:scale-110 duration-300'
          src={thumbnailUrl}
          alt='Kaiber Studio'
        />
      </div>
      <p className='lg:text-2xl sm:text-xl text-lg text-primary group-hover:underline'>
        {title}
      </p>

      <ModalVideo open={isOpen} setOpen={setIsOpen} handleClose={handleClose}>
        <iframe
          className='flex w-full aspect-video rounded-3xl'
          src={`https://www.youtube.com/embed/${VIDEO_ID}`}
          allow='accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture'
          allowFullScreen
          title='Embedded Kaiber Video'
        />
      </ModalVideo>
    </div>
  )
}

export default StudioComponent
