import { StarIcon } from '@heroicons/react/24/outline'
import * as Slider from '@radix-ui/react-slider'
import { useState, useEffect } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

// create a reactjs slider
const RadixSlider = ({
  title,
  description,
  handleChange,
  subscriptionTextCTA,
  showSubscriptionTextCTA,
  min = 1,
  max = 10,
  defaultOption = 6,
  units = '',
}) => {
  const [slider, setSlider] = useState(defaultOption)

  const handleSlider = (valueArray) => {
    const value = valueArray[0]
    setSlider(value)
    handleChange(value)
  }

  useEffect(() => {
    setSlider(defaultOption)
  }, [defaultOption])

  return (
    <div className='mb-5'>
      {title ||
        (description && (
          <div className='flex mb-5 gap-3'>
            {title && (
              <h1 className='text-xl lg:text-2xl xl:text-3xl'>{title}</h1>
            )}

            {/**Show information tooltip*/}
            {description && (
              <div className='flex has-tooltip '>
                <span className='tooltip rounded shadow-lg px-2 py-1 bg-gray-800 text-gray-100 -mt-8'>
                  {description}
                </span>
                <AiOutlineQuestionCircle className='w-6 h-6 self-center select-none' />
              </div>
            )}
          </div>
        ))}

      <div className='flex gap-5 md:w-80 w-full mb-5 items-center'>
        <div className='flex items-center'>
          <span className='bg-primary text-dark w-auto py-1 px-3 text-lg text-center font-semibold whitespace-nowrap'>
            {slider} {units}
          </span>
        </div>
        <Slider.Root
          className='relative flex items-center select-none touch-none w-full h-5'
          value={[slider]}
          max={max}
          min={min}
          step={1}
          onValueChange={handleSlider}
          aria-label='Intensity'
        >
          <Slider.Track className='bg-primary/50 relative grow  h-[2px]'>
            <hr className='absolute top-0 bottom-0 m-auto w-[1px] h-4 bg-primary border-0 p-0' />
            <Slider.Range className='absolute top-0 bottom-0 m-auto bg-primary h-[6px]' />
          </Slider.Track>
          <Slider.Thumb className='flex cursor-pointer justify-center relative w-5 h-5 bg-primary shadow-[0_2px_10px] shadow-primary/50 rounded-[10px] group focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-primary/50'>
            <span className='thumbLabel bg-primary text-dark -translate-y-[100%] group-focus:scale-y-100 scale-y-0'>
              {slider}
            </span>
          </Slider.Thumb>
        </Slider.Root>

        {showSubscriptionTextCTA ? (
          <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
            <StarIcon className='h-6 w-6 inline mr-2' />
            {subscriptionTextCTA}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default RadixSlider
