// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ol {
  counter-reset: item;
}
ol > li {
  display: block;
}
ol > li > ol > li {
  margin-bottom: 20px;
}
ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  margin-right: 20px;
}
ol > li > ol > li::before {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/list-decimal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB","sourcesContent":["ol {\n  counter-reset: item;\n}\nol > li {\n  display: block;\n}\nol > li > ol > li {\n  margin-bottom: 20px;\n}\nol > li:before {\n  content: counters(item, \".\") \" \";\n  counter-increment: item;\n  margin-right: 20px;\n}\nol > li > ol > li::before {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
