import { useEffect, useState } from 'react'

import http from '../services/HttpService'

export const Maintenance = ({ children }) => {
  const [maintenanceMode, setMaintenanceMode] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      const response = await http.get('/api/maintenance_mode')

      setMaintenanceMode(response.data.inMaintenance)
      setMessage(response.data.message)
    }

    checkMaintenanceMode()
  }, [])

  if (maintenanceMode) {
    return (
      <div className='flex flex-col items-center justify-center h-screen text-white'>
        <h1 className='font-bold'>Maintenance Mode</h1>
        <p className='text-xl'>
          {message} Check out our{' '}
          <a className='text-sky-300' href='https://discord.com/invite/kaiber'>
            Discord
          </a>{' '}
          for updates.
        </p>
      </div>
    )
  }

  return children
}
