import { useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import { Modal } from './Modal'

export default function FreeTrialStartedModalComponent({ children }) {
  const [open, setOpen] = useState(false)
  const { currentUser, setCurrentUser } = useUserAccountContext()

  const markAsRead = async () => {
    try {
      await http.patch('/api/auth/myaccount/free-trial-started')
    } catch (err) {}
  }

  const handleModalClose = () => {
    setOpen(false)

    markAsRead()

    setTimeout(() => {
      setCurrentUser((currentUser) => ({
        ...currentUser,
        showFreeTrialStarted: false,
      }))
    }, 100)
  }

  useEffect(() => {
    if (currentUser?.showFreeTrialStarted) {
      setOpen(true)
    }
  }, [currentUser])

  if (!currentUser?.showFreeTrialStarted) {
    return children
  }

  return (
    <>
      {children}

      <Modal open={open} setOpen={handleModalClose}>
        <div className='text-center px-6 py-6 sm:px-12 sm:py-10 w-full'>
          <CheckCircleIcon className='w-10 h-10 inline text-primary' />
          <p className='mt-4 text-4xl sm:text-5xl text-tertiary'>
            Free Trial Started
          </p>
          <p className='mt-4 text-tertiary/80'>
            7 days of free creativity, and then an upgrade to Explorer tier
            unless cancelled.
          </p>
          <div className='mt-8'>
            <button
              onClick={handleModalClose}
              className='text-2xl text-primary underline'
            >
              Start Creating
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
