function SectionTitle({ title, description }) {
  return (
    <div className='text-center'>
      <h1 className='text-white lg:text-6xl sm:text-5xl text-4xl font-semibold mb-8'>
        {title}
      </h1>
      <span className='text-white/50 lg:text-4xl sm:text-3xl text-2xl'>
        {description}
      </span>
    </div>
  )
}

export default SectionTitle
