import Button from './Button'
import { Link } from 'react-router-dom'

function HomeFeatureComponent({
  title,
  description,
  buttonText,
  forwardRef,
  scrollRef,
}) {
  return (
    <div
      ref={forwardRef}
      className='flex flex-col justify-center flex-1 w-full gap-10 sm:min-h-screen'
    >
      <div className='flex flex-col'>
        <h1 className='mb-4 text-2xl font-semibold lg:text-4xl sm:text-3xl'>
          {title}
        </h1>
        <p className='max-w-xl text-2xl text-white lg:text-4xl sm:text-3xl font-extralight'>
          {description}
        </p>
      </div>
      <Link to='/register'>
        <Button btnText={buttonText} />
      </Link>
    </div>
  )
}

export default HomeFeatureComponent
