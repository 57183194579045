import { FallbackProps } from 'react-error-boundary'
import { DEFAULT_NODE_HEIGHT, DEFAULT_NODE_WIDTH } from '../../../constants'

export const ErrorNode = ({ error }: FallbackProps) => {
  return (
    <div
      style={{
        width: DEFAULT_NODE_WIDTH,
        height: DEFAULT_NODE_HEIGHT,
      }}
      className='bg-node p-5'
    >
      <div role='alert' className='text-white'>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    </div>
  )
}
