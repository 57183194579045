import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import UAParser from 'ua-parser-js'
import http from '../../services/HttpService'
import Pagination from './Pagination'

export default function UserHistoryTab({ user }) {
  const [isLoading, setIsLoading] = useState(true)
  const [sessions, setSessions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const tableWrapperRef = useRef()

  const fetchUserSessions = useCallback(
    async (page = 1) => {
      setIsLoading(true)

      const response = await http.get(
        `/api/admin/users/${user.distinctId}/sessions`,
        {
          params: {
            page,
          },
        },
      )

      setSessions(response.data.items)
      setTotalItems(response.data.totalItems)
      setTotalPages(response.data.totalPages)
      setIsLoading(false)

      if (tableWrapperRef.current) {
        tableWrapperRef.current.scrollTo(0, 0)
      }
    },
    [user.distinctId],
  )

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    fetchUserSessions(newPage)
  }

  const formatSignedInDate = (createdAt) => {
    return moment(createdAt).utc().format('LLL')
  }

  const formatLastActivityDate = (updatedAt) => {
    return moment
      .duration({
        from: updatedAt,
      })
      .humanize()
  }

  const parseUserAgent = (userAgent) => {
    return new UAParser(userAgent)
  }

  const getIPAddressLink = (ip) => {
    return `https://whatismyipaddress.com/ip/${ip}`
  }

  useEffect(() => {
    fetchUserSessions()
  }, [fetchUserSessions])

  return (
    <>
      {
        // eslint-disable-next-line eqeqeq
        isLoading && totalItems == 0 ? (
          <div className='flex h-full'>
            <div className='m-auto bg-darkGray p-6 rounded-3xl'>
              <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
            </div>
          </div>
        ) : (
          <>
            {totalItems > 0 && (
              <>
                <div className='bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl relative'>
                  <div ref={tableWrapperRef} className='overflow-x-auto'>
                    <table className='w-full text-white table-auto border-collapse min-w-[700px]'>
                      <thead>
                        <tr>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Signed In
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Last Activity
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Device
                          </th>
                          <th className='text-left p-3 border-b border-[#545854]/25'>
                            Location
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessions.map((session, index) => {
                          const userAgent = parseUserAgent(
                            session.userAgent,
                          ).getResult()
                          const formattedUserAgent =
                            !userAgent.browser.name &&
                            !userAgent.os.name &&
                            !userAgent.os.version
                              ? userAgent.ua
                              : `${userAgent.browser.name}, ${userAgent.os.name} ${userAgent.os.version}`

                          return (
                            <tr key={session._id}>
                              <td
                                className={`p-3 ${
                                  index < sessions.length - 1
                                    ? 'border-b border-[#545854]/25'
                                    : ''
                                }`}
                              >
                                {formatSignedInDate(session.createdAt)}
                              </td>
                              <td
                                className={`p-3 ${
                                  index < sessions.length - 1
                                    ? 'border-b border-[#545854]/25'
                                    : ''
                                }`}
                              >
                                {formatLastActivityDate(session.updatedAt)}
                              </td>
                              <td
                                className={`p-3 ${
                                  index < sessions.length - 1
                                    ? 'border-b border-[#545854]/25'
                                    : ''
                                }`}
                              >
                                {formattedUserAgent}
                              </td>
                              <td
                                className={`p-3 ${
                                  index < sessions.length - 1
                                    ? 'border-b border-[#545854]/25'
                                    : ''
                                }`}
                              >
                                <a
                                  href={getIPAddressLink(session.ip)}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  {session.ip}
                                </a>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {isLoading && (
                    <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 rounded-3xl'>
                      <div className='flex flex-col items-center space-y-4 m-auto'>
                        <div className='bg-darkGray p-6 rounded-3xl'>
                          <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='mt-8'>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    disabled={isLoading}
                    onPageChange={handlePageChange}
                  />
                </div>
              </>
            )}

            {
              // eslint-disable-next-line eqeqeq
              totalItems == 0 && (
                <p className='text-tertiary/80'>No sessions found.</p>
              )
            }
          </>
        )
      }
    </>
  )
}
