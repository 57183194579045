import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Button,
} from '@material-tailwind/react'
import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AccountPill } from './AccountPill'
import { DEFAULT_THEME } from '../../../constants'
import { useThemeContext } from '../../../context'
import { useUserAccountContext } from '../../../context/userContext'
import { cn } from '../../../utils'

export const Account: React.FC = () => {
  const { currentUser } = useUserAccountContext()
  const { activeTheme, colors, setActiveTheme } = useThemeContext()
  const navigate = useNavigate()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownNode = dropdownRef.current
      const menuItems = document.querySelectorAll('.menu-item-selector')

      const isMenuItemClicked = Array.from(menuItems).some((menuItem) =>
        menuItem.contains(event.target as Node),
      )

      if (
        dropdownNode &&
        !dropdownNode.contains(event.target as Node) &&
        !isMenuItemClicked
      ) {
        setIsAccountSettingsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  return (
    <div className='flex-1 flex justify-end relative' ref={dropdownRef}>
      {currentUser && (
        <AccountPill
          user={currentUser}
          isAccountSettingsOpen={isAccountSettingsOpen}
          setIsAccountSettingsOpen={setIsAccountSettingsOpen}
        />
      )}

      {isAccountSettingsOpen && (
        <div
          className={`border-k2-gray-200 border absolute top-[50px] right-0 mt-2 p-4 w-48 flex flex-col gap-6 ${colors.background.neutral} rounded-2xl shadow-xl z-20 overflow-y-auto`}
        >
          <div className='flex flex-col gap-2'>
            <div
              className={`text-xs font-semibold cursor-pointer py-1 ${colors.text.default}`}
              onClick={() => navigate('/account?tab=my-plan')}
            >
              Account Settings
            </div>
            <div
              className={`flex items-center justify-between text-xs font-semibold cursor-pointer py-1 ${colors.text.subtle}`}
            >
              Theme
              <Menu>
                <MenuHandler>
                  <Button
                    className={`relative flex items-center bg-transparent ${colors.text.default} border border-gray-700 px-4 py-1 gap-4  rounded-2xl lowercase w-20 shadow-none`}
                  >
                    <span className='text-center flex-1'>{activeTheme}</span>
                  </Button>
                </MenuHandler>
                <MenuList
                  className={`menu-item-selector px-1 py-1 ${colors.background.element} border-gray-700 ${colors.text.default} rounded2xl min-w-0 w-20 shadow-none`}
                >
                  {Object.values(DEFAULT_THEME).map((theme) => (
                    <MenuItem
                      key={theme}
                      className={cn('w-full px-3 py-1 text-center text-xs', {
                        'bg-gray-200 text-black font-semibold':
                          activeTheme === theme,
                      })}
                      onClick={() => {
                        setActiveTheme(theme)
                        setIsAccountSettingsOpen(false)
                      }}
                    >
                      {theme}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
