import { useGlobalStore } from './globalStore'

const SCALING_COEFFICIENT = 0.75
const MIN_SCALE = 1
const ZOOM_FLOOR_FOR_SCALE = 0.1

export interface ICanvasStore {
  canvasDebouncedZoom: number
  canvasScale: number
}

/**
 * CanvasStore conceptually represents metadata about the react flow canvas.
 */
class CanvasStore {
  useStore = useGlobalStore

  public useDebouncedZoom() {
    return this.useStore((state) => state.canvasDebouncedZoom)
  }

  public setDebouncedZoomAndScale(canvasDebouncedZoom: number) {
    this.useStore.setState({ canvasDebouncedZoom })

    const newScale = Math.max(
      SCALING_COEFFICIENT / Math.max(canvasDebouncedZoom, ZOOM_FLOOR_FOR_SCALE),
      MIN_SCALE,
    )

    this.setScale(newScale)
  }

  public useScale() {
    return this.useStore((state) => state.canvasScale)
  }

  public setScale(canvasScale: number) {
    this.useStore.setState({ canvasScale })
  }
}

export const canvasStore = new CanvasStore()
