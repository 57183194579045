import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'

import { PresetMenu } from './PresetMenu/PresetMenu'
import { usePresetContext, useThemeContext } from '../../../context'
import { AddToFlowIconWithRef } from '../../../images/icons/AddToFlowIcon'
import { ClockIcon } from '../../../images/icons/ClockIcon'
import { HandIcon } from '../../../images/icons/HandIcon'
import { SelectionToolIcon } from '../../../images/icons/SelectionToolIcon'
import { cn } from '../../../utils'
import { Tooltip } from '../Tooltip'

interface TopbarProps {
  isMove: boolean
  isAutoSaving: boolean
  setIsMove: Dispatch<SetStateAction<boolean>>
  setDialogOpen: Dispatch<SetStateAction<boolean>>
}

export const Topbar = ({
  isMove,
  isAutoSaving,
  setIsMove,
  setDialogOpen,
}: TopbarProps) => {
  const { colors } = useThemeContext()
  const isPrevMoveRef = useRef(isMove)
  const isMoveRef = useRef(isMove)
  const isCaptured = useRef(false)
  const { togglePresetMenu, isPresetMenuOpen } = usePresetContext()
  const plusIconRef = useRef<SVGSVGElement>(null)

  const loadHistory = useCallback(() => {
    if (isAutoSaving) return
    setDialogOpen(true)
  }, [isAutoSaving, setDialogOpen])

  useEffect(() => {
    isMoveRef.current = isMove
  }, [isMove])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isCaptured.current) return

      if (event.key === 'Shift' || event.code === 'Space') {
        isPrevMoveRef.current = isMoveRef.current
        isCaptured.current = true
        if (event.key === 'Shift') {
          setIsMove(false)
        } else {
          setIsMove(true)
        }
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!isCaptured.current) return

      if (event.key === 'Shift' || event.code === 'Space') {
        setIsMove(isPrevMoveRef.current)
        isCaptured.current = false
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [setIsMove])

  return (
    <div className='z-20 flex items-center gap-4 px-4'>
      <div className='relative'>
        <Tooltip placement='bottom' content={`Create new preset`}>
          {/* Fragment needed here otherwise ref is null if direct child of Toolbar */}
          <div className='relative'>
            <AddToFlowIconWithRef
              ref={plusIconRef}
              size={24}
              className={cn(
                `rounded-full z-20 cursor-pointer hover:scale-110 ${colors.text.default} hover:${colors.text.brand}`,
                isPresetMenuOpen && colors.icon.brand,
              )}
              onClick={togglePresetMenu}
            />
          </div>
        </Tooltip>
        <PresetMenu iconRef={plusIconRef} />
      </div>
      <Tooltip placement='bottom' content={`Move`}>
        <HandIcon
          onClick={() => setIsMove(true)}
          size={24}
          className={cn(
            `relative rounded-full z-20 cursor-pointer hover:scale-110 ${colors.text.default} hover:${colors.text.brand}`,
            isMove && colors.icon.brand,
          )}
        />
      </Tooltip>
      <Tooltip placement='bottom' content={`Selection tool`}>
        <SelectionToolIcon
          onClick={() => setIsMove(false)}
          size={24}
          className={cn(
            `relative rounded-full z-20 cursor-pointer hover:scale-110 ${colors.text.default} hover:${colors.text.brand}`,
            !isMove && colors.icon.brand,
          )}
        />
      </Tooltip>
      <Tooltip placement='bottom' content={`Canvas states`}>
        <ClockIcon
          size={24}
          className={cn(
            `relative rounded-full z-20 cursor-pointer hover:scale-110 ${colors.text.default} hover:${colors.text.brand}`,
            { 'text-gray-400': isAutoSaving },
          )}
          onClick={loadHistory}
        />
      </Tooltip>
    </div>
  )
}

Topbar.displayName = 'Topbar'
