import React from 'react'

interface SelectionToolIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const SelectionToolIcon = React.forwardRef<
  SVGSVGElement,
  SelectionToolIconProps
>(({ size = '20', ...props }, ref) => (
  <>
    <svg
      ref={ref}
      stroke='currentColor'
      fill='none'
      height={size}
      width={size}
      viewBox='0 0 24 24'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.75 6V7.25C15.75 7.80228 16.1977 8.25 16.75 8.25H18M15.75 6V4.75C15.75 4.19772 16.1977 3.75 16.75 3.75H19.25C19.8023 3.75 20.25 4.19772 20.25 4.75V7.25C20.25 7.80228 19.8023 8.25 19.25 8.25H18M15.75 6H8.25M18 8.25V15.75M6 8.25V15.75M8 18H15.75M20.25 19.25V16.75C20.25 16.1977 19.8023 15.75 19.25 15.75H16.75C16.1977 15.75 15.75 16.1977 15.75 16.75V19.25C15.75 19.8023 16.1977 20.25 16.75 20.25H19.25C19.8023 20.25 20.25 19.8023 20.25 19.25ZM4.75 20.25H7C7.55228 20.25 8 19.8023 8 19.25V16.75C8 16.1977 7.55228 15.75 7 15.75H4.75C4.19772 15.75 3.75 16.1977 3.75 16.75V19.25C3.75 19.8023 4.19772 20.25 4.75 20.25ZM4.75 8.25H7.25C7.80228 8.25 8.25 7.80228 8.25 7.25V4.75C8.25 4.19772 7.80228 3.75 7.25 3.75H4.75C4.19772 3.75 3.75 4.19772 3.75 4.75V7.25C3.75 7.80228 4.19772 8.25 4.75 8.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </>
))
