import { useState, useEffect, useRef } from 'react'
import SectionTitle from './SectionTitle'
import HomeFeatureComponent from './FeatureComponent'
import { useInView } from 'react-intersection-observer'

const audioreactImg =
  'https://website-public-assets.kybercorp.org/web/features/audioreactivity.jpg'
const animationImg =
  'https://website-public-assets.kybercorp.org/web/features/animation.jpg'
const transformImg =
  'https://website-public-assets.kybercorp.org/web/features/transform.jpg'

function HomeFeature() {
  const [ref1, inView1] = useInView({ threshold: 0.8 })
  const [, inView2] = useInView({ threshold: 0.8 })
  const [ref3, inView3] = useInView({ threshold: 0.8 })

  const [imgFeature, setImgFeature] = useState(audioreactImg)

  useEffect(() => {
    if (inView1) setImgFeature(audioreactImg)
    if (inView2) setImgFeature(animationImg)
    if (inView3) setImgFeature(transformImg)
  }, [inView1, inView2, inView3])

  const refScroll1 = useRef(null)
  const refScroll3 = useRef(null)

  return (
    <div className='flex flex-col flex-1 max-w-[1440px] w-full items-center md:px-[3.5%] px-4'>
      <SectionTitle
        title='Elevate your art'
        description='Lift your creative expression to new heights'
      />
      <div className='flex flex-1 relative w-full sm:flex-row flex-col mb-20 gap-14 '>
        <div className='flex flex-1 flex-col w-fit sm:relative sticky sm:top-0 top-32'>
          <div className='flex flex-col sm:min-h-screen sticky top-0 items-center justify-center'>
            <div className='flex z-10 h-32 w-full translate-y-10 bg-black sm:hidden' />
            <img
              className='flex z-20 max-w-xl min-w-[250px] sm:w-[40vw] w-full h-fit rounded-3xl'
              src={imgFeature}
              alt='Kaiber Feature'
            />
            {/*<img className='flex absolute top-0 left-0 z-20 flex-1 max-w-xl min-w-[250px] sm:w-[40vw] w-full h-fit rounded-3xl' src={feature1} alt='Kaiber Feature' />
                    <img className='flex absolute top-0 left-0 z-20 flex-1 max-w-xl min-w-[250px] sm:w-[40vw] w-full h-fit rounded-3xl' src={feature1} alt='Kaiber Feature' />
                    */}
            <div className='flex z-10 sm:h-32 h-48 w-full -translate-y-10 bg-gradient-to-b from-black from-40% sm:hidden' />
          </div>
        </div>
        <div className='flex flex-1 flex-col min-h-screen mb-20 sm:mb-0 sm:gap-0 gap-32 items-center justify-center'>
          <HomeFeatureComponent
            forwardRef={ref1}
            scrollRef={refScroll1}
            title='Audioreactivity'
            description='Upload a song, add a touch of your artistic style, and let our audio analysis technology bring your beats to life.'
            buttonText='Feel the music'
          />
          {/* <HomeFeatureComponent
                        forwardRef={ref2}
                        scrollRef={refScroll2}
                        title='Animation'
                        description='Start with a few words or upload your own image, and watch your ideas turn into captivating visuals.'
                        buttonText='Ignite your imagination'
                    /> */}
          <HomeFeatureComponent
            forwardRef={ref3}
            scrollRef={refScroll3}
            title='Transform'
            description='Upload your videos and effortlessly transform them into entirely new styles and aesthetics.'
            buttonText='Transform your videos'
          />
        </div>
      </div>
    </div>
  )
}

export default HomeFeature
