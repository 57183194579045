import React from 'react'

interface FilterIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const FilterIcon = React.forwardRef<SVGSVGElement, FilterIconProps>(
  ({ size = '20', ...props }, ref) => (
    <>
      <svg
        ref={ref}
        stroke='currentColor'
        fill='none'
        height={size}
        width={size}
        viewBox='0 0 14 12'
        {...props}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.833496 1.16602H13.1668M4.8335 10.8327H9.16683M2.8335 5.99935H11.1668'
          stroke='currentColor'
          strokeLinecap='round'
        />
      </svg>
    </>
  ),
)
