import { FileTypeResult, fileTypeFromBlob } from 'file-type'
import { toast } from 'react-toastify'

import { MEDIA_CANVAS_DEFAULT_SIZE } from '../constants'
import { MediaType, LoadedFile, FileWithSource, Metadata } from '../types'
import {
  AUDIO_MIMETYPES,
  FILE_TYPES,
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
} from './constants'
import { getAutoScaledDimensions } from '../utils/mediaUtils'

/**
 * Returns whether the media type is valid.
 * If optional targetMediaType not provided, it checks against all valid media types.
 */
export const isValidMediaType = (
  mediaType: MediaType,
  targetMediaType?: MediaType,
): boolean =>
  targetMediaType ? mediaType === targetMediaType : mediaType in MediaType

export const isValidMimetype = (
  mimetype: string,
  targetFileType?: string,
): boolean => {
  const fileType = mimetype.split('/')[0]

  if (targetFileType && targetFileType !== fileType) return false

  switch (fileType) {
    case FILE_TYPES.IMAGE:
      return Object.values(IMAGE_MIMETYPES).includes(mimetype)
    case FILE_TYPES.AUDIO:
      return Object.values(AUDIO_MIMETYPES).includes(mimetype)
    case FILE_TYPES.VIDEO:
      return Object.values(VIDEO_MIMETYPES).includes(mimetype)
    default:
      return false
  }
}

export const processDroppedFiles = async (
  files: FileWithSource[],
  targetFileType?: string,
): Promise<LoadedFile[]> => {
  const validMimetypeFiles = files.filter((fileWithSource) => {
    if (isValidMimetype(fileWithSource.file.type, targetFileType)) return true
    toast.error(
      `Error processing file "${fileWithSource.file.name}". The file type "${fileWithSource.file.type}" is not supported.`,
    )
    return false
  })

  const fileLoadPromises = validMimetypeFiles.map((fileWithSource) => {
    const { file, source } = fileWithSource
    const fileType = file.type.split('/')[0]

    return new Promise<LoadedFile>((resolve, reject) => {
      switch (fileType) {
        case FILE_TYPES.IMAGE: {
          const img = new Image()
          img.onload = () => {
            const metadata: Metadata = {
              width: img.naturalWidth,
              height: img.naturalHeight,
            }
            const displayDimensions = getAutoScaledDimensions(
              img.naturalWidth,
              img.naturalHeight,
              MEDIA_CANVAS_DEFAULT_SIZE,
            )
            resolve({
              file,
              source,
              mediaType: MediaType.Image,
              metadata,
              displayDimensions,
            })
          }
          img.onerror = () =>
            reject(new Error(`Failed to load image: ${file.name}`))
          img.src = source
          break
        }

        case FILE_TYPES.VIDEO: {
          const video = document.createElement('video')
          video.onloadedmetadata = () => {
            const metadata: Metadata = {
              width: video.videoWidth,
              height: video.videoHeight,
              duration: video.duration,
            }
            const displayDimensions = getAutoScaledDimensions(
              video.videoWidth,
              video.videoHeight,
              MEDIA_CANVAS_DEFAULT_SIZE,
            )
            resolve({
              file,
              source,
              mediaType: MediaType.Video,
              metadata,
              displayDimensions,
            })
            video.remove()
            URL.revokeObjectURL(source)
          }
          video.onerror = () =>
            reject(new Error(`Failed to load video: ${file.name}`))
          video.src = source
          break
        }

        case FILE_TYPES.AUDIO: {
          const audio = new Audio()
          audio.onloadedmetadata = () => {
            const metadata: Metadata = {
              duration: audio.duration,
            }
            resolve({
              file,
              source,
              mediaType: MediaType.Audio,
              metadata,
            })
          }
          audio.onerror = () =>
            reject(new Error(`Failed to load audio: ${file.name}`))
          audio.src = source
          break
        }

        default: {
          toast.error(`Unsupported file type: ${file.type}`)
          reject(new Error(`Unsupported file type: ${file.type}`))
        }
      }
    })
  })

  try {
    return await Promise.all(fileLoadPromises)
  } catch (error) {
    console.error('Error loading files:', error)
    toast.error('An error occurred while loading some files. Please try again.')
    return []
  }
}

export const isFileUploaded = (file: unknown): file is File => {
  return file instanceof Blob
}

export const isFileListEmpty = (fileList: unknown): fileList is FileList => {
  return fileList instanceof FileList && fileList.length === 0
}

export const isFileUploadedOrFileListEmpty = (
  value: unknown,
): value is File | FileList => {
  return isFileUploaded(value) || isFileListEmpty(value)
}

export const getFileExtensionFromFilename = (filename: string) => {
  return filename
    .slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
    .split('?')[0]
}

export const getFileExtensionFromBlob = async (blob: Blob) => {
  const fileType = await fileTypeFromBlob(blob)
  return fileType?.ext
}

// TODO: update URL to proxy-file and determine content type dynamically in proxy server
export const getProxiedR2FileUrl = (url: string, isVideo = false): string =>
  url.replace(
    'https://secret-memories.3a0e8493c00fa2487b71580016cad807.r2.cloudflarestorage.com/',
    `https://proxy-${isVideo ? 'video' : 'image'}.kyber-corp-ent.workers.dev/`,
  )

/**
 * Returns a filename in the format of `<mime-type>_<date>.<extension>`
 *
 * Example: `image_2024-02-29T12:00:00.000Z.jpg`
 */
export const createFileNameFromFileType = async (fileType: FileTypeResult) => {
  const fileExtension = fileType.ext
  const currentDate = new Date().toISOString().replace(/[:.]/g, '-')

  return `${fileType.mime}_${currentDate}.${fileExtension}`
}

/**
 * Initializes a file from a blob.
 */
export const initializeFileFromBlob = async (blob: Blob) => {
  const fileType = await fileTypeFromBlob(blob)
  const fileName = await createFileNameFromFileType(fileType)
  const file = new File([blob], fileName, { type: fileType.mime })

  return file
}
