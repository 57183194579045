import { Modal } from './Modal'

const ChangeVersionModal = ({ isOpen, onClose, version, onContinue }) => {
  return (
    <Modal open={isOpen} setOpen={onClose} persistent>
      <section className='flex flex-col gap-3 items-center justify-center md:w-full'>
        <h2 className='text-white text-center md:w-72'>
          If you change to version {version}, you will lose your scenes. Are you
          sure you want to change your version?
        </h2>
        <button
          className='text-center w-48 mt-3 font-semibold md:w-52 px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-primary bg-primary hover:bg-primary/90 text-black'
          onClick={onContinue}
        >
          Continue
        </button>
        <button
          className='text-center w-48 mt-3 font-semibold md:w-52 px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-primary hover:bg-primary text-kaiberGreen hover:text-black'
          onClick={onClose}
        >
          Cancel
        </button>
      </section>
    </Modal>
  )
}

export { ChangeVersionModal }
