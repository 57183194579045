import { useThemeContext } from '../../../../context'
import { BaseComponentProps } from '../../../../types'
import { cn } from '../../../../utils'

interface IconWrapperProps extends BaseComponentProps {
  icon: React.ReactElement
}

export const IconWrapper = ({ icon, className }: IconWrapperProps) => {
  const { colors } = useThemeContext()

  return (
    <div
      className={cn(
        `flex items-center justify-center ${colors.background.element} rounded-full border ${colors.border.brand} ${colors.text.brand} p-1 shadow-k2-25 w-8 h-8`,
        className,
      )}
    >
      {icon}
    </div>
  )
}
