import { useState } from 'react'
import { toast } from 'react-toastify'
import http from '../../services/HttpService'

export const UpdateUserCreditsForm = ({ user, setUser }) => {
  const [isUpdatingUserCredits, setIsUpdatingUserCredits] = useState(false)

  const handleUpdateUserCredits = async (e) => {
    e.preventDefault()

    const credits = +e.target[0].value

    setIsUpdatingUserCredits(true)

    await http.patch(`/api/admin/users/${user.distinctId}/credits`, {
      credits,
    })

    setIsUpdatingUserCredits(false)

    toast.success(`User ${user.name || user.email} updated.`)

    setUser((user) => ({
      ...user,
      credits,
    }))
  }

  return (
    <form onSubmit={handleUpdateUserCredits}>
      <div className='mb-4'>
        <input
          type='number'
          defaultValue={user.credits}
          min='0'
          step='1'
          required
          className={`${
            isUpdatingUserCredits ? 'opacity-50 ' : ''
          }w-full p-3 mt-3 block border-transparent border rounded-md bg-[#545854]/25 text-white`}
          disabled={isUpdatingUserCredits}
        />
      </div>
      <button
        type='submit'
        className={`${
          isUpdatingUserCredits ? 'opacity-50 ' : ''
        }w-40 rounded-full border bg-primary border-primary p-4 text-sm font-bold text-gray-900 shadow-sm uppercase`}
        disabled={isUpdatingUserCredits}
      >
        {isUpdatingUserCredits ? (
          <div className='m-auto animate-spin rounded-full h-5 w-5 border-b-2 border-[#0B0B0B]'></div>
        ) : (
          'Save Changes'
        )}
      </button>
    </form>
  )
}
