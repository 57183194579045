interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const PromptElementIcon = (props: SVGProps) => {
  return (
    <svg
      width='75'
      height='42'
      viewBox='0 0 75 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.83479 38.8222C10.3946 36.7618 12.3138 33.9492 13.8449 30.8399C15.373 27.7366 16.5338 24.2996 16.5338 21C16.5338 17.7005 15.373 14.2634 13.8449 11.1601C12.3141 8.05141 10.3953 5.23927 8.83565 3.17897C8.01626 2.08297 8.79804 0.5 10.292 0.5H51.4934C52.034 0.5 52.5441 0.738045 52.8769 1.12599C54.0383 2.48149 56.4529 5.46005 58.5746 9.10882C60.7037 12.7701 62.5 17.0427 62.5 21C62.5 24.9573 60.7037 29.2299 58.5746 32.8912C56.4527 36.5402 54.038 39.5189 52.8767 40.8742L52.8734 40.8781C52.5449 41.2696 52.039 41.5 51.4934 41.5H10.292C8.79708 41.5 8.01781 39.9061 8.83479 38.8222ZM8.83479 38.8222L8.43614 38.5204M8.83479 38.8222C8.83495 38.8219 8.83512 38.8217 8.83528 38.8215L8.43614 38.5204M8.43614 38.5204C11.5332 34.4293 16.0338 27.3812 16.0338 21C16.0338 14.6189 11.5332 7.57068 8.43614 3.47962L53.2564 41.1996C52.8272 41.711 52.1777 42 51.4934 42H10.292C8.42451 42 7.34579 39.9656 8.43614 38.5204Z'
        fill='white'
        fillOpacity='0.75'
        stroke='url(#paint0_linear_4995_12093)'
      />
      <g filter='url(#filter0_b_4995_12093)'>
        <rect
          x='3'
          y='15'
          width='6'
          height='12'
          rx='2'
          fill='white'
          fillOpacity='0.75'
        />
        <rect
          x='3.375'
          y='15.375'
          width='5.25'
          height='11.25'
          rx='1.625'
          stroke='url(#paint1_linear_4995_12093)'
          strokeOpacity='0.7'
          strokeWidth='0.75'
        />
      </g>
      <g filter='url(#filter1_b_4995_12093)'>
        <rect
          x='57'
          y='9'
          width='12'
          height='24'
          rx='2'
          fill='white'
          fillOpacity='0.75'
        />
        <rect
          x='57.375'
          y='9.375'
          width='11.25'
          height='23.25'
          rx='1.625'
          stroke='url(#paint2_linear_4995_12093)'
          strokeOpacity='0.7'
          strokeWidth='0.75'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_4995_12093'
          x='-1'
          y='11'
          width='14'
          height='20'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_4995_12093'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_4995_12093'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_b_4995_12093'
          x='53'
          y='5'
          width='20'
          height='32'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='2' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_4995_12093'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_4995_12093'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_4995_12093'
          x1='8'
          y1='0'
          x2='23.7827'
          y2='53.557'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4995_12093'
          x1='1.61455'
          y1='12.2291'
          x2='13.4313'
          y2='18.1375'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.47' stopColor='#D3D3D3' stopOpacity='0.791518' />
          <stop offset='0.842645' stopColor='#F2F2F2' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4995_12093'
          x1='54.2291'
          y1='3.45818'
          x2='77.8625'
          y2='15.2749'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.47' stopColor='#D3D3D3' stopOpacity='0.791518' />
          <stop offset='0.842645' stopColor='#F2F2F2' />
        </linearGradient>
      </defs>
    </svg>
  )
}
