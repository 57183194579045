import { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { useUserAccountContext } from '../context/userContext'
import { updateReferrerId } from '../services/AuthService'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'

export default function ReferrerIdForm() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [referrerId, setReferrerId] = useState('')
  const [errors, setErrors] = useState({})

  const { currentUser, setCurrentUser } = useUserAccountContext()

  const formRef = useRef()
  const mixpanel = useMixpanel()

  const clearError = (field) => {
    setErrors((errors) => ({
      ...errors,
      [field]: null,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setErrors({})

    try {
      const response = await updateReferrerId(referrerId)

      setCurrentUser((currentUser) => ({
        ...currentUser,
        referrerId,
      }))

      formRef.current.reset()

      trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.CLICK_REFERRED_BY, {
        referred_by: referrerId,
      })

      toast.success(response.data.message)
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            setErrors(err.response.data.errors)
            break
          default:
            toast.error('Oops! Something went wrong.')
        }
      } else {
        toast.error(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const removeBaseUrlFromReferrerId = (value) => {
    const baseUrl = `${process.env.REACT_APP_SITE_URL}?referrer_id=`

    return value.replace(baseUrl, '')
  }

  const handleReferrerIdInput = (e) => {
    setReferrerId(removeBaseUrlFromReferrerId(e.target.value))
    clearError('referrerId')
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className='mb-4'>
        <label htmlFor='referrer-id' className='block text-tertiary text-2xl'>
          Referred by
        </label>

        <p className='text-tertiary/60 mt-2'>Enter referrer code below</p>

        <input
          type='text'
          id='referrer-id'
          placeholder='Ex: 11d7e527-51ef-ff5a-a3e5-5a53d632db9a'
          onInput={handleReferrerIdInput}
          required
          value={currentUser.referrerId || referrerId}
          className={`w-full mt-3 block ${
            errors.referrerId ? 'border-red-700' : 'border-transparent'
          } ${
            !!currentUser.referrerId ? 'text-tertiary/80' : 'text-white'
          } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
          disabled={isSubmitting || !!currentUser.referrerId}
        />
        {errors.referrerId && (
          <span className='text-red-500'>{errors.referrerId}</span>
        )}
      </div>
      <button
        type='submit'
        className={`${
          isSubmitting || !!currentUser.referrerId
            ? 'bg-primary/25 text-black/50'
            : 'bg-kaiberGreen'
        } rounded-full px-8 font-bold py-2 font-sans text-base text-black hover:bg-white`}
        disabled={isSubmitting || !!currentUser.referrerId}
      >
        Save
      </button>
      {/* <ul className='list-disc ml-7 text-tertiary/60'>
        <li>Your own referral code isn't allowed</li>
        <li>You and your friend will earn 300 extra credits if you pay for a plan</li>
      </ul> */}
    </form>
  )
}
