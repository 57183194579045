import { useState } from 'react'
import { cn } from '../utils'

export default function Tooltip({
  content,
  className = '',
  contentClassName = '',
  children,
}) {
  const [hover, setHover] = useState(false)

  const onMouseEnter = (e) => {
    setHover(true)
  }

  const onMouseLeave = (e) => {
    setHover(false)
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn('relative', className)}
    >
      <div
        className={cn(
          'invisible opacity-0 absolute w-44 left-1/2 -translate-x-1/2 bottom-8 bg-primary p-4 rounded-md text-sm text-gray-900 font-medium shadow-sm duration-100',
          { 'visible opacity-100': hover },
          contentClassName,
        )}
      >
        {content}
      </div>
      {children}
    </div>
  )
}
