export const ProfileIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M11.5413 4.20832H6.3469C5.30369 4.20832 4.45801 5.054 4.45801 6.09721V17.9028C4.45801 18.946 5.30369 19.7916 6.3469 19.7916H7.76967M7.76967 19.7916C7.89245 17.4238 9.85127 15.5416 12.2497 15.5416C14.6481 15.5416 16.6069 17.4238 16.7296 19.7916M7.76967 19.7916H16.7296M20.0413 12.7083V17.9028C20.0413 18.946 19.1957 19.7916 18.1525 19.7916H16.7296M18.8608 2.31943L19.8839 4.36573L21.9302 5.38887L19.8839 6.41202L18.8608 8.45832L17.8377 6.41202L15.7913 5.38887L17.8377 4.36573L18.8608 2.31943ZM14.8469 10.5833C14.8469 12.0177 13.6841 13.1805 12.2497 13.1805C10.8153 13.1805 9.65245 12.0177 9.65245 10.5833C9.65245 9.14891 10.8153 7.98609 12.2497 7.98609C13.6841 7.98609 14.8469 9.14891 14.8469 10.5833Z'
      stroke='#D9E123'
      strokeWidth='1.41667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
