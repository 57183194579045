export const TextIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    className={className}
    stroke='currentColor'
  >
    <path
      d='M4.45801 6.56949V4.20837H12.2497M12.2497 4.20837H20.0413V6.56949M12.2497 4.20837V19.7917M12.2497 19.7917H10.1247M12.2497 19.7917H14.3747'
      strokeWidth='1.41667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
