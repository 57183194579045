import TeamMember from './TeamMember'

const teamAvatar1 =
  'https://website-public-assets.kybercorp.org/web/about/victor.jpeg'
const teamAvatar2 =
  'https://website-public-assets.kybercorp.org/web/about/eric.gif'
const teamAvatar3 =
  'https://website-public-assets.kybercorp.org/web/about/ede.jpg'
const teamAvatar4 =
  'https://website-public-assets.kybercorp.org/web/about/christine.jpg'
const teamAvatar5 =
  'https://website-public-assets.kybercorp.org/web/about/jacky.jpg'
const teamAvatar6 =
  'https://website-public-assets.kybercorp.org/web/about/kyt.jpg'
const teamAvatar7 =
  'https://website-public-assets.kybercorp.org/web/about/gavin.jpg'
const teamAvatar8 =
  'https://website-public-assets.kybercorp.org/web/about/jimmi.jpg'
const teamAvatar9 =
  'https://website-public-assets.kybercorp.org/web/about/phil.jpg'
const teamAvatar10 =
  'https://website-public-assets.kybercorp.org/web/about/steve.jpg'
const teamAvatar11 =
  'https://website-public-assets.kybercorp.org/web/about/paul.jpg'
const teamAvatar12 =
  'https://website-public-assets.kybercorp.org/web/about/michael.jpg'
const teamAvatar13 =
  'https://website-public-assets.kybercorp.org/web/about/cody.jpg'
const teamAvatar14 =
  'https://website-public-assets.kybercorp.org/web/about/alan.jpg'
const teamAvatar15 =
  'https://website-public-assets.kybercorp.org/web/about/tuesday.jpg'
const teamAvatar16 =
  'https://website-public-assets.kybercorp.org/web/about/leslie.jpg'

function Team() {
  return (
    <div className='flex flex-col gap-14 relative pb-28'>
      <h2 className='text-5xl text-white font-semibold'>Our Team</h2>
      <div className='grid gap-10 w-full mx-auto sm:grid-cols-2 lg:grid-cols-3 '>
        <TeamMember
          avatar={teamAvatar1}
          name='Victor Wang'
          job='Co-Founder and CEO'
          twitterLink='https://twitter.com/stokebuilder'
        />
        <TeamMember
          avatar={teamAvatar2}
          name='Eric Gao'
          job='Co-Founder and CTO'
          twitterLink='https://twitter.com/itsoksami'
        />
        <TeamMember
          avatar={teamAvatar3}
          name='Ede Schweizer'
          job='Co-Founder and Chief Design Officer'
        />
        <TeamMember
          avatar={teamAvatar4}
          name='Christine Zuniga'
          job='Co-Founder and Head of Marketing'
          igLink='https://www.instagram.com/kaiberchristine/'
        />
        <TeamMember
          avatar={teamAvatar5}
          name='Jacky Lu'
          job='Co-Founder and Head of Creative'
          igLink='https://www.instagram.com/jackylu97/'
        />
        <TeamMember
          avatar={teamAvatar6}
          name='Kyt Janae'
          job='Head of Kaiber Studio'
        />
        <TeamMember
          avatar={teamAvatar7}
          name='Gavin Liu'
          job='Head of Engineering'
        />
        <TeamMember
          avatar={teamAvatar8}
          name='Jimmi English'
          job='Head of Analytics'
        />
        <TeamMember
          avatar={teamAvatar9}
          name='Phil Woodward'
          job='Head of Customer Success'
        />

        <TeamMember
          avatar={teamAvatar10}
          name='Steve Burstyn'
          job='Head of Ops + Biz Dev'
        />
        <TeamMember
          avatar={teamAvatar11}
          name='Paul Yun'
          job='Principal Software Engineer'
        />
        <TeamMember
          avatar={teamAvatar12}
          name='Michael Shin'
          job='Principal Mobile Engineer'
        />
        <TeamMember
          avatar={teamAvatar13}
          name='Cody Douglass'
          job='Senior Software Engineer'
        />
        <TeamMember
          avatar={teamAvatar14}
          name='Alan Amaya'
          job='Product Designer'
        />
        <TeamMember
          avatar={teamAvatar15}
          name='Tuesday Becker'
          job='Content Marketing Coordinator'
        />
        <TeamMember
          avatar={teamAvatar16}
          name='Leslie Ho'
          job='Design Intern'
        />
      </div>
    </div>
  )
}

export default Team
