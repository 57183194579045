import { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  verifyEmail,
  resendEmailVerificationEmail,
} from '../../services/AuthService'
import { Alert } from '../../components/Alert'
import Lottie from 'lottie-react'
import LoadingWhite from '../../images/lottie/loaderWhite.json'
import { accountCreation } from '../../utils/impact_util'

export default function EmailVerification() {
  const { emailVerificationToken } = useParams()
  const [isVerifying, setIsVerifying] = useState(true)
  const [isVerified, setIsVerified] = useState(false)
  const [isResending, setIsResending] = useState(false)
  const [isResent, setIsResent] = useState(false)
  const [showResendButton, setShowResendButton] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const handleVerifyEmail = useCallback(async () => {
    try {
      const { user } = await verifyEmail(emailVerificationToken)

      setIsVerified(true)
      setShowSuccess(true)
      setSuccessMessage('Your email has been verified.')

      accountCreation({
        user,
        orderId: '',
      })
    } catch (err) {
      setShowAlert(true)

      if (err.response) {
        setAlertMessage(err.response.data.message)
        setShowResendButton(true)
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    } finally {
      setIsVerifying(false)
    }
  }, [emailVerificationToken])

  const handleResendEmailVerification = async () => {
    try {
      // setShowAlert(false)
      setIsResending(true)

      await resendEmailVerificationEmail(emailVerificationToken)

      setShowAlert(false)
      setIsResent(true)
      setShowSuccess(true)
      setSuccessMessage(`We've sent an email to verify your account.`)
    } catch (err) {
      // setShowAlert(true)

      if (err.response) {
        setAlertMessage(err.response.data.message)
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    } finally {
      setIsResending(false)
    }
  }

  useEffect(() => {
    handleVerifyEmail()
  }, [handleVerifyEmail])

  return (
    <div className='sm:p-10 flex h-full bg-darkGray sm:bg-transparent overflow-y-auto'>
      {isVerifying ? (
        <div className='flex flex-col items-center space-y-4 m-auto'>
          <div className='bg-darkGray p-6 rounded-3xl'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        </div>
      ) : (
        <div className='w-full sm:max-w-sm bg-darkGray sm:rounded-3xl m-auto p-10'>
          {showAlert && (
            <div className={`mb-4${isResending ? ' invisible' : ''}`}>
              <Alert open={showAlert} message={alertMessage} type='alert' />
            </div>
          )}

          <Alert open={showSuccess} message={successMessage} type='success' />

          {isVerified && (
            <div className='text-center text-tertiary/80 mt-4 text-lg'>
              <a href='/onboarding' className='hover:text-primary'>
                Click here to login
              </a>
            </div>
          )}

          {showResendButton && !isResent && (
            <button
              type='button'
              className='text-center block w-full rounded-full border px-4 py-4 text-sm font-bold text-white shadow-sm border-primary/25 uppercase'
              onClick={handleResendEmailVerification}
              disabled={isResending}
            >
              {isResending ? (
                <div className='flex justify-center'>
                  <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-primary'></div>
                </div>
              ) : (
                'Resend Email Verification'
              )}
            </button>
          )}
        </div>
      )}
    </div>
  )
}
