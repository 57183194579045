import { RefObject, useEffect, useCallback } from 'react'

interface UseHorizontalScrollOptions {
  scrollSpeed?: number
  showScrollbar?: boolean
  showFades?: boolean
}

const SCROLL_SPEED = 0.3

export const useHorizontalScroll = (
  ref: RefObject<HTMLDivElement>,
  {
    scrollSpeed = SCROLL_SPEED,
    showScrollbar = false,
    showFades = true,
  }: UseHorizontalScrollOptions = {},
): void => {
  const applyScrollbarStyles = useCallback(() => {
    if (ref.current) {
      const style = ref.current.style as any
      if (!showScrollbar) {
        style.scrollbarWidth = 'none' // Firefox
        style.msOverflowStyle = 'none' // IE 10+
      } else {
        style.scrollbarWidth = ''
        style.msOverflowStyle = ''
      }
      // For Webkit browsers (Chrome, Safari, etc.)
      const styleElement = document.createElement('style')
      styleElement.textContent = showScrollbar
        ? ''
        : `
          #${ref.current.id}::-webkit-scrollbar {
            display: none;
          }
        `
      document.head.appendChild(styleElement)
      return () => {
        document.head.removeChild(styleElement)
      }
    }
  }, [showScrollbar, ref])

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const maskSizeFactor = 0.1 // 10% of the container width

    const updateMask = () => {
      if (!showFades) return
      const { scrollLeft, scrollWidth, clientWidth } = element
      const maskSize = Math.round(clientWidth * maskSizeFactor)
      const totalOverflow = scrollWidth - clientWidth
      const leftMaskSize = Math.min(scrollLeft, maskSize)
      const rightMaskSize = Math.min(totalOverflow - scrollLeft, maskSize)

      // Ensure we always have some right fade on initial load if there's overflow
      const initialRightMaskSize =
        totalOverflow > 0 ? Math.max(rightMaskSize, maskSize / 2) : 0

      element.style.maskImage = `linear-gradient(to right, 
        transparent, 
        black ${leftMaskSize}px, 
        black calc(100% - ${initialRightMaskSize}px), 
        transparent
      )`
      element.style.webkitMaskImage = element.style.maskImage
    }

    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY !== 0) {
        event.preventDefault()
        element.scrollLeft += event.deltaY * scrollSpeed
      }
    }

    const cleanup = applyScrollbarStyles()
    element.addEventListener('wheel', handleWheel, { passive: false })
    element.addEventListener('scroll', updateMask)

    // Initial mask application
    updateMask()

    return () => {
      if (cleanup) cleanup()
      element.removeEventListener('wheel', handleWheel)
      element.removeEventListener('scroll', updateMask)
      element.style.maskImage = ''
      element.style.webkitMaskImage = ''
    }
  }, [ref, scrollSpeed, showScrollbar, showFades, applyScrollbarStyles])
}
