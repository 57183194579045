import { useCallback, useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import AdminNavbar from '../../components/AdminNavbar'
import Footer from '../../components/Footer'
import http from '../../services/HttpService'
import { toast } from 'react-toastify'
import { Editor } from '@tinymce/tinymce-react'
import { AnnouncementsModal } from '../../components/AnnouncementsModal'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function AnnouncementsEditor() {
  const [isLoading, setIsLoading] = useState(true)
  const [announcement, setAnnouncement] = useState(null)
  const [announcementsModal, setAnnouncementsModal] = useState(false)

  const editorRef = useRef(null)
  const footerEditorRef = useRef(null)

  const { distinctId } = useParams()

  // TODO: Determine if/why we need this useState instead of just pulling from params directly
  const [localDistinctId] = useState(distinctId)

  const typeOptions = [
    { key: 'K1', value: 'K1' },
    { key: 'K2', value: 'K2' },
  ]

  const setAnnouncementId = (newId) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      _id: newId,
    }))
  }

  const setAnnouncementTitle = (newTitle) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      title: newTitle,
    }))
  }

  const setAnnouncementHeaderText = (newText) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      headerText: newText,
    }))
  }

  const setAnnouncementType = (newType) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      type: newType,
    }))
  }

  const setPublishAt = (newDate) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      publishAt: newDate,
    }))
  }

  const setHeaderImage = (newImage) => {
    setAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      headerImage: newImage,
    }))
  }

  const fetchAnnouncement = useCallback(async () => {
    try {
      const response = await http.get(
        `/api/admin/announcements/${localDistinctId}`,
      )
      const fetchedAnnouncement = response.data.announcement
      fetchedAnnouncement.publishAt = formatDate(fetchedAnnouncement.publishAt)
      setAnnouncement(fetchedAnnouncement)
    } catch (error) {
      console.log(error)
    }
  }, [localDistinctId])

  const loadEditor = useCallback(async () => {
    setIsLoading(true)
    if (localDistinctId) {
      fetchAnnouncement()
    } else {
      setAnnouncement({
        footerText: `<p className='text-tertiary/80 justify-center flex text-center'>
        🚀 &nbsp<a className='underline' href='https://discord.com/invite/kaiber' target='_blank' rel='noopener noreferrer'>Join the Discord</a>&nbspfor more updates &nbsp🚀
        </p>`,
        announcementHtml:
          '<p><strong>- New Feature Title.</strong>&nbspDescription of the new feature that explains what it is and how to use it</p>',
      })
    }
    setIsLoading(false)
  }, [fetchAnnouncement, localDistinctId])

  const handleContentChange = () => {
    announcement.announcementHtml = editorRef.current.getContent()
  }
  const handleFooterChange = () => {
    announcement.footerText = footerEditorRef.current.getContent()
  }

  const handleSaveAnnouncement = async () => {
    try {
      let announcementSaved = await http.post(`/api/admin/announcement-save`, {
        announcementHtml: editorRef.current.getContent(),
        announcementId: announcement?._id,
        announcementTitle: announcement?.title,
        announcementPublishAt: announcement?.publishAt,
        announcementHeaderImage: announcement?.headerImage,
        announcementFooterText: footerEditorRef.current.getContent(),
        announcementHeaderText: announcement?.headerText,
        announcementType: announcement?.type ?? typeOptions[0].value,
      })
      toast.success('The announcement has been saved.')
      if (announcement._id !== announcementSaved.data.notId)
        setAnnouncementId(announcementSaved.data.notId)
    } catch (error) {
      console.log(error)
    }
  }

  const formatDate = (publishAt) => {
    const dateObj = new Date(publishAt)
    return dateObj
  }

  const showPreview = () => {
    setAnnouncementsModal(true)
  }

  useEffect(() => {
    loadEditor()
  }, [loadEditor])

  return (
    <div className='bg-dark'>
      <AdminNavbar />
      <div className='section py-10'>
        <div className='container mx-auto max-w-7xl space-y-8 px-5'>
          {isLoading ? (
            <div className='flex h-full'>
              <div className='m-auto bg-darkGray p-6 rounded-3xl'>
                <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
              </div>
            </div>
          ) : (
            <>
              <Link
                to='/admin/announcements'
                className='inline-flex gap-2 items-center text-tertiary/80 hover:text-white'
              >
                <ArrowLeftIcon className='h-4 w-4' aria-hidden='true' />
                Back to Announcements
              </Link>
              <div className='flex items-center justify-center'>
                {/* Your image goes here */}
                <div className='w-full sm:w-1/2	'>
                  <div className='mb-4'>
                    <label className='block text-tertiary px-5 font-light'>
                      <strong>Header Text</strong>
                    </label>
                    <input
                      type='text'
                      id='headerText'
                      value={announcement?.headerText || ''}
                      onChange={(e) =>
                        setAnnouncementHeaderText(e.target.value)
                      }
                      placeholder={`✨ What's new ✨`}
                      required
                      className={`w-full mt-3 block ${
                        true ? 'border-red-700' : 'border-transparent'
                      } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='block text-tertiary px-5 font-light'>
                      <strong>Header Image</strong>
                    </label>
                    <input
                      type='text'
                      id='image'
                      value={announcement?.headerImage || ''}
                      onChange={(e) => setHeaderImage(e.target.value)}
                      placeholder={'Enter the header image url.'}
                      required
                      className={`w-full mt-3 block ${
                        true ? 'border-red-700' : 'border-transparent'
                      } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='block text-tertiary px-5 font-light'>
                      <strong>Announcement Title</strong>
                    </label>
                    <input
                      type='text'
                      id='title'
                      value={announcement?.title || ''}
                      onChange={(e) => setAnnouncementTitle(e.target.value)}
                      placeholder={'Updates'}
                      required
                      className={`w-full mt-3 block ${
                        true ? 'border-red-700' : 'border-transparent'
                      } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='block text-tertiary px-5 font-light'>
                      <strong>Announcement Type</strong>
                    </label>
                    <select
                      className={`w-full mt-3 block ${
                        true ? 'border-red-700' : 'border-transparent'
                      } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                      value={announcement?.type || typeOptions[0].value}
                      onChange={(e) => setAnnouncementType(e.target.value)}
                    >
                      {typeOptions.map((value, index) => (
                        <option key={`type-${index}`} value={value.key}>
                          {value.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='mb-4'>
                    <label className='block text-tertiary px-5 font-light'>
                      <strong>Publish Date</strong>
                    </label>
                    <DatePicker
                      className={`relative mt-3 block ${
                        true ? 'border-red-700' : 'border-transparent'
                      } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                      showTimeSelect
                      selected={announcement?.publishAt || ''}
                      onChange={(date) => setPublishAt(date)}
                      dateFormat='M/dd/yy h:mm a'
                    />
                  </div>

                  <div className='flex items-center justify-evenly  mt-5 text-kaiberGreen'>
                    <button
                      type='button'
                      className='inline-flex px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-primary hover:bg-primary hover:text-black w-fit'
                      onClick={showPreview}
                    >
                      Preview
                    </button>
                    <button
                      type='button'
                      className='inline-flex px-5 py-2 transition-all duration-200 ease-in-out border rounded-full buttonPrompt border-primary hover:bg-primary hover:text-black w-fit'
                      onClick={handleSaveAnnouncement}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>

              {!isLoading && announcement?.announcementHtml && (
                <div className='z-0 relative'>
                  <label className='block text-tertiary px-5 font-light'>
                    <strong>Announcements Editor</strong>
                  </label>
                  <Editor
                    apiKey={process.env.TINYMCE_API_KEY}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={announcement?.announcementHtml}
                    onEditorChange={handleContentChange}
                    init={{
                      height: 500,
                      menubar: 'insert',
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | insertfile link image media | help',
                      content_style:
                        'body { font-family: Helvetica, Arial, sans-serif font-size: 14px }', // Dark background and white text background-color: #000 color: #FFF
                      theme: 'silver',
                      skin: 'oxide-dark', // Use the Oxide Dark skin
                      content_css: 'dark',
                    }}
                  />
                </div>
              )}

              {!isLoading && (
                <div>
                  <label className='block text-tertiary px-5 font-light'>
                    <strong>Footer Editor</strong>
                  </label>
                  <Editor
                    apiKey={process.env.TINYMCE_API_KEY}
                    onInit={(evt, editor) => (footerEditorRef.current = editor)}
                    initialValue={announcement?.footerText}
                    onEditorChange={handleFooterChange}
                    init={{
                      height: 200,
                      menubar: 'insert',
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | insertfile link image media | help',
                      content_style:
                        'body { font-family: Helvetica, Arial, sans-serif font-size: 14px }', // Dark background and white text background-color: #000 color: #FFF
                      theme: 'silver',
                      skin: 'oxide-dark', // Use the Oxide Dark skin
                      content_css: 'dark',
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {announcement && (
        <AnnouncementsModal
          isOpen={announcementsModal}
          setIsOpen={setAnnouncementsModal}
          announcement={announcement}
        />
      )}
      ,
      <Footer />
    </div>
  )
}
