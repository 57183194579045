import http from './HttpService'
import cookies from 'browser-cookies'

export const AUTH_ACCESS_TOKEN_KEY = 'AUTH_TOKEN'
export const AUTH_REFRESH_TOKEN_KEY = 'AUTH_REFRESH_TOKEN'
export const IMPERSONATING_ID_KEY = 'IMPERSONATING_ID_KEY'

/**
 * Gets the access token from local storage
 * @returns Access token from local storage
 */
export function getAccessToken() {
  return window.localStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
}

/**
 * Sets the access token in local storage
 * @param {string} token Access token to be set in local storage
 */
export function setAccessToken(token) {
  window.localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token)
}

/**
 * Removes the access token from local storage
 */
export function removeAccessToken() {
  window.localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY)
}

/**
 * Gets the refresh token from local storage
 * @returns Refresh token from local storage
 */
export function getRefreshToken() {
  return window.localStorage.getItem(AUTH_REFRESH_TOKEN_KEY)
}

/**
 * Sets the refresh token in local storage
 * @param {string} token Refresh token to be set in local storage
 */
export function setRefreshToken(token) {
  window.localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, token)
}

/**
 * Removes the refresh token from local storage
 */
export function removeRefreshToken() {
  window.localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY)
}

/**
 * Sets the access and refresh tokens in local storage
 * @param {Object} param0 Tokens
 * @param {string} param0.token Access token to be set in local storage
 * @param {string} param0.refreshToken Refresh token to be set in local storage
 */
export function setAuthTokens({ token, refreshToken }) {
  if (token) {
    setAccessToken(token)
  }
  if (refreshToken && refreshToken !== 'undefined') {
    setRefreshToken(refreshToken)
  }
}

export function getAuthTokens() {
  return {
    token: getAccessToken(),
    refreshToken: getRefreshToken(),
  }
}

/**
 * Removes the access and refresh tokens from local storage
 */
export function removeAuthTokens() {
  removeAccessToken()
  removeRefreshToken()
}

export async function getAffiliate(affiliateToken) {
  const response = await http.get('/api/affiliate', {
    params: {
      token: affiliateToken,
    },
  })

  return response
}

export async function getCurrentUser() {
  // done on auth request for linking to affiliate if cookie is present
  const affiliateLinkToken = cookies.get('affiliate')

  const authParams = affiliateLinkToken ? { affiliateLinkToken } : {}

  const response = await http.get('/api/auth/me', {
    params: authParams,
  })

  const { token, refreshToken } = response.data

  setAuthTokens({
    token,
    refreshToken,
  })

  return response
}

export async function updateCurrentUser({
  firstName,
  lastName,
  username,
  skippedOnboarding,
  videoCount,
}) {
  const response = await http.patch('/api/auth/me', {
    firstName,
    lastName,
    username,
    skippedOnboarding,
  })

  return response
}

export async function login(email, password) {
  const response = await http.post('/api/auth/login', {
    email,
    password,
  })

  const { token, refreshToken } = response.data

  setAccessToken(token)
  setRefreshToken(refreshToken)

  return response
}

export async function impersonateLogin(distinctId) {
  const response = await http.post('/api/admin/auth/login', {
    distinctId,
  })

  await logout() // logout of admin before impersonating user

  setAuthTokens({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  })

  window.localStorage.setItem(IMPERSONATING_ID_KEY, distinctId)

  return response
}

export async function logout() {
  const response = await http.post('/api/auth/logout')

  removeAuthTokens()

  const distinctId = window.localStorage.getItem(IMPERSONATING_ID_KEY) || false
  if (distinctId) {
    window.localStorage.removeItem(IMPERSONATING_ID_KEY)
  }

  return response
}

export async function verifyEmail(token) {
  const response = await http.post('/api/auth/email/verify', {
    token,
  })

  setAuthTokens({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  })

  return response.data
}

export function resendEmailVerificationEmail(token) {
  return http.post('/api/auth/email/resend', {
    token,
  })
}

export async function register(
  email,
  password,
  passwordConfirmation,
  newsletter,
  referrerId, // [TO BE DEPRECATED]
  rewardfulReferrerId, // [TO BE DEPRECATED]
  rewardfulObjectId, // [TO BE DEPRECATED]
  affiliateLinkToken, // [TO BE DEPRECATED]
  impactPartnerId,
  impactPartnerToken,
) {
  const response = await http.post('/api/auth/register', {
    email,
    password,
    passwordConfirmation,
    newsletter,
    referrerId, // [TO BE DEPRECATED]
    rewardfulReferrerId, // [TO BE DEPRECATED]
    affiliateLinkToken, // [TO BE DEPRECATED]
    impactPartnerId,
    impactPartnerToken,
  })

  return response
}

export function sendPasswordResetLink(email) {
  return http.post('/api/auth/password/forgot', {
    email,
  })
}

export async function resetPassword(
  token,
  email,
  newPassword,
  newPasswordConfirmation,
) {
  const response = await http.post('/api/auth/password/reset', {
    token,
    email,
    newPassword,
    newPasswordConfirmation,
  })

  setAuthTokens({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  })

  return response
}

export function updateCurrentPassword(
  currentPassword,
  newPassword,
  newPasswordConfirmation,
  disconnectFromOtherDevices,
) {
  return http.patch('/api/auth/myaccount/password/current', {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
    disconnectFromOtherDevices,
  })
}

export function updateReferrerId(referrerId) {
  return http.patch('/api/auth/myaccount/referrer-id', {
    referrerId,
  })
}

export function updateOnboardingCompleted() {
  return http.patch('/api/auth/myaccount/onboarding-completed')
}

// T-1758 - Added in June 2023 - pre-mid June users were using a broken version of Rewardful affiliate tracking.
// This brings those users up to date (by storing the correct Rewardful object ID)
export async function setRewardfulObjectId(rewardfulObjectId) {
  return http.patch('/api/auth/myaccount/rewardful-object-id', {
    rewardfulObjectId,
  })
}

export async function deleteMyAccount(currentPassword) {
  const response = await http.post('/api/auth/myaccount/delete', {
    currentPassword,
  })

  removeAuthTokens()

  const distinctId = window.localStorage.getItem(IMPERSONATING_ID_KEY) || false
  if (distinctId) {
    window.localStorage.removeItem(IMPERSONATING_ID_KEY)
  }

  return response
}

export function requestUserData() {
  return http.post('/api/auth/myaccount/user-data/request')
}

export function getGoogleAuthorisationURL() {
  return http.get('/api/auth/google')
}

export async function signInAndUpWithGoogle(
  querystring,
  newsletter,
  referrerId, // [TO BE DEPRECATED]
  rewardfulReferrerId, // [TO BE DEPRECATED]
  affiliateLinkToken, // [TO BE DEPRECATED]
  impactPartnerId,
  impactPartnerToken,
) {
  const data = {
    newsletter,
    referrerId, // [TO BE DEPRECATED]
    rewardfulReferrerId, // [TO BE DEPRECATED]
    affiliateLinkToken, // [TO BE DEPRECATED]
    impactPartnerId,
    impactPartnerToken,
  }

  const response = await http.post(
    `/api/auth/google/callback${querystring}`,
    data,
  )

  setAuthTokens({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  })

  return response
}

export async function signInAndUpWithApple(
  querystring,
  newsletter,
  referrerId,
  rewardfulReferrerId,
  rewardfulObjectId,
  code,
  user,
) {
  const data = {
    newsletter,
    referrerId,
    rewardfulReferrerId,
    rewardfulObjectId,
    token: code,
    user,
  }

  const response = await http.post(
    `/api/auth/apple/callback${querystring}`,
    data,
  )

  setAuthTokens({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  })

  return response
}
