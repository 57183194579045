import { Menu } from '@headlessui/react'
import React, { useState, useRef, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { RadioSelectButton } from './RatioSelectButton'
import {
  DEFAULT_FORM_VALUES,
  RATIO_LEFT_SETTING_CONFIG,
  RATIO_RIGHT_SETTING_CONFIG,
} from '../../../../constants'
import { useThemeContext } from '../../../../context'
import { ChevronDownIcon } from '../../../../images/icons/ChevronDownIcon'
import { cn } from '../../../../utils'
import { ElementFloatingWindow } from '../ElementFloatingWindow'
import { AspectRatio, FieldType } from '@/types'

interface RatioSelectProps {
  aspectRatio: AspectRatio
  disabled: boolean
  onRatioChange: (ratio: AspectRatio) => void
}

export const RatioSelect: React.FC<RatioSelectProps> = ({
  aspectRatio,
  disabled,
  onRatioChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const floatingWindowRef = useRef<HTMLDivElement>(null)
  const { colors } = useThemeContext()
  const { register } = useFormContext()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        floatingWindowRef.current &&
        !floatingWindowRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='flex items-center justify-between w-full'>
      <label
        className={cn(
          'text-sm text-gray-300 mr-2 capitalize',
          colors.text.subtle,
        )}
      >
        Aspect Ratio
      </label>
      <input
        type='hidden'
        {...register(FieldType.AspectRatio)}
        defaultValue={DEFAULT_FORM_VALUES.aspectRatio}
      />
      <Menu as='div' className='relative inline-block text-left'>
        <Menu.Button
          className={cn(
            `${colors.elevation.surface.sunken} ${colors.text.default} text-sm px-3 text-center rounded-full outline-none h-7 w-24`,
            'flex items-center justify-between',
            { 'opacity-50 cursor-not-allowed': disabled },
          )}
          disabled={disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className='flex-grow text-center'>{aspectRatio}</span>
          <ChevronDownIcon
            size={8}
            className={cn(
              'transition-transform duration-200 transform -rotate-90',
              {
                'rotate-90': isOpen,
              },
            )}
          />
        </Menu.Button>
      </Menu>
      {isOpen && (
        <div ref={floatingWindowRef}>
          <ElementFloatingWindow
            className={cn(
              `bg-opacity-50 border border-opacity-75 rounded-2xl ${colors.elevation.surface.sunken} bg-clip-padding backdrop-filter backdrop-blur-sm border-k2-gray-400`,
            )}
          >
            <div className='flex flex-row gap-2'>
              <div className='flex flex-col gap-2'>
                {RATIO_LEFT_SETTING_CONFIG.map((ratioConfigSetting) => (
                  <RadioSelectButton
                    key={ratioConfigSetting.id}
                    ratioSettingConfig={ratioConfigSetting}
                    currentAspectRatio={aspectRatio}
                    onRatioChange={onRatioChange}
                  />
                ))}
              </div>
              <div className='flex flex-col gap-1'>
                {RATIO_RIGHT_SETTING_CONFIG.map((ratioSettingConfig) => (
                  <RadioSelectButton
                    key={ratioSettingConfig.id}
                    ratioSettingConfig={ratioSettingConfig}
                    currentAspectRatio={aspectRatio}
                    onRatioChange={onRatioChange}
                  />
                ))}
              </div>
            </div>
          </ElementFloatingWindow>
        </div>
      )}
    </div>
  )
}
