import { useState } from 'react'
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import http from '../services/HttpService'

export default function EmailVerificationButton({ text }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailVerificationSent, setEmailVerificationSent] = useState(false)

  const handleResend = async () => {
    setIsSubmitting(true)

    try {
      await http.post('/api/auth/email/resend/from-account')
      setEmailVerificationSent(true)
    } catch (err) {
      if (err.response) {
        if (err.response.data.code === 'EMAIL_VERIFICATION_RESEND_ERROR') {
          setEmailVerificationSent(true)
        } else {
          toast.error(err.response.data.message || err.response.data.error)
        }
      } else {
        toast.error(err.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div
      className={`${
        emailVerificationSent
          ? 'border border-primary/25 bg-primary/5 text-primary'
          : 'bg-yellow-300'
      } text-body px-4 py-5 rounded-2xl flex flex-wrap items-center justify-between gap-4`}
    >
      {emailVerificationSent ? (
        <div className='flex gap-2 items-center'>
          <CheckCircleIcon className='w-5 h-5 shrink-0' />
          Email verification sent.
        </div>
      ) : (
        <>
          <div className='flex gap-2 items-center'>
            <ExclamationTriangleIcon className='w-5 h-5 shrink-0' />
            {text}
          </div>
          <button
            onClick={handleResend}
            className='flex justify-center w-32 px-4 py-2 text-yellow-900 rounded-full bg-yellow-100 text-sm font-medium font-sans whitespace-nowrap'
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-yellow-900'></div>
            ) : (
              'RESEND EMAIL'
            )}
          </button>
        </>
      )}
    </div>
  )
}
