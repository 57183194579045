import {
  ANIMATED_IMAGE_PROMPT_COPY,
  ANIMATED_IMAGE_PROMPT,
} from '../../utils/constants'

export const PhraseFilter = ({ prompt, canvasId, onClick }) => (
  <p className='cursor-default'>
    {prompt === ANIMATED_IMAGE_PROMPT ? (
      <span
        className='cursor-pointer hover:bg-black hover:underline'
        onClick={onClick}
      >
        {ANIMATED_IMAGE_PROMPT_COPY}
      </span>
    ) : (
      <>
        {prompt.split(',').map((phrase, i) => (
          <span key={`${canvasId}_${i}`}>
            <span
              className='cursor-pointer hover:bg-black hover:underline'
              onClick={onClick}
            >
              {phrase.trim()}
            </span>
            <span>{i !== prompt.split(',').length - 1 && ', '}</span>
          </span>
        ))}
      </>
    )}
  </p>
)
