import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import { Modal } from './Modal'
import { Alert } from '../components/Alert'
import { useReCaptcha } from '../hooks/useReCaptcha'
import { CREDITS, SUBSCRIPTIONS } from '../utils/constants'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'
import { getCurrentUser } from '../services/AuthService'
import Lottie from 'lottie-react'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import { Logtail } from '@logtail/browser'

export default function FreeUserModalComponent({ children }) {
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [didUserClose, setDidUserClose] = useState(false)
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const reCaptcha = useReCaptcha()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()
  const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN)

  const getReCaptchaToken = async () => {
    try {
      return await reCaptcha.getToken()
    } catch (err) {
      console.error(err)
      setShowAlert(true)
      setAlertMessage(err.message || 'Captcha error')
      return null
    }
  }

  const handleFreeTrial = async () => {
    try {
      setIsSubmitting(true)

      logtail.info(
        `${currentUser?.distinctId} felog FreeUserModalComponent.js calling handleFreeTrial()`,
        currentUser,
      )
      logtail.flush()

      let paddleCustomer = currentUser?.paddleCustomerId
        ? { id: currentUser?.paddleCustomerId }
        : { email: currentUser?.email }

      trackEvent(
        mixpanel,
        currentUser,
        MIXPANEL_EVENTS.SUBSCRIPTION_FLOW_STARTED,
        {
          subscription_tier: SUBSCRIPTIONS.EXPLORER_TIER_MONTHLY,
        },
      )

      // Fix for legacy users who don't have a paddle customer id
      // &&
      // always check users data from DB in case previous transaction failed
      // &&
      // Users who are forbidden by Paddle, shift them to use Stripe
      const paddleVerify = await http.get('/api/auth/paddle/verify')

      if (paddleVerify?.data?.state !== 'forbidden') {
        const response = await getCurrentUser()

        setCurrentUser((currentUser) => ({
          ...currentUser,
          ...response.data.user,
        }))

        const userResponse = response?.data?.user

        logtail.info(
          `${currentUser?.distinctId} felog FreeUserModalComponent.js handleFreeTrial() get current user userResponse`,
          userResponse,
        )
        logtail.flush()

        // if user is already subscribed, don't show the subscription modal, reload to update the modals
        if (userResponse?.subscriptionStatus) {
          logtail.info(
            `${currentUser?.distinctId} felog FreeUserModalComponent.js handleFreeTrial() user already subscribed -- force exit payment flow`,
            currentUser,
          )
          logtail.flush()

          window.location.reload()
          return
        }

        if (userResponse?.paddleCustomerId) {
          paddleCustomer = { id: userResponse?.paddleCustomerId }
        }

        logtail.info(
          `${currentUser?.distinctId} felog FreeUserModalComponent.js handleFreeTrial() new trial sub - open Paddle iframe`,
          currentUser,
        )
        logtail.flush()

        window.Paddle.Checkout.open({
          settings: {
            theme: 'dark',
            allowLogout: false,
          },
          customer: paddleCustomer,
          items: [
            {
              priceId:
                process.env.REACT_APP_PADDLE_EXPLORER_MONTHLY_PLAN_FREE_TRIAL,
            },
          ],
        })
      } else {
        const reCaptchaToken = await getReCaptchaToken()

        if (!reCaptchaToken) throw new Error('Captcha error')

        const response = await http.post('/api/create_subscription_session', {
          reCaptchaToken,
          tier: {
            name: 'explorer_tier_monthly',
          },
          redirectTo: '/create',
        })

        // mixpanel.track("Subscription Session Created", {
        //   pricingInterval: type,
        // });

        window.location.href = response.data
      }
    } catch (err) {
      console.log(err)
    }
  }

  const markAsRead = async () => {
    try {
      await http.patch('/api/auth/myaccount/free-user-popup')
    } catch (err) {}
  }

  const handleModalClose = () => {
    setOpen(false)
    setDidUserClose(true)
    if (currentUser.showFreeUserModal) {
      //only update the first time
      markAsRead()

      setTimeout(() => {
        setCurrentUser((currentUser) => ({
          ...currentUser,
          showFreeUserModal: false,
        }))

        if (
          currentUser.subscriptionType !== 'Standard' &&
          currentUser.credits !== null &&
          !(currentUser.credits > 0)
        ) {
          navigate('/dashboard')
        }
      }, 100)
    }
  }

  useEffect(() => {
    window.addEventListener('paddleCheckoutClosed', async (data) => {
      logtail.info(
        `${currentUser?.distinctId} felog FreeUserModalComponent.js addEventListener('paddleCheckoutClosed') Paddle iframe closed data`,
        { paddle: data },
      )
      logtail.flush()

      if (Object.keys(data.detail.data).length !== 0) {
        // will not be empty if user simply closes the modal (contains customer data).
        // This event gets triggered even if the user submits a transaction, but will be empty if the transaction is submitted.
        setIsSubmitting(false)
      }
    })
    window.addEventListener('paddleCheckoutCompleted', async (data) => {
      try {
        const checkoutData = data.detail

        logtail.info(
          `${currentUser?.distinctId} felog FreeUserModalComponent.js addEventListener('paddleCheckoutCompleted') Paddle iframe checkoutData`,
          { paddle: checkoutData },
        )
        logtail.flush()

        // store data into tempUser collection, route is /api/auth/paddle/checkout-completed
        http.post('/api/auth/paddle/checkout-completed', checkoutData)

        // confirm paddle transaction status
        if (checkoutData?.data?.transaction_id) {
          await http.post('/api/auth/paddle/confirm-transaction', {
            transactionId: checkoutData?.data?.transaction_id,
          })

          const response = await getCurrentUser()

          setIsSubmitting(false)
          setCurrentUser((currentUser) => ({
            ...currentUser,
            ...response.data.user,
          }))
        } else {
          throw new Error('There was an error processing your request.')
        }
      } catch (err) {
        logtail.info(
          `${currentUser?.distinctId} felog FreeUserModalComponent.js addEventListener('paddleCheckoutCompleted') error`,
          { user: currentUser, error: err },
        )
        logtail.flush()

        setIsSubmitting(false)
        if (err.response) {
          toast.error(
            err.response.data?.message ||
              err.response.data?.error ||
              err.response.data,
          )
        } else {
          toast.error(err.message)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentUser])

  const updateMemoriesCount = useCallback(
    async (memoriesCount) => {
      try {
        await http.patch('/api/auth/myaccount/update-last-memories-multiple', {
          lastCount: memoriesCount,
        })

        setCurrentUser((currentUser) => ({
          ...currentUser,
          lastMemoriesMultiple: memoriesCount,
        }))
      } catch (err) {}
    },
    [setCurrentUser],
  )

  const isMultipleOfNumber = useCallback(
    async (memoriesCount, lastMemoriesMultiple) => {
      if (memoriesCount > 0) {
        let result = memoriesCount
        const multiple = 3
        if (memoriesCount % multiple === 0) {
          // eslint-disable-next-line eqeqeq
          if (memoriesCount != lastMemoriesMultiple) {
            updateMemoriesCount(memoriesCount)
          }
          return true
        } else {
          let remainder = memoriesCount % multiple
          result -= remainder
          result = Math.round(result * 100) / 100

          if (
            ((lastMemoriesMultiple === null ||
              lastMemoriesMultiple === undefined) &&
              result >= multiple) ||
            (result !== lastMemoriesMultiple &&
              result > lastMemoriesMultiple + multiple)
          ) {
            updateMemoriesCount(result)
            return true
          }
        }
      }

      return false
    },
    [updateMemoriesCount],
  )

  const checkConditions = useCallback(async () => {
    try {
      if (currentUser?.isInitialFreeTrial) {
        const isMultiple = await isMultipleOfNumber(
          currentUser.memoriesCount,
          currentUser.lastMemoriesMultiple,
        )

        if (
          (isMultiple && didUserClose === false) ||
          (currentUser?.credits !== null && currentUser.credits < 3) ||
          currentUser?.showFreeUserModal
        ) {
          setOpen(true)
        }
      } else {
        if (currentUser?.credits && currentUser.credits > 0) {
          return
        } else if (
          currentUser.subscriptionType !== 'Standard' &&
          !currentUser.showFreeTrialUsageAlert
        ) {
          setOpen(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [currentUser, isMultipleOfNumber, didUserClose])

  useEffect(() => {
    checkConditions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (currentUser.subscriptionType === 'Standard') {
    return children
  }

  return (
    <>
      {children}

      <Modal open={open} setOpen={handleModalClose} persistent={isSubmitting}>
        <div className='mt-4 flex flex-col items-center justify-end gap-2'>
          {isSubmitting ? (
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          ) : (
            <div className='text-center px-6 py-6 sm:px-12 sm:py-10 w-full'>
              {showAlert && (
                <div className='mb-4'>
                  <Alert
                    open={showAlert}
                    setOpen={setShowAlert}
                    message={alertMessage}
                    type='alert'
                  />
                </div>
              )}

              <p className='text-4xl font-bold text-tertiary'>
                Unlock Your Full Creative Potential
              </p>
              {/* <p className='mt-4 text-tertiary/80'>
                Only active Kaiber subscribers can create videos, but your videos and credits are still in your account.
              </p> */}
              {/* <div className='mt-8'>
                {!!currentUser.emailVerifiedAt ? (
                  <CheckCircleIcon
                    className='w-8 h-8 inline text-primary'
                  />
                ) : (
                  <ExclamationTriangleIcon
                    className='w-8 h-8 inline text-yellow-300'
                  />
                )}
                <p className='text-tertiary text-2xl'>
                  Confirm your email
                </p>
                {!!currentUser.emailVerifiedAt ? (
                  <p className='text-tertiary/80'>
                    Your email is already confirmed.
                  </p>
                ) : isSubmittingVerificationEmail ? (
                  <div className='mt-4 flex justify-center'>
                    <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-primary'></div>
                  </div>
                ) : emailVerificationSent ? (
                  <p className='text-tertiary/80'>
                    Your verification email has been sent. Please check your inbox or spam folder.
                  </p>
                ) : (
                  <p className='text-tertiary/80'>
                    Check your inbox or{' '}
                    <button
                      onClick={handleResentVerificationEmail}
                      className='text-primary underline'
                    >
                      click here
                    </button>{' '}
                    to resend a verification email.
                  </p>
                )}
              </div> */}
              {!currentUser.freeTrialClaimed && (
                <>
                  <div className='mt-8'>
                    {/* <ExclamationTriangleIcon
                      className='w-8 h-8 inline text-yellow-300'
                    /> */}
                    <p className='text-tertiary text-2xl font-bold'>
                      🌟 Free 7-Day Explorer Access
                    </p>
                    <p className='text-white m-auto mt-[10px] mb-[25px] max-w-[295px]'>
                      Enjoy access to Kaiber{' '}
                      <Link
                        className='underline'
                        to='/pricing?interval=monthly'
                      >
                        Explorer features
                      </Link>{' '}
                      and longer videos, plus an additional {CREDITS.FREE_TRIAL}{' '}
                      credits on us!
                    </p>
                    <button
                      onClick={handleFreeTrial}
                      className='mt-4 rounded-full border radial-gradient px-4 py-4 text-sm font-bold text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase gap-2 border-primary'
                    >
                      START FREE TRIAL
                    </button>
                    <p className='text-white text-[10px] italic mt-[10px]'>
                      Credit card required.
                    </p>
                  </div>
                  <div className='mt-8'>
                    <p className='text-tertiary text-2xl'>or</p>
                  </div>
                </>
              )}
              {/* <div className='mt-8'>
                <p className='text-tertiary text-2xl'>
                  {!currentUser.freeTrialClaimed ? (
                    'or'
                  ) : (
                    'and'
                  )}
                </p>
              </div> */}
              <div className='mt-8'>
                <Link to='/pricing' className='text-2xl text-primary underline'>
                  Subscribe to Kaiber today
                </Link>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {reCaptcha.element}
    </>
  )
}
