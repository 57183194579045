import { Button as MTButton } from '@material-tailwind/react'
import { cn } from '../../utils'

interface ButtonProps {
  children?: React.ReactNode
  className?: string
  label?: string
  onClick?: () => void
  color?: 'primary' | 'secondary'
  size?: 'sm' | 'lg'
  shape?: 'pill' | 'rectangle'
  outline?: boolean
  disabled?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  label,
  onClick,
  color = 'primary',
  size = 'lg',
  shape = 'pill',
  outline = false,
  disabled = false,
}) => {
  return (
    <MTButton
      className={cn(
        'text-neutral-800',
        {
          'bg-kaiber-green': color === 'primary',
          'bg-kaiber-purple': color === 'secondary',
          'text-sm px-5 py-2': size === 'sm',
          'text-lg px-10 py-4': size === 'lg',
          'rounded-full': shape === 'pill',
          'rounded-lg': shape === 'rectangle',
          'outline outline-1 bg-transparent': outline,
          'outline-kaiber-green text-kaiber-green':
            outline && color === 'primary',
          'outline-kaiber-purple text-kaiber-purple':
            outline && color === 'secondary',
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children || label}
    </MTButton>
  )
}
