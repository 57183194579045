import { ThemeValues } from '../context'

export enum PresetCategory {
  KaiberFlows = 'kaiberFlows',
  UserFlows = 'userFlows',
}

export interface PresetOrdering {
  [PresetCategory.KaiberFlows]: string[]
  [PresetCategory.UserFlows]: string[]
}

export interface UserPreferences {
  presetOrdering?: PresetOrdering
  activeTheme?: ThemeValues
}

export interface User {
  distinctId: string
  subscriptionType: string
  subscriptionTierDescription: string
  subscriptionStatus: string
  onboardingCompletedAt?: string
  profilePicture?: string
  credits: number
  memoriesCount: number
  preferences?: UserPreferences
  uuid: string
}
