import { memo, useCallback, type KeyboardEvent } from 'react'

import { useThemeContext } from '../../../../context/themeContext'
import { cn } from '../../../../utils'
import { ScaleWrapper } from '../../ScaleWrapper'

interface IFlowNodeNameInputProps {
  name: string
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  setName: React.Dispatch<React.SetStateAction<string>>
  handleSaveName: () => void
  displayName: string
  borderColor: string
}

export const FlowNodeNameInput = memo(
  ({
    name,
    isEditing,
    setIsEditing,
    setName,
    handleSaveName,
    displayName,
    borderColor,
  }: IFlowNodeNameInputProps) => {
    const { colors } = useThemeContext()

    const handleNameKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && isEditing) {
          event.preventDefault()
          event.stopPropagation()
          event.nativeEvent.stopImmediatePropagation()
          handleSaveName()
        }
      },
      [isEditing, handleSaveName],
    )

    const handleNameChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
      },
      [setName],
    )

    const handleNameBlur = useCallback(() => {
      handleSaveName()
    }, [handleSaveName])

    const handleNameClick = useCallback(() => {
      setIsEditing(true)
    }, [setIsEditing])

    return (
      <ScaleWrapper className='absolute flex flex-row items-center gap-2 -top-12 left-0 origin-bottom-left'>
        {isEditing ? (
          <input
            type='text'
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            onKeyDown={handleNameKeyDown}
            className={cn(
              'bg-k2-gray-600 border border-solid border-white rounded-lg px-3 py-2 h-9 z-50',
              colors.background.element,
              colors.text.default,
            )}
            autoFocus
          />
        ) : (
          <div
            className={cn(
              `header bg-k2-gray-600 border border-solid ${borderColor} rounded-lg px-3 py-2 cursor-pointer h-9 flex items-center z-10`,
              colors.background.element,
              colors.text.default,
            )}
            onClick={handleNameClick}
          >
            {displayName}
          </div>
        )}
      </ScaleWrapper>
    )
  },
)

FlowNodeNameInput.displayName = 'FlowNodeNameInput'
