import { useCallback } from 'react'
import {
  Media,
  FileWithSource,
  TagNamespace,
  AnalyticsEvent,
  MediaUploadSource,
} from '@/types'
import { mediaStore, myLibraryStore } from '../../stores'
import { processDroppedFiles } from '../../utils/fileUtils'
import { saveMedia } from '../../api'
import { extractAssetKey, makeTempMediaId } from '../../utils/mediaUtils'
import { Status } from '@/types'
import { useAnalytics } from '../useAnalytics'
import { normalizeTagName } from '../../utils/mediaTagUtils'

export const useFileUpload = () => {
  const { trackEvent } = useAnalytics()

  const uploadAndProcessFiles = useCallback(
    async (
      filesWithSource: FileWithSource[],
      uploadSource: MediaUploadSource,
    ) => {
      const processedFiles = []
      const loadedFiles = await processDroppedFiles(filesWithSource)

      for (const loadedFile of loadedFiles) {
        const { file, source, mediaType, metadata, displayDimensions } =
          loadedFile

        // Check if the media already exists in the mediaStore
        let media: Media = mediaStore.findMediaBySource(source)
        let isNewMedia = false

        if (!media) {
          isNewMedia = true
          const assetKey = extractAssetKey(source)
          const tempMedia: Media = {
            mediaId: makeTempMediaId(),
            createdAt: new Date(),
            status: Status.Done,
            source,
            assetKey,
            type: mediaType,
            ...metadata,
          }
          mediaStore.setMedia(tempMedia)

          // If it's a new file, upload it
          media = await saveMedia(
            file,
            [
              {
                ns: TagNamespace.MediaType,
                name: normalizeTagName(mediaType),
              },
              { ns: TagNamespace.Uploaded, name: 'Yes' },
            ],
            metadata,
          )

          mediaStore.signalTempMediaMatured(tempMedia, media)
        }

        if (isNewMedia) {
          trackEvent(AnalyticsEvent.MediaUploaded, {
            mediaId: media.mediaId,
            mediaType: media.type,
            fileSize: file.size,
            fileType: file.type,
            uploadSource: uploadSource,
          })

          myLibraryStore.prependMediaId(media.mediaId)
        }

        processedFiles.push({ ...media, displayDimensions })
      }

      return processedFiles
    },
    [trackEvent],
  )

  return { uploadAndProcessFiles }
}
