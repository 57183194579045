import { SVGProps } from '@/types'

export const ReorderIcon = ({ size = 24, className }: SVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path d='M16.95 9L16.9499 5.91408C16.9499 5.56171 16.6108 5.27605 16.1924 5.27605L12.3287 5.27605M13.4651 6.55211L11.95 5.27605L13.4651 4M4.69995 20H8.69995C9.80452 20 10.7 19.1046 10.7 18V14C10.7 12.8954 9.80452 12 8.69995 12H4.69995C3.59538 12 2.69995 12.8954 2.69995 14V18C2.69995 19.1046 3.59538 20 4.69995 20ZM15.2 20H19.2C20.3045 20 21.2 19.1046 21.2 18V14C21.2 12.8954 20.3045 12 19.2 12H15.2C14.0954 12 13.2 12.8954 13.2 14V18C13.2 19.1046 14.0954 20 15.2 20Z' />
  </svg>
)
