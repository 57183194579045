import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'

function CarouselFeature({
  title,
  description,
  imgMobile,
  linkText,
  pathUrl,
  forwardRef,
  scrollRef,
}) {
  return (
    <div>
      <div
        ref={scrollRef}
        className='flex flex-1 flex-col sm:min-h-screen gap-20 justify-center sm:w-auto w-[calc(100vw-49px)] snap-start'
      >
        <div ref={forwardRef}>
          <div className='flex flex-col gap-10'>
            <h1 className='text-white font-semibold lg:text-4xl sm:text-3xl text-2xl'>
              {title}
            </h1>
            <p className='text-white/50 lg:text-3xl sm:text-2xl text-xl'>
              {description}
            </p>
          </div>
          <Link className='inline-flex sm:mt-20 mt-5' to={`${pathUrl}`}>
            <div className='inline-flex font-semibold sm:gap-10 gap-2 lg:text-3xl sm:text-2xl text-xl text-primary hover:text-primary/90 active:text-primary/40 duration-300 '>
              <span>{linkText}</span>
              <IoIosArrowForward className='sm:mt-0 mt-1 lg:text-4xl sm:text-3xl text-2xl' />
            </div>
          </Link>
          <img
            className='w-full mt-10 sm:hidden rounded-3xl'
            src={imgMobile}
            alt='Kaiber Feature'
          />
        </div>
      </div>
    </div>
  )
}

export default CarouselFeature
