import {
  useCallback,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'

import { useMixpanel } from 'react-mixpanel-browser'
import { useUserAccountContext } from './userContext'

export const VideoEventsContext = createContext({
  eventStartTime: null,
  eventEndTime: null,
  eventDuration: null,
  setStartTime: () => {},
  setEndTime: () => {},
  setDuration: () => {},
})

export const VideoEventsProvider = ({ children }) => {
  const [eventStartTime, setEventStartTime] = useState(null)
  const [eventEndTime, setEventEndTime] = useState(null)
  const [eventDuration, setEventDuration] = useState(null)
  const [eventMethod, setEventMethod] = useState(null)
  const [eventMemoryId, setEventMemoryId] = useState(null)
  const [isPlaying, setIsPlaying] = useState(null)
  const mixpanel = useMixpanel()
  const { setMixpanelMemoryIds } = useUserAccountContext()

  const sendStopEvent = useCallback(() => {
    let duration = parseFloat((eventEndTime - eventStartTime) * 1000).toFixed(2)
    if (duration > 0) {
      mixpanel.track('video_stopped', {
        duration,
        method: eventMethod,
        memoryId: eventMemoryId,
      })
    }
    setEventMethod(null)
    setIsPlaying(null)
  }, [eventEndTime, eventMethod, eventMemoryId, eventStartTime, mixpanel])

  const trackVideos = async (videos) => {
    const memoryIds = await videos.map((video) => video.memory_id)

    setMixpanelMemoryIds(memoryIds)

    mixpanel.track('video_presented', {
      number_of_videos: videos.length,
      memoryIds,
    })
  }

  const onUnloadEvent = (videoRef, memory_id) => {
    if (videoRef != null && memory_id != null) {
      setEventEndTime(videoRef?.current?.currentTime)
      setEventMemoryId(memory_id)
      setEventMethod('unload')
    } else if (eventStartTime != null && eventEndTime != null && isPlaying) {
      setEventMethod('unload_navigation')
    }
  }

  useEffect(() => {
    if (eventMethod != null) {
      sendStopEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMethod])

  return (
    <VideoEventsContext.Provider
      value={{
        eventStartTime,
        eventEndTime,
        eventDuration,
        eventMethod,
        eventMemoryId,
        sendStopEvent,
        setEventStartTime,
        setEventEndTime,
        setEventDuration,
        setEventMethod,
        setEventMemoryId,
        trackVideos,
        onUnloadEvent,
        isPlaying,
        setIsPlaying,
      }}
    >
      {children}
    </VideoEventsContext.Provider>
  )
}

export const useVideoEventsContext = () => useContext(VideoEventsContext)
