const image1 = '/website/welcome/videoImg1.png'
const image2 = '/website/welcome/videoImg2.png'
const image3 = '/website/welcome/videoImg3.png'

function OdkComponent({
  text1A,
  text1B,
  text2A,
  text2B,
  text3A,
  text3B,
  videoUrl1,
  videoUrl2,
  videoUrl3,
}) {
  return (
    <div className='flex flex-1 w-full sm:gap-10 gap-4 my-20 justify-center lg:text-3xl sm:text-2xl text-base'>
      <div className='flex flex-col h-fit  items-center justify-center gap-4'>
        <a href={videoUrl1} target='_blank' rel='noreferrer'>
          <div className='max-h-[435px] aspect-square rounded-full overflow-hidden'>
            <img
              className='max-h-[435px] aspect-square object-cover hover:scale-105 duration-300'
              src={image1}
              alt='Kaiber Video'
            />
          </div>
        </a>
        <div className='flex flex-col'>
          <span
            className={`text-white font-semibold text-center h-10 ${
              text1A ? 'block' : 'hidden'
            }`}
          >
            {text1A}
          </span>
          <span
            className={`text-white text-center ${text1B ? 'block' : 'hidden'}`}
          >
            {text1B}
          </span>
        </div>
      </div>
      <div className='flex flex-col h-fit aspect-square items-center justify-center gap-4'>
        <a href={videoUrl2} target='_blank' rel='noreferrer'>
          <div className='max-h-[435px] aspect-square rounded-full overflow-hidden'>
            <img
              className='max-h-[435px] aspect-square object-cover hover:scale-105 duration-300'
              src={image2}
              alt='Kaiber Video'
            />
          </div>
        </a>
        <div className='flex flex-col'>
          <span
            className={`text-white font-semibold text-center h-10 ${
              text1A ? 'block' : 'hidden'
            }`}
          >
            {text2A}
          </span>
          <span
            className={`text-white text-center ${text1B ? 'block' : 'hidden'}`}
          >
            {text2B}
          </span>
        </div>
      </div>
      <div className='flex flex-col h-fit aspect-square items-center justify-center gap-4'>
        <a href={videoUrl3} target='_blank' rel='noreferrer'>
          <div className='max-h-[435px] aspect-square rounded-full overflow-hidden'>
            <img
              className='max-h-[435px] aspect-square object-cover hover:scale-105 duration-300'
              src={image3}
              alt='Kaiber Video'
            />
          </div>
        </a>
        <div className='flex flex-col'>
          <span
            className={`text-white font-semibold text-center h-10 ${
              text3A ? 'block' : 'hidden'
            }`}
          >
            {text3A}
          </span>
          <span
            className={`text-white text-center ${text3B ? 'block' : 'hidden'}`}
          >
            {text3B}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OdkComponent
