import { useState, useEffect } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import filetype from 'magic-bytes.js'
import { toast } from 'react-toastify'

import { cn, isMobile } from '../utils'
import { IMAGE_MIMETYPES } from '../utils/constants'

const ImageUpload = ({
  onChange,
  image,
  disabled,
  disabledFade,
  title,
  description,
  color,
  imageMaskOverlay,
}) => {
  const [previewImage, setPreviewImage] = useState(null)

  const handleDrop = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]

    if (disabled) {
      return
    }

    if (!(await validateImageFileType(file))) {
      // toast.error('Only *.jpg, *.jpeg and *.png are allowed.')
    } else if (!validateImageSize(file.size)) {
      toast.error('The image may not be greater than 20 MB.')
    } else {
      onChange(file)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.jpg,.jpeg,.png,.webp'
    fileInput.style.display = 'none'
    fileInput.onchange = async (e) => {
      const file = e.target.files[0]

      if (disabled) {
        return
      }

      if (!(await validateImageFileType(file))) {
        toast.error('Only *.jpg, *.jpeg and *.png are allowed.')
      } else if (!validateImageSize(file.size)) {
        toast.error('The image may not be greater than 20 MB.')
      } else {
        onChange(file)
      }
    }

    document.body.appendChild(fileInput)

    fileInput.click()

    // iOS fix
    setTimeout(() => {
      document.body.removeChild(fileInput)
    }, 100)
  }

  const validateImageFileType = async (file) => {
    const buffer = await file.arrayBuffer()
    const bytes = new Uint8Array(buffer)
    const fileTypes = filetype(bytes)

    const validImageMimetypes = [
      IMAGE_MIMETYPES.JPEG,
      IMAGE_MIMETYPES.PNG,
      IMAGE_MIMETYPES.WEBP,
    ]

    if (
      fileTypes.length > 0 &&
      validImageMimetypes.includes(fileTypes[0].mime)
    ) {
      return true
    }

    return false
  }

  const validateImageSize = (fileSize) => {
    const maxFileSize = 1024 * 1024 * 20 // 20 MB

    return fileSize <= maxFileSize
  }

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setPreviewImage(e.target.result)
      }
      reader.readAsDataURL(image)
    }
  }, [image])

  return (
    <div
      className={cn(
        `text-${color} text-center relative bg-darkGray rounded-2xl flex flex-col`,
        image && 'border-primary border-4',
        !isMobile() && 'w-full p-5 max-w-md',
        disabledFade || disabled ? 'opacity-50' : 'hover:bg-[#30303a]',
      )}
    >
      <div className='flex w-full gap-3'>
        <button
          className={cn('w-full', isMobile() && 'p-4')}
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          disabled={disabled}
        >
          <div className='flex justify-center items-center gap-3 mb-2'>
            <p className='text-xl leading-7 sm:text-[21px]'>{title}</p>
          </div>

          {image ? (
            <div className='self-center mt-3 relative'>
              <img
                src={previewImage}
                alt=''
                className='w-full m-auto max-w-xs uploaded-image'
              />
              {imageMaskOverlay && (
                <img
                  src={imageMaskOverlay}
                  alt=''
                  className='absolute top-0 left-0 w-full h-full object-contain'
                />
              )}
            </div>
          ) : (
            <div className='flex items-center'>
              <div>
                <PhotoIcon className='w-12 h-12' />
              </div>
              <div className='ml-4 leading-4 text-left'>
                <small className={`${isMobile() ? 'hidden' : ''}`}>
                  <span>Drag image here or</span>{' '}
                  <span className='underline underline-offset-2'>
                    upload file
                  </span>
                  .
                </small>
                <small
                  className={`${
                    isMobile() ? 'text-[16px] font-normal' : 'block'
                  }`}
                >
                  {description}
                </small>
              </div>
            </div>
          )}
        </button>
      </div>
      {/* REMOVE BUTTON */}
      {/* {image &&
        <div className='flex flex-1 mt-[10%]'>
          <BackButton
            onClick={() => onChange(null)}
            colorClassName='kaiberGreen'
          />
        </div>
      } */}
      {/* CLOSE BUTTON */}
      {image && (
        <div className='top-8 right-8'>
          <button
            type='button'
            className='underline underline-offset-2 p-2 text-kaiberGreen'
            onClick={() => onChange(null)}
          >
            <small>Remove</small>
          </button>
        </div>
      )}
    </div>
  )
}

export default ImageUpload
