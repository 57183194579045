import { useEffect, useRef, useState } from 'react'
import {
  IoMdArrowRoundBack,
  IoIosArrowDown,
  IoIosArrowUp,
} from 'react-icons/io'
import classNames from 'classnames'

import InitialFilesUploadComponent from './InitialFilesUploadComponent'
import PromptSelection from './PromptSelection'
import { Alert } from './Alert'
import { isUserAgent } from '../utils'
import { MagicWandButton } from './MagicWandButton'
import {
  PLACEHOLDERS,
  STYLE_PRESETS,
  USER_AGENTS,
  FLIPBOOK,
  ANIMATION,
  TRANSFORM,
  VIDEO_TYPES_AND_VERSIONS,
} from '../utils/constants'
import { useUserAccountContext } from '../context/userContext'

const MAX_TEXT_AREA_WIDTH = 620
const MIN_TEXT_AREA_WIDTH = 410
const CHAR_WIDTH = 20

export const PromptInput = ({
  subject,
  artStyle,
  artColor,
  negativePrompt,
  artEmotion,
  activeScene,
  setShowAlert,
  setSubject,
  setOriginalSubject,
  setNegativePrompt,
  setPromptSelectedValue,
  setArtStyle,
  setCuratedStyle,
  setArtColor,
  setArtEmotion,
  handleAudioFileChange,
  handleFileInputChange,
  handleVideoFileChange,
  setAlertMessage,
  audioFileName,
  setAudioFileName,
  userProvidedAudioFile,
  userProvidedAudioAudioUrl,
  reuseHasAudio,
  userProvidedVideoFile,
  isAudioProcessing,
  setIsAudioProcessing,
  userProvidedStartingFrame,
  longVideoMaintenanceMode,
  maxLength,
  didReuseSettings,
  selectedCategory,
  handleNextStep,
  handleNextCategory,
  getPreviewFrames,
  isLoadingMemoryId,
  totalCreditsCost,
  promptSelectedValue,
  showAlert,
  alertMessage,
  showSuccess,
  setShowSuccess,
  successMessage,
  interrogate,
  isInterrogating,
  maxVideoUploadLength,
  isImage,
  isVideo,
  videoType,
  typeAndVersion,
  maxAudioUploadLength,
  skipPrompt,
  imageMaskOverlay,
}) => {
  const inputRefSubject = useRef(null)
  const inputRefStyle = useRef(null)
  const inputRefNegative = useRef(null)
  const [showNegativePrompt, setShowNegativePrompt] = useState(false)
  const { featureToggles } = useUserAccountContext()
  const enableNegativePrompt = featureToggles['negative-prompt']

  const isMobile = isUserAgent(USER_AGENTS.mobile)

  const allowImageUpload = videoType !== TRANSFORM

  const allowVideoUpload = videoType === TRANSFORM

  const allowAudioUpload =
    videoType === FLIPBOOK ||
    videoType === ANIMATION ||
    typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V3

  const allowMagicWand =
    !!userProvidedStartingFrame ||
    isVideo ||
    typeAndVersion === VIDEO_TYPES_AND_VERSIONS.MOTION_V3

  const toggleNegativePrompt = () => {
    setShowNegativePrompt(!showNegativePrompt)
  }

  const adjustTextAreaDimensions = (textArea) => {
    if (textArea) {
      const currentWidth = textArea.value.length * CHAR_WIDTH
      if (currentWidth < MIN_TEXT_AREA_WIDTH) {
        textArea.style.width = `${MIN_TEXT_AREA_WIDTH}px`
      } else {
        textArea.style.width = `${Math.min(
          MAX_TEXT_AREA_WIDTH,
          currentWidth,
        )}px`
      }

      textArea.style.height = 'auto'
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      checkCategoryHasValue('artStyle') &&
      checkCategoryHasValue('subject')
    ) {
      event.preventDefault()

      setSubject(inputRefSubject.current.value.trim())
      setArtStyle([inputRefStyle.current.value.trim()])
      setNegativePrompt(inputRefNegative.current.value.trim())
      handleNextStep()
    }
  }

  const handleFocus = (e) => {
    if (e.target === inputRefSubject.current && !subject) {
      inputRefSubject.current.select()
    } else if (e.target === inputRefStyle.current && !artStyle?.length) {
      inputRefStyle.current.select()
    } else if (e.target === inputRefNegative.current && !negativePrompt) {
      inputRefNegative.current.select()
    }
  }

  const selectSubjectText = () => {
    inputRefSubject?.current.focus()
    inputRefSubject?.current.select()
  }

  const selectStyleText = () => {
    inputRefStyle?.current.focus()
    inputRefStyle?.current.select()
  }

  const selectNegativeText = () => {
    inputRefNegative?.current.focus()
    inputRefNegative?.current.select()
  }

  useEffect(() => {
    adjustTextAreaDimensions(inputRefSubject.current)
  }, [subject])

  useEffect(() => {
    adjustTextAreaDimensions(inputRefStyle.current)
  }, [artStyle])

  useEffect(() => {
    adjustTextAreaDimensions(inputRefNegative.current)
  }, [negativePrompt])

  const handleCategoryChange = (
    category,
    value,
    isText = false,
    isCuratedStyle = false,
    isPreSelected = false,
  ) => {
    setShowAlert(false)

    switch (category) {
      case 'subject':
        setSubject(value)
        if (isPreSelected) {
          setOriginalSubject(value)
        }
        setPromptSelectedValue(value)
        break
      case 'artStyle':
        // Allow multiple selections for art style - toggle options on and off, unless they type in something custom
        if (isText || isCuratedStyle) {
          setPromptSelectedValue(value)
          if (!value) {
            setArtStyle([])
          } else {
            setArtStyle([value])
          }
        } else {
          let updatedArtStyle = [...artStyle]

          if (artStyle.includes(value)) {
            updatedArtStyle = artStyle.filter((style) => style !== value)
          } else {
            updatedArtStyle = [value]
          }

          setArtStyle(updatedArtStyle)
        }

        if (isCuratedStyle) {
          setCuratedStyle(value)
        } else {
          setCuratedStyle(null)
        }

        break
      case 'artColor':
        setArtColor(value)
        break
      case 'artEmotion':
        setArtEmotion(value)
        break
      case 'negative':
        setNegativePrompt(value)
        break
      default:
        break
    }
  }

  const checkCategoryValueSelected = (category, value) => {
    switch (category) {
      case 'subject':
        return subject === value
      case 'artStyle':
        return (
          artStyle.includes(value) ||
          (value in STYLE_PRESETS[videoType] &&
            artStyle.includes(STYLE_PRESETS[videoType][value]))
        )
      case 'artColor':
        return artColor === value
      case 'artEmotion':
        return artEmotion === value
      default:
        return false
    }
  }

  const checkCategoryHasValue = (category) => {
    switch (category) {
      case 'subject':
        return !!subject && subject !== ' '
      case 'artStyle':
        return !!artStyle.length && artStyle[0] !== ' '
      case 'artColor':
        return !!artColor
      case 'artEmotion':
        return !!artEmotion
      default:
        return false
    }
  }

  return (
    <div className={classNames('md:grid grid-cols-7 gap-0 w-full', {})}>
      <div className='col-span-4'>
        <div className='grid'>
          {!isMobile && (
            <div className='flex flex-col sm:px-6 py-10 text-quaternary overflow-hidden'>
              <div className='flex'>
                <h1 className='mb-5 mr-[6.5rem] sm:mr-40 lg:mr-56 xl:mr-72'>
                  PROMPT
                </h1>
              </div>
              <div className='text-2xl leading-normal whitespace-pre-line lg:leading-[2.5rem] xl:leading-[3rem] lg:text-3xl xl:text-4xl'>
                <p className='text-'>
                  I want to create a video of
                  {allowMagicWand ? (
                    <MagicWandButton
                      onClick={interrogate}
                      isLoading={isInterrogating}
                      color='#A59CDA'
                      className='inline text-xl'
                    />
                  ) : null}
                </p>
                <textarea
                  ref={inputRefSubject}
                  type='text'
                  onFocus={handleFocus}
                  onKeyDown={handleKeyDown}
                  onChange={(e) =>
                    handleCategoryChange('subject', e.target.value || ' ', true)
                  }
                  onBlur={(e) => {
                    handleCategoryChange(
                      'subject',
                      e.target.value.trim() !== PLACEHOLDERS.SUBJECT &&
                        e.target.value.trim().replace(/\s+/g, ' '),
                      true,
                    )
                  }}
                  value={subject || PLACEHOLDERS.SUBJECT}
                  className={classNames(
                    'bg-quaternary py-1 px-2 font-bold cursor-text outline-none whitespace-pre-wrap inline-flex h-auto resize-none',
                    !subject ? 'text-black/50' : 'text-black',
                  )}
                  rows={1}
                />
                <IoMdArrowRoundBack
                  className='inline w-10 h-10 cursor-pointer text-quaternary hover:scale-90 align-top mt-[10px]'
                  onClick={selectSubjectText}
                />

                {enableNegativePrompt && videoType !== TRANSFORM && (
                  <div>
                    <p
                      className='text- text-[20px] text-[#B6B6B6] flex items-center cursor-pointer'
                      onClick={toggleNegativePrompt}
                    >
                      I don’t want to see
                      <div className='text-[#B6B6B6] ml-[5px] mt-[3px]'>
                        {showNegativePrompt ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )}
                      </div>
                    </p>

                    <div
                      className={classNames(
                        'overflow-hidden w-full transition-[height]',
                        showNegativePrompt ? 'h-[100px]' : 'h-0',
                      )}
                    >
                      <textarea
                        ref={inputRefNegative}
                        type='text'
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onChange={(e) =>
                          handleCategoryChange(
                            'negative',
                            e.target.value || ' ',
                            true,
                          )
                        }
                        onBlur={(e) => {
                          handleCategoryChange(
                            'negative',
                            e.target.value.trim() !== PLACEHOLDERS.NEGATIVE &&
                              e.target.value.trim().replace(/\s+/g, ' '),
                            true,
                          )
                        }}
                        value={negativePrompt || PLACEHOLDERS.NEGATIVE}
                        className={classNames(
                          'bg-[#B6B6B6] py-1 px-2 font-bold cursor-text outline-none whitespace-pre-wrap inline-flex h-auto resize-none',
                          !negativePrompt ? 'text-black/50' : 'text-black',
                        )}
                        rows={1}
                      />
                      <IoMdArrowRoundBack
                        className='inline w-10 h-10 cursor-pointer text-[#B6B6B6] hover:scale-90 align-top mt-[10px]'
                        onClick={selectNegativeText}
                      />
                    </div>
                  </div>
                )}

                <p className='mt-7 text-primary'>in the style of </p>
                <textarea
                  ref={inputRefStyle}
                  type='text'
                  onFocus={handleFocus}
                  onKeyDown={handleKeyDown}
                  onChange={(e) =>
                    handleCategoryChange(
                      'artStyle',
                      e.target.value || ' ',
                      true,
                    )
                  }
                  onBlur={(e) => {
                    handleCategoryChange(
                      'artStyle',
                      e.target.value.trim() !== PLACEHOLDERS.STYLE &&
                        e.target.value.trim().replace(/\s+/g, ' '),
                      true,
                    )
                  }}
                  value={
                    artStyle?.length > 0
                      ? artStyle.join(', ')
                      : PLACEHOLDERS.STYLE
                  }
                  className={classNames(
                    'bg-primary py-1 px-2 font-bold cursor-text outline-none whitespace-pre-wrap inline-flex resize-none h-auto',
                    artStyle?.length === 0 ? 'text-black/50' : 'text-black',
                  )}
                  rows={1}
                />

                <IoMdArrowRoundBack
                  className='inline w-10 h-10 cursor-pointer text-primary hover:scale-90 align-top mt-[10px]'
                  onClick={selectStyleText}
                />
              </div>
            </div>
          )}

          {/* Image/Song Upload */}
          {activeScene === 1 && !isMobile && (
            <div className='flex flex-1 mx-6'>
              <InitialFilesUploadComponent
                handleAudioFileChange={handleAudioFileChange}
                handleFileInputChange={handleFileInputChange}
                handleVideoFileChange={handleVideoFileChange}
                setAlertMessage={setAlertMessage}
                setShowAlert={setShowAlert}
                audioFileName={audioFileName}
                setAudioFileName={setAudioFileName}
                audio={userProvidedAudioFile}
                audioUrl={userProvidedAudioAudioUrl}
                reuseHasAudio={reuseHasAudio}
                isAudioProcessing={isAudioProcessing}
                setIsAudioProcessing={setIsAudioProcessing}
                image={userProvidedStartingFrame}
                video={userProvidedVideoFile}
                longVideoMaintenanceMode={longVideoMaintenanceMode}
                maxAudioUploadLength={maxAudioUploadLength}
                didReuseSettings={didReuseSettings}
                maxVideoUploadLength={maxVideoUploadLength}
                isImage={isImage}
                isVideo={isVideo}
                allowImageUpload={allowImageUpload}
                allowAudioUpload={allowAudioUpload}
                allowVideoUpload={allowVideoUpload}
                imageMaskOverlay={imageMaskOverlay}
              />
            </div>
          )}
        </div>
      </div>

      <div className='col-span-3 py-2 sm:px-6 sm:py-10 w-full'>
        <PromptSelection
          selectedCategory={selectedCategory}
          handleCategoryChange={handleCategoryChange}
          handleNextCategory={handleNextStep}
          handleNextCategoryMobile={handleNextCategory}
          checkCategoryValueSelected={checkCategoryValueSelected}
          checkCategoryHasValue={checkCategoryHasValue}
          generateVideo={getPreviewFrames}
          useUserProvidedStartingFrame={!!userProvidedStartingFrame}
          isLoading={isLoadingMemoryId}
          creditsCost={totalCreditsCost}
          selectedValue={promptSelectedValue}
          subject={subject}
          artStyle={artStyle}
          negativePrompt={negativePrompt}
          stylePresets={STYLE_PRESETS[videoType]}
          isMobile={isMobile}
          //PROMPT VARIABLES
          inputRefSubject={inputRefSubject}
          inputRefStyle={inputRefStyle}
          inputRefNegative={inputRefNegative}
          handleKeyDown={handleKeyDown}
          selectSubjectText={selectSubjectText}
          selectStyleText={selectStyleText}
          selectNegativeText={selectNegativeText}
          isInterrogating={isInterrogating}
          interrogate={interrogate}
          isVideo={isVideo}
          videoType={videoType}
          skipPrompt={skipPrompt}
          userProvidedStartingFrame={userProvidedStartingFrame}
          userProvidedVideoFile={userProvidedVideoFile}
        />
        <div className={classNames('mt-8 mb-4', { hidden: !showAlert })}>
          <Alert
            open={showAlert}
            setOpen={setShowAlert}
            message={alertMessage}
            type='alert'
          />
          <Alert
            open={showSuccess}
            setOpen={setShowSuccess}
            message={successMessage}
            type='success'
          />
        </div>
      </div>
    </div>
  )
}
