import { create } from 'zustand'
import { subscribeWithSelector, devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { ICanvasStore } from './canvasStore'
import { MediaStoreState } from './mediaStore'
import { MyLibraryStoreState } from './myLibraryStore'

// Not the best way. Ideally we can turn on debug mode using a URL parameter in production.
const debugModeEnabled = process.env.NODE_ENV === 'development'

type GlobalStoreState = MediaStoreState & MyLibraryStoreState & ICanvasStore

/**
 * A Zustand store to facilitate data sharing and management among UI components.
 * Its properties are described in `GlobalStoreState`.
 * To learn more about Zustand, visit https://github.com/pmndrs/zustand.
 */
export const useGlobalStore = create<GlobalStoreState>()(
  // https://github.com/pmndrs/zustand/blob/main/docs/integrations/immer-middleware.md
  immer(
    devtools(
      // Use Redux DevTools to inspect the state of the store.
      subscribeWithSelector(() => ({
        canvasDebouncedZoom: 1,
        canvasScale: 1,
        medias: {},
        myLibraryMediaIds: [],
        myLibraryTagFilters: [],
      })),
      // Enables a powerful debugger. See https://www.youtube.com/watch?v=QVRIJpXNXR8
      { enabled: debugModeEnabled, name: 'Global Store' },
    ),
  ),
)

// Exposes the stores to the `window` object for debugging purposes.
if (debugModeEnabled) {
  ;(window as any).useGlobalStore = useGlobalStore
}
