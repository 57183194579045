import React, { useState, useRef, useEffect } from 'react'
import { FieldOption } from '../../../types'
import { cn } from '../../../utils'

interface DropdownProps {
  options: FieldOption[]
  value: string
  label?: string
  onChange: (v: string) => void
}

export const Dropdown = ({
  options,
  value,
  label,
  onChange,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const handleSelectClick = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (v: string) => {
    onChange(v)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={selectRef} className='relative inline-block w-full'>
      <div
        className='w-full h-6 pl-3 pr-3 text-white text-[10px] text-center bg-gray-500 border-none rounded-xl cursor-pointer focus:outline-none focus:shadow-outline flex items-center justify-center'
        onClick={handleSelectClick}
      >
        <div className='text-center'>
          {value ?? label ?? 'Select an option'}
        </div>
        <svg
          className={`absolute w-4 h-4 fill-current transform transition-transform right-2 ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
          viewBox='0 0 20 20'
        >
          <path d='M5.25 7.75l4.25 4.25 4.25-4.25'></path>
        </svg>
      </div>
      {isOpen && (
        <ul className='absolute z-10 w-full mt-1 bg-gray-500 rounded-xl overflow-hidden text-white'>
          {options.map((option, index) => (
            <li
              key={index}
              className={cn(
                'px-4 py-1 text-[10px] text-center cursor-pointer hover:bg-gray-700',
                { 'bg-gray-700': option.value === value },
              )}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
