import { CheckpointSelector } from '../components/k2/Elements/CheckpointSelector'
import { FillPrompt } from '../components/k2/Elements/Prompt/FillPrompt'
import { PromptV2 } from '../components/k2/Elements/Prompt/PromptV2'
import { FluxImageSettings } from '../components/k2/Elements/Settings/Image/FluxImageSettings'
import { ImageSettingsV2 } from '../components/k2/Elements/Settings/Image/ImageSettingsV2'
import { UpscaleV1Settings } from '../components/k2/Elements/Settings/Image/UpscaleSettingsV1'
import { FlipbookV1Settings } from '../components/k2/Elements/Settings/Video/FlipbookV1Settings'
import { MotionV2Settings } from '../components/k2/Elements/Settings/Video/MotionV2Settings'
import { MotionV3Settings } from '../components/k2/Elements/Settings/Video/MotionV3Settings'
import { TransformV3Settings } from '../components/k2/Elements/Settings/Video/TransformV3Settings'
import { AudioUpload } from '../components/k2/Elements/Upload/AudioUpload'
import { ImageUpload } from '../components/k2/Elements/Upload/ImageUpload'
import { VideoUpload } from '../components/k2/Elements/Upload/VideoUpload'
import { LABELS } from '../constants'
import { ImageElementIcon } from '../images/element/ImageElementIcon'
import { PromptElementIcon } from '../images/element/PromptElementIcon'
import { ElementType, FieldType } from '@/types'

export interface ElementMetadata {
  component: React.ReactElement
  elementIcon: React.ReactElement
  fields: string[]
  imageSource?: string
  label?: string
}

type ElementsConfig = {
  [key in ElementType]: ElementMetadata
}

export const ELEMENTS_CONFIG: ElementsConfig = {
  [ElementType.CheckpointSelector]: {
    component: <CheckpointSelector />,
    elementIcon: <PromptElementIcon />,
    fields: [FieldType.CheckpointSelector],
    imageSource: '/elements/3d.jpg',
    label: LABELS.CHECKPOINT_SELECTOR,
  },
  [ElementType.Prompt]: {
    component: <PromptV2 />,
    elementIcon: <PromptElementIcon />,
    fields: [FieldType.Subject, FieldType.Style],
    label: LABELS.SUBJECT_PROMPT,
  },
  [ElementType.InpaintPrompt]: {
    component: <FillPrompt />,
    elementIcon: <PromptElementIcon />,
    fields: [FieldType.Subject, FieldType.Style, FieldType.MaskPrompt],
  },
  [ElementType.ImageSettings]: {
    component: <ImageSettingsV2 />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Evolve, FieldType.AspectRatio, FieldType.Version],
    label: LABELS.IMAGE_SETTINGS,
  },
  [ElementType.MotionV3Settings]: {
    component: <MotionV3Settings />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Length, FieldType.Motion],
    label: LABELS.VIDEO_DETAILS,
  },
  [ElementType.MotionV2Settings]: {
    component: <MotionV2Settings />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Length, FieldType.Motion],
    label: LABELS.MOTION_V2_SETTINGS,
  },
  [ElementType.FlipbookV1Settings]: {
    component: <FlipbookV1Settings />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Evolve, FieldType.Length, FieldType.AspectRatio],
    label: LABELS.FLIPBOOK_V1_SETTINGS,
  },
  [ElementType.TransformV3Settings]: {
    component: <TransformV3Settings />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Evolve],
    label: LABELS.TRANSFORM_V3_SETTINGS,
  },
  [ElementType.AudioUpload]: {
    component: <AudioUpload />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.AudioUpload],
    imageSource: '/elements/audio-reactivity.jpg',
    label: LABELS.AUDIO_REACTIVITY,
  },
  [ElementType.ImageUpload]: {
    component: <ImageUpload />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.ImageUpload],
    imageSource: '/elements/moodboard.jpg',
    label: LABELS.REFERENCE_IMAGE,
  },
  [ElementType.VideoUpload]: {
    component: <VideoUpload />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.VideoUpload],
    imageSource: '/elements/video-upload.jpg',
    label: LABELS.VIDEO_UPLOAD,
  },
  [ElementType.StyleReferenceUpload]: {
    component: (
      <ImageUpload
        formField={FieldType.StyleReferenceUpload}
        label={LABELS.STYLE_REFERENCE}
        maxFiles={3}
        allowWeights
      />
    ),
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.StyleReferenceUpload],
    imageSource: '/elements/draw-to-prompt.jpg',
    label: LABELS.STYLE_REFERENCE,
  },
  [ElementType.CompositionReferenceUpload]: {
    component: (
      <ImageUpload
        formField={FieldType.CompositionReferenceUpload}
        label={LABELS.COMPOSITION_REFERENCE}
        maxFiles={3}
        allowWeights
      />
    ),
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.CompositionReferenceUpload],
    imageSource: '/elements/midjourney.jpg',
    label: LABELS.COMPOSITION_REFERENCE,
  },
  [ElementType.ImageReferenceUpload]: {
    component: (
      <ImageUpload
        formField={FieldType.ImageReferenceUpload}
        label={LABELS.IMAGE_REFERENCE}
        maxFiles={3}
        allowWeights
      />
    ),
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.ImageReferenceUpload],
    imageSource: '/elements/audio-reactivity.jpg',
    label: LABELS.IMAGE_REFERENCE,
  },
  [ElementType.FaceReferenceUpload]: {
    component: (
      <ImageUpload
        formField={FieldType.FaceReferenceUpload}
        label={LABELS.FACE_REFERENCE}
        maxFiles={1}
        allowWeights
      />
    ),
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.FaceReferenceUpload],
    imageSource: '/elements/enhance.jpg',
    label: LABELS.FACE_REFERENCE,
  },
  [ElementType.PulidFaceReferenceUpload]: {
    component: (
      <ImageUpload
        formField={FieldType.PulidFaceReferenceUpload}
        label={LABELS.PULID_FACE_REFERENCE}
        maxFiles={1}
        allowWeights
      />
    ),
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.PulidFaceReferenceUpload],
    imageSource: '/elements/camera-movements.jpg',
    label: LABELS.PULID_FACE_REFERENCE,
  },
  [ElementType.ControlnetReferenceUpload]: {
    component: (
      <ImageUpload
        formField={FieldType.ControlnetReferenceUpload}
        label={LABELS.CONTROLNET_REFERENCE}
        maxFiles={1}
        allowWeights
      />
    ),
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.ControlnetReferenceUpload],
    imageSource: '/elements/upscale.jpg',
    label: LABELS.CONTROLNET_REFERENCE,
  },

  [ElementType.FluxImageSettings]: {
    component: <FluxImageSettings />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Subject],
    label: LABELS.FLUX_IMAGE_SETTINGS,
  },
  [ElementType.UpscaleV1Settings]: {
    component: <UpscaleV1Settings />,
    elementIcon: <ImageElementIcon />,
    fields: [FieldType.Evolve, FieldType.AspectRatio, FieldType.Version],
    label: LABELS.UPSCALE_V1_SETTINGS,
  },
}
