import { ImageSparkleIcon } from '../../../images/icons/ImageSparkleIcon'
import { UploadIcon } from '../../../images/icons/UploadIcon'
import { VideoIcon } from '../../../images/icons/VideoIcon'
import { MediaType, TagNamespace } from '../../../types'

interface TagIconProps {
  ns: TagNamespace
  name: string
}

export const TagIcon = ({ ns, name }: TagIconProps) => {
  switch (ns) {
    case TagNamespace.MediaType:
      if (name === MediaType.Image) return <ImageSparkleIcon size={20} />
      else if (name === MediaType.Video) return <VideoIcon size={18} />
      break
    case TagNamespace.Uploaded:
      return <UploadIcon size={20} />
    default:
      return <>{name}</>
  }
}
