function QuoteSection() {
  return (
    <div className='flex md:h-[90vh] h-[600px] w-full self-center justify-center items-center bg-[url(https://kaiber.ai/website/studio/bg_quote.png)] bg-cover bg-right'>
      <div className='flex flex-col text-center max-w-5xl md:px-[3.5%] px-4 gap-14'>
        <p className='lg:text-5xl sm:text-4xl text-3xl font-semibold text-white'>
          “Kaiber opened a new realm of creative possibilities and allowed us to
          produce quality music visualizers with a fraction of the time and
          resources that were previously required.”
        </p>
        <span className='lg:text-4xl sm:text-3xl text-2xl text-primary font-bold'>
          Mike Shinoda (Linkin Park)
        </span>
      </div>
    </div>
  )
}

export default QuoteSection
