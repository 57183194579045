import { useMemo } from 'react'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'
import { cn } from '../../utils'

export default function Pagination({
  currentPage = 1,
  totalPages,
  disabled = false,
  onPageChange,
}) {
  const safeTotalPages = useMemo(() => Math.min(totalPages, 100), [totalPages])

  const handlePageChange = (e) => {
    onPageChange(+e.target.value)
  }

  const handlePrevPage = () => {
    onPageChange(currentPage - 1)
  }

  const handleNextPage = () => {
    onPageChange(currentPage + 1)
  }

  return (
    <div className={`flex justify-between${disabled ? ' opacity-60' : ''}`}>
      {totalPages > 0 ? (
        <div className='text-white flex gap-2 items-center'>
          Page
          <select
            value={currentPage}
            onChange={handlePageChange}
            className={cn(
              'border border-primary/25 bg-transparent p-2 rounded-lg text-white text-center block h-10',
              !disabled && 'cursor-pointer',
            )}
            disabled={disabled}
          >
            {Array(safeTotalPages)
              .fill(0)
              .map((_, index) => (
                <option
                  key={`page-${index + 1}`}
                  value={index + 1}
                  className='bg-darkGray'
                >
                  {index + 1}
                </option>
              ))}
          </select>
          of {safeTotalPages}
        </div>
      ) : null}
      <div className='flex gap-2'>
        <button
          type='button'
          onClick={handlePrevPage}
          className={cn(
            'border p-2 rounded-lg text-center block h-10 border-primary/25  text-white',
            currentPage === 1 && 'border-gray-900 text-gray-700',
          )}
          disabled={currentPage === 1 || disabled}
        >
          <ChevronDoubleLeftIcon className='w-6 h-6' />
        </button>
        <button
          type='button'
          onClick={handleNextPage}
          className={`${
            // eslint-disable-next-line eqeqeq
            currentPage == safeTotalPages
              ? 'border border-gray-900 p-2 rounded-lg text-gray-700 text-center block h-10'
              : 'border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
          }`}
          disabled={
            // eslint-disable-next-line eqeqeq
            currentPage == safeTotalPages || disabled
          }
        >
          <ChevronDoubleRightIcon className='w-6 h-6' />
        </button>
      </div>
    </div>
  )
}
