import { useState, useEffect, useRef } from 'react'
import { VideoCameraIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-toastify'

import { isMobile } from '../utils'
import { getFirstFrame } from '../utils/video_util'

const VideoUpload = ({
  onChange,
  video,
  disabled,
  videoS3Key,
  description,
  title,
}) => {
  const [previewVideo, setPreviewVideo] = useState(null)
  const [, setHasVideo] = useState(!!video)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)

  const handleDrop = (e) => {
    e.preventDefault()

    if (process.env.DISABLE_TRANSFORM) {
      toast.error('Transform is temporarily disabled.')
      return
    }

    const file = e.dataTransfer.files[0]

    if (disabled) {
      return
    }

    if (!validateVideoMimetype(file.type)) {
      // toast.error('Only *.mp4 and *.mov are accepted.')
    } else if (!validateVideoSize(file.size)) {
      toast.error('The video may not be greater than 200 MB.')
    } else {
      onChange(file)
      getFirstFrame(file, videoRef, canvasRef)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()

    if (process.env.DISABLE_TRANSFORM) {
      toast.error('Transform is temporarily disabled.')
      return
    }

    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.mp4,.mov'
    fileInput.style.display = 'none'
    fileInput.onchange = (e) => {
      const file = e.target.files[0]

      if (disabled) {
        return
      }

      if (!validateVideoMimetype(file.type)) {
        toast.error('Only *.mp4 and *.mov are accepted.')
      } else if (!validateVideoSize(file.size)) {
        toast.error('The video may not be greater than 200 MB.')
      } else {
        onChange(file)
      }
    }

    document.body.appendChild(fileInput)

    fileInput.click()

    // iOS fix
    setTimeout(() => {
      document.body.removeChild(fileInput)
    }, 100)
  }

  const validateVideoMimetype = (mimetype) => {
    return ['video/mp4', 'video/quicktime', 'video/mov'].includes(mimetype)
  }

  const validateVideoSize = (fileSize) => {
    const maxFileSize = 1024 * 1024 * 200 // 200 MB

    return fileSize <= maxFileSize
  }

  useEffect(() => {
    if (video) {
      // video can be the user selected video from their computer or, if we're using saved prompt, a video URL
      console.log(video)
      try {
        // check if video is a string (URL) or a file
        if (typeof video === 'string') {
          setPreviewVideo(video)
        } else {
          setPreviewVideo(URL.createObjectURL(video))
          setHasVideo(true)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [video])

  useEffect(() => {
    return () => {
      if (previewVideo) {
        URL.revokeObjectURL(previewVideo)
      }
    }
  }, [previewVideo])

  return (
    <div
      className={`relative bg-darkGray rounded-2xl text-left flex text-[#a59cda] ${
        video ? 'border-primary border-4' : ''
      } ${isMobile() ? '' : 'w-full p-5 max-w-md'} ${
        disabled ? 'opacity-50' : 'hover:bg-[#30303a]'
      } `}
    >
      <button
        className={`w-full ${isMobile() ? 'p-4' : ''}`}
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        disabled={disabled}
      >
        <p className='mb-2 text-xl leading-7 text-center'>{title}</p>

        <div className='flex flex-1 w-full justify-center items-center'>
          {video ? (
            <div>
              <video
                ref={videoRef}
                src={previewVideo}
                alt=''
                className='uploaded-video'
                controls
              />
            </div>
          ) : null}
        </div>
        <div className='w-full'>
          {video || videoS3Key ? (
            <small
              onClick={(e) => {
                e.stopPropagation()
                onChange(null)
                setPreviewVideo(null)
              }}
              className='flex underline underline-offset-2 p-2'
            >
              Remove
            </small>
          ) : (
            /* <>
               <small>Drag video here or</small>{' '}
               <small className='underline underline-offset-2'>
                 upload file
               </small>
             </>*/
            <div className='flex items-center'>
              <div className={`${isMobile() ? 'flex justify-center' : ''}`}>
                <VideoCameraIcon className='w-12 h-12' />
              </div>
              <div
                className={`${
                  isMobile() ? 'ml-4' : 'ml-4 leading-4 text-left'
                }`}
              >
                <small className={`${isMobile() ? 'hidden' : ''}`}>
                  <span>Drag video here or</span>{' '}
                  <span className='underline underline-offset-2'>
                    upload file
                  </span>
                  .
                </small>
                <small
                  className={`${
                    isMobile() ? 'text-[16px] font-normal' : 'block'
                  }`}
                >
                  {description}
                </small>
              </div>
            </div>
          )}
        </div>
      </button>
      {/* CLOSE BUTTON */}
      {(video || videoS3Key) && (
        <div className='top-8 right-8'>
          <button
            type='button'
            className='rounded-full outline-none hover:text-gray-500 text-kaiberGreen'
            onClick={() => onChange(null)}
          ></button>
        </div>
      )}
    </div>
  )
}

export default VideoUpload
