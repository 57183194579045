import { useRef, useState } from 'react'
import { updateCurrentPassword } from '../services/AuthService'
import { Alert } from '../components/Alert'
import { useUserAccountContext } from '../context/userContext'
import { PasswordInput } from '../components/PasswordInput'
import Lottie from 'lottie-react'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'

export function ChangePasswordForm() {
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const [errors, setErrors] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const formRef = useRef()
  const mixpanel = useMixpanel()

  const clearError = (field) => {
    setErrors((errors) => ({
      ...errors,
      [field]: null,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    setShowAlert(false)
    setErrors({})

    const currentPassword = currentUser.hasPassword
      ? e.target[0].value
      : 'nopassword'

    const newPassword = e.target[currentUser.hasPassword ? 1 : 0].value
    const newPasswordConfirmation =
      e.target[currentUser.hasPassword ? 2 : 1].value
    const disconnectFromOtherDevices = currentUser.hasPassword
      ? e.target[3].checked
      : false

    try {
      const response = await updateCurrentPassword(
        currentPassword,
        newPassword,
        newPasswordConfirmation,
        disconnectFromOtherDevices,
      )

      setCurrentUser((currentUser) => ({
        ...currentUser,
        hasPassword: true,
      }))

      trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.CLICK_CHANGE_PASSWORD, {
        disconnect_devices: disconnectFromOtherDevices,
      })

      setSuccessMessage(response.data.message)
      setShowSuccess(true)

      formRef.current.reset()

      setTimeout(() => {
        setShowSuccess(false)
      }, 3000)
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            setErrors({
              email: err.response.data.message,
            })
            break
          case 400:
            setErrors(err.response.data.errors)
            break
          default:
            setAlertMessage('Oops! Something went wrong.')
            setShowAlert(true)
        }
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
        setShowAlert(true)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <div className='md:grid md:grid-cols-3 md:gap-6 border-b border-lightGray pb-10 mb-1 pt-10'>
        <div className='md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-2xl font-medium leading-6 text-kaiberGreen'>
              {currentUser.hasPassword ? 'Change' : 'Create'} Password
            </h3>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='shadow rounded-2xl'>
            <div className='rounded-2xl px-4 py-5 sm:p-6 relative sm:pt-0'>
              {showAlert && (
                <div className='mb-6'>
                  <Alert
                    open={showAlert}
                    setOpen={setShowAlert}
                    message={alertMessage}
                    type='alert'
                  />
                </div>
              )}

              {showSuccess && (
                <div className='mb-6'>
                  <Alert
                    open={showSuccess}
                    message={successMessage}
                    type='success'
                  />
                </div>
              )}

              <form onSubmit={handleSubmit} ref={formRef}>
                {currentUser.hasPassword && (
                  <div className='mb-6'>
                    <label
                      htmlFor='current-password'
                      className='block text-tertiary font-medium'
                    >
                      Current Password
                    </label>
                    <input
                      type='password'
                      id='current-password'
                      onInput={() => clearError('currentPassword')}
                      placeholder='Enter Password'
                      required
                      className={`w-full mt-3 block ${
                        errors.currentPassword
                          ? 'border-red-700'
                          : 'border-transparent'
                      } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                      disabled={isSubmitting}
                    />
                    {errors.currentPassword && (
                      <span className='text-red-500'>
                        {errors.currentPassword}
                      </span>
                    )}
                  </div>
                )}
                <div className='mb-6'>
                  <label
                    htmlFor='new-password'
                    className='block text-tertiary font-medium'
                  >
                    {currentUser.hasPassword ? 'New ' : ''}Password
                  </label>
                  <PasswordInput
                    id='new-password'
                    onInput={() => clearError('newPassword')}
                    placeholder='Enter New Password'
                    required
                    className={`w-full mt-3 block ${
                      errors.newPassword
                        ? 'border-red-700'
                        : 'border-transparent'
                    } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                    disabled={isSubmitting}
                  />
                  {errors.newPassword && (
                    <span className='text-red-500'>{errors.newPassword}</span>
                  )}
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='new-password-confirmation'
                    className='block text-tertiary font-medium'
                  >
                    Confirm Password
                  </label>
                  <input
                    type='password'
                    id='new-password-confirmation'
                    onInput={() => clearError('newPasswordConfirmation')}
                    required
                    placeholder='Confirm New Password'
                    className={`w-full mt-3 block ${
                      errors.newPasswordConfirmation
                        ? 'border-red-700'
                        : 'border-transparent'
                    } p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white`}
                    disabled={isSubmitting}
                  />
                  {errors.newPasswordConfirmation && (
                    <span className='text-red-500'>
                      {errors.newPasswordConfirmation}
                    </span>
                  )}
                </div>
                {currentUser.hasPassword && (
                  <div className='mb-6'>
                    <label>
                      <input
                        type='checkbox'
                        defaultChecked
                        id='disconnect-from-other-devices'
                        className='float-left mt-[6px]'
                        disabled={isSubmitting}
                      />
                      <span className='text-tertiary/80 block ml-5'>
                        Disconnect from other devices
                      </span>
                    </label>
                  </div>
                )}
                <button
                  type='submit'
                  className='w-full rounded-full border bg-primary border-primary p-4 text-base font-bold text-gray-900 shadow-sm hover:bg-white'
                  disabled={isSubmitting}
                >
                  {currentUser.hasPassword ? 'Change' : 'Create'} Password
                </button>
              </form>

              {isSubmitting && (
                <div className='absolute flex w-full h-full bg-gray-500 bg-opacity-75 inset-0 sm:rounded-3xl'>
                  <div className='flex flex-col items-center space-y-4 m-auto'>
                    <div className='bg-darkGray p-6 rounded-3xl'>
                      <Lottie
                        animationData={LoadingWhite}
                        loop={true}
                        className='w-16 h-16'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
