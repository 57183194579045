import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share'
import { EmailIcon, FacebookIcon, TwitterIcon } from 'react-share'
import { useMixpanel } from 'react-mixpanel-browser'
import { useUserAccountContext } from '../context/userContext'
// import { useUserAccountContext } from '../context/userContext'
import EmailVerificationButton from './EmailVerificationButton'

export default function ReferForm({ shareUrl }) {
  // const { currentUser, rewardfulSignupUrl } = useUserAccountContext()
  const [, setDidCopy] = useState(false)
  const { currentUser } = useUserAccountContext()
  const mixpanel = useMixpanel()

  const handleCopy = () => {
    navigator.clipboard.writeText(shareUrl)
    setDidCopy(true)
    setTimeout(() => {
      setDidCopy(false)
    }, 2000)

    mixpanel.track('Copied Referral Link', {
      source: '/account',
    })
  }

  return (
    <div className='px-4 py-5 sm:p-6 w-full rounded-2xl sm:pt-0'>
      <p className='text-tertiary text-2xl'>Share and Earn Credits</p>
      {!currentUser.emailVerifiedAt &&
        currentUser.subscriptionType !== 'Standard' && (
          <EmailVerificationButton text='Your email must be verified to earn credits.' />
        )}

      <p className='text-tertiary/60 mt-2'>
        If your friend signs up for 1 Pro account, you get 300 credits.
      </p>

      {/* {currentUser?.rewardfulId ? (
        <> */}
      <div className='flex gap-2 mt-4 mb-6'>
        <EmailShareButton
          onClick={() =>
            mixpanel.track('Clicked Share to Email', {
              source: 'Account Refer Form',
            })
          }
          subject={'Check out this video I made at https://kaiber.ai!'}
          body={'Check out this video I made at https://kaiber.ai!'}
        >
          <EmailIcon size={48} round />
        </EmailShareButton>
        <FacebookShareButton
          onClick={() =>
            mixpanel.track('Clicked Share to Facebook', {
              source: 'Account Refer Form',
            })
          }
          url={shareUrl}
          quote={'Check out this video I made at https://kaiber.ai!'}
          hashtag='#kaibervideo'
        >
          <FacebookIcon size={48} round />
        </FacebookShareButton>
        <TwitterShareButton
          onClick={() =>
            mixpanel.track('Clicked Share to Twitter', {
              source: 'Account Refer Form',
            })
          }
          url={shareUrl}
          title={'Check out this video I made at https://kaiber.ai! @KaiberAI'}
        >
          <TwitterIcon size={48} round />
        </TwitterShareButton>
      </div>
      <div className='relative mt-1 flex items-center'>
        <input
          type='text'
          name='search'
          id='search'
          defaultValue={shareUrl}
          className='p-4 pl-6 block w-full rounded-full border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-infoGrey text-white'
        />
        <div className='absolute inset-y-0 right-0 flex py-1.5 pr-1.5'>
          <kbd
            className='justify-center inline-flex items-center rounded-full px-4 py-4 font-sans text-sm font-medium text-black cursor-pointer uppercase bg-kaiberGreen h-10 w-10'
            onClick={handleCopy}
          >
            <DocumentDuplicateIcon className=' mr-[-3px] min-w-[25px]' />
            {/* {didCopy ? 'Copied!' : 'Copy Link'} */}
          </kbd>
        </div>
      </div>
      {/* </>
      ) : (
        <a
          href={rewardfulSignupUrl}
          className='inline-flex items-center rounded-full border radial-gradient px-4 py-4 text-sm font-bold text-gray-900 shadow-sm focus:outline-none focus:ring-0 uppercase gap-2 border-primary'
        >
          GET A REWARDFUL ACCOUNT
        </a>
      )} */}
    </div>
  )
}
