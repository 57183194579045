import { ReactFlowJsonObject } from '@xyflow/react'
import { atomizeChangeset, diff } from 'json-diff-ts'

export const getCanvasChanges = (
  previousObj: ReactFlowJsonObject,
  currentObj: ReactFlowJsonObject,
) => {
  const newNodes = currentObj.nodes.map((node) => {
    const { id, measured, position, type, dragHandle } = node
    const { formValues, overrides, ...others } = node.data

    return {
      id,
      measured,
      position,
      type,
      data: {
        ...others,
        ...(formValues && { formValues }),
        ...(overrides && { overrides }),
      },
      dragHandle,
    }
  })

  const currentFlowObj: ReactFlowJsonObject = {
    nodes: newNodes,
    edges: currentObj.edges,
    viewport: currentObj.viewport,
  }

  const changeSet = diff(previousObj, currentFlowObj, {
    embeddedObjKeys: {
      nodes: (obj, shouldReturnKeyName) =>
        shouldReturnKeyName ? 'id' : obj.id,
    },
  })

  // atomized diffs take less traffic data
  const diffs = atomizeChangeset(changeSet)

  return {
    diffs,
    currentFlowObj,
    hasChanges: diffs.length > 0,
  }
}

export const isCanvasExportable = (canvas: HTMLCanvasElement) => {
  try {
    const context = canvas.getContext('2d')
    context.getImageData(0, 0, 1, 1)
    return true // Canvas is likely to exportable if image data is accessible
  } catch (error) {
    if (
      error instanceof DOMException &&
      error.code === DOMException.SECURITY_ERR
    ) {
      return false // Canvas is not exportable due to CORS restrictions
    } else {
      throw error // Some other error occurred
    }
  }
}
