export const initializeCookieBanner = ({ posthog, mixpanel }) => {
  if (!posthog) {
    throw new Error('Posthog is not defined')
  }
  if (!mixpanel) {
    throw new Error('Mixpanel is not defined')
  }

  const setupCookieScript = () => {
    const acceptTargeting = () => {
      mixpanel.opt_in_tracking()
      posthog.opt_in_capturing()
    }

    const rejectTargeting = () => {
      mixpanel.opt_out_tracking()
      posthog.opt_out_capturing()
    }

    const accept = (categories) => {
      if (categories?.includes('targeting')) {
        acceptTargeting()
      }
      // Add more categories here when needed
    }

    const acceptAll = () => {
      acceptTargeting()
    }

    const rejectAll = () => {
      rejectTargeting()
    }

    const initCookies = (instance) => {
      const { categories } = instance?.currentState()
      if (!categories || categories.length === 0) {
        rejectAll()
        return
      }
      if (!categories.includes('targeting')) {
        rejectTargeting()
      }
    }

    const instance = window.CookieScript?.instance

    if (instance) {
      const isProd = process.env.NODE_ENV === 'production'
      const isKaiberSubdomain = window.location.href.includes('kaiber.ai')

      // because cookie script doesn't store user's choice in non-"kaiber.ai" domains, the banner always show up in dev
      if (!isProd && !isKaiberSubdomain) {
        // Wait for the banner to be rendered to be removed
        const interval = setInterval(() => {
          const csElement = document.getElementById('cookiescript_injected')
          if (csElement) {
            clearInterval(interval)
            csElement.remove()
          }
        }, 100)
        return
      }

      initCookies(instance)

      instance.onAcceptAll = () => {
        acceptAll()
      }

      instance.onAccept = ({ categories }) => {
        accept(categories)
      }

      instance.onReject = () => {
        rejectAll()
      }

      instance.onRejectAll = () => {
        rejectAll()
      }
    }
  }

  if (document.readyState === 'complete') {
    setupCookieScript()
  } else {
    window.addEventListener('DOMContentLoaded', setupCookieScript)
  }
}

export const showCookieBanner = () => {
  const instance = window.CookieScript?.instance
  if (!instance) return
  instance.show()
}
