import React from 'react'
import { IconType } from 'react-icons'

import { AspectRatio } from './shared'
import {
  ASPECT_RATIO,
  AUDIO_UPLOAD,
  COMPOSITION_REFERENCE_UPLOAD,
  CONTROLNET_REFERENCE_UPLOAD,
  CHECKPOINT_SELECTOR,
  CREATIVITY_SCALE,
  DENOISE,
  EVOLVE,
  HEIGHT,
  WIDTH,
  FACE_REFERENCE_UPLOAD,
  IMAGE_REFERENCE_UPLOAD,
  IMAGE_SETTINGS,
  IMAGE_UPLOAD,
  LENGTH,
  MOTION,
  MOTION_V2_SETTINGS,
  MOTION_V3_SETTINGS,
  PROMPT,
  PULID_FACE_REFERENCE_UPLOAD,
  STYLE,
  STYLE_REFERENCE_UPLOAD,
  SUBJECT,
  VERSION,
  MASK_PROMPT,
  INPAINT_PROMPT,
  FLIPBOOK_V1_SETTINGS,
  FLUX_IMAGE_SETTINGS,
  GUIDANCE,
  STEPS,
  UPSCALE_V1_SETTINGS,
  LOCK_ASPECT_RATIO,
  TRANSFORM_V3_SETTINGS,
  VIDEO_UPLOAD_S3_KEY,
} from '../constants'

export interface Path {
  key?: string
  signedUrl?: string
}

export interface FileWithSource {
  file: File
  source: string // url
  media?: Media
}

export interface ContainerState {
  modelType: ModelType
}

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
}

export enum ModelType {
  ImageLab = 'ImageLab',
  MotionV2 = 'MotionV2',
  MotionV3 = 'MotionV3',
  TransformV3 = 'TransformV3',
  FlipbookV1 = 'FlipbookV1',
  Assemble = 'Assemble',
  InpaintV1 = 'InpaintV1',
  FluxImage = 'FluxImage',
  UpscaleV1 = 'UpscaleV1',
}

export const SubjectModelType = {
  [ModelType.ImageLab]: '',
  [ModelType.MotionV2]: 'motion',
  [ModelType.MotionV3]: 'motion',
  [ModelType.TransformV3]: 'transform',
  [ModelType.FlipbookV1]: 'flipbook',
  [ModelType.Assemble]: '',
  [ModelType.InpaintV1]: '',
  [ModelType.FluxImage]: '',
  [ModelType.UpscaleV1]: '',
}

export const MODEL_DIMENSION_VALIDATION: Partial<
  Record<ModelType, { multipleOf: number }>
> = {
  [ModelType.FluxImage]: {
    multipleOf: 32,
  },
}

export const ModelLabels = {
  [ModelType.ImageLab]: 'Create Image',
  [ModelType.MotionV2]: 'Create Motion',
  [ModelType.MotionV3]: 'Create Motion',
  [ModelType.TransformV3]: 'Create Motion',
  [ModelType.FlipbookV1]: 'Create Motion',
  [ModelType.Assemble]: 'Assemble',
  [ModelType.InpaintV1]: 'Create Image',
}

export enum Status {
  Pending = 'pending',
  Done = 'done',
  Failed = 'failed',
}

export enum ElementType {
  AudioUpload = AUDIO_UPLOAD,
  CompositionReferenceUpload = COMPOSITION_REFERENCE_UPLOAD,
  ControlnetReferenceUpload = CONTROLNET_REFERENCE_UPLOAD,
  FaceReferenceUpload = FACE_REFERENCE_UPLOAD,
  ImageReferenceUpload = IMAGE_REFERENCE_UPLOAD,
  CheckpointSelector = CHECKPOINT_SELECTOR,
  ImageSettings = IMAGE_SETTINGS,
  ImageUpload = IMAGE_UPLOAD,
  InpaintPrompt = INPAINT_PROMPT,
  MotionV2Settings = MOTION_V2_SETTINGS,
  FlipbookV1Settings = FLIPBOOK_V1_SETTINGS,
  MotionV3Settings = MOTION_V3_SETTINGS,
  Prompt = PROMPT,
  PulidFaceReferenceUpload = PULID_FACE_REFERENCE_UPLOAD,
  StyleReferenceUpload = STYLE_REFERENCE_UPLOAD,
  UpscaleV1Settings = UPSCALE_V1_SETTINGS,
  VideoUpload = VIDEO_UPLOAD_S3_KEY,
  FluxImageSettings = FLUX_IMAGE_SETTINGS,
  TransformV3Settings = TRANSFORM_V3_SETTINGS,
}

export enum FieldType {
  AspectRatio = ASPECT_RATIO,
  LockAspectRatio = LOCK_ASPECT_RATIO,
  Height = HEIGHT,
  Width = WIDTH,
  AudioUpload = AUDIO_UPLOAD,
  CompositionReferenceUpload = COMPOSITION_REFERENCE_UPLOAD,
  ControlnetReferenceUpload = CONTROLNET_REFERENCE_UPLOAD,
  CheckpointSelector = CHECKPOINT_SELECTOR,
  CreativityScale = CREATIVITY_SCALE,
  Denoise = DENOISE,
  Evolve = EVOLVE,
  FaceReferenceUpload = FACE_REFERENCE_UPLOAD,
  Guidance = GUIDANCE,
  ImageReferenceUpload = IMAGE_REFERENCE_UPLOAD,
  ImageUpload = IMAGE_UPLOAD,
  Length = LENGTH,
  MaskPrompt = MASK_PROMPT,
  Motion = MOTION,
  PulidFaceReferenceUpload = PULID_FACE_REFERENCE_UPLOAD,
  Style = STYLE,
  StyleReferenceUpload = STYLE_REFERENCE_UPLOAD,
  Subject = SUBJECT,
  Steps = STEPS,
  Version = VERSION,
  VideoUpload = VIDEO_UPLOAD_S3_KEY,
}

export interface CanvasMenuConfig {
  title: string
  icon: IconType
}

export interface FlowConfig {
  mediaType: MediaType
  requiredElements: ElementType[]
  optionalElements: ElementType[]
  overrides?: FormOverrides
}

// used only serverside for now to filter out presets to be sent to client
export interface RolloutConfig {
  enabled: boolean
  featureFlag?: string
}

export interface PresetFlow {
  presetId: string
  name: string
  flow: Flow
  rolloutConfig: RolloutConfig
  inputType?: MediaType[]
  description?: string
  thumbnail?: Path
  userId: string
}

export interface CompatiblePresetFlow extends PresetFlow {
  isCompatible: boolean
}

export interface Flow {
  name?: string
  modelType: ModelType
  elements: ElementType[]
  formValues: MediaForm
}

export interface ImageForm {
  aspectRatio: AspectRatio
  batchSize?: number
  guidance?: number
  steps?: number
  compositionReference?: File[] // todo change to string[]
  compositionReferenceWeights?: number[]
  controlnetReference?: string[]
  controlnetReferenceWeights?: number[]
  curatedStyle?: string
  faceReference?: string[]
  faceReferenceWeights?: number[]
  imageReference?: string[]
  imageReferenceWeights?: number[]
  isPreview?: boolean
  maskPrompt?: string
  negativePrompt?: string
  pulidFaceReference?: string[]
  pulidFaceReferenceWeights?: number[]
  style: string
  styleReference?: string[]
  styleReferenceWeights?: number[]
  subject: string
  type: MediaType
  userProvidedStartingFrame?: string[]
  userProvidedVideoFileS3Key?: string
  stableDiffusionCheckpoint?: string
  version: string
  width: number
  height: number
  denoise?: number
  creativityScale?: number
}

export interface Image {
  assetKey?: string
  createdAt: Date
  curatedStyle?: string
  height?: number
  mediaId?: string
  flow?: Flow
  progress?: number
  source?: string // signedUrl
  style?: string
  subject?: string
  status?: Status
  tags?: string[]
  type: MediaType
  width?: number
  // TODO: ENG-1947
  // We can get mediaId once it's generated so we are using tempId(with creation date) on useCreateMediaNode.
  // We need to save this id inside Media so that we can update node data later.
  tempNodeId?: string
}

export interface VideoForm {
  aspectRatio: AspectRatio
  style: string
  subject: string
  type: MediaType
  version: string
  motionMagnitude?: number
  evolve: number
  length?: number
  userProvidedStartingFrame?: File[]
  userProvidedAudioFile?: Blob
  userProvidedVideoFile?: Blob
  userProvidedVideoFileS3Key?: string
  width?: number
  height?: number
}

// this needs fixing, should prob not extend VideoForm
export interface Video {
  mediaId?: string
  createdAt: Date
  width?: number
  height?: number
  assetKey?: string
  progress?: number
  status: Status
  source?: string
  style: string
  subject: string
  thumbnailAssetKey?: string
  thumbnailSource?: string
  type: MediaType
  version: string
  flow?: Flow
  // TODO: ENG-1947
  // We can get mediaId once it's generated so we are using tempId(with creation date) on useCreateMediaNode.
  // We need to save this id inside Media so that we can update node data later.
  tempNodeId?: string
}

export interface ImageMetadata {
  width: number
  height: number
}

export interface VideoMetadata {
  width: number
  height: number
  duration: number
}

export interface AudioMetadata {
  duration: number
}

export type Metadata = VideoMetadata | ImageMetadata | AudioMetadata

export interface Dimensions {
  width: number
  height: number
}

export interface LoadedFile {
  file: File
  source: string
  mediaType: MediaType
  metadata: Metadata
  displayDimensions?: Dimensions
}

export type MediaForm = ImageForm | VideoForm
export type Media = Image | Video

type ImageFormKeys = keyof ImageForm
type VideoFormKeys = keyof VideoForm

type FormOverrideKeys = ImageFormKeys | VideoFormKeys
type FormOverrideValues = ImageForm[ImageFormKeys] | VideoForm[VideoFormKeys]
export type FormOverrides = Record<FormOverrideKeys, FormOverrideValues> | {}

export interface CanvasVersion {
  timestamp: number
}

export interface Canvas {
  id: string
  title: string
}

/*******
 * API *
 *******/
export interface GenerateImagePayload extends ImageForm {
  curatedStyle: string
  prompt: string
  typeAndVersion: string
  videoType: string
}

export interface GenerateMotionV3Payload extends VideoForm {
  aspectRatio: AspectRatio
  originalPrompt?: string
  prompt: string
  retainInitialImage: boolean
  scenes: string
  seed: number
}

//TODO: aspectRatio, prompt are only used by BE for mixpanel.track(), but BE should use those fields in scenes[0]. Once BE stops using aspectRatio, prompt, remove these fields here.
export interface GenerateTransformV3Payload extends VideoForm {
  aspectRatio: AspectRatio
  inversionPrompt?: string
  prompt: string
  scenes: string
  seed: number
}

export interface GenerateFlipbookV1Payload extends VideoForm {
  aspectRatio: AspectRatio
  prompt: string
  scenes: string
  seed: number
}

export enum LibraryViewMode {
  Overview = 'Overview',
  Collection = 'Collection',
}

// IF THE SERVER VERSION OF THIS ENUM CHANGES, THIS ENUM MUST BE UPDATED TO MATCH.
export enum TagNamespace {
  UserTags = 'User Tags',
  Feature = 'Feature',
  MediaType = 'Media',
  Upscale = 'Upscale',
  Uploaded = 'Uploaded',
}

// This is copied from server/
export class MediaTag {
  ns: TagNamespace
  name: string
}
export interface TagFilter extends MediaTag {
  selected?: boolean
}

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  className?: string
  color?: string
  size?: number
  width?: number | string
  height?: number | string
}

export enum DropType {
  Preset = 'preset',
  Collection = 'collection',
}

export interface RatioSettingConfig {
  id: number
  label: string
  ratio: {
    w: number
    h: number
  }
  width: number
  height: number
}

interface SubjectPrompt {
  name: string
  subjectPrompt: string
}
interface GeneralStyle {
  name: string
  stylePrompt: string
  imageUrl: string
}

interface FeaturedStyle extends GeneralStyle {
  showNewIcon?: boolean
}

export interface GetPromptsResponse {
  subjects: {
    flipbook: SubjectPrompt[]
    transform: SubjectPrompt[]
    motion: SubjectPrompt[]
    [other: string]: SubjectPrompt[]
  }
  featuredStyles: FeaturedStyle[]
  generalStyles: GeneralStyle[]
}
