import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import CarouselFeature from './CarouselFeature'
import MoreFeatures from './MoreFeatures'

const audioreactImg =
  'https://website-public-assets.kybercorp.org/web/features/audioreactivity.jpg'
const textToVideoImg =
  'https://website-public-assets.kybercorp.org/web/features/animation.jpg'
const transformImg =
  'https://website-public-assets.kybercorp.org/web/features/transform.jpg'

function FeaturesIndex({ id }) {
  const [ref1, inView1] = useInView({ threshold: 0.5 })
  const [ref2, inView2] = useInView({ threshold: 0.5 })
  const [ref3, inView3] = useInView({ threshold: 0.5 })

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (inView1 && !inView2 && activeIndex !== 0) setActiveIndex(0)
    else if (inView2 && activeIndex !== 1) setActiveIndex(1)
    else if (inView3 && activeIndex !== 2) setActiveIndex(2)
  }, [inView1, inView2, inView3, activeIndex])

  const refScroll1 = useRef(null)
  const refScroll2 = useRef(null)
  const refScroll3 = useRef(null)

  return (
    <div id={id} className='flex flex-col gap-24'>
      {/* PARALLAX/CAROUSEL FEATURES */}
      <div className='relative'>
        <div className='flex w-full md:gap-10 gap-5 '>
          <div className='flex sm:flex-col flex-row sm:w-full sm:min-h-screen items-center sm:overflow-x-auto overflow-x-scroll sm:gap-0 gap-5 sm:snap-none snap-x snap-mandatory no-scrollbar'>
            <CarouselFeature
              forwardRef={ref1}
              scrollRef={refScroll1}
              title='Audioreactivity'
              description='Captivating visuals that respond to every beat and rhythm.'
              linkText='Try now'
              pathUrl='/create'
              imgMobile={audioreactImg}
            />

            <CarouselFeature
              forwardRef={ref2}
              scrollRef={refScroll2}
              title='Animation'
              description='Where your words take shape and still images come to life.'
              linkText='Try now'
              pathUrl='/create'
              imgMobile={textToVideoImg}
            />

            <CarouselFeature
              forwardRef={ref3}
              scrollRef={refScroll3}
              title='Transform'
              description='Change the look of your videos with just a few clicks.'
              linkText='Try now'
              pathUrl='/create'
              imgMobile={transformImg}
            />
          </div>
          <div className='sm:flex flex-col w-full min-w-[55%] sticky top-0 h-screen items-center hidden'>
            <div
              className={`flex absolute top-0 left-0 h-screen justify-center items-center transition-opacity duration-500 ${
                inView1 ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                className='rounded-3xl'
                src={audioreactImg}
                alt='Feature Audioreactivity'
              />
            </div>
            <div
              className={`flex absolute top-0 left-0 h-screen justify-center items-center transition-opacity duration-500 ${
                inView2 ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                className='rounded-3xl'
                src={textToVideoImg}
                alt='Feature Text-to-Video'
              />
            </div>
            <div
              className={`flex absolute top-0 left-0 h-screen justify-center items-center transition-opacity duration-500 ${
                inView3 ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                className='rounded-3xl'
                src={transformImg}
                alt='Feature Transform'
              />
            </div>
          </div>
        </div>
        <div className='flex w-full sm:sticky sm:bottom-28 sm:mt-0 mt-5 gap-5 sm:justify-start justify-center '>
          <div
            className={`h-4 w-4 rounded-full cursor-pointer ${
              activeIndex === 0 ? 'bg-primary' : 'bg-gray-300'
            }`}
            onClick={() =>
              refScroll1.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              })
            }
          />
          <div
            className={`h-4 w-4 rounded-full cursor-pointer ${
              activeIndex === 1 ? 'bg-primary' : 'bg-gray-300'
            }`}
            onClick={() =>
              refScroll2.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              })
            }
          />
          <div
            className={`h-4 w-4 rounded-full cursor-pointer ${
              activeIndex === 2 ? 'bg-primary' : 'bg-gray-300'
            }`}
            onClick={() =>
              refScroll3.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              })
            }
          />
        </div>
      </div>

      {/* MORE FEATURES - DASHBOARD/GALLERY */}
      <MoreFeatures />
    </div>
  )
}

export default FeaturesIndex
