import { useRef, useState } from 'react'
import Slider from 'react-slick'
import YouTube from 'react-youtube'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const videoOne =
  'https://website-public-assets.kybercorp.org/web/musician/video-1.jpg'
const videoTwo =
  'https://website-public-assets.kybercorp.org/web/musician/video-2.jpg'
const videoThree =
  'https://website-public-assets.kybercorp.org/web/musician/video-3.jpg'
const videoFour =
  'https://website-public-assets.kybercorp.org/web/musician/video-4.jpg'
const videoFive =
  'https://website-public-assets.kybercorp.org/web/musician/video-5.jpg'
const videoSix =
  'https://website-public-assets.kybercorp.org/web/musician/video-6.jpg'
const videoSeven =
  'https://website-public-assets.kybercorp.org/web/musician/video-7.jpg'
const videoEight =
  'https://website-public-assets.kybercorp.org/web/musician/video-8.jpg'
const videoNine =
  'https://website-public-assets.kybercorp.org/web/musician/video-9.jpg'
const videoTen =
  'https://website-public-assets.kybercorp.org/web/musician/video-10.jpg'
const videoEleven =
  'https://website-public-assets.kybercorp.org/web/musician/video-11.jpg'
const videoTwelve =
  'https://website-public-assets.kybercorp.org/web/musician/video-12.jpg'

const videoData = [
  {
    videoUrl: 'https://www.youtube.com/embed/YvIxMU1UgfI?si=TvHRQj1MNL2qurx6',
    imageUrl: videoSeven,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/7NK_JOkuSVY?si=ow0qWpylGlartwQp',
    imageUrl: videoEight,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/zr-PTLqbYqo?si=67pujbg2QxvdXpUA',
    imageUrl: videoEleven,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/6AXfUamllek?si=nQ-5PQhh1DbMKLEX',
    imageUrl: videoTwelve,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/E-hfNBsbfOw?si=0UxGJ9hzCkhicKzD',
    imageUrl: videoNine,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/CoAad6p_hGY?si=hGErCqgg4UeuaVT8',
    imageUrl: videoTen,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/HbfsaP5WN6c?si=v36my_VWom3ydqNM',
    imageUrl: videoOne,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/C0WyyMppPE0?si=gOkyh3LdjHjNGg_B',
    imageUrl: videoFive,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/3IUpzhJ7P3c?si=UItel-uucQ-pBDpI',
    imageUrl: videoSix,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/ucgqoyrAAz4?si=5VI6T4fgOT6sfWmU',
    imageUrl: videoFour,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/8jjI_CuicY4?si=MHU9T1Xu-u2VEC_g',
    imageUrl: videoThree,
  },
  {
    videoUrl: 'https://www.youtube.com/embed/tUnSBKqSSKQ?si=EFozvSBe7YpyqB3V',
    imageUrl: videoTwo,
  },
]

const VideoItem = ({
  videoUrl,
  imageUrl,
  index,
  onClick,
  onVideoPause,
  onVideoPlay,
}) => {
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(true)
    onClick(index)
  }

  const handlePause = () => {
    onVideoPause()
  }

  const handlePlay = () => {
    onVideoPlay()
  }

  return (
    <div key={index}>
      <div className='video-iframe-container block w-full rounded-[10px] md:overflow-hidden md:aspect-[1.8] max-w-[100%] cursor-pointer'>
        {clicked ? (
          <YouTube
            videoId={videoUrl.split('embed/')[1].split('?')[0]}
            containerClassName='video-container'
            opts={{
              width: '100%',
              playerVars: {
                autoplay: 1,
              },
            }}
            onPause={handlePause}
            onEnd={handlePause}
            onPlay={handlePlay}
          />
        ) : (
          <img
            src={imageUrl}
            alt={`Video thumbnail ${index + 1}`}
            className='w-full aspect-video'
            onClick={handleClick}
          />
        )}
      </div>
    </div>
  )
}

const MusicianVideoSliderMobile = () => {
  const sliderRefTop = useRef(null)

  const settingsTop = {
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    cssEase: 'linear',
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => {
      setCurrentVideoIndex(next)
    },
  }

  const [, setCurrentVideoIndex] = useState(null)
  const [, setIsPlaying] = useState(false)

  const handleImageClick = (index) => {
    setCurrentVideoIndex(index)
    setIsPlaying(true)
    sliderRefTop.current.slickPause()
  }

  const handleVideoPause = () => {
    setIsPlaying(false)
    sliderRefTop.current.slickPlay()
  }

  const handleVideoPlay = () => {
    setIsPlaying(true)
    sliderRefTop.current.slickPause()
  }

  const movieItems = videoData.map((data, index) => (
    <VideoItem
      key={index}
      videoUrl={data.videoUrl}
      imageUrl={data.imageUrl}
      index={index}
      onClick={handleImageClick}
      onVideoPause={handleVideoPause}
      onVideoPlay={handleVideoPlay}
    />
  ))

  return (
    <div>
      <Slider
        className='musician-video-slider musician-video-slider-mobile related-slider !h-auto !w-[100%] md:!w-auto'
        {...settingsTop}
        ref={sliderRefTop}
      >
        {movieItems}
      </Slider>
    </div>
  )
}

export default MusicianVideoSliderMobile
