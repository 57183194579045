import UpArrow from '../../images/UpArrow.svg'
import DownArrow from '../../images/DownArrow.svg'

export const ExpandPromptButton = ({ onClick, isPromptFullyExpanded }) => (
  <button
    onClick={onClick}
    className='p-0 transition duration-200 ease-in-out rounded-full over:bg-opacity-70 focus:outline-none h-7 w-7'
  >
    {isPromptFullyExpanded ? (
      <img src={DownArrow} alt='Down Arrow' />
    ) : (
      <img src={UpArrow} alt='Up Arrow' />
    )}
  </button>
)
