import { FieldType } from '../types'

export const isImageReferenceField = (field: FieldType) => {
  const imageReferenceFields = new Set<FieldType>([
    FieldType.CompositionReferenceUpload,
    FieldType.ControlnetReferenceUpload,
    FieldType.FaceReferenceUpload,
    FieldType.ImageReferenceUpload,
    FieldType.PulidFaceReferenceUpload,
    FieldType.StyleReferenceUpload,
  ])

  return imageReferenceFields.has(field)
}

export const toTitleCase = (str: string): string => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
}
