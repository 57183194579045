import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import AdminNavbar from '../../components/AdminNavbar'
import Footer from '../../components/Footer'
import Line from '../../components/charts/Line'
import http from '../../services/HttpService'

export default function AdminDashboard() {
  const [isLoading, setIsLoading] = useState(true)
  const [totalUsers, setTotalUsers] = useState(0)
  const [onlineUsers, setOnlineUsers] = useState(0)
  const [totalSubscribers, setTotalSubscribers] = useState(0)
  const [dailyActiveUsers, setDailyActiveUsers] = useState([])
  const [userRetention, setUserRetention] = useState([])
  const [signups, setSignups] = useState([])
  const [fromDate] = useState(
    moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),
  )
  const [toDate] = useState(moment().utc().format('YYYY-MM-DD'))

  const fetchInitialData = useCallback(async () => {
    const params = {
      fromDate,
      toDate,
    }

    const responses = await Promise.all([
      http.get('/api/admin/users/total'),
      http.get('/api/admin/users/subscribers'),
      http.get('/api/admin/users/online'),
      http.get('/api/admin/activity/daily-active-users', { params }),
      http.get('/api/admin/activity/daily-user-retention', { params }),
      http.get('/api/admin/activity/daily-signups', { params }),
    ])

    setTotalUsers(responses[0].data.totalUsers)
    setTotalSubscribers(responses[1].data.totalSubscribers)
    setOnlineUsers(responses[2].data.onlineUsers)
    setDailyActiveUsers(responses[3].data)
    setUserRetention(responses[4].data)
    setSignups(responses[5].data)
    setIsLoading(false)
  }, [fromDate, toDate])

  const fetchOnlineUsers = useCallback(async () => {
    const response = await http.get('/api/admin/users/online')

    setOnlineUsers(response.data.onlineUsers)
  }, [])

  const [dailyActiveUsersData, dailyActiveUsersAvg] = useMemo(
    () => [
      [
        {
          label: 'Active Users',
          data: dailyActiveUsers.map((d, i) => ({
            date: moment(d.date).utc().toDate(),
            value: d.activeUsers,
          })),
        },
        // {
        //   label: 'Active Users',
        //   data: dates.map((d, i) => ({
        //     date: d,
        //     activeUsers
        //   }))
        // }
      ],
      dailyActiveUsers.reduce((a, b) => a + b.activeUsers, 0) /
        dailyActiveUsers.length,
    ],
    [dailyActiveUsers],
  )

  const [userRetentionData, userRetentionAvg] = useMemo(
    () => [
      [
        {
          label: 'Retention',
          data: userRetention.map((d, i) => ({
            date: moment(d.date).utc().toDate(),
            value: d.retention,
          })),
        },
        // {
        //   label: 'User Retention',
        //   data: dates.map((d, i) => ({
        //     date: d,
        //     userRetention
        //   }))
        // }
      ],
      userRetention.reduce((a, b) => a + b.retention, 0) / userRetention.length,
    ],
    [userRetention],
  )

  const [signupsData, signupsAvg] = useMemo(
    () => [
      [
        {
          label: 'Signups',
          data: signups.map((d, i) => ({
            date: moment(d.date).utc().toDate(),
            value: d.signups,
          })),
        },
        // {
        //   label: 'Signups',
        //   data: dates.map((d, i) => ({
        //     date: d,
        //     signups
        //   }))
        // }
      ],
      signups.reduce((a, b) => a + b.signups, 0) / signups.length,
    ],
    [signups],
  )

  const formatNumber = (value) => {
    return Intl.NumberFormat('en-US').format(Math.round(value))
  }

  useEffect(() => {
    let intervalId
    ;(async () => {
      await fetchInitialData()

      intervalId = setInterval(() => {
        fetchOnlineUsers()
      }, 3000)
    })()

    return () => {
      clearInterval(intervalId)
    }
  }, [fetchInitialData, fetchOnlineUsers])

  return (
    <div className='bg-dark'>
      <AdminNavbar />
      <div className='section py-10'>
        <div className='container mx-auto max-w-7xl space-y-8 px-5'>
          <div className='flex justify-between'>
            <h1 className='text-tertiary/80 flex gap-2 text-5xl font-bold'>
              Activity
            </h1>
            {!isLoading && (
              <div
                className='flex items-center gap-2 mt-4'
                title='Active users in the last 15 minutes'
              >
                <div className='pulsating-circle'></div>
                <p className='text-primary font-bold cursor-default'>
                  {onlineUsers}
                </p>
              </div>
            )}
          </div>
          {isLoading ? (
            <div className='flex h-full'>
              <div className='m-auto bg-darkGray p-6 rounded-3xl'>
                <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
              </div>
            </div>
          ) : (
            <div className='grid grid-cols-2 gap-6'>
              <div className='col-span-2 sm:col-auto bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
                <p className='font-bold text-tertiary'>Total users</p>
                <p className='text-tertiary/80 flex gap-2 text-5xl font-bold mt-2'>
                  {formatNumber(totalUsers)}
                </p>
              </div>
              <div className='col-span-2 sm:col-auto bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
                <p className='font-bold text-tertiary'>Total subscribers</p>
                <p className='text-tertiary/80 flex gap-2 text-5xl font-bold mt-2'>
                  {formatNumber(totalSubscribers)}
                </p>
              </div>
              <div className='col-span-2'>
                <button
                  type='button'
                  className='flex items-center ml-auto p-2 rounded-lg border border-primary/25 shadow-sm hover:bg-gray-700 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 text-white'
                >
                  <CalendarIcon className='h-5 w-5' />
                  <span className='mx-1'>Last 30 Days</span>
                  <ChevronDownIcon className='h-4 w-4' />
                  {/* <ChevronUpIcon className='h-4 w-4' /> */}
                </button>
              </div>
              {dailyActiveUsers.length && (
                <div className='col-span-2 bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
                  <p className='font-bold text-tertiary'>Daily Active Users</p>
                  <p className='text-tertiary/80 flex gap-2 text-5xl font-bold mt-2'>
                    {formatNumber(dailyActiveUsersAvg)}
                  </p>
                  <Line className='mt-6' data={dailyActiveUsersData} />
                </div>
              )}
              {userRetention.length && (
                <div className='col-span-2 bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
                  <p className='font-bold text-tertiary'>User Retention</p>
                  <p className='text-tertiary/80 flex gap-2 text-5xl font-bold mt-2'>
                    {userRetentionAvg.toFixed(2)}%
                  </p>
                  (
                  <Line
                    className='mt-6'
                    data={userRetentionData}
                    numberType='percent'
                  />
                  )
                </div>
              )}
              <div className='col-span-2 bg-darkGray px-4 py-5 sm:p-6 shadow rounded-2xl'>
                <p className='font-bold text-tertiary'>Signups</p>
                <p className='text-tertiary/80 flex gap-2 text-5xl font-bold mt-2'>
                  {formatNumber(signupsAvg)}
                </p>
                <Line className='mt-6' data={signupsData} />
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}
