export const API_ENDPOINTS = {
  CREATE_CANVAS: '/api/v1/canvas/create',
  CREATE_COLLECTION: '/api/v1/collection',
  DELETE_COLLECTION: '/api/v1/collection/:collectionId',
  DELETE_PRESET_FLOW: '/api/v1/flow/delete_preset_flow',
  GENERATE_MOTION_V3: '/api/motion/v3/generate_video',
  GENERATE_TRANSFORM_V3: '/api/transform/v2/generate_video', // transform v2 is actually v3
  GET_CANVASES: '/api/v1/canvas/canvases',
  GET_CANVAS_VERSION_IDS: '/api/v1/canvas/versions',
  GET_COLLECTION: '/api/v1/collection/:collectionId',
  LIST_COLLECTIONS: '/api/v1/collection',
  GET_KAIBER_PRESET_FLOWS: '/api/v1/flow/kaiber_preset_flows',
  GET_MEDIA: '/api/v1/media',
  GET_MY_MEDIA: '/api/v1/media/my_media',
  GET_PREVIEW_FRAMES: '/api/get_preview_frames',
  GET_SIGNED_URL: '/api/get_signed_url',
  GET_USER_PRESET_FLOWS: '/api/v1/flow/preset_flows',
  GET_VIDEO: '/api/get_memory',
  LIST_UNIQUE_TAGS: '/api/v1/media/unique_tags',
  LOAD_CANVAS: '/api/v1/canvas/load',
  PROCESS_MEDIA: '/api/v1/media/process_media',
  SAVE_CANVAS: '/api/v1/canvas/save',
  SAVE_MEDIA: '/api/v1/media/save_media',
  SAVE_PRESET_FLOW: '/api/v1/flow/save_preset_flow',
  UPDATE_COLLECTION: '/api/v1/collection',
  UPDATE_PRESET_FLOW: '/api/v1/flow/update_preset_flow',
  UPDATE_TITLE: '/api/v1/canvas/title',
  UPDATE_USER_PREFERENCES: '/api/v1/profile/preferences',
  GET_DEFAULT_PROMPTS: '/api/v1/prompt/preset_prompts',
  UPLOAD_ASSET: '/api/upload_asset',
}

export const HEADERS = { 'Content-Type': 'multipart/form-data' }

export const FLAGS = {
  KAIBER_2: 'enable-kaiber-2',
  TYPESCRIPT_TEST: 'enable-typescript-test',
  USER_PROFILES: 'feature-user-profiles',
}

export const ANNOUNCEMENTS_LAST_VIEWED_K1 = 'ANNOUNCEMENTS_LAST_VIEWED_K1'
export const ANNOUNCEMENTS_LAST_VIEWED_K2 = 'ANNOUNCEMENTS_LAST_VIEWED_K2'
