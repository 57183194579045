import { AiOutlineQuestionCircle } from 'react-icons/ai'
import classNames from 'classnames'

// Create a React component for radio buttons
const RadioGroup = ({
  title,
  description,
  handleChange,
  options = [],
  selectedOption,
}) => {
  const handleRadioChange = (event) => {
    handleChange(parseInt(event.target.value))
  }

  return (
    <div className='mb-12'>
      <div className='flex mb-5 gap-3 items-center'>
        <h1 className='text-xl lg:text-2xl xl:text-3xl'>{title}</h1>

        {/* Show information tooltip */}
        <div className='flex has-tooltip'>
          <span className='tooltip rounded shadow-lg px-2 py-1 bg-gray-800 text-gray-100 -mt-8'>
            {description}
          </span>
          <AiOutlineQuestionCircle className='w-6 h-6 self-center select-none' />
        </div>
        <div className='flex-grow text-quaternary'>
          <a
            href='https://helpcenter.kaiber.ai/kaiber/en/articles/8699970-transform-1-0-vs-transform-2-0'
            rel='noreferrer'
            target='_blank'
          >
            Learn more
          </a>
        </div>
      </div>

      {/* Radio button group */}
      <div className='flex relative rounded-full ease-in-out duration-300'>
        {options.map((option, index) => (
          <label
            key={index}
            className={classNames(
              `w-1/${options.length} flex items-center justify-center truncate uppercase select-none rounded-full py-2 w-20 mr-2 transition-all ease-in-out border border-primary/50`,
              {
                'cursor-pointer':
                  (selectedOption !== option.value &&
                    option.numberActiveScenes === 1) ||
                  (selectedOption !== option.value &&
                    option.numberActiveScenes > 1 &&
                    option.name !== '3.0'),
                'opacity-40':
                  option.numberActiveScenes > 1 && option.name === '3.0',
                'bg-primary text-dark font-bold':
                  selectedOption === option.value,
                'hover:bg-primary hover:text-dark':
                  selectedOption !== option.value &&
                  option.numberActiveScenes === 1,
              },
            )}
          >
            <input
              type='radio'
              name={option.name}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={handleRadioChange}
              className='appearance-none'
              disabled={option.numberActiveScenes > 1 && option.name === '3.0'}
            />
            <span
              htmlFor={option.name}
              className={classNames(
                `w-1/${options.length} flex items-center justify-center truncate uppercase select-none rounded-full`,
              )}
            >
              {option.name}
            </span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioGroup
