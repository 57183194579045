import SectionTitle from './SectionTitle'

function CTA() {
  return (
    <div className='flex flex-col flex-1 max-w-[1440px] w-full items-center md:px-[3.5%] px-4'>
      <SectionTitle title='Try Kaiber for free' description={null} />
      <form
        id='email-form'
        name='email-form'
        action='/register'
        method='GET'
        aria-label='Email Form'
        className='flex flex-col items-center justify-center flex-1 w-full gap-10'
      >
        <input
          className='md:max-w-xs h-14 w-full outline-none border-white border-b-2 py-3 px-4 text-3xl leading-10 text-center text-white bg-transparent'
          type='email'
          maxLength='256'
          name='email'
          data-name='email'
          placeholder='Enter your email'
          id='email'
          required
        />
        <input
          type='submit'
          value='Get Started'
          data-wait='Please wait...'
          className='flex justify-center text-xl items-center min-w-[200px] md:w-fit w-full leading-5 rounded-full px-4 py-3 font-medium bg-primary hover:bg-primary/90 active:bg-primary/40 text-black cursor-pointer duration-300'
        />
      </form>
    </div>
  )
}

export default CTA
