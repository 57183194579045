import { useState } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'
import { MusicalNoteIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import TrimAudio from './TrimAudio'
import { Modal } from './Modal'
import { useUserAccountContext } from '../context/userContext'
import { AudioPlayback } from './AudioPlayback'

import Lottie from 'lottie-react'
import LoadingWhite from '../images/lottie/loaderWhite.json'

import { isMobile } from '../utils'
import { fileTypeFromBuffer } from 'file-type'
import { SUPPORTED_AUDIO_EXTENSIONS } from '../utils/constants'

const AudioUpload = ({
  title,
  description,
  color,
  onChange,
  setAlertMessage,
  setShowAlert,
  audio,
  audioUrl,
  fileName,
  setFileName,
  isProcessing,
  setIsProcessing,
  longVideoMaintenanceMode,
  disabled,
  disabledFade,
  maxDuration,
  didReuseSettings,
  reuseHasAudio,
  showMaxAudioUploadLength,
}) => {
  const [isTrimming, setIsTrimming] = useState(false)
  const { currentUser } = useUserAccountContext()
  const isSubscribed = currentUser?.subscriptionType === 'Standard'

  const maxAudioLengthText = longVideoMaintenanceMode
    ? '2 minutes'
    : '4 minutes'

  const validateAudioFileLength = (file) => {
    setIsProcessing(true)
    const reader = new FileReader()
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    reader.readAsArrayBuffer(file)
    reader.onload = function () {
      const arrayBuffer = reader.result
      audioContext.decodeAudioData(arrayBuffer, function (audioBuffer) {
        if (audioBuffer.duration > maxDuration) {
          setAlertMessage(
            `Your audio will be trimmed to the first ${maxAudioLengthText}`,
          )
          setShowAlert(true)
          cutAudio(audioBuffer)
        } else {
          onChange(file)
        }
      })
    }
  }

  const isValidAudio = async (file) => {
    try {
      if (disabled) {
        return
      }

      const slicedFile = file.slice(0, 100)
      let fileBuffer = await slicedFile.arrayBuffer()

      let typeFromBuffer = await fileTypeFromBuffer(fileBuffer)

      if (file && !file.type.startsWith('audio')) {
        toast.error('Only audios are allowed.')
        return
      }

      if (
        !SUPPORTED_AUDIO_EXTENSIONS.includes(typeFromBuffer.mime.split('/')[1])
      ) {
        toast.error('Invalid audio file format.')
        return
      }

      setFileName(file.name)
      validateAudioFileLength(file)
    } catch (err) {
      console.log(err)
      toast.error('Only audios are allowed.')
    }
  }

  const handleDrop = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    await isValidAudio(file)
  }

  const handleClick = (e) => {
    e.preventDefault()
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept =
      'audio/m4a, audio/x-m4a, audio/wav, audio/ogg, audio/aac, audio/mp3'
    fileInput.style.display = 'none'
    fileInput.onchange = async (e) => {
      const file = e.target.files[0]
      await isValidAudio(file)
    }

    document.body.appendChild(fileInput)

    fileInput.click()

    // iOS fix
    setTimeout(() => {
      document.body.removeChild(fileInput)
    }, 100)
  }

  const handleTrimmedAudio = (trimmedAudio) => {
    setIsTrimming(false)
    onChange(trimmedAudio)
  }

  const handleClose = () => {
    onChange(null)
  }

  const bufferToWave = (abuffer, len, channel_offset = 0) => {
    const numOfChan = abuffer.numberOfChannels
    const length = len * numOfChan * 2 + 44

    let buffer = new ArrayBuffer(length)
    let view = new DataView(buffer)
    let channels = []
    let offset = 0
    let pos = 0
    let i
    let sample

    // write WAVE header
    setUint32(0x46464952) // 'RIFF'
    setUint32(length - 8) // file length - 8
    setUint32(0x45564157) // 'WAVE'

    setUint32(0x20746d66) // 'fmt ' chunk
    setUint32(16) // length = 16
    setUint16(1) // PCM (uncompressed)
    setUint16(numOfChan)
    setUint32(abuffer.sampleRate)
    setUint32(abuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
    setUint16(numOfChan * 2) // block-align
    setUint16(16) // 16-bit (hardcoded in this demo)

    setUint32(0x61746164) // 'data' - chunk
    setUint32(length - pos - 4) // chunk length

    // write interleaved data
    for (i = 0; i < abuffer.numberOfChannels; i++)
      channels.push(abuffer.getChannelData(i))

    while ((pos < length) & (offset + channel_offset < channels[0].length)) {
      for (i = 0; i < numOfChan; i++) {
        // interleave channels
        sample = Math.max(-1, Math.min(1, channels[i][offset + channel_offset])) // clamp
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
        view.setInt16(pos, sample, true) // write 16-bit sample
        pos += 2
      }
      offset++ // next source sample
    }

    // create Blob
    return new Blob([buffer], { type: 'audio/wav' })

    function setUint16(data) {
      view.setUint16(pos, data, true)
      pos += 2
    }

    function setUint32(data) {
      view.setUint32(pos, data, true)
      pos += 4
    }
  }

  const cutAudio = (audioBuffer, start = 0) => {
    const newAudioBlob = bufferToWave(
      audioBuffer,
      2 * Math.round((maxDuration * audioBuffer.sampleRate) / 2),
      2 * Math.round((start * audioBuffer.sampleRate) / 2),
    )

    onChange(newAudioBlob)
  }

  return (
    <>
      <Modal
        open={!!isTrimming}
        setOpen={() => setIsTrimming(false)}
        handleClose={handleClose}
      >
        {audio && (
          <TrimAudio
            file={audio}
            handleTrimmedAudio={handleTrimmedAudio}
            handleCancel={() => {
              setIsTrimming(false)
              onChange(null)
            }}
          />
        )}
      </Modal>

      <div
        className={`flex flex-col text-${color} relative bg-darkGray rounded-2xl text-left ${
          audio ? 'border-primary border-4' : ''
        } ${isMobile() ? '' : 'w-full p-5 max-w-md'} ${
          disabledFade || disabled || !isSubscribed
            ? 'opacity-50'
            : 'hover:bg-[#30303a]'
        } `}
      >
        <div className='flex w-full gap-3'>
          <button
            className={`w-full ${isMobile() ? 'p-4' : ''}`}
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            disabled={disabled || !isSubscribed || isProcessing}
          >
            <div className='flex justify-center items-center gap-3 mb-2'>
              <p className='text-xl leading-7 sm:text-[21px]'>{title}</p>
            </div>
            <div
              className={classNames('flex items-center flex-1 w-full', {
                'justify-center': audio || isProcessing,
              })}
            >
              {!isSubscribed ? (
                <></>
              ) : isProcessing ? (
                <div className='flex flex-col items-center justify-center flex-1 mt-4 text-center'>
                  <Lottie
                    animationData={LoadingWhite}
                    loop={true}
                    className='w-16 h-16'
                  />
                </div>
              ) : audio ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  className='flex flex-col items-center justify-center flex-1'
                >
                  <AudioPlayback color={color} audioUrl={audioUrl} />
                </div>
              ) : null}
            </div>
            <div className='w-full'>
              {!isSubscribed ? (
                <Link to='/pricing'>
                  <div className='rounded-full font-bold bg-gray-500/80 text-left text-primary text-sm px-4 py-2.5'>
                    <StarIcon className='inline w-6 h-6 mr-2' />
                    Upgrade to upload initial audio
                  </div>
                </Link>
              ) : !audio ? (
                <div className='flex items-center'>
                  <div className={`${isMobile() ? 'flex justify-center' : ''}`}>
                    <MusicalNoteIcon className='w-12 h-12' />
                  </div>
                  <div
                    className={`${
                      isMobile() ? 'ml-4' : 'ml-4 leading-4 text-left'
                    }`}
                  >
                    <small>
                      <span className={`${isMobile() ? 'hidden' : ''}`}>
                        Drag audio here or
                      </span>{' '}
                      <span
                        className={`${
                          isMobile() ? 'hidden' : 'underline underline-offset-2'
                        }`}
                      >
                        upload file.
                      </span>
                      {showMaxAudioUploadLength ? (
                        <span
                          className={`${
                            isMobile() ? 'text-[16px] font-normal' : 'block'
                          }`}
                        >
                          Max length{' '}
                          {(maxDuration / 60) % 1 === 0
                            ? maxDuration / 60
                            : (maxDuration / 60).toFixed(1)}{' '}
                          mins.
                        </span>
                      ) : (
                        <span
                          className={`${
                            isMobile() ? 'text-[16px] font-normal' : 'block'
                          }`}
                        >
                          Upload audio file.
                        </span>
                      )}
                    </small>
                  </div>
                </div>
              ) : null}
            </div>
          </button>
        </div>
        {/* CLOSE BUTTON */}
        {audio && !isProcessing && (
          <div className='top-8 right-8'>
            <button
              type='button'
              onClick={() => onChange(null)}
              className='flex text-KaiberGreen'
            >
              <small className='underline underline-offset-2 p-2'>Remove</small>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default AudioUpload
