import SyntaxHighlighter from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

export default function UserRawJSONTab({ user }) {
  return (
    <SyntaxHighlighter
      language='javascript'
      style={monokaiSublime}
      showLineNumbers
    >
      {JSON.stringify(user, null, 2)}
    </SyntaxHighlighter>
  )
}
