interface PencilIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}
export const PencilIcon = ({ size = '1em', ...props }: PencilIconProps) => (
  <svg
    stroke='currentColor'
    fill='none'
    strokeWidth='0'
    viewBox='0 0 10 10'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.625 2.12497L7.0429 0.707078C7.4334 0.316553 8.0666 0.316553 8.4571 0.707078L9.2929 1.54286C9.6834 1.93339 9.6834 2.56655 9.2929 2.95708L7.875 4.37497M5.625 2.12497L0.667895 7.08207C0.480355 7.26962 0.375 7.52397 0.375 7.78917V9.62497H2.21078C2.476 9.62497 2.73036 9.51962 2.9179 9.33207L7.875 4.37497M5.625 2.12497L7.875 4.37497'
      stroke='currentColor'
      strokeWidth='0.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
