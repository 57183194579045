import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../styles/list-decimal.css'

export default function Privacy() {
  return (
    <div className='bg-dark'>
      <Navbar />
      <div className='max-w-7xl mx-auto px-5 py-12 pt-32'>
        <div className='max-w-2xl space-y-4 mx-auto'>
          <h1 className='text-tertiary text-6xl'>Terms of Service</h1>
          <h2 className='text-tertiary text-xl'>
            Date of Last Revision: October 31, 2023
          </h2>
          <div className='text-justify'>
            <p className='text-tertiary/60'>
              Kyber Corp. dba Kaiber (“<b>Kaiber</b>”, “<b>we</b>”, “<b>us</b>”,
              or “<b>our</b>”) has made these Terms of Service (the “
              <b>Agreement</b>t”) available to explain the terms and conditions
              by which you may access and use (a) Kaiber’s products and
              services, (b){' '}
              <a className='text-primary' href='https://kaiber.ai/'>
                https://kaiber.ai
              </a>
              , (c) our iOS and Android mobile applications (the “Mobile Apps”),
              and (d) other related products and services that link to this
              Agreement (collectively, the “Platform”).
            </p>
            <p className='text-tertiary/60'>
              You must read this Agreement carefully as it governs your use of
              the Platform. By accessing or using the Platform, you signify that
              you have read, understand, and agree to be bound by this Agreement
              in its entirety. If you do not agree, you are not authorized to
              access or use of our Platform and should not use our Platform.
            </p>

            <ol className='list-decimal ml-5 mt-5'>
              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Kaiber Platform
                </span>
                <ol className='list-decimal'>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Our Platform</u>. Kaiber provides a platform that allows
                    you to generate images, videos, and other content (“
                    <b>Output</b>”) based on (i) text or style inputs you select
                    or provide to Kaiber or other images, music, videos, and
                    other content you may upload or provide to Kaiber (“
                    <b>User Input</b>”), and (ii) images, music, sounds, and
                    other content we may source from third parties (“
                    <b>Third-Party Content</b>”). We are constantly improving
                    the Platform. You agree and acknowledge that the Platform is
                    subject to modification and change, including but not
                    limited to the art style of Output, the algorithms and
                    methods used to generate the Output, and the features
                    available to you.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Registration</u>. In order to use certain portions of the
                    Platform, you must register an account by providing us with
                    your name, email, and other information requested in our
                    registration form. You agree to provide us with complete and
                    accurate registration information. You may not attempt to
                    impersonate another person in registration. If you are
                    registering for our Platform on behalf of an organization,
                    you warrant that you are authorized to agree to this
                    Agreement on their behalf. You agree to be responsible for
                    the security of your account. You accept that you are solely
                    responsible for all activities that take place through your
                    account, and that failure to limit access to your devices or
                    browser may permit unauthorized use by third-parties. Only
                    one user may use the Platform per registered account. Each
                    user of the Platform may only have one account.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>No Minors Permitted</u>. Our Platform are not intended
                    for minors under the age of 18. If you are a minor under the
                    age of 18, please do not register for our Platform or send
                    any personal information to us. If you have reason to
                    believe that a minor under the age of 18 is using our
                    Platform, please let us know immediately at{' '}
                    <a className='text-primary' href='mailto:support@kaiber.ai'>
                      support@kaiber.ai
                    </a>{' '}
                    and we will seek to revoke access and delete any associated
                    information as quickly as possible.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Additional Policies</u>. You agree and acknowledge that
                    your use of the Platform is subject to our Privacy Policy
                    available{' '}
                    <a className='text-primary' href='https://kaiber.ai/'>
                      https://kaiber.ai
                    </a>{' '}
                    .
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Usage Requirements
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Use of Platform</u>. You may access, and we grant you a
                    non-exclusive right to use, the Platform in accordance with
                    this Agreement. You will comply with this Agreement and all
                    applicable laws when using the Platform. We and our
                    affiliates own all rights, title, and interest in and to the
                    Platform, including the underlying technology and
                    intellectual property rights therein.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Content</u>. You retain ownership of all User Input you
                    provide to the Platform and all Output generated from your
                    User Input (excluding any Third-Party Content included with
                    the Output). User Input and all Output (but excluding any
                    Third-Party Content included with the Output) is
                    collectively referred to as “User Content”. Subject to your
                    compliance with this Agreement and to the extent Kaiber
                    acquires any right in any User Content, Kaiber hereby
                    assigns to you all its right, title and interest in and to
                    your User Content. You are permitted to use (i) Output for
                    any purpose if you comply with this Agreement and (ii)
                    Third-Party Content incorporated into any Output solely as
                    part of the original Output and not on a standalone basis,
                    including not to directly commercialize or monetize such
                    Third-Party Content. During and after the term of this
                    Agreement, Kaiber may freely use your User Content to
                    provide and maintain the Platform, develop and improve our
                    products and services (including to improve and train our AI
                    models), comply with applicable law, and enforce our
                    policies. You are responsible for all User Content,
                    including for ensuring that it does not violate any
                    applicable law or this Agreement.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Similar Output</u>. Due to the nature of machine
                    learning, Output may not be unique across users and the
                    Platform may generate the same or similar output for Kaiber
                    or a third party. Other users may also provide similar User
                    Input and receive the same or similar Output. Output that
                    are requested by and generated for other users are not
                    considered yours.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Feedback</u>. We appreciate feedback, comments, ideas,
                    proposals and suggestions for improvements. If you provide
                    any of these things, we may use it without restriction or
                    compensation to you. You also authorize Kaiber and its
                    third-party service providers to derive statistical and
                    usage data relating to your use of the Platform (“
                    <b>Usage Data</b>”). We may use Usage Data for any purpose
                    under applicable law and our Privacy Policy.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Platform Restrictions</u>. You may not (i) use the
                    Platform in a way that infringes, misappropriates or
                    violates any person’s rights (ii) reverse assemble, reverse
                    compile, decompile, translate or otherwise attempt to
                    discover the source code or underlying components of models,
                    algorithms, and systems of the Platform (except to the
                    extent such restrictions are contrary to applicable law)
                    (iii) use output from the Platform to develop models that
                    compete with Kaiber (iv) use any automated or programmatic
                    method to extract data or output from the Platform,
                    including scraping, web harvesting, or web data extraction
                    (v) represent that output from the Platform was
                    human-generated when it is not (vi) send us any personal
                    information of children under 13 or the applicable age of
                    digital consent or (vii) use the Platform in violation of
                    any applicable laws and regulations (including any export
                    control laws). You will comply with any rate limits and
                    other requirements in our documentation.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>User Conduct</u>. You represent, warrant, and covenant
                    that: (i) any User Content you transfer via the Platform
                    have been legally obtained and belong to you (ii) you will
                    not upload or create any User Content that contain adult
                    content, gore, child sexual abuse material or any content
                    that exploits or harms children (iii) you will not engage in
                    any conduct that is or could be considered illegal, obscene,
                    defamatory, threatening, intimidating, harassing, hateful or
                    racially or ethnically offensive (iv) you will not provide
                    any false, inaccurate or misleading information while using
                    the Platform, or engage in any activity that operates to
                    defraud Kaiber, other users of the Platform, or any other
                    person or entity (v) you will not interfere with or disrupt
                    the Platform or servers or networks connected to the
                    Platform, or disobey any requirements, procedures, policies,
                    or regulations of networks connected to the Platform (vi)
                    you will not impersonate any person or entity, or falsely
                    state or otherwise misrepresent your affiliation with a
                    person or entity (vii) you will not infringe, misappropriate
                    or violate any intellectual property, privacy, publicity or
                    other proprietary rights of Kaiber or any third party (viii)
                    you will not disguise your location through IP proxying or
                    other methods and (ix) you will not obtain or attempt to
                    access or otherwise obtain any content or information
                    through any means not intentionally made available or
                    provided for through the Platform, including attempting to
                    avoid, bypass, remove, deactivate, impair, descramble or
                    otherwise circumvent any technological measure implemented
                    by us or any of our service providers or any other third
                    party to protect the Platforms.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Confidentiality</u>. In connection with the Platform, you
                    may be given access to certain Confidential Information of
                    Kaiber. You may use Confidential Information only as needed
                    to use the Platform as permitted under this Agreement. You
                    may not disclose Confidential Information to any third
                    party, and you will protect Confidential Information in the
                    same manner that you protect your own confidential
                    information of a similar nature, using at least reasonable
                    care. “<b>Confidential Information</b>” means nonpublic
                    information that Kaiber or its affiliates or third parties
                    designate as confidential or should reasonably be considered
                    confidential under the circumstances, including software,
                    specifications, and other nonpublic business information.
                    Confidential Information does not include information that:
                    (i) is or becomes generally available to the public through
                    no fault of yours (ii) you already possess without any
                    confidentiality obligations when you received it under this
                    Agreement (iii) is rightfully disclosed to you by a third
                    party without any confidentiality obligations or (iv) you
                    independently developed without using Confidential
                    Information. You may disclose Confidential Information when
                    required by law or the valid order of a court or other
                    governmental authority if you give reasonable prior written
                    notice to Kaiber and use reasonable efforts to limit the
                    scope of disclosure, including assisting us with challenging
                    the disclosure requirement, in each case where possible.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Third Party Platform</u>. Any third party software,
                    services, or other products you use in connection with the
                    Platform are subject to their own terms, and we are not
                    responsible for any such third party products.
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Fees and Payments
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Fees and Billing</u>. . To the extent the Platform are
                    made available to you for a fee, you will pay all fees
                    charged to your account (“<b>Fees</b>”) according to the
                    prices and terms on the applicable pricing page, or as
                    otherwise agreed between us in writing. We have the right to
                    correct pricing errors or mistakes even if we have already
                    issued an invoice or received payment. You will provide
                    complete and accurate billing information including a valid
                    and authorized payment method. We will charge your payment
                    method on an agreed-upon periodic basis, but may reasonably
                    change the date on which the charge is posted. You authorize
                    Kaiber, and our third-party payment processor(s), to charge
                    your payment method for the Fees. If your payment cannot be
                    completed, we will provide you written notice and may
                    suspend access to the Platform until payment is received.
                    Fees are payable in U.S. dollars and are due upon invoice
                    issuance. Payments are nonrefundable except as provided in
                    this Agreement.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Taxes</u>. Unless otherwise stated, Fees do not include
                    federal, state, local, and foreign taxes, duties, and other
                    similar assessments (“<b>Taxes</b>
                    ”). You are responsible for all Taxes associated with your
                    purchase, excluding Taxes based on our net income, and we
                    may invoice you for such Taxes. You agree to timely pay such
                    Taxes and provide us with documentation showing the payment,
                    or additional evidence that we may reasonably require.
                    Kaiber uses the name and address in your account
                    registration as the place of supply for tax purposes, so you
                    must keep this information accurate and up-to-date.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Changes in Fees</u>. We may change our prices by posting
                    notice to your account and/or to our website. Price
                    increases will be effective 14 days after they are posted,
                    except for increases made for legal reasons or increases
                    made to any free or beta services, which will be effective
                    immediately. Any price changes will apply to the Fees
                    charged to your account immediately after the effective date
                    of the changes.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Free Tier</u>. You may not create more than one account
                    to benefit from credits provided in the free tier of the
                    Platform. If we believe you are not using the free tier in
                    good faith, we may charge you standard fees or stop
                    providing access to the Platform
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Term and Termination
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Termination Suspension</u>. This Agreement takes effect
                    when you first use the Platform and remain in effect until
                    terminated. You may terminate this Agreement at any time for
                    any reason by discontinuing the use of the Platform. We may
                    terminate this Agreement for any reason by providing you at
                    least 30 days’ advance notice. We may terminate this
                    Agreement immediately upon notice to you if you materially
                    breach this Agreement (including any breach of Sections 2.4
                    and 2.5), if there are changes in relationships with third
                    party technology providers outside of our control, or to
                    comply with law or government requests. We may suspend your
                    access to the Platform, with or without notice, if you do
                    not comply with this Agreement, if your use poses a security
                    risk to us or any third party, or if we suspect that your
                    use is fraudulent or could subject us or any third party to
                    liability.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Effect on Termination</u>. Upon termination, you will
                    stop using the Platform and you will promptly return or, if
                    instructed by us, destroy any Confidential Information. The
                    sections of this Agreement which by their nature should
                    survive termination or expiration should survive, including
                    but not limited to Sections 2.2, 2.4, 2.5, 4, and 6-9.
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Mobile Services and Software
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Mobile Services</u>. The Platform includes certain
                    services that are available via a mobile device, including
                    (i) the ability to upload content to the Platform via a
                    mobile device, (ii) the ability to access or browse the
                    Platform from a mobile device, and (iii) the ability to
                    access certain features and content through Mobile Apps
                    (collectively, the “<b>Mobile Services</b>”). To the extent
                    you access the Platform through a mobile device, your
                    wireless service carrier’s standard charges, data rates, and
                    other fees may apply. In addition, downloading, installing,
                    or using certain Mobile Services may be prohibited or
                    restricted by your carrier, and not all Mobile Services may
                    work with all carriers or devices.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Third-Party Distribution Channels</u>. Kaiber offers
                    Mobile Apps that may be made available through the Apple App
                    Store, the Google Play Store, or other distribution channels
                    (“<b>Distribution Channels</b>”). If you obtain such Mobile
                    Apps through a Distribution Channel, you may be subject to
                    additional terms of the Distribution Channel. This Agreement
                    is between you and us only, and not with the Distribution
                    Channel. To the extent that you utilize any other
                    third-party products and services in connection with your
                    use of the Platform, you agree to comply with all applicable
                    terms of any agreement for such third-party products and
                    services.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Apple-Enabled Software</u>. With respect to Mobile Apps
                    that are made available for your use in connection with an
                    Apple-branded product (the “<b>Apple-Enabled Software</b>”),
                    in addition to the other terms and conditions set forth in
                    this Agreement, the following terms and conditions apply:
                  </li>
                  <ul className='list-disc ml-5'>
                    <li className='text-tertiary/60 mb-[18px]'>
                      Kaiber and you acknowledge that this Agreement is
                      concluded between Kaiber and you only, and not with Apple
                      Inc. (“
                      <b>Apple</b>”), and that as between Kaiber and Apple,
                      Kaiber, not Apple, is solely responsible for the
                      Apple-Enabled Software and the content thereof.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      You may not use the Apple-Enabled Software in any manner
                      that is in violation of or inconsistent with the Usage
                      Rules set forth for Apple-Enabled Software in, or
                      otherwise be in conflict with, the Apple Media Services
                      Terms and Conditions.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      Your license to use the Apple-Enabled Software is limited
                      to a non-transferable license to use the Apple-Enabled
                      Software on an iOS product that you own or control, as
                      permitted by the “Usage Rules” set forth in the Apple
                      Media Services Terms and Conditions, except that such
                      Apple-Enabled Software may be accessed and used by other
                      accounts associated with the purchaser via Apple’s Family
                      Sharing or volume purchasing programs.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      Apple has no obligation whatsoever to provide any
                      maintenance or support services with respect to the
                      Apple-Enabled Software.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      Apple is not responsible for any product warranties,
                      whether express or implied by law. In the event of any
                      failure of the Apple-Enabled Software to conform to any
                      applicable warranty, you may notify Apple, and Apple will
                      refund the purchase price for the Apple-Enabled Software,
                      if any, to you and, to the maximum extent permitted by
                      applicable law, Apple will have no other warranty
                      obligation whatsoever with respect to the Apple-Enabled
                      Software, or any other claims, losses, liabilities,
                      damages, costs, or expenses attributable to any failure to
                      conform to any warranty, which will be Kaiber’s sole
                      responsibility, to the extent it cannot be disclaimed
                      under applicable law.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      Kaiber and you acknowledge that Kaiber, not Apple, is
                      responsible for addressing any claims of you or any third
                      party relating to the Apple-Enabled Software or your
                      possession and/or use of that Apple-Enabled Software,
                      including: (a) product liability claims (b) any claim that
                      the Apple-Enabled Software fails to conform to any
                      applicable legal or regulatory requirement and (c) claims
                      arising under consumer protection, privacy, or similar
                      legislation
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      In the event of any third-party claim that the
                      Apple-Enabled Software or your possession and use of that
                      Apple-Enabled Software infringes that third party’s
                      intellectual property rights, as between Kaiber and Apple,
                      Kaiber, not Apple, will be solely responsible for the
                      investigation, defense, settlement, and discharge of any
                      such intellectual property infringement claim.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      You represent and warrant that (a) you are not located in
                      a country that is subject to a U.S. Government embargo, or
                      that has been designated by the U.S. Government as a
                      “terrorist supporting” country and (b) you are not listed
                      on any U.S. Government list of prohibited or restricted
                      parties.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      If you have any questions, complaints, or claims with
                      respect to the Apple-Enabled Software, they should be
                      directed to Kaiber at{' '}
                      <a
                        className='text-primary'
                        href='mailto:support@kaiber.ai'
                      >
                        support@kaiber.ai
                      </a>
                      .
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      You must comply with applicable third-party terms of
                      agreement when using the Apple-Enabled Software, e.g.,
                      your wireless data service agreement.
                    </li>
                    <li className='text-tertiary/60 mb-[18px]'>
                      Kaiber and you acknowledge and agree that Apple, and
                      Apple’s subsidiaries, are third-party beneficiaries of
                      this Agreement with respect to the Apple-Enabled Software,
                      and that, upon your acceptance of the terms and conditions
                      of this Agreement, Apple will have the right (and will be
                      deemed to have accepted the right) to enforce this
                      Agreement against you with respect to the Apple-Enabled
                      Software as a third-party beneficiary thereof.
                    </li>
                  </ul>

                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Google-Sourced Software</u>. The following applies to any
                    Mobile App you download from the Google Play Store (“
                    <b>Google- Sourced Software</b>”): (a) you acknowledge that
                    this Agreement is between you and Kaiber only, and not with
                    Google, Inc. (“<b>Google</b>”) (b) your use of
                    Google-Sourced Software must comply with Google’s
                    then-current Google Play Terms of Service (c) Google is only
                    a provider of Google Play where you obtained the
                    Google-Sourced Software (d) Kaiber, and not Google, is
                    solely responsible for Kaiber’s Google-Sourced Software (e)
                    Google has no obligation or liability to you with respect to
                    Google-Sourced Software or this Agreement and (f) you
                    acknowledge and agree that Google is a third-party
                    beneficiary to this Agreement as it relates to Kaiber’s
                    Google-Sourced Software.
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Proprietary Rights
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Platform Content</u>. You acknowledge and agree that the
                    Platform may contain content, assets, or features made
                    available by Kaiber or other Kaiber users (“
                    <b>Platform Content</b>”) that are protected by copyright,
                    patent, trademark, trade secret, or other proprietary rights
                    and laws. Except as expressly set forth herein, we reserve
                    all right, title and interest to the Platform Content.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Trademarks</u>. The Kaiber name and logos are trademarks
                    and service marks of Kaiber (collectively the “
                    <b>Kaiber Trademarks</b>”). Other company, product, and
                    service names and logos used and displayed via the Platform
                    may be trademarks or service marks of their respective
                    owners who may not endorse or be affiliated with or
                    connected to Kaiber. This Agreement and the Platform do not
                    grant you any license or right to use any of Kaiber
                    Trademarks, without our prior written permission.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Copyright Complaints</u>. Kaiber respects the
                    intellectual property of others, and we ask our users to do
                    the same. If you believe that your work has been copied in a
                    way that constitutes copyright infringement, or that your
                    intellectual property rights have been otherwise violated,
                    you should notify Kaiber of your infringement claim in
                    accordance with the procedure set forth below.
                    <p>
                      <span className='inline-block mb-[18px] mt-[18px]'>
                        Kaiber will process and investigate notices of alleged
                        infringement and will take appropriate actions under the
                        Digital Millennium Copyright Act (“DMCA”) and other
                        applicable intellectual property laws with respect to
                        any alleged or actual infringement. A notification of
                        claimed copyright infringement should be emailed to
                        Kaiber’s Copyright Agent at{' '}
                        <a className='text-primary' href='support@kaiber.ai'>
                          support@kaiber.ai
                        </a>
                        . (Subject line: “DMCA Takedown Request”).
                      </span>
                      <span className='inline-block mb-[18px]'>
                        To be effective, the notification must be in writing and
                        contain the following information:
                      </span>
                      <ul className='list-disc ml-5'>
                        <li className='text-tertiary/60 mb-[18px]'>
                          a physical or electronic signature of a person
                          authorized to act on behalf of the owner of the
                          copyright or other intellectual property interest that
                          is allegedly infringed
                        </li>
                        <li className='text-tertiary/60 mb-[18px]'>
                          identification of the copyrighted work or other
                          intellectual property that you claim has been
                          infringed, or, if multiple copyrighted works or other
                          intellectual property are covered by a single
                          notification, a representative list of such works or
                          other intellectual property
                        </li>
                        <li className='text-tertiary/60 mb-[18px]'>
                          identification of the content that is claimed to be
                          infringing or to be the subject of infringing
                          activity, and where the content that you claim is
                          infringing is located within the Platform, with enough
                          detail that we may find it within the Platform
                        </li>
                        <li className='text-tertiary/60 mb-[18px]'>
                          your address, telephone number, and email address
                        </li>
                        <li className='text-tertiary/60 mb-[18px]'>
                          a statement by you that you have a good faith belief
                          that the disputed use is not authorized by the
                          copyright or intellectual property owner, its agent,
                          or the law and
                        </li>
                        <li className='text-tertiary/60 mb-[18px]'>
                          a statement by you that the information in your notice
                          is accurate and, under penalty of perjury, that you
                          are the copyright or intellectual property owner or
                          are authorized to act on the behalf of the owner of
                          the copyright or intellectual property that is
                          allegedly infringed.
                        </li>
                      </ul>
                    </p>
                  </li>

                  <li className='text-tertiary/60 mb-[18px]'>
                    <span>
                      <u>Counter-Notice</u>. If you believe that your User
                      Content that was removed (or to which access was disabled)
                      is not infringing, or that you have the authorization from
                      the copyright owner, the copyright owner’s agent, or
                      pursuant to the law, to upload and use the content in your
                      User Content, you may send a written counter-notice
                      containing the following information to the Copyright
                      Agent:
                    </span>
                    <ul className='list-disc ml-5 mt-[18px]'>
                      <li className='text-tertiary/60 mb-[18px]'>
                        your physical or electronic signature
                      </li>
                      <li className='text-tertiary/60 mb-[18px]'>
                        identification of the content that has been removed or
                        to which access has been disabled and the location at
                        which the content appeared before it was removed or
                        disabled
                      </li>
                      <li className='text-tertiary/60 mb-[18px]'>
                        a statement by you, made under penalty of perjury, that
                        you have a good faith belief that the content was
                        removed or disabled as a result of mistake or a
                        misidentification of the content to be removed or
                        disabled and
                      </li>
                      <li className='text-tertiary/60 mb-[18px]'>
                        your name, address, telephone number, and email address,
                        a statement that you consent to the jurisdiction of the
                        federal court located within the Northern District of
                        California and a statement that you will accept service
                        of process from the person who provided notification of
                        the alleged infringement.
                      </li>
                    </ul>
                    <span className='inline-block'>
                      If a counter-notice is received by the Copyright Agent,
                      Kaiber will send a copy of the counter-notice to the
                      original complaining party informing them that Kaiber may
                      replace the removed content or cease disabling it within
                      ten (10) business days. Unless the owner of the applicable
                      copyrighted work or other intellectual property files an
                      action seeking a court order against Kaiber or the user,
                      the removed content may be replaced, or access to it
                      restored, within ten (10) to fourteen (14) business days
                      or more after receipt of the counter-notice, at our sole
                      discretion.
                    </span>
                  </li>

                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Repeat Infringer Policy</u>. In accordance with the DMCA
                    and other applicable law, Kaiber has adopted a policy of
                    terminating, in appropriate circumstances and at Kaiber’s
                    sole discretion, the accounts of users who are deemed to be
                    repeat infringers. Kaiber may also at its sole discretion
                    limit access to the Platform and/or terminate the accounts
                    of any users who infringe any intellectual property rights
                    of others, whether or not there is any repeat infringement.
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Indemnification Disclaimer Limitations on Liability
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Indemnity</u>. You agree to hold harmless, release,
                    defend, and indemnify us and our officers, directors,
                    employees, contractors, agents, affiliates, and subsidiaries
                    from and against all claims, damages, obligations, losses,
                    liabilities, costs, and expenses arising from: (a) your
                    access and use of our Platform or (b) your violation of any
                    term or condition of this Agreement, the right of any third
                    party, or any other applicable law, rule, or regulation.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Disclaimer</u>. We plan to continue to develop and
                    improve Kaiber, but we make no guarantees or promises about
                    how it operates or that it will function as intended, and
                    your use is at your own risk. THE PLATFORM AND OUTPUT ARE
                    PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE
                    AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES
                    (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO
                    THE PLATFORM OR OUTPUT, AND DISCLAIM ALL WARRANTIES
                    INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY,
                    NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES
                    ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO
                    NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED,
                    ACCURATE OR ERROR FREE, OR THAT ANY USER CONTENT WILL BE
                    SECURE OR NOT LOST OR ALTERED.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <span>
                      <u>Limitations of Liability</u>. UNDER NO CIRCUMSTANCES
                      SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES,
                      CONTRACTORS, AGENTS, AFFILIATES, OR SUBSIDIARIES BE LIABLE
                      TO YOU FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                      CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT
                      LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
                      DATA, OR OTHER INTANGIBLE PROPERTY, ARISING OUT OF OR
                      RELATING TO ANY ACCESS OR USE OF OR INABILITY TO ACCESS OR
                      USE OF THE PLATFORM, NOR WILL WE BE RESPONSIBLE FOR ANY
                      DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING, TAMPERING,
                      OR OTHER UNAUTHORIZED ACCESS OR USE OF OUR PLATFORM OR THE
                      INFORMATION CONTAINED WITHIN IT, WHETHER SUCH DAMAGES ARE
                      BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR
                      OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED
                      OR UNAUTHORIZED USE OF OUR PLATFORM.
                    </span>

                    <span className='inline-block mb-[18px] mt-[18px]'>
                      SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
                      LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR
                      CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO
                      YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL
                      DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW
                      IN CASES INVOLVING PERSONAL INJURY) EXCEED IN THE
                      AGGREGATE (A) THE AMOUNTS YOU HAVE PAID US TO US IN THE
                      SIX (6) MONTHS PRECEDING THE DATE OF THE CLAIM OR (B) ONE
                      HUNDRED U.S. DOLLARS ($100.00 USD) OR ITS EQUIVALENT IN
                      THE LOCAL CURRENCY OF THE APPLICABLE JURISDICTION.
                    </span>
                    <span className='inline-block mb-[18px] mt-[18px]'>
                      THE FOREGOING LIMITATIONS WILL NOT APPLY TO THE EXTENT
                      PROHIBITED BY LAW.
                    </span>
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Dispute Resolution By Binding Arbitration
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Agreement to Arbitrate</u>. This Dispute Resolution by
                    Binding Arbitration section is referred to in this Agreement
                    as the “<b>Arbitration Agreement</b>.” You agree that any
                    and all disputes or claims that have arisen or may arise
                    between you and Kaiber, whether arising out of or relating
                    to this Agreement (including any alleged breach thereof),
                    the Platform, and any aspect of the relationship or
                    transactions between us, shall be resolved exclusively
                    through final and binding arbitration, rather than a court,
                    in accordance with the terms of this Arbitration Agreement,
                    except that you may assert individual claims in small claims
                    court, if your claims qualify. Further, this Arbitration
                    Agreement does not preclude you from bringing issues to the
                    attention of federal, state, or local agencies, and such
                    agencies can, if the law allows, seek relief against us on
                    your behalf. You agree that, by entering into this
                    Agreement, you and Kaiber are each waiving the right to a
                    trial by jury or to participate in a class action. Your
                    rights will be determined by a neutral arbitrator, not a
                    judge or jury. The Federal Arbitration Act governs the
                    interpretation and enforcement of this Arbitration
                    Agreement.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>
                      Prohibition of Class and Representative Actions and
                      Non-Individualized Relief
                    </u>
                    . YOU AND KAIBER AGREE THAT EACH OF US MAY BRING CLAIMS
                    AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
                    PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                    REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND
                    KAIBER AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
                    OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT
                    OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
                    REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR
                    MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
                    DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
                    SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
                    RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S),
                    EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR
                    MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO
                    THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS
                    PROVISION.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Pre-Arbitration Dispute Resolution</u>. Kaiber is always
                    interested in resolving disputes amicably and efficiently,
                    and most user concerns can be resolved quickly and to the
                    user’s satisfaction by emailing support at{' '}
                    <a className='text-primary' href='support@kaiber.ai'>
                      support@kaiber.ai
                    </a>{' '}
                    . If such efforts prove unsuccessful, a party who intends to
                    seek arbitration must first send to the other, by certified
                    mail, a written Notice of Dispute (“
                    <b>Notice</b>”). The Notice to Kaiber should be sent to 112
                    S. French Street, Suite 105 Wilmington, DE 19801 (“
                    <b>Notice Address</b>”). The Notice must (i) describe the
                    nature and basis of the claim or dispute and (ii) set forth
                    the specific relief sought. If Kaiber and you do not resolve
                    the claim within sixty (60) calendar days after the Notice
                    is received, you or Kaiber may commence an arbitration
                    proceeding. During the arbitration, the amount of any
                    settlement offer made by Kaiber or you shall not be
                    disclosed to the arbitrator until after the arbitrator
                    determines the amount, if any, to which you or Kaiber is
                    entitled.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <span>
                      <u>Arbitration Procedures</u>. Arbitration will be
                      conducted by a neutral arbitrator in accordance with the
                      American Arbitration Association’s (“
                      <b>AAA</b>”) rules and procedures, including the AAA’s
                      Consumer Arbitration Rules (collectively, the “
                      <b>AAA Rules</b>”), as modified by this Arbitration
                      Agreement. For information on the AAA, please visit its
                      website,{' '}
                      <a
                        className='text-primary'
                        href='https://www.adr.org/consumer'
                      >
                        http://www.adr.org
                      </a>{' '}
                      . Information about the AAA Rules and fees for consumer
                      disputes can be found at the AAA’s consumer arbitration
                      page,{' '}
                      <a
                        className='text-primary'
                        href='https://www.adr.org/consumer'
                      >
                        http://www.adr.org/consumer
                      </a>{' '}
                      , as may be updated from time to time. If there is any
                      inconsistency between any term of the AAA Rules and any
                      term of this Arbitration Agreement, the applicable terms
                      of this Arbitration Agreement will control unless the
                      arbitrator determines that the application of the
                      inconsistent Arbitration Agreement terms would not result
                      in a fundamentally fair arbitration. The arbitrator must
                      also follow the provisions of this Agreement as a court
                      would. All issues are for the arbitrator to decide,
                      including, but not limited to, issues relating to the
                      scope, enforceability, and arbitrability of this
                      Arbitration Agreement. Although arbitration proceedings
                      are usually simpler and more streamlined than trials and
                      other judicial proceedings, the arbitrator can award the
                      same damages and relief on an individual basis that a
                      court can award to an individual under this Agreement and
                      applicable law. Decisions by the arbitrator are
                      enforceable in court and may be overturned by a court only
                      for very limited reasons.
                    </span>
                    <span className='inline-block mb-[18px] mt-[18px]'>
                      Unless Kaiber and you agree otherwise, any arbitration
                      hearings will take place in a reasonably convenient
                      location for both parties with due consideration of their
                      ability to travel and other pertinent circumstances. If
                      the parties are unable to agree on a location, the
                      determination shall be made by AAA. If your claim is for
                      $10,000 or less, Kaiber agrees that you may choose whether
                      the arbitration will be conducted solely on the basis of
                      documents submitted to the arbitrator, through a
                      telephonic hearing, or by an in-person hearing as
                      established by the AAA Rules. If your claim exceeds
                      $10,000, the right to a hearing will be determined by the
                      AAA Rules. Regardless of the manner in which the
                      arbitration is conducted, the arbitrator shall issue a
                      reasoned written decision sufficient to explain the
                      essential findings and conclusions on which the award is
                      based.
                    </span>
                  </li>

                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Costs of Arbitration</u>. Payment of all filing,
                    administration, and arbitrator fees (collectively, the “
                    <b>Arbitration Fees</b>”) will be governed by the AAA Rules,
                    unless otherwise provided in this Arbitration Agreement. If
                    the value of the relief sought is $75,000 or less, at your
                    request, Kaiber will pay all Arbitration Fees. If the value
                    of relief sought is more than $75,000 and you are able to
                    demonstrate to the arbitrator that you are economically
                    unable to pay your portion of the Arbitration Fees or if the
                    arbitrator otherwise determines for any reason that you
                    should not be required to pay your portion of the
                    Arbitration Fees, Kaiber will pay your portion of such fees.
                    In addition, if you demonstrate to the arbitrator that the
                    costs of arbitration will be prohibitive as compared to the
                    costs of litigation, Kaiber will pay as much of the
                    Arbitration Fees as the arbitrator deems necessary to
                    prevent the arbitration from being cost-prohibitive. Any
                    payment of attorneys’ fees will be governed by the AAA
                    Rules.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Confidentiality</u>. All aspects of the arbitration
                    proceeding, and any ruling, decision, or award by the
                    arbitrator, will be strictly confidential for the benefit of
                    all parties.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Severability</u>. If a court or the arbitrator decides
                    that any term or provision of this Arbitration Agreement
                    (other than Section 6.2 above) is invalid or unenforceable,
                    the parties agree to replace such term or provision with a
                    term or provision that is valid and enforceable and that
                    comes closest to expressing the intention of the invalid or
                    unenforceable term or provision, and this Arbitration
                    Agreement shall be enforceable as so modified. If a court or
                    the arbitrator decides that any of the provisions of Section
                    6.2 are invalid or unenforceable, then the entirety of this
                    Arbitration Agreement shall be null and void, unless such
                    provisions are deemed to be invalid or unenforceable solely
                    with respect to claims for public injunctive relief. The
                    remainder of this Agreement will continue to apply.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Future Changes to Arbitration Agreement</u>.
                    Notwithstanding any provision in this Agreement to the
                    contrary, Kaiber agrees that if it makes any future change
                    to this Arbitration Agreement (other than a change to the
                    Notice Address) while you are a user of the Platform, you
                    may reject any such change by sending Kaiber written notice
                    within thirty (30) calendar days of the change to the Notice
                    Address provided above. By rejecting any future change, you
                    are agreeing that you will arbitrate any dispute between us
                    in accordance with the language of this Arbitration
                    Agreement as of the date you first accepted this Agreement
                    (or accepted any subsequent changes to this Agreement).
                  </li>
                </ol>
              </li>

              <li className='text-tertiary/60'>
                <span className='mb-[18px] inline-block font-bold'>
                  Miscellaneous
                </span>
                <ol>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Entire Agreement</u>. These terms constitute the entire
                    agreement between you and us with respect to the subject
                    matter hereof. This Agreement supersedes any and all prior
                    or contemporaneous written and oral agreements,
                    communications and other understandings (if any) relating to
                    the subject matter of the terms.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Assignment</u>. You may not assign or transfer this
                    Agreement, by operation of law or otherwise, without our
                    prior written consent. Any attempt by you to assign or
                    transfer this Agreement without our prior written consent
                    shall be null and void. We may freely assign or transfer
                    this Agreement. Subject to the foregoing, this Agreement
                    will bind and inure to the benefit of the parties, their
                    successors and permitted assigns.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Notice</u>. We may provide any notice to you under this
                    Agreement using commercially reasonable means, including
                    using public communication channels. Notices we provide by
                    using public communication channels will be effective upon
                    posting.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Modifications</u>. We may amend this Agreement from time
                    to time by posting a revised version on the website, or if
                    an update materially adversely affects your rights or
                    obligations under this Agreement we will provide notice to
                    you either by emailing the email associated with your
                    account or providing an in-product notification. Those
                    changes will become effective no sooner than 30 days after
                    we notify you. All other changes will be effective
                    immediately. Your continued use of the Platform after any
                    change means you agree to such change.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Equitable Remedies</u>. You acknowledge that if you
                    violate or breach this Agreement, it may cause irreparable
                    harm to Kaiber, and Kaiber shall have the right to seek
                    injunctive relief against you in addition to any other legal
                    remedies.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Severability</u>. If any provision of this Agreement
                    shall be determined to be invalid or unenforceable under any
                    rule, law, or regulation of any local, state, or federal
                    government agency, such provision will be changed and
                    interpreted to accomplish the objectives of the provision to
                    the greatest extent possible under any applicable law and
                    the validity or enforceability of any other provision of
                    this Agreement shall not be affected.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Special Notice for International Use Export Controls</u>.
                    Kaiber is headquartered in the United States. Whether inside
                    or outside of the United States, you are solely responsible
                    for ensuring compliance with the laws of your specific
                    jurisdiction. Portions of the Platform, the Mobile Apps and
                    the transmission of applicable data, if any, is subject to
                    United States export controls. No portion of the Platform
                    may be downloaded or otherwise exported or re- exported in
                    violation of U.S. export laws. Downloading, accessing or
                    using the Platform is at your sole risk.
                  </li>
                  <li className='text-tertiary/60 mb-[18px]'>
                    <u>Governing Law</u>. This Agreement will be governed by the
                    laws of the State of California without regard to its
                    conflict of law provisions. With respect to any disputes or
                    claims not subject to arbitration, as set forth below, you
                    and Kaiber agree to submit to the personal and exclusive
                    jurisdiction of the state and federal courts located within
                    San Francisco, CA. The failure of Kaiber to exercise or
                    enforce any right or provision of this Agreement will not
                    constitute a waiver of such right or provision. If any
                    provision of this Agreement is found by a court of competent
                    jurisdiction to be invalid, the parties nevertheless agree
                    that the court should endeavor to give effect to the
                    parties’ intentions as reflected in the provision, and the
                    other provisions of this Agreement remain in full force and
                    effect.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
