import { TrashOutlineIcon } from '../../images/icons/TrashOutlineIcon'

export const DeleteButton = ({ onDelete, canvas }) => (
  <button
    onClick={(e) => {
      if (onDelete) {
        onDelete(true, canvas._id, canvas.adminDeleted, canvas.user)
      }
    }}
    disabled={
      canvas.status === 'pending' || canvas.upscale_status === 'pending'
    }
    className={`${
      canvas.status === 'pending' || canvas.upscale_status === 'pending'
        ? 'pointer-events-none opacity-50'
        : ''
    } flex items-center justify-center p-0 rounded-full shadow-md border text-white hover:bg-alarm hover:bg-opacity-70 focus:outline-none transition ease-in-out duration-200 h-7 w-7`}
  >
    <TrashOutlineIcon size={16} alt='trash can icon' />
  </button>
)
