import { useState, useEffect, useMemo } from 'react'
import { BiSolidBrush } from 'react-icons/bi'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { RiArrowRightDownLine } from 'react-icons/ri'
import { TbCoins } from 'react-icons/tb'

import { ChangeVersionModal } from './ChangeVersionModal'
import DropdownMenu from './DropdownMenu'
import ImageMask from './ImageMask'
import { PromptDisplay } from './PromptDisplay'
import { SelectAudioReactivity } from './SelectAudioReactivity'
import { VideoLengthDisplay } from './VideoLengthDisplay'
import { AudioTimestampSlider } from '../components/AudioTimestampSlider'
import CreatorCheckboxSelect from '../components/CreatorCheckboxSelect'
import CreatorOptionsSelect from '../components/CreatorOptionsSelect'
import InitialFilesUploadComponent from '../components/InitialFilesUploadComponent'
import LengthSlider from '../components/LengthSlider'
import { Modal } from '../components/Modal'
import RadioGroup from '../components/RadioGroup'
import SelectAspectRatio from '../components/SelectAspectRatio'
import Slider from '../components/Slider'
import { useUserAccountContext } from '../context/userContext'
import {
  ASPECT_RATIO_OPTIONS,
  CAMERA_MOVEMENT_MUTUALLY_EXCLUSIVE_OPTIONS,
  CAMERA_MOVEMENT_OPTIONS,
  MODEL_VERSIONS,
  FLIPBOOK,
  MOTION,
  ANIMATION,
  TRANSFORM,
  VIDEO_TYPES_AND_VERSIONS,
  BETA_MOTION_V3,
  MOTION_V3_CAMERA_MOVEMENTS,
  MOTION_V3_BRUSH,
  STABLE_DIFFUSION_CHECKPOINTS,
  STABLE_DIFFUSION_CHECKPOINT_DROPDOWN,
} from '../utils/constants'

const { MOTION_V2, MOTION_V3, FLIPBOOK_V1, TRANSFORM_V2 } =
  VIDEO_TYPES_AND_VERSIONS

export default function VideoSettings({
  handleAudioFileChange,
  handleFileInputChange,
  handleNextStep,
  handleGoBackToPrompt,
  setAlertMessage,
  audioFileName,
  setAudioFileName,
  setShowAlert,
  handleAspectRatioSelect,
  userProvidedStartingFrame,
  handleLengthSlider,
  handleStrengthSlider,
  audio,
  audioUrl,
  isAudioProcessing,
  setIsAudioProcessing,
  hasAudio,
  hasVideo,
  image,
  handleCameraMovementSelect,
  handleSelectMakeBoomerangVideo,
  length,
  strength,
  aspectRatio,
  isMobile,
  selectedFrame,
  cameraMovementNames,
  creditsCost,
  makeBoomerangVideo,
  artStyle,
  subject,
  longVideoMaintenanceMode,
  showUserProvidedStartingFrameInFirstFrameOfVideo,
  handleSelectShowUserProvidedStartingFrameInFirstFrameOfVideo,
  handleAudioReactivityChange,
  hasNextStep,
  activeScene,
  scenes,
  userProvidedMediaLength,
  handleAudioTimestampChange,
  handleTimestampChanges,
  sceneStartTimestamp,
  minSceneStartTimestamp,
  maxSceneStartTimestamp,
  numberOfScenes,
  audioReactivity,
  showOnlyTimeLine,
  lengthMaxDuration,
  lengthMinDuration,
  lengthStep,
  videoType,
  handleVersionChange,
  version,
  typeAndVersion,
  handleMotionMagnitudeSlider,
  motionMagnitude,
  isImage,
  handleRetainInitialImage,
  retainInitialImage,
  didReuseSettings,
  reuseHasAudio,
  maxAudioUploadLength,
  stableDiffusionCheckpoint,
  handleStableDiffusionCheckpointChange,
  onFinalMask,
  mask,
  imageMaskOverlay,
}) {
  const { currentUser, featureToggles } = useUserAccountContext()
  const [showVersionModal, setShowVersionModal] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [showImageMaskModal, setShowImageMaskModal] = useState(false)
  const [, setImageDimensions] = useState({
    width: 0,
    height: 0,
  })
  const isMotionV3Enabled = featureToggles[BETA_MOTION_V3.FLAG]
  const isMotionV3CameraMovementEnabled =
    featureToggles[MOTION_V3_CAMERA_MOVEMENTS.FLAG]
  const isMotionV3BrushEnabled = featureToggles[MOTION_V3_BRUSH.FLAG]
  const isStableDiffusionCheckpointsDropdownEnabled =
    featureToggles[STABLE_DIFFUSION_CHECKPOINT_DROPDOWN.FLAG]

  const isSubscribed = currentUser?.subscriptionType === 'Standard'

  const generateText = 'Generate Previews'

  // Some settings are only available for certain models
  const showCameraMovementSelect =
    videoType === FLIPBOOK ||
    videoType === ANIMATION ||
    (typeAndVersion === MOTION_V3 && isMotionV3CameraMovementEnabled)
  const showBoomerangSelect = videoType === FLIPBOOK || videoType === ANIMATION
  const showInitImageInFirstFrameOption =
    videoType === FLIPBOOK || videoType === ANIMATION
  const allowVideoLengthSelection =
    videoType === MOTION || videoType === FLIPBOOK || videoType === ANIMATION
  const showImageCanvas =
    typeAndVersion === MOTION_V3 && isMotionV3BrushEnabled && image
  const showCheckpointDropdown =
    isStableDiffusionCheckpointsDropdownEnabled &&
    (typeAndVersion === TRANSFORM_V2 || videoType === MOTION)

  const isFlipbookV1 = typeAndVersion === FLIPBOOK_V1
  const isTransform = videoType === TRANSFORM
  const isMotionV2 = typeAndVersion === MOTION_V2
  const isMotionV3 = typeAndVersion === MOTION_V3
  const isScene1 = activeScene === 1

  const showEvolveSlider =
    (((isMotionV2 && image) || isTransform || (isMotionV3 && image)) &&
      isScene1) ||
    isFlipbookV1

  let modelVersions = MODEL_VERSIONS[videoType]

  if (videoType === MOTION && !isMotionV3Enabled) {
    modelVersions = [2]
  }

  // Filter MODEL_VERSIONS based on audio support. If audio is present, only include versions that support it.
  const modelVersionOptions = modelVersions.filter(
    (version) =>
      (version === 3 && videoType === MOTION && hasAudio) || !hasAudio,
  )

  // Get a data URL for image
  const imageSrc = useMemo(
    () => (image ? URL.createObjectURL(image) : null),
    [image],
  )

  const handleOpenImageMaskModal = () => {
    setShowImageMaskModal(true)
  }

  const handleCloseImageMaskModal = () => {
    setShowImageMaskModal(false)
  }

  useEffect(() => {
    const img = new Image()
    img.src = imageSrc
    img.onload = () => {
      // Set the image dimensions, preserve aspect ratio such that no side exceeds 768 px
      const aspectRatio = img.width / img.height
      const maxSize = 768
      const width = img.width > img.height ? maxSize : maxSize * aspectRatio
      const height = img.height > img.width ? maxSize : maxSize / aspectRatio
      setImageDimensions({ width, height })
    }
  }, [imageSrc]) // This effect depends on `imageSrc` and runs whenever `imageSrc` changes

  return (
    <div className='flex flex-col w-full grid-cols-7 gap-0 md:grid md:w-auto'>
      {/* COLUMN LEFT */}
      <div className='order-2 col-span-4 md:order-1'>
        <div className='flex flex-col pt-2 sm:px-6 sm:py-10 text-primary'>
          {isMobile && !showOnlyTimeLine && (
            <button
              onClick={handleGoBackToPrompt}
              className='mb-5 transition-all w-fit text-primary hover:bg-primary-light hover:text-dark'
            >
              <IoMdArrowRoundBack className='inline w-10 h-10 cursor-pointer' />
              <span className='text-sm font-bold sm:text-base'></span>
            </button>
          )}
          {!isMobile && <span className='mb-5'>SETTINGS</span>}
          {/* VIDEO DURATION SECTION */}
          {!hasAudio && !hasVideo && allowVideoLengthSelection && (
            <>
              <div className=''>
                <h1 className='mb-5 text-xl lg:text-2xl xl:text-3xl text-primary'>
                  Video Duration
                </h1>
                {isSubscribed && longVideoMaintenanceMode && (
                  <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
                    Long video generations are temporarily disabled as we scale
                    up our servers. Thank you for your patience.
                  </div>
                )}

                {typeAndVersion === MOTION_V2 ||
                typeAndVersion === MOTION_V3 ? (
                  <LengthSlider
                    handleChange={handleLengthSlider}
                    maxDuration={lengthMaxDuration}
                    minDuration={lengthMinDuration}
                    step={lengthStep}
                    showSubscriptionTextCTA={!isSubscribed}
                    subscriptionTextCTA={
                      'Upgrade to generate videos up to 2 minutes 40 seconds.'
                    }
                    defaultOption={length}
                  />
                ) : (
                  <LengthSlider
                    handleChange={handleLengthSlider}
                    maxDuration={lengthMaxDuration}
                    minDuration={lengthMinDuration}
                    step={lengthStep}
                    showSubscriptionTextCTA={!isSubscribed}
                    subscriptionTextCTA={
                      'Upgrade to generate videos up to 8 minutes.'
                    }
                    defaultOption={length}
                  />
                )}
              </div>
            </>
          )}
          {/* Video length */}
          {isScene1 &&
            (hasAudio || (hasVideo && typeAndVersion === TRANSFORM_V2)) && (
              <div className='mb-12'>
                <h2 className='mb-5 text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
                  {hasAudio ? 'Song' : 'Video'} upload = Video length
                </h2>
                <VideoLengthDisplay length={userProvidedMediaLength} />
              </div>
            )}
          {isScene1 && !hasVideo && !image && (
            // ASPECT RATIO SECTION
            <SelectAspectRatio
              handleSelect={handleAspectRatioSelect}
              disabled={!!userProvidedStartingFrame}
              options={ASPECT_RATIO_OPTIONS}
              title={'Aspect Ratio'}
              defaultOption={aspectRatio}
              showSubscriptionTextCTA={false}
            />
          )}
          {/* CAMERA MOVEMENT */}
          {!hasVideo && showCameraMovementSelect && (
            <div className='rounded-2xl max-w-md self-start mt-[20px] pb-0 mb-[30px]'>
              <CreatorOptionsSelect
                handleSelect={handleCameraMovementSelect}
                className='mb-0'
                options={CAMERA_MOVEMENT_OPTIONS}
                title={'Camera Movement'}
                defaultOption={cameraMovementNames}
                showSubscriptionTextCTA={!isSubscribed}
                subscriptionTextCTA={
                  'Upgrade to select different camera movements'
                }
                mutuallyExclusiveOptions={
                  CAMERA_MOVEMENT_MUTUALLY_EXCLUSIVE_OPTIONS
                }
              />
            </div>
          )}
          {isScene1 &&
            userProvidedStartingFrame &&
            showInitImageInFirstFrameOption && (
              <CreatorCheckboxSelect
                title={'Show uploaded image in the first frame'}
                handleSelect={
                  handleSelectShowUserProvidedStartingFrameInFirstFrameOfVideo
                }
                checked={showUserProvidedStartingFrameInFirstFrameOfVideo}
              />
            )}
          {showEvolveSlider && (
            <div className='mb-10'>
              <Slider
                handleChange={handleStrengthSlider}
                min={0}
                max={10}
                showSubscriptionTextCTA={false}
                subscriptionTextCTA={null}
                defaultOption={strength}
                title='Intensity'
                description='Lower = More stable, Higher = More wild'
              />
              {strength === 0 && videoType === MOTION && image !== null && (
                <p className='text-primary max-w-[310px]'>
                  Setting evolve to 0 animates your uploaded image as is. No
                  preview frames will be generated.
                </p>
              )}
            </div>
          )}

          {/* ... */}
          {showCheckpointDropdown && (
            <DropdownMenu
              title='Model'
              description='Select the Stable Diffusion checkpoint for generating the video.'
              handleChange={handleStableDiffusionCheckpointChange}
              options={Object.entries(STABLE_DIFFUSION_CHECKPOINTS).map(
                ([key, value]) => ({
                  name: key,
                  value,
                }),
              )}
              selectedOption={stableDiffusionCheckpoint}
            />
          )}

          {(typeAndVersion === MOTION_V2 || typeAndVersion === MOTION_V3) && (
            <Slider
              handleChange={handleMotionMagnitudeSlider}
              min={0}
              max={10}
              showSubscriptionTextCTA={false}
              subscriptionTextCTA={null}
              defaultOption={motionMagnitude}
              title='Motion '
              description='Lower = More stable movements, Higher = More wild movements'
            />
          )}
          {/* Version Selector */}
          {modelVersions.length > 1 && (
            <RadioGroup
              title='Version'
              description={
                typeAndVersion === MOTION_V2 || typeAndVersion === MOTION_V3
                  ? 'Motion 3.0 has higher quality, supports audio upload and audio-reactivity, and costs more.'
                  : 'Version 3.0: 6 credits/sec, Version 1.0: 5 credits/sec'
              }
              handleChange={(event) => {
                if (
                  typeAndVersion === MOTION_V2 &&
                  event === 3 &&
                  Object.keys(scenes).length > 1
                ) {
                  setShowVersionModal(true)
                  setSelectedVersion(event)
                  return
                }

                handleVersionChange(event)
              }}
              options={modelVersionOptions.map((i) => ({
                name: videoType === TRANSFORM && i === 2 ? '3.0' : `${i}.0`, // a hack to display V3 instead of V2 for transform
                value: i,
                numberActiveScenes: numberOfScenes,
              }))}
              selectedOption={version}
            />
          )}
          {/* Audio Timestamp Slider */}
          {activeScene > 1 && (hasAudio || hasVideo) && (
            <div className='mt-12 mb-20'>
              <AudioTimestampSlider
                onSlide={handleAudioTimestampChange}
                onValuesChange={handleTimestampChanges}
                max={maxSceneStartTimestamp}
                min={minSceneStartTimestamp || 0}
                timestamp={sceneStartTimestamp}
                activeScene={activeScene}
                numberOfScenes={numberOfScenes}
                scenes={scenes}
                length={userProvidedMediaLength}
              />
            </div>
          )}
          {hasAudio && (
            <SelectAudioReactivity
              handleSelect={handleAudioReactivityChange}
              audioReactivity={audioReactivity}
            />
          )}
          {isScene1 && typeAndVersion === MOTION_V3 && hasAudio && (
            <CreatorCheckboxSelect
              title={'Retain initial frame composition'}
              handleSelect={handleRetainInitialImage}
              checked={retainInitialImage}
              description={
                'Selecting this will always show the initial frame when the music is silent.'
              }
              disabled={mask !== null}
              disabledDescription={
                'This option must be selected when using Motion Brush.'
              }
            />
          )}
          {isScene1 && !hasAudio && !hasVideo && showBoomerangSelect && (
            <div className='self-start max-w-md pb-5 mb-10 rounded-2xl'>
              <CreatorCheckboxSelect
                title={
                  'Boomerang. \n Video plays forward for half duration, then in reverse.'
                }
                upgradeTitle={'Boomerang'}
                handleSelect={handleSelectMakeBoomerangVideo}
                checked={makeBoomerangVideo}
                requiresSubscription={true}
                showSubscriptionTextCTA={!isSubscribed}
                subscriptionTextCTA={'Upgrade to toggle boomerang settings'}
              />
            </div>
          )}
          {/* NEXT STEP - PREVIEW - MOBILE BUTTON */}
          {isMobile && (
            <div className='flex items-center gap-2 mb-4'>
              <div className='flex items-center justify-center w-8 h-8 rounded-full bg-secondary/10'>
                <TbCoins className='w-4 text-[#CAD7CA]' />
              </div>
              <p className='text-gray-400'>
                {' '}
                Credits Cost: {creditsCost} / {currentUser?.credits}
              </p>
            </div>
          )}
          {videoType === MOTION && strength === 0 && isMobile ? (
            <button
              className='inline-flex items-center gap-2 mt-2 text-sm font-medium transition-all hover:scale-105 active:scale-95 sm:hidden w-fit text-primary sm:mt-24 sm:text-base'
              onClick={handleNextStep}
            >
              <div className='flex items-center justify-center w-full px-4 rounded-full bg-primary'>
                <img
                  className='w-25'
                  src='/button_create_mobile.png'
                  alt='Kaiber Create'
                />
                <span className='py-1 text-xl text-dark'>Create Video</span>
              </div>
            </button>
          ) : (
            <button
              className='inline-flex items-center gap-2 py-1 pl-3 pr-5 text-base font-semibold text-gray-900 duration-100 rounded-full sm:text-xl sm:hidden w-fit bg-primary hover:opacity-80 active:opacity-50'
              onClick={handleNextStep}
            >
              <RiArrowRightDownLine className='w-6 h-6 font-black' />
              {generateText}
            </button>
          )}
        </div>
      </div>

      {/* COLUMN RIGHT */}
      <div className='order-1 col-span-3 sm:px-6 md:order-2 mt-[20px]'>
        <div className='flex flex-col pt-2 pb-10 sm:py-10 text-quaternary'>
          {!isMobile && <h2 className='mb-5'>PROMPT</h2>}
          <PromptDisplay
            subject={subject}
            artStyle={artStyle}
            onBackClick={handleGoBackToPrompt}
          />
        </div>

        {showImageCanvas && (
          <div className='flex'>
            <button
              className='mb-5 mr-2 text-2xl underline text-kaiberGreen text-primary'
              onClick={handleOpenImageMaskModal}
            >
              Open in Motion Brush
            </button>
            <span className='text-sm text-primary'>(optional)</span>
          </div>
        )}

        <Modal
          open={showImageMaskModal}
          setOpen={setShowImageMaskModal}
          handleClose={handleCloseImageMaskModal}
          containerClassName='p-8 flex justify-center items-center bg-k2-gray-600 sm:max-w-xl'
          title={
            <div className='flex items-center'>
              <BiSolidBrush className='w-6 h-6 mr-2' />
              <span>Motion Brush</span>
            </div>
          }
        >
          <div className='modal-content'>
            <ImageMask
              imageUrl={imageSrc}
              image={image}
              onFinalMask={onFinalMask}
              defaultMask={mask}
              handleCloseImageMaskModal={handleCloseImageMaskModal}
              containerClassName='p-12'
            />
          </div>
        </Modal>

        {isScene1 && !hasVideo && !isMobile && (
          <div className='flex flex-1'>
            <InitialFilesUploadComponent
              handleAudioFileChange={handleAudioFileChange}
              handleFileInputChange={handleFileInputChange}
              setAlertMessage={setAlertMessage}
              setShowAlert={setShowAlert}
              audioFileName={audioFileName}
              setAudioFileName={setAudioFileName}
              audio={audio}
              isAudioProcessing={isAudioProcessing}
              setIsAudioProcessing={setIsAudioProcessing}
              audioUrl={audioUrl}
              image={image}
              isImage={isImage}
              longVideoMaintenanceMode={longVideoMaintenanceMode}
              allowImageUpload={true}
              allowAudioUpload={
                videoType === FLIPBOOK || typeAndVersion === MOTION_V3
              }
              maxAudioUploadLength={maxAudioUploadLength}
              didReuseSettings={didReuseSettings}
              reuseHasAudio={reuseHasAudio}
              imageMaskOverlay={imageMaskOverlay}
            />
          </div>
        )}
        {!isMobile && (
          <div className='flex items-center gap-2 mt-2'>
            <div className='flex items-center justify-center w-8 h-8 rounded-full bg-secondary/10'>
              <TbCoins className='w-4 text-[#CAD7CA]' />
            </div>

            <p className='text-gray-400'>
              {' '}
              Credits Cost: {creditsCost} / {currentUser?.credits}
            </p>
          </div>
        )}

        {/* NEXT STEP - PREVIEW */}
        {/* {strength !== 0 && hasNextStep && videoType !== MOTION && !image? ( */}
        {strength === 0 && videoType === MOTION && image ? (
          <button
            className='flex sm:flex-row flex-col hover:scale-105 active:scale-95 transition-all sm:inline-flex hidden items-center gap-2 text-primary font-medium sm:mt-24 mt-20 text-sm sm:text-base w-full sm:max-w-[230px]'
            onClick={handleNextStep}
          >
            <img
              className='items-center w-24 sm:w-64'
              src='/button_create.png'
              alt=''
            />
            <span className='text-lg'>Create Video</span>
          </button>
        ) : hasNextStep ? (
          <button
            className='items-center hidden gap-2 py-1 pl-3 pr-5 text-base font-semibold text-gray-900 duration-100 rounded-full sm:text-xl sm:inline-flex w-fit mt-14 bg-primary hover:opacity-80 active:opacity-50'
            onClick={handleNextStep}
          >
            <RiArrowRightDownLine className='w-6 h-6 font-black sm:w-8 sm:h-8' />
            {generateText}
          </button>
        ) : (
          <button
            className={`${
              !selectedFrame
                ? 'opacity-50 '
                : 'flex sm:flex-row flex-col hover:scale-105 active:scale-95 transition-all '
            }sm:inline-flex hidden items-center justify-center gap-2 text-primary font-medium sm:mt-24 mt-20 text-sm sm:text-base`}
            onClick={handleNextStep}
            disabled={!selectedFrame}
          >
            <img
              className='items-center w-24 sm:w-64'
              src='/button_create.png'
              alt=''
            />
            <span className='text-lg'>Create Video</span>
          </button>
        )}
      </div>
      <ChangeVersionModal
        isOpen={showVersionModal}
        onClose={() => setShowVersionModal(false)}
        version={`${selectedVersion}.0`}
        onContinue={() => {
          handleVersionChange(selectedVersion, true)
          setShowVersionModal(false)
        }}
      />
    </div>
  )
}
