import React from 'react'
import { SVGProps } from '@/types'

export const PlusCircleIcon: React.FC<SVGProps> = ({
  color = 'currentColor',
  size = 20,
  ...props
}) => (
  <svg
    stroke={color}
    fill='none'
    strokeWidth='2'
    viewBox='0 0 24 24'
    strokeLinecap='round'
    strokeLinejoin='round'
    width={size}
    height={size}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='12' cy='12' r='11'></circle>
    <line x1='12' y1='8' x2='12' y2='16'></line>
    <line x1='8' y1='12' x2='16' y2='12'></line>
  </svg>
)
