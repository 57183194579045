import { useConnection, Position, getBezierPath } from '@xyflow/react'

import { useThemeContext } from '../../../context'

export const CustomConnectionLine = ({
  fromX,
  fromY,
  toX,
  toY,
}: {
  fromX: number
  fromY: number
  toX: number
  toY: number
}) => {
  const { colors } = useThemeContext()
  const { fromHandle } = useConnection()

  const [edgePath] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromHandle?.position,
    targetX: toX,
    targetY: toY,
    targetPosition: Position.Left,
  })

  return (
    <g>
      <path
        d={edgePath}
        fill='none'
        strokeWidth={3}
        stroke={colors.hex.brand}
        strokeDasharray='5,5'
        className='animate-march'
      />
      <circle
        cx={toX}
        cy={toY}
        fill='#fff'
        r={3}
        stroke={colors.hex.brand}
        strokeWidth={1.5}
      />
    </g>
  )
}
