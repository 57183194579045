import { Spinner } from './Spinner'

interface LoadingProps {
  text: string
}

export const Loading = ({ text }: LoadingProps) => {
  return (
    <div className='w-full h-full absolute top-0 left-0 z-50 bg-darkGray bg-opacity-75 flex flex-col justify-center items-center'>
      <Spinner containerClassName='bg-transparent' iconClassName='w-16 h-16' />
      <span className='text-md font-semibold text-white'>{text}</span>
    </div>
  )
}
