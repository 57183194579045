import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Tab } from '@headlessui/react'
import AdminNavbar from '../../components/AdminNavbar'
import Footer from '../../components/Footer'
import http from '../../services/HttpService'
import UserRawJSONTab from '../../components/admin/UserRawJSONTab'
import UserHistoryTab from '../../components/admin/UserHistoryTab'
import UserDetailsTab from '../../components/admin/UserDetailsTab'
import UserMemoriesTab from '../../components/admin/UserMemoriesTab'
import UserCreditLogsTab from '../../components/admin/UserCreditLogsTab'
import BanAccountModal from '../../components/BanAccountModal'

export default function UserInfo() {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [memories, setMemories] = useState([])
  const [creditsSpent, setCreditsSpent] = useState(null)
  const [purchasedCredits, setPurchasedCredits] = useState(null)
  const [tabs] = useState([
    'Details',
    'History',
    'Raw JSON',
    'Memories',
    'Credit Logs',
  ])
  const [isBanAccountModalActive, setIsBanAccountModalActive] = useState(false)
  const [userBanningId, setUserBanningId] = useState(null)
  const [userBanningObjectId, setUserBanningObjectId] = useState(null)
  const [userBanningEmail, setUserBanningEmail] = useState(null)
  const [isBanned, setIsBanned] = useState(null)

  const { distinctId } = useParams()

  const fetchUser = useCallback(async () => {
    setIsLoading(true)

    const response = await http.get(`/api/admin/users/${distinctId}`)

    setUser(response.data.user)
    setMemories(response.data.memories.items)
    setCreditsSpent(response.data.memories.creditsSpent)
    setPurchasedCredits(response.data.purchasedCredits)
    setIsLoading(false)
  }, [distinctId])

  const toggleBanAccountModal = async (
    isOpen,
    userBanningId,
    userBanningObjectId,
    userBanningEmail,
    isBanned,
  ) => {
    setIsBanAccountModalActive(isOpen)
    setUserBanningId(userBanningId || null)
    setUserBanningObjectId(userBanningObjectId || null)
    setUserBanningEmail(userBanningEmail || null)
    setIsBanned(isBanned || null)
  }

  const toggleAccountBanned = (userBanningObjectId) => {
    setUser(
      (user) =>
        (user._id = userBanningObjectId
          ? { ...user, banned: !user.banned || false }
          : user),
    )
  }

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <div className='bg-dark'>
      <AdminNavbar />
      <div className='section py-10'>
        <div className='container mx-auto max-w-7xl space-y-8 px-5'>
          {isLoading ? (
            <div className='flex h-full'>
              <div className='m-auto bg-darkGray p-6 rounded-3xl'>
                <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-primary'></div>
              </div>
            </div>
          ) : (
            <>
              <Link
                to='/admin/users'
                className='inline-flex gap-2 items-center text-tertiary/80 hover:text-white'
              >
                <ArrowLeftIcon className='h-4 w-4' aria-hidden='true' />
                Back to Users
              </Link>
              <div className='flex items-center'>
                <img
                  src={
                    user.profilePicture || user.picture || '/favicon-32x32.png'
                  }
                  alt={`Profile icon`}
                  referrerPolicy='no-referrer'
                  className='rounded-full w-12 h-12'
                />
                <div className='ml-4'>
                  <h1 className='text-primary text-2xl sm:text-3xl md:text-5xl break-all'>
                    {user.name || user.email}
                  </h1>
                  <p className='text-gray-400 mt-1'>{user.email}</p>
                </div>
              </div>
              <Tab.Group>
                <Tab.List className='flex gap-4 border-b border-[#1E1F1E]'>
                  {tabs.map((tab) => (
                    <Tab
                      key={`tab-${tab}`}
                      className={({ selected }) =>
                        `pb-2 outline-none border-b-2 ${
                          selected
                            ? 'text-primary border-primary'
                            : 'text-tertiary/80 border-transparent'
                        }`
                      }
                    >
                      {tab}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels>
                  {tabs.map((tab) => (
                    <Tab.Panel key={`tabpanel-${tab}`}>
                      {tab === 'Details' && (
                        <UserDetailsTab
                          user={user}
                          setUser={setUser}
                          creditsSpent={creditsSpent}
                          purchasedCredits={purchasedCredits}
                          onAccountBan={toggleBanAccountModal}
                          refreshUser={fetchUser}
                        />
                      )}
                      {tab === 'History' && <UserHistoryTab user={user} />}
                      {tab === 'Raw JSON' && <UserRawJSONTab user={user} />}
                      {tab === 'Memories' && (
                        <UserMemoriesTab memories={memories} />
                      )}
                      {tab === 'Credit Logs' && (
                        <UserCreditLogsTab user={user} />
                      )}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </>
          )}
        </div>
      </div>
      <Footer />
      <BanAccountModal
        isOpen={isBanAccountModalActive}
        userBanningEmail={userBanningEmail}
        userBanningId={userBanningId}
        userBanningObjectId={userBanningObjectId}
        onClose={toggleBanAccountModal}
        onBanAcoount={toggleAccountBanned}
        isBanned={isBanned}
      />
    </div>
  )
}
