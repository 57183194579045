import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../images/welcome/logo.png'
import { Link } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminNavbar() {
  const navigation = [
    {
      name: 'Activity',
      href: '/admin/dashboard',
    },
    {
      name: 'Users',
      href: '/admin/users',
    },
    {
      name: 'Gallery',
      href: '/admin/gallery',
    },
    {
      name: 'Announcements',
      href: '/admin/announcements',
    },
  ]

  return (
    <Disclosure
      as='header'
      className='bg-dark shadow border-b border-[#1E1F1E]'
    >
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl lg:divide-y lg:divide-gray-200 py-4 sm:py-6 px-5'>
            <div className='relative flex justify-between gap-2'>
              <div className='relative z-10 flex px-2 lg:px-0'>
                <div className='flex flex-shrink-0 max-w-[180px] items-center'>
                  <Link to='/' className='relative'>
                    <img src={logo} alt='Kaiber Logo' />
                  </Link>
                </div>
              </div>
              <div className='relative z-0 flex flex-1 items-center justify-center px-2 lg:absolute lg:inset-0'>
                <div className='hidden md:block w-full sm:max-w-2xl	 text-center'>
                  {navigation.map((item) => {
                    const isActive = window.location.pathname === item.href
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          isActive
                            ? ' text-white'
                            : 'text-tertiary/60 hover:text-gray-50',
                          'rounded-2xl py-2 px-3 inline-flex items-center text-sm font-medium uppercase',
                        )}
                        aria-current={isActive ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    )
                  })}
                </div>
              </div>
              <div className='relative z-10 flex items-center md:hidden'>
                {/* Mobile menu button */}
                <Link to='/dashboard'>
                  <button className='inline-flex items-center justify-center rounded-full p-2 text-primary border border-primary/25 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </Link>
              </div>
              <div className='hidden lg:relative lg:z-10 lg:ml-4 md:flex lg:items-center gap-2'>
                <Link to='/dashboard'>
                  <button className='inline-flex items-center justify-center rounded-full p-2 text-primary border border-primary/25 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </Link>
              </div>
              <div className='relative z-10 flex items-center md:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-full p-2 text-primary border border-primary/25  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                  <span className='sr-only'>Open menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel as='nav' className='lg:hidden' aria-label='Global'>
            <div className='px-5'>
              {navigation.map((item) => {
                const isActive = window.location.pathname === item.href
                return (
                  <Disclosure.Button
                    key={item.name}
                    as={
                      item.href && !item.href.startsWith('https://')
                        ? Link
                        : 'a'
                    }
                    to={item.href || '#'}
                    href={item.href || '#'}
                    className={classNames(
                      isActive ? ' text-primary' : 'text-tertiary',
                      'block rounded-md py-2 px-3 text-base font-medium uppercase',
                    )}
                    aria-current={isActive ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                )
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
