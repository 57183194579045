import { useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'

import { useUserAccountContext } from '../context/userContext'
import http from '../services/HttpService'
import { Modal } from './Modal'

export default function SubscriptionTierUpdatedModalComponent({ children }) {
  const [open, setOpen] = useState(false)
  const { currentUser, setCurrentUser } = useUserAccountContext()
  const navigate = useNavigate()

  const markAsRead = async () => {
    try {
      await http.patch('/api/auth/myaccount/subscription-tier-updated-alert')
    } catch (err) {}
  }

  const handleModalClose = async () => {
    setOpen(false)

    await markAsRead()

    setCurrentUser((currentUser) => ({
      ...currentUser,
      showSubscriptionTierUpdatedAlert: false,
    }))

    setTimeout(() => {
      navigate('/create')
    }, 100)
  }

  useEffect(() => {
    if (currentUser?.showSubscriptionTierUpdatedAlert) {
      setOpen(true)
    }
  }, [currentUser])

  if (
    currentUser?.showFreeTrialStarted ||
    !currentUser?.showSubscriptionTierUpdatedAlert
  ) {
    return children
  }

  return (
    <>
      {children}

      <Modal open={open} setOpen={handleModalClose}>
        <div className='text-center px-6 py-6 sm:px-12 sm:py-10 w-full'>
          <CheckCircleIcon className='w-10 h-10 inline text-primary' />
          <p className='mt-4 text-4xl sm:text-5xl text-tertiary'>
            Thank you for using Kaiber!
          </p>
          <p className='mt-4 text-tertiary/80'>
            Your account has been updated to:{' '}
            {currentUser?.subscriptionTierDescription}
          </p>
          <div className='mt-8'>
            {/* [LUIS TODO] review modal closing after trial update*/}
            <button
              onClick={handleModalClose}
              className='text-2xl text-primary underline'
            >
              Start Creating
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
