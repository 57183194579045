import { Media } from '@/types'
import { getMedia } from '../api'

const isPresignedR2 = (url: string) =>
  url?.startsWith('https://secret-memories.')

const isProxiedR2 = (url: string) =>
  url?.startsWith('https://proxy-image.kyber-corp-ent.workers.dev/')

/**
 * Extracts the asset key from a media source url on extractable formats.
 *
 * Example:
 *   Provided a presigned r2 url: 'https://secret-memories.3a0e8493c00fa2487b71580016cad807.r2.cloudflarestorage.com/init-images/51ae0d2761edd2515b44de0528d84013a140b08a2f0174b09fbc794115fed6f9.webp?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=c7aa518caffdd78ea3544d5f64202a33%2F20240625%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240625T222307Z&X-Amz-Expires=86400&X-Amz-Signature=0ba9c7f5511cc9bc610eae81d5e0745d3d146b53a43fc36cb1c45c07d1dd06b3&X-Amz-SignedHeaders=host'
 *   or a proxied r2 url: 'https://proxy-image.kyber-corp-ent.workers.dev/init-images/51ae0d2761edd2515b44de0528d84013a140b08a2f0174b09fbc794115fed6f9.webp?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=c7aa518caffdd78ea3544d5f64202a33%2F20240625%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240625T222307Z&X-Amz-Expires=86400&X-Amz-Signature=0ba9c7f5511cc9bc610eae81d5e0745d3d146b53a43fc36cb1c45c07d1dd06b3&X-Amz-SignedHeaders=host'
 *   it returns the assetKey:
 *     'init-images/51ae0d2761edd2515b44de0528d84013a140b08a2f0174b09fbc794115fed6f9.webp'
 *
 *   If optional removePrefix true, for assetKey it returns:
 *     '51ae0d2761edd2515b44de0528d84013a140b08a2f0174b09fbc794115fed6f9.webp'
 */
export const extractAssetKey = (
  url: string,
  options: { removePrefix?: boolean } = {},
): string | undefined => {
  // return undefined if url is NOT in extractable format
  if (!isPresignedR2(url) && !isProxiedR2(url)) return undefined

  const source = new URL(url)
  let assetKey = source.pathname.substring(1)

  if (options?.removePrefix) {
    const parts = assetKey.split('/')
    if (parts.length > 1) {
      assetKey = parts[parts.length - 1]
    }
  }

  return assetKey
}

export const getAssetKeyFromMedia = async (
  media: Media,
  options: { removePrefix?: boolean } = {},
): Promise<string | undefined> => {
  try {
    let assetKey = extractAssetKey(media.source, options)

    if (!assetKey && media.mediaId) {
      const { mediaId } = media
      const updatedMedia = await getMedia({ mediaId })

      assetKey = updatedMedia?.assetKey
    }

    return assetKey
  } catch (error) {
    console.error('Error extracting assetKey from media', media, error)
    return undefined
  }
}

export const getAutoScaledDimensions = (
  width: number,
  height: number,
  maxWidth: number,
): { width: number; height: number } => {
  const aspectRatio = width / height
  if (width > maxWidth) {
    return {
      width: maxWidth,
      height: maxWidth / aspectRatio,
    }
  }
  return { width, height }
}

/**
 * Returns true iif the `url` is non-null AND valid (i.e. contains `X-Amz-Date` and `X-Amz-Expires`), AND has expired.
 * TODO(ENG-2459): don't be hacky. Let backend return an expiry time.
 * @param url Example: https://proxy-image.kyber-corp-ent.workers.dev/init-images/353c608d-ba54-4463-96fd-a56d1867bd36.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=c7aa518caffdd78ea3544d5f64202a33%2F20240807%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240807T222409Z&X-Amz-Expires=86400&X-Amz-Signature=74560b9c6d08b104d785ce5f20c9ff2038e1c069444c76db4033cc0ba8b2dc46&X-Amz-SignedHeaders=host&x-id=GetObject
 */
export const signedUrlExpired = (url: string) => {
  if (!url) return false
  const urlParams = new URL(url).searchParams
  const amzDate = urlParams.get('X-Amz-Date')
  const amzExpires = urlParams.get('X-Amz-Expires')
  if (!amzDate || !amzExpires) return false
  // Parse the amzDate
  const date = new Date(
    amzDate.substring(0, 4) +
      '-' +
      amzDate.substring(4, 6) +
      '-' +
      amzDate.substring(6, 8) +
      'T' +
      amzDate.substring(9, 11) +
      ':' +
      amzDate.substring(11, 13) +
      ':' +
      amzDate.substring(13, 15) +
      'Z',
  )

  // Convert amzExpires to milliseconds and add it to the date
  const expiresInMillis = parseInt(amzExpires, 10) * 1000
  const expiryDate = new Date(date.getTime() + expiresInMillis)
  // Compare with the current date and time
  return expiryDate < new Date()
}

/**
 * Returns true if the `url` seems to be from Sieve's output.
 * Example: https://sieve-prod-us-central1-persistent-bucket.storage.googleapis.com/...
 * TODO(ENG-2459): don't be hacky. Let backend return an expiry time.
 */
export const isSieveUrl = (url: string) => url && url.includes('sieve-prod')

/**
 * Generates a temporary media ID for MediaStore.
 * Use case: This is the ID for a media before backend returns the actual media ID. */
export const makeTempMediaId = (): string => `temp-${Math.random()}`

/** Returns true if the media ID is "temporary" (see @link{makeTempMediaId} for its meaning) */
export const isTempMediaId = (mediaId: string): boolean =>
  mediaId.startsWith('temp-')

/** Returns true if the given media is "temporary" (see @link{makeTempMediaId} for its meaning) */
export const isTempMedia = (media: Media): boolean =>
  isTempMediaId(media.mediaId)
