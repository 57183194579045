import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'

import { DEFAULT_BORDER_COLOR } from '../../../constants'
import { useNodeUtility } from '../../../hooks'

export interface MenuItem {
  label: string
  action: () => void
  className?: string
}

export interface ContextMenuProps {
  id: string | null
  top?: number
  left?: number
  right?: number
  bottom?: number
  isNavMenu?: boolean
  borderColor?: string
  onClick?: () => void
  additionalMenuItems?: MenuItem[]
}

export const ContextMenu: React.FC<ContextMenuProps> = ({
  id,
  top,
  left,
  right,
  bottom,
  isNavMenu = false,
  borderColor = DEFAULT_BORDER_COLOR,
  onClick,
  additionalMenuItems = [],
}) => {
  const {
    duplicateNodeById,
    deleteNodeById,
    recreateFlowById,
    duplicateSelectedNodes,
    deleteSelectedNodes,
    recreateSelectedFlows,
    isAllSelectedNodesMediaNodesWithFlow,
    isSingleNodeMediaNodeWithFlow,
    isSingleNodeSelected,
    anyNodesSelected,
  } = useNodeUtility()

  const duplicateNode = useCallback(() => {
    if (isSingleNodeSelected(id)) {
      duplicateNodeById(id)
    } else {
      duplicateSelectedNodes()
    }
  }, [id, isSingleNodeSelected, duplicateNodeById, duplicateSelectedNodes])

  const deleteNode = useCallback(() => {
    if (isSingleNodeSelected(id)) {
      deleteNodeById(id)
    } else {
      deleteSelectedNodes()
    }
  }, [id, isSingleNodeSelected, deleteNodeById, deleteSelectedNodes])

  const recreateFlow = useCallback(() => {
    if (isSingleNodeSelected(id)) {
      recreateFlowById(id)
    } else {
      recreateSelectedFlows()
    }
  }, [id, isSingleNodeSelected, recreateFlowById, recreateSelectedFlows])

  const allMenuItems = useMemo(() => {
    const defaultMenuItems: MenuItem[] = [
      { label: 'Duplicate', action: duplicateNode },
      { label: 'Delete', action: deleteNode, className: 'text-[#FF6363]' },
    ]

    if (
      isSingleNodeMediaNodeWithFlow(id) ||
      (anyNodesSelected() && isAllSelectedNodesMediaNodesWithFlow())
    ) {
      defaultMenuItems.push({
        label: 'Recreate Flow',
        action: recreateFlow,
      })
    }
    return [...defaultMenuItems, ...additionalMenuItems]
  }, [
    id,
    deleteNode,
    additionalMenuItems,
    duplicateNode,
    recreateFlow,
    isAllSelectedNodesMediaNodesWithFlow,
    isSingleNodeMediaNodeWithFlow,
    anyNodesSelected,
  ])

  const buttonClass =
    'block w-full focus:outline-none text-left p-2 border-none hover:bg-[#575757] text-white'

  const handleItemClick = (action: () => void) => {
    action()
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      style={{ top, left, right, bottom }}
      className={`absolute z-10 px-0 py-2 bg-[#313137] border solid shadow-lg rounded-lg bg-opacity-80 backdrop-blur-sm pointer-events-auto ${borderColor}`}
      onClick={onClick}
    >
      {allMenuItems.map((item, index) => (
        <button
          key={index}
          className={cn(buttonClass, item.className)}
          type='button'
          onClick={(e) => {
            e.stopPropagation()
            handleItemClick(item.action)
          }}
        >
          {item.label}
        </button>
      ))}

      {!isNavMenu && (
        <button
          className={buttonClass}
          type='button'
          onClick={(e) => {
            e.stopPropagation()
            onClick?.()
          }}
        >
          Close Menu
        </button>
      )}
    </div>
  )
}
