import { ThemeProvider as MaterialTailwindProvider } from '@material-tailwind/react'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { MixpanelProvider } from 'react-mixpanel-browser'

import { ThemeProvider as CustomThemeProvider } from './context'
import { analytics } from './utils/analytics'

interface ExternalProvidersProps {
  children: React.ReactNode
}

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '', {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  disable_session_recording: true,
})

analytics.load({ writeKey: process.env.SEGMENT_API_KEY })

export const ExternalProviders = ({ children }: ExternalProvidersProps) => {
  return (
    <MixpanelProvider token={MIXPANEL_TOKEN}>
      <PostHogProvider client={posthog}>
        <MaterialTailwindProvider>
          <CustomThemeProvider>{children}</CustomThemeProvider>
        </MaterialTailwindProvider>
      </PostHogProvider>
    </MixpanelProvider>
  )
}
