import UpScale from '../../images/Upscale2White.svg'
import classNames from 'classnames'

export const UpScaleButton = ({ canvas }) => {
  return (
    <button
      className={classNames(
        'p-0 rounded-full opacity-75 shadow-md hover:bg-gray-700 focus:outline-none h-7 w-7 transition duration-500 ease-in-out hover:opacity-100 hover:scale-105',
      )}
    >
      <img
        src={UpScale}
        alt={`Memory is upscaled`}
        title='Memory is upscaled'
      />
    </button>
  )
}
