import { useState } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'

export default function SelectAspectRatio({
  handleSelect,
  disabled,
  title,
  options,
  defaultOption,
  subscriptionTextCTA,
  showSubscriptionTextCTA,
  aspectRatio,
}) {
  const [selectedName, setSelectedName] = useState(defaultOption)

  const onSelect = (name, value, style) => {
    setSelectedName(name)
    handleSelect(name, value, style)
  }

  if (disabled) {
    return null
  }

  return (
    <div>
      <h1 className='mb-5 text-xl whitespace-pre-line lg:text-2xl xl:text-3xl'>
        {title}
      </h1>

      {!disabled && !showSubscriptionTextCTA && (
        <div className='flex flex-wrap items-center gap-3 mb-5 sm:gap-8'>
          {options.map((option) => (
            <button type='button' key={option.name}>
              <input
                id={option.name}
                type='radio'
                onChange={() => onSelect(option.name, option.value)}
                value={option.name}
                name='aspect-ratio'
                checked={selectedName === option.name}
                required
                className='hidden peer'
              />
              <label
                htmlFor={option.name}
                className={`flex input cursor-pointer relative items-center rounded-md justify-center peer-checked:bg-primary bg-[#1E201E] text-primary peer-checked:text-dark font-bold [&>div]:border-primary [&>div]:peer-checked:border-dark ${option.style}`}
              >
                <div
                  htmlFor={option.name}
                  className='absolute top-0 left-0 m-1 border-t border-l w-2 h-2 $'
                />
                <div
                  htmlFor={option.name}
                  className='absolute bottom-0 right-0 w-2 h-2 m-1 border-b border-r'
                />
                {option.name}
              </label>
            </button>
          ))}
        </div>
      )}

      {showSubscriptionTextCTA ? (
        <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
          <StarIcon className='inline w-6 h-6 mr-2' />
          {subscriptionTextCTA}
        </div>
      ) : null}
    </div>
  )
}
