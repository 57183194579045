import { BaseComponentProps } from '../../types'

interface Props extends BaseComponentProps {
  size?: string | number
}

export const QuestionMagnifyingGlassIcon = ({ size = '1em' }: Props) => (
  <svg
    stroke='currentColor'
    fill='currentColor'
    strokeWidth='0'
    viewBox='0 0 64 64'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='magnifying-glass'>
      <path
        id='Vector'
        d='M54 54L43.004 43.004M43.004 43.004C46.5027 39.5053 48.6667 34.672 48.6667 29.3333C48.6667 18.6558 40.0109 10 29.3333 10C18.6558 10 10 18.6558 10 29.3333C10 40.0109 18.6558 48.6667 29.3333 48.6667C34.672 48.6667 39.5053 46.5027 43.004 43.004Z'
        stroke='#DDEB26'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M25 24C25 22.3431 26.3432 21 28 21H30.4174C32.396 21 34 22.604 34 24.5826C34 25.7804 33.4014 26.8992 32.4046 27.5636L31.2812 28.3126C30.1684 29.0544 29.5 30.3034 29.5 31.6408V32M29.5 37.5V37.48M30 37.5C30 37.7762 29.7762 38 29.5 38C29.2238 38 29 37.7762 29 37.5C29 37.2238 29.2238 37 29.5 37C29.7762 37 30 37.2238 30 37.5Z'
        stroke='#DDEB26'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)
