import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { cn } from '../../utils'

const RETRY_TIMER = 1000 * 60 * 60 * 24 // one day

export const CanvasFailed = ({
  canvas,
  handleTryAgain,
  isRequeueing,
  onDelete,
  showFailedIcon,
}) => {
  let canRetry = false
  let hoursUntilRetry = 0

  if (!isRequeueing) {
    if (!canvas.requeuedAt) {
      canRetry = true
    } else {
      const timeSinceLastRequeue = new Date() - new Date(canvas.requeuedAt)
      if (timeSinceLastRequeue > RETRY_TIMER) {
        canRetry = true
      } else {
        hoursUntilRetry = Math.ceil(
          (RETRY_TIMER - timeSinceLastRequeue) / (1000 * 60 * 60),
        )
      }
    }
  }

  const onDeleteClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete(true, canvas._id)
  }

  return (
    <div className='flex flex-col items-center justify-center text-center gap-3'>
      {showFailedIcon && (
        <ExclamationTriangleIcon className={'h-16 w-16 text-white'} />
      )}
      <p className='text-white'>
        Lights, camera... Oops! 🎥 <br />
        {canRetry && 'Server hiccup, try again?'}
      </p>
      <div className='inline'>
        {canRetry || isRequeueing ? (
          <button
            onClick={handleTryAgain}
            type='button'
            disabled={isRequeueing}
            className={cn(
              'rounded-full border bg-transparent border-primary/25 text-primary px-4 py-2 mr-2 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2',
              isRequeueing && 'pointer-events-none opacity-50 border-none',
            )}
          >
            {isRequeueing ? (
              <div className='border-2 rounded-full animate-spin h-7 w-7 border-primary'></div>
            ) : (
              'Try again'
            )}
          </button>
        ) : (
          <p className='mb-4 text-white'>
            Try again in {hoursUntilRetry} hour{hoursUntilRetry > 1 && 's'}
          </p>
        )}
        <button
          onClick={onDeleteClicked}
          type='button'
          className='border border-black rounded-full px-4 py-2 bg-red-500 hover:bg-red-700'
        >
          Delete
        </button>
      </div>
    </div>
  )
}
