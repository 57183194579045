export interface BaseProviderProps {
  children: React.ReactNode
}

export interface BaseWrapperProps extends BaseProviderProps {}

export interface BaseComponentProps {
  className?: string
}

export interface BaseComponentPropsWithChildren extends BaseComponentProps {
  children?: React.ReactNode
}

export interface FieldOption {
  value: string
  label: string
}

// TODO: ENG-1907 make consistent and replace with shared types from server
export enum AspectRatio {
  R16By9 = '16:9',
  R9By16 = '9:16',
  R1By1 = '1:1',
  R3By4 = '3:4',
  R4By3 = '4:3',
  Custom = 'Custom',
}

export interface Announcement {
  headerText?: string
  headerImage?: string
  title?: string
  announcementHtml?: string | TrustedHTML
  footerText?: string
  announcementType?: string
}
