import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  DEFAULT_FORM_VALUES,
  MIN_DIMENSION,
  MAX_DIMENSION,
} from '../../../../../constants'
import {
  getAspectRatioValue,
  getDimensionsFromAspectRatio,
} from '../../../../../utils/imageUtils'
import { InputField } from '../../../Fields'
import { CheckboxField } from '../../../Fields/CheckboxField'
import { RatioSelect } from '../RatioSelect'
import { FieldType, AspectRatio } from '@/types'

interface ImageDimensionsInputProps {
  isImageUploaded: boolean
  multipleOf?: number
}

export const ImageDimensionsInput: React.FC<ImageDimensionsInputProps> = ({
  isImageUploaded,
  multipleOf = 1,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const width = watch(FieldType.Width)
  const height = watch(FieldType.Height)
  const aspectRatio = watch(FieldType.AspectRatio)
  const lockAspectRatio = watch(FieldType.LockAspectRatio)

  const updateAspectRatioFromDimensions = useCallback(
    (currentWidth: number, currentHeight: number) => {
      const currentRatio = currentWidth / currentHeight
      const matchingRatio = Object.entries(AspectRatio).find(([, value]) => {
        if (value === AspectRatio.Custom) return false
        const [w, h] = value.split(':').map(Number)
        return Math.abs(currentRatio - w / h) < 0.001
      })
      const newAspectRatio = matchingRatio
        ? matchingRatio[1]
        : AspectRatio.Custom
      setValue(FieldType.AspectRatio, newAspectRatio)
    },
    [setValue],
  )

  const updateDimensionsFromAspectRatio = useCallback(
    (newAspectRatio: AspectRatio) => {
      if (newAspectRatio !== AspectRatio.Custom) {
        try {
          const { width: newWidth, height: newHeight } =
            getDimensionsFromAspectRatio(newAspectRatio)
          setValue(FieldType.Width, newWidth, { shouldValidate: true })
          setValue(FieldType.Height, newHeight, { shouldValidate: true })
        } catch (error) {
          console.error('Error updating dimensions from aspect ratio:', error)
        }
      }
    },
    [setValue],
  )

  const handleInputChange = useCallback(
    (fieldName: FieldType, value: string) => {
      if (fieldName === FieldType.Width || fieldName === FieldType.Height) {
        if (value === '') {
          setValue(fieldName, undefined, { shouldValidate: true })
        } else {
          const numValue = parseInt(value, 10)
          if (!isNaN(numValue)) {
            setValue(fieldName, numValue, { shouldValidate: true })
            if (lockAspectRatio) {
              const otherDimensionField =
                fieldName === FieldType.Width
                  ? FieldType.Height
                  : FieldType.Width
              const aspectRatioValue = getAspectRatioValue(aspectRatio, {
                width,
                height,
              })
              const newOtherDimensionValue =
                fieldName === FieldType.Width
                  ? Math.round(numValue / aspectRatioValue)
                  : Math.round(numValue * aspectRatioValue)

              setValue(otherDimensionField, newOtherDimensionValue, {
                shouldValidate: true,
              })
            } else {
              updateAspectRatioFromDimensions(
                fieldName === FieldType.Width ? numValue : (width as number),
                fieldName === FieldType.Height ? numValue : (height as number),
              )
            }
          }
        }
      } else if (fieldName === FieldType.AspectRatio) {
        setValue(fieldName, value as AspectRatio)
        updateDimensionsFromAspectRatio(value as AspectRatio)
      }
    },
    [
      setValue,
      updateAspectRatioFromDimensions,
      updateDimensionsFromAspectRatio,
      width,
      height,
      lockAspectRatio,
    ],
  )

  const validateDimension = (value: number | undefined) => {
    if (value === undefined) return 'This field is required'
    if (value < MIN_DIMENSION) return `Minimum value is ${MIN_DIMENSION}`
    if (value > MAX_DIMENSION) return `Maximum value is ${MAX_DIMENSION}`
    return true
  }

  return (
    <>
      <div className='flex flex-row justify-between items-center gap-2'>
        <RatioSelect
          aspectRatio={aspectRatio}
          onRatioChange={(ratio) =>
            handleInputChange(FieldType.AspectRatio, ratio)
          }
          disabled={isImageUploaded}
        />
      </div>

      <CheckboxField
        label={FieldType.LockAspectRatio}
        registration={register(FieldType.LockAspectRatio)}
        defaultChecked={DEFAULT_FORM_VALUES[FieldType.LockAspectRatio]}
        disabled={isImageUploaded}
      />

      <InputField
        label={FieldType.Width}
        type='number'
        {...register(FieldType.Width, {
          required: 'Width is required',
          validate: validateDimension,
          onChange: (e) => handleInputChange(FieldType.Width, e.target.value),
        })}
        defaultValue={DEFAULT_FORM_VALUES[FieldType.Width]}
        className={errors[FieldType.Width] ? 'border border-red-500' : ''}
        disabled={isImageUploaded}
      />

      <InputField
        label={FieldType.Height}
        type='number'
        {...register(FieldType.Height, {
          required: 'Height is required',
          validate: validateDimension,
          onChange: (e) => handleInputChange(FieldType.Height, e.target.value),
        })}
        defaultValue={DEFAULT_FORM_VALUES[FieldType.Height]}
        className={errors[FieldType.Height] ? 'border border-red-500' : ''}
        disabled={isImageUploaded}
      />

      {multipleOf > 1 && (
        <p className='text-gray-600 text-sm mt-2'>
          {`* Values are automatically adjusted to multiples of ${multipleOf}`}
        </p>
      )}
    </>
  )
}
