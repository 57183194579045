import { HorizontalGradientBar } from '../images/icons/HorizontalGradientBar'

export const DrawerHandle = ({
  onClick,
}: React.HTMLProps<HTMLButtonElement>) => (
  <button
    type='button'
    title='Toggle advanced fields'
    onClick={onClick}
    className='group/drawer-handle mt-5 -mb-4 p-1.5 opacity-85 hover:opacity-100 transition ease-in-out'
  >
    <HorizontalGradientBar className='group-hover/drawer-handle:shadow-glow-gold duration-300 rounded-full' />
  </button>
)
