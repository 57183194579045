import { useRef, useEffect } from 'react'
import { XYPosition } from '@xyflow/react'

export const useMousePosition = (isActive: boolean = true) => {
  const positionRef = useRef<XYPosition>({ x: 0, y: 0 })

  useEffect(() => {
    const updateMousePosition = (event: MouseEvent) => {
      positionRef.current = { x: event.clientX, y: event.clientY }
    }

    if (isActive) {
      window.addEventListener('mousemove', updateMousePosition)

      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
      }
    }
  }, [isActive])

  const isMouseOverHTMLElement = (element: HTMLElement): boolean => {
    if (element) {
      const { x, y } = positionRef.current
      const rect = element.getBoundingClientRect()
      return (
        x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
      )
    }
    return false
  }

  return { positionRef, isMouseOverHTMLElement }
}
