import { useState } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'
import * as Slider from '@radix-ui/react-slider'
import { formatDuration } from '../utils'

const LengthSlider = ({
  handleChange,
  subscriptionTextCTA,
  showSubscriptionTextCTA,
  maxDuration = 10,
  minDuration = 3,
  step = 1,
  defaultOption = 8,
}) => {
  const [sliderValue, setSliderValue] = useState(defaultOption)

  const handleSlider = (valueArray) => {
    const value = valueArray[0]
    handleChange(value)
    setSliderValue(value)
  }

  const handleDecrease = () => {
    if (sliderValue > 3) {
      const newValue = sliderValue - 1
      handleChange(newValue)
      setSliderValue(newValue)
    }
  }

  const handleIncrease = () => {
    if (sliderValue < maxDuration) {
      const newValue = sliderValue + 1
      handleChange(newValue)
      setSliderValue(newValue)
    }
  }

  return (
    <div className='flex flex-col mb-5'>
      <div className='flex items-center w-full gap-3 mb-5 md:w-80'>
        <button
          className='w-12 h-8 text-lg font-semibold text-center bg-primary text-dark rounded-full focus:outline-none'
          onClick={handleDecrease}
        >
          -
        </button>
        <span className='w-24 px-2 py-1 text-lg font-semibold text-center bg-primary text-dark'>
          {formatDuration(defaultOption || 3)}
        </span>
        <button
          className='w-12 h-8 text-lg font-semibold text-center bg-primary text-dark rounded-full focus:outline-none'
          onClick={handleIncrease}
        >
          +
        </button>
        <div className='w-full space-y-4'>
          <Slider.Root
            className='relative flex items-center w-full h-5 select-none touch-none'
            value={[defaultOption]}
            max={maxDuration}
            min={minDuration}
            step={step}
            onValueChange={handleSlider}
            aria-label='Duration'
          >
            <Slider.Track className='bg-primary/50 relative grow h-[2px]'>
              <hr className='absolute top-0 bottom-0 m-auto w-[1px] h-4 bg-primary border-0 p-0' />
              <Slider.Range className='absolute top-0 bottom-0 m-auto bg-primary h-[6px]' />
            </Slider.Track>
            <Slider.Thumb className='flex cursor-pointer justify-center group w-5 h-5 bg-primary shadow-[0_2px_10px] shadow-primary/50 rounded-[10px] focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-primary/50'>
              <span className='thumbLabel bg-primary text-dark -translate-y-[100%] group-focus:scale-y-100 scale-y-0'>
                {formatDuration(defaultOption || 3)}
              </span>
            </Slider.Thumb>
          </Slider.Root>
        </div>
      </div>
      {showSubscriptionTextCTA ? (
        <a
          href='/pricing'
          className='rounded-full w-fit font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'
        >
          <StarIcon className='inline w-6 h-6 mr-2' />
          {subscriptionTextCTA}
        </a>
      ) : null}
    </div>
  )
}

export default LengthSlider
