import { ElementType } from '@/types'
import { ElementMenuItem } from './ElementMenuItem'
import { ELEMENTS_CONFIG } from '../../../config'

/**
 * Props for the ElementsMenu component.
 * @interface ElementsMenuProps
 * @property {ElementType[]} elements - All possible elements that can be added to the menu. These elements will be optional.
 * @property {string} formId - A string identifier for the form associated with this menu.
 * @property {() => void} onSelect - A callback function to be called when an element is selected.
 */
interface ElementsMenuProps {
  elements: ElementType[]
  formId: string
  onSelect: () => void
}
interface ElementsMenuProps {
  elements: ElementType[]
  formId: string
  onSelect: () => void
}

export const ElementsMenu = ({
  elements,
  formId,
  onSelect,
}: ElementsMenuProps) => {
  return (
    <div
      className={'text-gray-100 grid gap-x-3 gap-y-4'}
      style={{
        gridTemplateColumns: `repeat(${elements.length > 2 ? 3 : elements.length}, minmax(112px, 1fr))`,
      }}
    >
      {elements.map((element, index) => (
        <ElementMenuItem
          key={`${ELEMENTS_CONFIG[element]}-${index}`}
          element={element}
          formId={formId}
          closePopover={onSelect}
        />
      ))}
    </div>
  )
}
