import { useThemeContext } from '../../../context'

export interface MediaProgressOverlayProps {
  progress: number
  hideProgress: boolean
}

export const MediaProgressOverlay = ({
  progress,
  hideProgress,
}: MediaProgressOverlayProps) => {
  const { colors } = useThemeContext()
  if (hideProgress) return null

  return (
    <>
      <div className='absolute inset-0 flex items-center justify-center'>
        <span className={`${colors.text.brand} text-xl font-bold`}>
          {`${Math.floor((progress ?? 0) * 100)}%`}
        </span>
      </div>

      <div className='absolute bottom-[1px] left-0 w-[calc(100%-8px)] ml-1 h-1 bg-k2-neutral-300/75 overflow-hidden rounded-b-xl'>
        <div
          className={`h-full ${colors.background.brand} rounded`}
          style={{ width: `${Math.floor((progress ?? 0) * 100)}%` }}
        ></div>
      </div>
    </>
  )
}
