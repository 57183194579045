import { useReactFlow } from '@xyflow/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FlowNodeProps } from './FlowNode'
import { FlowNodeNameInput } from './FlowNodeNameInput'
import { FlowNodeToolbar } from './FlowNodeToolbar'
import { FLOWS_CONFIG } from '../../../../config/flowsConfig'
import {
  BORDER_COLORS,
  DEFAULT_BORDER_COLOR,
} from '../../../../constants/colors'
import { useThemeContext } from '../../../../context'
import { usePresetContext } from '../../../../context/presetContext'
import { useNodeUtility } from '../../../../hooks/k2/useNodeUtility'
import { BookmarkIcon } from '../../../../images/icons/BookmarkIcon'
import { ExpandIcon } from '../../../../images/icons/ExpandIcon'
import { MinimizeIcon } from '../../../../images/icons/MinimizeIcon'
import { PlusCircleIcon } from '../../../../images/icons/PlusCircleIcon'
import { XMarkIcon2 } from '../../../../images/icons/XMarkIcon2'
import { cn } from '../../../../utils'
import { getAssetKeyFromMedia } from '../../../../utils/mediaUtils'
import { ElementsMenu } from '../../FlowHeader/ElementsMenu'
import { ToolbarButton } from '../../NodeToolbar'
import { Media } from '@/types'

interface INodeHeaderProps extends FlowNodeProps {
  isHovering: boolean
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const FlowNodeHeader: React.FC<INodeHeaderProps> = (props) => {
  const {
    id,
    data: {
      modelType,
      formValues,
      elements = [],
      lastTempId,
      name: initialName,
    },
    isHovering,
    isCollapsed,
    setIsCollapsed,
  } = props

  const { colors } = useThemeContext()
  const { updateNode, getNode } = useReactFlow()
  const { deleteNodeById } = useNodeUtility()
  const { savePreset } = usePresetContext()

  const menuRef = useRef<HTMLDivElement>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [showElementsMenu, setShowElementsMenu] = useState(false)
  const [name, setName] = useState(initialName)

  const optionalElements = useMemo(
    () => FLOWS_CONFIG[modelType].optionalElements,
    [modelType],
  )
  const availableElements = useMemo(
    () => optionalElements.filter((element) => !elements.includes(element)),
    [elements, optionalElements],
  )
  const borderColor = useMemo(
    () => BORDER_COLORS[modelType] ?? DEFAULT_BORDER_COLOR,
    [modelType],
  )
  const displayName = useMemo(
    () => (name ? `${name} (${modelType})` : modelType),
    [name, modelType],
  )

  const handleSavePreset = useCallback(async () => {
    try {
      let thumbnail = null
      if (lastTempId) {
        const mediaNode = getNode(lastTempId)
        const media = mediaNode?.data?.media as Media
        if (media) {
          thumbnail = {
            key: await getAssetKeyFromMedia(media),
          }
        }
      }

      await savePreset({
        name: name || `${modelType} Preset`,
        modelType,
        formValues,
        elements,
        thumbnail,
      })
    } catch (error) {
      console.error('Error saving preset:', error)
    }
  }, [name, modelType, formValues, elements, savePreset, lastTempId, getNode])

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowElementsMenu(false)
    }
  }, [])

  const toggleCollapseFlow = useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [setIsCollapsed])

  const handleSaveName = useCallback(() => {
    updateNode(id, {
      data: {
        ...props.data,
        name: name,
      },
    })
    setIsEditing(false)
  }, [id, props.data, name, updateNode])

  const handleShowElements = useCallback(() => {
    setShowElementsMenu(true)
  }, [])

  const handleHideElements = useCallback(() => {
    setShowElementsMenu(false)
  }, [])

  const toolbarButtons: ToolbarButton[] = useMemo(
    () => [
      ...(availableElements.length > 0
        ? [
            {
              icon: PlusCircleIcon,
              onClick: handleShowElements,
              title: 'Add Elements',
              className: colors.text.default,
            },
          ]
        : []),
      {
        icon: BookmarkIcon,
        onClick: handleSavePreset,
        title: 'Save as Preset',
        className: colors.text.default,
      },
      {
        icon: isCollapsed ? ExpandIcon : MinimizeIcon,
        onClick: toggleCollapseFlow,
        title: isCollapsed ? 'Expand Flow' : 'Minimize Flow',
        className: colors.text.default,
      },
      {
        icon: XMarkIcon2,
        onClick: () => deleteNodeById(id),
        title: 'Remove from Canvas',
        className: 'text-alarm',
        size: 24,
      },
    ],
    [
      availableElements.length,
      id,
      deleteNodeById,
      handleShowElements,
      handleSavePreset,
      toggleCollapseFlow,
      isCollapsed,
      colors.text.default,
    ],
  )

  useEffect(() => {
    if (showElementsMenu) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showElementsMenu, handleClickOutside])

  return (
    <div>
      <div
        ref={menuRef}
        className={cn(
          'absolute bg-k2-gray-600 rounded-xl p-6 z-50 hidden outline outline-gray-900 shadow-lg left-1/2 -translate-x-1/2 top-0',
          {
            'animate-in zoom-in-50 duration-150 block': showElementsMenu,
          },
        )}
      >
        <ElementsMenu
          formId={id}
          onSelect={handleHideElements}
          elements={availableElements}
        />
      </div>
      <FlowNodeToolbar
        isEditing={isEditing}
        isHovering={isHovering}
        toolbarButtons={toolbarButtons}
        borderColor={borderColor}
      />
      <FlowNodeNameInput
        name={name}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        setName={setName}
        handleSaveName={handleSaveName}
        displayName={displayName}
        borderColor={borderColor}
      />
    </div>
  )
}
