// forwardRef is used here to allow this custom InputField component to be used seamlessly with form libraries
// (like react-hook-form) and in situations where a ref needs to be passed to the underlying input element.
// This enables proper integration with form management, validation, and imperative handle access.
import { forwardRef, InputHTMLAttributes } from 'react'

import { useThemeContext } from '../../../context/themeContext'
import { cn } from '../../../utils'
import { toTitleCase } from '../../../utils/formUtils'

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  className?: string
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label = '', className = '', ...rest }, ref) => {
    const { colors } = useThemeContext()

    return (
      <div className='flex items-center justify-between w-full'>
        {label && (
          <label
            htmlFor={label}
            className={cn(
              'text-sm text-gray-300 mr-2 capitalize',
              colors.text.subtle,
            )}
          >
            {toTitleCase(label)}
          </label>
        )}
        <input
          ref={ref}
          id={label}
          className={`${colors.elevation.surface.sunken} ${colors.text.default} text-sm px-3 text-center rounded-full outline-none h-7 w-24
                      [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
                      disabled:opacity-50 disabled:cursor-not-allowed
                      ${className}`}
          {...rest}
        />
      </div>
    )
  },
)

InputField.displayName = 'InputField'
