import { Helmet } from 'react-helmet'

function ReferrerIdHead() {
  return (
    <Helmet>
      <script>
        {'(' +
          function () {
            const query = new URLSearchParams(window.location.search)
            const referrerId = query.get('referrer_id')

            if (referrerId) {
              const domain = window.location.origin.includes('localhost')
                ? 'localhost'
                : '.kaiber.ai'

              document.cookie = `referrer_id=${referrerId} Domain=${domain} Max-Age=2592000 Path=/ SameSite=none Secure`
            }
          }.toString() +
          ')()'}
      </script>
    </Helmet>
  )
}

export default ReferrerIdHead
