import { TabPanel, Tabs, TabsBody } from '@material-tailwind/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

import { PresetItem } from './PresetItem'
import { PresetMenuHeader } from './PresetMenuHeader'
import { saveMedia } from '../../../../api'
import {
  PRESET_FADE_OUT_MILLISECONDS,
  useCanvasContext,
  usePresetContext,
  useUserAccountContext,
  useThemeContext,
} from '../../../../context'
import { useHorizontalScroll, useMousePosition } from '../../../../hooks'
import { CircleInfoIcon } from '../../../../images/icons/CircleInfoIcon'
import { cn } from '../../../../utils'
import { dispatchDropEvent } from '../../../../utils/dndUtils'
import {
  CompatiblePresetFlow,
  DropType,
  PresetCategory,
  PresetOrdering,
  TagNamespace,
} from '@/types'

const MOUSE_CLICK_THRESHOLD_MS = 200

interface PresetMenuProps {
  iconRef: React.RefObject<SVGSVGElement>
}

export interface TabData {
  label: string
  value: PresetCategory
  flows: CompatiblePresetFlow[]
}

export const PresetMenu: React.FC<PresetMenuProps> = ({ iconRef }) => {
  const {
    isPresetMenuOpen,
    userPresets,
    kaiberPresets,
    addPresetToCanvas,
    closePresetMenu,
    updatePreset,
    deletePreset,
    inputMedia,
  } = usePresetContext()
  const { colors } = useThemeContext()
  const { currentUser, updateUserPreferences } = useUserAccountContext()
  const { getReactFlowElement } = useCanvasContext()
  const [activeTab, setActiveTab] = useState(PresetCategory.KaiberFlows)
  const [hoveredPreset, setHoveredPreset] =
    useState<CompatiblePresetFlow | null>(null)
  const [showDescription, setShowDescription] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [editModes, setEditModes] = useState<Set<string>>(new Set())
  const [presetOrdering, setPresetOrdering] = useState<PresetOrdering>(
    currentUser.preferences?.presetOrdering || {
      [PresetCategory.KaiberFlows]: [],
      [PresetCategory.UserFlows]: [],
    },
  )
  const [isReordering, setIsReordering] = useState(false)

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const mouseDownTimeRef = useRef<number>(0)
  const { positionRef: mousePositionRef, isMouseOverHTMLElement } =
    useMousePosition(isPresetMenuOpen)
  const presetNameInputRef = useRef<{ [key: string]: HTMLInputElement | null }>(
    {},
  )

  // allow horizontal scroll with mouse on preset scroll container
  useHorizontalScroll(scrollContainerRef)

  const orderPresets = useCallback(
    (
      presets: CompatiblePresetFlow[],
      category: PresetCategory,
    ): CompatiblePresetFlow[] => {
      const currentOrdering = presetOrdering[category] || []

      // Create a map of presets for quick lookup
      const presetMap = new Map(
        presets.map((preset) => [preset.presetId, preset]),
      )

      // First, add all presets that are in the ordering
      const orderedPresets = currentOrdering
        .map((presetId) => presetMap.get(presetId))
        .filter(
          (preset): preset is CompatiblePresetFlow => preset !== undefined,
        )

      // Then, add any remaining presets that weren't in the ordering
      presets.forEach((preset) => {
        if (!orderedPresets.includes(preset)) {
          orderedPresets.push(preset)
        }
      })

      return orderedPresets
    },
    [presetOrdering],
  )

  // Filtered in this context means that we are showing the user some subset of the total available presets.
  // We disable reordering because it's difficult to maintain the order of the filtered presets with respect to the global order.
  const isFiltered = searchText.length > 0 || inputMedia !== null

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    const category = activeTab

    if (!result.destination && !isMouseOverHTMLElement(menuRef.current)) {
      dispatchDropEvent(getReactFlowElement(), mousePositionRef.current, {
        type: DropType.Preset,
        [DropType.Preset]: JSON.stringify(activeFlows[source.index]),
      })
    } else if (result.destination) {
      const newPresetOrdering = orderPresets(activeFlows, category).map(
        (preset) => preset.presetId,
      )

      // Remove the moved preset from its original position
      const [movedPresetId] = newPresetOrdering.splice(source.index, 1)

      // Insert the moved preset at its new position
      newPresetOrdering.splice(destination.index, 0, movedPresetId)

      setPresetOrdering((prevOrdering) => ({
        ...prevOrdering,
        [category]: newPresetOrdering,
      }))

      updateUserPreferences({
        ...currentUser.preferences,
        presetOrdering: {
          ...presetOrdering,
          [category]: newPresetOrdering,
        },
      }).catch((error) => {
        console.error('Failed to update preset ordering:', error)
        setPresetOrdering(
          currentUser.preferences?.presetOrdering || {
            [PresetCategory.KaiberFlows]: [],
            [PresetCategory.UserFlows]: [],
          },
        )
      })
    }
  }

  const confirmNameChange = async (presetId: string) => {
    const preset = userPresets.find((p) => p.presetId === presetId)
    const newName = presetNameInputRef.current[presetId]?.value
    if (preset && newName && preset.name !== newName) {
      try {
        const updatedPreset = { ...preset, name: newName }
        await updatePreset(updatedPreset)
      } catch (error) {
        console.error('Error updating preset name:', error)
      }
    }
  }

  const handleReorderClick = () => {
    setIsReordering((prev) => !prev)
  }

  useEffect(() => {
    const handleMouseDown = () => {
      mouseDownTimeRef.current = Date.now()
    }

    const handleMouseUp = (event: MouseEvent) => {
      const mouseUpTime = Date.now()

      // If the mouse was down for less than the threshold, consider it a click, not a drag
      if (mouseUpTime - mouseDownTimeRef.current < MOUSE_CLICK_THRESHOLD_MS) {
        if (
          isPresetMenuOpen &&
          menuRef.current &&
          !menuRef.current.contains(event.target as Node) &&
          iconRef.current &&
          !iconRef.current.contains(event.target as Node)
        ) {
          closePresetMenu()
        }
      }
    }

    if (isPresetMenuOpen) {
      document.addEventListener('mousedown', handleMouseDown)
      document.addEventListener('mouseup', handleMouseUp)

      return () => {
        document.removeEventListener('mousedown', handleMouseDown)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isPresetMenuOpen, closePresetMenu, iconRef])

  const handleTabChange = (tab: PresetCategory) => {
    if (tab !== activeTab) {
      setActiveTab(tab)
      setHoveredPreset(null)
      setShowDescription(false)
      setIsReordering(false)
      setEditModes(new Set())
    }
  }

  const handleSearchInput = (text: string) => {
    setIsReordering(false)
    setSearchText(text)
  }

  const handleFileUpload =
    (preset: CompatiblePresetFlow) => async (file: File) => {
      try {
        const media = await saveMedia(file, [
          { ns: TagNamespace.MediaType, name: 'Image' },
          { ns: TagNamespace.Uploaded, name: 'Yes' },
        ])
        // @todo - Track this upload event - https://kaiberteam.atlassian.net/browse/ENG-2457

        if (media) {
          // Update the preset's thumbnail
          const updatedPreset = {
            ...preset,
            thumbnail: {
              key: media.assetKey,
            },
          }
          await updatePreset(updatedPreset)
          setEditModes((prevModes) => {
            const newModes = new Set(prevModes)
            newModes.delete(preset.presetId)
            return newModes
          })
        } else {
          throw new Error('Failed to process image.')
        }
      } catch (error) {
        console.error('Error uploading media:', error)
      }
    }

  const filteredFlows = useCallback(
    (flows: CompatiblePresetFlow[]) => {
      const filtered = flows.filter((preset: CompatiblePresetFlow) =>
        preset.name.toLowerCase().includes(searchText.toLowerCase()),
      )
      return filtered
    },
    [searchText],
  )

  const orderedFlows = useCallback(
    (flows: CompatiblePresetFlow[], category: PresetCategory) => {
      return orderPresets(flows, category)
    },
    [orderPresets],
  )

  const tabs: TabData[] = [
    {
      label: 'My Flows',
      value: PresetCategory.UserFlows,
      flows: orderedFlows(filteredFlows(userPresets), PresetCategory.UserFlows),
    },
    {
      label: 'Kaiber Flows',
      value: PresetCategory.KaiberFlows,
      flows: orderedFlows(
        filteredFlows(kaiberPresets),
        PresetCategory.KaiberFlows,
      ),
    },
  ]

  const activeFlows =
    tabs.find((tab: TabData) => tab.value === activeTab)?.flows || []

  return (
    <div
      ref={menuRef}
      className={cn(
        'absolute top-full left-0 pt-4 mt-2 z-30 -translate-x-1/2 before:content-[""] before:absolute before:-top-2 before:left-0 before:right-0 before:h-6',
        `transition-[visibility] duration-${PRESET_FADE_OUT_MILLISECONDS} ease-in-out invisible`,
        { 'visible': isPresetMenuOpen },
      )}
    >
      <div
        className={cn(
          `${colors.elevation.surface.sunken} shadow-md rounded-2xl w-[calc(85vw-250px)] max-w-[1060px] border-k2-gray-200 border`,
          `transition-opacity duration-${PRESET_FADE_OUT_MILLISECONDS} ease-in-out opacity-0`,
          { 'opacity-100': isPresetMenuOpen },
        )}
      >
        <Tabs value={activeTab}>
          <PresetMenuHeader
            activeTab={activeTab}
            closePresetMenu={closePresetMenu}
            handleReorderClick={handleReorderClick}
            handleSearchInput={handleSearchInput}
            handleTabChange={handleTabChange}
            ref={searchRef}
            searchText={searchText}
            showReorderButton={!isFiltered && activeFlows.length > 0}
            tabs={tabs}
          />
          <TabsBody>
            {tabs.map(({ value, flows }: TabData) => (
              <TabPanel
                key={value}
                value={value}
                className='absolute pl-0 pb-1'
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId={`presets-${value}`}
                    direction='horizontal'
                    isDropDisabled={!isReordering || isFiltered}
                  >
                    {(provided, _) => (
                      <div
                        ref={(el) => {
                          scrollContainerRef.current = el
                          provided.innerRef(el)
                        }}
                        {...provided.droppableProps}
                        className='flex gap-4 overflow-x-auto relative ml-6 pl-6'
                      >
                        {/* @todo: pull this out into its own component: https://kaiberteam.atlassian.net/browse/ENG-2566 */}
                        {flows.map(
                          (preset: CompatiblePresetFlow, index: number) =>
                            preset.isCompatible && (
                              <PresetItem
                                key={preset.presetId}
                                preset={preset}
                                index={index}
                                category={value as PresetCategory}
                                isReordering={isReordering}
                                isFiltered={isFiltered}
                                hoveredPreset={hoveredPreset}
                                editModes={editModes}
                                presetNameInputRef={presetNameInputRef}
                                setHoveredPreset={setHoveredPreset}
                                setEditModes={setEditModes}
                                onSelectPreset={addPresetToCanvas}
                                confirmNameChange={confirmNameChange}
                                handleFileUpload={handleFileUpload}
                                deletePreset={deletePreset}
                                menuRef={menuRef}
                              />
                            ),
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TabPanel>
            ))}

            {!!hoveredPreset?.description && (
              <div className='relative'>
                <div
                  className={cn(
                    'transition-all duration-300 overflow-hidden h-0 ease-in-out px-6',
                    {
                      'h-40': showDescription,
                    },
                  )}
                >
                  <div className='text-white'>
                    <div className='font-semibold pb-2'>
                      {hoveredPreset.name}
                    </div>
                    <div className={colors.text.default}>
                      {hoveredPreset.description}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </TabsBody>
        </Tabs>

        <div
          className={cn(
            `${colors.background.default} absolute -bottom-[14px] z-30 left-1/2 flex items-center justify-center cursor-pointer border-k2-gray-200 border w-8 h-8 rounded-md shadow-k2-25 opacity-0 transition-all duration-300 ease-in-out opacity-100`,
          )}
          onClick={() => setShowDescription(!showDescription)}
        >
          <CircleInfoIcon
            className={`opacity-80 hover:opacity-100 transition ease-in-out duration-300 ${colors.text.brand}`}
          />
        </div>
      </div>
    </div>
  )
}

PresetMenu.displayName = 'PresetMenu'
