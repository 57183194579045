import { useState } from 'react'
import { Draggable, DraggingStyle } from 'react-beautiful-dnd'

import { IconWrapper } from './IconWrapper'
import { PRESETS_CONFIG } from '../../../../config'
import { HOTKEYS } from '../../../../constants'
import { useThemeContext } from '../../../../context'
import { useHotkeys } from '../../../../hooks'
import { CheckIcon } from '../../../../images/icons/CheckIcon'
import { EditIcon } from '../../../../images/icons/EditIcon'
import { TrashIcon } from '../../../../images/icons/TrashIcon'
import { XMarkIcon2 } from '../../../../images/icons/XMarkIcon2'
import { cn } from '../../../../utils'
import { IconButton } from '../../../Button'
import { UploadDropzone } from '../../UploadDropzone'
import { CompatiblePresetFlow, PresetCategory } from '@/types'

interface PresetItemProps {
  preset: CompatiblePresetFlow
  index: number
  category: PresetCategory
  isReordering: boolean
  isFiltered: boolean
  hoveredPreset: CompatiblePresetFlow | null
  editModes: Set<string>
  presetNameInputRef: React.RefObject<{
    [key: string]: HTMLInputElement | null
  }>
  menuRef: React.RefObject<HTMLDivElement>
  setHoveredPreset: React.Dispatch<
    React.SetStateAction<CompatiblePresetFlow | null>
  >
  setEditModes: React.Dispatch<React.SetStateAction<Set<string>>>
  onSelectPreset: (preset: CompatiblePresetFlow) => void
  confirmNameChange: (presetId: string) => Promise<void>
  handleFileUpload: (
    preset: CompatiblePresetFlow,
  ) => (file: File) => Promise<void>
  deletePreset: (presetId: string) => Promise<void>
}

const DeleteConfirmation: React.FC<{
  onConfirm: () => void
  onCancel: () => void
}> = ({ onConfirm, onCancel }) => (
  <div className='absolute inset-0 bg-k2-neutral-600 flex items-center justify-center'>
    <div className='text-white text-center'>
      <p className='mb-2'>Delete preset?</p>
      <div className='flex justify-center gap-4'>
        <div
          className='text-green-500 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation()
            onConfirm()
          }}
        >
          <CheckIcon size='24' className='text-green-500 cursor-pointer' />
        </div>
        <div
          className='text-red-500 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation()
            onCancel()
          }}
        >
          <XMarkIcon2 />
        </div>
      </div>
    </div>
  </div>
)

export const PresetItem: React.FC<PresetItemProps> = ({
  preset,
  index,
  category,
  isReordering,
  isFiltered,
  hoveredPreset,
  editModes,
  presetNameInputRef,
  menuRef,
  setHoveredPreset,
  setEditModes,
  onSelectPreset,
  confirmNameChange,
  handleFileUpload,
  deletePreset,
}) => {
  const { colors } = useThemeContext()

  const [isDeleting, setIsDeleting] = useState(false)

  useHotkeys({
    [HOTKEYS.ENTER]: {
      handler: (event: KeyboardEvent) => {
        if (editModes.has(preset.presetId)) {
          event.preventDefault()
          confirmNameChange(preset.presetId)
          toggleEditMode(preset.presetId, preset.name)
        }
      },
    },
  })

  const toggleEditMode = (presetId: string, presetName: string) => {
    setEditModes((prevModes) => {
      const newModes = new Set(prevModes)
      if (newModes.has(presetId)) {
        newModes.delete(presetId)
        confirmNameChange(presetId)
      } else {
        newModes.add(presetId)
        setTimeout(() => {
          const input = presetNameInputRef.current?.[presetId]
          if (input) {
            input.value = presetName
            input.focus()
            input.setSelectionRange(input.value.length, input.value.length)
          }
        }, 0)
      }
      return newModes
    })
  }

  const handleDeleteClick = () => {
    setIsDeleting(true)
  }

  const confirmDelete = async () => {
    try {
      await deletePreset(preset.presetId)
    } catch (error) {
      console.error('Error deleting preset:', error)
    } finally {
      setIsDeleting(false)
    }
  }

  const cancelDelete = () => {
    setIsDeleting(false)
  }

  const handlePresetClick = () => {
    if (!editModes.has(preset.presetId)) {
      onSelectPreset(preset)
    }
  }

  const renderPresetContent = () => {
    if (editModes.has(preset.presetId)) {
      return (
        <UploadDropzone
          onFileUpload={handleFileUpload(preset)}
          className='w-full h-full'
        />
      )
    }

    if (isDeleting) {
      return (
        <DeleteConfirmation onConfirm={confirmDelete} onCancel={cancelDelete} />
      )
    }

    if (preset.thumbnail?.signedUrl) {
      return (
        <img
          alt={preset.name}
          src={preset.thumbnail.signedUrl}
          className='w-full h-full object-cover'
          style={{ objectPosition: 'center' }}
        />
      )
    }

    return (
      <div className='w-full h-full bg-k2-neutral-600 flex items-center justify-center text-k2-neutral-300'>
        No Image
      </div>
    )
  }

  const renderPresetName = () => {
    if (editModes.has(preset.presetId)) {
      return (
        <input
          ref={(el) => {
            if (presetNameInputRef.current) {
              presetNameInputRef.current[preset.presetId] = el
            }
          }}
          type='text'
          defaultValue={preset.name}
          onBlur={() => confirmNameChange(preset.presetId)}
          onClick={(e) => e.stopPropagation()}
          className={`text-xs items-center font-normal bg-transparent border-b ${colors.border.brand} ${colors.text.brand} outline-none`}
        />
      )
    }

    return <span>{preset.name}</span>
  }

  const renderEditButtons = () => {
    if (
      category !== PresetCategory.UserFlows ||
      hoveredPreset?.presetId !== preset.presetId ||
      isDeleting
    ) {
      return null
    }

    return (
      <>
        <div
          className='absolute top-2 right-2 text-shrek cursor-pointer'
          onClick={(e) => {
            e.stopPropagation()
            toggleEditMode(preset.presetId, preset.name)
          }}
        >
          {editModes.has(preset.presetId) ? (
            <XMarkIcon2 className={colors.text.default} />
          ) : (
            <EditIcon className='text-white' />
          )}
        </div>
        {!editModes.has(preset.presetId) && (
          <IconButton
            icon={TrashIcon}
            title='delete preset'
            className='absolute top-2 left-2 text-alarm'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              handleDeleteClick()
            }}
          />
        )}
      </>
    )
  }

  return (
    <Draggable draggableId={preset.presetId} index={index}>
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          const offset = menuRef.current?.getBoundingClientRect().left ?? 0
          const style = provided.draggableProps.style as DraggingStyle
          style.left = (style.left as number) - offset
          provided.draggableProps.style = style
        }
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn('relative mb-5 mt-2', {
              'z-40': snapshot.isDragging,
            })}
            style={{
              ...provided.draggableProps.style,
              top: 'auto !important',
            }}
          >
            <div
              className={cn(
                'flex flex-col flex-shrink-0 gap-2 cursor-pointer transition-all ease-in-out duration-200 select-none',
                {
                  'scale-105': hoveredPreset?.presetId === preset.presetId,
                  'animate-jiggle':
                    isReordering && !isFiltered && !snapshot.isDragging,
                },
              )}
              onClick={handlePresetClick}
              onMouseOver={() => setHoveredPreset(preset)}
            >
              <div
                className={cn(
                  'relative w-[150px] h-[150px] overflow-hidden rounded-lg border-2 border-transparent transition-colors',
                  {
                    [`${colors.border.brand}`]:
                      hoveredPreset?.presetId === preset.presetId,
                  },
                )}
              >
                {renderPresetContent()}
                {renderEditButtons()}
              </div>
              <span
                className={cn(
                  `text-xs font-normal mt-2 ${colors.text.default}`,
                  {
                    [colors.text.brand]:
                      hoveredPreset?.presetId === preset.presetId,
                  },
                )}
              >
                {renderPresetName()}
              </span>
            </div>

            {preset.name in PRESETS_CONFIG && (
              <div className='absolute bottom-8 w-full scale-105'>
                <div
                  className={cn(
                    'flex justify-between opacity-0 transition ease-in-out',
                    {
                      'opacity-100':
                        hoveredPreset?.presetId === preset.presetId,
                    },
                  )}
                >
                  <IconWrapper
                    icon={PRESETS_CONFIG[preset.name]?.fromIcon}
                    className='-ml-2'
                  />
                  <IconWrapper
                    icon={PRESETS_CONFIG[preset.name]?.toIcon}
                    className='-mr-2'
                  />
                </div>
              </div>
            )}
          </div>
        )
      }}
    </Draggable>
  )
}
