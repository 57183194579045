import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Lottie from 'lottie-react'
import { useMixpanel } from 'react-mixpanel-browser'
import { usePostHog } from 'posthog-js/react'

import { FLAGS } from './constants'
import { Maintenance } from './components/Maintenance'
import { Alert } from './components/Alert'
import AdminDashboard from './pages/Admin/AdminDashboard'
import AdminGallery from './pages/Admin/AdminGallery'
import Announcements from './pages/Admin/Announcements'
import AnnouncementsEditor from './pages/Admin/AnnouncementsEditor'
import Users from './pages/Admin/Users'
import UserInfo from './pages/Admin/UserInfo'
import FreeUserModalComponent from './components/FreeUserModalComponent'
import FreeTrialStartedModalComponent from './components/FreeTrialStartedModalComponent'
import FreeTrialUsageAlertModalComponent from './components/FreeTrialUsageAlertModalComponent'
import AudioCreator from './pages/AudioCreator'
import { FeatureFlagged } from './components/FeatureFlagged'
import GetMobile from './components/GetMobile'
import Onboarding from './components/Onboarding'
import MobileLanding from './pages/MobileLanding'
import Motion from './pages/Motion'
import { Community } from './pages/Community'
import LoadingWhite from './images/lottie/loaderWhite.json'
import { Profile } from './pages/Profile'
import SubscriptionTierUpdatedModalComponent from './components/SubscriptionTierUpdatedModalComponent'
import { CompleteOnboardingModal } from './components/CompleteOnboardingModal'
import { Creator } from './pages/Creator'
import { Dashboard } from './pages/Dashboard'
import Pricing from './pages/Pricing'
import PricingNew from './pages/PricingNew'
import { Account } from './pages/Account'
import { Gallery } from './pages/Gallery'
import { AboutRoutes } from './components/about/AboutRoutes'
import { Product } from './pages/Product'
import { Studio } from './pages/Studio'
import Share from './pages/Share'
import Login from './pages/Auth/Login'
import Register from './pages/Auth/Register'
import EmailVerification from './pages/Auth/EmailVerification'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetPassword from './pages/Auth/ResetPassword'
import GoogleCallback from './pages/Auth/GoogleCallback'
import AppleCallback from './pages/Auth/AppleCallback'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import AffiliateTerms from './pages/AffiliateTerms'
import HowItWorks from './pages/HowItWorks'
import Credits from './pages/Credits'
import Welcome from './pages/Welcome'
import Musician from './pages/Musician'
import Portfolio from './pages/Portfolio'
import NotFound from './pages/NotFound'
import MemoryDetailView from './pages/MemoryDetailView'
import { useUserAccountContext } from './context/userContext'
import { useVideoEventsContext } from './context/videoEventsContext'
import { useScrollToTop } from './hooks/useScrollToTop'
import { FlowCanvas } from './pages/FlowCanvas'
import { ReactFlowProvider } from '@xyflow/react'
import { initializeCookieBanner } from './utils/cookieUtils'
import { FlowCanvasSelector } from './pages/FlowCanvasSelector'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// global React Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // A good staleTime is all you need: https://tkdodo.eu/blog/react-query-as-a-state-manager
      // The default staleTime of 0 can cause a lot of refetches -- see https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults.
      // Overriding to Infinity because refetching everything on a large canvas can cause performance issues.
      staleTime: Infinity,
      retry: false, // Overriding the default retry of 3
    },
  },
})

export const RoutesComponent = () => {
  const location = useLocation()
  const mixpanel = useMixpanel()
  const posthog = usePostHog()
  const {
    isLoading,
    isOnboardingCompleted,
    isAuthenticated,
    currentUser,
    featureToggles,
    errorCode,
    errorMessage,
  } = useUserAccountContext()
  const { eventStartTime, eventEndTime, onUnloadEvent, isPlaying } =
    useVideoEventsContext()

  const featureUserProfiles = featureToggles[FLAGS.USER_PROFILES]

  useScrollToTop()

  // Initialize the Cookie Banner when the routes are loaded
  useEffect(() => {
    if (mixpanel && posthog) {
      // Expose the Mixpanel instance to the window object for debugging purposes
      window.mixpanel = mixpanel
      initializeCookieBanner({ posthog, mixpanel })
    }
  }, [posthog, mixpanel])

  useEffect(() => {
    mixpanel.track('Page View', { 'Page Name': location.pathname })
    window.Intercom('update')

    if (eventStartTime !== null && eventEndTime !== null && isPlaying) {
      onUnloadEvent()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (isAuthenticated) {
      posthog.identify(currentUser?.distinctId, {
        email: currentUser?.email,
        role: currentUser?.role,
        username: currentUser?.username,
        subscriptionTierDescription: currentUser?.subscriptionTierDescription,
      })
    }
  }, [
    currentUser?.distinctId,
    currentUser?.email,
    currentUser?.role,
    currentUser?.subscriptionTierDescription,
    currentUser?.username,
    isAuthenticated,
    posthog,
  ])

  if (isLoading) {
    return (
      <div className='flex h-full'>
        <div className='p-6 m-auto bg-darkGray rounded-3xl'>
          <Lottie
            animationData={LoadingWhite}
            loop={true}
            className='w-16 h-16'
          />
        </div>
      </div>
    )
  } else if (errorMessage) {
    return (
      <>
        {errorCode === 'BETA_ACCESS_DENIED' ? (
          <div className='flex flex-col items-center justify-center h-screen text-white'>
            <h1 className='font-bold'>Access Denied</h1>
            <p className='text-xl'>{errorMessage}</p>
          </div>
        ) : (
          <div className='flex h-full'>
            <div className='p-6 m-auto sm:max-w-sm rounded-3xl'>
              <Alert open={true} message={errorMessage} type='alert' />
              <div className='mt-4 text-center text-gray-400'>
                <a
                  href={window.location.href}
                  className='text-tertiary/80 hover:text-primary'
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.reload()
                  }}
                >
                  Click here
                </a>{' '}
                to refresh the page
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <Routes>
      <Route path='/' element={<Welcome />} />
      <Route
        path='/flow-canvas'
        element={
          isAuthenticated ? (
            <FeatureFlagged by={featureToggles[FLAGS.KAIBER_2]}>
              <FlowCanvasSelector />
            </FeatureFlagged>
          ) : (
            <Navigate to='/login?redirect=/flow-canvas' replace={true} />
          )
        }
      />
      <Route
        path='/flow-canvas/:canvasId'
        element={
          isAuthenticated ? (
            <FeatureFlagged by={featureToggles[FLAGS.KAIBER_2]}>
              <QueryClientProvider client={queryClient}>
                <ReactFlowProvider>
                  <FlowCanvas />
                </ReactFlowProvider>
              </QueryClientProvider>
            </FeatureFlagged>
          ) : (
            <Navigate to='/login?redirect=/flow-canvas' replace={true} />
          )
        }
      />
      <Route path='/vidcon' element={<Navigate to='/' replace={true} />} />
      <Route path='/mobile' element={<MobileLanding />} />
      <Route path='/community' element={<Community />} />
      <Route
        path='/product/motion'
        element={<Motion isAuthenticated={isAuthenticated} />}
      />
      <Route path='/portfolio' element={<Portfolio />} />
      <Route path='/musician' element={<Musician />} />
      <Route
        path='/create'
        element={
          isAuthenticated ? (
            isOnboardingCompleted || currentUser?.skippedOnboarding ? (
              <Maintenance>
                <SubscriptionTierUpdatedModalComponent>
                  <CompleteOnboardingModal>
                    <FreeUserModalComponent>
                      <FreeTrialUsageAlertModalComponent>
                        <FreeTrialStartedModalComponent>
                          <Creator />
                        </FreeTrialStartedModalComponent>
                      </FreeTrialUsageAlertModalComponent>
                    </FreeUserModalComponent>
                  </CompleteOnboardingModal>
                </SubscriptionTierUpdatedModalComponent>
              </Maintenance>
            ) : (
              <Navigate to='/onboarding' replace={true} />
            )
          ) : (
            <Navigate to='/login?redirect=/create' replace={true} />
          )
        }
      />
      <Route
        path='/audio'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <Maintenance>
              <FreeUserModalComponent>
                <FreeTrialUsageAlertModalComponent>
                  <FreeTrialStartedModalComponent>
                    <AudioCreator />
                  </FreeTrialStartedModalComponent>
                </FreeTrialUsageAlertModalComponent>
              </FreeUserModalComponent>
            </Maintenance>
          ) : (
            <Navigate to='/login?redirect=/create' replace={true} />
          )
        }
      />
      <Route
        path='/dashboard'
        element={
          isAuthenticated ? (
            isOnboardingCompleted || currentUser?.skippedOnboarding ? (
              featureUserProfiles ? (
                <CompleteOnboardingModal>
                  <Profile />
                </CompleteOnboardingModal>
              ) : (
                <CompleteOnboardingModal>
                  <Dashboard />
                </CompleteOnboardingModal>
              )
            ) : (
              <Navigate to='/onboarding' replace={true} />
            )
          ) : (
            <Navigate to='/login?redirect=/dashboard' replace={true} />
          )
        }
      />
      <Route path='/get-mobile' element={<GetMobile />} />

      <Route path='/profile/:username' element={<Profile publicView />} />

      <Route
        path='/onboarding'
        element={
          isAuthenticated ? (
            <Onboarding />
          ) : (
            <Navigate to='/login?redirect=/onboarding' replace={true} />
          )
        }
      />
      <Route
        path='/pricing'
        element={
          <SubscriptionTierUpdatedModalComponent>
            <FreeTrialUsageAlertModalComponent>
              <FreeTrialStartedModalComponent>
                <Pricing />
              </FreeTrialStartedModalComponent>
            </FreeTrialUsageAlertModalComponent>
          </SubscriptionTierUpdatedModalComponent>
        }
      />
      <Route path='/pricingnew' element={<PricingNew />} />
      <Route path='/about/*' element={<AboutRoutes />} />
      <Route path='/product/*' element={<Product />} />
      <Route path='/studio' element={<Studio />} />
      <Route
        path='/account'
        element={
          isAuthenticated ? (
            <Account />
          ) : (
            <Navigate to='/login?redirect=/account' replace={true} />
          )
        }
      />
      <Route path='/gallery' element={<Gallery />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/affiliate/terms' element={<AffiliateTerms />} />
      <Route path='/how-it-works' element={<HowItWorks />} />
      <Route path='/credits' element={<Credits />} />
      <Route path='/share/:memory_id' element={<Share />} />
      <Route path='/memory/:memory_id' element={<MemoryDetailView />} />
      <Route
        path='/login'
        element={
          !isAuthenticated ? (
            <Login />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/register'
        element={
          !isAuthenticated ? (
            <Register />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/register/:referrerId'
        element={
          !isAuthenticated ? (
            <Register />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/invite/:referrerId'
        element={
          !isAuthenticated ? (
            <Register />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/email/verify/:emailVerificationToken'
        element={<EmailVerification />}
      />
      <Route
        path='/password/forgot'
        element={
          !isAuthenticated ? (
            <ForgotPassword />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/password/reset/:passwordResetToken'
        element={
          !isAuthenticated ? (
            <ResetPassword />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/auth/google/callback'
        element={
          !isAuthenticated ? (
            <GoogleCallback />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/auth/apple/callback'
        element={
          !isAuthenticated ? (
            <AppleCallback />
          ) : (
            <Navigate to='/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/admin/dashboard'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <AdminDashboard />
          ) : (
            <Navigate to='/login?redirect=/admin/dashboard' replace={true} />
          )
        }
      />
      <Route
        path='/admin/users'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <Users />
          ) : (
            <Navigate to='/login?redirect=/admin/users' replace={true} />
          )
        }
      />
      <Route
        path='/admin/users/:distinctId'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <UserInfo />
          ) : (
            <Navigate to='/login?redirect=/admin/users' replace={true} />
          )
        }
      />
      <Route
        path='/admin/gallery'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <AdminGallery />
          ) : (
            <Navigate to='/login?redirect=/admin/gallery' replace={true} />
          )
        }
      />
      <Route
        path='/admin/announcements'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <Announcements />
          ) : (
            <Navigate
              to='/login?redirect=/admin/announcements'
              replace={true}
            />
          )
        }
      />
      <Route
        path='/admin/announcement/:distinctId'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <AnnouncementsEditor />
          ) : (
            <Navigate
              to='/login?redirect=/admin/announcements'
              replace={true}
            />
          )
        }
      />
      <Route
        path='/admin/announcement'
        element={
          isAuthenticated && currentUser.role === 'admin' ? (
            <AnnouncementsEditor />
          ) : (
            <Navigate
              to='/login?redirect=/admin/announcements'
              replace={true}
            />
          )
        }
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
