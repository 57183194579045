import { RatioSettingConfig } from '../types'
import { AspectRatio } from '../types/shared'

// ELEMENTS
export const CAMERA_MOVEMENTS = 'cameraMovements'
export const DRAW_TO_PROMPT = 'drawToPrompt'
export const ENHANCE = 'enhance'
export const IMAGE_SETTINGS = 'imageSettings'
export const INPAINT_PROMPT = 'inpaintPrompt'
export const MIDJOURNEY = 'midjourney'
export const MOTION_BRUSH = 'motionBrush'
export const MOODBOARD = 'moodboard'
export const MOTION_V3_SETTINGS = 'motionV3Settings'
export const MOTION_V2_SETTINGS = 'motionV2Settings'
export const FLIPBOOK_V1_SETTINGS = 'flipbookV1Settings'
export const TRANSFORM_V3_SETTINGS = 'transformV3Settings'
export const FLUX_IMAGE_SETTINGS = 'fluxImageSettings'
export const PROMPT = 'prompt'
export const REMIX_IMAGES = 'remixImages'
export const THREE_D = '3d'
export const UPSCALE = 'upscale'
export const UPSCALE_V1_SETTINGS = 'upscaleV1Settings'
export const VIDEO_UPLOAD_S3_KEY = 'userProvidedVideoFileS3Key'

// FIELDS
export const ASPECT_RATIO = 'aspectRatio'
export const CREATIVITY_SCALE = 'creativityScale'
export const DENOISE = 'denoise'
export const LOCK_ASPECT_RATIO = 'lockAspectRatio'
export const EVOLVE = 'evolve'
export const HUE = 'hue'
export const LENGTH = 'length'
export const MASK_PROMPT = 'maskPrompt'
export const MOTION = 'motionMagnitude'
export const SATURATION = 'saturation'
export const STYLE = 'style'
export const SUBJECT = 'subject'
export const TYPE = 'type'
export const VERSION = 'version'
export const GUIDANCE = 'guidance'
export const STEPS = 'steps'
export const BRIGHTNESS = 'brightness'
export const CONTRAST = 'contrast'
export const GRAYSCALE = 'grayscale'
export const ANGLE = 'angle'

export const FIELD_LABELS = {
  [ASPECT_RATIO]: 'Aspect Ratio',
  [EVOLVE]: 'Intensity',
  [HUE]: 'Hue',
  [LENGTH]: 'Length',
  [MASK_PROMPT]: 'Mask Prompt',
  [MOTION]: 'Motion Magnitude',
  [SATURATION]: 'Saturation',
  [STYLE]: 'Style',
  [SUBJECT]: 'Subject',
  [TYPE]: 'Type',
  [VERSION]: 'Version',
  [BRIGHTNESS]: 'Brightness',
  [CONTRAST]: 'Contrast',
  [GRAYSCALE]: 'Grayscale',
  [ANGLE]: 'Angle',
}

// ELEMENTS / FIELDS
export const AUDIO_UPLOAD = 'userProvidedAudioFile'
export const COMPOSITION_REFERENCE_UPLOAD = 'compositionReference'
export const CONTROLNET_REFERENCE_UPLOAD = 'controlnetReference'
export const FACE_REFERENCE_UPLOAD = 'faceReference'
export const HEIGHT = 'height'
export const WIDTH = 'width'
export const IMAGE_UPLOAD = 'userProvidedStartingFrame'
export const IMAGE_REFERENCE_UPLOAD = 'imageReference'
export const PULID_FACE_REFERENCE_UPLOAD = 'pulidFaceReference'
export const STYLE_REFERENCE_UPLOAD = 'styleReference'
export const VIDEO_UPLOAD = 'userProvidedVideoFile'
export const CHECKPOINT_SELECTOR = 'stableDiffusionCheckpoint'
export const DEMO_FIELDS_ELEMENT = 'demoFieldsElement'

// DATA_TYPES
export const TEXT_URI_LIST = 'text/uri-list'
export const PRESET = 'preset'
export const COLLECTION = 'collection'
export const FORUMLA_SUBJECT = 'subject'

// CHECKPOINTS
export const STABLE_DIFFUSION_CHECKPOINTS: Record<string, string> = {
  'Animated': 'dreamshaper',
  'Photorealistic': 'realistic',
}

// DEFAULTS
export const DEFAULT_ASPECT_RATIO = AspectRatio.R16By9
const DEFAULT_LOCK_ASPECT_RATIO = true
const DEFAULT_WIDTH = 1280
const DEFAULT_HEIGHT = 720
const DEFAULT_EVOLVE = 4
const DEFAULT_STYLE = ''
export const DEFAULT_SUBJECT = 'girl with hair blowing in the wind'
const DEFAULT_VERSION = '1'
const DEFAULT_TYPE = 'Image'
const DEFAULT_MOTION_MAGNITUDE = 4
const DEFAULT_LENGTH = 4
const DEFAULT_CHECKPOINT = 'Animated'
const DEFAULT_WEIGHTS: number[] = []
const DEFAULT_GUIDANCE = 3
const DEFAULT_STEPS = 38
const DEFAULT_DENOISE = 0.4
const DEFAULT_CREATIVITY_SCALE = 5

export const DEFAULT_CANVAS_TYPE = DEFAULT_TYPE
export const CURRENT_VERSION = 'k2'

// Canvas
export const CANVAS_SAVE_DEBOUNCE_TIME_MS = 12000
export const ZOOM_DEBOUNCE_TIME_MS = 100

// Analytics
export const PAGEVIEW = 'pageview'

// SIZING
export const MEDIA_CANVAS_DEFAULT_SIZE = 360
export const MEDIA_CANVAS_MAX_SIZE = 2048
export const MEDIA_CANVAS_MIN_SIZE = 128
export const MEDIA_GRID_MAX_WIDTH = 200
export const ELEMENT_MAX_WIDTH = 250
export const DEFAULT_NODE_HEIGHT = 514
export const DEFAULT_NODE_WIDTH = 850
export const DEFAULT_NODE_GAP = 80
export const DEFAULT_NODE_POS_X_Y = 80
export const MIN_IMAGE_PLACEHOLDER_HEIGHT = 180
export const MIN_DIMENSION = 512
export const MAX_DIMENSION = 2688

// Animation
export const CENTER_MOVING_DURATION_MS = 300
export const BOUNDING_BOX_PADDING = 2

// Tagnames
export const HTML_TAGS = {
  textarea: 'textarea',
  input: 'input',
}

// DEFAULT FLOW FORM VALUES
export const DEFAULT_FORM_VALUES = {
  aspectRatio: DEFAULT_ASPECT_RATIO,
  lockAspectRatio: DEFAULT_LOCK_ASPECT_RATIO,
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT,
  subject: DEFAULT_SUBJECT,
  style: DEFAULT_STYLE,
  evolve: DEFAULT_EVOLVE,
  version: DEFAULT_VERSION,
  type: DEFAULT_TYPE,
  motionMagnitude: DEFAULT_MOTION_MAGNITUDE,
  length: DEFAULT_LENGTH,
  stableDiffusionCheckpoint: STABLE_DIFFUSION_CHECKPOINTS[DEFAULT_CHECKPOINT],
  weights: DEFAULT_WEIGHTS,
  compositionReferenceWeights: DEFAULT_WEIGHTS,
  controlnetReferenceWeights: DEFAULT_WEIGHTS,
  faceReferenceWeights: DEFAULT_WEIGHTS,
  imageReferenceWeights: DEFAULT_WEIGHTS,
  styleReferenceWeights: DEFAULT_WEIGHTS,
  guidance: DEFAULT_GUIDANCE,
  steps: DEFAULT_STEPS,
  denoise: DEFAULT_DENOISE,
  creativityScale: DEFAULT_CREATIVITY_SCALE,
}

// LABELS - used for human readbility in UIs
export const LABELS = {
  ASSEMBLE: 'Assemble',
  AUDIO: 'Audio',
  AUDIO_REACTIVITY: 'Audio Reactivity',
  CHECKPOINT_SELECTOR: 'Select Checkpoint',
  COMPOSITION_REFERENCE: 'Layout',
  CONTROLNET_REFERENCE: 'Stencil',
  CROPPER: 'Cropper',
  EVOLVE: 'Evolve',
  FACE_REFERENCE: 'Face',
  FLIPBOOK_V1: 'Flipbook V1',
  FLIPBOOK_V1_SETTINGS: 'Flipbook V1 Settings',
  IMAGE: 'Image',
  IMAGE_LAB: 'Image Lab',
  IMAGE_REFERENCE: 'Image Reference',
  IMAGE_SETTINGS: 'Image Settings',
  IMAGE_UPLOAD: 'Image Upload',
  INIT_IMAGE: 'Init Image',
  MOTION_V2: 'Motion 2.0',
  MOTION_V2_SETTINGS: 'Motion 2.0 Settings',
  MOTION_V3: 'Motion 3.0',
  MOTION_V3_SETTINGS: 'Motion 3.0 Settings',
  IMAGE_EDIT_SETTINGS: 'ImageEdit Settings',
  PULID_FACE_REFERENCE: 'PulID Face',
  STYLE_REFERENCE: 'Aesthetic',
  TRANSFORM_V3: 'Transform V3',
  TRANSFORM_V3_SETTINGS: 'Transform V3 Settings',
  VIDEO_LAB: 'Video Lab',
  VIDEO_UPLOAD: 'Video Upload',
  IMAGE_DETAILS: 'Image Details',
  VIDEO_DETAILS: 'Video Details',
  REFERENCE_IMAGE: 'Reference Image',
  SUBJECT_PROMPT: 'Subject',
  FLUX_IMAGE_SETTINGS: 'Flux Image Settings',
  UPSCALE_V1_SETTINGS: 'Upscale 1.0 Settings',
  INPAINT_PROMPT: 'Fill',
  MOTION_MASK: 'Motion Mask',
}

// GENERAL
export const MEDIAS_FETCH_SIZE = 20
export const MEDIA_TYPES = {
  Assemble: 'Image',
  Cropper: 'Image',
  ImageLab: 'Image',
  MotionV2: 'Image',
  MotionV3: 'Video',
  TransformV3: 'Video',
  FlipbookV1: 'Video',
  InpaintV1: 'Image',
  FluxImage: 'Image',
  UpscaleV1: 'Image',
}

export const LENGTH_CONFIG = {
  MOTION_V2: {
    min: 3,
    max: 160,
  },
  MOTION_V3: {
    min: 3,
    max: 16,
  },
  MOTION_V3_WITH_AUDIO: {
    min: 3,
    max: 240,
  },
  TRANSFORM_V2: {
    min: 3,
    max: 240,
  },
  TRANSFORM_V3: {
    min: 3,
    max: 240,
  },
  FLIPBOOK_V1: {
    min: 3,
    max: 480,
  },
}

export const WEIGHTS = 'Weights'

// Keyboard hotkeys
export enum HOTKEYS {
  FIT_VIEW,
  SELECT_ALL,
  COPY,
  PASTE,
  ENTER,
  NONE,
}

export enum DEFAULT_THEME {
  Light = 'light',
  Dark = 'dark',
}

export const RATIO_LEFT_SETTING_CONFIG: RatioSettingConfig[] = [
  { id: 1, ratio: { w: 16, h: 9 }, label: 'Youtube', width: 96, height: 54 },
  { id: 2, ratio: { w: 4, h: 3 }, label: 'Instagram', width: 96, height: 72 },
  { id: 3, ratio: { w: 3, h: 4 }, label: 'Instagram', width: 96, height: 128 },
]

export const RATIO_RIGHT_SETTING_CONFIG: RatioSettingConfig[] = [
  { id: 4, ratio: { w: 1, h: 1 }, label: 'Instagram', width: 96, height: 96 },
  {
    id: 5,
    ratio: { w: 9, h: 16 },
    label: 'TikTok, Instagram, Spotify, Canvas',
    width: 54,
    height: 96,
  },
]
