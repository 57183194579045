import SectionTitle from './SectionTitle'
import OdkComponent from './OdkComponent'
import Button from './Button'
import { Link } from 'react-router-dom'

function SectionOdk() {
  return (
    <div className='flex flex-col flex-1 max-w-[1440px] w-full items-center md:px-[3.5%] px-4'>
      <SectionTitle
        title='Enter the world of Kaiber Studio'
        description='Where artistry meets technological innovation'
      />
      <OdkComponent
        text1A={'Linkin Park'}
        text1B={'“Lost”'}
        text2A={'Mike Shinoda'}
        text2B={`'In My Head'`}
        text3A={'Kid Cudi'}
        text3B={'“Entergalactic”'}
        videoUrl1={'https://youtu.be/7NK_JOkuSVY'}
        videoUrl2={'https://youtu.be/bGyH1JF1Nu4'}
        videoUrl3={'https://youtu.be/zr-PTLqbYqo'}
      />
      <Link to='/studio'>
        <Button btnText='Kaiber Studio' />
      </Link>
    </div>
  )
}

export default SectionOdk
