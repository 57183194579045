import { useCallback, useEffect, useRef } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { MOTION_HERO } from '../utils/constants'

const kMask = '/website/motion/k-mask-clip.svg'

export default function Motion({ isAuthenticated }) {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const handleWindowResize = useCallback(() => {
    const newVideoSrc = getVideoSource(window.innerWidth)
    const currentVideoSrc = playerRef.current.currentSrc()
    const currentTime = playerRef.current.currentTime()

    if (newVideoSrc.src !== currentVideoSrc) {
      playerRef.current.src(newVideoSrc)
      playerRef.current.ready(() => {
        playerRef.current.currentTime(currentTime)
        playerRef.current.play()
      })
    }
  }, [])

  useEffect(() => {
    playerRef.current = videojs(videoRef.current, {
      autoplay: true,
      controls: false,
      loop: true,
      muted: true,
      html5: {
        hls: {
          overrideNative: true,
        },
      },
    })

    const videoSrc = getVideoSource(window.innerWidth)
    playerRef.current.src(videoSrc)

    // Enable autoplay on user interaction, necessary for mobile devices
    const handleUserInteraction = () => {
      playerRef.current.play()
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
      window.removeEventListener('scroll', handleUserInteraction)
    }

    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('touchstart', handleUserInteraction)
    window.addEventListener('scroll', handleUserInteraction)
    window.addEventListener('resize', handleWindowResize)

    return () => {
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
      window.removeEventListener('resize', handleWindowResize)
      window.removeEventListener('scroll', handleUserInteraction)
      playerRef.current.dispose()
    }
  }, [handleWindowResize])

  const getVideoSource = (windowWidth) => {
    return {
      src: MOTION_HERO,
      type: 'application/x-mpegURL',
    }
  }

  return (
    <div className='bg-black'>
      <Navbar />

      <div className='relative px-0 overflow-hidden text-tertiary section pt-[74px] h-[274px] md:h-[459px] mt-[75px] md:mt-[94px] flex items-center'>
        <video
          ref={videoRef}
          className='mt-[-100px] select-none pointer-events-none video-js vjs-default-skin absolute left-50% transform -translate-x-50% sm:min-w-[177.77vh] sm:min-h-[56.25vw] min-h-[177.77vw] min-w-[56.25vh] sm:aspect-video aspect-[9/16] w-screen h-screen vjs-controls-disabled vjs-workinghover vjs-v8 vjs-has-started vjs_video_3-dimensions vjs-playing vjs-user-inactive'
          playsInline
          muted
        />

        <div className='w-full flex justify-center'>
          <div className='max-w-[1200px] w-full'>
            <h1 className='m-0 text-[48px] md:text-[96px] text-tertiary leading-[125%] relative mt-[-86px]'>
              Discover Motion
            </h1>
          </div>
        </div>
      </div>

      <div className='md:py-[140px] pt-[32px] pb-[120px] flex justify-center'>
        <h2 className='max-w-[80%] md:max-w-[790px] w-full md:text-5xl text-tertiary text-center leading-tight'>
          Welcome to Motion by Kaiber, transforming your static text into
          dynamic animations
        </h2>
      </div>

      <div className='mb-[120px] md:mb-[140px] flex justify-center mx-[20px] md:mx-0'>
        <div className='flex max-w-[1890px] w-full items-center md:items-start relative md:h-[865px] flex-col-reverse md:justify-center'>
          <div className='md:ml-[5%] xl:ml-[120px] relative z-[1] max-w-[789px] w-full md:w-[70%]'>
            <h2 className='text-tertiary xl:text-5xl font-medium leading-tight mt-[30px]'>
              Meet Motion - The Next Chapter in Content Creation
            </h2>
            <p className='text-tertiary text-base xl:text-2xl my-6'>
              Just type in your text or upload your favorite image and watch it
              turn into fluid, attention-grabbing animations. Welcome to the
              limitless potential of Motion.
            </p>
            <a
              className='text-black bg-kaiberGreen rounded-full py-3 px-10 text-base xl:text-2xl font-semibold'
              href={isAuthenticated ? '/create' : '/register'}
            >
              Get Started
            </a>
          </div>

          <div className='md:absolute right-0 self-end'>
            <div className='relative pt-0 w-[295px] h-[458px] md:w-[545px] md:h-[708px] overflow-hidden rounded-[33px] md:rounded-[60px]'>
              <iframe
                className='select-none pointer-events-none w-full h-[524px] md:h-[965px] absolute bottom-0 left-0'
                title='Gumlet video player'
                src='https://play.gumlet.io/embed/64d13189c28971a733908e86'
                allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture'
                allowFullScreen='false'
              ></iframe>
              <img
                className='h-full absolute top-0 left-[-1px] md:left-0'
                src={kMask}
                alt='unveil'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mb-[120px] md:mb-[140px] flex justify-center'>
        <div className='flex flex-col md:flex-row max-w-[1026px] w-full items-center justify-center relative'>
          <div className='w-[90%]'>
            <div className='relative w-full pt-[142%] md:pt-0 md:w-[460px] md:h-[654px] overflow-hidden'>
              <iframe
                className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[50px]'
                title='Gumlet video player'
                src='https://play.gumlet.io/embed/64d132c6c28971a7339093fc'
                allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className='md:ml-[120px] relative z-[1] w-full mt-[20px] text-center md:text-left max-w-[90%]'>
            <h2 className='text-tertiary text-3xl md:text-5xl font-medium leading-tight max-w-[300px] md:max-w-[400px] m-auto md:m-0'>
              Animations from Text Made Easy
            </h2>
          </div>
        </div>
      </div>

      <div className='mb-[120px] md:mb-[140px]'>
        <div className='relative flex flex-col items-center justify-center'>
          <div className='w-[90%] max-w-[1200px]'>
            <div className='relative w-full pt-[50%] md:w-full overflow-hidden'>
              <iframe
                className='select-none pointer-events-none aspect-[9/16] w-full h-full absolute bottom-0 left-0 rounded-[30px]'
                loading='lazy'
                title='Gumlet video player'
                src='https://play.gumlet.io/embed/64d13373c28971a73390973a'
                allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className='text-center max-w-[800px] m-auto mt-4 mx-3'>
            <h2 className='text-tertiary xl:text-5xl font-medium leading-tight mt-[30px]'>
              Streamline Your Animation Creation with Motion
            </h2>
            <p className='text-tertiary text-base xl:text-2xl my-6'>
              Leave behind complex software and technical hurdles. With Motion,
              crafting impressive animations is effortless.
            </p>
            <a
              className='text-black bg-kaiberGreen rounded-full py-3 px-6 text-base xl:text-2xl font-semibold'
              href={isAuthenticated ? '/create' : '/register'}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>

      <div className='mb-[120px] md:mb-[140px] flex justify-center'>
        <div className='flex flex-col md:flex-row max-w-[1210px] w-full items-center justify-center relative'>
          <div className='w-[90%] max-w-[460px]'>
            <div className='relative w-full pt-[178%] md:pt-0 md:w-[460px] md:h-[816px] overflow-hidden'>
              <iframe
                className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[50px]'
                title='Gumlet video player'
                src='https://play.gumlet.io/embed/64d1349dc28971a73390a127'
                allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className='md:ml-[40px] relative z-[1] w-full mt-[20px] text-center md:text-left max-w-[90%]'>
            <h2 className='mb-6 text-tertiary text-3xl md:text-5xl font-normal leading-tight max-w-[300px] md:max-w-[400px]'>
              Key Features
            </h2>
            <ul className='list-disc ml-6'>
              <li className='text-tertiary text-base pl-2.5 mb-4 text-left'>
                <p className='text-base md:text-2xl font-light'>
                  <span className='font-semibold'>
                    Animate Your Text or Images:
                  </span>{' '}
                  Simply enter your text and Motion will transform it into
                  engaging animations.
                </p>
              </li>
              <li className='text-tertiary text-base pl-2.5 mb-4 text-left'>
                <p className='text-base md:text-2xl font-light'>
                  <span className='font-semibold'>
                    Breathe Life into Images:
                  </span>{' '}
                  Use Motion to give your pictures a dynamic new dimension.
                </p>
              </li>
              <li className='text-tertiary text-base pl-2.5 mb-4 text-left'>
                <p className='text-base md:text-2xl font-light'>
                  <span className='font-semibold'>Animation Made Easy:</span>{' '}
                  Say goodbye to complicated software and steep learning curves.
                </p>
              </li>
              <li className='text-tertiary text-base pl-2.5 mb-4 text-left'>
                <p className='text-base md:text-2xl font-light'>
                  <span className='font-semibold'>Complete Control:</span> You
                  decide the level of animation to add to your text and images.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='mb-[120px] md:mb-[140px] flex justify-center'>
        <div className='flex max-w-[1210px] w-full items-center relative justify-between flex-col-reverse md:flex-row'>
          <div className='mr-0 md:mr-[41px] relative z-[1] max-w-[90%] md:max-w-[678px] w-full'>
            <h2 className='text-tertiary xl:text-5xl font-medium leading-tight mt-[30px]'>
              Join the Kaiber Community
            </h2>
            <p className='text-tertiary text-base xl:text-2xl my-6'>
              Be part of a network of creators passionate about making
              compelling content. Share your work, get feedback, and find
              inspiration from other creators.
            </p>
            <a
              className='text-black bg-kaiberGreen rounded-full py-3 px-6 text-base xl:text-2xl font-semibold'
              href='https://discord.com/invite/kaiber'
            >
              Join Kaiber’s Discord
            </a>
          </div>

          <div className='w-[90%]'>
            <div className='relative w-full pt-[178%] md:pt-0 md:w-[460px] md:h-[817px] overflow-hidden'>
              <iframe
                className='select-none pointer-events-none w-full h-full absolute bottom-0 left-0 rounded-[50px]'
                title='Gumlet video player'
                src='https://play.gumlet.io/embed/64d1352ffbee34e5447c1b66'
                allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className='mb-[150px] md:mb-[270px] flex justify-center'>
        <div className='flex flex-col md:flex-row max-w-[1890px] w-full items-center relative md:h-[788px]'>
          <div className='md:absolute left-0 self-start'>
            <div className='relative pt-0 md:w-[445px] md:pt-[177.67%] overflow-hidden md:rounded-tr-[200px] md:rounded-br-[200px] rounded-tl-[0px] rounded-bl-[0px] h-[456px] p-0 rounded-tr-[100px] rounded-br-[100px] w-[260px]'>
              <iframe
                className='select-none pointer-events-none absolute top-0 w-full h-[550px] md:h-full'
                title='Gumlet video player'
                src='https://play.gumlet.io/embed/64d135bbc28971a73390a724'
                allow='accelerometer gyroscope autoplay encrypted-media picture-in-picture fullscreen'
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className=' md:ml-[525px] mt-[20px] relative z-[1] max-w-[90%] m-auto md:m-0 md:max-w-[789px] w-full '>
            <h2 className='text-tertiary xl:text-5xl font-medium leading-tight mb-8 md:max-w-[90%]'>
              Have Questions? We’ve got answers
            </h2>
            <a
              className='text-black bg-kaiberGreen rounded-full py-3 px-[70px] text-base xl:text-2xl font-semibold'
              href='https://helpcenter.kaiber.ai/kaiber/en/articles/8225490-a-deep-dive-into-kaiber-s-motion'
            >
              See our FAQ
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
