/* eslint-disable */
// TODO - fix eslint errors related to loading patterns here

// MY VIDEOS PAGE
import { useState, useReducer, useEffect, useRef, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'

import http from '../services/HttpService'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import VideoDeletionModal from '../components/VideoDeletionModal'
import VideoInfoModal from '../components/VideoInfoModal'
import { UpscaleModal } from '../components/UpscaleModal'
import DownloadModal from '../components/DownloadModal'
import { VideoGallery } from '../components/VideoGallery'
import { SearchBar } from '../components/SearchBar'
import { useVideoEventsContext } from '../context/videoEventsContext'
import { isMobile } from '../utils'

export const Dashboard = ({ hideNav = false, username }) => {
  // stores list of videos
  const [videosList, setVideosList] = useState([])
  const videosListRef = useRef(videosList)
  videosListRef.current = videosList

  let currentIndex = 0

  // stores videos for gallery state
  const [videos, setVideos] = useState([])
  const [isVideoDeletionModalActive, setIsVideoDeletionModalActive] =
    useState(false)
  const [videoDeletionId, setVideoDeletionId] = useState(null)
  const [isVideoInfoModalActive, setIsVideoInfoModalActive] = useState(false)
  const [videoInfoSettingsUsed, setVideoInfoSettingsUsed] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [offsetDescription, setOffsetDescription] = useState(null)
  const [hasPagination, setHasPagination] = useState(false)
  const [isUpscalingActive, setIsUpscalingActive] = useState(false)
  const [memory, setMemory] = useState(false)
  const [canvasIsUpscaling, setCanvasIsUpscaling] = useState([])
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)
  const [isMakingPublic, setIsMakingPublic] = useState(false)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef(null)

  const { trackVideos } = useVideoEventsContext()

  const getVideos = useCallback(
    async (page = 1, query = null) => {
      setIsLoading(true)

      if (page === 1 && videos.length > 0) {
        setVideos([])
        setCurrentPage(1)
      }

      try {
        let perPage = 20
        if (isMobile()) {
          perPage = 12
        }

        // concurrent_loading=true to skip the signed S3 URLs for this API call, and instead
        // get them as separate API calls for each gallery item.
        // Added this to ensure /get_gallery_videos is backwards compatible with the mobile app.
        const { data } = await http.get(
          `/api/get_videos_for_user?concurrent_loading=true&page=${page}&per_page=${perPage}`,
          {
            params: {
              q: query ?? searchInputRef?.current?.value ?? '',
              username,
            },
          },
        )

        let originalVideosList = data.items
        currentIndex = 0

        setVideosList(originalVideosList)
        setVideos(originalVideosList)
        setTotalPages(data.totalPages)

        // for pagination
        setTotalItems(data.totalItems)
        setOffsetDescription(data.offsetDescription)
        setHasPagination(data.hasPagination)

        trackVideos(data.items)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [setVideos, setIsLoading, searchInputRef, videos],
  )

  useEffect(() => {
    getVideos(currentPage)
    // note: do not include getVideos in dependency array otherwise infinite calls
  }, [])

  const handleDownloadOpen = (memory) => {
    setMemory(memory)
    setIsDownloadOpen(true)
  }

  const toggleUpscaleModal = (_memory) => {
    setMemory(_memory)
    setIsUpscalingActive(!isUpscalingActive)
  }

  const handleOnCloseUpscaleModal = (memoryId = false) => {
    if (memoryId) {
      const arr = canvasIsUpscaling
      arr.push(memoryId)
      setCanvasIsUpscaling(arr)
    }
    setIsUpscalingActive(false)
  }

  const handleIsUpscaling = (memoryId) => {
    const currentVideo = videos.find((video) => video.memory_id === memoryId)

    return (
      (currentVideo &&
        currentVideo.upscale_status &&
        currentVideo.upscale_status === 'pending') ||
      canvasIsUpscaling.findIndex((id) => id === memoryId) >= 0
    )
  }

  const handleUpscaleSuccess = async (memoryId) => {
    const index = videos.findIndex((v) => v.memory_id === memoryId)
    if (index) {
      const response = await http.get('/api/get_memory/' + memoryId)
      if (response) {
        videos[index] = response.data.memory
      }
    }

    const arr = canvasIsUpscaling
    let ind = arr.findIndex((id) => id === memoryId)
    if (ind >= 0) arr.splice(ind, 1)
    setCanvasIsUpscaling(arr)
    await new Promise((resolve) => setTimeout(resolve, 100))
    forceUpdate()
  }

  const handleUpscaleError = async (memoryId) => {
    const index = videos.findIndex((v) => v.memory_id === memoryId)
    if (index >= 0) {
      const response = await http.get('/api/get_memory/' + memoryId)
      if (response) {
        videos[index] = response.data.memory
      }
    }

    const arr = canvasIsUpscaling
    let ind = arr.findIndex((id) => id === memoryId)
    arr.splice(ind, 1)
    setCanvasIsUpscaling(arr)
    forceUpdate()
  }

  const toggleVideoDeletionModal = (isOpen, videoId) => {
    setIsVideoDeletionModalActive(isOpen)
    setVideoDeletionId(videoId || null)
  }

  const toggleVideoInfoModal = (isOpen, settingsUsed) => {
    setIsVideoInfoModalActive(isOpen)
    setVideoInfoSettingsUsed(settingsUsed)
  }

  // for delete
  const removeVideoFromUI = (videoId) => {
    setVideos((videos) => videos.filter((v) => v._id !== videoId))
  }

  const handlePageClick = (event) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    const page = event.selected + 1
    setCurrentPage(page)

    if (videos.length > 0) {
      // loop through videos and remove them from the DOM
      for (let i = 0; i < videos.length; i++) {
        if (!!document.getElementById(videos[i]._id)) {
          const videoElement = document.getElementById(videos[i]._id)
          const sourceElement = document
            .getElementById(videos[i]._id)
            .getElementsByTagName('source')[0]
          if (sourceElement) {
            videoElement.pause()
            sourceElement.src = ''
            videoElement.load()
            sourceElement.remove()
            videoElement.remove()
          }
        }
      }
    }

    // resetting the state to clear videos breaks scrollTo() (for some unknown reason) on Firefox and iOS Safari.
    // Use a timeout to delay the state change.
    setTimeout(() => {
      currentIndex = 0
      setVideos([])
      setVideosList([])

      getVideos(page, searchText)
    }, 250)
  }

  const handleSearchVideos = (page, searchText) => {
    currentIndex = 0
    setVideos([])
    setVideosList([])

    getVideos(page, searchText)
  }

  const handleVideoStatusChange = (videoId, status, url) => {
    setVideos((videos) =>
      videos.map((v) => (v._id === videoId ? { ...v, status, url } : v)),
    )
  }

  const handleMakePublic = async (videoId) => {
    if (isMakingPublic) return
    setIsMakingPublic(true)

    try {
      const res = await http.post(`/api/make_public/${videoId}`)
      const { is_public } = res.data

      if (res.status === 200) {
        toast.success(
          `Your video is now ${is_public ? 'public 🎉' : 'private 🔒'}`,
        )
        setVideos((videos) =>
          videos.map((v) => (v._id === videoId ? { ...v, is_public } : v)),
        )
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.error || err.response.data)
      } else if (err?.message) {
        toast.error(err.message)
      } else {
        toast.error('Something went wrong.')
      }
    } finally {
      setIsMakingPublic(false)
    }
  }

  return (
    <div className='bg-black'>
      {!hideNav && (
        <Navbar
          searchRef={searchInputRef}
          getVideos={handleSearchVideos}
          isLoading={isLoading}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      )}
      <div className='py-10 section pt-[74px]'>
        <div className='container px-5 pt-12 mx-auto space-y-8 max-w-7xl'>
          <div className='flex flex-col justify-center sm:justify-between sm:flex-row'>
            <h2 className='pb-6 text-3xl font-semibold text-center sm:text-left sm:pb-0 text-tertiary'>
              {!username && 'My'} Videos
            </h2>

            {/* SEARCH */}
            {(videos.length !== 0 || searchText != null) && (
              <SearchBar
                ref={searchInputRef}
                getVideos={handleSearchVideos}
                isLoading={isLoading}
                setSearchText={setSearchText}
                searchText={searchText}
                className='w-96'
              />
            )}

            {/* <a href='/create'>
              <button className='inline-flex items-center gap-3 px-4 py-3 text-base font-medium text-black uppercase border border-transparent rounded-full shadow-sm bg-primary hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'>
                <PlusIcon className='w-6 h-6' /> Create New
              </button>
            </a> */}
          </div>

          <VideoGallery
            videos={videos}
            getVideos={getVideos}
            setSearchText={setSearchText}
            searchInputRef={searchInputRef}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            isLoading={isLoading}
            isDashboard
            username={username}
            onVideoStatusChange={handleVideoStatusChange}
            onDelete={toggleVideoDeletionModal}
            onInfo={toggleVideoInfoModal}
            onMakePublic={handleMakePublic}
            onUpscaleOpen={toggleUpscaleModal}
            isUpscaling={handleIsUpscaling}
            isMakingPublic={isMakingPublic}
            onUpscaleSuccess={handleUpscaleSuccess}
            onUpscaleError={handleUpscaleError}
            onDownload={handleDownloadOpen}
          />

          {/* PAGINATION */}
          <div>
            {offsetDescription && hasPagination && (
              <div className='mb-5 text-center'>
                <span className='text-gray-500'>{offsetDescription}</span>
              </div>
            )}

            {!(videos.length === 0 && searchText === null) &&
              hasPagination &&
              totalItems > 0 && (
                <ReactPaginate
                  forcePage={currentPage - 1}
                  breakLabel='...'
                  previousLabel={<ChevronDoubleLeftIcon className='w-6 h-6' />}
                  nextLabel={<ChevronDoubleRightIcon className='w-6 h-6' />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalPages}
                  renderOnZeroPageCount={null}
                  marginPagesDisplayed={2}
                  containerClassName='flex flex-wrap justify-center gap-2'
                  breakClassName='page-item'
                  pageClassName='page-item'
                  previousClassName='page-item'
                  nextClassName='page-item'
                  activeClassName='active'
                  breakLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                  pageLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block w-10 h-10'
                  previousLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                  nextLinkClassName='border border-primary/25 p-2 rounded-lg text-white text-center block h-10'
                  activeLinkClassName='radial-gradient shadow-sm text-gray-900 font-bold'
                  disabledLinkClassName='border-gray-900 text-gray-700'
                />
              )}
          </div>
        </div>
      </div>
      <Footer />

      {/* MODALS */}
      <VideoDeletionModal
        isOpen={isVideoDeletionModalActive}
        videoId={videoDeletionId}
        onClose={toggleVideoDeletionModal}
        onDelete={removeVideoFromUI}
      />
      <VideoInfoModal
        settingsUsed={videoInfoSettingsUsed}
        isOpen={isVideoInfoModalActive}
        onClose={toggleVideoInfoModal}
      />
      <DownloadModal
        isOpen={isDownloadOpen}
        onClose={() => {
          setIsDownloadOpen(false)
        }}
        memory={memory}
      />
      <UpscaleModal
        isOpen={isUpscalingActive}
        onClose={handleOnCloseUpscaleModal}
        memory={memory}
        onSuccess={handleUpscaleSuccess}
        onError={handleUpscaleError}
      />
    </div>
  )
}
