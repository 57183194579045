import React from 'react'

interface ArrowRotateRightLeftIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string
}

export const ArrowRotateRightLeftIcon = React.forwardRef<
  SVGSVGElement,
  ArrowRotateRightLeftIconProps
>(({ size = `32`, ...props }, ref) => (
  <svg
    ref={ref}
    stroke='currentColor'
    fill='none'
    strokeWidth='0'
    viewBox='0 0 16 16'
    height={size}
    width={size}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.8412 2.5V4.66667C12.8412 4.94281 12.6174 5.16667 12.3412 5.16667H10.1745M3.16667 13.5V11.3333C3.16667 11.0572 3.39053 10.8333 3.66667 10.8333H5.83333M2.54255 8.68747C2.51447 8.46227 2.5 8.2328 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.7844 2.5 11.4074 3.34975 12.4215 4.66667M13.4575 7.31253C13.4855 7.53773 13.5 7.7672 13.5 8C13.5 11.0375 11.0375 13.5 8 13.5C6.21561 13.5 4.59258 12.6503 3.57856 11.3333'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
))
