import { useState, useEffect } from 'react'
import cookies from 'browser-cookies'
import { signInAndUpWithApple } from '../../services/AuthService'
import { Alert } from '../../components/Alert'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'
import LoadingWhite from '../../images/lottie/loaderWhite.json'

export default function AppleCallback() {
  const [isLoading, setIsLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)

  const handleAppleCallback = async () => {
    try {
      const query = new URLSearchParams(window.location.search)
      const code = query.get('code')
      const user = query.get('user')
      const error = query.get('error')

      if (code == null && error == null) {
        setIsLoading(false)
        setShowAlert(true)
        setAlertMessage('Oops! Something went wrong.')
      } else if (!code && error !== '' && error != null) {
        setIsLoading(false)
        setShowAlert(true)
        if (error === 'DATA_MISSING') {
          setAlertMessage(
            'We were unable to complete your request. Please try again shortly',
          )
        } else {
          setAlertMessage('Oops! Something went wrong.')
        }
      }

      let newsletter

      switch (window.sessionStorage.getItem('NEWSLETTER')) {
        case 'true':
          newsletter = true
          break
        case 'false':
          newsletter = false
          break
        default:
          newsletter = false
      }

      const referrerId = window.sessionStorage.getItem('REFERRER_ID')
      const referrerIdFromCookies = cookies.get('referrer_id')
      const rewardfulReferral = JSON.parse(cookies.get('rewardful.referral'))
      const rewardfulReferrerId = rewardfulReferral?.affiliate?.id // ID of the affiliate
      const rewardfulObjectId = rewardfulReferral?.id // ID of the referral

      await signInAndUpWithApple(
        window.location.search,
        newsletter,
        referrerId || referrerIdFromCookies,
        rewardfulReferrerId,
        rewardfulObjectId,
        code,
        user,
      )

      window.sessionStorage.removeItem('NEWSLETTER')

      if (referrerId) {
        window.sessionStorage.removeItem('REFERRER_ID')
      }

      const redirect = window.sessionStorage.getItem('LOGIN_REDIRECT_TO')

      if (redirect) {
        window.sessionStorage.removeItem('LOGIN_REDIRECT_TO')
        window.location.replace(window.location.origin + redirect)
      } else {
        window.location.replace('/dashboard')
      }
    } catch (err) {
      setIsLoading(false)
      setShowAlert(true)

      if (err.response) {
        setAlertMessage(
          err.response.data.message || 'Oops! Something went wrong.',
        )
      } else {
        setAlertMessage(
          'We were unable to complete your request. Please check your connection and try again.',
        )
      }
    }
  }

  useEffect(() => {
    handleAppleCallback()
  }, [])

  return (
    <div
      className={`sm:p-10 flex h-full ${
        isLoading ? 'bg-transparent' : 'bg-darkGray'
      } sm:bg-transparent overflow-y-auto`}
    >
      {isLoading ? (
        <div className='flex flex-col items-center space-y-4 m-auto'>
          <div className='bg-darkGray p-6 rounded-3xl'>
            <Lottie
              animationData={LoadingWhite}
              loop={true}
              className='w-16 h-16'
            />
          </div>
        </div>
      ) : (
        <div className='w-full sm:max-w-sm bg-darkGray sm:rounded-3xl m-auto p-10'>
          {showAlert && (
            <>
              <div className='mb-4'>
                <Alert open={showAlert} message={alertMessage} type='alert' />
              </div>

              <div className='text-center'>
                <Link
                  to='/login'
                  className='text-tertiary/80 hover:text-primary'
                >
                  Back to login page
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
