import { useState, useCallback, useEffect } from 'react'

interface UseHorizontalResizerProps {
  containerRef: React.RefObject<HTMLElement>
  minWidth: number
  maxWidth: number
  initialWidth: number
  resizerWidth?: number
  useFadeBar?: boolean
}

interface UseHorizontalResizerReturn {
  width: number
  isResizing: boolean
}

export const useHorizontalResizer = ({
  containerRef,
  minWidth,
  maxWidth,
  initialWidth,
  resizerWidth = 16,
  useFadeBar = false,
}: UseHorizontalResizerProps): UseHorizontalResizerReturn => {
  const [width, setWidth] = useState(initialWidth)
  const [isResizing, setIsResizing] = useState(false)

  const handleResizeStart = useCallback((e: MouseEvent) => {
    e.preventDefault()
    setIsResizing(true)
  }, [])

  const handleResizeEnd = useCallback(() => {
    setIsResizing(false)
  }, [])

  const handleResize = useCallback(
    (e: MouseEvent) => {
      if (!isResizing || !containerRef.current) return

      const containerRect = containerRef.current.getBoundingClientRect()
      const newWidth = Math.max(
        minWidth,
        Math.min(maxWidth, containerRect.right - e.clientX),
      )
      setWidth(newWidth)
    },
    [isResizing, minWidth, maxWidth, containerRef],
  )

  useEffect(() => {
    if (!containerRef.current) return

    containerRef.current.style.width = `${width}px`

    const resizerElement = document.createElement('div')
    resizerElement.style.cssText = `
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: ${resizerWidth}px;
      cursor: ew-resize;
      ${useFadeBar ? '' : 'opacity: 0;'}
    `
    resizerElement.className = `
      rounded-tl-xl rounded-bl-xl
      transition-all duration-200 ease-in-out
    `

    if (useFadeBar) {
      const updateGradient = () => {
        const computedStyle = window.getComputedStyle(containerRef.current!)
        const bgColor = computedStyle.backgroundColor
        const rgb = bgColor.match(/\d+/g)!.map(Number)
        const lighterRgb = rgb.map((val) => Math.min(255, val + 20))
        resizerElement.style.backgroundImage = `linear-gradient(to right, rgb(${lighterRgb.join(',')}), transparent)`
      }

      resizerElement.addEventListener('mouseenter', updateGradient)
      resizerElement.addEventListener('mouseleave', () => {
        resizerElement.style.backgroundImage = 'none'
      })

      // Initial gradient setup
      updateGradient()
    }

    resizerElement.addEventListener('mousedown', handleResizeStart)

    containerRef.current.appendChild(resizerElement)

    return () => {
      resizerElement.removeEventListener('mousedown', handleResizeStart)
      if (useFadeBar) {
        resizerElement.removeEventListener('mouseenter', () => {})
        resizerElement.removeEventListener('mouseleave', () => {})
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeChild(resizerElement)
    }
  }, [width, resizerWidth, useFadeBar, handleResizeStart, containerRef])

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleResize)
      window.addEventListener('mouseup', handleResizeEnd)
    }
    return () => {
      window.removeEventListener('mousemove', handleResize)
      window.removeEventListener('mouseup', handleResizeEnd)
    }
  }, [isResizing, handleResize, handleResizeEnd])

  return { width, isResizing }
}
