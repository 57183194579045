import { useRef, useCallback } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { LiaUserEditSolid } from 'react-icons/lia'

import { cn } from '../utils'

export const ProfilePicture = ({ canEdit, onUpdate, src }) => {
  const fileInputRef = useRef(null)

  const containerClasses = cn(
    'relative aspect-square rounded-2xl overflow-hidden group w-1/2 sm:w-full',
    { 'cursor-default': !canEdit },
  )

  const imageClasses = cn(
    'absolute inset-0 w-full h-full object-cover ease-in-out transition-all duration-500',
    {
      'group-hover:opacity-80 group-hover:scale-[1.03]': canEdit,
    },
  )

  const iconContainerClasses = cn(
    'absolute right-0 bottom-0 p-2 ease-in-out transition-all duration-500',
    {
      'md:opacity-0 group-hover:cursor-pointer md:group-hover:opacity-100':
        canEdit,
      hidden: !canEdit,
    },
  )

  const handleImageUpload = useCallback(
    async (event) => {
      const file = event.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append('profilePicture', file)

        try {
          const response = await axios.patch(
            'api/v1/profile/picture',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )

          toast.success('Profile image updated successfully', response)
          onUpdate({ profilePicture: response.data.profilePicture })
        } catch (error) {
          toast.error('Error updating profile image', error)
        }
      }
    },
    [onUpdate],
  )

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  const isCurrentUserProfile = window.location.pathname === '/dashboard'
  const defaultProfilePicture = `${isCurrentUserProfile ? '.' : '..'}/images/7db18bce4420ed0bd83b6efbfd6ed6bf-community_art2.png`

  return (
    <button
      className={containerClasses}
      onClick={canEdit ? triggerFileInput : undefined}
    >
      <img
        className={imageClasses}
        src={src || defaultProfilePicture}
        alt='Profile'
      />
      <div className={iconContainerClasses}>
        <LiaUserEditSolid className='w-6 h-6 text-kaiberGreen' />
        <input
          ref={fileInputRef}
          type='file'
          className='hidden'
          accept='image/*'
          onChange={handleImageUpload}
        />
      </div>
    </button>
  )
}
