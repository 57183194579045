import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'

function MoreFeaturesItem({
  image,
  title,
  description,
  pathUrl,
  linkText,
  orderText,
  orderImg,
}) {
  return (
    <div className='flex flex-1 sm:flex-row flex-col gap-20 items-center justify-center'>
      <div className={`flex order-first ${orderImg}`}>
        <img
          className='max-h-[435px] rounded-3xl'
          src={image}
          alt='Kaiber Feature'
        />
      </div>

      <div
        className={`flex flex-1 w-full flex-shrink-0 flex-col max-w-md sm:min-w-[300px] order-last ${orderText}`}
      >
        <div className='flex flex-col gap-4 '>
          <h1 className='text-white lg:text-4xl sm:text-3xl text-2xl'>
            {title}
          </h1>
          <p className='text-white/50 lg:text-3xl sm:text-2xl text-xl'>
            {description}
          </p>
        </div>
        <Link className='inline-flex mt-10 items-center' to={`${pathUrl}`}>
          <div className='inline-flex font-semibold lg:text-3xl sm:text-2xl text-xl text-primary hover:text-primary/90 active:text-primary/40 duration-300 '>
            <span>{linkText}</span>
            <IoIosArrowForward className='mt-1 lg:text-4xl sm:text-3xl text-2xl' />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default MoreFeaturesItem
