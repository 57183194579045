import { useFormContext } from 'react-hook-form'

import { PromptV2 } from './PromptV2'
import { LABELS } from '../../../../constants'
import { FieldType } from '../../../../types'
import { useInputHandlers } from '../../../../utils/inputUtils'

export const FillPrompt = () => {
  const { register } = useFormContext()
  const { handleFocusCapture, handleBlurCapture } = useInputHandlers()

  return (
    <PromptV2 label={LABELS.INPAINT_PROMPT}>
      <span className='italic text-gray-300 font-semibold text-base'>Fill</span>
      <textarea
        onFocusCapture={handleFocusCapture}
        onBlurCapture={handleBlurCapture}
        className='h-16 w-full bg-transparent outline-none resize-none'
        {...register(FieldType.MaskPrompt)}
      />
    </PromptV2>
  )
}
