import Navbar from '../../Navbar'
import Footer from '../../Footer'

function Blog() {
  return (
    <div className='bg-black'>
      <Navbar />
      <main className='flex flex-col mx-auto flex-1 max-w-[1440px] w-full md:px-[3.5%] px-4 gap-24 pt-28'>
        TEST TEST
      </main>
      <Footer />
    </div>
  )
}

export default Blog
