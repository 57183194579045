import { Rect, useReactFlow, XYPosition } from '@xyflow/react'
import { useCallback } from 'react'

import { DEFAULT_NODE_HEIGHT, DEFAULT_NODE_WIDTH } from '../../constants'

/**
 * This hook offers helper functions for determining the nodes' positions.
 */

export const useNodePosition = () => {
  const { screenToFlowPosition, getNode } = useReactFlow()

  /**
   * Calculates the position for a new node on a canvas based on the existing nodes' positions or a specified target position.
   */
  const getNewNodePosition = useCallback(
    (targetPosition?: XYPosition) => {
      if (targetPosition) {
        const position: XYPosition = {
          x: targetPosition.x,
          y: targetPosition.y,
        }
        return { position }
      }

      const x = window.innerWidth / 2
      const y = window.innerHeight / 2

      const position = screenToFlowPosition({ x, y })
      position.x -= DEFAULT_NODE_WIDTH / 2
      position.y -= DEFAULT_NODE_HEIGHT / 2

      return { position }
    },
    [screenToFlowPosition],
  )

  const getSelectionBound = useCallback(
    (selectedNodeIds: string[]): Rect => {
      const min = {
        x: Infinity,
        y: Infinity,
      }
      const max = {
        x: -Infinity,
        y: -Infinity,
      }

      selectedNodeIds.forEach((id) => {
        const node = getNode(id)
        const width = node.measured?.width ?? DEFAULT_NODE_WIDTH
        const height = node.measured?.height ?? DEFAULT_NODE_HEIGHT
        min.x = Math.min(min.x, node.position.x)
        min.y = Math.min(min.y, node.position.y)
        max.x = Math.max(max.x, node.position.x + width / 2)
        max.y = Math.max(max.y, node.position.y + height / 2)
      })

      const bound: Rect = {
        x: min.x,
        y: min.y,
        width: max.x - min.x,
        height: max.y - min.y,
      }

      return bound
    },
    [getNode],
  )

  return {
    getNewNodePosition,
    getSelectionBound,
  }
}
