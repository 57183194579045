import { Node, XYPosition, useReactFlow, Position } from '@xyflow/react'
import { memo, useCallback, useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { FormProvider, useForm } from 'react-hook-form'

import { ErrorNode } from './../ErrorNode'
import { MediaNodeData } from './../MediaNode'
import { FlowNodeHeader } from './FlowNodeHeader'
import { BORDER_COLORS, DEFAULT_BORDER_COLOR } from '../../../../constants'
import {
  DEFAULT_FORM_VALUES,
  DEFAULT_SUBJECT,
  MEDIA_CANVAS_DEFAULT_SIZE,
  MEDIA_TYPES,
  TEXT_URI_LIST,
} from '../../../../constants'
import { useCanvasContext, useThemeContext } from '../../../../context'
import { useInitialLoad } from '../../../../hooks'
import { cn } from '../../../../utils'
import {
  dispatchDropEvent,
  findNearestDroppableElement,
} from '../../../../utils/dndUtils'
import { isValidMediaType } from '../../../../utils/fileUtils'
import { CanvasFlow } from '../../Canvas/CanvasFlow'
import { CustomHandle } from '../../Canvas/CustomHandle'
import { FlowHeader } from '../../FlowHeader'
import {
  BaseComponentProps,
  ElementType,
  FieldType,
  FormOverrides,
  MediaForm,
  MediaType,
  ModelType,
  NodeType,
  Status,
} from '@/types'

export interface FlowNodeData extends Record<string, unknown> {
  modelType: ModelType
  name?: string
  slotId: string
  formValues?: MediaForm
  overrides?: FormOverrides // overrides is used to override formValues, currently used on AssembleNode
  status?: Status
  nodeIndex: number
  startCollapsed?: boolean
  elements: ElementType[]
  lastTempId?: string
}

export interface FlowNodeProps extends BaseComponentProps {
  id: string
  data: FlowNodeData
}

const BaseFlowNode: React.FC<FlowNodeProps> = memo((props) => {
  const {
    id,
    data: {
      modelType,
      formValues,
      overrides,
      nodeIndex,
      startCollapsed,
      elements = [],
      status,
    },
  } = props

  const methods = useForm({
    defaultValues: {
      // default form values should be determined by model type (required items only)
      ...DEFAULT_FORM_VALUES,
      ...formValues,
      type: MEDIA_TYPES[modelType] as MediaType,
    },
  })
  const { flowToScreenPosition, updateNode } = useReactFlow()
  const { initializeFlowElements, registerOnNodeIntersection } =
    useCanvasContext()
  const { colors } = useThemeContext()

  const [isHovering, setIsHovering] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed || false)

  useInitialLoad(async () => {
    if (!elements.length) {
      initializeFlowElements(id, modelType)
    }
  })

  /**
   * Handles the intersection of a node with another node
   */
  const handleNodeIntersection = useCallback(
    (selfNode: Node, intersectingNode: Node) => {
      if (intersectingNode.type !== NodeType.MediaNode) return

      const intersectingNodeScreenCenterPosition = flowToScreenPosition({
        x: intersectingNode.position.x + intersectingNode.measured.width / 2,
        y: intersectingNode.position.y + intersectingNode.measured.height / 2,
      })

      const nearestDroppableElement = findNearestDroppableElement(
        intersectingNodeScreenCenterPosition,
      )

      if (nearestDroppableElement) {
        const elementType = nearestDroppableElement.getAttribute(
          'data-element-type',
        ) as ElementType
        const { ImageUpload, VideoUpload } = ElementType
        const { type, source } = (intersectingNode.data as MediaNodeData).media

        if (
          [ImageUpload, VideoUpload].includes(elementType) &&
          isValidMediaType(type)
        ) {
          dispatchDropEvent(
            nearestDroppableElement,
            intersectingNodeScreenCenterPosition,
            {
              [TEXT_URI_LIST]: source,
            },
          )

          // Update the subject to intersecting node's subject
          const { subject } = formValues
          if (subject?.length === 0 || subject === DEFAULT_SUBJECT) {
            if (intersectingNode.data) {
              const mediaData = intersectingNode.data as MediaNodeData
              if (mediaData.media?.subject) {
                methods.setValue(FieldType.Subject, mediaData.media.subject)
              }
            }
          }

          // Snap the intersecting node back to where we started the drag operation
          if (intersectingNode.data.dragStartPosition) {
            updateNode(intersectingNode.id, {
              position: intersectingNode.data.dragStartPosition as XYPosition,
            })
          }
        }
      }
    },
    [formValues, flowToScreenPosition, updateNode, methods],
  )

  useEffect(() => {
    registerOnNodeIntersection(id, handleNodeIntersection)
  }, [handleNodeIntersection, id, registerOnNodeIntersection])

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false)
  }, [])

  return (
    <FormProvider {...methods}>
      <div
        style={{ minWidth: MEDIA_CANVAS_DEFAULT_SIZE }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FlowNodeHeader
          {...props}
          isHovering={isHovering}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <div
          className={cn(
            `collapsible-node relative border-2 ${BORDER_COLORS[modelType] ?? DEFAULT_BORDER_COLOR} rounded-2xl px-10 py-8 pt-20`,
            {
              'pt-8': isCollapsed,
            },
            'flex flex-col leading-tight text-center',
          )}
          style={{
            background: colors.background.flowGradient,
          }}
        >
          <div className='absolute top-2 right-[-75px] z-20'>
            <FlowHeader formId={id} modelType={modelType} status={status} />
          </div>

          <CanvasFlow
            key={`${modelType}-${id}`}
            modelType={modelType}
            parentId={id}
            overrides={overrides}
            elements={elements}
            nodeIndex={nodeIndex}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <CustomHandle type='source' position={Position.Right} isConnectable />
        </div>
      </div>
    </FormProvider>
  )
})

BaseFlowNode.displayName = 'FlowNode'

export const FlowNode = withErrorBoundary(BaseFlowNode, {
  FallbackComponent: ErrorNode,
  onError(error: any, info: any) {
    console.error('FlowNode Error caught by Error Boundary:', error, info)
  },
})
