import { Helmet } from 'react-helmet'

import { useUserAccountContext } from '../context/userContext'

function PaddleHead() {
  const { currentUser } = useUserAccountContext()

  return (
    <Helmet>
      <script type='text/javascript'>
        {`
                    var isLoaded = new Promise((res,rej) => {
                        function include_once(url) {
                            /*
                            [additional code to check if 'url' was already included]
                            */
                            if (document.querySelector("script[src='https://cdn.paddle.com/paddle/v2/paddle.js']")) {
                              // Script has already been included, resolve immediately
                              res();
                              return;
                            }
                            
                            var script = document.createElement('script')
                            script.type = 'text/javascript'
                            script.onload = () => res()
                            script.onerror = () => rej()
                            script.src = url
                            document.head.appendChild(script)
                        }
                        include_once('https://cdn.paddle.com/paddle/v2/paddle.js')
                    })
                    isLoaded.then(() => {
                        ${
                          process.env.REACT_APP_PADDLE_IS_SANDBOX
                            ? `window.Paddle.Environment.set('sandbox')`
                            : ``
                        }
                        window.Paddle.Setup({
                        token: '${
                          process.env.REACT_APP_PADDLE_PUBLIC_AUTH_TOKEN
                        }',
                        pwAuth: '${
                          process.env.REACT_APP_PROFITWELL_PUBLIC_TOKEN
                        }',
                        pwCustomer: {${
                          currentUser && currentUser.paddleCustomerId
                            ? `id: '${currentUser.paddleCustomerId}'`
                            : ''
                        } },
                        eventCallback: function(data) {
                          // The data.event will specify the event type
                          if (data.name === 'checkout.closed') {
                            const paddleCheckoutClosed = new CustomEvent('paddleCheckoutClosed', { detail: data })
                            window.dispatchEvent(paddleCheckoutClosed)
                          } else if (data.name === 'checkout.completed') {
                            const paddleCheckoutCompleted = new CustomEvent('paddleCheckoutCompleted', { detail: data })
                            window.dispatchEvent(paddleCheckoutCompleted)
                            window.Paddle.Checkout.close()
                          } else if (data.name === 'checkout.customer.created') {
                            const paddleCheckoutVerify = new CustomEvent('paddleCheckoutVerify', { detail: data })
                            window.dispatchEvent(paddleCheckoutVerify)
                          } else if (data.name === 'checkout.failed') {
                            const paddleCheckoutFailed = new CustomEvent('paddleCheckoutFailed', { detail: data })
                            window.dispatchEvent(paddleCheckoutFailed)
                            window.Paddle.Checkout.close()
                          }
                        }
                        })
                    })
                `}
      </script>
    </Helmet>
  )
}

export default PaddleHead
