import ImageDnD from './ImageDnD'
import AudioDnD from './AudioDnD'
import VideoDnD from './VideoDnD'
import { StarIcon } from '@heroicons/react/24/solid'
import { useUserAccountContext } from '../context/userContext'

export default function InitialFilesUploadComponent({
  handleAudioFileChange,
  handleFileInputChange,
  handleVideoFileChange,
  setAlertMessage,
  setShowAlert,
  audio,
  audioUrl,
  reuseHasAudio,
  image,
  video,
  audioFileName,
  setAudioFileName,
  isAudioProcessing,
  setIsAudioProcessing,
  longVideoMaintenanceMode,
  disabled,
  maxAudioUploadLength,
  showMaxAudioUploadLength,
  didReuseSettings,
  maxVideoUploadLength,
  isVideo,
  isImage,
  allowAudioUpload,
  allowImageUpload,
  allowVideoUpload,
  imageMaskOverlay,
}) {
  const { currentUser } = useUserAccountContext()

  const isSubscribed = currentUser?.subscriptionType === 'Standard'

  const maxAudioLengthText = longVideoMaintenanceMode
    ? '2 minutes'
    : '4 minutes'

  return (
    <div className='flex flex-col flex-1 w-full gap-5 mb-5'>
      {/* <Disclosure as='div' className='p-6 pt-6 bg-darkGray rounded-3xl'>
        {({ open }) => (
          <> */}
      {/* INITIAL IMAGE */}
      {allowImageUpload && (
        <div className='flex flex-col justify-between gap-2 sm:flex-row sm:items-center'>
          <ImageDnD
            onChange={handleFileInputChange}
            image={image}
            title={'Image Upload'}
            color={'quaternary'}
            description={'Max size 20MB.'}
            imageMaskOverlay={imageMaskOverlay}
          />
          {/* <div className='space-y-5'>
          <div className='flex flex-col justify-between gap-2 sm:flex-row sm:items-center'>
            <p className='font-bold text-tertiary/60'>
              <PhotoIcon className='inline w-6 h-6 mr-4' />
              Initial Video: (max length 1 minute):
            </p>
            {!isSubscribed ? (
              <Link to='/pricing'>
                <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
                  <StarIcon className='inline w-6 h-6 mr-2' />
                  Go Pro to transform videos
                </div>
              </Link>
            ) : null}
          </div>
          {isSubscribed ? (
            <VideoDnD
              onChange={handleVideoFileChange}
              setAlertMessage={setAlertMessage}
              setShowAlert={setShowAlert}
              video={video}
            />
          ) : null}
        </div> */}
        </div>
      )}

      {allowVideoUpload && (
        <div className='flex flex-col justify-between gap-2 sm:flex-row sm:items-center'>
          {/*// verify if this component is necessary, otherwise remove*/}
          {isVideo && (
            <VideoDnD
              onChange={handleVideoFileChange}
              color={'primary-light'}
              setAlertMessage={setAlertMessage}
              setShowAlert={setShowAlert}
              video={video}
              disabled={!!image || !!audio || isAudioProcessing || disabled}
              description={`Max length ${parseInt(maxVideoUploadLength / 60)} ${
                parseInt(maxVideoUploadLength / 60) !== 1 ? 'mins.' : 'min.'
              }`}
              hideRemoveButton={true}
            />
          )}

          {isSubscribed && longVideoMaintenanceMode && (
            <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
              Long video generations are temporarily disabled as we scale up our
              servers. Thank you for your patience.
            </div>
          )}
        </div>
      )}

      {/* INITIAL AUDIO */}
      {allowAudioUpload && (
        <div className='flex flex-col gap-2 sm:items-center'>
          {!isSubscribed ? (
            <div className='text-quaternary text-center relative bg-darkGray rounded-2xl flex flex-col w-full p-5 max-w-md self-start'>
              <h3 className='text-xl leading-7 sm:text-[21px] mb-[20px] text-kaiberGreen opacity-30'>
                Song Playback
              </h3>
              <a href='/pricing'>
                <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
                  <StarIcon className='inline w-6 h-6 mr-2' />
                  Upgrade to upload initial audio
                </div>
              </a>
            </div>
          ) : (
            <div className='flex flex-col flex-1 w-full gap-2'>
              {!isVideo && (
                <AudioDnD
                  title='Song Playback'
                  description={`Max length ${maxAudioLengthText}`}
                  onChange={handleAudioFileChange}
                  setAlertMessage={setAlertMessage}
                  setShowAlert={setShowAlert}
                  color={'primary-light'}
                  fileName={audioFileName}
                  setFileName={setAudioFileName}
                  audio={audio}
                  isProcessing={isAudioProcessing}
                  setIsProcessing={setIsAudioProcessing}
                  audioUrl={audioUrl}
                  longVideoMaintenanceMode={longVideoMaintenanceMode}
                  maxDuration={maxAudioUploadLength}
                  showMaxAudioUploadLength={showMaxAudioUploadLength}
                  didReuseSettings={didReuseSettings}
                  reuseHasAudio={reuseHasAudio}
                />
              )}
              {audio && (
                <>
                  <span className='px-5 text-sm text-primary'>
                    Audio track = Video length
                  </span>
                  <span className='px-5 text-sm text-primary'>
                    {didReuseSettings
                      ? 'Audio cannot be replaced if you reuse settings from a previous video'
                      : ''}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      )}

      {/* </>
        )}
      </Disclosure> */}
    </div>
    // <div className='bg-darkGray rounded-[64px] p-12 sm:w-[680px]'>
    //   <dt className='text-lg'>
    //     <div className='flex items-start justify-between w-full text-left text-white'>
    //       <div className='flex flex-col justify-center gap-4 sm:flex-row sm:items-center'>
    //         <p className='text-4xl font-bold text-tertiary/80'>
    //           Upload Initial Files
    //         </p>
    //         <div className='rounded-full bg-gray-500/80 text-tertiary/60 uppercase text-base px-2 py-1.5 font-bold'>
    //           Optional
    //         </div>
    //       </div>
    //     </div>
    //   </dt>
    //   <div as='dd' className='pt-6 mt-2 space-y-10'>
    //     {!video ? (
    //       <div className='space-y-5'>
    //         <p className='font-bold text-tertiary/60'>
    //           <PhotoIcon className='inline w-6 h-6 mr-4' />
    //           Initial Image:
    //         </p>
    //         <ImageDnD onChange={handleFileInputChange} image={image} />
    //       </div>
    //     ) : null}
    //     {!video ? (
    //       <div className='space-y-5'>
    //         {isSubscribed && longVideoMaintenanceMode ? (
    //           <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
    //             Long video generations are temporarily disabled as we scale up
    //             our servers. Thank you for your patience.
    //           </div>
    //         ) : null}
    //         <div className='flex flex-col justify-between gap-2 sm:flex-row sm:items-center'>
    //           <p className='font-bold text-tertiary/60'>
    //             <MusicalNoteIcon className='inline w-6 h-6 mr-4' />
    //             {`Initial Audio (max length ${maxAudioLengthText}):`}
    //           </p>
    //           {!isSubscribed ? (
    //             <Link to='/pricing'>
    //               <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
    //                 <StarIcon className='inline w-6 h-6 mr-2' />
    //                 Go Pro to upload initial audio
    //               </div>
    //             </Link>
    //           ) : (
    //             <p className='text-primary'>
    //               <a
    //                 href='https://audiotrimmer.com/'
    //                 target='_blank' rel='noreferrer'
    //                 rel='noreferrer'
    //               >
    //                 Need to trim your audio?
    //               </a>
    //             </p>
    //           )}
    //         </div>

    //         {isSubscribed ? (
    //           <AudioDnD
    //             onChange={handleAudioFileChange}
    //             setAlertMessage={setAlertMessage}
    //             setShowAlert={setShowAlert}
    //             audio={audio}
    //             longVideoMaintenanceMode={longVideoMaintenanceMode}
    //           />
    //         ) : null}
    //       </div>
    //     ) : null}
    //     <div className='space-y-5'>
    //       <div className='flex flex-col justify-between gap-2 sm:flex-row sm:items-center'>
    //         <p className='font-bold text-tertiary/60'>
    //           <PhotoIcon className='inline w-6 h-6 mr-4' />
    //           Initial Video: (max length 1 minute):
    //         </p>
    //         {!isSubscribed ? (
    //           <Link to='/pricing'>
    //             <div className='rounded-full font-bold bg-gray-500/80 text-primary text-sm px-4 py-2.5'>
    //               <StarIcon className='inline w-6 h-6 mr-2' />
    //               Go Pro to transform videos
    //             </div>
    //           </Link>
    //         ) : null}
    //       </div>
    //       {isSubscribed ? (
    //         <VideoDnD
    //           onChange={handleVideoFileChange}
    //           setAlertMessage={setAlertMessage}
    //           setShowAlert={setShowAlert}
    //           video={video}
    //         />
    //       ) : null}
    //     </div>
    //   </div>
    // </div>
  )
}
