import React, { useState } from 'react'

import { ContextMenu } from './ContextMenu'
import { DotGridIcon } from '../../../images/icons/DotGridIcon'
import { DownloadIcon } from '../../../images/icons/DownloadIcon'
import { MuteIcon } from '../../../images/icons/MuteIcon'
import { SoundOnIcon } from '../../../images/icons/SoundOnIcon'
import { MediaType } from '../../../types'
import { IconButton } from '../../Button'
import { OverlayBackground } from '../OverlayBackground'

interface MediaOverlayMenuProps {
  mediaType: MediaType
  nodeId?: string
  onDownload?: () => void
  onBookmark?: () => void
  isMuted: boolean
  showDownloadButton?: boolean
  toggleSound: () => void
}

export const MediaOverlayMenu: React.FC<MediaOverlayMenuProps> = ({
  mediaType,
  nodeId,
  onDownload,
  isMuted,
  showDownloadButton,
  toggleSound,
}) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)

  const toggleContextMenu = () => {
    setIsContextMenuVisible(!isContextMenuVisible)
  }

  return (
    <OverlayBackground>
      {/* Overlay top */}
      {!!nodeId && (
        <div className='absolute top-3 right-3 z-10'>
          <IconButton
            onClick={toggleContextMenu}
            icon={DotGridIcon}
            title='menu'
          />
          {isContextMenuVisible && (
            <div className='absolute top-0 left-0 pl-14'>
              <ContextMenu id={nodeId} top={25} right={56} isNavMenu={true} />
            </div>
          )}
        </div>
      )}

      {/* Overlay bottom */}
      <div className='absolute bottom-3 z-10 w-full px-3'>
        <div className='flex justify-between'>
          {/* bottom left */}
          <div className='flex gap-3'>
            {MediaType.Video === mediaType && (
              <IconButton
                onClick={toggleSound}
                icon={isMuted ? MuteIcon : SoundOnIcon}
                title='Toggle Sound'
              />
            )}
          </div>

          {/* bottom right */}
          <div className='flex gap-3'>
            {showDownloadButton && onDownload && (
              <IconButton
                onClick={onDownload}
                icon={DownloadIcon}
                title='download'
                className='text-white'
              />
            )}
            {/* 
              <IconButton
                onClick={onBookmark}
                icon={BookmarkIcon}
                title='bookmark'
              />
            */}
          </div>
        </div>
      </div>
    </OverlayBackground>
  )
}
