import { useCallback, useEffect, useState, useRef } from 'react'
import ReactPolling from 'react-polling'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import { HomeIcon } from '@heroicons/react/24/outline'
import { useMixpanel } from 'react-mixpanel-browser'
import { MIXPANEL_EVENTS, trackEvent } from '../utils/mixpanel'

import http from '../services/HttpService'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import InviteSection from '../components/InviteSection'
import RelatedSlider from '../components/RelatedSlider'
import { useUserAccountContext } from '../context/userContext'
import { useVideoEventsContext } from '../context/videoEventsContext'
import LoadingWhite from '../images/lottie/loaderWhite.json'
import { CopyPromptButton } from '../components/CanvasCell/CopyPromptButton'
import expand from '../images/Expand.svg'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import {
  ANIMATED_IMAGE_PROMPT,
  ANIMATED_IMAGE_PROMPT_COPY,
} from '../utils/constants'
import { getPrompt } from '../utils'

function ShareView() {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [memoryId, setMemoryId] = useState(null)
  const [memory, setMemory] = useState(null)
  const [isMemoryDeleted, setMemoryDeleted] = useState(false)
  const { currentUser, isAuthenticated, featureToggles } =
    useUserAccountContext()
  const {
    setEventStartTime,
    setEventEndTime,
    setEventMethod,
    setEventMemoryId,
    onUnloadEvent,
  } = useVideoEventsContext()
  const mixpanel = useMixpanel()
  const navigate = useNavigate()
  const videoRef = useRef()
  const [relatedVideos, setRelatedVideos] = useState([{}, {}, {}])

  const fetchMemory = useCallback(() => {
    return http.get('/api/get_memory/' + params.memory_id)
  }, [params.memory_id])

  const fetchRelatedVideos = (memory_id) => {
    return http.get('/api/related-videos/' + memory_id)
  }

  const enableRelatedVideos = featureToggles['enable-related-videos']

  useEffect(() => {
    // if params.memory_id set a variable to then refresh the page
    if (params.memory_id) {
      setMemoryId(params.memory_id)
    }
  }, [params.memory_id])

  const getData = useCallback(async () => {
    try {
      const memoryData = await fetchMemory()
      if (memoryData.data?.memory) {
        const isOwner =
          currentUser?.distinctId === memoryData.data?.memory.auth0_user_id
        if (isOwner) {
          // navigate(`/memory/${params.memory_id}`)
        }
        setMemory(memoryData.data.memory)
        setMemoryDeleted(false)
        if (videoRef.current) {
          videoRef.current.load()
        }
      }

      if (memoryData.data?.deleted === true) {
        setMemory(null)
        setMemoryDeleted(true)
      }

      if (enableRelatedVideos) {
        const graphQL = await fetchRelatedVideos(
          memoryData.data.memory?.memory_id || memoryId,
        )

        if (!graphQL.data.error) {
          const shuffledMovies = randomizeArray(graphQL.data.movies)
          setRelatedVideos(shuffledMovies)
        } else {
          setRelatedVideos({})
        }
      }
      setIsLoading(false)

      return memoryData
    } catch (error) {
      if (error.response?.status === 404) {
        navigate('/not-found')
      }
      setIsLoading(false)
    }
  }, [
    currentUser?.distinctId,
    enableRelatedVideos,
    fetchMemory,
    memoryId,
    navigate,
  ])

  useEffect(() => {
    if (memoryId) getData()
  }, [getData, memoryId])

  const onPlay = (event) => {
    setEventStartTime(videoRef.current.currentTime)
  }

  const onPause = (event) => {
    setEventMemoryId(memoryId)
    setEventEndTime(videoRef.current.currentTime)
    setEventMethod('player')
  }

  useEffect(() => {
    const onUnload = (event) => {
      onUnloadEvent(videoRef, memoryId)
    }

    window.addEventListener('beforeunload', onUnload)

    return () => {
      window.addEventListener('beforeunload', onUnload)
    }
  }, [memoryId, onUnloadEvent, params.memory_id])

  function randomizeArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  function onSlideSelected(selectedMemoryId) {
    setRelatedVideos([{}, {}, {}])

    mixpanel.track('click_related_video', {
      distinct_id: currentUser?.distinctId,
      email: currentUser?.email,
      click_related_video_source: memory
        ? 'Gallery Video'
        : isMemoryDeleted
          ? 'Deleted Video'
          : '404 page',
      originating_video_id: selectedMemoryId,
      clicked_video_id: memoryId,
    })

    trackEvent(mixpanel, currentUser, MIXPANEL_EVENTS.CLICK_RELATED_VIDEO, {
      platform: window.location.origin,
      // video_type: ,  to implement in the future https://height.app/M9jzUFTFNu/T-6200s
      // video_index: currentIndex?,  to implement in the future https://height.app/M9jzUFTFNu/T-6200
      video_is_deleted: isMemoryDeleted,
      original_memory_id: selectedMemoryId,
      clicked_memory_id: memoryId,
      video_creator_distinct_id: memory?.auth0_user_id,
      // video_creator_username:  to implement in the future https://height.app/M9jzUFTFNu/T-6200
      // video_thumbnail:  to implement in the future https://height.app/M9jzUFTFNu/T-6200
    })

    setIsLoading(true)
    setMemoryId(selectedMemoryId)
    navigate(`/share/${selectedMemoryId}`)
    window.scrollTo(0, 0)
  }

  const getPromptFromMemory = () => {
    if (memory.prompt) return memory.prompt
    else return getPrompt(memory.scenes[0]?.subject, memory.scenes[0]?.style)
  }

  return (
    <div>
      <Navbar />
      <div className='flex bg-dark justify-center py-9 pt-[74px]'>
        {memoryId ? (
          <ReactPolling
            url={'url to poll'}
            interval={1000} // in milliseconds(ms)
            retryCount={10000} // this is optional
            onSuccess={(response) => {
              if (
                response.data.memory &&
                (typeof response.data.memory.status === 'undefined' ||
                  response.data.memory.status === 'done')
              ) {
                setMemory(response.data.memory)
                return false
              } else if (response.data?.deleted === true) {
                setMemoryDeleted(true)
                return false
              } else {
                return true
              }
            }}
            onFailure={(response) => {
              return true
            }} // this is optional
            promise={getData} // custom api calling function that should return a promise
            render={({ startPolling, stopPolling, isPolling }) => {
              if (isPolling) {
                return (
                  <div>
                    <div className='flex justify-center'>
                      <div className='w-3/4 m-auto space-y-8 text-center'>
                        <div className='flex justify-center py-40'>
                          <div className='flex flex-col items-center space-y-4'>
                            <div className='p-6 bg-darkGray rounded-3xl'>
                              <Lottie
                                animationData={LoadingWhite}
                                loop={true}
                                className='w-16 h-16'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              } else if (memory || isMemoryDeleted) {
                return (
                  <div className='w-full'>
                    <div className='m-auto space-y-8 max-w-[1152px] md:w-[93%] md:mt-[70px] mb-[60px]'>
                      <div className='flex flex-col-reverse grid-cols-4 gap-8 md:flex-row'>
                        {isLoading ? (
                          <div className='w-full h-full absolute top-0 left-0 flex items-center justify-center z-10'>
                            <div className='p-6 bg-darkGray rounded-3xl'>
                              <Lottie
                                animationData={LoadingWhite}
                                loop={true}
                                className='w-16 h-16'
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className='md:w-[50%]'>
                          <div className='px-6 py-6 pt-0 md:pt-6 space-y-4 sm:px-12 sm:py-10 md:bg-darkGray md:rounded-3xl border-b-[1px] border-b-[#292932] md:border-0 pb-[32px] md:pb-6'>
                            {memory ? (
                              <div>
                                <div className='items-center hidden'>
                                  <div className='w-[54px] h-[54px] rounded-[8px] bg-[#333] mr-[10px] overflow-hidden'>
                                    <img
                                      src='https://secret-memories.s3.amazonaws.com/init-images/ffc75fb4-52b4-4018-965c-d44935182671-2023-11-20-08-10-21.png?AWSAccessKeyId=AKIASWOMRRLR3UMQGJW6&Expires=1700749285&Signature=wGHm91TLVNwpMrx0cmBfn9N508U%3D'
                                      alt='init'
                                    />
                                  </div>
                                  <p className='text-white text-base'>
                                    User Name
                                  </p>
                                </div>
                                {getPromptFromMemory() ===
                                ANIMATED_IMAGE_PROMPT ? (
                                  <p className='text-2xl text-tertiary line-clamp-[10] leading-normal mt-3'>
                                    {ANIMATED_IMAGE_PROMPT_COPY}
                                    <span className='inline-block has-tooltip ml-3'>
                                      <div className='text-lg pl-8 pr-2 py-1 text-gray-100 bg-gray-800 rounded shadow-lg tooltip'>
                                        No prompt was entered when creating this
                                        video.
                                      </div>
                                      <AiOutlineQuestionCircle className='size-7 leading-3 text-[#9B92D0]' />
                                    </span>
                                  </p>
                                ) : (
                                  <p className='text-2xl text-tertiary break-words line-clamp-[10] mt-0'>
                                    {getPromptFromMemory()}
                                  </p>
                                )}

                                <div className='w-[70px] justify-between mt-[35px] hidden md:flex'>
                                  <CopyPromptButton
                                    settingsUsed={memory.settingsUsed}
                                  />
                                  {/* <FavouritePromptButton /> */}
                                </div>

                                {/*<div className='flex flex-col items-center gap-2 md:flex-row justify-left'>
                                  <Link
                                    to={
                                      isAuthenticated
                                        ? '/create'
                                        : `/invite/${memory.creator_uuid}`
                                    }
                                    className='w-full md:w-auto'
                                  >
                                    <button className='inline-flex items-center md:hidden justify-center flex-grow w-full gap-2 px-6 py-4 text-base font-bold text-gray-700 uppercase border border-transparent rounded-full shadow-sm radial-gradient hover:bg-green-700 focus:outline-none focus:ring-0 sm:w-auto'>
                                      Create with the same prompt
                                    </button>
                                  </Link>
                                  </div>*/}
                              </div>
                            ) : (
                              <div className='text-center sm:text-start'>
                                <h1 className='text-tertiary text-8xl'>
                                  <span className='font-bold'>404</span>
                                </h1>
                                <p className='text-2xl text-tertiary break-words line-clamp-[10] mt-0'>
                                  This video has been deleted.
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='flex flex-col items-center col-span-2 max-h-[440px] md:max-h-full overflow-hidden md:overflow-auto mt-[25px] md:mt-0 w-full rounded-tl-[10px] rounded-tr-[10px] md:rounded-0 relative md:w-[50%]'>
                          {memory ? (
                            <div>
                              <video
                                className='m-auto md:rounded-lg md:max-h-[680px] w-full object-cover object-center md:mt-0'
                                type='video/mp4'
                                autoPlay
                                muted
                                loop
                                playsInline
                                controls
                                ref={videoRef}
                                onPlay={onPlay}
                                onPause={onPause}
                                onEnded={onPause}
                              >
                                <source
                                  src={memory.mediaUrl}
                                  type='video/mp4'
                                />
                              </video>

                              <div className='hidden bg-black rounded-[4px] text-[15px] bg-opacity-50 text-white px-2 py-1 absolute bottom-[10px] left-[10px]'>
                                0:24
                              </div>

                              <button className='hidden bg-none outline-none bg-black bg-opacity-50 rounded-[4px] px-1 py-1 absolute bottom-[10px] right-[10px]'>
                                <img src={expand} alt='Expand Video' />
                              </button>

                              <div className='w-full h-[2px] absolute bottom-0 hidden'>
                                <div className='w-[75%] h-full bg-white'></div>
                              </div>
                            </div>
                          ) : (
                            <Link to='/'>
                              <button className='inline-flex items-center justify-between flex-grow gap-2 px-6 py-4 mt-6 text-base font-bold text-white uppercase border border-transparent rounded-full shadow-sm bg-secondary hover:bg-green-700 focus:outline-none focus:ring-0 w-60'>
                                Go back home
                                <HomeIcon className='w-6 h-6' />
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    {relatedVideos &&
                    relatedVideos.length &&
                    enableRelatedVideos ? (
                      <div className='rada m-auto space-y-8  max-w-[1152px] md:w-[93%] w-[95%]'>
                        <div className='text-white max-w-[100vw] w-[100%]'>
                          <h2 className='text-[#C6C4C8] text-[24px] mb-[32px]'>
                            {isMemoryDeleted
                              ? 'Explore more videos'
                              : 'Related'}
                          </h2>
                          <RelatedSlider
                            sliderContent={relatedVideos}
                            handleOnSlideSelected={onSlideSelected}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              } else {
                return null
              }
            }}
          />
        ) : (
          <div>
            <div className='flex justify-center'>
              <div className='w-3/4 m-auto space-y-8 text-center'>
                <div className='flex justify-center py-40'>
                  <div className='flex flex-col items-center space-y-4'>
                    <div className='p-6 bg-darkGray rounded-3xl'>
                      <Lottie
                        animationData={LoadingWhite}
                        loop={true}
                        className='w-16 h-16'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isAuthenticated ? (
        <div className='px-5 bg-dark'>
          <InviteSection registerLink={`/invite/${memory?.creator_uuid}`} />
        </div>
      ) : null}
      {memory && <Footer />}
    </div>
  )
}

export default ShareView
