function StudioReel() {
  return (
    <div classname='flex justify-center flex-col w-full'>
      <h2 className='text-left md:text-center text-[32px] md:text-[48px] text-white md:max-w-[80%] m-auto leading-[36px] md:leading-[56px] mb-[50px] md:mb-[100px]'>
        Kaiber Studio is an emergent leader at the frontier of the next era of
        self expression, and we’re just getting started.
      </h2>

      <div className='p-0 pt-[56.25%] relative mb-0 md:mb-[120px]'>
        <iframe
          muted='true'
          loading='lazy'
          title='Gumlet video player'
          false
          src='https://play.gumlet.io/embed/65f3029aafcac16a02fcff9a'
          className='border-0 absolute top-0 left-0 h-full w-full'
          allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;'
          frameborder='0'
          allowfullscreen
        ></iframe>
      </div>
    </div>
  )
}

export default StudioReel
