import { listUniqueTags } from '../api'
import { myLibraryStore } from '../stores'

/**
 * Capitalizes the first character (e.g. "image" -> "Image") because
 * by convention, a system-defined tag's first character is capitalized.
 */
export const normalizeTagName = (word: string): string => {
  return word.substring(0, 1).toUpperCase() + word.substring(1)
}

export const reloadTagFilters = async () => {
  try {
    const newTags = await listUniqueTags()
    myLibraryStore.setTagFilters((curTags) => {
      const selectedTags = curTags.filter((tag) => tag.selected)
      if (selectedTags.length > 0) {
        selectedTags.forEach((selectedTag) => {
          const newTag = newTags.find(
            (newTag) =>
              newTag.ns === selectedTag.ns && newTag.name === selectedTag.name,
          )
          if (newTag) {
            newTag.selected = true
          } else {
            newTags.push(selectedTag)
          }
        })
      }
      return newTags
    })
  } catch (error) {
    console.error('Error fetching unique tags:', error)
  }
}
