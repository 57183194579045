import { HOTKEYS } from '../constants'

/**
 * Checking if ctrl or cmd key is pressed depends on system
 *
 * @param event
 * @returns true | false
 */
export const isCtrlKeyPressed = (event: KeyboardEvent) => {
  return event.metaKey || event.ctrlKey
}

/**
 * Checking if registered hotkey is pressed
 *
 * @param event
 * @returns hotkey
 */
export const getHotKey = (event: KeyboardEvent) => {
  if (isCtrlKeyPressed(event)) {
    switch (event.key) {
      case 'a':
      case 'A':
        return HOTKEYS.SELECT_ALL
      case 'c':
      case 'C':
        return HOTKEYS.COPY
      case 'v':
      case 'V':
        return HOTKEYS.PASTE
      case 'e':
      case 'E':
        return HOTKEYS.FIT_VIEW
      default:
        return HOTKEYS.NONE
    }
  }

  switch (event.key) {
    case 'Enter':
      return HOTKEYS.ENTER
    default:
      return HOTKEYS.NONE
  }
}
